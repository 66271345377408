import create from "zustand";
import produce from "immer";

export type ActionableInsightFilter = {
  label: string;
  id: number;
  enabled: boolean;
};

export type EmployeeTableFilterState = {
  searchText: string;
  status: string;
  department: string;
  dateOfJoinStart: Date;
  dateOfJoinEnd: Date;
  actionableInsightFilters: ActionableInsightFilter[];
  setSearchText: (text: string) => void;
  setStatus: (text: string) => void;
  setDepartment: (text: string) => void;
  setDateOfJoinStart: (date: Date) => void;
  setDateOfJoinEnd: (date: Date) => void;
  toggleActionableInsightFilter: (
    insightFilter: ActionableInsightFilter,
    value: boolean
  ) => void;
  reset: () => void;
};

const initialState = {
  searchText: "",
  status: "",
  department: "",
  dateOfJoinStart: new Date(2000, 1, 1),
  dateOfJoinEnd: new Date(),
  actionableInsightFilters: [
    {
      label: "Flight Risk ( less than 20% )",
      quantity: "0",
      id: 0,
      enabled: false,
    },
    {
      label: "Option Holder with zero grant",
      quantity: "0",
      id: 1,
      enabled: false,
    },
    {
      label: "Credentials not generated",
      quantity: "0",
      id: 2,
      enabled: false,
    },
    {
      label: "Option Holder yet to login",
      quantity: "0",
      id: 3,
      enabled: false,
    },
    { label: "Resigned Option Holder", quantity: "0", id: 4, enabled: false },
  ],
};
const useStore = create<EmployeeTableFilterState>((set) => ({
  ...initialState,
  setSearchText: (text) => {
    set((state) => ({ ...state, searchText: text }));
  },
  setStatus: (text) => {
    set((state) => ({ ...state, status: text }));
  },
  setDepartment: (text) => {
    set((state) => ({ ...state, department: text }));
  },
  setDateOfJoinStart: (date) => {
    set((state) => ({ ...state, dateOfJoinStart: date }));
  },
  setDateOfJoinEnd: (date) => {
    set((state) => ({ ...state, dateOfJoinEnd: date }));
  },
  toggleActionableInsightFilter: (insightFilter, value) => {
    set(
      produce((state: EmployeeTableFilterState) => {
        const insightFilters = state.actionableInsightFilters;
        const filterToToggle = insightFilters.find(
          (item) => item.id === insightFilter.id
        );
        if (filterToToggle) {
          filterToToggle.enabled = value;
        }
      })
    );
  },
  reset: () => {
    set(() => initialState);
  },
}));

export const useEmployeeTableFilterStore = useStore;
