import React from "react";
import randomColor from "randomcolor";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useNavigate } from "react-router";
import { _sum } from "../utils/arrays";
import { useEsopPlans } from "../queries";
import { Box, HStack, VStack } from "../components/utils";
import { DoughnutChart } from "./DoughnutChart";
import { getCurrencyType } from "../utils/currencyFormatter";

ChartJS.register(ArcElement, Tooltip, Legend);
const CardPlan = () => {
  const currency = getCurrencyType();
  const { data, isPlaceholderData } = useEsopPlans();
  const plans = (data || []).filter((plan) => plan.planName !== "Total");
  const header = "Option Plans";
  const values = plans.map((plan, index) => [plan.issuedShares, plan.planName]);
  const graphValues = values.map((value, index) => value[0]);
  const planNames = values.map((value, index) => value[1]);
  const backgroundColor: string[] = [];
  for (let i = 0; i < values.length; i++) {
    const color = randomColor({
      luminosity: "bright",
      alpha: 0.3,
      seed: planNames[i],
    });
    backgroundColor.push(color);
  }
  const planData = {
    labels: planNames,
    datasets: [
      {
        label: "# of Votes",
        data: graphValues,
        backgroundColor,
        borderColor: backgroundColor,
      },
    ],
  };
  const navigate = useNavigate();
  return (
    <VStack
      className={`p-6 bg-white rounded-lg max-h-96 overflow-auto ${
        isPlaceholderData ? "loading" : ""
      } `}
    >
      <VStack className="mb-4">
        <p className="font-medium text-gray-dark">{header}</p>
        <p className="text-xs1 font-semi-medium text-gray-light">
          {plans.length.toLocaleString(currency)} Active Plans
        </p>
      </VStack>
      <Box
        className="cursor-pointer w-50 h-70 align-center"
        onClick={() => {
          navigate("/options/allPlans");
        }}
      >
        {data && <DoughnutChart data={planData} />}
      </Box>
      <VStack className="overflow-auto ">
        {plans.map((plan) => {
          const backgroundColor = randomColor({
            luminosity: "bright",
            alpha: 0.3,
            seed: plan.planName,
          });
          return (
            <HStack key={plan.esopPlanId} className="justify-between p-2">
              <HStack>
                <Box
                  style={{ backgroundColor }}
                  className="w-4 h-4 mt-2 rounded"
                ></Box>
                <VStack
                  className="pl-4 cursor-pointer"
                  onClick={() => {
                    navigate(`/options/planView/${plan.esopPlanId}`);
                  }}
                >
                  <p className="font-medium text-xs2 text-gray-dark">
                    {plan.planName}
                  </p>
                  <p className="text-xs1 font-semi-medium text-gray-light">
                    {plan.issuedShares.toLocaleString(currency)} Options
                  </p>
                </VStack>
              </HStack>
            </HStack>
          );
        })}
      </VStack>
    </VStack>
  );
};

export default CardPlan;
