import { Icon } from "@iconify/react";
import { CircularProgress } from "@mui/material";
import { ChartData } from "chart.js";
import { format } from "date-fns";
import { Form, Formik, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useIsMutating, useQuery } from "react-query";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { getPlanDetails } from "../../api/Esop";
import { ExpandButton } from "../../components/shared/ExpandButton";
import FileInput from "../../components/shared/FileInput";
import { Input, Label, TextArea } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import { SelectButtonGroup } from "../../components/shared/SelectButtonGroup";
import { SwitchButton } from "../../components/shared/SwitchButton";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
} from "../../components/utils";
import PlanTypes from "../../constants/PlanTypes";
import { useUserDetails, useVestingTemplates } from "../../queries";
import { queryClient } from "../../queries/client";
import {
  useAddPlan,
  useAddPlanResult,
  useCreatePlanDetails,
  useEditPlan,
  usePlanStateChange,
} from "../../queries/esopPlan";
import { useError } from "../../store/errorStore";
import { AddPlanReq, EsopPlan } from "../../types/EsopPlan";
import { VestingTriggerType, VestingType } from "../../types/VestingTemplate";
import { getCurrencyType } from "../../utils/currencyFormatter";
import {
  formatDate,
  formatDisplayDate,
  formatWithTimeZone,
} from "../../utils/date";
import { AddOrEditVestingTemplate } from "../vestingSchedules/AddVestingTemplate";
import { BarChart } from "../vestingSchedules/BarChart";
import { generateProjections } from "../vestingSchedules/generateProjections";
import { planInitValues } from "./planInitValues";

type AddOrEditPlanProps = {
  mode?: "Edit" | "Add";
  onClose: () => void;
  plan?: EsopPlan;
};

export default function AddOrEditPlan(props: AddOrEditPlanProps) {
  const { mode, onClose, plan } = props;
  const { mutate: addPlan } = useAddPlan();
  const { mutate: editPlan } = useEditPlan();
  const [activeStepNo, setActiveStepNo] = useState(1);
  const { data: _vestingTemplates } = useVestingTemplates();
  const vestingTemplates = _vestingTemplates || [];
  const { data: createPlanDetails } = useCreatePlanDetails() as any;
  const userData = useUserDetails();
  const vestingTemplateNames = vestingTemplates.map(
    (v) => v.vestingTemplateName
  );
  const { isIdle, data, isLoading } = useQuery(
    ["currentPlanDetials", plan?.esopPlanId],
    () => getPlanDetails((plan as EsopPlan)?.planName),
    {
      enabled: mode === "Edit",
    }
  );
  function getInitialValues() {
    if (mode === "Edit" && plan && data) {
      const existingPlanValues = {
        planName: data.planName,
        planType: data.planType,
        planStartDate: formatDate(data.planStartDate),
        poolSize: data.poolSize,
        planTerm: data.planTerm,
        planDescription: data.planDescription,
        notes: data.note || "",
        vestingTemplateId: data.vesting.id,
        conversionNumber: data.conversionNumber || 1,
        conversionShares: data.conversionShares || 1,
        poolShares: data.poolSize / data.conversionNumber || 0,
        vestingTemplateName: data.vesting.vestingTemplateName,
        isExercise: data.excerise.isExercise,
        exercisePercentage: data.excerise.exercisePercentage * 100,
        frequencyOfExercise: data.excerise.frequencyOfExercise,
        isResignation:
          (data.acceleratedVesting &&
            data.acceleratedVesting?.isTerminationForResignation) ||
          false,
        resignationDuration:
          (data.acceleratedVesting &&
            data.acceleratedVesting.terminationForResignationDuration) ||
          0,
        resignationPercentage:
          (data.acceleratedVesting &&
            data.acceleratedVesting.terminationForResignationPercentage *
              100) ||
          0,
        isCause:
          (data.acceleratedVesting &&
            data.acceleratedVesting.isTerminationForCause) ||
          false,
        causeDuration:
          (data.acceleratedVesting &&
            data.acceleratedVesting.terminationForCauseDuration) ||
          0,
        causePercentage:
          (data.acceleratedVesting &&
            data.acceleratedVesting.terminationForCausePercentage * 100) ||
          0,
        isConvenience:
          (data.acceleratedVesting &&
            data.acceleratedVesting.isTerminationConvenience) ||
          false,
        convenienceDuration:
          (data.acceleratedVesting &&
            data.acceleratedVesting.terminationForConvenienceDuration) ||
          0,
        conveniencePercentage:
          (data.acceleratedVesting &&
            data.acceleratedVesting.terminationForConveniencePercentage *
              100) ||
          0,
        trustShareholderId:
          data.planType === "Trust" ? data.trustShareholderId : 0,
        trustShareHolderName: data.trustShareholderName || null,
        boardResolutionDocuments: "",
        deathDuration: 0.0,
        deathPercentage: 0,
        id: data.id,
      };
      return existingPlanValues;
    } else {
      return planInitValues;
    }
  }
  const getAvailableShares = (values: AddPlanReq) => {
    let poolAvailable =
      plan?.esopPlanState === "Active" || plan?.esopPlanState === "Amendment"
        ? (createPlanDetails?.esopPoolCount || 0) + (plan?.totalShares || 0)
        : createPlanDetails?.esopPoolCount;
    if (values.planType === "Trust" && mode === "Add") {
      const selectedShareHolder = values?.trustShareholderId;
      poolAvailable =
        createPlanDetails?.trustDetails.find(
          (shareHolder: { id: number }) =>
            // eslint-disable-next-line eqeqeq
            shareHolder.id == selectedShareHolder
        )?.poolAvailable || 0;
    }
    return poolAvailable;
  };
  const steps = [
    { no: 1, title: "Create an ESOP Plan" },
    { no: 2, title: "Vesting Schedule" },
    { no: 3, title: "Exercise Window" },
    { no: 4, title: "Constructed" },
  ];
  const [areStepsValid, setStepValid] = useState({
    basicDetails: false,
    vestingSchedules: false,
    excerciseWindow: false,
  });
  const minStartDate = new Date(
    userData.data?.company.dateOfIncorporation || "1900-01-01"
  );
  const validationSchema = Yup.object({
    isExercise: Yup.boolean(),
    planType: Yup.string()
      .required("required")
      .oneOf(PlanTypes.map((t) => t.value)),
    planName: Yup.string()
      .required("required")
      .min(3, "Minimum three characters required")
      .max(100),
    planStartDate: Yup.date()
      .required("required")
      .min(
        minStartDate,
        `Plan date should not be before company Incorporation date (
    ${format(
      new Date(userData.data?.company.dateOfIncorporation || "1900-01-01"),
      "dd-MM-yyyy"
    )})`
      ),
    poolSize: Yup.number()
      .required("Pool Size is required")
      .positive("Pool Size must be a positive number")
      .test(
        "Pool Size",
        "Pool Size must be lesser than available options ",
        (_value, context) => {
          const value = context.parent as AddPlanReq;
          const optionsAvailable =
            plan?.esopPlanState === "Active" ||
            plan?.esopPlanState === "Amendment"
              ? (createPlanDetails?.esopPoolCount || 0) +
                (plan?.totalShares || 0)
              : createPlanDetails?.esopPoolCount;
          let sharesAvailable = optionsAvailable * value.conversionNumber;
          if (
            value.planType === "Trust" &&
            value.trustShareholderId !== undefined &&
            mode === "Add"
          ) {
            sharesAvailable =
              (createPlanDetails?.trustDetails.find(
                (shareHolderId: { id: number; poolAvailable: number }) =>
                  // eslint-disable-next-line eqeqeq
                  shareHolderId.id == value.trustShareholderId
              )?.poolAvailable || 0) * value.conversionNumber;
          }
          if (value.poolSize > sharesAvailable) {
            return false;
          }
          return true;
        }
      ),
    planTerm: Yup.number()
      .required("Plan Term  is required")
      .positive("Plan Term must be a positive number"),
    planDescription: Yup.string()
      .required("required")
      .min(3, "Minimum three characters required")
      .max(500),
    notes: Yup.string(),
    trustShareholderId: Yup.number().when(["planType"], {
      is: (planType: string) => planType === "Trust",
      then: Yup.number().required("required"),
    }),
    vestingTemplateName: Yup.string().required("required"),
    isCause: Yup.boolean(),
    conversionNumber: Yup.number()
      .integer("conversion number should be an integar")
      .positive("should not be zero"),
    poolShares: Yup.number()
      .integer("Pool Share should be an integar")
      .positive("Pool Share should be positive"),
    isResignation: Yup.boolean(),
    isConvenience: Yup.boolean(),
    exercisePercentage: Yup.number().when(["isExercise", "planType"], {
      is: (isExercise: boolean, planType: string) =>
        isExercise && planType !== "SAR",
      then: Yup.number()
        .positive("required")
        .required("required")
        .typeError("expected a number"),
    }),
    causeDuration: Yup.number().when(["isExercise", "isCause"], {
      is: (isExercise: boolean, isCause: boolean) => isExercise && isCause,
      then: Yup.number()
        .positive("required")
        .integer("Please enter only whole number")
        .required("required")
        .typeError("expected a number"),
    }),
    causePercentage: Yup.number().when(["isExercise", "isCause"], {
      is: (isExercise: boolean, isCause: boolean) => isExercise && isCause,
      then: Yup.number()
        .positive("required")
        .required("required")
        .typeError("expected a number"),
    }),
    convenienceDuration: Yup.number().when(["isExercise", "isConvenience"], {
      is: (isExercise: boolean, isConvenience: boolean) =>
        isExercise && isConvenience,
      then: Yup.number()
        .positive("required")
        .integer("Please enter only whole number")
        .required("required")
        .typeError("expected a number"),
    }),
    conveniencePercentage: Yup.number().when(["isExercise", "isConvenience"], {
      is: (isExercise: boolean, isConvenience: boolean) =>
        isExercise && isConvenience,
      then: Yup.number()
        .positive("required")
        .required("required")
        .typeError("expected a number"),
    }),
    resignationDuration: Yup.number().when(["isExercise", "isResignation"], {
      is: (isExercise: boolean, isResignation: boolean) =>
        isExercise && isResignation,
      then: Yup.number()
        .positive("required")
        .integer("Please enter only whole number")
        .required("required")
        .typeError("expected a number"),
    }),
    resignationPercentage: Yup.number().when(["isExercise", "isResignation"], {
      is: (isExercise: boolean, isResignation: boolean) =>
        isExercise && isResignation,
      then: Yup.number()
        .positive("required")
        .required("required")
        .typeError("expected a number"),
    }),
  });
  const errorMessage = useError();
  const updateStepValidity = (
    stepName: "basicDetails" | "vestingSchedules" | "excerciseWindow",
    stepValue: boolean
  ) => {
    setStepValid((value) => ({ ...value, [stepName]: stepValue }));
  };
  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValues()}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const form = { ...values };
        form.vestingTemplateId =
          vestingTemplates.find(
            (v) => v.vestingTemplateName === form.vestingTemplateName
          )?.id || 0;
        form.causePercentage /= 100;
        form.conveniencePercentage /= 100;
        form.deathPercentage /= 100;
        form.exercisePercentage /= 100;
        form.resignationPercentage /= 100;
        form.planStartDate = formatWithTimeZone(form.planStartDate);
        form.trustShareholderId =
          form.planType === "Trust" ? form.trustShareholderId : 0;
        if (!form.isExercise) {
          form.isCause = false;
          form.causeDuration = 0;
          form.causePercentage = 0;
          form.isConvenience = false;
          form.convenienceDuration = 0;
          form.conveniencePercentage = 0;
          form.isResignation = false;
          form.resignationDuration = 0;
          form.resignationPercentage = 0;
        }
        if (mode === "Edit") {
          form.id = data?.id;
          editPlan(form, {
            onSuccess: () => {
              setActiveStepNo((step) => step + 1);
              toast("Plan Amended!", { type: "success" });
            },
            onError: (err: any) => {
              errorMessage.setMessage(err.response.data.reason);
              toast(err.response.data.reason, {
                type: "error",
                autoClose: 2000,
              });
            },
          });
        } else {
          addPlan(form, {
            onSuccess: () => {
              setActiveStepNo((step) => step + 1);
              toast("Plan Constructed!", { type: "success" });
            },
            onError: (err: any) => {
              errorMessage.setMessage(err.response.data.reason);
              toast(err.response.data.reason, {
                type: "error",
                autoClose: 2000,
              });
            },
          });
        }
      }}
    >
      <Form>
        <div className="justify-between px-10 text-lg font-medium border-b py-7">
          <h6 className="flex justify-between">
            {mode === "Edit" ? "Amend Plan" : "Add Plan"}{" "}
            <span onClick={() => onClose()} className="cursor-pointer">
              X
            </span>{" "}
          </h6>
        </div>
        <StepperHeader
          activeStepNo={activeStepNo}
          setActiveStepNo={(no) => setActiveStepNo(no)}
          steps={steps}
          stepValidtity={areStepsValid}
        ></StepperHeader>
        <div className={`flex flex-col min-h-[600px] `}>
          {activeStepNo === 1 && (
            <BasicDetails
              setStepValidity={(value: boolean) =>
                updateStepValidity("basicDetails", value)
              }
              onBack={() => onClose()}
              onNext={() => setActiveStepNo((step) => step + 1)}
              mode={mode}
              planData={plan}
            />
          )}
          {activeStepNo === 2 && (
            <VestingSchedules
              setStepValidity={(value: boolean) =>
                updateStepValidity("vestingSchedules", value)
              }
              onBack={() => setActiveStepNo((step) => step - 1)}
              onNext={() => setActiveStepNo((step) => step + 1)}
            />
          )}
          {activeStepNo === 3 && (
            <ExerciseWindows
              setStepValidity={(value: boolean) =>
                updateStepValidity("vestingSchedules", value)
              }
              onBack={() => setActiveStepNo((step) => step - 1)}
              onNext={() => setActiveStepNo((step) => step + 1)}
            />
          )}
          {activeStepNo === 4 && (
            <Constructed
              setStepValidity={(_value: boolean) => null}
              onBack={() => onClose()}
              onNext={() => {
                queryClient.setQueriesData("addPlanResult", undefined);
                onClose();
              }}
            />
          )}
        </div>
      </Form>
    </Formik>
  );
}
function Errors() {
  const formik = useFormikContext<AddPlanReq>();
  return (
    <>
      {Object.entries(formik.errors).map((error, i) => (
        <Error key={i} text={`${error[0]} : ${error[1]}`}></Error>
      ))}
    </>
  );
}

function StepperHeader({
  activeStepNo,
  steps,
  setActiveStepNo,
  stepValidtity,
}: {
  activeStepNo: number;
  steps: { no: number; title: string }[];
  setActiveStepNo: (no: number) => void;
  stepValidtity: {
    basicDetails: boolean;
    vestingSchedules: boolean;
    excerciseWindow: boolean;
  };
}) {
  function getIfPreviousStepIsValid(targetStepNo: number) {
    if (activeStepNo === steps.length) {
      return false;
    }
    if (targetStepNo < activeStepNo) {
      return true;
    }
    switch (activeStepNo) {
      case 1:
        return stepValidtity.basicDetails;
      case 2:
        return stepValidtity.vestingSchedules;
      case 3:
        return stepValidtity.excerciseWindow;
      default:
        return true;
    }
  }
  return (
    <HStack className="justify-evenly py-4 text-gray-400 text-[15px] ">
      {steps.map(({ no, title }, i) => (
        <VStack key={i}>
          <button
            type="button"
            className={`peer font-medium  ${
              activeStepNo === no ? "text-orange-501" : ""
            }`}
            onClick={() =>
              getIfPreviousStepIsValid(no) ? setActiveStepNo(no) : null
            }
          >
            {no}. {title}
          </button>
          <div
            className={`h-0.5 rounded-full ${
              activeStepNo === no ? "bg-orange-501" : ""
            }`}
            aria-label="underline"
          ></div>
        </VStack>
      ))}
    </HStack>
  );
}

type StepProps = {
  onBack: () => void;
  onNext: () => void;
  setStepValidity: (value: boolean) => void;
  mode?: "Edit" | "Add";
  planData?: EsopPlan;
};

function BasicDetails(props: StepProps) {
  const currency = getCurrencyType();
  const { onBack, onNext, setStepValidity, mode, planData } = props;
  const formik = useFormikContext<AddPlanReq>();

  const { data: createPlanDetails } = useCreatePlanDetails();
  const { errors, touched } = formik;
  const [displaySharesAvailable, setSharesAvailable] = useState(0);
  const basicDetailFields = [
    "planName",
    "planDescription",
    "planType",
    "planStartDate",
    "poolSize",
    "notes",
    "planTerm",
    "trustShareholderId",
  ];
  let initalCheck = false;
  const basicDetailErrors =
    errors.planName ||
    errors.planDescription ||
    errors.planType ||
    errors.planStartDate ||
    errors.poolSize ||
    errors.poolShares ||
    errors.conversionNumber ||
    errors.notes ||
    errors.planTerm ||
    (formik.values.planType === "Trust" && errors.trustShareholderId);
  function doPartialTouch() {
    formik.setFieldTouched("planName");
    formik.setFieldTouched("planDescription");
    formik.setFieldTouched("planType");
    formik.setFieldTouched("planStartDate");
    formik.setFieldTouched("poolSize");
    formik.setFieldTouched("poolShares");
    formik.setFieldTouched("conversionNumber");
    formik.setFieldTouched("notes");
    formik.setFieldTouched("planTerm");
    if (formik.values.planType === "Trust") {
      formik.setFieldTouched("trustShareholderId");
    }
  }
  useEffect(() => {
    if (planData || createPlanDetails) {
      setSharesAvailable(getSharesAvailable() || 0);
    }
  }, [createPlanDetails, planData]);
  useEffect(() => {
    initalCheck = basicDetailFields.some((value) =>
      formik.validateField(value)
    );
  }, []);
  useEffect(() => {
    const planType = formik.values.planType;
    if (planType === "ESOP") {
      setSharesAvailable(getSharesAvailable());
      formik.setFieldValue("trustShareholderId", 0);
    }
  }, [formik.values.planType]);
  useEffect(() => {
    setStepValidity(basicDetailErrors === undefined);
  }, [basicDetailErrors, initalCheck]);

  useEffect(() => {
    formik.setFieldValue(
      "conversionNumber",
      formik.values.conversionNumber / formik.values.conversionShares
    );
    formik.setFieldValue(
      "poolShares",
      formik.values.poolSize / formik.values.conversionNumber
    );
    formik.setFieldValue("conversionShares", 1);
  }, [formik.values.conversionNumber, formik.values.poolSize]);
  useEffect(() => {
    if (mode === "Edit") return;
    let poolSize = 0;
    if (formik.values.trustShareholderId !== 0) {
      poolSize =
        createPlanDetails?.trustDetails.find(
          (shareHolder) =>
            // eslint-disable-next-line eqeqeq
            shareHolder.id == formik.values.trustShareholderId
        )?.poolAvailable || 0;
    }
    setSharesAvailable(poolSize);
  }, [formik.values.trustShareholderId]);
  const getSharesAvailable = () => {
    let sharesAvailable =
      planData?.esopPlanState === "Active" ||
      planData?.esopPlanState === "Amendment"
        ? (createPlanDetails?.esopPoolCount || 0) + (planData?.totalShares || 0)
        : createPlanDetails?.esopPoolCount;
    if (mode === "Edit" && planData?.planType === "Trust")
      sharesAvailable = createPlanDetails?.esopPoolCount;
    return sharesAvailable || 0;
  };
  useEffect(() => {
    if (formik.values.planType !== "Trust") {
      setSharesAvailable(getSharesAvailable);
    }
  }, [getSharesAvailable]);
  const showMessage =
    formik.values?.poolSize > 0 && formik.values?.conversionNumber > 0;
  return (
    <>
      <VStack className="w-full px-10 py-7 gap-9 ">
        <HStack className="gap-8 ">
          <div className="flex-1">
            <Label className="text-sm font-normal">Plan Type</Label>
            <Select
              disabled={mode === "Edit"}
              options={PlanTypes}
              valueGetter={(o) => o.value}
              textGetter={(o) => o.text}
              {...formik.getFieldProps("planType")}
            />
            {touched.planType && errors.planType && (
              <Error text={errors.planType} />
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Plan Name</Label>
            <Input
              disabled={mode === "Edit"}
              type="text"
              {...formik.getFieldProps("planName")}
            />
            {touched.planName && errors.planName && (
              <Error text={errors.planName} />
            )}
          </div>
        </HStack>
        <HStack className="gap-8 ">
          <div className="flex-1">
            <Label className="text-sm font-normal">Plan Start Date</Label>
            <Input type="date" {...formik.getFieldProps("planStartDate")} />
            {touched.planStartDate && errors.planStartDate && (
              <Error text={errors.planStartDate} />
            )}
          </div>
          <div className="flex-1">
            <div className="flex items-center justify-between">
              <Label className="text-sm font-normal">
                Plan Pool Size ( No of Options)
              </Label>
              <Label className="text-xs font-normal text-green-400">
                {displaySharesAvailable} shares available{" "}
              </Label>
            </div>
            <Input type="number" {...formik.getFieldProps("poolSize")} />
            {showMessage && (
              <Label className="text-xs font-normal">
                {formik.values.poolSize} options will be converted into{" "}
                {formik.values.poolShares.toFixed(2)} shares
              </Label>
            )}
            {touched.poolSize && errors.poolSize && (
              <Error text={errors.poolSize} />
            )}
            {touched.poolSize && errors.poolShares && (
              <Error text={errors.poolShares} />
            )}
          </div>
        </HStack>
        <HStack className="gap-8 ">
          <div className="flex-1">
            <Label className="text-sm font-normal">
              Plan Term (No of Years)
            </Label>
            <Input type="number" {...formik.getFieldProps("planTerm")} />
            {touched.planTerm && errors.planTerm && (
              <Error text={errors.planTerm} />
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">
              Board Resolution Documents
            </Label>
            <div className="py-1.5">
              <FileInput
                disabled={true}
                className="cursor-no-drop"
                accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                file="Attach Board Resolution"
              />
            </div>
          </div>
        </HStack>
        <HStack className="gap-8 ">
          <div className="flex-1">
            <Label className="text-sm font-normal">Plan Description</Label>
            <TextArea {...formik.getFieldProps("planDescription")} />
            {touched.planDescription && errors.planDescription && (
              <Error text={errors.planDescription} />
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Notes</Label>
            <TextArea {...formik.getFieldProps("notes")} />
            {touched.notes && errors.notes && <Error text={errors.notes} />}
          </div>
        </HStack>
        {formik.values.planType === "Trust" && (
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">Trust Name</Label>
              <HStack className="items-center">
                {mode === "Add" ? (
                  <Select
                    options={createPlanDetails?.trustDetails || []}
                    valueGetter={(o) => o.id}
                    textGetter={(o) => o.trustName}
                    {...formik.getFieldProps("trustShareholderId")}
                  />
                ) : (
                  <Input
                    type="text"
                    className="cursor-not-allowed text-gray"
                    disabled
                    {...formik.getFieldProps("trustShareHolderName")}
                  />
                )}
              </HStack>
              {touched.trustShareholderId && errors.trustShareholderId && (
                <Error text={errors.trustShareholderId} />
              )}
            </div>
            <div className="flex-1"></div>
          </HStack>
        )}
        <HStack className="gap-8 ">
          <div className="space-y-4">
            <Label className="text-sm font-normal">Conversion Ratio</Label>
            <HStack className="items-center">
              <Input
                type="number"
                className="w-48"
                {...formik.getFieldProps("conversionNumber")}
              />
              <div className="pl-2"> options</div>
            </HStack>
            {touched.conversionNumber && errors.conversionNumber && (
              <Error text={errors.conversionNumber} />
            )}
            <HStack className="items-center">
              <Input
                type="number"
                className="w-48"
                disabled={true}
                {...formik.getFieldProps("conversionShares")}
              />
              <div className="pl-2">shares</div>
            </HStack>
            {showMessage && (
              <Label className="text-xs font-normal">
                {formik.values.conversionNumber} options will be converted into{" "}
                {formik.values.conversionShares.toFixed(2)} shares
              </Label>
            )}
          </div>
        </HStack>
      </VStack>
      <HStack className="justify-between p-9 justify">
        <ButtonPrimary1 onClick={() => onBack()}>Cancel</ButtonPrimary1>
        <div className="flex items-center">
          <ButtonPrimary
            className="min-w-[120px]"
            onClick={() => {
              if (basicDetailErrors) {
                doPartialTouch();
              } else {
                onNext();
              }
            }}
          >
            Continue
          </ButtonPrimary>
          <div>{false && <CircularProgress size={32} />}</div>
        </div>
      </HStack>
    </>
  );
}

function VestingSchedules(props: StepProps) {
  const { onBack, onNext, setStepValidity } = props;
  const { data: vestingTemplates } = useVestingTemplates();
  const vestingScheduleTemplates = vestingTemplates?.filter(
    (schedule) => !schedule.isDefault
  );
  const vestingTemplateNames = vestingScheduleTemplates?.map(
    (v) => v.vestingTemplateName
  );
  const formik = useFormikContext<AddPlanReq>();
  const { errors, touched } = formik;
  const [vestingTemplateSource, setVestingTemplateSource] = useState(
    "Select Vesting Template"
  );
  const vestingScheduleErrors = errors.vestingTemplateName;
  const vestingTemplateFields = ["vestingTemplateName"];
  function doPartialTouch() {
    formik.setFieldTouched("vestingTemplateName");
  }
  let initalCheck = false;
  useEffect(() => {
    initalCheck = vestingTemplateFields.some((value) =>
      formik.validateField(value)
    );
  }, []);
  useEffect(() => {
    setStepValidity(vestingScheduleErrors === undefined);
  }, [vestingScheduleErrors, initalCheck]);
  return (
    <>
      <VStack className="w-full px-10 py-7 gap-9 ">
        <HStack className="gap-8 ">
          <SelectButtonGroup
            options={["Select Vesting Template", "Create new Vesting Template"]}
            value={vestingTemplateSource}
            onChange={(value) => setVestingTemplateSource(value)}
          />
        </HStack>
        {vestingTemplateSource === "Select Vesting Template" && (
          <>
            <HStack className="gap-8 ">
              <div className="flex-1">
                <Label className="text-sm font-normal">Vesting Schedule</Label>
                <Select
                  options={vestingTemplateNames || []}
                  {...formik.getFieldProps("vestingTemplateName")}
                />
                {touched.vestingTemplateName && errors.vestingTemplateName && (
                  <Error text={errors.vestingTemplateName} />
                )}
              </div>
              <div className="flex-1">
                <ProjectionChart />
              </div>
            </HStack>
          </>
        )}
        {vestingTemplateSource === "Create new Vesting Template" && (
          <AddOrEditVestingTemplate
            onClose={() => {}}
            onCreate={(template) => {
              formik.setFieldValue("vestingTemplateId", template.id);
              formik.setFieldValue(
                "vestingTemplateName",
                template.vestingTemplateName
              );
              setVestingTemplateSource("Select Vesting Template");
            }}
          />
        )}
      </VStack>
      {vestingTemplateSource === "Select Vesting Template" && (
        <HStack className="justify-between p-8 justify">
          <ButtonPrimary1 onClick={() => onBack()}>Back</ButtonPrimary1>
          <div className="flex items-center">
            <ButtonPrimary
              className="min-w-[120px]"
              onClick={() => {
                if (vestingScheduleErrors) {
                  doPartialTouch();
                } else {
                  onNext();
                }
              }}
            >
              Continue
            </ButtonPrimary>
            <div>{false && <CircularProgress size={32} />}</div>
          </div>
        </HStack>
      )}
    </>
  );
}

function ExerciseWindows(props: StepProps) {
  const { onBack, onNext, setStepValidity } = props;
  const formik = useFormikContext<AddPlanReq>();
  const mutating = useIsMutating("addPlan");
  const isLoading = mutating > 0;
  const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  let initalCheck = false;
  const { touched, errors } = formik;
  const exerciseWindowFields = [
    "exercisePercentage",
    "causeDuration",
    "causePercentage",
    "convenienceDuration",
    "conveniencePercentage",
    "resignationDuration",
    "resignationPercentage",
  ];
  const exerciseWindowErrors =
    formik.values.isExercise &&
    (errors.exercisePercentage ||
      errors.causeDuration ||
      errors.causePercentage ||
      errors.convenienceDuration ||
      errors.conveniencePercentage ||
      errors.resignationDuration ||
      errors.resignationPercentage);

  function doPartialTouch() {
    formik.setFieldTouched("exercisePercentage");
    formik.setFieldTouched("causeDuration");
    formik.setFieldTouched("causePercentage");
    formik.setFieldTouched("convenienceDuration");
    formik.setFieldTouched("conveniencePercentage");
    formik.setFieldTouched("resignationDuration");
    formik.setFieldTouched("resignationPercentage");
  }
  useEffect(() => {
    initalCheck = exerciseWindowFields.some((value) =>
      formik.validateField(value)
    );
  }, []);
  useEffect(() => {
    setStepValidity(exerciseWindowErrors === undefined);
  }, [exerciseWindowErrors, initalCheck]);
  useEffect(() => {
    if (!formik.values.isExercise) {
      formik.setFieldValue("frequencyOfExercise", 0);
      formik.setFieldValue("exercisePercentage", 0);
      formik.setFieldValue("isCause", false);
      formik.setFieldValue("causeDuration", 0);
      formik.setFieldValue("causePercentage", 0);
      formik.setFieldValue("isConvenience", false);
      formik.setFieldValue("convenienceDuration", 0);
      formik.setFieldValue("conveniencePercentage", 0);
      formik.setFieldValue("isResignation", false);
      formik.setFieldValue("resignationDuration", 0);
      formik.setFieldValue("resignationPercentage", 0);
    }
  }, [formik.values.isExercise]);
  return (
    <>
      <HStack className="flex flex-row-reverse justify-end gap-4 px-10 text-sm font-semibold ">
        <SwitchButton
          className=""
          value={formik.values.isExercise}
          label="Allow Exercise"
          onClick={() => {
            formik.setFieldValue("isExercise", !formik.values.isExercise);
          }}
        />
      </HStack>
      <VStack className={`w-full gap-4 px-10 py-7 `}>
        {formik.values.planType !== "SAR" ? (
          <>
            <div className="">
              <Label className="text-sm font-semibold">
                While in Employment:
              </Label>
            </div>
            <HStack className="justify-between gap-4">
              <Box className="flex font-medium ">
                <Label className="flex w-48 text-sm">Exercise frequency</Label>
                <Select
                  disabled={!formik.values.isExercise}
                  options={options}
                  {...formik.getFieldProps("frequencyOfExercise")}
                />
              </Box>
              <Box className="flex">
                <Label className="text-sm">% of vested options</Label>
                <Input
                  {...formik.getFieldProps("exercisePercentage")}
                  disabled={!formik.values.isExercise}
                />
                {touched.exercisePercentage && errors.exercisePercentage && (
                  <Error text={errors.exercisePercentage} />
                )}
              </Box>
            </HStack>
          </>
        ) : (
          ""
        )}
        <div className="">
          <Label className="text-sm font-semibold">Separated due to:</Label>
        </div>
        <HStack className="gap-4 font-medium">
          <div className="flex flex-row-reverse w-[40%] justify-end gap-4 items-center">
            <SwitchButton
              className={!formik.values.isExercise ? "cursor-default" : ""}
              value={formik.values.isResignation}
              label="Resignation"
              onClick={() => {
                if (formik.values.isExercise) {
                  formik.setFieldValue(
                    "isResignation",
                    !formik.values.isResignation
                  );
                }
              }}
            />
          </div>
          <div className=" w-[30%]">
            <Label className="text-sm">Exercise Window (Months)</Label>
            <Input
              disabled={
                !formik.values.isResignation || !formik.values.isExercise
              }
              type="number"
              {...formik.getFieldProps("resignationDuration")}
            />
            {touched.resignationDuration && errors.resignationDuration && (
              <Error text={errors.resignationDuration} />
            )}
          </div>
          <div className=" w-[30%]">
            <Label className="text-sm">% of vested options</Label>
            <Input
              {...formik.getFieldProps("resignationPercentage")}
              disabled={
                !formik.values.isResignation || !formik.values.isExercise
              }
            />
            {touched.resignationPercentage && errors.resignationPercentage && (
              <Error text={errors.resignationPercentage} />
            )}
          </div>
        </HStack>
        <HStack className="gap-4 font-medium">
          <div className="flex flex-row-reverse w-[40%] justify-end gap-4 items-center">
            <SwitchButton
              className={!formik.values.isExercise ? "cursor-default" : ""}
              value={formik.values.isConvenience}
              label="Termination without Cause"
              onClick={() => {
                if (formik.values.isExercise) {
                  formik.setFieldValue(
                    "isConvenience",
                    !formik.values.isConvenience
                  );
                }
              }}
            />
          </div>
          <div className=" w-[30%]">
            <Label className="text-sm">Exercise Window (Months)</Label>
            <Input
              disabled={
                !formik.values.isConvenience || !formik.values.isExercise
              }
              type="number"
              {...formik.getFieldProps("convenienceDuration")}
            />
            {touched.convenienceDuration && errors.convenienceDuration && (
              <Error text={errors.convenienceDuration} />
            )}
          </div>
          <div className=" w-[30%]">
            <Label className="text-sm">% of vested options</Label>
            <Input
              {...formik.getFieldProps("conveniencePercentage")}
              disabled={
                !formik.values.isConvenience || !formik.values.isExercise
              }
            />
            {touched.conveniencePercentage && errors.conveniencePercentage && (
              <Error text={errors.conveniencePercentage} />
            )}
          </div>
        </HStack>
        <HStack className="gap-4 font-medium">
          <div className="flex flex-row-reverse w-[40%] justify-end gap-4 items-center">
            <SwitchButton
              className={!formik.values.isExercise ? "cursor-default" : ""}
              value={formik.values.isCause}
              label="Termination with Cause"
              onClick={() => {
                if (formik.values.isExercise) {
                  formik.setFieldValue("isCause", !formik.values.isCause);
                }
              }}
            />
          </div>
          <div className=" w-[30%]">
            <Label className="text-sm">Exercise Window (Months)</Label>
            <Input
              disabled={!formik.values.isCause || !formik.values.isExercise}
              type="number"
              {...formik.getFieldProps("causeDuration")}
            />
            {touched.causeDuration && errors.causeDuration && (
              <Error text={errors.causeDuration} />
            )}
          </div>
          <div className=" w-[30%]">
            <Label className="text-sm ">% of vested options</Label>
            <Input
              {...formik.getFieldProps("causePercentage")}
              disabled={!formik.values.isCause || !formik.values.isExercise}
            />
            {touched.causePercentage && errors.causePercentage && (
              <Error text={errors.causePercentage} />
            )}
          </div>
        </HStack>
        {false && (
          <HStack className="gap-4 font-medium">
            <div className=" w-[40%]">
              <Label className="text-sm">
                Upon Death or Permanent Disability
              </Label>
            </div>
            <div className=" w-[30%]">
              <Label className="text-sm">Exercise Window (Months)</Label>
              <Input
                disabled={
                  !formik.values.isResignation || !formik.values.isExercise
                }
                type="number"
                {...formik.getFieldProps("deathDuration")}
              />
            </div>
            <div className=" w-[30%]">
              <Label className="text-sm font-normal">% of vested options</Label>
              <Input
                {...formik.getFieldProps("deathPercentage")}
                disabled={
                  !formik.values.isResignation || !formik.values.isExercise
                }
              />
            </div>
          </HStack>
        )}
      </VStack>
      <HStack className="justify-between p-8 justify">
        <ButtonPrimary1 onClick={() => onBack()}>Back</ButtonPrimary1>
        <div className="flex items-center">
          <ButtonPrimary
            onClick={() => {
              if (exerciseWindowErrors && formik.values.isExercise) {
                doPartialTouch();
              }
            }}
            className="min-w-[120px]"
            type="submit"
          >
            Save
          </ButtonPrimary>
          <div>{isLoading && <CircularProgress size={32} />}</div>
        </div>
      </HStack>
    </>
  );
}

function Constructed(props: StepProps) {
  const errorMessage = useError();
  const navigate = useNavigate();
  const { onBack, onNext } = props;
  const { data: plan } = useAddPlanResult();
  const [expanded, setExpanded] = useState(true);
  const [expandedVesting, setExpandedVesting] = useState(false);
  const [expandedExercised, setExpandedExercised] = useState(false);
  const [expandedGraph, setExpandedGraph] = useState(false);
  const { mutate: changePlanState, isLoading } = usePlanStateChange();
  function handleClick() {
    if (plan && !plan.id) return;
    changePlanState(
      {
        esopPlanId: plan ? plan.id : 0,
        esopPlanState: "FOR_APPROVAL",
        dateOfTransaction: formatWithTimeZone(new Date().toDateString()),
        note: null,
      },
      {
        onSuccess: () => {
          toast("State Changed Successfully!", { type: "success" });
          onBack();
        },
        onError: (err: any) => {
          errorMessage.setMessage(err.response.data.reason);
          toast(err.response.data.reason, { type: "error", autoClose: 2000 });
        },
      }
    );
  }
  return (
    <>
      <VStack className="justify-between px-24 py-7 grow">
        {plan && (
          <>
            <HStack
              aria-label="section"
              className="py-2 border-b border-dashed "
            >
              <Box className="py-2 pr-4">
                <ExpandButton
                  expanded={expanded}
                  onClick={() => setExpanded((state) => !state)}
                ></ExpandButton>
              </Box>
              <VStack className="flex-1">
                <Box aria-label="section header" className="mb-2">
                  <h5 className="text-sm font-normal">Plan Details</h5>
                  <h6 className="text-sm font-normal text-gray-light">
                    {plan?.planName}
                  </h6>
                </Box>
                {expanded && (
                  <HStack aria-label="section body" className="flex-wrap grow">
                    {[
                      { field: "Plan Type", value: plan?.planType },
                      {
                        field: "Plan Name",
                        value: plan?.planName,
                      },
                      {
                        field: "Plan Start Date",
                        value:
                          plan?.planStartDate &&
                          formatDisplayDate(
                            new Date(plan?.planStartDate).toUTCString()
                          ),
                      },
                      { field: "Plan Pool Size", value: plan?.poolSize },
                      { field: "Plan Term", value: `${plan?.planTerm} Years` },
                      { field: "Resolution Docs", value: "" },
                      {
                        field: "Plan Description",
                        value: plan?.planDescription,
                      },
                      {
                        field: "Notes",
                        value: plan?.note,
                      },
                    ].map(({ field, value }, i) => (
                      <HStack key={i} className="w-1/2 p-1 ">
                        <span className="w-1/3 text-sm font-normal text-gray-400">
                          {field}
                        </span>
                        <span className="w-2/3 text-sm font-normal">
                          {value?.toString()}
                        </span>
                      </HStack>
                    ))}
                  </HStack>
                )}
              </VStack>
            </HStack>
            {/* vesting data */}
            <HStack
              aria-label="section"
              className="py-2 border-b border-dashed "
            >
              <Box className="py-2 pr-4">
                <ExpandButton
                  expanded={expandedVesting}
                  onClick={() => setExpandedVesting((state) => !state)}
                ></ExpandButton>
              </Box>
              <VStack className="flex-1">
                <Box aria-label="section header" className="mb-2">
                  <h5 className="text-sm font-normal">Vesting Schedule</h5>
                </Box>
                {expandedVesting && (
                  <HStack aria-label="section body" className="flex-wrap grow">
                    {[
                      {
                        field: "Vesting Interval",
                        value: plan?.vesting.vestingInterval,
                      },
                      {
                        field: "Vesting Period",
                        value: plan?.vesting.vestingPeriod,
                      },
                      {
                        field: "Vesting Schedule",
                        value: plan?.vesting.vestingTemplateName,
                      },
                      {
                        field: "Vesting Trigger Type",
                        value: plan?.vesting.vestingTriggerType,
                      },
                      {
                        field: "Vesting Type",
                        value: plan?.vesting.vestingType,
                      },
                    ].map(({ field, value }, i) => (
                      <HStack key={i} className="w-1/2 p-1 ">
                        <span className="w-1/3 text-sm font-normal text-gray-400">
                          {field}
                        </span>
                        <span className="w-2/3 text-sm font-normal">
                          {value?.toString()}
                        </span>
                      </HStack>
                    ))}
                  </HStack>
                )}
              </VStack>
            </HStack>
            {/* exercise window */}
            <HStack
              aria-label="section"
              className="py-2 border-b border-dashed "
            >
              <Box className="py-2 pr-4">
                <ExpandButton
                  expanded={expandedExercised}
                  onClick={() => setExpandedExercised((state) => !state)}
                ></ExpandButton>
              </Box>
              <VStack className="flex-1">
                <Box aria-label="section header" className="mb-2">
                  <h5 className="text-sm font-normal">Exercise Window</h5>
                </Box>
                {expandedExercised && (
                  <HStack aria-label="section body" className="flex-wrap grow">
                    {[
                      {
                        field: "Allow Exercise",
                        value: plan?.excerise.isExercise ? "Yes" : "No",
                      },
                      {
                        field: "Exercise Percentage",
                        value: (plan?.excerise.exercisePercentage || 0) * 100,
                      },

                      {
                        field: "Frequency of Exercise",
                        value: plan?.excerise.frequencyOfExercise,
                      },
                    ].map(({ field, value }, i) => (
                      <HStack key={i} className="w-1/2 p-1 ">
                        <span className="w-1/3 text-sm font-normal text-gray-400">
                          {field}
                        </span>
                        <span className="w-2/3 text-sm font-normal">
                          {value?.toString()}
                        </span>
                      </HStack>
                    ))}
                  </HStack>
                )}
              </VStack>
            </HStack>
            {/* vesting graph */}
            <HStack
              aria-label="section"
              className="py-2 border-b border-dashed "
            >
              <Box className="py-2 pr-4">
                <ExpandButton
                  expanded={expandedGraph}
                  onClick={() => setExpandedGraph((state) => !state)}
                ></ExpandButton>
              </Box>
              <VStack className="flex-1">
                <Box aria-label="section header" className="mb-2">
                  <h5 className="text-sm font-normal">Vesting Schedule</h5>
                  <h6 className="text-sm font-normal text-gray-light">
                    Expand to view Vesting Graph
                  </h6>
                </Box>
                {expandedGraph && (
                  <HStack aria-label="section body" className="flex-wrap grow">
                    <ProjectionChart />
                  </HStack>
                )}
              </VStack>
            </HStack>

            <HStack className="items-center w-full h-24 px-6 border-2 border-yellow-400 border-dashed rounded-md bg-yellow-50">
              <div className="flex justify-center mx-4 rounded-full h-11 w-11 bg-amber-200">
                <Icon
                  icon="ant-design:exclamation-outlined"
                  color="#ffc700"
                  height={42}
                />
              </div>
              <div className="mx-4">
                <p className="text-[16px] font-medium text-slate-900 ">
                  Your Plan &quot;{plan?.planName}&quot; has been{" "}
                  {plan.esopPlanState === "AMENDMENT"
                    ? "amended."
                    : "constructed."}
                </p>
                {plan.esopPlanState === "CONSTRUCTED" && (
                  <p className="text-slate-500 font-medium text-[14px]">
                    Take next step if you are fine with details above,
                    <span
                      onClick={() => handleClick()}
                      className="mx-2 underline cursor-pointer text-orange-501"
                    >
                      please send <b>For Approval</b>
                    </span>
                    {isLoading && <CircularProgress size={32} />}
                  </p>
                )}
              </div>
            </HStack>
          </>
        )}
      </VStack>
      <HStack className="justify-between p-8 justify">
        <div></div>
        <div className="flex items-center">
          <ButtonPrimary className="min-w-[120px]" onClick={() => onNext()}>
            Close
          </ButtonPrimary>
          <div>{false && <CircularProgress size={32} />}</div>
        </div>
      </HStack>
    </>
  );
}

function ProjectionChart() {
  const formik = useFormikContext<AddPlanReq>();
  const { data: _vestingTemplates } = useVestingTemplates();
  const vestingTemplates = _vestingTemplates || [];
  const template = vestingTemplates.find(
    (template) =>
      template.vestingTemplateName === formik.values.vestingTemplateName
  );
  if (template?.schedules) {
    template.schedules.forEach((s) => {
      s.percentage /= 100;
    });
  }
  const vestings = generateProjections({
    cliffPeriod: template?.cliffPeriod || 12,
    intervalUnit: "Month",
    optionsGranted: 1000,
    schedules: template?.schedules || [],
    vestingInterval: template?.vestingInterval || 0,
    vestingPeriod: template?.vestingPeriod || 0,
    vestingStartDate: new Date(new Date().getFullYear(), 0, 1),
    vestingTriggerType: template?.vestingTriggerType || VestingTriggerType.Time,
    vestingType: template?.vestingType || VestingType.Standard,
  });

  const data: ChartData<"bar", number[], unknown> = {
    labels: vestings.map((v) => format(v.date, "MMM yy")),
    datasets: [
      {
        label: "to date",
        data: vestings.map(
          (v) =>
            (v.accumulatedVestingPercentageForGrant - v.vestedPercentage) * 100
        ),
        backgroundColor: "#F8B195",
      },
      {
        label: "on date",
        data: vestings.map((v) => v.vestedPercentage * 100),
        backgroundColor: "#F67280",
        borderRadius: 4,
      },
    ],
  };

  return <BarChart data={data} />;
}
