import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import produce from "immer";
import { Select } from "../../components/shared/Select";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
} from "../../components/utils";
import {
  CompensationTemplate,
  SalaryPerGrades,
  useEsopModellingStore,
} from "../../store/esopModellingStore";
import AddNewTemplate from "./AddNewTemplate";
import { Input } from "../../components/shared/InputField";
import AddSalaryPerGradeDialog from "./AddSalaryPerGradeDialog";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import {
  useGetAllCompensationTemplates,
  useGetAllModels,
  useSaveCompensationTemplate,
  useUpdateCompensationTemplate,
} from "../../queries/esopModel";
import {
  CompensationTemplateReq,
  EsopModelReq,
} from "../../types/EsopModelling";

type StepProps = {
  onNext: () => void;
};

function CreateCompensationTemplate(props: StepProps) {
  const data = useEsopModellingStore();
  const { onNext } = props;
  const { data: esopModels } = useGetAllModels();
  const esopModelsList = esopModels?.data || [];
  const { data: _compensationTemplates } = useGetAllCompensationTemplates();
  const compensationTemplatesList = _compensationTemplates?.data || [];
  const { mutate: setTemplate } = useSaveCompensationTemplate();
  const { mutate: updateCompensationTemplate } =
    useUpdateCompensationTemplate();
  const [dialog, setDialog] = useState<{
    open: boolean;
    index?: number;
    mode?: "Edit" | "Add";
  }>({
    open: false,
    mode: "Add",
  });

  const [salaryPerGradeDialog, setSalaryPerGradeDialog] = useState<{
    open: boolean;
    index?: number;
    mode?: "Edit" | "Add";
  }>({
    open: false,
    mode: "Add",
  });

  const templateData = compensationTemplatesList.map(
    (filter: CompensationTemplateReq) => filter.templateName
  );
  function handleOnChange(template: string) {
    if (template === "Add New Template") {
      setDialog({ ...dialog, open: true, mode: "Add" });
    } else {
      const selectedTemplate = data.compensationTemplate?.filter(
        (templateItem) => templateItem?.templateName === template
      )[0];
      if (selectedTemplate) {
        setTemplateName(selectedTemplate.templateName);
        setPlanningPeriod(selectedTemplate.planningPeriod);
        setSalaryPerGrade(selectedTemplate.salaryPerGrades);
        const gradeList = selectedTemplate.salaryPerGrades.map(
          (item) => item.gradeName
        );
        setGradeList(gradeList);
      }
    }
  }

  function handleEdit(template: string) {
    setDialog({ ...dialog, open: true, mode: "Edit" });
  }

  const [templateName, setTemplateName] = useState(
    data.selectedCompensationTemplate.templateName || ""
  );
  const [planningPeriod, setPlanningPeriod] = useState(
    data.selectedCompensationTemplate.planningPeriod || 0
  );
  const [gradeList, setGradeList] = useState<string[]>(
    data.selectedCompensationTemplate.salaryPerGrades?.map(
      (item) => item.gradeName
    ) || []
  );
  const [salaryPerGrade, setSalaryPerGrade] = useState<SalaryPerGrades[]>(
    data.selectedCompensationTemplate.salaryPerGrades || []
  );

  function addSalaryPerGrades(salaryPerGrades: SalaryPerGrades[]) {
    setSalaryPerGrade(salaryPerGrades);
    const gradeListArr = salaryPerGrades.map((item) => item.gradeName);
    setGradeList(gradeListArr);
  }

  function handleAdd() {
    setSalaryPerGradeDialog({
      ...salaryPerGradeDialog,
      open: true,
      mode: "Add",
    });
  }

  function handleAction(action: Action, index: number) {
    if (action.name === "Edit") {
      setSalaryPerGradeDialog({
        ...salaryPerGradeDialog,
        open: true,
        mode: "Edit",
        index,
      });
    } else if (action.name === "Delete") {
      const tempSalaryPerGrades = [...salaryPerGrade];
      tempSalaryPerGrades.splice(index, 1);
      setSalaryPerGrade(tempSalaryPerGrades);
    }
  }

  function handlePrimaryActionAddTemplate(
    compensationTemplate: CompensationTemplate
  ) {
    addSalaryPerGrades(compensationTemplate.salaryPerGrades);
    setTemplateName(compensationTemplate.templateName);
    data.setCompensationTemplate(compensationTemplate);
    data.setSelectedCompensationTemplate(compensationTemplate);
  }

  function handlePrimaryActionEditTemplate(
    compensationTemplate: CompensationTemplate
  ) {
    addSalaryPerGrades(compensationTemplate.salaryPerGrades);
    setTemplateName(compensationTemplate.templateName);
  }

  function handlePrimaryActionAddSalaryPerGrade(
    salaryPerGradeItem: SalaryPerGrades
  ) {
    const objIndex = salaryPerGrade.findIndex(
      (item, index) => salaryPerGradeItem.gradeName === item.gradeName
    );
    setSalaryPerGrade(
      produce((salaryPerGrade: SalaryPerGrades[]) => {
        salaryPerGrade.map((item, index) => {
          if (index === objIndex) {
            item.minSalary = salaryPerGradeItem.minSalary;
            item.maxSalary = salaryPerGradeItem.maxSalary;
            item.optionMultiplier = salaryPerGradeItem.optionMultiplier;
            item.salary =
              (salaryPerGradeItem.minSalary + salaryPerGradeItem.maxSalary) / 2;
          }
          return item;
        });
      })
    );
    const compensationTemplateObj = {
      note: "",
      templateName,
      planningPeriod,
      salaryPerGrades: salaryPerGrade,
    };
    data.setCompensationTemplate(compensationTemplateObj);
  }

  function handleSubmit() {
    if (planningPeriod < 1) {
      toast("Planning Period should be atleast 1", {
        type: "error",
        autoClose: 2000,
      });
      return;
    }
    if (templateName === "") {
      toast("Please Select Template", {
        type: "error",
        autoClose: 2000,
      });
      return;
    }
    const compensationTemplateObj = {
      note: "",
      templateName,
      planningPeriod,
      salaryPerGrades: salaryPerGrade,
    };
    data.setCompensationTemplate(compensationTemplateObj);
    data.setSelectedCompensationTemplate(compensationTemplateObj);

    const existingTemplate = compensationTemplatesList.filter(
      (template: CompensationTemplateReq) =>
        template.templateName === templateName
    )[0];

    if (existingTemplate) {
      const existingTemplateInModel = esopModelsList.filter(
        (model: EsopModelReq) =>
          model.compensationTemplateId === existingTemplate.id
      )[0];

      if (existingTemplateInModel) {
        toast("Template already in use. Please change the name", {
          type: "error",
          autoClose: 2000,
        });
      } else {
        existingTemplate.compensationTemplateDetails = compensationTemplateObj;
        updateCompensationTemplate(existingTemplate, {
          onSuccess: () => {
            toast("Saved Successfully!", {
              type: "success",
              autoClose: 2000,
            });
          },
          onError: (err: any) => {
            toast(err.response.data.reason, {
              type: "error",
              autoClose: 2000,
            });
          },
        });
        onNext();
      }
    } else {
      const templateReqObj = {
        note: compensationTemplateObj.note,
        templateName: compensationTemplateObj.templateName,
        description: "",
        compensationTemplateDetails: compensationTemplateObj,
      };

      setTemplate(templateReqObj, {
        onSuccess: () => {
          toast("Saved Successfully!", {
            type: "success",
            autoClose: 2000,
          });
        },
        onError: (err: any) => {
          toast(err.response.data.reason, {
            type: "error",
            autoClose: 2000,
          });
        },
      });
      onNext();
    }
  }

  return (
    <Box className="w-78 p-4 bg-white rounded-lg ">
      <VStack className="justify-between gap-9 p-4">
        <VStack className="justify-between gap-2">
          <p className="text-lg font-medium text-gray-dark">
            Compensation Template
          </p>
          <p className="text-s font-medium text-gray-light">
            Use the template to structure the compensation payable in the form
            of stock options to different grades of employees over the course of
            the ESOP plan. The drop down provides the option to choose an in
            built template or add a new template to customize the details in
            line with the company needs.
          </p>
        </VStack>
        <HStack className="justify-start">
          <p className={` form-label text-sm font-medium w-1/3 mt-3`}>
            Compensation Template
          </p>
          <Box className="w-1/3 flex items-start ml-2">
            <Select
              options={["Add New Template", ...templateData]}
              value={templateName}
              onChange={(e) => handleOnChange(e.target.value)}
            />
            {templateName && templateName !== "Add New Template" && (
              <button type="button" onClick={(e) => handleEdit(templateName)}>
                {" "}
                <Icon
                  icon={"eva:edit-outline"}
                  className="absolute w-6 h-6 text-gray-400 bg-gray-100 rounded-full cursor-pointer m-2 hover:text-black"
                />
              </button>
            )}
          </Box>
        </HStack>
        <Dialog open={dialog.open} maxWidth="lg">
          {dialog.mode === "Add" && (
            <AddNewTemplate
              onPrimaryAction={(compensationTemplate: CompensationTemplate) => {
                handlePrimaryActionAddTemplate(compensationTemplate);
                setDialog({ ...dialog, open: false });
              }}
              onClose={() => {
                setDialog({ ...dialog, open: false });
              }}
              mode="Add"
            />
          )}
          {dialog.mode === "Edit" && (
            <AddNewTemplate
              onPrimaryAction={(compensationTemplate: CompensationTemplate) => {
                handlePrimaryActionEditTemplate(compensationTemplate);
                setDialog({ ...dialog, open: false });
              }}
              onClose={() => {
                setDialog({ ...dialog, open: false });
              }}
              templateName={templateName}
              salaryPerGrades={salaryPerGrade}
              planningPeriod={planningPeriod}
              mode="Edit"
            />
          )}
        </Dialog>
        <HStack className="justify-start">
          <p className={` form-label text-sm font-medium w-1/3 mt-3 `}>
            Planning Period (Years)
          </p>
          <Box className="w-1/3 flex items-start ml-2">
            <Input
              type="number"
              placeholder="Planning Period"
              className="w-48"
              value={planningPeriod}
              onChange={(e) => setPlanningPeriod(e.target.valueAsNumber)}
            />
          </Box>
        </HStack>
        <HStack className="justify-between">
          <Box className="max-h-full overflow-auto">
            <table className="w-[600px]  table-space">
              <thead className="text-xs font-medium text-gray-light">
                <tr className="border-b border-dashed ">
                  <td className="py-3 hover:cursor-pointer">Grades</td>
                  <td className="py-3 hover:cursor-pointer">Salary</td>
                  <td className="py-3 hover:cursor-pointer">
                    Option Multiplier (%)
                  </td>
                  <td className="py-3 hover:cursor-pointer"></td>
                </tr>
              </thead>
              <tbody className="font-medium text-dark">
                {salaryPerGrade?.map((employeesDetail, index) => (
                  <tr
                    key={`${employeesDetail.gradeName}-${index}`}
                    className=" border-b  border-dashed cursor-pointer hover:bg-slate-50"
                  >
                    <td className="p-2">{employeesDetail.gradeName}</td>
                    {employeesDetail.salary > 0 ? (
                      <td className="p-2">
                        {employeesDetail.minSalary} -{" "}
                        {employeesDetail.maxSalary}
                      </td>
                    ) : (
                      <td className="p-2"></td>
                    )}
                    {employeesDetail.optionMultiplier > 0 ? (
                      <td className="p-2">
                        {employeesDetail.optionMultiplier}
                      </td>
                    ) : (
                      <td className="p-2"></td>
                    )}
                    <td className="px-2 py-4 align-top">
                      <CTADropdown
                        actions={[{ name: "Edit" }, { name: "Delete" }]}
                        onAction={(action) => handleAction(action, index)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        </HStack>
        {gradeList.length > 0 && (
          <ButtonPrimary
            className="min-w-max w-1/5"
            onClick={() => {
              handleAdd();
            }}
          >
            ADD
          </ButtonPrimary>
        )}
        <Dialog open={salaryPerGradeDialog.open} maxWidth="lg">
          {salaryPerGradeDialog.mode === "Add" && (
            <AddSalaryPerGradeDialog
              onPrimaryAction={(salaryPerGradeItem: SalaryPerGrades) => {
                handlePrimaryActionAddSalaryPerGrade(salaryPerGradeItem);
                setSalaryPerGradeDialog({
                  ...salaryPerGradeDialog,
                  open: false,
                });
              }}
              onClose={() => {
                setSalaryPerGradeDialog({
                  ...salaryPerGradeDialog,
                  open: false,
                });
              }}
              data={salaryPerGrade[salaryPerGrade.length]}
              gradeList={gradeList}
            />
          )}

          {salaryPerGradeDialog.mode === "Edit" && (
            <AddSalaryPerGradeDialog
              onPrimaryAction={(salaryPerGradeItem: SalaryPerGrades) => {
                handlePrimaryActionAddSalaryPerGrade(salaryPerGradeItem);
                setSalaryPerGradeDialog({
                  ...salaryPerGradeDialog,
                  open: false,
                });
              }}
              onClose={() => {
                setSalaryPerGradeDialog({
                  ...salaryPerGradeDialog,
                  open: false,
                });
              }}
              data={salaryPerGrade[salaryPerGradeDialog.index || 0]}
              gradeList={gradeList}
            />
          )}
        </Dialog>
        <HStack className="justify-end pt-12">
          <ButtonPrimary
            type="submit"
            onClick={() => {
              handleSubmit();
            }}
          >
            Next
          </ButtonPrimary>
        </HStack>
      </VStack>
    </Box>
  );
}

export default CreateCompensationTemplate;
