import create from "zustand";

export type TransactionIndexState = {
  index: number;
  setIndex: (index: number) => void;
  reset: () => void;
};

const initialState = {
  index: 0,
};

const transactionIndexStore = create<TransactionIndexState>((set) => ({
  ...initialState,
  setIndex: (indexValue) => {
    set((state) => ({ index: indexValue }));
  },
  reset: () => {
    set(() => initialState);
  },
}));

export const useTransactionIndexStore = transactionIndexStore;
