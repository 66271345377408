import randomColor from "randomcolor";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Box, HStack, VStack } from "../../components/utils";
import { DoughnutChart } from "../../shared/DoughnutChart";
import {
  InstrumentChartModel,
  RoundNameChartModel,
  ShareHolderChartModel,
} from "../../types/HistoricOverView";
import { getRandomColor } from "../../utils/historicUtilities";
import { useHistoricChartStore } from "../../store/chartStore";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";

ChartJS.register(ArcElement, Tooltip, Legend);

export function RoundChartCard() {
  const currency = getCurrencyType();
  const roundChart = useHistoricChartStore();
  const _roundData = roundChart.round || [];
  const values = _roundData.map((roundDetail) => [
    roundDetail.roundInvestmentAmount,
    roundDetail.roundName,
  ]);
  const investmentValues = values.map((value) => value[0]);
  const currencySymbol = getCurrencySymbol();
  const roundNames = values.map((value) => value[1]);
  const backgroundColor = getRandomColor(roundNames as string[]);
  const roundChartData = {
    labels: roundNames,
    datasets: [
      {
        label: "Round Chart",
        data: investmentValues,
        backgroundColor,
        borderColor: backgroundColor,
      },
    ],
  };

  return (
    <VStack className={"p-6 bg-white rounded-lg max-h-96"}>
      <ChartHeader
        header="Rounds"
        subheader="rounds"
        count={_roundData.length.toLocaleString(currency)}
      />
      <Box className="w-50 h-70 align-center">
        {_roundData && <DoughnutChart data={roundChartData} />}
      </Box>
      <VStack className="overflow-auto justify-between">
        {_roundData.map((plan) =>
          ChartContent(
            plan.roundName,
            plan.roundInvestmentAmount.toLocaleString(currency),
            currencySymbol
          )
        )}
      </VStack>
    </VStack>
  );
}

export function InstrumentChartCard() {
  const securitiesChart = useHistoricChartStore();
  const _securitiesChartData = securitiesChart.instrument || [];
  const values = _securitiesChartData.map((securitiesChartDetail) => [
    securitiesChartDetail.holdingPercentage * 100,
    securitiesChartDetail.instrumentClassName,
  ]);
  const currency = getCurrencyType();

  const holdingPercentageValues = values.map((value) => value[0]);
  const instrumentNames = values.map((value) => value[1]);
  const backgroundColor = getRandomColor(instrumentNames as string[]);
  const instrumentChartData = {
    labels: instrumentNames,
    datasets: [
      {
        label: "Securities Chart",
        data: holdingPercentageValues,
        backgroundColor,
        borderColor: backgroundColor,
      },
    ],
  };

  return (
    <VStack className={"p-6 bg-white rounded-lg max-h-96"}>
      <ChartHeader
        header="Securities"
        subheader="securities"
        count={_securitiesChartData.length.toLocaleString(currency)}
      />
      <Box className="w-50 h-70 align-center">
        {_securitiesChartData && <DoughnutChart data={instrumentChartData} />}
      </Box>
      <VStack className="overflow-auto justify-between">
        {_securitiesChartData.map((plan) =>
          ChartContent(
            plan.instrumentClassName,
            (plan.holdingPercentage * 100).toLocaleString(currency),
            "%"
          )
        )}
      </VStack>
    </VStack>
  );
}

export function ShareHolderChartCard() {
  const shareHolderChart = useHistoricChartStore();
  const currency = getCurrencyType();
  const _shareHolderChartData = shareHolderChart.shareHolder || [];
  const values = _shareHolderChartData.map((shareHolderChartDetail) => [
    shareHolderChartDetail.holdingPercentage * 100,
    shareHolderChartDetail.name,
  ]);
  const holdingPercentageValues = values.map((value) => value[0]);
  const shareHolderNames = values.map((value) => value[1]);
  const backgroundColor = getRandomColor(shareHolderNames as string[]);
  const planData = {
    labels: shareHolderNames,
    datasets: [
      {
        label: "Shareholders",
        data: holdingPercentageValues,
        backgroundColor,
        borderColor: backgroundColor,
      },
    ],
  };

  return (
    <VStack className={"p-6 bg-white rounded-lg max-h-96"}>
      <ChartHeader
        header="Shareholders"
        subheader="shareholders"
        count={_shareHolderChartData.length.toLocaleString(currency)}
      />
      <Box className="w-50 h-70 align-center">
        {_shareHolderChartData && <DoughnutChart data={planData} />}
      </Box>
      <VStack className="overflow-auto justify-between">
        {_shareHolderChartData.map((plan) =>
          ChartContent(
            plan.name,
            (plan.holdingPercentage * 100).toLocaleString(currency),
            "%"
          )
        )}
      </VStack>
    </VStack>
  );
}

function ChartContent(identifier: string, value: string, unit: string) {
  const backgroundColor = randomColor({
    luminosity: "bright",
    alpha: 0.3,
    seed: identifier,
  });
  return (
    <HStack key={identifier} className="justify-between p-2">
      <HStack>
        <Box style={{ backgroundColor }} className="w-4 h-4 mt-2 rounded"></Box>
        <VStack className="pl-4">
          <p className="font-medium w-20 text-xs2 text-gray-dark">
            {identifier}
          </p>
          <p className="text-xs1 font-semi-medium text-gray-light">
            {value} {unit}
          </p>
        </VStack>
      </HStack>
    </HStack>
  );
}

function ChartHeader({
  header,
  subheader,
  count,
}: {
  header: string;
  subheader: string;
  count: string;
}) {
  return (
    <VStack className="mb-4">
      <p className="font-medium text-gray-dark">{header}</p>
      <p className="text-xs1 font-semi-medium text-gray-light">
        {count} {subheader}
      </p>
    </VStack>
  );
}
