import { useFormikContext } from "formik";
import { toast } from "react-toastify";
import { useContext } from "react";
import { Input } from "../../components/shared/InputField";
import {
  Box,
  HStack,
  VStack,
  Error,
  ButtonPrimary,
  ButtonPrimary1,
  isEsopViewer,
} from "../../components/utils";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { SiteSettingsRes } from "../../types/SiteSettings";
import EmailToggles from "./EmailToggles";
import { SiteSettingsContext } from "./SiteSettings";

function EmailSettings() {
  const formik = useFormikContext<SiteSettingsRes>();
  const isUserEsopViewer = isEsopViewer();
  const isError = useContext(SiteSettingsContext);
  const { values, errors, touched, getFieldProps, setFieldValue } = formik;
  const emailSettingsErrors =
    errors.communicationEmailId ||
    errors.senderNameForEmail ||
    errors.emailsToBeSent ||
    errors.reminderEmailsToBeSent ||
    errors.reminderEmailsStartFrom ||
    errors.reminderEmailsInterval ||
    errors.employeeInactivityLogout ||
    errors.approverMailToAdmin ||
    errors.isAttachmentEnabled;
  function doPartialTouch() {
    formik.setFieldTouched("communicationEmailId");
    formik.setFieldTouched("senderNameForEmail");
    formik.setFieldTouched("emailsToBeSent");
    formik.setFieldTouched("reminderEmailsToBeSent");
    formik.setFieldTouched("reminderEmailsStartFrom");
    formik.setFieldTouched("reminderEmailsInterval");
    formik.setFieldTouched("employeeInactivityLogout");
    formik.setFieldTouched("approverMailToAdmin");
    formik.setFieldTouched("isAttachmentEnabled");
  }

  return (
    <Box className="md:w-[425px] lg:w-[850px] p-4 bg-white rounded-lg overflow-auto">
      <Box className="max-h-[550px] overflow-auto">
        <VStack className="justify-between p-4 gap-9">
          <Box className="items-center mt-6 text-lg font-medium text-gray-dark">
            Email Settings
          </Box>
          <HStack className="gap-10 pb-6">
            <VStack>
              <HStack className="flex-1">
                <p className={` form-label text-sm font-medium  mt-4 mr-2`}>
                  Email Sender Name Address
                </p>

                <Input
                  type="text"
                  placeholder="Email Address"
                  className="lg:w-48 md:w-32"
                  {...formik.getFieldProps("communicationEmailId")}
                  disabled={!formik.values.emailsToBeSent}
                />
              </HStack>
              <HStack className="justify-end">
                {touched?.communicationEmailId &&
                  errors?.communicationEmailId && (
                    <Error text={errors?.communicationEmailId} />
                  )}
              </HStack>
            </VStack>
            <VStack>
              <HStack>
                <p className={` form-label text-sm font-medium mt-4 mr-2`}>
                  Email Sender Name
                </p>
                <Input
                  type="text"
                  placeholder="Email Sender Name"
                  className="lg:w-48 md:w-32"
                  {...formik.getFieldProps("senderNameForEmail")}
                  disabled={!formik.values.emailsToBeSent}
                />
              </HStack>
              <HStack className="justify-end">
                {touched?.senderNameForEmail && errors?.senderNameForEmail && (
                  <Error text={errors?.senderNameForEmail} />
                )}
              </HStack>
            </VStack>
          </HStack>
          <HStack className="justify-between">
            <p className={` form-label text-sm font-medium w-2/3 `}>
              Reminder Emails
            </p>
            <Box className="flex justify-end w-1/3 pr-20 ml-2">
              <SwitchButton
                onClick={() => {
                  formik.setFieldValue(
                    "reminderEmailsToBeSent",
                    !formik.values.reminderEmailsToBeSent
                  );
                }}
                className=""
                value={formik.values.reminderEmailsToBeSent}
                label=""
              />
            </Box>
          </HStack>
          {formik.values.reminderEmailsToBeSent && (
            <VStack className="gap-4">
              <HStack>
                <p className="mt-4 ml-6 text-sm font-medium form-label">
                  Start reminder Emails after
                </p>
                <VStack>
                  <Input
                    type="text"
                    placeholder="No. of days"
                    className="m-1 w-36"
                    {...formik.getFieldProps("reminderEmailsStartFrom")}
                  />
                  {touched?.reminderEmailsStartFrom &&
                    errors?.reminderEmailsStartFrom && (
                      <Error text={errors?.reminderEmailsStartFrom} />
                    )}
                </VStack>
                <p className="mt-4 ml-1 text-sm font-medium form-label">Days</p>
              </HStack>
              <HStack>
                <p className="mt-4 ml-6 text-sm font-medium form-label">
                  Set reminder emails interval to
                </p>
                <VStack>
                  <Input
                    type="text"
                    placeholder="No. of days"
                    className="m-1 w-36"
                    {...formik.getFieldProps("reminderEmailsInterval")}
                  />
                  {touched?.reminderEmailsInterval &&
                    errors?.reminderEmailsInterval && (
                      <Error text={errors?.reminderEmailsInterval} />
                    )}
                </VStack>
                <p className="mt-4 ml-1 text-sm font-medium form-label">Days</p>
              </HStack>
            </VStack>
          )}
          <HStack className="justify-between">
            <p className={` form-label text-sm font-medium w-2/3 `}>
              Allow admin to deactivate inactive Employee accounts
            </p>
            <Box className="flex justify-end w-1/3 pr-20 ml-2">
              <SwitchButton
                onClick={() => {
                  formik.setFieldValue(
                    "employeeInactivityLogout",
                    !formik.values.employeeInactivityLogout
                  );
                }}
                className=""
                value={formik.values.employeeInactivityLogout}
                label=""
              />
            </Box>
          </HStack>
          <HStack className="justify-between">
            <p className={` form-label text-sm font-medium w-2/3 `}>
              Allow admin to receive all approver Emails
            </p>
            <Box className="flex justify-end w-1/3 pr-20 ml-2">
              <SwitchButton
                onClick={() => {
                  formik.setFieldValue(
                    "approverMailToAdmin",
                    !formik.values.approverMailToAdmin
                  );
                }}
                className=""
                value={formik.values.approverMailToAdmin}
                label=""
              />
            </Box>
          </HStack>
          <HStack className="justify-between">
            <p className={` form-label text-sm font-medium w-2/3 `}>
              Allow Employees to receive attachment in the offer letter
            </p>
            <Box className="flex justify-end w-1/3 pr-20 ml-2">
              <SwitchButton
                onClick={() => {
                  formik.setFieldValue(
                    "isAttachmentEnabled",
                    !formik.values.isAttachmentEnabled
                  );
                }}
                className=""
                value={formik.values.isAttachmentEnabled}
                label=""
              />
            </Box>
          </HStack>
        </VStack>
        <EmailToggles />
      </Box>

      {!isUserEsopViewer && (
        <HStack className="justify-between mt-12">
          <ButtonPrimary1 type="reset" className="mr-8 text-red-500">
            Cancel
          </ButtonPrimary1>
          <ButtonPrimary disabled={isError} type="submit">
            Save
          </ButtonPrimary>
        </HStack>
      )}
    </Box>
  );
}

export default EmailSettings;
