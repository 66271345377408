import { useFormikContext } from "formik";
import { useContext } from "react";
import { Input } from "../../components/shared/InputField";
import {
  Box,
  HStack,
  VStack,
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  isEsopViewer,
} from "../../components/utils";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { SiteSettingsRes } from "../../types/SiteSettings";
import { SiteSettingsContext } from "./SiteSettings";
import { getCurrencySymbol } from "../../utils/currencyFormatter";

function EmployeePortalSettings() {
  const formik = useFormikContext<SiteSettingsRes>();
  const isUserEsopViewer = isEsopViewer();
  const currencySymbol = getCurrencySymbol();
  const isError = useContext(SiteSettingsContext);
  const { values, errors, touched, getFieldProps, setFieldValue } = formik;
  const employeePortalErrors =
    errors.showPPSToEmployees ||
    errors.showPlanToEmployees ||
    errors.showEmpPortalValuesForecast ||
    errors.ppsForEmployee;
  function doPartialTouch() {
    formik.setFieldTouched("showPPSToEmployees");
    formik.setFieldTouched("showPlanToEmployees");
    formik.setFieldTouched("showEmpPortalValuesForecast");
    formik.setFieldTouched("ppsForEmployee");
  }
  return (
    <Box className="lg:w-[850px] md:w-[425px] p-4 bg-white rounded-lg ">
      <VStack className="gap-9 justify-between p-4">
        <Box className="text-gray-dark items-center mt-6 text-lg font-medium">
          Employee Portal Settings
        </Box>
        <HStack className="justify-between">
          <p className={` form-label text-sm font-medium w-1/3 mt-4`}>
            Price Per Share ({currencySymbol})
          </p>
          <VStack className="w-1/3">
            <Input
              type="number"
              placeholder="Price Per Share"
              className="lg:w-48 md:w-32"
              disabled={!values.showPPSToEmployees}
              {...getFieldProps("ppsForEmployee")}
            />
            {touched.ppsForEmployee && errors.ppsForEmployee && (
              <Error text={errors.ppsForEmployee} />
            )}
          </VStack>
        </HStack>
        <HStack className="justify-between">
          <p className={` form-label text-sm font-medium w-2/3 `}>
            Display Price Per Share on Employee portal
          </p>
          <Box className="flex items-start w-1/3 ml-2">
            <SwitchButton
              onClick={() => {
                setFieldValue("showPPSToEmployees", !values.showPPSToEmployees);
              }}
              value={values?.showPPSToEmployees || false}
              label=""
            />
          </Box>
        </HStack>
        <HStack className="justify-between">
          <p className={` form-label text-sm font-medium w-2/3 `}>
            Display forecast values in Employee portal
          </p>
          <Box className="flex items-start w-1/3 ml-2">
            <SwitchButton
              onClick={() => {
                setFieldValue(
                  "showEmpPortalValuesForecast",
                  !values.showEmpPortalValuesForecast
                );
              }}
              className=""
              value={values.showEmpPortalValuesForecast}
              label=""
            />
          </Box>
        </HStack>
        <HStack className="justify-between">
          <p className={` form-label text-sm font-medium w-2/3 `}>
            Show Plan to Employee
          </p>
          <Box className="flex items-start w-1/3 ml-2">
            <SwitchButton
              onClick={() => {
                setFieldValue(
                  "showPlanToEmployees",
                  !values.showPlanToEmployees
                );
              }}
              className=""
              value={values.showPlanToEmployees || false}
              label=""
            />
          </Box>
        </HStack>
        <HStack className="justify-between">
          <p className={` form-label text-sm font-medium w-2/3 `}>
            Mandatory Nominee Information for Employees
          </p>
          <Box className="flex items-start w-1/3 ml-2">
            <SwitchButton
              onClick={() => {
                setFieldValue("isNomineeMandatory", !values.isNomineeMandatory);
              }}
              className=""
              value={values.isNomineeMandatory || false}
              label=""
            />
          </Box>
        </HStack>
        <HStack className="justify-between">
          <p className={` form-label text-sm font-medium w-2/3 `}>
            Show Value View To Employee
          </p>
          <Box className="flex items-start w-1/3 ml-2">
            <SwitchButton
              onClick={() => {
                setFieldValue("isValueView", !values.isValueView);
              }}
              className=""
              value={values.isValueView || false}
              label=""
            />
          </Box>
        </HStack>
        <HStack className="justify-between">
          <p className={` form-label text-sm font-medium w-2/3 `}>
            Allow Employee to Download Grant Letter
          </p>
          <Box className="flex items-start w-1/3 ml-2">
            <SwitchButton
              onClick={() => {
                setFieldValue(
                  "enableGrantLetterDownload",
                  !values.enableGrantLetterDownload
                );
              }}
              className=""
              value={values.enableGrantLetterDownload || false}
              label=""
            />
          </Box>
        </HStack>
      </VStack>
      {!isUserEsopViewer && (
        <HStack className="justify-between mt-12">
          <ButtonPrimary1 type="reset" className="mr-8 text-red-500">
            Cancel
          </ButtonPrimary1>
          <ButtonPrimary
            disabled={isError}
            onClick={() => {
              if (employeePortalErrors) {
                doPartialTouch();
              }
            }}
            type="submit"
          >
            Save
          </ButtonPrimary>
        </HStack>
      )}
    </Box>
  );
}

export default EmployeePortalSettings;
