import { Box, HStack, VStack } from "../../components/utils";
import { useEmployeesDepartment } from "../../queries";
import { optionsDepartment } from "../../shared/chart-data";
import useIsMobile from "../../utils/detectDevice";
import { DepartmentChart } from "./DepartmentChart";

const Department = (props: { title: string | number }) => {
  const { data: department } = useEmployeesDepartment();
  const data = department?.departmentEmployeeCountDtos || [];
  data.sort((a, b) => a.numberOfEmployees - b.numberOfEmployees);
  const departmentData = data.map((dept) => dept.numberOfEmployees);
  const departmentLabel = data.map((dept) => dept.department);
  const departmentCount = departmentData.slice(-5);
  const othersCount =
    departmentData.length > 5
      ? [
          ...departmentCount,
          departmentData.slice(0, -5).reduce((a, b) => a + b, 0),
        ]
      : departmentData.slice(-5);
  const chartLabels =
    departmentLabel.length > 5
      ? [...departmentLabel.slice(-5), "Others"]
      : departmentLabel;
  const departmentChartData = {
    labels: chartLabels,
    datasets: [
      {
        label: "# of Departments",
        data: othersCount,
        backgroundColor: [
          "#B74D56",
          "rgba(255,168,0,1)",
          "#D97A57",
          "#70B6FE",
          "#50CD89",
          "#EF5B2D",
        ],
        borderColor: [
          "#B74D56",
          "rgba(255,168,0,1)",
          "#D97A57",
          "#70B6FE",
          "#50CD89",
          "#EF5B2D",
        ],
      },
    ],
  };
  const { isMobile } = useIsMobile();
  return (
    <VStack className="space-x-4 bg-white rounded-lg">
      <VStack className="p-4 mb-4">
        <p className="font-medium text-gray-dark">{props.title}</p>
        <p className="text-xs1 font-semi-medium text-gray-light"></p>
      </VStack>
      <Box className={`h-80 align-center ${isMobile ? "w-80" : "w-100"}`}>
        {department && (
          <DepartmentChart
            data={departmentChartData}
            options={optionsDepartment}
            length={departmentData.length}
          />
        )}
      </Box>
    </VStack>
  );
};

export default Department;
