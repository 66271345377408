import { useFormikContext } from "formik";
import { useContext, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import produce from "immer";
import { toast } from "react-toastify";
import {
  Box,
  HStack,
  VStack,
  ButtonPrimary,
  ButtonPrimary1,
  ButtonSecondary,
  Error,
  isEsopViewer,
} from "../../components/utils";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { SignerDetails, SiteSettingsRes } from "../../types/SiteSettings";
import { Input, Label } from "../../components/shared/InputField";

import { Select } from "../../components/shared/Select";
import { useGetAllCompanyUsers } from "../../queries/siteSettings";
import { SiteSettingsContext } from "./SiteSettings";

function ESignSettings() {
  const formik = useFormikContext<SiteSettingsRes>();
  const isUserEsopViewer = isEsopViewer();
  const disableDocuSign = false;
  const isError = useContext(SiteSettingsContext);
  const { values, errors, touched, getFieldProps, setFieldValue } = formik;
  const eSignSettingsErrors =
    errors.isEsignEnabled || errors.isDocuSignEnabled || errors.signerDetails;
  function doPartialTouch() {
    formik.setFieldTouched("isEsignEnabled");
    formik.setFieldTouched("isDocuSignEnabled");
    formik.setFieldTouched("signerDetails");
  }

  const { data: _userData } = useGetAllCompanyUsers();
  const userNames = _userData;
  const [availableSigners, setAvailableSigners] = useState<
    Array<SignerDetails>
  >(formik.values.signerDetails);
  const baseSigner: SignerDetails = {
    name: "",
    email: "",
    signerType: "OPTIONS",
    toBeAdded: true,
  };
  function handleAdd() {
    const signers = [...values.signerDetails];
    const newSigner = baseSigner;
    signers.push(newSigner);
    setFieldValue("signerDetails", signers);
  }

  function handleEdit(
    index: number,
    changedField: keyof SignerDetails,
    changeEvent: any
  ) {
    setAvailableSigners(
      produce((availableSigners) => {
        const signer = availableSigners[index];
        signer.toBeUpdated = true;
        if (changedField === "email" || changedField === "name")
          signer[changedField] = changeEvent.target.value;
      })
    );
    formik.setFieldValue(`signerDetails[${index}].toBeUpdated`, true);
  }

  function handleDelete(deleteIndex: number) {
    const signerDetails = [...values.signerDetails];
    signerDetails.forEach((signer, index) => {
      if (deleteIndex === index) {
        if (signer.toBeAdded) signerDetails.splice(deleteIndex, 1);
        signer.toBeDeleted = true;
      }
    });
    setFieldValue("signerDetails", signerDetails);
  }
  function userSelected(selectedUserId: number, signerIndex: number) {
    const selectedUser = _userData?.find(
      (user) => user.userId === selectedUserId
    );
    formik.setFieldValue(
      `signerDetails[${signerIndex}].name`,
      selectedUser?.fullName
    );
    formik.setFieldValue(
      `signerDetails[${signerIndex}].email`,
      selectedUser?.emailId
    );
    formik.setFieldValue(
      `signerDetails[${signerIndex}].userId`,
      selectedUser?.userId
    );
  }
  return (
    <Box className="lg:w-[850px] md:w-[425px] p-4 bg-white rounded-lg ">
      {disableDocuSign && (
        <VStack className="gap-9 justify-between p-4">
          <Box className="text-gray-dark items-center mt-6 text-lg font-medium">
            DocuSign Settings
          </Box>
          <HStack className="justify-between">
            <p className={` form-label text-sm font-medium w-2/3 `}>
              Employees should be allowed to E-sign
            </p>
            <Box className="flex items-start w-1/3 ml-2">
              <SwitchButton
                className=""
                value={formik.values.isDocuSignEnabled}
                label=""
                onClick={() => {
                  formik.setFieldValue(
                    "isDocuSignEnabled",
                    !formik.values.isDocuSignEnabled
                  );
                }}
              />
            </Box>
          </HStack>
          {formik.values.isDocuSignEnabled && (
            <>
              <HStack>
                <p className={` form-label text-sm font-medium w-2/3 `}>
                  Signers for Options
                </p>
              </HStack>
              <VStack aria-label="header" className="gap-y-2 px-4">
                {values.signerDetails.length > 0 && (
                  <HStack className="gap-x-8 mt-4">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">User</Label>
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">Name</Label>
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">E-Mail</Label>
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Signature Type
                      </Label>
                    </div>
                    <div className="flex-1"></div>
                  </HStack>
                )}
                <VStack className="max-h-72 justify-between gap-4 overflow-y-auto">
                  {values.signerDetails?.map((signer, signerIndex: number) => {
                    const newSigner = values?.signerDetails[signerIndex];
                    const fieldTouched = touched?.signerDetails?.[signerIndex];
                    const err = errors?.signerDetails?.[signerIndex] as any;
                    return (
                      <HStack
                        className="gap-4"
                        key={`${signer.name}${signerIndex}`}
                      >
                        <div className="flex-1">
                          <Select
                            options={userNames || []}
                            textGetter={(e) => e.fullName}
                            valueGetter={(e) => e.userId}
                            value={newSigner.userId}
                            onChange={(e) => {
                              setFieldValue(
                                `signerDetail[${signerIndex}].userId`,
                                e.target.value
                              );
                              userSelected(Number(e.target.value), signerIndex);
                            }}
                          />
                        </div>
                        <div className="flex-1">
                          <Input
                            type="text"
                            className={`${
                              formik.values.signerDetails[signerIndex]
                                ?.userId != null
                                ? "cursor-not-allowed"
                                : null
                            }`}
                            disabled={
                              formik.values.signerDetails[signerIndex]
                                ?.userId != null
                            }
                            value={newSigner.name}
                            onChange={(e) => {
                              setFieldValue(
                                `signerDetails[${signerIndex}].name`,
                                e.target.value
                              );
                            }}
                          />
                          {fieldTouched?.name && err?.name && (
                            <Error text={err?.name} />
                          )}
                        </div>
                        <div className="flex-1">
                          <Input
                            type="email"
                            className={`${
                              formik.values.signerDetails[signerIndex]
                                ?.userId != null
                                ? "cursor-not-allowed"
                                : null
                            }`}
                            value={newSigner.email}
                            onChange={(e) => {
                              setFieldValue(
                                `signerDetails[${signerIndex}].email`,
                                e.target.value
                              );
                            }}
                            disabled={
                              formik.values.signerDetails[signerIndex]
                                ?.userId != null
                            }
                          />
                          {fieldTouched?.email && err?.email && (
                            <Error text={err?.email} />
                          )}
                        </div>
                        <div className="flex-1">
                          <Select
                            disabled
                            options={["OPTIONS"]}
                            value={newSigner.signerType}
                          />
                        </div>
                        <div className="flex items-center flex-1 gap-4">
                          {signer.toBeDeleted ? (
                            <div className="text-xs text-red-600">
                              Will be deleted
                            </div>
                          ) : (
                            <Icon
                              width={20}
                              className="hover:text-orange-501 text-gray-400 cursor-pointer"
                              onClick={() => handleDelete(signerIndex)}
                              icon={"ic:baseline-delete"}
                            />
                          )}
                        </div>
                      </HStack>
                    );
                  })}
                </VStack>
                <HStack className="justify-center mt-8">
                  <ButtonSecondary
                    className="text-[11px] leading-[20px] font-medium py-1 bg-slate-201 text-slate-501 h-auto px-2 flex flex-col items-center"
                    onClick={() => handleAdd()}
                  >
                    Add a Signer
                  </ButtonSecondary>
                </HStack>
              </VStack>
              <HStack></HStack>
            </>
          )}
        </VStack>
      )}
      {true && (
        <VStack className="gap-9 justify-between p-4">
          <Box className="text-gray-dark items-center mt-6 text-lg font-medium">
            Digio Settings
          </Box>
          <HStack className="justify-between">
            <p className={` form-label text-sm font-medium w-2/3 `}>
              Employees should be allowed to E-sign
            </p>
            <Box className="flex items-start w-1/3 ml-2">
              <SwitchButton
                className=""
                value={formik.values.isDigioEnabled}
                label=""
                onClick={() => {
                  if (!formik.values.isDigioEnabled) {
                    toast(
                      "Digio is enabled only for Employee e-signing,Please save to reflect the changes",
                      {
                        type: "info",
                        autoClose: 5000,
                      }
                    );
                  }
                  formik.setFieldValue(
                    "isDigioEnabled",
                    !formik.values.isDigioEnabled
                  );
                }}
              />
            </Box>
          </HStack>
          {formik.values.isDigioEnabled && (
            <>
              <HStack>
                <p className={` form-label text-sm font-medium w-2/3 `}>
                  Please Note this enables only Employee Grant acceptance
                </p>
              </HStack>
            </>
          )}
        </VStack>
      )}
      <VStack className="gap-9 justify-between p-4">
        <Box className="text-gray-dark items-center mt-6 text-lg font-medium">
          Legality Settings
        </Box>
        <HStack className="justify-between">
          <p className={` form-label text-sm font-medium w-2/3 `}>
            Employees should be allowed to E-sign
          </p>
          <Box className="flex items-start w-1/3 ml-2">
            <SwitchButton
              onClick={() => {
                formik.setFieldValue(
                  "isEsignEnabled",
                  !formik.values.isEsignEnabled
                );
              }}
              className=""
              value={formik.values.isEsignEnabled}
              label=""
            />
          </Box>
        </HStack>
      </VStack>
      {!isUserEsopViewer && (
        <HStack className="justify-between mt-12">
          <ButtonPrimary1 type="reset" className="mr-8 text-red-500">
            Cancel
          </ButtonPrimary1>
          <ButtonPrimary disabled={isError} type="submit">
            Save
          </ButtonPrimary>
        </HStack>
      )}
    </Box>
  );
}

export default ESignSettings;
