import React, { useEffect } from "react";
import { Icon } from "@iconify/react";
import randomColor from "randomcolor";
import { useParams } from "react-router";
import {
  Box,
  VStack,
  HStack,
  Center,
  isAdminViewer,
  isEsopViewer,
} from "../components/utils";
import {
  PendingRequest,
  RequestType,
  usePendingRequests,
} from "../queries/pendingRequests";
import {
  useExerciseApproveDialog,
  useVestingApproveDialog,
} from "../store/useDialogStore";
import { useGrants } from "../queries";
import { calculateDateDiffTillNow } from "../utils/date";
import { useAuthorizationStore } from "../store";
import { canUserPerformActionOnEvent } from "../utils/permissionHandler";
import { Role } from "../types/common";

function PendingRequests() {
  const { id } = useParams();
  const _id = id || "";
  const header = "Pending Requests";
  const { authority } = useAuthorizationStore();
  const message =
    "Vesting and Exercise Request(s) Pending Approval with Admin/Option Approver";
  let { requests } = usePendingRequests();

  if (_id !== "") {
    requests = requests.filter(
      (request) => request.planId === parseInt(_id, 10)
    );
  }
  const { setState: setExerciseApproveDialog } = useExerciseApproveDialog();
  const { setState: setVestingApproveDialog } = useVestingApproveDialog();
  const { data: grants } = useGrants();
  const isUserAdminViewer = isAdminViewer();
  const isUserEsopViewer = isEsopViewer();

  function handleAction(type: RequestType, id: number) {
    if (type === "Vest Request") {
      if (!canUserPerformActionOnEvent(authority as Role, "VEST", "APPROVE"))
        return;
      setVestingApproveDialog({
        open: true,
        grant: grants?.find((grant) => grant?.optionHolderId === id),
      });
    } else if (type === "Exercise Request") {
      if (
        !canUserPerformActionOnEvent(authority as Role, "EXCERCISE", "APPROVE")
      )
        return;
      setExerciseApproveDialog({
        open: true,
        grant: grants?.find((grant) => grant?.optionHolderId === id),
      });
    }
  }

  return (
    <VStack className="p-6 overflow-auto bg-white rounded-lg max-h-96">
      <VStack className="mb-4">
        <p className="font-medium text-gray-dark">{header}</p>
        <p className="text-xs1 font-semi-medium text-gray-light">{message}</p>
      </VStack>
      <Box className="overflow-auto max-h-72">
        {requests.map((row, _index) => {
          const backgroundColor = randomColor({
            luminosity: "dark",
            format: "rgba",
            alpha: 0.2,
            seed: row.subject,
          });
          return (
            <HStack
              key={`${row.subject}-${_index}`}
              className="items-center justify-between p-2"
            >
              <HStack>
                <Box
                  style={{ backgroundColor }}
                  className="w-4 h-4 mt-2 rounded"
                ></Box>
                <VStack className="w-5/6 pl-4 ">
                  <p
                    className={`font-medium cursor-pointer text-xs2 text-gray-dark ${
                      isUserAdminViewer || isUserEsopViewer
                        ? "cursor-not-allowed"
                        : ""
                    }`}
                    onClick={() =>
                      !(isUserAdminViewer || isUserEsopViewer)
                        ? handleAction(row.type, row.id || 0)
                        : null
                    }
                  >
                    {row.subject}
                  </p>
                  <p className="text-xs1 font-semi-medium text-gray-light">
                    {row.requestDate &&
                      calculateDateDiffTillNow(row.requestDate)}
                  </p>
                </VStack>
              </HStack>
              <button
                className={`h-4 ${
                  isUserAdminViewer || isUserEsopViewer
                    ? "cursor-not-allowed"
                    : ""
                }`}
                onClick={() =>
                  !(isUserAdminViewer || isUserEsopViewer)
                    ? handleAction(row.type, row.id || 0)
                    : null
                }
              ></button>
            </HStack>
          );
        })}
        {requests.length === 0 && (
          <Center className="h-48">
            <p className="p-4 font-medium text-teal-600 rounded">
              No Pending Requests !!{" "}
            </p>
          </Center>
        )}
      </Box>
    </VStack>
  );
}

export default PendingRequests;
