import React, { useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import * as _ from "lodash";
import { format } from "date-fns";
import { Box, HStack, VStack } from "../../components/utils";
import { globalFilter, sort } from "../../utils/arrays";
import BasicMenu from "../../components/shared/Menu";
import { EsopPool } from "../../types/HistoricDataModel";
import Pagination from "../../components/shared/Pagination";
import { TabTransactionDetail } from "../../types/HistoricOverView";
import { formatDate } from "../../utils/date";

export default function OverviewEsopTable({
  tabTransactionDetail,
}: TabTransactionDetail) {
  const values =
    tabTransactionDetail?.investorData?.map((element) => ({
      poolSize: element.poolSize,
      date: format(new Date(element.date || new Date()), "yyyy-MM-dd"),
    })) || [];
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [globalFilterText, setGlobalFilterText] = useState("");

  const [sortField, setSortField] = useState<{
    field: keyof EsopPool | "" | undefined;
    ascending: boolean;
  }>({ field: "date", ascending: false });

  let esopList: EsopPool[] = values || [];

  esopList = useMemo(() => {
    if (!esopList) return [];
    const filterResult = globalFilter(esopList, globalFilterText, [
      "poolSize",
      "date",
    ]);
    const sortResult = sort(
      filterResult,
      sortField?.field,
      sortField?.ascending
    );
    return sortResult;
  }, [esopList, globalFilterText, sortField]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return esopList.slice(firstPageIndex, lastPageIndex);
  }, [esopList, currentPage, pageSize]);

  return (
    <Box>
      <HStack aria-label="toolbar" className="justify-between mb-8">
        <VStack className="min-w-max"></VStack>
        <HStack className="h-11 min-w-min">
          <HStack className="items-center p-2 rounded-lg w-72 bg-white text-slate-dark mx-2">
            <Icon icon="fe:search" width="24" className="mr-2 " />
            <input
              type="text"
              className="w-full font-medium border-0 outline-none text-xs2 bg-inherit"
              placeholder="Search"
              value={globalFilterText}
              onChange={(e) => {
                setGlobalFilterText(e.target.value);
              }}
            ></input>
          </HStack>
        </HStack>
      </HStack>
      <Box className="overflow-auto max-h-full">
        <table className="w-full table-space">
          <thead className="text-xs font-medium text-gray-light">
            <tr>
              <td
                className="py-3 w-72 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "poolSize",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Share Alloted
              </td>

              <td
                className="py-3 w-72 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "date",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Date
              </td>
            </tr>
          </thead>
          <tbody className={""}>
            {values &&
              currentTableData?.map((esopPool, index) => (
                <tr key={esopPool.uuid} className="border-t border-dashed ">
                  <td className="py-4 w-72 align-top ">
                    <HStack>
                      <Box>
                        <p className={` text-xs font-medium text-gray-dark `}>
                          {esopPool.poolSize}
                        </p>
                      </Box>
                    </HStack>
                  </td>

                  <td className="py-4 w-32 align-top ">
                    <HStack className="w-32 ">
                      <Box>
                        <p className={` text-xs font-medium text-gray-dark `}>
                          {formatDate(esopPool.date)}
                        </p>
                      </Box>
                    </HStack>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {values.length === 0 && (
          <div className="text-black text-center">
            <h1>No Esop Pool Found</h1>
          </div>
        )}
      </Box>
      <Box className="flex justify-between mt-8">
        <BasicMenu
          defaultValue={pageSize}
          options={[5, 10, 20, 50, 100]}
          onOptionChange={(value) => setPageSize(value)}
        />
        <Pagination
          currentPage={currentPage}
          totalCount={esopList.length}
          pageSize={pageSize}
          onPageChange={(page: number) => setCurrentPage(page)}
        />
      </Box>
    </Box>
  );
}
