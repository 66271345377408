/* eslint-disable quote-props */
export const currencyTypes = {
  INR: "en-IN",
  USD: "en-US",
  EUR: "en-EU",
  GBP: "en-GB",
  AED: "ar-AE",
  AUD: "en-AU",
  CNY: "zh-CN",
  JPY: "ja-JP",
  SGD: "en-SG",
};

export const currencies = [
  "AED - د.إ",
  "AUD - A$",
  "CNY - ¥",
  "EUR - €",
  "GBP - £",
  "INR - ₹",
  "JPY - ¥",
  "SGD - S$",
  "USD - $",
];
