import { Icon } from "@iconify/react";
import { useState } from "react";
import * as React from "react";
import { useNavigate } from "react-router";
import { queryClient, useUserDetails } from "../../queries";
import { Box, HStack, redirectToHissa, VStack } from "../utils";
import { useAuthorizationStore, useAuthStore } from "../../store";
import { doLogout } from "../../api/Esop";
import {
  AssociatedCompany,
  UserDetailModel,
} from "../../types/UserDetailModel";
import { Input } from "./InputField";
import { globalFilter } from "../../utils/arrays";

export default function Profile() {
  const userData = useUserDetails();
  const navigate = useNavigate();
  async function switchCompany(company: AssociatedCompany) {
    if (company.nameOfTheCompany === userData.data?.company.nameOfTheCompany)
      return;
    await queryClient.resetQueries("userDetails");
    localStorage.setItem("companyId", company.id.toString());
    userData.refetch();
    queryClient.invalidateQueries();
    queryClient.refetchQueries();
  }
  return (
    <div>
      <HStack
        className="gap-2 text-xs cursor-pointer text-gray-dark"
        aria-label="navbar"
      >
        <div className="ml-3 mr-1">
          {userData.data && <ProfileImage user={userData.data} />}
        </div>
        <div className="pt-2 mr-1">
          {userData.data && (
            <VStack className="w-28">
              <p className="truncate">{userData.data.fullName}</p>
              <HStack>
                <span className="truncate">
                  {userData.data.company.nameOfTheCompany}
                </span>
                <ProfileDropDown
                  user={userData.data}
                  onAction={switchCompany}
                  navigate={navigate}
                />
              </HStack>
            </VStack>
          )}
        </div>
      </HStack>
    </div>
  );
}

function ProfileImage(props: { user: UserDetailModel }) {
  const { user } = props;
  return (
    <div>
      {user.pathToProfilePicture ? (
        <img
          className="w-10 mt-1 border-2 border-white rounded-md"
          src={user.pathToProfilePicture.fileUrl}
        />
      ) : (
        <div className="mt-2 profileImage">
          {user.fullName[0].toUpperCase()}
        </div>
      )}
    </div>
  );
}

function ProfileDropDown({
  className = "items-end",
  user,
  onAction,
  render = () => (
    <Icon
      icon="carbon:overflow-menu-horizontal"
      className="text-gray-400 rounded hover:bg-gray-300"
      height={20}
    />
  ),
  navigate,
}: {
  className?: string;
  user: UserDetailModel;
  onAction: (company: AssociatedCompany) => void;
  // eslint-disable-next-line no-undef
  render?: () => JSX.Element;
  navigate: Function;
}) {
  const { clear } = useAuthStore();
  const [showModal, setShowModal] = useState(false);
  const [showCompanies, setShowCompanies] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const { clearAuthorization } = useAuthorizationStore();
  function logout() {
    doLogout().then((_) => {
      localStorage.removeItem("accesstoken");
      localStorage.removeItem("currentUser");
      localStorage.removeItem("companyId");
      localStorage.removeItem("hissaUrl");
      queryClient.clear();
      clear();
      clearAuthorization();
      redirectToHissa("", true);
      window.location.href = `${
        process.env.REACT_APP_LEGACY_URL ||
        localStorage.getItem("hissaUrl") ||
        "https://dev.hissa.com"
      }`;
    });
  }
  const RenderElement = render;
  const contextRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const checkIfClickOutside = (e: any) => {
      if (!(showModal && contextRef.current?.contains(e.target))) {
        setShowModal(false);
        setShowCompanies(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickOutside);
    };
  }, [showModal]);

  const userCompanies = user.companiesAssociatedToUser;
  const companies = React.useMemo(() => {
    if (!userCompanies) return [];
    const filterResult = globalFilter(userCompanies, searchInput, [
      "nameOfTheCompany",
    ]);
    return filterResult;
  }, [searchInput, userCompanies]);
  return (
    <div className="inline-flex">
      <button
        className="inline-flex text-xs text-center rounded-lg peer"
        type="button"
        onClick={() => setShowModal((state) => !state)}
      >
        <RenderElement />
      </button>
      {showModal && (
        <div
          ref={contextRef}
          id="dropdown"
          className="absolute z-10 mt-8 bg-white border divide-y divide-gray-100 rounded shadow right-10 "
        >
          <ul
            className="py-1 text-xs font-normal divide-y text-dark w-36"
            aria-labelledby="dropdownDefault"
          >
            <li className="dropdown-submenu pull-left">
              <a
                className={` block px-4 py-2 hover:bg-gray-100 `}
                onMouseDown={(e) => {
                  navigate("/user/profile");
                  setShowModal(false);
                }}
              >
                View Profile
              </a>
            </li>
            <li className="dropdown-submenu pull-left">
              <a
                className={` block px-4 py-2 hover:bg-gray-100 `}
                onMouseDown={(e) => e.preventDefault()}
                onMouseEnter={() => setShowCompanies(true)}
                onMouseLeave={() =>
                  showCompanies ? null : setShowCompanies(false)
                }
                onClick={() => setShowCompanies((state) => !state)}
              >
                Switch Company
              </a>
              {showCompanies && (
                <>
                  <ul className="mt-8 bg-white border divide-y divide-gray-100 rounded shadow  dropdown-menu">
                    <HStack className="items-center justify-start px-2 rounded bg-slate-light text-slate-dark">
                      <Icon icon="fe:search" width="24" className="" />
                      <Input
                        type="text"
                        value={searchInput}
                        className="w-full font-medium border-0 outline-none text-xs2 bg-inherit"
                        placeholder="Search Company"
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                    </HStack>
                    {companies.map((company, index) => (
                      <li
                        onMouseDown={(e) => {
                          e.preventDefault();
                          onAction(company);
                        }}
                        key={index}
                        className="block px-4 py-2 hover:bg-gray-100"
                      >
                        <button
                          type="button"
                          className={`${
                            company.nameOfTheCompany ===
                              user.company.nameOfTheCompany &&
                            "text-gray-300 cursor-not-allowed "
                          } `}
                        >
                          {company.nameOfTheCompany}
                        </button>
                      </li>
                    ))}
                    {companies.length === 0 ? (
                      <ul className="bg-white rounded shadow ">
                        <li className="block px-4 py-2 hover:bg-gray-100">
                          No companies availables
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                  </ul>
                </>
              )}
            </li>
            <li>
              <a
                className={` block px-4 py-2 hover:bg-gray-100 `}
                onMouseDown={(e) => {
                  e.preventDefault();
                  setShowModal((state) => !state);
                  logout();
                }}
              >
                Logout
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
