import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
} from "../../components/utils";
import {
  useGetAllModels,
  useAddEsopModel,
  useGetAllCompensationTemplates,
  useGetAllHiringPlans,
} from "../../queries/esopModel";
import {
  Refresh,
  Performance,
  useEsopModellingStore,
  YearlyDetailsSummary,
  AdditionalMultiplier,
  GradeDetailsSummary,
  AdditionalCompensation,
} from "../../store/esopModellingStore";
import {
  CompensationTemplateReq,
  HiringPlanReq,
  EsopModelReq,
} from "../../types/EsopModelling";
import { _sum } from "../../utils/arrays";

type StepProps = {
  onBack: () => void;
};

function Summary(props: StepProps) {
  const { onBack } = props;
  const data = useEsopModellingStore();
  const { data: esopModels } = useGetAllModels();
  const esopModelsList = esopModels?.data || [];
  const { data: _compensationTemplates } = useGetAllCompensationTemplates();
  const compensationTemplateId = _compensationTemplates?.data?.filter(
    (templateItem: CompensationTemplateReq) =>
      templateItem?.templateName ===
      data.selectedCompensationTemplate.templateName
  )[0]?.id;
  const { data: _hiringPlans } = useGetAllHiringPlans();
  const hiringPlanId = _hiringPlans?.data.filter(
    (hirePlan: HiringPlanReq) =>
      hirePlan?.planName === data.selectedHiringPlan.planName
  )[0]?.id;

  const planningPeriod = data.selectedCompensationTemplate.planningPeriod;
  const planningPeriodArr = Array(planningPeriod).fill(0);
  const gradeList =
    data.selectedCompensationTemplate?.salaryPerGrades?.map(
      (item) => item.gradeName
    ) || [];
  const { mutate: saveSummary } = useAddEsopModel();
  const currentEmployees = gradeList.map(
    (grade) =>
      data.existingEmployeesDetails.filter((ele) => ele.gradeName === grade)[0]
        ?.total
  );
  const currentYear: number = new Date().getFullYear();

  const gradeDetailsTotal = {
    gradeName: "Total",
    salary: 0,
    optionMultiplier: 0,
    optionsValue: 0,
    numberOfEmployee: 0,
    totalValue: 0,
    numberOfOptions: 0,
    holdingPercentage: 0,
  };

  useMemo(() => {
    let noOfSharesPerYear: number[] = [];
    noOfSharesPerYear = data.valuationPerYear.map(
      (item, index) => item / data.sharePricePerYear[index]
    );
    data.setNoOfSharesPerYear(noOfSharesPerYear);
  }, [data.valuationPerYear, data.sharePricePerYear]);

  let compensationArr: AdditionalCompensation[] = [];

  gradeList.map((grade, gradeIndex) => {
    let totalAdditionalCompensation = 0;
    let noOfOptions = 0;
    let percentageHolding = 0;
    let numberOfEmployees = 0;
    const salaryValue =
      data.selectedCompensationTemplate.salaryPerGrades.filter(
        (ele) => ele.gradeName === grade
      )[0]?.salary || 0;
    const optionMultiplierValue =
      (data.selectedCompensationTemplate.salaryPerGrades.filter(
        (ele) => ele.gradeName === grade
      )[0]?.optionMultiplier || 0) / 100;
    const existingEmployeeData = data.existingEmployeesDetails.filter(
      (ele) => ele.gradeName === grade
    )[0];
    const additionalMultiplierArr = [...data.additionalMultiplier];
    additionalMultiplierArr.sort((a, b) => {
      const c = new Date(a.beforeDate || "").getTime();
      const d = new Date(b.beforeDate || "").getTime();
      return c - d;
    });
    additionalMultiplierArr.push({
      beforeDate: new Date().toISOString().split("T")[0],
      multiple: 0,
    });
    if (existingEmployeeData) {
      for (let i = 0; i < additionalMultiplierArr.length; i++) {
        const currentYear = new Date().getFullYear();
        let multiplier: AdditionalMultiplier = {};
        multiplier = additionalMultiplierArr[i];

        const yearOfEmployeeJoining = new Date(
          multiplier.beforeDate || ""
        ).getFullYear();
        const previousBracket =
          i !== 0
            ? new Date(
                additionalMultiplierArr[i - 1].beforeDate || ""
              ).getFullYear()
            : 0;
        const employeeTenure = currentYear - yearOfEmployeeJoining;
        numberOfEmployees = 0;
        if (employeeTenure >= 3) {
          numberOfEmployees = existingEmployeeData.y3;
        }
        if (employeeTenure >= 2 && employeeTenure < 3) {
          if (yearOfEmployeeJoining - previousBracket === 1) {
            numberOfEmployees = existingEmployeeData.y2;
          } else if (yearOfEmployeeJoining - previousBracket > 1) {
            numberOfEmployees =
              existingEmployeeData.y2 + existingEmployeeData.y3;
          }
        }
        if (employeeTenure >= 1 && employeeTenure < 2) {
          if (yearOfEmployeeJoining - previousBracket === 1) {
            numberOfEmployees = existingEmployeeData.y1;
          } else if (yearOfEmployeeJoining - previousBracket === 2) {
            numberOfEmployees =
              existingEmployeeData.y1 + existingEmployeeData.y2;
          } else if (yearOfEmployeeJoining - previousBracket > 2) {
            numberOfEmployees =
              existingEmployeeData.y1 +
              existingEmployeeData.y2 +
              existingEmployeeData.y3;
          }
        }
        if (employeeTenure < 1) {
          if (yearOfEmployeeJoining - previousBracket === 1) {
            numberOfEmployees = existingEmployeeData.y0;
          } else if (yearOfEmployeeJoining - previousBracket === 2) {
            numberOfEmployees =
              existingEmployeeData.y0 + existingEmployeeData.y1;
          } else if (yearOfEmployeeJoining - previousBracket === 3) {
            numberOfEmployees =
              existingEmployeeData.y0 +
              existingEmployeeData.y1 +
              existingEmployeeData.y2;
          } else if (yearOfEmployeeJoining - previousBracket > 3) {
            numberOfEmployees =
              existingEmployeeData.y0 +
              existingEmployeeData.y1 +
              existingEmployeeData.y2 +
              existingEmployeeData.y3;
          }
        }
        totalAdditionalCompensation +=
          numberOfEmployees *
          (1 + (multiplier.multiple || 0) / 100) *
          salaryValue *
          optionMultiplierValue;
      }
    }
    noOfOptions += totalAdditionalCompensation / data.currentSharePrice;
    percentageHolding +=
      totalAdditionalCompensation /
      data.currentSharePrice /
      (data.valuation / data.currentSharePrice);
    compensationArr = [
      ...compensationArr,
      {
        grade,
        totalValue: totalAdditionalCompensation,
        noOfOptions,
        percentageHolding,
      },
    ];
    return grade;
  });

  const refreshArr: Refresh[] = [];

  let existingEmployeesYearlyDetails: YearlyDetailsSummary[] = [
    {
      year: 0,
      valuation: data.valuation,
      sharePrice: data.currentSharePrice,
      totalNoOfShares: data.valuation / data.currentSharePrice,
      percentageHolding: 0,
      noOfOptions: 0,
      gradeDetailsPerYear: compensationArr.map(
        (compensationArrItem, gradeIndex) => {
          const salary =
            data.selectedCompensationTemplate.salaryPerGrades.filter(
              (ele) => ele.gradeName === compensationArrItem.grade
            )[0]?.salary || 0;
          const noOfEmployees =
            data.existingEmployeesDetails.filter(
              (item) => item.gradeName === compensationArrItem.grade
            )[0]?.total || 0;
          const optionMultiplier =
            (data.selectedCompensationTemplate.salaryPerGrades.filter(
              (ele) => ele.gradeName === compensationArrItem.grade
            )[0]?.optionMultiplier || 0) / 100;
          return {
            gradeName: compensationArrItem.grade,
            noOfEmployees,
            salary,
            optionMultiplier,
            optionValue: salary * optionMultiplier,
            totalValue: compensationArrItem.totalValue,
            noOfOptions: Math.floor(
              compensationArrItem.totalValue / data.currentSharePrice
            ),
            percentageHolding: 0,
          };
        }
      ),
    },
  ];

  let tempYearlyDetailsSummary: YearlyDetailsSummary[] = [];
  if (data.selectedHiringPlan?.hireGrades?.length > 0) {
    tempYearlyDetailsSummary = planningPeriodArr.map((item, index) => ({
      year: index + 1,
      valuation: data.valuationPerYear[index],
      sharePrice: data.sharePricePerYear[index],
      totalNoOfShares:
        data.valuationPerYear[index] / data.sharePricePerYear[index],
      percentageHolding: 0,
      noOfOptions: 0,
      gradeDetailsPerYear: gradeList.map((grade, gradeIndex) => {
        const salaryValue =
          data.selectedCompensationTemplate.salaryPerGrades.filter(
            (ele) => ele.gradeName === grade
          )[0]?.salary || 0;
        const optionMultiplierValue =
          (data.selectedCompensationTemplate.salaryPerGrades.filter(
            (ele) => ele.gradeName === grade
          )[0]?.optionMultiplier || 0) / 100;
        const numberOfEmployees = data.selectedHiringPlan?.hireGrades.filter(
          (ele) => ele.gradeName === grade
        )[0]?.year[index];
        const totalValue =
          numberOfEmployees * salaryValue * optionMultiplierValue;
        const refreshEmployees =
          data.existingEmployeesDetails.filter(
            (ele) => ele.gradeName === grade
          )[0].total +
          data.selectedHiringPlan.hireGrades
            .filter((ele) => ele.gradeName === grade)[0]
            .year.slice(0, index + 1)
            .reduce((sum, v, i) => (i < index ? sum + v : sum), 0);
        const refreshTotalValue =
          refreshEmployees * salaryValue * optionMultiplierValue;
        if (
          (index + 1) % data.specialGrants.frequency === 0 &&
          data.specialGrants.refreshGrants
        ) {
          refreshArr.push({
            year: index + 1,
            grade,
            totalValue:
              refreshTotalValue *
              (data.specialGrants.multiplierPercentage / 100) *
              (data.specialGrants.coverage / 100),
            noOfOptions: Math.floor(
              (refreshTotalValue *
                (data.specialGrants.multiplierPercentage / 100) *
                (data.specialGrants.coverage / 100)) /
                data.sharePricePerYear[index]
            ),
            percentageHolding: 0,
          });
        }
        return {
          gradeName: grade,
          noOfEmployees: numberOfEmployees,
          salary: salaryValue,
          optionMultiplier: optionMultiplierValue,
          optionValue: salaryValue * optionMultiplierValue,
          totalValue: numberOfEmployees * salaryValue * optionMultiplierValue,
          noOfOptions: Math.floor(
            (numberOfEmployees * salaryValue * optionMultiplierValue) /
              data.sharePricePerYear[index]
          ),
          percentageHolding: 0,
        };
      }),
    }));
  }

  existingEmployeesYearlyDetails = existingEmployeesYearlyDetails.map(
    (item) => {
      item.noOfOptions = _sum(item.gradeDetailsPerYear, "noOfOptions");
      item.percentageHolding = _sum(
        item.gradeDetailsPerYear,
        "percentageHolding"
      );
      return item;
    }
  );

  tempYearlyDetailsSummary = tempYearlyDetailsSummary.map((item) => {
    item.noOfOptions = _sum(item.gradeDetailsPerYear, "noOfOptions");
    item.percentageHolding = _sum(
      item.gradeDetailsPerYear,
      "percentageHolding"
    );
    return item;
  });

  const yearlyDetailsSummary: YearlyDetailsSummary[] = [
    ...tempYearlyDetailsSummary,
  ];

  let tempgradeDetailsSummary: GradeDetailsSummary[] = [];
  if (data.selectedHiringPlan?.hireGrades?.length > 0) {
    tempgradeDetailsSummary =
      gradeList.length > 0
        ? gradeList.map((grade, gradeIndex) => {
            const salaryValue =
              data.selectedCompensationTemplate.salaryPerGrades.filter(
                (ele) => ele.gradeName === grade
              )[0]?.salary || 0;
            const optionMultiplierValue =
              (data.selectedCompensationTemplate.salaryPerGrades.filter(
                (ele) => ele.gradeName === grade
              )[0]?.optionMultiplier || 0) / 100;
            const numberOfEmployees =
              (data.existingEmployeesDetails?.filter(
                (ele) => ele.gradeName === grade
              )[0]?.total || 0) +
              (data.selectedHiringPlan.hireGrades
                .filter((ele) => ele.gradeName === grade)[0]
                ?.year.reduce((partialSum: any, a) => partialSum + a, 0) || 0);
            const totalValue =
              numberOfEmployees * salaryValue * optionMultiplierValue;
            let numberOfOptions = 0;
            let percentageHolding = 0;
            data.summary.yearlyDetailsSummary.forEach((item, index) => {
              item.gradeDetailsPerYear.forEach((ele) => {
                if (ele.gradeName === grade) {
                  numberOfOptions += ele.noOfOptions;
                  percentageHolding += ele.percentageHolding;
                }
              });
            });
            existingEmployeesYearlyDetails.forEach((item, index) => {
              item.gradeDetailsPerYear.forEach((ele) => {
                if (ele.gradeName === grade) {
                  numberOfOptions += ele.noOfOptions;
                  percentageHolding += ele.percentageHolding;
                }
              });
            });
            return {
              gradeName: grade,
              salary: salaryValue,
              optionMultiplier: optionMultiplierValue,
              optionsValue: salaryValue * optionMultiplierValue,
              numberOfEmployee: numberOfEmployees,
              totalValue,
              numberOfOptions,
              holdingPercentage: 0,
            };
          })
        : [];
  }

  let gradeDetailsSummary: GradeDetailsSummary[] = [
    {
      gradeName: "Founding Team",
      salary: 0,
      optionMultiplier: 0,
      optionsValue: 0,
      numberOfEmployee: 0,
      totalValue: 0,
      numberOfOptions: data.summary.foundingteam.noOfOptions,
      holdingPercentage: data.specialGrants.reserveForFoundingTeam,
    },
    {
      gradeName: "Advisors",
      salary: 0,
      optionMultiplier: 0,
      optionsValue: 0,
      numberOfEmployee: 0,
      totalValue: 0,
      numberOfOptions: data.summary.advisors.noOfOptions,
      holdingPercentage: data.specialGrants.reserveForAdvisors,
    },
    {
      gradeName: "Performance",
      salary: 0,
      optionMultiplier: 0,
      optionsValue: 0,
      numberOfEmployee: 0,
      totalValue: 0,
      numberOfOptions: data.summary.performance.noOfOptions,
      holdingPercentage: data.specialGrants.reserveForPerformance,
    },
    {
      gradeName: "Refresh",
      salary: 0,
      optionMultiplier: 0,
      optionsValue: 0,
      numberOfEmployee: 0,
      totalValue: 0,
      numberOfOptions: _sum(data.summary.refresh, "noOfOptions"),
      holdingPercentage: _sum(data.summary.refresh, "percentageHolding"),
    },
  ];

  gradeDetailsSummary = gradeDetailsSummary.concat(tempgradeDetailsSummary);

  const tempNoOfShares = data.valuationPerYear[0] / data.sharePricePerYear[0];

  let fullTotalOptions =
    data.noOfSharesPerYear[0] +
    _sum(refreshArr, "noOfOptions") +
    _sum(yearlyDetailsSummary, "noOfOptions") +
    _sum(existingEmployeesYearlyDetails, "noOfOptions");

  const sumOfPerformanceFoundersAdvisorsPercent =
    data.specialGrants.reserveForFoundingTeam +
    data.specialGrants.reserveForPerformance +
    data.specialGrants.reserveForAdvisors;

  const sumOfPerformanceFoundersAdvisorsOptions = Math.floor(
    (fullTotalOptions * sumOfPerformanceFoundersAdvisorsPercent) /
      (100 - sumOfPerformanceFoundersAdvisorsPercent)
  );

  fullTotalOptions += sumOfPerformanceFoundersAdvisorsOptions;

  const foundingTeamNoOfOptions =
    (data.specialGrants.reserveForFoundingTeam /
      sumOfPerformanceFoundersAdvisorsPercent) *
      sumOfPerformanceFoundersAdvisorsOptions || 0;
  const advisorsNoOfOptions =
    (data.specialGrants.reserveForAdvisors /
      sumOfPerformanceFoundersAdvisorsPercent) *
      sumOfPerformanceFoundersAdvisorsOptions || 0;
  const performanceNoOfOptions =
    (data.specialGrants.reserveForPerformance /
      sumOfPerformanceFoundersAdvisorsPercent) *
      sumOfPerformanceFoundersAdvisorsOptions || 0;

  refreshArr.map((refreshItem) => {
    refreshItem.percentageHolding =
      (refreshItem.noOfOptions / fullTotalOptions) * 100;
    return refreshItem;
  });

  gradeDetailsSummary.map((gradeDetailsItem) => {
    if (
      gradeDetailsItem.gradeName !== "Founding Team" &&
      gradeDetailsItem.gradeName !== "Advisors" &&
      gradeDetailsItem.gradeName !== "Performance"
    ) {
      gradeDetailsItem.holdingPercentage =
        (gradeDetailsItem.numberOfOptions / fullTotalOptions) * 100;
    }
    return gradeDetailsItem;
  });

  compensationArr.map((compensationItem) => {
    compensationItem.percentageHolding =
      (compensationItem.noOfOptions / fullTotalOptions) * 100;
    return compensationItem;
  });

  existingEmployeesYearlyDetails.map((yearlyDetailsItem) => {
    yearlyDetailsItem.percentageHolding =
      (yearlyDetailsItem.noOfOptions / fullTotalOptions) * 100;
    return yearlyDetailsItem;
  });

  yearlyDetailsSummary.map((yearlyDetailsItem) => {
    yearlyDetailsItem.percentageHolding =
      (yearlyDetailsItem.noOfOptions / fullTotalOptions) * 100;
    return yearlyDetailsItem;
  });

  useMemo(() => {
    data.setSummary({
      foundingteam: {
        percentageHolding: data.specialGrants.reserveForFoundingTeam,
        noOfOptions: foundingTeamNoOfOptions,
      },
      advisors: {
        noOfOptions: advisorsNoOfOptions,
        percentageHolding: data.specialGrants.reserveForAdvisors,
      },
      performance: {
        noOfOptions: performanceNoOfOptions,
        percentageHolding: data.specialGrants.reserveForPerformance,
      },
      refresh: refreshArr,
      gradeDetailsSummary,
      additionalCompensation: compensationArr,
      yearlyDetailsSummary,
    });
  }, [
    data.specialGrants.reserveForFoundingTeam,
    data.specialGrants.reserveForAdvisors,
    data.specialGrants.reserveForPerformance,
  ]);

  function submitModel() {
    const existingModel = esopModelsList.filter(
      (model: EsopModelReq) => model.modelName === data.modelName
    )[0];

    if (existingModel) {
      toast("Model already in use. Please change the name", {
        type: "error",
        autoClose: 2000,
      });
    } else {
      const modelDto = _.pick(
        data,
        "modelName",
        "currentSharePrice",
        "valuation",
        "compensationTemplate",
        "selectedCompensationTemplate",
        "existingEmployeesDetails",
        "isAdjustedOptionMulitplier",
        "additionalMultiplier",
        "hiringPlans",
        "selectedHiringPlan",
        "sharePriceMultipliers",
        "valuationPerYear",
        "noOfSharesPerYear",
        "sharePricePerYear",
        "specialGrants",
        "summary"
      );
      const saveSummaryReqObj = {
        modelName: data.modelName,
        esopModel: modelDto,
        compensationTemplateId: parseInt(compensationTemplateId, 10),
        hiringPlanId: parseInt(hiringPlanId, 10),
      };
      saveSummary(saveSummaryReqObj, {
        onSuccess: () => {
          toast("Saved Successfully!", {
            type: "success",
            autoClose: 2000,
          });
        },
        onError: (err: any) => {
          toast(err.response.data.reason, {
            type: "error",
            autoClose: 2000,
          });
        },
      });
    }
  }

  let employeesNumberOfOptions = 0;
  let employeesPercentageHolding = 0;
  data.summary.yearlyDetailsSummary.forEach((item, index) => {
    employeesNumberOfOptions += item.noOfOptions;
    employeesPercentageHolding += item.percentageHolding;
  });
  existingEmployeesYearlyDetails.forEach((item, index) => {
    employeesNumberOfOptions += item.noOfOptions;
    employeesPercentageHolding += item.percentageHolding;
  });
  const esopPercentage =
    data.summary.foundingteam.percentageHolding +
    data.summary.advisors.percentageHolding +
    employeesPercentageHolding +
    data.summary.performance.percentageHolding +
    _sum(data.summary.refresh, "percentageHolding");

  const esopShares =
    data.noOfSharesPerYear[0] +
    data.summary.foundingteam.noOfOptions +
    data.summary.advisors.noOfOptions +
    employeesNumberOfOptions +
    data.summary.performance.noOfOptions +
    _sum(data.summary.refresh, "noOfOptions");

  const sars = data.summary.advisors.percentageHolding;

  return (
    <Box className="w-78 p-4 bg-white rounded-lg ">
      <VStack className="justify-between gap-4 p-4">
        <VStack className="justify-between gap-2">
          <p className="text-lg font-medium text-gray-dark">Summary</p>
          <p className="text-s font-medium text-gray-light">
            The summary of the ESOP model created is displayed below. The
            summary shows the value of the options, number of employees covered
            and the total number of options across all grades at the last
            valuation of the company.
          </p>
        </VStack>

        <Box className="my-auto rounded-lg drop-shadow-md">
          <VStack className="justify-between gap-4 p-4 bg-white">
            <Box className="items-center text-lg font-medium text-gray-dark pt-6">
              ESOP Forecast
            </Box>
            <p className={` form-label text-sm font-medium `}>
              The recommended option pool size is
            </p>

            <HStack className="justify-between">
              <VStack className="w-1/3">
                <p className="items-center text-lg font-medium text-orange-501">
                  {esopPercentage.toFixed(4)} %
                </p>
                <p className="items-center text-lg font-medium text-gray-dark">
                  ESOP
                </p>
              </VStack>
              <VStack className="w-1/3">
                <p className="items-center text-lg font-medium text-orange-501">
                  {Math.floor(esopShares)}
                </p>
                <p className="items-center text-lg font-medium text-gray-dark">
                  Shares
                </p>
              </VStack>
              <VStack className="w-1/3">
                <p className="items-center text-lg font-medium text-orange-501">
                  {sars.toFixed(4)} %
                </p>
                <p className="items-center text-lg font-medium text-gray-dark">
                  SAR
                </p>
              </VStack>
            </HStack>
          </VStack>
        </Box>

        <HStack className="justify-between">
          <HStack className="w-1/2 pt-4">
            <p className="items-center text-medium font-medium text-gray-600 m-2">
              Current Valuation:{" "}
            </p>
            <p className="items-center text-medium font-medium text-gray-900 m-2">
              {data.valuation}
            </p>
          </HStack>
          <HStack className="w-1/2 pt-4">
            <p className="items-center text-medium font-medium text-gray-600 m-2">
              Current Share Price:{" "}
            </p>
            <p className="items-center text-medium font-medium text-gray-900 m-2">
              {data.currentSharePrice}
            </p>
          </HStack>
        </HStack>

        <VStack className="justify-between pt-6">
          <Box className="max-h-full overflow-auto">
            <table className="w-[600px] table-space">
              <thead className="text-xs font-medium text-gray-light">
                <tr className="border-b border-dashed ">
                  <td className="py-3 hover:cursor-pointer">Grades</td>
                  <td className="py-3 hover:cursor-pointer">Options Value</td>
                  <td className="py-3 hover:cursor-pointer">
                    Number of Employees
                  </td>
                  <td className="py-3 hover:cursor-pointer">Total Value</td>
                  <td className="py-3 hover:cursor-pointer">
                    Number of Options
                  </td>
                  <td className="py-3 hover:cursor-pointer">
                    Percentage Holding
                  </td>
                </tr>
              </thead>
              <tbody className="font-medium text-dark">
                {data.summary.gradeDetailsSummary.length > 0 &&
                  data.summary.gradeDetailsSummary.map(
                    (gradeDetails, gradeIndex) => {
                      gradeDetailsTotal.optionsValue +=
                        gradeDetails.optionsValue;
                      gradeDetailsTotal.holdingPercentage += parseFloat(
                        gradeDetails.holdingPercentage.toFixed(4)
                      );
                      gradeDetailsTotal.numberOfEmployee +=
                        gradeDetails.numberOfEmployee;
                      gradeDetailsTotal.numberOfOptions += Math.floor(
                        gradeDetails.numberOfOptions
                      );
                      gradeDetailsTotal.totalValue += gradeDetails.totalValue;
                      return (
                        <tr
                          key={gradeIndex}
                          className=" border-b  border-dashed
                             cursor-pointer hover:bg-slate-50"
                        >
                          <td className="p-2">{gradeDetails.gradeName}</td>
                          <td className="p-2">{gradeDetails.optionsValue}</td>
                          <td className="p-2">
                            {gradeDetails.numberOfEmployee}
                          </td>
                          <td className="p-2">{gradeDetails.totalValue}</td>
                          <td className="p-2">
                            {Math.floor(gradeDetails.numberOfOptions)}
                          </td>
                          <td className="p-2">
                            {gradeDetails.holdingPercentage.toFixed(4)}
                          </td>
                        </tr>
                      );
                    }
                  )}
                <tr
                  className=" border-b  border-dashed
                             cursor-pointer hover:bg-slate-50"
                >
                  <td className="p-2">{gradeDetailsTotal.gradeName}</td>
                  <td className="p-2">{gradeDetailsTotal.optionsValue}</td>
                  <td className="p-2">{gradeDetailsTotal.numberOfEmployee}</td>
                  <td className="p-2">{gradeDetailsTotal.totalValue}</td>
                  <td className="p-2">
                    {Math.floor(gradeDetailsTotal.numberOfOptions)}
                  </td>
                  <td className="p-2">
                    {gradeDetailsTotal.holdingPercentage.toFixed(4)}
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
        </VStack>

        <VStack className="justify-between pt-6">
          <Box className="max-h-full overflow-auto">
            <table className="w-[600px] table-space">
              <thead className="text-xs font-medium text-gray-light">
                <tr className="border-b border-dashed ">
                  <td className="py-3 hover:cursor-pointer">Year</td>
                  <td className="py-3 hover:cursor-pointer">Price Per Share</td>
                  <td className="py-3 hover:cursor-pointer">Total Value</td>
                  <td className="py-3 hover:cursor-pointer">No. Of Options</td>
                  <td className="py-3 hover:cursor-pointer">
                    Percentage Holding
                  </td>
                </tr>
              </thead>
              <tbody className="font-medium text-dark">
                {existingEmployeesYearlyDetails?.map((yearlyDetails, index) => (
                  <tr
                    key={`${yearlyDetails.year}-${index}`}
                    className=" border-b  border-dashed cursor-pointer hover:bg-slate-50"
                  >
                    <td className="p-2">{currentYear + index}</td>
                    <td className="p-2">{yearlyDetails.sharePrice}</td>
                    <td className="p-2">
                      {Math.floor(
                        _sum(yearlyDetails.gradeDetailsPerYear, "totalValue")
                      )}
                    </td>
                    <td className="p-2">
                      {Math.floor(yearlyDetails.noOfOptions)}
                    </td>
                    <td className="p-2">
                      {yearlyDetails.percentageHolding.toFixed(4)}
                    </td>
                  </tr>
                ))}
                {data.summary.yearlyDetailsSummary?.map(
                  (yearlyDetails, index) => (
                    <tr
                      key={`${yearlyDetails.year}-${index}`}
                      className=" border-b border-dashed cursor-pointer hover:bg-slate-50"
                    >
                      <td className="p-2">{currentYear + index + 1}</td>
                      <td className="p-2">{yearlyDetails.sharePrice}</td>
                      <td className="p-2">
                        {Math.floor(
                          _sum(yearlyDetails.gradeDetailsPerYear, "totalValue")
                        )}
                      </td>
                      <td className="p-2">
                        {Math.floor(yearlyDetails.noOfOptions)}
                      </td>
                      <td className="p-2">
                        {yearlyDetails.percentageHolding.toFixed(4)}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </Box>
        </VStack>
        <HStack className="justify-between pt-12">
          <ButtonPrimary1
            type="reset"
            className="text-red-500 mr-8"
            onClick={() => {
              onBack();
            }}
          >
            Back
          </ButtonPrimary1>
          <ButtonPrimary type="submit" onClick={() => submitModel()}>
            Save
          </ButtonPrimary>
        </HStack>
      </VStack>
    </Box>
  );
}

export default Summary;
