import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { getUserDetails } from "../api/Esop";
import {
  createCompanyDetails,
  checkCompanyName,
  getImageFile,
  onBoardingElasticSearch,
  onBoardingUploadAdminDetail,
  onBoardingUploadCompanyDetail,
  saveAdminProfile,
  saveHistoricalRound,
  getHistoricalRound,
  updateCaptable,
  getInvestorDropdown,
  addShareHolderGroupName,
  uploadImportedTransaction,
  getDiscardHistoricalRound,
  getDiscardCompanyFromOnboarding,
  getRoundChart,
  getInstrumentChart,
  getShareHolderChart,
  getReportBasedOnSecurity,
  getReportBasedOnShareHolder,
  getReportBasedOnRound,
  approveHistoricalRound,
  shareCapitalDetail,
} from "../api/onBoarding";
import { useHistoricChartStore } from "../store/chartStore";
import {
  InstrumentChartModel,
  RoundNameChartModel,
  ShareHolderChartModel,
} from "../types/HistoricOverView";
import { queryClient } from "./client";

export function useOnBoardingElasticSearch(searchQuery: string) {
  return useQuery({
    queryKey: ["onBoardingElasticSearch", searchQuery],
    queryFn: onBoardingElasticSearch,
  });
}

export function useOnBoardingUploadCompanyDetail() {
  return useQuery({
    queryKey: "OnBoardingUploadCompanyDetail",
    queryFn: onBoardingUploadCompanyDetail,
  });
}

export function useShareCapitalDetails() {
  return useQuery({
    queryKey: "OnBoardingUploadCompanyDetail",
    queryFn: shareCapitalDetail,
  });
}

export function useOnBoardingUploadAdminDetail() {
  return useQuery({
    queryKey: "OnBoardingUploadAdminDetail",
    queryFn: onBoardingUploadAdminDetail,
    onSuccess: () => {
      queryClient.invalidateQueries("userDetailsOnboarding");
    },
  });
}

export function useAddAdminProfileData() {
  return useMutation({
    mutationKey: "addAdminProfile",
    mutationFn: saveAdminProfile,
  });
}

export function useGetImageFile(fileId: string) {
  return useQuery({
    queryKey: ["getImageFile", fileId],
    queryFn: getImageFile,
    enabled: false,
  });
}

export function useCheckCompanyName(
  companyName: string,
  isNewRegistration: boolean,
  navigateOnSuccess: any
) {
  return useQuery({
    queryKey: ["checkCompanyName", companyName, isNewRegistration],
    queryFn: checkCompanyName,
    enabled: false,
    onSuccess: () => {
      queryClient.invalidateQueries("userDetailsOnboarding");
      navigateOnSuccess();
    },
  });
}

export function useCreateCompanyDetails() {
  return useMutation({
    mutationKey: "createCompanyDetails",
    mutationFn: createCompanyDetails,
    onSuccess: () => {},
    onError: (error: { response: { data: { reason: string } } }) => {},
  });
}

export function useGetInvestorDropdown() {
  return useQuery({
    queryKey: "InvestorDropdown",
    queryFn: getInvestorDropdown,
  });
}

export function useUploadImportedTransaction() {
  return useMutation({
    mutationKey: "importTranasaction",
    mutationFn: uploadImportedTransaction,
    onSuccess: () => {
      queryClient.invalidateQueries("getHistoricalRound");
      queryClient.refetchQueries("getHistoricalRound");
    },
    onError: (error: { response: { data: { reason: string } } }) => {},
  });
}

export function useGetHistoricalRound() {
  return useQuery({
    queryKey: "getHistoricalRound",
    queryFn: getHistoricalRound,
  });
}

export function useSaveHistoricalRound() {
  return useMutation({
    mutationKey: "saveHistoricalRound",
    mutationFn: saveHistoricalRound,
    onSuccess: () => {},
    onError: (error: { response: { data: { reason: string } } }) => {},
  });
}

export function useApproveHistoricalRound() {
  return useMutation({
    mutationKey: "approveHistoricalRound",
    mutationFn: approveHistoricalRound,
    onSuccess: () => {},
    onError: (error: { response: { data: { reason: string } } }) => {},
  });
}

export function useUpdateCaptable() {
  return useMutation({
    mutationKey: "updateCaptable",
    mutationFn: updateCaptable,
    onSuccess: () => {},
    onError: (error: { response: { data: { reason: string } } }) => {},
  });
}

export function useGetUserDetails() {
  return useQuery({
    queryKey: "userDetailsOnboarding",
    queryFn: getUserDetails,
    staleTime: 0,
  });
}

export function useGetDiscardHistoricalRound(
  companyId: string,
  onSuccess: any
) {
  return useQuery({
    queryKey: ["discardHistoricalRound", companyId],
    queryFn: getDiscardHistoricalRound,
    enabled: false,
    onSuccess,
  });
}

export function useAddShareHolderGroupName() {
  return useMutation({
    mutationKey: "addShareHolderGroupName",
    mutationFn: addShareHolderGroupName,
    onSuccess: () => {
      queryClient.invalidateQueries("InvestorDropdown");
      queryClient.refetchQueries("InvestorDropdown");
    },
    onError: (error: { response: { data: { reason: string } } }) => {},
  });
}

export function useGetDiscardCompanyFromOnboarding(onSuccess: any) {
  return useQuery({
    queryKey: "getDiscardCompanyFromOnboarding",
    queryFn: getDiscardCompanyFromOnboarding,
    enabled: false,
    onSuccess,
  });
}

export function useGetRoundChart() {
  return useMutation({
    mutationKey: "roundChart",
    mutationFn: getRoundChart,
    onSuccess: (data: RoundNameChartModel[]) => {
      useHistoricChartStore.setState((store) => ({
        ...store,
        round: data,
      }));
    },
  });
}
export function useGetInstrumentChart() {
  return useMutation({
    mutationKey: "instrumentChart",
    mutationFn: getInstrumentChart,
    onSuccess: (data) => {
      useHistoricChartStore.setState((store) => ({
        ...store,
        instrument: data,
      }));
    },
  });
}
export function useGetShareHolderChart() {
  return useMutation({
    mutationKey: "shareHolderChart",
    mutationFn: getShareHolderChart,
    onSuccess: (data) => {
      useHistoricChartStore.setState((store) => ({
        ...store,
        shareHolder: data,
      }));
    },
  });
}

export function useGetReportBasedOnRound(onSuccess: any) {
  return useQuery({
    queryKey: "getReportBasedOnRound",
    queryFn: getReportBasedOnRound,
    enabled: false,
    onSuccess,
  });
}

export function useGetReportBasedOnSecurity(onSuccess: any) {
  return useQuery({
    queryKey: "getReportBasedOnSecurity",
    queryFn: getReportBasedOnSecurity,
    enabled: false,
    onSuccess,
  });
}

export function useGetReportBasedOnShareHolder(onSuccess: any) {
  return useQuery({
    queryKey: "getReportBasedOnSecurity",
    queryFn: getReportBasedOnShareHolder,
    enabled: false,
    onSuccess,
  });
}
