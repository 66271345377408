import { format } from "date-fns";
import * as _ from "lodash";
import { useMutation, useQuery } from "react-query";
import {
  addVestingTemplate,
  getEmployeeVestingTable,
  getVestingEvents,
  getVestingTemplateDetail,
  getVestingTemplateNames,
  getVestingTemplates,
} from "../api/Esop";
import { VestingEventReq } from "../types/VestingTemplate";
import { queryClient } from "./client";
import { randomVestingEvents, randomVestingTemplate } from "./placeholders";

export function useVestingTemplates() {
  return useQuery({
    queryKey: "vestingTemplates",
    queryFn: getVestingTemplates,
    select: (data) =>
      data.map((v) => ({
        ...v,
        schedules: v.schedules.map((s) => ({
          ...s,
          percentage: s.percentage * 100,
          eventTargetDate: s.eventTargetDate
            ? format(new Date(s.eventTargetDate), "yyyy-MM-dd")
            : s.eventTargetDate,
          eventCompletionDate: s.eventCompletionDate
            ? format(new Date(s.eventCompletionDate), "yyyy-MM-dd")
            : s.eventCompletionDate,
        })),
      })),
    placeholderData: Array(15)
      .fill(0)
      .map((data) => randomVestingTemplate()),
  });
}
export function useVestingTemplateDetail(id: number) {
  return useQuery({
    queryKey: ["vestingTemplateDetail", id],
    queryFn: getVestingTemplateDetail,
  });
}

export function useVestingEvents() {
  return useQuery({
    queryKey: "vestingEvents",
    queryFn: getVestingEvents,
    placeholderData: Array(10)
      .fill({})
      .map((_i) => randomVestingEvents()) as VestingEventReq[],
  });
}
export function useAddVestingTemplate() {
  return useMutation({
    mutationKey: "addVestingTemplate",
    mutationFn: addVestingTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries("vestingTemplates");
      queryClient.refetchQueries("vestingTemplates");
    },
    onError: () => {},
  });
}

export function useEditVestingTemplate() {
  return useMutation({
    mutationKey: "addVestingTemplate",
    mutationFn: addVestingTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries("vestingTemplates");
      queryClient.refetchQueries("vestingTemplates");
    },
    onError: () => {},
  });
}

export function useVestingTables(empId: number) {
  return useQuery({
    queryKey: ["ProjectedVesting", empId],
    queryFn: () => getEmployeeVestingTable(empId),
  });
}
