import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Navigate, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Dialog } from "@mui/material";
import AlertDialog from "../../components/shared/AlertDialog";
import {
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  redirectToHissa,
} from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import {
  useCreateCompanyDetails,
  useGetDiscardCompanyFromOnboarding,
  useOnBoardingUploadCompanyDetail,
} from "../../queries/onBoarding";
import {
  CompanyDetailsPostModel,
  DropDownModel,
} from "../../types/OnBoardDataModel";
import CompanyDetailForm from "./CompanyDetailForm";
import BusinessDetailForm from "./BusinessDetailForm";
import FounderTable from "./FounderTable";
import DirectorTable from "./DirectorTable";
import { initialCompanyDetail } from "./initValues";
import { formatDate } from "../../utils/date";

export interface FileTypeModel {
  fileBlob: string;
  fileName?: string;
  fileType?: string;
}

function CompanyDetail(): React.ReactElement {
  const { mutate: createCompanyDetails } = useCreateCompanyDetails();
  const [companyLogoImage, setCompanyLogoImage] = useState<FileTypeModel>({
    fileBlob: "",
  });
  const { refetch, data: _companyDetail } = useOnBoardingUploadCompanyDetail();
  const { refetch: getDiscardCompany } = useGetDiscardCompanyFromOnboarding(
    () => handleDiscard()
  );
  const companyDetail = _companyDetail;

  const navigate = useNavigate();
  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
  }>({
    open: false,
  });

  const discardCompany = () => {
    setDialog({
      open: true,
      message: "Do you want to discard the company?",
    });
  };

  const handleDiscard = () => {
    setDialog({ open: false });
    localStorage.removeItem("companyName");
    toast("Company Deleted Successfully", {
      type: "success",
      autoClose: 2000,
    });
    navigate("/onboarding");
  };

  useEffect(() => {
    refetch();
  }, []);

  const validationCompanyDetailsSchema = Yup.object().shape({
    nameOfTheCompany: Yup.string().required("Company Name is required"),
    cinNumber: Yup.string().required("CIN Number is required"),
    typeOfTheCompany: Yup.string().required("Company Type is required"),
    dateOfIncorporation: Yup.string().required(
      "Date of Incorporation is required"
    ),
    companyEmailId: Yup.string()
      .email("Enter Valid Company Email is required")
      .required("Email is required"),
    companyCurrency: Yup.string().required("Company Currency is required"),
    nic: Yup.string().required("NIC is required"),
    industry: Yup.array().min(1, "Industry is Required"),
    category: Yup.array().min(1, "Category is Required"),
    founders: Yup.array()
      .of(
        Yup.object().shape({
          fullName: Yup.string().required("Founder Name is required"),
          emailId: Yup.string()
            .email("Enterompany Email is required")
            .required("Valid EmailID is required"),
          designation: Yup.string().required("Designation is required"),
          nationality: Yup.string().required("Nationality is required"),
        })
      )
      .required("Required"),
    directors: Yup.array()
      .of(
        Yup.object().shape({
          fullName: Yup.string().required("Director Name is required"),
          emailId: Yup.string()
            .email("Enter Valid Company Email is required")
            .required("Valid E-mail ID is required"),
          typeOfDirector: Yup.string().required("Type of Director is required"),
          din: Yup.string().required("DIN Number is required"),
          nationality: Yup.string().required("Nationality is required"),
        })
      )
      .required("Required"),
  });

  const getInitialValue = () =>
    companyDetail
      ? {
          id: parseInt(companyDetail.id, 10),
          nameOfTheCompany: companyDetail.nameOfTheCompany || "",
          dippCertificateNumber: companyDetail.dippCertificateNumber || "",
          cinNumber: companyDetail.cinNumber || "",
          typeOfTheCompany: companyDetail.typeOfTheCompany || "",
          companyCurrency: companyDetail.companyCurrency || "",
          dateOfIncorporation: formatDate(companyDetail.dateOfIncorporation),
          corporateOfficeAddress: companyDetail.corporateOfficeAddress || "",
          companyEmailId: companyDetail.companyEmailId || "",
          companyWebsite: companyDetail.companyWebsite || "",
          companyLogoPath: companyLogoImage?.fileBlob || "",
          doingBusinessAs: companyDetail.doingBusinessAs || "",
          industry: companyDetail.industry || [],
          category: companyDetail.business || [],
          nic: companyDetail.nic || "",
          founders: companyDetail.founders,
          directors: companyDetail.directors,
        }
      : initialCompanyDetail;

  const handleCompanyDetailsSubmit = (values: CompanyDetailsPostModel) => {
    const formData = new FormData();
    const incorpDate = new Date(values.dateOfIncorporation).toUTCString();
    formData.append("nameOfTheCompany", values.nameOfTheCompany);
    formData.append("dippCertificateNumber", values.dippCertificateNumber);
    formData.append("doingBusinessAs", values.doingBusinessAs);
    formData.append("cinNumber", values.cinNumber);
    formData.append("typeOfTheCompany", values.typeOfTheCompany);
    formData.append("dateOfIncorporation", incorpDate);
    formData.append("corporateOfficeAddress", values.corporateOfficeAddress);
    formData.append("companyEmailId", values.companyEmailId);
    formData.append("companyWebsite", values.companyWebsite);
    formData.append("companyCurrency", values.companyCurrency);
    formData.append("nic", values.nic);
    formData.append("companyLogoPath", values.companyLogoPath);
    values.category.forEach((category, index) => {
      formData.append(`business[${index}]`, category);
    });
    values.industry.forEach((industry, index) => {
      formData.append(`industry[${index}]`, industry);
    });
    values?.founders.forEach((founder, index) => {
      formData.append(`founders[${index}].isEdit`, `${founder.isEdit}`);
      formData.append(`founders[${index}].emailId`, `${founder.emailId}`);
      formData.append(`founders[${index}].fullName`, `${founder.fullName}`);
      formData.append(
        `founders[${index}].designation`,
        `${founder.designation}`
      );
      formData.append(
        `founders[${index}].contactNumber`,
        `${founder.contactNumber}`
      );
      formData.append(`founders[${index}].address`, `${founder.address}`);
      formData.append(
        `founders[${index}].nationality`,
        `${founder.nationality}`
      );
      formData.append(
        `founders[${index}].linkedInProfile`,
        `${founder.linkedInProfile}`
      );
      formData.append(
        `founders[${index}].twitterHandle`,
        `${founder.twitterHandle}`
      );
      formData.append(`founders[${index}].isSelected`, `${founder.isSelected}`);
      formData.append(
        `founders[${index}].shouldShowDialog`,
        `${founder.shouldShowDialog}`
      );
    });
    values?.directors.forEach((director, index) => {
      formData.append(`directors[${index}].isEdit`, `${director.isEdit}`);
      formData.append(`directors[${index}].fullName`, `${director.fullName}`);
      formData.append(
        `directors[${index}].typeOfDirector`,
        `${director.typeOfDirector}`
      );
      formData.append(`directors[${index}].din`, `${director.din}`);
      formData.append(`directors[${index}].emailId`, `${director.emailId}`);
      formData.append(`directors[${index}].address`, `${director.address}`);
      formData.append(
        `directors[${index}].contactNumber`,
        `${director.contactNumber}`
      );
      formData.append(
        `directors[${index}].nationality`,
        `${director.nationality}`
      );
      formData.append(
        `directors[${index}].isSelected`,
        `${director.isSelected}`
      );
      formData.append(
        `directors[${index}].shouldShowDialog`,
        `${director.shouldShowDialog}`
      );
    });
    createCompanyDetails(formData, {
      onSuccess: () => {
        toast("Company Details Saved Successfully!", {
          type: "success",
          autoClose: 2000,
        });
        navigate("/onboarding/historic");
      },
      onError: (error: { response: { data: { reason: string } } }) => {
        toast(`${error.response.data.reason}`, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  };

  const getDropDownValue = () => {
    const dropDownOptions: DropDownModel = {
      selectedCategoryValues: companyDetail?.business,
      selectedIndustryValues: companyDetail?.industry,
      industryDropdownValues: companyDetail?.industryDropdownValues || [],
      categoryDropdownValues: companyDetail?.categoryDropdownValues || [],
    };
    return dropDownOptions;
  };

  return (
    <>
      <div className="w-11/12 m-auto min-h-screen">
        <div className="px-0 text-lg font-medium py-7">
          <h1>Onboarding</h1>
        </div>
        <BCHeader
          className="items-baseline mb-4"
          bcTitle="OnBoarding"
          bcSubTitle="Company Details"
        />
        <Formik
          enableReinitialize={true}
          initialValues={getInitialValue()}
          onSubmit={(values: CompanyDetailsPostModel) => {
            handleCompanyDetailsSubmit(values);
          }}
          validationSchema={validationCompanyDetailsSchema}
        >
          {(formik) => (
            <>
              <Form>
                <Dialog open={dialog.open} maxWidth="md">
                  <AlertDialog
                    onClose={() => setDialog({ open: false })}
                    open={dialog.open}
                    message={dialog.message}
                    onPrimaryAction={getDiscardCompany}
                    onSecondaryAction={() => setDialog({ open: false })}
                  />
                </Dialog>
                <CompanyDetailForm
                  companyLogoImage={companyLogoImage?.fileBlob}
                  setCompanyLogoImage={setCompanyLogoImage}
                />

                <BusinessDetailForm dropDownValue={getDropDownValue()} />

                <FounderTable />
                <DirectorTable />
                <HStack className="justify-end m-8">
                  <ButtonPrimary1
                    type="reset"
                    className="text-red-500 mr-8"
                    onClick={() => {
                      redirectToHissa("landing/dashboard/capTable");
                    }}
                  >
                    Go to Dashboard
                  </ButtonPrimary1>
                  <ButtonPrimary1
                    type="reset"
                    className="text-red-500 mr-8"
                    onClick={discardCompany}
                  >
                    Discard
                  </ButtonPrimary1>
                  <ButtonPrimary type="submit">Continue</ButtonPrimary>
                </HStack>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </>
  );
}

export default CompanyDetail;
