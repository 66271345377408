import React from "react";
import { Box, HStack } from "../../components/utils";
import GrantsTable from "../esopOverview/GrantsTable";
import VestingSchedule from "./VestingSchedule";
import useIsMobile from "../../utils/detectDevice";

function GrantsVSGrade() {
  const { isMobile } = useIsMobile();
  return (
    <>
      <Box className="my-6">
        <HStack className="items-baseline justify-between p-4 bg-white">
          <Box className="py-2 text-lg font-semibold text-gray-600 ">
            Grant Details
          </Box>
        </HStack>
        <Box className="w-full border-b"></Box>
        <Box className={`${isMobile ? "w-full overflow-x-auto" : "grow"} `}>
          <GrantsTable />
        </Box>
      </Box>
      <Box>
        <VestingSchedule />
      </Box>
    </>
  );
}

export default GrantsVSGrade;
