import React, { useCallback, useMemo, useRef } from "react";
import {
  CellClickedEvent,
  ColDef,
  GridApi,
  SideBarDef,
  ValueParserParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router";
import { VestingTemplate } from "../../types/VestingTemplate";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { getCurrencyType } from "../../utils/currencyFormatter";
import { useAuthorizationStore } from "../../store/useAuthorizationStore";
import {
  Box,
  HStack,
  isAdminViewer,
  isEsopViewer,
} from "../../components/utils";
import { useVestingScheduleDialog } from "../../store/useDialogStore";

function VestingTemplatesTableAgGrid({
  VestingTemplateTableData,
  handleAction,
}: {
  VestingTemplateTableData: VestingTemplate[];
  handleAction: (template: VestingTemplate, action: Action) => void;
}) {
  const isUserAdminViewer = isAdminViewer();
  const isUserEsopViewer = isEsopViewer();
  const { authority } = useAuthorizationStore();
  const { setState: setDialog } = useVestingScheduleDialog();
  const chartThemes = useMemo<string[]>(() => ["ag-pastel", "ag-vivid"], []);
  const navigate = useNavigate();
  const currency = getCurrencyType();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const gridApi = useRef<GridApi | null>(null);
  const sideBar = useMemo<SideBarDef>(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          width: 225,
          maxWidth: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    }),
    []
  );

  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));

  const normaliseValue = (value: string | number) => {
    if (typeof value === "number") return value;
    if (typeof value === "string") {
      // check if it can be converted to number first
      // assume if commas are there
      if (isValidDate(value)) return new Date(value).valueOf();
      try {
        value = value.replaceAll(",", "");
      } catch (TypeError) {
        value = value.replace(/,./g, "");
      }
      return parseFloat(value);
    }
    return value;
  };
  const sortComparator = (
    valueA: number | string,
    valueB: number | string,
    ..._: any[]
  ) => normaliseValue(valueA) - normaliseValue(valueB);
  const filterparams = {
    filterParams: {
      filterOptions: [
        "lessThan",
        {
          displayKey: "lessThanWithNulls",
          displayName: "Less Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue < filterValue,
        },
        "greaterThan",
        {
          displayKey: "greaterThanWithNulls",
          displayName: "Greater Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue > filterValue,
        },
        {
          displayKey: "betweenExclusive",
          displayName: "Between (Exclusive)",
          predicate: ([fv1, fv2]: any, cellValue: number | null) =>
            cellValue == null ||
            // eslint-disable-next-line no-mixed-operators
            (fv1 < cellValue && fv2 > cellValue),
          numberOfInputs: 2,
        },
      ],
    },
  };
  const stringToNumberConvertor = (formattedNumber: string) =>
    parseInt(formattedNumber.toString().replaceAll(",", ""), 10);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      comparator: sortComparator,
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );

  const columnDefs: ColDef[] = [
    {
      headerName: "TEMPLATE NAME",
      field: "vestingTemplateName",
      cellStyle: { "line-height": "20px", "padding-top": "15px" },
      filter: "agSetColumnFilter",
      filterValueGetter: (params) => params.getValue("vestingTemplateName"),
      filterParams: {
        comparator: sortComparator,
      },
      comparator(valueA, valueB, ..._other) {
        return valueA.localeCompare(valueB);
      },
      menuTabs: ["filterMenuTab"],
      sortable: true,
      suppressSizeToFit: true,
    },
    {
      headerName: "OPTION HOLDERS",
      field: "numberOfUsersApllied",
      filter: "agNumberColumnFilter",
      filterValueGetter: (params) =>
        stringToNumberConvertor(params.getValue("numberOfUsersApllied")),
      filterParams: filterparams,
      menuTabs: ["filterMenuTab"],
      sortable: true,
    },
    {
      headerName: "VESTING TYPE",
      field: "vestingType",
      filter: "agSetColumnFilter",
      filterValueGetter: (params) => params.getValue("vestingType"),
      sortable: true,
      filterParams: {
        comparator: sortComparator,
      },
      comparator(valueA, valueB, ..._other) {
        return valueA.localeCompare(valueB);
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "TRIGGERS",
      field: "vestingTriggerType",
      filter: "agSetColumnFilter",
      filterValueGetter: (params) => params.getValue("vestingTriggerType"),
      sortable: true,

      filterParams: {
        comparator: sortComparator,
      },
      comparator(valueA, valueB, ..._other) {
        return valueA.localeCompare(valueB);
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "VESTING SCHEDULES",
      field: "vestingSchedule",
      cellStyle: { "line-height": "20px", "padding-top": "15px" },
      filter: false,
      sortable: false,
      menuTabs: [],
      cellRendererParams: ({ value }: { value: { props: any } }) => value.props,
      cellRenderer: VestingInfoRender,
    },
    {
      headerName: "",
      field: "actions",
      pinned: "right",
      hide: false,
      width: 80,
      maxWidth: 80,
      filter: false,
      colId: "action-column",
      suppressNavigable: true,
      suppressColumnsToolPanel: true,
      resizable: false,
      sortable: false,
      menuTabs: ["columnsMenuTab"],
      cellRendererParams: ({ value }: { value: any }) => value.props,
      cellRenderer: CTADropdown,
    },
  ];

  const rowData = useMemo(
    () =>
      VestingTemplateTableData?.map((template) => ({
        vestingTemplateName: template.vestingTemplateName,
        numberOfUsersApllied: template.numberOfUsersApllied,
        vestingType: template.vestingType,
        vestingTriggerType: template.vestingTriggerType,
        vestingSchedule: (
          <VestingInfoRender
            vestingPeriod={template.vestingPeriod}
            vestingInterval={template.vestingInterval}
            cliffPeriod={template.cliffPeriod}
          />
        ),
        actions: (
          <CTADropdown
            actions={[
              {
                name: "Edit",
                disabled:
                  template.numberOfUsersApllied > 0 ||
                  isUserAdminViewer ||
                  isUserEsopViewer,
              },
            ]}
            onAction={(action) => handleAction(template, action)}
          />
        ),
      })),
    [VestingTemplateTableData]
  );

  const onPageSizeChanged = useCallback(() => {
    const value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridApi.current?.paginationSetPageSize(Number(value));
  }, []);

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const selectedRowIndex = cellParams.node.rowIndex || 0;
      const template = VestingTemplateTableData[selectedRowIndex];
      setDialog({ open: true, template, mode: "View" });
    }
  };
  return (
    <AgGridReact
      sideBar={sideBar}
      onGridReady={(params) => {
        // Store the grid API referen
        gridApi.current = params.api;
      }}
      alwaysShowHorizontalScroll
      alwaysMultiSort
      animateRows={true}
      defaultColDef={defaultColDef}
      rowData={rowData}
      onCellClicked={handleCellClick}
      columnDefs={columnDefs}
      pagination={true}
      suppressRowTransform={true}
      suppressCopyRowsToClipboard={true}
      suppressCopySingleCellRanges={true}
      suppressCellFocus={true}
      suppressMenuHide={true}
      rowClass={"border-t border-dashed cursor-pointer hover:bg-slate-50 "}
      overlayNoRowsTemplate={
        '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
      }
    ></AgGridReact>
  );
}

function VestingInfoRender(props: {
  vestingPeriod: number | null;
  vestingInterval?: number | null;
  cliffPeriod: number;
}) {
  return (
    <Box>
      <p className={` text-gray-dark `}>
        {props.vestingPeriod && `${props.vestingPeriod} Months, `}
        {props?.vestingInterval &&
          `Vesting Every ${props?.vestingInterval} Month, `}
        {props?.cliffPeriod > -1 && `${props?.cliffPeriod} Month Cliff`}
      </p>
    </Box>
  );
}

export default VestingTemplatesTableAgGrid;
