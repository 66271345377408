import React, { useEffect, useState } from "react";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { Input, Label } from "../../components/shared/InputField";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
  Error,
} from "../../components/utils";
import { AdditionalMultiplier } from "../../store/esopModellingStore";

type AddAdditionalMultiplierProps = {
  onClose: () => void;
  onPrimaryAction: (data: AdditionalMultiplier) => void;
  data?: AdditionalMultiplier;
};

function AddAdditionalMultiplierDialog({
  onClose = () => {},
  onPrimaryAction = () => {},
  data,
}: AddAdditionalMultiplierProps) {
  const initialAdditionalMultiplier: AdditionalMultiplier = {
    beforeDate: data?.beforeDate || new Date().toISOString().split("T")[0],
    multiple: data?.multiple || 0,
  };
  const validationSchema = Yup.object().shape({
    beforeDate: Yup.date().required("Before Date is required"),
    multiple: Yup.number().min(0).required("required"),
  });
  const handleSubmit = (values: AdditionalMultiplier) => {
    onPrimaryAction(values);
  };
  return (
    <Formik
      key="addAdditionalMultiplier"
      initialValues={initialAdditionalMultiplier}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {(formik) => (
        <Form key="additionalMultiplier">
          <VStack className="w-full gap-9 p-10">
            <Box className="text-lg font-medium border-b">
              <h6 className="justify-between flex">
                Add Additional Multiplier
                <span onClick={() => onClose()} className="cursor-pointer">
                  X
                </span>{" "}
              </h6>
            </Box>
            <HStack className="gap-8 ">
              <div className="flex-1">
                <Label className="text-sm font-normal">Before Date</Label>
                <Input
                  type="date"
                  placeholder="Enter Date"
                  {...formik.getFieldProps("beforeDate")}
                />
                {formik.touched.beforeDate && formik.errors.beforeDate && (
                  <Error text={formik.errors.beforeDate} />
                )}
              </div>
              <div className="flex-1">
                <Label className="text-sm font-normal">
                  Additional Multiplier (%)
                </Label>
                <Input
                  type="number"
                  placeholder="Enter Additional Multiplier"
                  {...formik.getFieldProps("multiple")}
                />
                {formik.touched.multiple && formik.errors.multiple && (
                  <Error text={formik.errors.multiple} />
                )}
              </div>
            </HStack>
            <HStack className="justify-between mt-12">
              <ButtonPrimary1
                type="reset"
                className="text-red-500 mr-8"
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </ButtonPrimary1>
              <ButtonPrimary type="submit">Create</ButtonPrimary>
            </HStack>
          </VStack>
        </Form>
      )}
    </Formik>
  );
}

export default AddAdditionalMultiplierDialog;
