import React, { useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import * as _ from "lodash";
import { format } from "date-fns";
import { Box, HStack, VStack } from "../../components/utils";
import { globalFilter, sort } from "../../utils/arrays";
import BasicMenu from "../../components/shared/Menu";
import { Investor } from "../../types/HistoricDataModel";
import Pagination from "../../components/shared/Pagination";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { TabTransactionDetail } from "../../types/HistoricOverView";
import { getTotalAmount, getTotalShares } from "../../utils/historicUtilities";

export default function OverviewPrimaryTransactionTable({
  tabTransactionDetail,
}: TabTransactionDetail) {
  const values =
    tabTransactionDetail?.investorData?.map((element) => ({
      investorName: element.investorName,
      amount: element.amount,
      par: element.par,
      premium: element.premium,
      numberOfShares: element.numberOfShares,
      securityType: element.securityType,
      isPricedRound: true,
      roundName: element.roundName,
      date: element.date,
    })) || [];

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [globalFilterText, setGlobalFilterText] = useState("");

  const [sortField, setSortField] = useState<{
    field: keyof Investor | "" | undefined;
    ascending: boolean;
  }>({ field: "date", ascending: false });

  let primaryTransactions: Investor[] = values || [];

  primaryTransactions = useMemo(() => {
    if (!primaryTransactions) return [];
    const filterResult = globalFilter(primaryTransactions, globalFilterText, [
      "investorName",
      "amount",
      "par",
      "premium",
      "numberOfShares",
      "date",
      "securityType",
    ]);
    const sortResult = sort(
      filterResult,
      sortField?.field,
      sortField?.ascending
    );
    return sortResult;
  }, [primaryTransactions, globalFilterText, sortField]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return primaryTransactions.slice(firstPageIndex, lastPageIndex);
  }, [primaryTransactions, currentPage, pageSize]);

  return (
    <Box className="mt-2">
      <HStack aria-label="toolbar" className="justify-between mb-8">
        <VStack className="min-w-max">
          <p className="text-xs2 font-medium text-gray-500 ">Paid Up Capital</p>
          <p className="text-sm font-medium text-gray-dark">
            {tabTransactionDetail?.paidUpCapital}
          </p>
        </VStack>
        <VStack className="min-w-max">
          <p className="text-xs2 font-medium text-gray-500 ">
            Pre-Money Valuation
          </p>
          <p className="text-sm font-medium text-gray-dark">
            {tabTransactionDetail?.preMoneyValuation}
          </p>
        </VStack>

        <VStack className="min-w-max">
          <p className="text-xs2 font-medium text-gray-500 ">
            Post-Money Valuation
          </p>
          <p className="text-sm font-medium text-gray-dark">
            {(tabTransactionDetail?.preMoneyValuation || 0) +
              getTotalAmount(currentTableData)}
          </p>
        </VStack>
        <VStack className="flex flex-row-reverse  min-w-max">
          <SwitchButton value={false} label="Priced Round" />
        </VStack>
        <HStack className="items-center p-2 rounded-lg w-72 bg-white text-slate-dark mx-2">
          <Icon icon="fe:search" width="24" className="mr-2 " />
          <input
            type="text"
            className="w-full font-medium border-0 outline-none text-xs2 bg-inherit"
            placeholder="Search"
            value={globalFilterText}
            onChange={(e) => {
              setGlobalFilterText(e.target.value);
            }}
          ></input>
        </HStack>
      </HStack>

      <Box className="overflow-auto max-h-full">
        <table className="table-space">
          <thead className="text-xs font-medium text-gray-light">
            <tr>
              <td
                className="py-3 px-4  hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "investorName",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Investor Name
              </td>

              <td
                className="py-3 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "securityType",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Security
              </td>

              <td
                className="py-3 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "par",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                PAR
              </td>

              <td
                className="py-3 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "premium",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Premium
              </td>

              <td
                className="py-3 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "numberOfShares",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                No. of Shares
              </td>

              <td
                className="py-3 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "amount",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Amount
              </td>

              <td
                className="py-3 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "date",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Date
              </td>

              <td
                className="py-3 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "roundName",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Round
              </td>
            </tr>
          </thead>
          <tbody className={""}>
            {currentTableData &&
              currentTableData?.map((investor: Investor, index) => (
                <tr key={investor.uuid} className="border-t border-dashed ">
                  <td className="py-4 px-4 align-top ">
                    <HStack className="w-32 ">
                      <Box>
                        <p className={` text-xs font-medium text-gray-dark `}>
                          {investor.investorName}
                        </p>
                      </Box>
                    </HStack>
                  </td>

                  <td className="py-4 align-top ">
                    <HStack className="w-28 ">
                      <Box>
                        <p className={` text-xs font-medium text-gray-dark `}>
                          {investor.securityType}
                        </p>
                      </Box>
                    </HStack>
                  </td>

                  <td className="py-4 align-top ">
                    <HStack className="w-28 ">
                      <Box>
                        <p className={` text-xs font-medium text-gray-dark `}>
                          {investor.par}
                        </p>
                      </Box>
                    </HStack>
                  </td>

                  <td className="py-4 align-top ">
                    <HStack className="w-28 ">
                      <Box>
                        <p className={` text-xs font-medium text-gray-dark `}>
                          {investor.premium}
                        </p>
                      </Box>
                    </HStack>
                  </td>

                  <td className="py-4 align-top ">
                    <HStack className="w-28 ">
                      <Box>
                        <p className={` text-xs font-medium text-gray-dark `}>
                          {investor.numberOfShares}
                        </p>
                      </Box>
                    </HStack>
                  </td>

                  <td className="py-4 align-top ">
                    <HStack className="w-28 ">
                      <Box>
                        <p className={` text-xs font-medium text-gray-dark `}>
                          {investor.amount}
                        </p>
                      </Box>
                    </HStack>
                  </td>

                  <td className="py-4 align-top ">
                    <HStack className="w-32">
                      <Box>
                        <p className={` text-xs font-medium text-gray-dark `}>
                          {format(
                            new Date(investor.date || new Date()),
                            "yyyy-MM-dd"
                          )}
                        </p>
                      </Box>
                    </HStack>
                  </td>

                  <td className="py-4 align-top ">
                    <HStack className="w-32 ">
                      <Box>
                        <p className={` text-xs font-medium text-gray-dark `}>
                          {investor.roundName}
                        </p>
                      </Box>
                    </HStack>
                  </td>
                </tr>
              ))}
            {values.length > 0 && (
              <tr key="total" className="border-t border-dashed ">
                <td className="py-4 px-4 align-top ">
                  <HStack className="w-32 ">
                    <p className={` text-xs font-medium text-black`}>Total</p>
                  </HStack>
                </td>

                <td className="py-4 w-28  align-top "></td>

                <td className="py-4 w-16 align-top "></td>

                <td className="py-4 w-16 align-top "></td>

                <td className="py-4  align-top ">
                  <HStack className="w-16 ">
                    <Box>
                      <p className={` text-xs font-medium text-black `}>
                        {getTotalShares(currentTableData)}
                      </p>
                    </Box>
                  </HStack>
                </td>

                <td className="py-4 align-top ">
                  <HStack className="w-28 ">
                    <Box>
                      <p className={` text-xs font-medium text-black`}>
                        {getTotalAmount(currentTableData)}
                      </p>
                    </Box>
                  </HStack>
                </td>

                <td className="py-4 w-32 align-top "></td>

                <td className="py-4 w-32 align-top "></td>
              </tr>
            )}
          </tbody>
        </table>
        {values.length === 0 && (
          <div className="text-black text-center p-4">
            <h1>No Primary Transaction Found</h1>
          </div>
        )}
      </Box>
      <Box className="flex justify-between mt-8">
        <BasicMenu
          defaultValue={pageSize}
          options={[5, 10, 20, 50, 100]}
          onOptionChange={(value) => setPageSize(value)}
        />
        <Pagination
          currentPage={currentPage}
          totalCount={values.length}
          pageSize={pageSize}
          onPageChange={(page: number) => setCurrentPage(page)}
        />
      </Box>
    </Box>
  );
}
