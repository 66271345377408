import { Form, Formik } from "formik";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import * as _ from "lodash";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
} from "../../components/utils";
import {
  useGetSiteSettings,
  useSetSiteSettings,
} from "../../queries/siteSettings";
import BCHeader from "../../shared/BCHeader";
import { SiteSettingsRes } from "../../types/SiteSettings";
import EmailSettings from "./EmailSettings";
import EmployeePortalSettings from "./EmployeePortalSettings";
import GrantLetterSettings from "./GrantLetterSettings";
import SecuritySettings from "./SecuritySettings";
import EmailTemplateSettings from "./EmailTemplateSettings";
import ESignSettings from "./ESignSettings";
import { useAuthorizationStore } from "../../store";

export const SiteSettingsContext = createContext(false);
function SiteSettings() {
  const { authority, permissions: actualPermissions } = useAuthorizationStore();
  const [isUserAdmin, setIsUserAdmin] = useState<boolean>();
  useEffect(() => {
    setIsUserAdmin(
      authority?.includes("ROLE_ADMIN" || "ROLE_ESOP_ADMIN") &&
        actualPermissions?.includes(
          "FULL_ACCESS" || "ADMIN_SAVING_POST" || "ADMIN_SETTINGS_GET"
        )
    );
  }, [authority, actualPermissions]);
  const [activeSetting, setActiveSetting] = useState<string>("Email Settings");
  const {
    data: siteSettingDetails,
    refetch,
    isError,
    isFetched,
  } = useGetSiteSettings();
  const { mutate: setSiteSettings } = useSetSiteSettings();
  function handleOnCancel(values: SiteSettingsRes) {
    values = initialSiteSettings;
  }
  const [hasError, setHasError] = useState<boolean>(true);
  useEffect(() => {
    if (isFetched) setHasError(isError);
  }, [isFetched]);
  const handleSubmit = (values: SiteSettingsRes) => {
    values.signerDetailsDtos = _.cloneDeep(values.signerDetails);
    setSiteSettings(values, {
      onSuccess: () => {
        toast("Saved Successfully!", {
          type: "success",
          autoClose: 2000,
        });
      },
      onError: (err: any) => {
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
      },
    });
  };
  const initialSiteSettings: SiteSettingsRes = {
    showPPSToEmployees: siteSettingDetails?.showPPSToEmployees || false,
    showPlanToEmployees: siteSettingDetails?.showPlanToEmployees || false,
    emailsToBeSent: siteSettingDetails?.emailsToBeSent as boolean,
    approverMailToAdmin: siteSettingDetails?.approverMailToAdmin || false,
    faqForEmployee: siteSettingDetails?.faqForEmployee || false,
    communicationEmailId:
      siteSettingDetails?.communicationEmailId == null
        ? "hissa@rulezero.com"
        : siteSettingDetails?.communicationEmailId || "hissa@rulezero.com",
    grantSignature: siteSettingDetails?.grantSignature || "",
    planLevelGrantTemplate: siteSettingDetails?.planLevelGrantTemplate || [],
    listOfPlansWithoutTemplates:
      siteSettingDetails?.listOfPlansWithoutTemplates || [],
    grantLetterTemplates: siteSettingDetails?.grantLetterTemplates || [],
    ppsForEmployee: siteSettingDetails?.ppsForEmployee || undefined,
    acceptanceNumber: siteSettingDetails?.acceptanceNumber || undefined,
    senderNameForEmail:
      siteSettingDetails?.senderNameForEmail == null
        ? "Hissa"
        : siteSettingDetails?.senderNameForEmail || "Hissa",
    isEsignEnabled: siteSettingDetails?.isEsignEnabled || false,
    showEmpPortalValuesForecast:
      siteSettingDetails?.showEmpPortalValuesForecast || false,
    isCustomizedPlanEnabled:
      siteSettingDetails?.isCustomizedPlanEnabled || false,
    isAttachmentEnabled: siteSettingDetails?.isAttachmentEnabled || false,
    isEnabledTwoFactorAuthentication:
      siteSettingDetails?.isEnabledTwoFactorAuthentication || false,
    reminderEmailsToBeSent: siteSettingDetails?.reminderEmailsToBeSent || false,
    reminderEmailsStartFrom:
      siteSettingDetails?.reminderEmailsStartFrom || undefined,
    reminderEmailsInterval:
      siteSettingDetails?.reminderEmailsInterval || undefined,
    employeeInactivityLogout:
      siteSettingDetails?.employeeInactivityLogout || false,
    shouldEmployeeBeIntimidated:
      siteSettingDetails?.shouldEmployeeBeIntimidated || false,
    isDocuSignEnabled: siteSettingDetails?.isDocuSignEnabled || false,
    isDigioEnabled: siteSettingDetails?.isDigioEnabled || false,
    signerDetails: siteSettingDetails?.signerDetails || [],
    sendEmailsToEmployees: siteSettingDetails?.sendEmailsToEmployees as boolean,
    sendGrantEmailsToEmployees:
      siteSettingDetails?.sendGrantEmailsToEmployees as boolean,
    sendVestingEmailsToEmployees:
      siteSettingDetails?.sendVestingEmailsToEmployees as boolean,
    sendBuyBackEmailsToEmployees:
      siteSettingDetails?.sendBuyBackEmailsToEmployees as boolean,
    sendGrantOfferedEmailsToEmployees:
      siteSettingDetails?.sendGrantOfferedEmailsToEmployees as boolean,
    sendGrantWithdrawEmailsToEmployees:
      siteSettingDetails?.sendGrantWithdrawEmailsToEmployees as boolean,
    sendGrantReminderEmailsToEmployees:
      siteSettingDetails?.sendGrantReminderEmailsToEmployees as boolean,
    sendVestingCompleteEmailsToEmployees:
      siteSettingDetails?.sendVestingCompleteEmailsToEmployees as boolean,
    sendBuyBackOfferedEmailsToEmployees:
      siteSettingDetails?.sendBuyBackOfferedEmailsToEmployees as boolean,
    sendBuyBackClosedEmailsToEmployees:
      siteSettingDetails?.sendBuyBackClosedEmailsToEmployees as boolean,
    sendBuyBackReminderEmailsToEmployees:
      siteSettingDetails?.sendBuyBackReminderEmailsToEmployees as boolean,
    sendEmailsToAdmin: siteSettingDetails?.sendEmailsToAdmin as boolean,
    sendGrantEmailsToAdmin:
      siteSettingDetails?.sendGrantEmailsToAdmin as boolean,
    sendPlanEmailsToAdmin: siteSettingDetails?.sendPlanEmailsToAdmin as boolean,
    sendTransactionEmailsToAdmin:
      siteSettingDetails?.sendTransactionEmailsToAdmin as boolean,
    sendReminderEmailsToAdmin:
      siteSettingDetails?.sendReminderEmailsToAdmin as boolean,
    employeeSignatureForGrant:
      siteSettingDetails?.employeeSignatureForGrant as boolean,
    requestApproverSignature:
      (siteSettingDetails?.requestApproverSignature as boolean) || false,
    isNomineeMandatory:
      (siteSettingDetails?.isNomineeMandatory as boolean) || false,
    isValueView: (siteSettingDetails?.isValueView as boolean) || false,
    enableGrantLetterDownload:
      siteSettingDetails?.enableGrantLetterDownload || false,
      preponeVesting:
      siteSettingDetails?.preponeVesting || false,
  };

  const validationSchema = Yup.object({
    showPPSToEmployees: Yup.boolean(),
    showPlanToEmployee: Yup.boolean(),
    showEmpPortalValuesForecast: Yup.boolean(),
    ppsForEmployee: Yup.number().when(["showPPSToEmployees"], {
      is: (showPPSToEmployees: boolean) => showPPSToEmployees === true,
      then: Yup.number().positive("required").typeError("expected a number"),
    }),
    emailsToBeSent: Yup.boolean(),
    communicationEmailId: Yup.string().when(["emailsToBeSent"], {
      is: (emailsToBeSent: boolean) => emailsToBeSent === true,
      then: Yup.string().email().required("Valid Email is required"),
    }),
    senderNameForEmail: Yup.string().when(["emailsToBeSent"], {
      is: (emailsToBeSent: boolean) => emailsToBeSent === true,
      then: Yup.string()
        .required("Email Sender Name is required")
        .min(1, "minimum one character required")
        .max(100),
    }),
    reminderEmailsToBeSent: Yup.boolean(),
    reminderEmailsStartFrom: Yup.number().when(["reminderEmailsToBeSent"], {
      is: (reminderEmailsToBeSent: boolean) => reminderEmailsToBeSent === true,
      then: Yup.number()
        .positive("required")
        .required("required")
        .typeError("expected a number"),
    }),
    reminderEmailsInterval: Yup.number().when(["reminderEmailsToBeSent"], {
      is: (reminderEmailsToBeSent: boolean) => reminderEmailsToBeSent === true,
      then: Yup.number()
        .positive("required")
        .required("required")
        .typeError("expected a number"),
    }),
    employeeInactivityLogout: Yup.boolean(),
    approverMailToAdmin: Yup.boolean(),
    isAttachmentEnabled: Yup.boolean(),
    acceptanceNumber: Yup.number()
      .positive("required")
      .typeError("expected a number"),
    isEsignEnabled: Yup.boolean(),
    isEnabledTwoFactorAuthentication: Yup.boolean(),
    isDocuSignEnabled: Yup.boolean(),
    signerDetails: Yup.array().when(["isDocuSignEnabled"], {
      is: (isDocuSignEnabled: boolean) => isDocuSignEnabled,
      then: Yup.array().of(
        Yup.object({
          name: Yup.string().required("name required"),
          email: Yup.string().required("email required"),
        })
      ),
    }),
    sendGrantEmailsToEmployees: Yup.boolean(),
    sendVestingEmailsToEmployees: Yup.boolean(),
    sendBuyBackEmailsToEmployees: Yup.boolean(),
    sendGrantOfferedEmailsToEmployees: Yup.boolean(),
    sendGrantWithdrawEmailsToEmployees: Yup.boolean(),
    sendGrantReminderEmailsToEmployees: Yup.boolean(),
    sendVestingCompleteEmailsToEmployees: Yup.boolean(),
    sendBuyBackOfferedEmailsToEmployees: Yup.boolean(),
    sendBuyBackClosedEmailsToEmployees: Yup.boolean(),
    sendBuyBackReminderEmailsToEmployees: Yup.boolean(),
    sendEmailsToAdmin: Yup.boolean(),
    sendGrantEmailsToAdmin: Yup.boolean(),
    sendPlanEmailsToAdmin: Yup.boolean(),
    sendTransactionEmailsToAdmin: Yup.boolean(),
    sendReminderEmailsToAdmin: Yup.boolean(),
    employeeSignatureForGrant: Yup.boolean(),
    requestApproverSignature: Yup.boolean(),
    isNomineeMandatory: Yup.boolean(),
    isValueView: Yup.boolean(),
    enableGrantLetterDownload: Yup.boolean(),
  });

  useEffect(() => {
    refetch();
  }, [activeSetting]);
  return (
    <>
      {isUserAdmin && (
        <Formik
          key="siteSettings"
          initialValues={initialSiteSettings}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          onReset={(values) => {
            handleOnCancel(values);
          }}
        >
          {(formik) => (
            <Form key="employeePortalSettings">
              <VStack className="gap-4">
                <div className="flex items-baseline justify-between">
                  <BCHeader
                    className="items-baseline "
                    bcTitle="Settings"
                    bcSubTitle="Site Settings"
                  />
                </div>
                <Box className="my-auto rounded-lg h-[850px] drop-shadow-md">
                  <HStack className="w-full gap-4">
                    <Box className="h-full min-h-full bg-white rounded-lg w-21 min-w-min">
                      <Box className="items-center p-4 mt-10 text-lg font-medium text-gray-dark whitespace-nowrap">
                        Site Settings Menu
                      </Box>
                      <VStack className="justify-between h-[550px]">
                        <Box
                          className={`cursor-pointer  form-label text-sm font-medium   p-4  whitespace-nowrap ${
                            activeSetting === "Email Settings"
                              ? "rounded text-orange-501"
                              : ""
                          }`}
                          onClick={() => setActiveSetting("Email Settings")}
                        >
                          Email Settings
                        </Box>
                        <Box
                          className={`cursor-pointer  form-label text-sm font-medium   p-4  whitespace-nowrap ${
                            activeSetting === "Employee Portal Settings"
                              ? "rounded text-orange-501"
                              : ""
                          }`}
                          onClick={() =>
                            setActiveSetting("Employee Portal Settings")
                          }
                        >
                          Employee Portal Settings
                        </Box>
                        <Box
                          className={`cursor-pointer  form-label text-sm font-medium   p-4  whitespace-nowrap ${
                            activeSetting === "Grant Letter Settings"
                              ? "rounded text-orange-501"
                              : ""
                          }`}
                          onClick={() =>
                            setActiveSetting("Grant Letter Settings")
                          }
                        >
                          Grant Settings
                        </Box>
                        <Box
                          className={`cursor-pointer  form-label text-sm font-medium   p-4  whitespace-nowrap ${
                            activeSetting === "ESign Settings"
                              ? "rounded text-orange-501"
                              : ""
                          }`}
                          onClick={() => setActiveSetting("ESign Settings")}
                        >
                          E-Sign Settings
                        </Box>
                        <Box
                          className={`cursor-pointer  form-label text-sm font-medium   p-4  whitespace-nowrap ${
                            activeSetting === "Security Settings"
                              ? "rounded text-orange-501"
                              : ""
                          }`}
                          onClick={() => setActiveSetting("Security Settings")}
                        >
                          Security Settings
                        </Box>
                        <Box
                          className={`cursor-pointer  form-label text-sm font-medium   p-4 whitespace-nowrap  ${
                            activeSetting === "Email Template Settings"
                              ? "rounded text-orange-501"
                              : ""
                          }`}
                          onClick={() =>
                            setActiveSetting("Email Template Settings")
                          }
                        >
                          Email Template Settings
                        </Box>
                      </VStack>
                    </Box>
                    <Box className="flex bg-white grow">
                      <SiteSettingsContext.Provider value={hasError}>
                        {activeSetting === "Email Settings" && (
                          <EmailSettings />
                        )}
                        {activeSetting === "Employee Portal Settings" && (
                          <EmployeePortalSettings />
                        )}
                        {activeSetting === "Grant Letter Settings" && (
                          <GrantLetterSettings />
                        )}
                        {activeSetting === "ESign Settings" && (
                          <ESignSettings />
                        )}
                        {activeSetting === "Security Settings" && (
                          <SecuritySettings />
                        )}
                        {activeSetting === "Email Template Settings" && (
                          <EmailTemplateSettings />
                        )}
                      </SiteSettingsContext.Provider>
                    </Box>
                  </HStack>
                </Box>
              </VStack>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}

export default SiteSettings;
