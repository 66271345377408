import randomColor from "randomcolor";
import React from "react";
import { Center } from "../../components/utils";

function ImpactedEmployees({
  name,
  impactedEmployees,
}: {
  name: string;
  impactedEmployees: string[];
}) {
  const seed = name;
  const backgroundColor = randomColor({
    luminosity: "dark",
    format: "rgba",
    alpha: 1,
    seed,
  });
  const color = randomColor({
    luminosity: "dark",
    format: "rgba",
    alpha: 1,
    seed,
  });
  return (
    <div
      className="relative w-8 h-8 transition duration-300 ease-in-out delay-100 border-2 border-white border-solid rounded-full cursor-pointer hover:-translate-y-1 hover:scale-100 ring-2 ring-white hover:ring-blue-800 hover:z-10 hover:ease-in"
      style={{
        backgroundColor,
        color,
      }}
    >
      <Center className="py-1.5 text-white">
        <p>{name.toUpperCase().charAt(0)}</p>
      </Center>
    </div>
  );
}

export default ImpactedEmployees;
