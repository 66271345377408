import { format } from "date-fns";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  getCompanyName,
  HStack,
  VStack,
} from "../../components/utils";

import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import { useEsopPlans, useGenerateSH6Report } from "../../queries";
import { SH6ReportReq } from "../../types/EsopPlan";
import { ReportDto } from "../../types/Grant";
import { formatDate } from "../../utils/date";
import {
  convertBase64ToBlob,
  downloadBlobObject,
} from "../../utils/DownloadFile";

function SH6ReportDialog({
  open,
  onClose = () => {},
}: {
  open: boolean;
  onClose: () => void;
}) {
  const [selectedPlan, setSelectedPlan] = useState<string>("All Plans");
  const [showFromDate, setShowFromDate] = useState(false);
  const { data: esopTableDtoList } = useEsopPlans();
  const plans = esopTableDtoList || [];
  const planNames: string[] = [];
  const planId: number[] = [];
  planNames.push("All Plans");
  plans.forEach((plan) => {
    planNames.push(plan.planName);
    planId.push(plan.esopPlanId);
  });

  const initialValues: SH6ReportReq = {
    date: formatDate(new Date().toUTCString()),
    id: "ALL",
  };
  const validationSchema = Yup.object({
    id: Yup.string().required("required"),
    date: Yup.date().required("required"),
  });

  const fileName = `SH-6 Report_${getCompanyName()}.xlsx`;

  async function downloadReport(sh6Report: ReportDto) {
    onClose();
    const blob = convertBase64ToBlob(sh6Report.base64File, sh6Report.fileType);
    downloadBlobObject(await blob, fileName);
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      refetch();
    },
  });

  function handleChange(planName: string) {
    setSelectedPlan(planName);
    plans.forEach((plan) => {
      if (plan.planName === planName) {
        setShowFromDate(true);
        formik.setFieldValue("id", plan.esopPlanId.toString());
      }
      if (planName === "All Plans") {
        setShowFromDate(false);
        formik.setFieldValue("id", "ALL");
      }
    });
  }
  const { refetch } = useGenerateSH6Report(
    format(new Date(formik.values.date), "yyyy-MM-dd"),
    formik.values.id,
    (data: ReportDto) => downloadReport(data)
  );

  return (
    <VStack className=" min-h-min">
      <div className="p-4 text-lg font-medium border-b ">
        <h6 className="flex justify-between">
          Generating SH-6
          <span className="cursor-pointer" onClick={() => onClose()}>
            X
          </span>
        </h6>
      </div>
      <VStack className="justify-between gap-2">
        <HStack className="items-center justify-between px-6 py-4">
          <Label className="text-sm font-normal ">Plan</Label>
          <Box>
            <Select
              className="ml-2.5"
              options={planNames}
              value={selectedPlan}
              onChange={(e) => handleChange(e.target.value)}
            />
          </Box>
        </HStack>
        <HStack className="items-center justify-between px-6 ">
          <Label className="text-sm font-normal">To Date:</Label>
          <Box>
            <Input
              type="date"
              className="ml-2.5"
              value={formik.values.date}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                if (!e.target.value) return;
                formik.setFieldValue("date", e.target.value);
              }}
            />
          </Box>
        </HStack>
        <HStack className="justify-between px-4 py-6 mt-5">
          <ButtonSecondary
            onClick={() => onClose()}
            className="text-gray-400 bg-slate-50"
          >
            Cancel
          </ButtonSecondary>
          <ButtonPrimary onClick={() => formik.handleSubmit()}>
            Generate
          </ButtonPrimary>
        </HStack>
      </VStack>
    </VStack>
  );
}
export default SH6ReportDialog;
