import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import { AxiosError } from "axios";
import produce from "immer";
import { color } from "@mui/system";
import _ from "lodash";
import { Disclosure } from "@headlessui/react";
import {
  Box,
  ButtonPrimary,
  VStack,
  isEsopViewer,
} from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { Label, TextArea } from "../../components/shared/InputField";
import {
  useGetAllFaq,
  useGetAllFaqSettings,
  useSetAllFaq,
  useSetFaqSettings,
} from "../../queries/faq";
import { Faqparam } from "../../types/faqPara";
import { useAuthorizationStore } from "../../store";

interface faqs {
  inputs: Faqparam[];
}

interface FaqError extends AxiosError {
  data: { resultCode: string };
}
function Faq() {
  const { authority, permissions: actualPermissions } = useAuthorizationStore();
  const [isUserAdmin, setIsUserAdmin] = useState<boolean>();
  useEffect(() => {
    setIsUserAdmin(
      authority?.includes("ROLE_ADMIN" || "ROLE_ESOP_ADMIN") &&
        actualPermissions?.includes(
          "FULL_ACCESS" || "ADMIN_SAVING_POST" || "ADMIN_SETTINGS_GET"
        )
    );
  }, [authority, actualPermissions]);
  const { data: _faqdata, isFetched: faqsFetched } = useGetAllFaq();
  const isUserEsopViewer = isEsopViewer();

  const { data: _getAllFaqSettings, isFetched } = useGetAllFaqSettings();

  let faqValue = _getAllFaqSettings;
  useEffect(() => {
    setshowFaqToggle(_getAllFaqSettings);
    faqValue = _getAllFaqSettings;
  }, [isFetched]);

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  const defaultFaqTemplate = { question: "", answer: "", saved: false };

  const [faqState, setFaqState] = useState<Faqparam[]>([defaultFaqTemplate]);

  const [showFaqToggle, setshowFaqToggle] = useState(_getAllFaqSettings);

  useEffect(() => {
    const faqData = _.cloneDeep(_faqdata);
    if (faqsFetched) {
      const alteredFaqs = faqData?.map((faq) => {
        faq.saved = true;
        return faq;
      });
      setFaqState(alteredFaqs || []);
    }
  }, [faqsFetched, _faqdata]);

  const addInput = () => {
    setFaqState([...faqState, defaultFaqTemplate]);
  };

  const removeInput = (index: number) => {
    setFaqState(
      produce((existingValues) => {
        existingValues.splice(index, 1);
      })
    );
  };

  const handleFaqSave = (faqIndex: number, action: "Save" | "Discard") => {
    if (
      (!faqState[faqIndex].answer || faqState[faqIndex]?.answer === "") &&
      (!faqState[faqIndex].question || faqState[faqIndex]?.question === "")
    ) {
      setFaqState(
        produce((existingValues) => {
          existingValues.splice(faqIndex);
        })
      );
    } else {
      setFaqState(
        produce((existingValues) => {
          existingValues[faqIndex].saved = action === "Save";
        })
      );
    }
  };

  const handleQuestion = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => {
    setFaqState(
      produce((existingValues) => {
        if (event != null) {
          existingValues[index].question = event.target.value;
        }
      })
    );
  };

  const { mutate: addFaq } = useSetAllFaq();

  const { mutate: addFaqSettings } = useSetFaqSettings();

  const handleAnswer = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => {
    setFaqState(
      produce((existingValues) => {
        existingValues[index].answer = event.target.value;
      })
    );
  };

  const handleSubmit = () => {
    const faqDto = faqState.filter(
      (faq) => faq.answer !== "" && faq.question !== ""
    );
    addFaq(faqDto, {
      onSuccess: () => {
        toast("FAQ saved Successfully!", { type: "success" });
      },
      onError: (error) => {
        toast(`${error.response?.data.resultCode}`, {
          type: "error",
          autoClose: 2000,
        });
      },
    });

    if (faqValue !== showFaqToggle) {
      addFaqSettings(
        { faqForEmployee: showFaqToggle || false },
        {
          onSuccess: () => {},
          onError: (error) => {
            toast(`${error.response?.data.resultCode}`, {
              type: "error",
              autoClose: 2000,
            });
          },
        }
      );
    }
  };

  return (
    <>
      {isUserAdmin && (
        <VStack className="gap-4 mx-auto">
          <div className="flex items-baseline justify-between">
            <BCHeader
              className="items-baseline "
              bcTitle="Settings"
              bcSubTitle="FAQ Settings"
            />
          </div>
          <Box className="p-8 bg-white rounded-lg min-w-min">
            <VStack>
              <div className="flex justify-end mb-3 mr-24">
                <SwitchButton
                  className={showFaqToggle ? "cursor-default" : ""}
                  value={showFaqToggle || false}
                  label={""}
                  onClick={() => {
                    if (!isUserEsopViewer) setshowFaqToggle(!showFaqToggle);
                  }}
                />
              </div>
              <div className="flex justify-end mb-3">
                <Label>Show FAQ to Employee</Label>
              </div>
            </VStack>
            <div className="bg-gray-50">
              <div className="px-6 py-12 mx-auto max-w-7xl sm:py-16 lg:px-8">
                <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
                  <h2 className="text-3xl font-bold tracking-tight text-center text-gray-900 sm:text-4xl">
                    Frequently asked questions
                  </h2>
                  {faqState.map((faqItem, faqIndex) => (
                    <dl
                      key={faqIndex}
                      className="mt-6 space-y-6 divide-y divide-gray-200"
                    >
                      <Disclosure as="div" key={faqIndex} className="pt-6">
                        {({ open }) => (
                          <>
                            <dt className="flex text-lg">
                              <Disclosure.Button
                                as="div"
                                className="flex items-start text-left text-gray-400"
                              >
                                <span>{faqIndex + 1}.</span>
                              </Disclosure.Button>

                              {!faqItem.saved ? (
                                <TextArea
                                  placeholder="Type question"
                                  className="block w-full bg-white border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  id={faqIndex.toString()}
                                  value={faqItem.question}
                                  onChange={(event) => {
                                    handleQuestion(event, faqIndex);
                                  }}
                                />
                              ) : (
                                <span className="ml-2">{faqItem.question}</span>
                              )}
                            </dt>
                            <div className="mt-2"></div>
                            <div className="pl-3 mt-2">
                              {!faqItem.saved ? (
                                <TextArea
                                  placeholder="Type answer"
                                  className="block w-full bg-white border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  value={faqItem.answer}
                                  onChange={(event) =>
                                    handleAnswer(event, faqIndex)
                                  }
                                />
                              ) : (
                                <div
                                  className="ml-2 "
                                  dangerouslySetInnerHTML={{
                                    __html: faqItem.answer,
                                  }}
                                ></div>
                              )}
                              <div className="flex justify-end mt-2">
                                <button
                                  className="justify-end ml-6 text-zinc-300 hover:scale-105"
                                  onClick={() => removeInput(faqIndex)}
                                >
                                  <DeleteIcon className="text-gray-600 cursor-pointer hover:text-orange-501" />
                                </button>
                                {faqItem.saved ? (
                                  <button
                                    type="submit"
                                    onClick={() =>
                                      handleFaqSave(faqIndex, "Discard")
                                    }
                                  >
                                    {" "}
                                    <Icon
                                      icon="material-symbols:edit-document-rounded"
                                      className="text-gray-600 cursor-pointer hover:text-orange-501"
                                      height={24}
                                      width={24}
                                    />
                                  </button>
                                ) : (
                                  <p></p>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </Disclosure>
                    </dl>
                  ))}
                </div>
              </div>
            </div>
            {!isUserEsopViewer && (
              <div className="flex justify-end mt-5 mb-8">
                <ButtonPrimary
                  className="flex flex-row mr-4"
                  onClick={addInput}
                >
                  +Add FAQ
                </ButtonPrimary>
                <ButtonPrimary type="submit" onClick={handleSubmit}>
                  Save
                </ButtonPrimary>
              </div>
            )}
          </Box>
        </VStack>
      )}
    </>
  );
}

export default Faq;
