import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import Dialog from "@mui/material/Dialog";
import { toast } from "react-toastify";
import { Button, Menu, MenuItem } from "@mui/material";
import _ from "lodash";
import { GridApi } from "ag-grid-community";
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  Center,
  getCompanyName,
  HStack,
  isAdminViewer,
  isEsopViewer,
  VStack,
} from "../../components/utils";
import { globalFilter, sort } from "../../utils/arrays";
import BasicMenu from "../../components/shared/Menu";
import Pagination from "../../components/shared/Pagination";
import { Employee } from "../../types/Employee";
import Avatar from "../esopOverview/Avatar";
import AddEmployee from "../../modals/AddEmployee";
import { useEmployeeTableFilterStore } from "../../store/employeeTableFilters";
import { limitString, _trimAll } from "../../utils/string";
import {
  useAddEmployeeExcel,
  useCheckConfigExists,
  useEmployees,
  useEmployeeTemplate,
  useExportToExcel,
} from "../../queries";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { BulkActions, ExportImport } from "../esopOverview/GrantsTable";
import GenerateCredentials from "../../components/shared/GenerateCredentials";
import { formatDate, formatDisplayDate } from "../../utils/date";
import convertToBase64 from "../../utils/convertToBase64";
import {
  employeeHeadingVal,
  employeeHeadersObj,
  employeeHeaderslist,
} from "../../types/ExportExcelEmployee";
import {
  convertBase64ToBlob,
  downloadBlobObject,
  downloadS3File,
} from "../../utils/DownloadFile";
import Tooltip from "../../components/shared/Tooltip";
import useIsMobile from "../../utils/detectDevice";
import EmployeeTableFilters from "./employeeTableFilters";
import SortingComponent from "../../components/SortingVariation";
import { EmptyTable } from "../../components/shared/EmptyTable";
import { useAuthStore } from "../../store";
import EmployeesTableAgGrid from "./EmployeesTableAgGrid";
import EmployeeImport from "./EmployeeImport";

function EmployeesTable({ data }: { data: Employee[] }) {
  const { user } = useAuthStore();
  const navigate = useNavigate();
  const { isPlaceholderData, isFetching, refetch } = useEmployees();
  // filter and sort logic
  const [globalFilterText, setGlobalFilterText] = useState("");
  const [sortField, setSortField] = useState<{
    field: keyof Employee | "" | undefined;
    ascending: boolean;
  }>();
  const { data: hrmsConfig, isLoading } = useCheckConfigExists();
  const employeeTableRef = useRef<GridApi | null>(null);

  const [employeeImportDialog, setEmployeeImportDialog] = useState(false);
  const filter = useEmployeeTableFilterStore();
  const template = useEmployeeTemplate();
  const templateFile = template.data || "";
  const isUserAdminViewer = isAdminViewer();
  const isUserEsopViewer = isEsopViewer();

  data = useMemo(() => {
    if (!data) return [];
    const filterResult = globalFilter(
      data,
      globalFilterText || filter.searchText,
      [
        "employeeName",
        "employeeIdentificationString",
        "employeeDesignation",
        "email",
      ]
    )
      .filter(
        (employee) =>
          !filter.status ||
          _trimAll(employee.employmentStatus.toLowerCase()) ===
            _trimAll(filter.status.toLocaleLowerCase())
      )
      .filter(
        (employee) =>
          !filter.department ||
          _trimAll(employee.department.toLowerCase()) ===
            _trimAll(filter.department.toLowerCase())
      )
      .filter(
        (employee) =>
          new Date(employee.dateOfJoin) >= filter.dateOfJoinStart &&
          new Date(employee.dateOfJoin) <= filter.dateOfJoinEnd
      )
      .filter(
        (employee) =>
          !filter.actionableInsightFilters[0].enabled ||
          employee.totalPercentageVested > 0.8
      )
      .filter(
        (employee) =>
          !filter.actionableInsightFilters[1].enabled ||
          !employee.totalOptionsGranted
      )
      .filter(
        (employee) =>
          !filter.actionableInsightFilters[2].enabled ||
          !employee.isLoginProvided
      )
      .filter(
        (employee) =>
          !filter.actionableInsightFilters[3].enabled || !employee.firstLogin
      )
      .filter(
        (employee) =>
          !filter.actionableInsightFilters[4].enabled ||
          (employee.employmentStatus &&
            employee.employmentStatus === "Resigned")
      );

    const sortResult = sort(
      filterResult,
      sortField?.field,
      sortField?.ascending
    );
    return sortResult;
  }, [
    data,
    globalFilterText,
    filter,
    sortField,
    isFetching,
    isPlaceholderData,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [globalFilterText, filter]);

  // selection logic
  const [selectedItems, setSelectedItems] = useState<Employee[]>([]);
  const onSelectionChangetemp = (item: Employee | Employee[]) => {
    const employeeItem: Employee[] = [];
    if (_.isArray(item)) {
      const selectedEmployeeList = item.map((emp) =>
        emp.employeeId?.toLocaleString()
      );
      const selectedEmployees = data.filter((emp) =>
        selectedEmployeeList.includes(emp.employeeIdentificationString)
      );
      employeeItem.push(...selectedEmployees);
    } else {
      const selectedEmployee = data.filter(
        (dataItem) =>
          dataItem.employeeIdentificationString ===
          item.employeeId?.toLocaleString()
      );
      employeeItem.push(...selectedEmployee);
    }

    setSelectedItems((items) => [...employeeItem]);
  };

  const onSelectionChange = (item: Employee | Employee[]) => {
    const employeeItem: Employee[] = [];

    if (_.isArray(item)) {
      const selectedEmployeeIds = item.map((emp) =>
        emp.employeeId?.toLocaleString()
      );

      const filteredRowIds: string[] = [];
      employeeTableRef.current?.forEachNodeAfterFilter((node: any) => {
        filteredRowIds.push(node?.data?.employeeId);
      });
      const filteredRows = data.filter((dataItem) =>
        filteredRowIds.includes(dataItem.employeeIdentificationString)
      );

      const selectedEmployees = filteredRows.filter((emp) =>
        selectedEmployeeIds.includes(emp.employeeIdentificationString)
      );

      employeeItem.push(...selectedEmployees);
    } else {
      const selectedEmployeeId = item.employeeId?.toLocaleString();

      const filteredRowIds: string[] = [];
      employeeTableRef.current?.forEachNodeAfterFilter((node: any) => {
        filteredRowIds.push(node?.data?.employeeId);
      });
      const filteredRows = data.filter((dataItem) =>
        filteredRowIds.includes(dataItem.employeeIdentificationString)
      );

      const selectedEmployee = filteredRows.find(
        (dataItem) =>
          dataItem.employeeIdentificationString === selectedEmployeeId
      );

      if (selectedEmployee) {
        employeeItem.push(selectedEmployee);
      }
    }

    setSelectedItems(() => [...employeeItem]);
  };

  const onSelectionChangetemp1 = (item: Employee) => {
    setSelectedItems((items) =>
      items.find((i) => i === item)
        ? items.filter((i) => i !== item)
        : [...items, item]
    );
  };
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  useEffect(() => {
    if (selectAllChecked) {
      setSelectedItems(data);
    } else {
      setSelectedItems([]);
    }
  }, [data, selectAllChecked]);
  const fileRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  //Export Import on Action
  const handleAction = (action: Action) => {
    if (action.disabled) return;
    if (action.name === "Export All To Excel") {
      exportClickHandler();
    } else if (action.name === "Export Selected") {
      const selectedRows = employeeTableRef.current
        ?.getSelectedRows()
        .map((row) => row?.employeeId);
      const rowsTobeExported = data.filter((dataItem) =>
        selectedRows?.includes(dataItem.employeeIdentificationString)
      );
      exportClickHandler(rowsTobeExported);
    } else if (action.name === "Export Filtered") {
      if (employeeTableRef.current?.isAnyFilterPresent()) {
        const filteredRowIds: string[] = [];
        employeeTableRef.current?.forEachNodeAfterFilter((node: any) => {
          filteredRowIds.push(node?.data?.employeeId);
        });
        const filteredRows = data.filter((dataItem) =>
          filteredRowIds.includes(dataItem.employeeIdentificationString)
        );
        exportClickHandler(filteredRows);
      }
    } else if (action.name === "Import From Excel") {
      fileRef.current.click();
    } else if (action.name === "Download Template") {
      downloadS3File(templateFile);
    } else if (action.name === "Import Employees") {
      setEmployeeImportDialog(true);
    }
  };

  const { mutate: exportExcel } = useExportToExcel();

  const fileName = `Option Holders Details_${getCompanyName()}.xlsx`;
  const exportClickHandler = (dataToBeExported = data) => {
    const reqData = dataToBeExported?.map((item: any) => {
      item.dateOfJoin = formatDisplayDate(
        new Date(item.dateOfJoin).toUTCString()
      );
      item.isLoginProvided =
        item.isLoginProvided == null ? false : item.isLoginProvided;
      item.loginAccess = item.deactivate
        ? "Deactivated"
        : item.isLoginProvided
        ? "Provided"
        : "Not Provided";
      return item;
    });
    const reqObj = {
      heading: employeeHeadingVal,
      companyName: user?.company.nameOfTheCompany,
      data: reqData,
      headers: getHeaderObj(employeeHeaderslist),
    };

    exportExcel(reqObj, {
      onSuccess: async (data) => {
        const blob = await convertBase64ToBlob(
          data.data || "",
          data.fileType
            ? data.fileType
            : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        downloadBlobObject(blob, fileName);
      },
      onError: () => {
        toast("Something Went Wrong", { type: "error", autoClose: 2000 });
      },
    });
  };
  const getHeaderObj = (keys: string[]) =>
    keys.map((ele: string) => ({
      key: ele,
      value: employeeHeadersObj[ele],
    }));
  const { mutate: addEmployeeExcel } = useAddEmployeeExcel();

  function handleChange(e: any) {
    const file = e.target.files[0];
    const allowedFileExtensions = ["xlsx", "xls", "ods"];
    if (
      allowedFileExtensions.some((extension) => file.name.endsWith(extension))
    ) {
      convertToBase64(file).then((data) => {
        const base64 = {
          file: data as string,
        };
        addEmployeeExcel(base64, {
          onSuccess: (data) => {
            toast(
              `${data.length} Employee details were successfully uploaded`,
              {
                type: "success",
              }
            );
          },
          onError: (error: { response: { data: { reason: string } } }) => {
            toast(error.response.data.reason, {
              type: "error",
              autoClose: 5000,
            });
          },
        });
      });
    } else {
      toast(
        ` Invalid file type, allowed file types are ${allowedFileExtensions.join(
          ", "
        )}`,
        { type: "error" }
      );
    }
    fileRef.current.value = "";
  }
  //pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [data, currentPage, pageSize]);

  const [bulkActionDialog, setBulkActionDialog] = useState<{
    open: boolean;
    employeeItem?: Employee[];
    empWithCred?: Employee[];
    empWithoutCred?: Employee[];
    mode?: "Generate Credentials";
  }>({
    open: false,
    employeeItem: [],
    empWithCred: [],
    empWithoutCred: [],
    mode: "Generate Credentials",
  });
  function handleBulkAction(action: Action, employeeItem: Employee[]): void {
    if (action.disabled) {
      return;
    }
    if (action.name === "Generate Credentials") {
      const empWithCredentials = employeeItem.filter(
        (employee) => employee.isLoginProvided === true
      );
      const empWithoutCredentials = employeeItem.filter(
        (employee) => employee.isLoginProvided !== true
      );
      setBulkActionDialog({
        open: true,
        employeeItem,
        empWithCred: empWithCredentials,
        empWithoutCred: empWithoutCredentials,
        mode: "Generate Credentials",
      });
    }
  }
  function viewDetails(emp: Employee) {
    navigate(`/options/employeeDetails/${emp.id}`);
  }
  const { isMobile } = useIsMobile();
  const [showFilter, setShowFilter] = useState(false);
  function showAdvanceFilters() {
    setShowFilter(!showFilter);
  }
  return (
    <Box
      className={` p-4 bg-white rounded-lg ${
        isMobile ? "min-w-[1040px]" : "w-full"
      }`}
    >
      <p className="text-lg font-medium text-gray-dark">
        Option Holders Details
      </p>
      <HStack aria-label="toolbar" className="justify-between mb-8">
        <VStack className="min-w-max">
          <p className="mt-4 text-xs font-medium text-gray-light">
            {data.length} Option Holders
          </p>
        </VStack>
        <HStack className="h-11 min-w-min">
          {isMobile && (
            <Center className={`p-2 px-3 mx-2 text-white rounded bg-white`}>
              <Icon
                icon={`${
                  showFilter ? "material-symbols:close-rounded" : "el:filter"
                }`}
                className={` text-orange-501 rounded`}
                height={20}
                onClick={() => showAdvanceFilters()}
              />
            </Center>
          )}
          <Center>
            <BulkActions
              actions={[
                {
                  name: "Generate Credentials",
                  disabled: selectedItems.length === 0 || isUserEsopViewer,
                },
              ]}
              onAction={(action) => handleBulkAction(action, selectedItems)}
            />
          </Center>
          <Dialog
            open={bulkActionDialog.open}
            onClose={() =>
              setBulkActionDialog({
                open: false,
                employeeItem: [],
              })
            }
          >
            <GenerateCredentials
              open={bulkActionDialog.open}
              employeeList={selectedItems}
              empWithCred={bulkActionDialog.empWithCred || []}
              empWithoutCred={bulkActionDialog.empWithoutCred || []}
              onClose={() =>
                setBulkActionDialog({
                  open: false,
                  employeeItem: bulkActionDialog.employeeItem,
                })
              }
              onSecondaryAction={() =>
                setBulkActionDialog({
                  open: false,
                  employeeItem: bulkActionDialog.employeeItem,
                })
              }
            />
          </Dialog>
          <Dialog
            maxWidth="md"
            open={employeeImportDialog}
            onClose={() => setEmployeeImportDialog(false)}
          >
            <div className="w-[900px] h-full">
              <EmployeeImport
                onSuccess={() => {
                  setEmployeeImportDialog(false);
                  refetch();
                }}
                onClose={() => setEmployeeImportDialog(false)}
              />
            </div>
          </Dialog>
          <input
            ref={fileRef}
            onChange={handleChange}
            multiple={false}
            type="file"
            accept=".xlsx, .xls, .ods"
            hidden
          />
          <ExportImport
            actions={[
              {
                name: "Export All To Excel",
              },
              {
                name: "Export Selected",
                disabled:
                  employeeTableRef.current?.getSelectedRows()?.length === 0,
              },
              {
                name: "Export Filtered",
                disabled: !employeeTableRef.current?.isAnyFilterPresent(),
              },
              {
                name: "Import Employees",
                disabled: true,
              },
              {
                name: "Import From Excel",
                disabled: isUserAdminViewer || isUserEsopViewer,
              },
              {
                name: "Download Template",
              },
            ]}
            onAction={(action) => handleAction(action)}
          />
        </HStack>
        {isMobile && showFilter && (
          <Box
            className={`z-20  overflow-y-auto bg-white flex mt-8 justify-center absolute transition delay-300 border border-solid ${
              showFilter ? "0" : "-100%"
            }`}
          >
            <EmployeeTableFilters />
          </Box>
        )}
      </HStack>
      <HStack className="justify-between w-full ">
        <Box
          style={{
            height: `${(data.length >= 10 ? 10 : data.length + 3) * 60}px`,
          }}
          className="w-full h-full max-h-full overflow-x-auto ag-theme-material"
        >
          <EmployeesTableAgGrid
            key={"EmployyeTable"}
            employeeTableData={data}
            onSelectedRows={onSelectionChange}
            handleAction={handleBulkAction}
            gridRef={employeeTableRef}
          />
        </Box>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          // style={{ height: `${pageSize * 64 + 128}px` }}
          className="hidden w-full max-h-full overflow-x-auto"
        >
          <table
            className={` table-space ${isMobile ? "min-w-[1040px]" : "w-full"}`}
          >
            <thead className="text-xs font-medium text-gray-light">
              <tr className="">
                <td className="py-3">
                  <input
                    type="checkbox"
                    className="accent-orange-501 outline-hidden"
                    // checked={selectedItems.l(grant) !== -1}
                    onChange={(e) => setSelectAllChecked(!selectAllChecked)}
                  ></input>
                </td>
                <td className="py-3"></td>
                <td>
                  <HStack className="items-center hover:cursor-pointer">
                    EMPLOYEE NAME
                    <SortingComponent
                      fieldName="employeeName"
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      onChangeSort={() => {
                        setSortField({
                          field: "employeeName",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                  </HStack>
                </td>
                <td>
                  <HStack className="items-center hover:cursor-pointer">
                    DATE OF JOINING
                    <SortingComponent
                      fieldName="dateOfJoin"
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      onChangeSort={() => {
                        setSortField({
                          field: "dateOfJoin",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                  </HStack>
                </td>
                <td>
                  <HStack className="items-center hover:cursor-pointer">
                    EMPLOYEE ID
                    <SortingComponent
                      fieldName="employeeIdentificationString"
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      onChangeSort={() => {
                        setSortField({
                          field: "employeeIdentificationString",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                  </HStack>
                </td>
                <td>
                  <HStack className="items-center hover:cursor-pointer">
                    EMAIL ID
                    <SortingComponent
                      fieldName="email"
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      onChangeSort={() => {
                        setSortField({
                          field: "email",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                  </HStack>
                </td>
              </tr>
            </thead>
            <tbody
              id="employee-table"
              className={isPlaceholderData ? "loading" : ""}
            >
              {currentTableData &&
                currentTableData?.map((emp) => (
                  <tr
                    key={emp.id}
                    className="border-t border-dashed cursor-pointer hover:bg-slate-50"
                    onClick={() => viewDetails(emp)}
                  >
                    <td
                      className="py-4 align-top"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <input
                        type="checkbox"
                        className="mt-2 accent-orange-501 outline-hidden"
                        checked={selectedItems.indexOf(emp) !== -1}
                        onChange={(e) => onSelectionChange(emp)}
                      ></input>
                    </td>
                    <td className="px-2 py-4 align-top">
                      {!isPlaceholderData ? (
                        <Avatar
                          status={emp.employmentStatus}
                          name={emp.employeeName}
                        />
                      ) : (
                        <div className="w-8 h-8 loading-opacity"></div>
                      )}
                    </td>
                    <td className="py-4 align-top ">
                      <HStack className="">
                        <Box className="">
                          <Tooltip text={emp.employeeName}>
                            <p className="text-xs font-medium text-gray-dark">
                              {limitString(emp.employeeName, 40)}
                            </p>
                          </Tooltip>
                          <Tooltip
                            text={`${emp.employeeDesignation} ${emp.department}`}
                          >
                            <p className="leading-5 capitalize text-xxs text-gray-light">
                              {limitString(
                                emp.employeeDesignation,
                                15
                              ).toLowerCase() || "NA"}{" "}
                              |{" "}
                              {limitString(emp.department, 10).toLowerCase() ||
                                "NA"}
                            </p>
                          </Tooltip>
                        </Box>
                      </HStack>
                    </td>
                    <td className="py-4 align-top">
                      <Box className="">
                        <HStack>
                          <Box>
                            <p className="text-xs font-medium text-gray-dark">
                              {formatDisplayDate(emp.dateOfJoin)}
                            </p>
                            {emp.lastDay && (
                              <p className="leading-5 capitalize text-xxs text-gray-light">{`Resigned on: ${formatDisplayDate(
                                emp.lastDay
                              )}`}</p>
                            )}
                          </Box>
                        </HStack>
                      </Box>
                    </td>
                    <td className="py-4 align-top">
                      <Box>
                        <p className="text-xs font-medium text-gray-dark">
                          {emp.employeeIdentificationString}
                        </p>
                      </Box>
                    </td>
                    <td className="py-4 align-top">
                      <Box>
                        <p className="text-xs font-medium text-gray-dark">
                          {emp.email}
                        </p>
                      </Box>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <Box />
          {currentTableData.length === 0 && (
            <EmptyTable
              header="No Option Holders"
              subHeader="Click on Add Option Holder button to add Option Holders."
            />
          )}

          <Box className="flex justify-between mt-8">
            <BasicMenu
              defaultValue={pageSize}
              options={[5, 10, 20, 50, 100]}
              onOptionChange={(value) => {
                setPageSize(value);
                setCurrentPage(1);
              }}
            />
            <Pagination
              className=""
              currentPage={currentPage}
              totalCount={data.length}
              pageSize={pageSize}
              onPageChange={(page: number) => setCurrentPage(page)}
            />
          </Box>
        </Box>
      </HStack>
    </Box>
  );
}

export default EmployeesTable;
