export const planInitValues = {
  id: undefined,
  planName: "",
  planType: "ESOP",
  scope: "",
  planStartDate: "",
  poolSize: 0,
  planTerm: 0,
  planDescription: "",
  notes: "",
  vestingTemplateId: 0,
  vestingTemplateName: "",
  isExercise: false,
  exercisePercentage: 0,
  frequencyOfExercise: 0,
  isResignation: false,
  resignationDuration: 0.0,
  resignationPercentage: 0,
  isCause: false,
  causeDuration: 0.0,
  causePercentage: 0,
  isConvenience: false,
  convenienceDuration: 0.0,
  conveniencePercentage: 0,
  trustShareholderId: 0,
  boardResolutionDocuments: "",
  deathDuration: 0.0,
  deathPercentage: 0,
  conversionNumber: 1,
  conversionShares: 1,
  poolShares: 0,
};
