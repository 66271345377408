import React from "react";
import { Icon } from "@iconify/react";
import randomColor from "randomcolor";
import _ from "lodash";
import {
  Box,
  VStack,
  HStack,
  Center,
  isAdminViewer,
  isEsopViewer,
} from "../components/utils";
import { useRecentDrafts } from "../queries";
import { RequestType } from "../queries/pendingRequests";
import { useGrantCreatedDialog } from "../store/useDialogStore";
import { calculateDateDiffTillNow } from "../utils/date";

function CardDraft() {
  const header = "Recent Drafts";
  const { data: _recentDrafts } = useRecentDrafts();
  const recentDrafts = _.uniqBy(_recentDrafts, (grant) => grant.id);
  const message = `${recentDrafts.length} New Grant(s) Created`;
  const { setState: setGrantCreatedDialog } = useGrantCreatedDialog();
  const isUserAdminViewer = isAdminViewer();
  const isUserEsopViewer = isEsopViewer();

  function handleAction({ id, type }: { id: number; type: RequestType }) {
    if (type === "Grant Created") {
      setGrantCreatedDialog({
        open: true,
        grantId: id,
        variant: "state change",
      });
    }
  }
  return (
    <VStack className="p-6 overflow-auto bg-white rounded-lg max-h-96">
      <VStack className="mb-4">
        <p className="font-medium text-gray-dark">{header}</p>
        <p className="text-xs1 font-semi-medium text-gray-light">{message}</p>
      </VStack>
      <VStack className="overflow-auto">
        {recentDrafts.map((row) => {
          const backgroundColor = randomColor({
            luminosity: "dark",
            format: "rgba",
            alpha: 0.2,
            seed: row.subject,
          });
          return (
            <HStack key={row.id} className="justify-between p-2">
              <HStack>
                <Box
                  style={{ backgroundColor }}
                  className="w-4 h-4 mt-2 rounded"
                ></Box>
                <VStack className="pl-4">
                  <p className="font-medium text-xs2 text-gray-dark">
                    {row.subject}
                  </p>
                  <p className="text-xs1 font-semi-medium text-gray-light">
                    {calculateDateDiffTillNow(row.requestDate)}
                  </p>
                </VStack>
              </HStack>
              <button
                onClick={() =>
                  !(isUserAdminViewer || isUserEsopViewer)
                    ? handleAction({ id: row.id || 0, type: row.type })
                    : null
                }
              >
                <Icon
                  icon="akar-icons:pencil"
                  width="27"
                  className={`h-4 transition duration-100 ease-in-out border-white border-solid rounded-full text-orange-501 ring-2 ring-white hover:p-1 hover:h-6 hover:text-orange-501 hover:-translate-y-1 hover:scale-100 hover:ring-blue-800 ${
                    isUserAdminViewer || isUserEsopViewer
                      ? "cursor-not-allowed"
                      : ""
                  }`}
                />
              </button>
            </HStack>
          );
        })}
        {recentDrafts.length === 0 && (
          <Center className="h-48">
            <p className="p-4 font-medium text-teal-600 rounded">
              No Recent Drafts !!{" "}
            </p>
          </Center>
        )}
      </VStack>
    </VStack>
  );
}

export default CardDraft;
