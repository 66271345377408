import React, { useCallback, useMemo, useRef } from "react";
import {
  CellClickedEvent,
  ColDef,
  GridApi,
  SideBarDef,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router";
import { EsopPlan } from "../../types/EsopPlan";
import { limitString } from "../../utils/string";
import Avatar from "../esopOverview/Avatar";
import {
  Box,
  HStack,
  isAdminViewer,
  isEsopViewer,
} from "../../components/utils";
import { getCurrencyType } from "../../utils/currencyFormatter";
import PlanStatusLabel from "../esopOverview/StatusLabel";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { useAuthorizationStore } from "../../store/useAuthorizationStore";

type PlanInfoCellProps = {
  plan: EsopPlan;
};

function PlanTableAgGrid({
  planTableData,
  handleAction,
}: {
  planTableData: EsopPlan[];
  handleAction: (plan: EsopPlan, action: Action) => void;
}) {
  const isUserAdminViewer = isAdminViewer();
  const isUserEsopViewer = isEsopViewer();
  const { authority } = useAuthorizationStore();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const gridApi = useRef<GridApi | null>(null);
  const navigate = useNavigate();
  const currency = getCurrencyType();
  const chartThemes = useMemo<string[]>(() => ["ag-pastel", "ag-vivid"], []);
  const sideBar = useMemo<SideBarDef>(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          width: 225,
          maxWidth: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    }),
    []
  );

  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));

  const normaliseValue = (value: string | number) => {
    if (typeof value === "number") return value;
    if (typeof value === "string") {
      // check if it can be converted to number first
      // assume if commas are there
      if (isValidDate(value)) return new Date(value).valueOf();
      try {
        value = value.replaceAll(",", "");
      } catch (TypeError) {
        value = value.replace(/,./g, "");
      }
      return parseFloat(value);
    }
    return value;
  };
  const sortComparator = (
    valueA: number | string,
    valueB: number | string,
    ..._: any[]
  ) => normaliseValue(valueA) - normaliseValue(valueB);

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const rowData = planTableData.find(
        (row) =>
          row.esopPlanId ===
          cellParams?.data?.esopPlanState?.props?.plan?.esopPlanId
      );
      if (rowData) {
        navigate(`/options/planView/${rowData.esopPlanId}`);
      }
    }
  };

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      comparator: sortComparator,
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );
  const stringToNumberConvertor = (formattedNumber: string) =>
    parseInt(formattedNumber.toString().replaceAll(",", ""), 10);

  const filterparams = {
    filterParams: {
      filterOptions: [
        "lessThan",
        {
          displayKey: "lessThanWithNulls",
          displayName: "Less Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue < filterValue,
        },
        "greaterThan",
        {
          displayKey: "greaterThanWithNulls",
          displayName: "Greater Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue > filterValue,
        },
        {
          displayKey: "betweenExclusive",
          displayName: "Between (Exclusive)",
          predicate: ([fv1, fv2]: any, cellValue: number | null) =>
            cellValue == null ||
            // eslint-disable-next-line no-mixed-operators
            (fv1 < cellValue && fv2 > cellValue),
          numberOfInputs: 2,
        },
      ],
    },
  };
  const columnDefs: ColDef[] = [
    {
      headerName: "PLAN NAME",
      width: 200,
      field: "planName",
      filter: "agSetColumnFilter",
      filterValueGetter: (params) => params.getValue("planName").props.planName,
      initialWidth: 200,
      minWidth: 200,
      sortable: true,
      autoHeight: true,
      menuTabs: ["filterMenuTab"],
      wrapText: true,
      filterParams: {
        comparator: sortComparator,
      },
      comparator(valueA, valueB, ..._other) {
        return valueA.props.planName?.localeCompare(valueB.props.planName);
      },
      suppressSizeToFit: true,
      cellRendererParams: ({ value }: { value: { props: any } }) => value.props,
      cellRenderer: PlanInfoRender,
    },
    {
      headerName: "TOTAL SHARE",
      field: "totalShares",
      filter: "agNumberColumnFilter",
      filterValueGetter: (params) =>
        stringToNumberConvertor(params.getValue("totalShares")),
      filterParams: filterparams,
      menuTabs: ["filterMenuTab"],
      sortable: true,
      autoHeight: true,
      wrapText: true,
    },
    {
      headerName: "GRANTED",
      field: "issuedShares",
      filter: "agNumberColumnFilter",
      filterValueGetter: (params) =>
        stringToNumberConvertor(params.getValue("issuedShares")),
      filterParams: filterparams,
      menuTabs: ["filterMenuTab"],
      sortable: true,
      autoHeight: true,
      wrapText: true,
    },
    {
      headerName: "AVAILABLE",
      field: "optionsReserved",
      filter: "agNumberColumnFilter",
      filterValueGetter: (params) =>
        stringToNumberConvertor(params.getValue("optionsReserved")),
      filterParams: filterparams,
      menuTabs: ["filterMenuTab"],
      sortable: true,
      autoHeight: true,
      wrapText: true,
    },
    {
      headerName: "STATE",

      field: "esopPlanState",

      sortable: true,

      filter: "agMultiColumnFilter",
      comparator(valueA, valueB, ..._other) {
        return valueA.props.plan.esopPlanState?.localeCompare(
          valueB.props.plan.esopPlanState
        );
      },
      filterParams: {
        maxNumConditions: 5,
        buttons: ["reset"],
        filters: [
          {
            title: "Plan Status",
            filter: "agSetColumnFilter",
            display: "subMenu",
            filterParams: {
              keyCreator: (params: any) => {
                const status =
                  params?.data?.esopPlanState?.props?.plan?.esopPlanState;
                return status; // Custom keyCreator logic
              },
              valueFormatter: (params: any) => {
                const status = params?.value?.props?.plan?.esopPlanState;
                return status;
              },
            },
          },
        ],
      },
      suppressSizeToFit: true,
      menuTabs: ["filterMenuTab"],
      cellRendererParams: ({ value }: { value: { props: any } }) => value.props,
      cellRenderer: PlanInfoCell,
    },
    {
      headerName: "",
      field: "actions",
      pinned: "right",
      hide: false,
      width: 80,
      maxWidth: 80,
      filter: false,
      colId: "action-column",
      suppressNavigable: true,
      suppressColumnsToolPanel: true,
      menuTabs: ["columnsMenuTab"],
      resizable: false,
      sortable: false,
      cellRendererParams: ({ value }: { value: any }) => value.props,
      cellRenderer: CTADropdown,
    },
  ];

  const rowData = useMemo(
    () =>
      planTableData?.map((plan) => ({
        planName: (
          <PlanInfoRender
            planName={plan.planName}
            planDescription={plan.planDescription}
          />
        ),
        totalShares: (
          (plan?.totalShares || 0) * (plan?.conversionNumber || 1)
        ).toLocaleString(currency),
        issuedShares: (
          (plan?.totalShares || 0) * (plan?.conversionNumber || 1) -
          (plan?.optionsReserved || 0)
        ).toLocaleString(currency),
        optionsReserved: plan?.optionsReserved.toLocaleString(currency),
        esopPlanState: <PlanInfoCell plan={plan}></PlanInfoCell>,
        actions: (
          <CTADropdown
            actions={[
              {
                name: "Amend Plan",
                disabled:
                  plan.esopPlanState === "Amendment" ||
                  isUserAdminViewer ||
                  isUserEsopViewer,
              },
              {
                name: "Update Plan Status",
                disabled:
                  plan.esopPlanState === "Active" ||
                  plan.esopPlanState === "Rejected" ||
                  isUserEsopViewer ||
                  isUserAdminViewer,
              },
              {
                name: "Delete Plan",
                disabled:
                  plan.issuedShares > 0 ||
                  isUserEsopViewer ||
                  isUserAdminViewer,
              },
              {
                name: "Upload Plan Document",
                disabled: isUserAdminViewer || isUserEsopViewer,
              },
              {
                name: "Modify Plan Ownership",
                disabled: !(
                  authority === "ROLE_ADMIN" || authority === "ROLE_ESOP_ADMIN"
                ),
              },
            ]}
            onAction={(action) => handleAction(plan, action)}
          />
        ),
      })),
    [planTableData]
  );
  const onPageSizeChanged = useCallback(() => {
    const value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridApi.current?.paginationSetPageSize(Number(value));
  }, []);

  const onGridReady = (params: any) => {
    gridApi.current = params.api;
  };
  const topGrid = useRef(null);
  const bottomGrid = useRef(null);

  return (
    <AgGridReact
      ref={topGrid}
      alignedGrids={bottomGrid.current ? [bottomGrid.current] : undefined}
      onGridReady={onGridReady}
      alwaysShowHorizontalScroll
      suppressRowTransform={true}
      alwaysMultiSort
      sideBar={sideBar}
      animateRows={true}
      defaultColDef={defaultColDef}
      rowData={rowData}
      columnDefs={columnDefs}
      onCellClicked={handleCellClick}
      suppressCopyRowsToClipboard={true}
      suppressCopySingleCellRanges={true}
      suppressCellFocus={true}
      suppressMenuHide={true}
      rowClass={"border-t border-dashed cursor-pointer hover:bg-slate-50 "}
      pagination={true}
      overlayNoRowsTemplate={
        '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
      }
    ></AgGridReact>
  );
}

function PlanInfoRender(props: { planName: string; planDescription?: string }) {
  return (
    <HStack className="align-top px-2 py-2">
      <Avatar name={props.planName} />
      <Box className="px-2 pb-6">
        <p className="text-xs font-medium text-gray-dark">{props.planName}</p>
        <p className="capitalize text-xxs text-gray-light text-wrap">
          {props?.planDescription?.toLowerCase()}
        </p>
      </Box>
    </HStack>
  );
}

const PlanInfoCell: React.FC<PlanInfoCellProps> = ({ plan }) => (
  <HStack className="px-2 py-2 align-middle">
    <Box>
      <HStack className="">
        <PlanStatusLabel state={plan.esopPlanState || ""} />
      </HStack>
    </Box>
  </HStack>
);
export default PlanTableAgGrid;
