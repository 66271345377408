import jwtDecode from "jwt-decode";
import * as React from "react";
import { currencyTypes } from "../constants/CompanyCurrencyType";
import { useAuthorizationStore, useAuthStore } from "../store";
/*
Wrapper components around regular divs to improve code readability
with nested divs
*/

export function HStack(
  props: React.HTMLAttributes<HTMLDivElement>
): React.ReactElement {
  const className = `flex flex-row ${props.className}`;
  return <div {...props} className={className}></div>;
}

export function VStack(
  props: React.HTMLAttributes<HTMLDivElement>
): React.ReactElement {
  const className = `flex flex-col ${props.className}`;
  return <div {...props} className={className}></div>;
}

export function Center(
  props: React.HTMLAttributes<HTMLDivElement>
): React.ReactElement {
  const className = `flex items-center justify-center ${props.className}`;
  return <div {...props} className={className}></div>;
}

export function Box(
  props: React.HTMLAttributes<HTMLDivElement>
): React.ReactElement {
  const className = `${props.className}`;
  return <div {...props} className={className}></div>;
}

export function ButtonPrimary(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
) {
  const { disabled, className, type, ...rest } = props;
  const _type = type || "button";
  const _className = `px-5 py-2 font-normal text-white rounded h-11 text-sm1 bg-orange-p focus:opacity-90 hover:bg-orange-500 cursor-pointer 
    ${
      disabled && "cursor-not-allowed bg-gray-300 hover:bg-gray-300"
    } ${className}`;
  return (
    <button
      disabled={disabled}
      className={_className}
      type={_type}
      {...rest}
    ></button>
  );
}

export function ButtonPrimary1(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
): React.ReactElement {
  const { className, type, ...rest } = props;
  const _type = type || "button";
  const _className = `px-8 py-2 font-normal bg-slate-light rounded h-11 text-sm1 text-orange-p focus:opacity-90 hover:bg-slate-200  ${className}`;
  return <button {...rest} className={_className} type={_type}></button>;
}

export function ButtonSecondary(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
): React.ReactElement {
  const { className, type, ...rest } = props;
  const _type = type || "button";
  const _className = `px-5 py-2 font-normal rounded h-11 text-gray-dark text-sm1 bg-gray-light focus:opacity-90 hover:bg-gray-100 ${className}`;
  return <button {...rest} className={_className} type={_type}></button>;
}

export function ButtonCancelSecondary(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
): React.ReactElement {
  const { className, type, ...rest } = props;
  const _type = type || "button";
  const _className = `p-2 font-normal rounded h-8 text-gray-dark text-sm1 bg-gray-light focus:opacity-90 hover:bg-gray-100 ${className}`;
  return <button {...rest} className={_className} type={_type}></button>;
}

export function Error({ text }: { text?: string }) {
  return <p className="text-[9px] text-red-500 my-0.5">{text}</p>;
}

function getAccessToken(): string {
  const token = localStorage.getItem("accesstoken") as string;
  return token;
}
export function redirectToHissa(path: string, postLogout = false) {
  const hissaUrl =
    process.env.REACT_APP_LEGACY_URL ||
    localStorage.getItem("hissaUrl") ||
    "https://dev.hissa.com";
  if (postLogout) {
    window.location.href = hissaUrl;
    return;
  }
  window.location.href = `${hissaUrl}/${path}?accessToken=${getAccessToken()}&companyId=${localStorage.getItem(
    "companyId"
  )}`;
}

export function clearCredentials() {
  const credentialKeys = [
    "accesstoken",
    "hissaUrl",
    "companyId",
    "x-usr-token",
  ];
  credentialKeys.forEach((key) => {
    localStorage.removeItem(key);
  });
}

export function decodeAuthToken(accesstoken: string) {
  const decodedToken = jwtDecode(accesstoken) as {
    xUserToken: string;
    exp: number;
  };
  return decodedToken;
}

export function checkTokenValidity() {
  const CLOCK_SKEW_MARGIN = 1000 * 60 * 5; // 5 minutes
  const token = localStorage.getItem("accesstoken");
  if (!token) return true;
  const decodedToken = decodeAuthToken(token);
  const currentDate = new Date();
  if (decodedToken.exp * 1000 - currentDate.getTime() < CLOCK_SKEW_MARGIN)
    return false;
  return true;
}

export function revalidateAuth() {
  if (!checkTokenValidity()) {
    useAuthStore.setState((store) => ({ ...store, isAuthenticated: false }));
  }
}

export function getCompanyName() {
  const { user } = useAuthStore();
  return user?.company.nameOfTheCompany;
}

export function getCompanyPreponeSettings() {
  const { user } = useAuthStore();
  return user?.company?.preponeVesting || false;
}

export function isAdminViewer() {
  const { authority } = useAuthorizationStore();
  return authority === "ADMIN_VIEWER";
}

export function isEsopViewer() {
  const { authority } = useAuthorizationStore();
  return authority === "ROLE_ESOP_VIEWER";
}
