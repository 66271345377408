import { useFormik } from "formik";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { Input, Label } from "../../components/shared/InputField";
import { Box, ButtonPrimary, HStack, VStack } from "../../components/utils";
import { useGetOptionBuyback } from "../../queries/optionBuyback";
import { optionBuybackDetail } from "../../types/OptionBuyback";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { formatDisplayDate } from "../../utils/date";
import StatusLabel from "../esopOverview/StatusLabel";

function LiquidationDetails() {
  const currencySymbol = getCurrencySymbol();
  const currency = getCurrencyType();
  const navigate = useNavigate();
  const { id } = useParams();
  const _id = id || "";

  const { data: optionBuybackOverview } = useGetOptionBuyback();
  let buybackDetail: optionBuybackDetail[] = [];
  if (_id !== " ") {
    buybackDetail = (optionBuybackOverview || [])?.filter(
      (item) => parseInt((item?.id || 0)?.toString(), 10) === parseInt(_id, 10)
    );
  }
  const initialValues = {
    name: buybackDetail[0]?.name,
    startDate: formatDisplayDate(buybackDetail[0]?.startDate || "1979-01-01"),
    percentage: ((buybackDetail[0]?.percentage || 0) * 100).toPrecision(3),
    price: buybackDetail[0]?.price.toLocaleString(),
    vestingCutOffDate: formatDisplayDate(
      buybackDetail[0]?.vestingCutOffDate || "1979-01-01"
    ),
    endDate: formatDisplayDate(buybackDetail[0]?.endDate || "1979-01-01"),
    description: buybackDetail[0]?.description,
    willingNess: buybackDetail && buybackDetail[0]?.isWillingNessGiven,
    liquidationEndDate: formatDisplayDate(
      buybackDetail[0]?.liquidationEndDate || "1979-01-01"
    ),
    sellingPrice: buybackDetail && buybackDetail[0]?.sellingPrice,
    buyerName: buybackDetail && buybackDetail[0]?.buyerName,
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: {},
    onSubmit: () => {},
  });

  return (
    <VStack className="gap-4 p-6">
      <>
        <Box className="flex items-center gap-12">
          <p className="text-lg font-medium text-stone-700">
            {buybackDetail[0]?.name}
          </p>
          <StatusLabel state={buybackDetail[0]?.elState || ""} />
        </Box>
        <VStack className="gap-12 bg-[#fefefe] rounded drop-shadow-md mx-4 p-6">
          <VStack className="w-4/5 gap-4 mt-4">
            <HStack className="justify-start w-full gap-12">
              <Box className="flex items-center justify-between w-1/2 gap-4 ">
                <Label>Liquidation Program Name</Label>
                <VStack>
                  <Input
                    type="text"
                    className="text-black bg-white"
                    readOnly
                    {...formik.getFieldProps("name")}
                  />
                </VStack>
              </Box>
              <Box className="flex items-center justify-between w-1/2 gap-4 ">
                <Label>Exercise Start Date</Label>
                <VStack>
                  <Input
                    type="text"
                    className="text-black bg-white"
                    readOnly
                    {...formik.getFieldProps("startDate")}
                  />
                </VStack>
              </Box>
            </HStack>
            <HStack className="justify-start w-full gap-12">
              <Box className="flex items-center justify-between w-1/2 gap-4 ">
                <Label>Exercise End Date</Label>
                <VStack>
                  <Input
                    type="text"
                    className="text-black bg-white"
                    readOnly
                    {...formik.getFieldProps("endDate")}
                  />
                </VStack>
              </Box>
              <Box className="flex items-center justify-between w-1/2 gap-4 ">
                <Label>Description</Label>
                <VStack>
                  <Input
                    type="text"
                    className="text-black bg-white"
                    readOnly
                    {...formik.getFieldProps("description")}
                  />
                </VStack>
              </Box>
            </HStack>
            <HStack className="justify-start gap-12 text-sm ">
              <Box className="flex items-center justify-between w-1/2 gap-4 ">
                <Label>Vesting End Date</Label>
                <VStack>
                  <Input
                    type="text"
                    className="text-black bg-white"
                    readOnly
                    {...formik.getFieldProps("vestingCutOffDate")}
                  />
                </VStack>
              </Box>
              <Box className="flex items-center justify-between w-1/2 gap-4 ">
                <Label>Liquidation End Date</Label>
                <VStack>
                  <Input
                    type="text"
                    className="text-black bg-white"
                    readOnly
                    {...formik.getFieldProps("liquidationEndDate")}
                  />
                </VStack>
              </Box>
            </HStack>
          </VStack>
        </VStack>
      </>
    </VStack>
  );
}

export default LiquidationDetails;
