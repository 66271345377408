import { CircularProgress, Dialog } from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import { format } from "date-fns";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { ChartData } from "chart.js";
import * as React from "react";
import { useParams } from "react-router";
import DeleteIcon from "@mui/icons-material/Delete";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import {
  ButtonPrimary1,
  Error,
  ButtonPrimary,
  HStack,
  VStack,
} from "../../components/utils";
import { useEmployees } from "../../queries/employees";
import { sort } from "../../utils/arrays";
import { useVestingTemplates } from "../../queries/vestingTemplate";
import { useAddGrant, useEditGrant, useEsopPlans } from "../../queries";
import { Employee } from "../../types/Employee";
import { EsopPlan } from "../../types/EsopPlan";
import { formatWithTimeZone } from "../../utils/date";
import { AddGrantReq, Grant } from "../../types/Grant";
import { VestingTriggerType, VestingType } from "../../types/VestingTemplate";
import { generateProjections } from "../vestingSchedules/generateProjections";
import { BarChart } from "../vestingSchedules/BarChart";
import AlertDialog from "../../components/shared/AlertDialog";
import FileInput from "../../components/shared/FileInput";
import convertToBase64 from "../../utils/convertToBase64";
import { useGrantCreatedDialog } from "../../store/useDialogStore";
import { useError } from "../../store/errorStore";
import LazyPdfDocument from "../../components/shared/LazyPdfDocument";
import canUserBeAssignedToThisPlan from "../../utils/grantRule";
import { useAuthStore } from "../../store";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import SearchDropDown from "../../components/shared/SearchDropdown";

type AddOrEditGrantProps = {
  mode?: "Edit" | "Clone" | "Add" | "Delete";
  onClose: () => void;
  grant?: Grant;
};

export function AddOrEditGrant(props: AddOrEditGrantProps) {
  const { user } = useAuthStore();
  const currency = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const { id } = useParams();
  const _id = id || "";
  const { setState: setGrantCreatedDialog } = useGrantCreatedDialog();
  const errorMessage = useError();
  const [showDialog, setShowDialog] = useState(false);
  const { mode, onClose, grant } = props;
  const [grantByAmount, setGrantByAmount] = useState(false);
  const [sharePrice, setSharePrice] = useState(1);
  const [amountGranted, setAmountGranted] = useState(0);
  const { data: _employees } = useEmployees();
  const employees = sort(_employees || [], "employeeName");
  const employedEmployees = employees.filter(
    (employee) => employee.employmentStatus === "Employed"
  );
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();
  const { data: _plans } = useEsopPlans();
  const [selectedPlan, setSelectedPlan] = useState<EsopPlan>();
  const plans = _plans || [];
  let singlePlanName: string = "";
  if (_id !== "") {
    const individualPlan = plans.filter(
      (plan) => plan.esopPlanId === parseInt(_id, 10)
    )[0];
    singlePlanName = individualPlan?.planName;
  }

  useEffect(() => {
    if (mode === "Add" && _id !== "") {
      const individualPlan = plans.filter(
        (plan) => plan.esopPlanId === parseInt(_id, 10)
      )[0];
      setSelectedPlan(individualPlan);
    }
    if (mode === "Clone" || mode === "Edit") {
      const individualPlan = plans.filter(
        (plan) => plan.planName === grant?.planName
      )[0];
      setSelectedPlan(individualPlan);
    }
  }, [mode, _id]);
  const { data: vestingTemplates } = useVestingTemplates();
  const vestingScheduleTemplates = vestingTemplates?.filter(
    (schedule) => !schedule.isDefault
  );
  const _vestingTemplateNames = vestingScheduleTemplates?.map(
    (v) => v.vestingTemplateName
  );
  const vestingTemplateNames = _vestingTemplateNames || [];
  const vestingTypes = [
    { description: "Vesting From date of Grant", value: "GrantDate" },
    {
      description: "Vesting From date of Joining",
      value: "EmployeeJoiningDate",
    },
    { description: "Custom Vesting", value: "CustomDate" },
  ];
  const employeeJoiningDate = new Date(
    selectedEmployee?.dateOfJoin || "1900-01-02"
  );
  employeeJoiningDate.setDate(employeeJoiningDate.getDate() - 1);
  employeeJoiningDate.setHours(23);
  const minStartDate =
    new Date(selectedPlan?.planStartDate || "1900-01-02") >
    new Date(selectedEmployee?.dateOfJoin || "1900-01-02")
      ? new Date(selectedPlan?.planStartDate || "1900-01-02")
      : new Date(selectedEmployee?.dateOfJoin || "1900-01-02");
  minStartDate.setDate(minStartDate.getDate() - 1);
  minStartDate.setHours(23);
  const baseValues = {
    employeeId: 0,
    employeeName: "",
    employeeIdentificationString: "",
    planName: singlePlanName || "",
    vestingDateType: "GrantDate",
    vestingTemplate: "",
    optionsGranted: 0,
    grantPrice: 0,
    dateOfGrant: format(new Date(), "yyyy-MM-dd"),
    vestingDate: format(new Date(), "yyyy-MM-dd"),
    isGrantLetterProvided: false,
    grantLetterType: "",
    grantLetter: "",
    grantLetterFileName: "Attach File",
    customizedGrantLetter: false,
  };
  function getInitialValuesForEditing(grant: Grant) {
    return {
      id: grant?.optionHolderId,
      employeeId: grant.employeeId,
      employeeName: grant.optionHolderName,
      employeeIdentificationString:
        employees.find((emp) => emp.id === grant.employeeId)
          ?.employeeIdentificationString || "",
      planName: grant.planName,
      vestingDateType: grant.vestingDateType,
      vestingTemplate: grant.vestingTemplateName,
      optionsGranted: grant.optionsGranted,
      grantPrice: grant.grantPrice,
      dateOfGrant: format(new Date(grant.grantDate), "yyyy-MM-dd"),
      vestingDate: format(new Date(grant.vestingDate), "yyyy-MM-dd"),
      grantLetterFileName: "",
      isGrantLetterProvided: grant.customizedGrantLetter,
      customizedGrantLetter: grant.customizedGrantLetter,
    };
  }
  function getInitialValuesForCloning(grant: Grant) {
    return {
      employeeId: 0,
      employeeName: "",
      employeeIdentificationString: "",
      planName: grant.planName,
      vestingDateType: grant.vestingDateType,
      vestingTemplate: grant.vestingTemplateName,
      optionsGranted: grant.optionsGranted,
      grantPrice: grant.grantPrice,
      dateOfGrant: format(new Date(grant.grantDate), "yyyy-MM-dd"),
      vestingDate: format(new Date(grant.vestingDate), "yyyy-MM-dd"),
      grantLetterFileName: "Attach File",
      isGrantLetterProvided: false,
      customizedGrantLetter: false,
    };
  }
  function getInitialValues() {
    if (mode === "Edit" && grant) {
      return getInitialValuesForEditing(grant);
    } else if (mode === "Clone" && grant) {
      return getInitialValuesForCloning(grant);
    } else {
      return baseValues;
    }
  }

  const allowedOptions =
    mode === "Edit"
      ? (selectedPlan?.optionsReserved || 0) + (grant?.optionsGranted || 0) || 0
      : selectedPlan?.optionsReserved || 0;
  const validationSchema = Yup.object().shape({
    employeeId: Yup.number().required("required").notOneOf([0], "required"),
    employeeName: Yup.string(),
    planName: Yup.string().required("required"),
    vestingDateType: Yup.string().oneOf(vestingTypes.map((v) => v.value)),
    optionsGranted: Yup.number()
      .max(
        allowedOptions,
        `cannot issue more than ${allowedOptions} available options in this plan`
      )
      .moreThan(0, "minimum one option is required")
      .required("options to be granted is required"),
    grantPrice: Yup.number()
      .required("required")
      .moreThan(0, "grant price should be more than 0"),
    vestingTemplate: Yup.string()
      .required("required")
      .oneOf(
        vestingTemplateNames.map((v) => v.toString()),
        "invalid vesting template"
      ),
    vestingDate: Yup.date()
      .required("required")
      .min(
        employeeJoiningDate,
        `Vesting date should not be before employee joining date (
        ${format(
          new Date(selectedEmployee?.dateOfJoin || "1900-01-01"),
          "dd-MM-yyyy"
        )})`
      ),
    dateOfGrant: Yup.date()
      .required("required")
      .min(
        minStartDate,
        `Grant date should not be before plan start date (
      ${format(
        new Date(selectedPlan?.planStartDate || "1900-01-01"),
        "dd-MM-yyyy"
      )}) and employee join date (
        ${format(
          new Date(selectedEmployee?.dateOfJoin || "1900-01-01"),
          "dd-MM-yyyy"
        )})`
      ),
  });
  const {
    mutate: addGrant,
    isLoading: addGrantLoading,
    status: addGrantStatus,
  } = useAddGrant();
  const { mutate: editGrant, isLoading: editGrantLoading } = useEditGrant();
  const isLoading = mode === "Edit" ? editGrantLoading : addGrantLoading;

  const [documentDialog, setDocumentDialog] = useState<{
    open: boolean;
    grant?: Grant;
  }>({ open: false, grant: undefined });
  const formik = useFormik<AddGrantReq>({
    initialValues: getInitialValues(),
    enableReinitialize: true,
    validationSchema,
    onSubmit: () => {
      const grantDto = { ...formik.values };
      grantDto.vestingDate = formatWithTimeZone(grantDto.vestingDate);
      grantDto.dateOfGrant = formatWithTimeZone(grantDto.dateOfGrant);
      setShowDialog(true);
      if (mode === "Edit") {
        editGrant(grantDto, {
          onSuccess: (res) => {
            formik.resetForm();
            toast("Grant Edited Successfully!", { type: "success" });
            onClose();
          },
          onError: (err: any) => {
            errorMessage.setMessage(err.response.data.reason);
            toast(err.response.data.reason, { type: "error", autoClose: 2000 });
          },
        });
      } else {
        addGrant(grantDto, {
          onSuccess: (result) => {
            formik.resetForm();
            setGrantCreatedDialog({
              open: true,
              grantId: result.optionHolderId,
              variant: "created",
            });
            onClose();
            toast("Grant Added Successfully!", { type: "success" });
          },
          onError: (err: any) => {
            errorMessage.setMessage(err.response.data.reason);
            toast(err.response.data.reason, { type: "error", autoClose: 2000 });
          },
        });
      }
    },
  });
  useEffect(() => {
    if (grantByAmount) {
      formik.setFieldValue(
        "optionsGranted",
        Math.round(amountGranted / sharePrice)
      );
    }
  }, [amountGranted, grantByAmount, sharePrice]);

  useEffect(() => {
    handleGrantDateChange(formik.values.dateOfGrant);
  }, [formik.values.dateOfGrant]);

  useEffect(() => {
    handleVestingDateTypeChange(formik.values.vestingDateType);
  }, [formik.values.vestingDateType]);

  const plansToShow = useMemo(() => {
    const selectedEmployee = employees.find(
      (employee) => employee.id === formik.values.employeeId
    );
    formik.values.planName = "";
    if (selectedEmployee)
      return (
        _plans?.filter((plan) =>
          canUserBeAssignedToThisPlan(
            plan.planType || "",
            selectedEmployee.employmentType?.toString() || "",
            user?.company.companyCurrency || "INR - "
          )
        ) || []
      );
    return _plans || [];
  }, [formik.values.employeeId]);

  const handleGrantDateChange = (date: string) => {
    if (formik.values.vestingDateType === "GrantDate") {
      formik.setFieldValue("vestingDate", date);
    }
  };

  const handleVestingDateTypeChange = (type: string) => {
    if (type === "GrantDate") {
      formik.setFieldValue("vestingDate", formik.values.dateOfGrant);
    }
    if (type === "EmployeeJoiningDate" && selectedEmployee?.dateOfJoin) {
      formik.setFieldValue(
        "vestingDate",
        format(new Date(selectedEmployee.dateOfJoin), "yyyy-MM-dd")
      );
    }
  };

  async function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    formik.setFieldValue("grantLetter", base64);
    formik.setFieldValue("isGrantLetterProvided", true);
    if (
      file.type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    )
      formik.setFieldValue("grantLetterType", "docx");
    else {
      formik.setFieldValue("grantLetterType", "pdf");
    }
  }
  function getHeaderText() {
    if (mode === "Edit") return "Edit Grant";
    else if (mode === "Clone") return "Clone Grant";
    else return "Create Grant";
  }
  function handleDelete() {
    formik.setFieldValue("grantLetter", null);
    formik.setFieldValue("isGrantLetterProvided", false);
    formik.setFieldValue("customizedGrantLetter", false);
  }
  return (
    <div className="">
      <div className="px-10 text-lg font-medium border-b py-7">
        <h6 className="flex justify-between">
          {getHeaderText()}{" "}
          <span onClick={() => onClose()} className="cursor-pointer">
            X
          </span>
        </h6>
      </div>
      <VStack className="w-full px-10 py-7 gap-9">
        <HStack className="gap-8 ">
          <div className="flex-1">
            <Label className="text-sm font-normal">Option Holder</Label>
            <SearchDropDown
              onChange={(e, value) => {
                formik.setFieldValue("employeeId", value?.id);
                formik.setFieldValue(
                  "employeeIdentificationString",
                  value?.employeeIdentificationString
                );
                formik.setFieldValue("employeeName", value?.employeeName);
                setSelectedEmployee(
                  employees.find((emp) => emp.id === value?.id)
                );
              }}
              placeholder="--Select--"
              options={employedEmployees}
              value={
                employedEmployees.filter(
                  (item) => item.id === Number(formik.values.employeeId)
                )[0] || null
              }
              className={""}
              getOptionLabel={(option) =>
                `${option.employeeName} (${option.employeeIdentificationString})`
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            {formik.touched.employeeId && formik.errors.employeeId && (
              <Error text={formik.errors.employeeId} />
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Plan Name</Label>
            <Select
              disabled={mode === "Edit"}
              options={plansToShow}
              value={formik.values.planName}
              name="planName"
              valueGetter={(plan) => plan.planName}
              textGetter={(plan) => plan.planName}
              onChange={(e) => {
                formik.handleChange(e);
                setSelectedPlan(
                  plans.find((plan) => plan.planName === e.target.value)
                );
              }}
            />
            {formik.touched.planName && formik.errors.planName && (
              <Error text={formik.errors.planName} />
            )}
          </div>
          <div className="flex-1">
            <div className="flex items-center justify-between">
              <Label
                className={`text-sm font-normal ${
                  grantByAmount && "text-gray-300"
                }`}
              >
                No of Options
              </Label>
              {selectedPlan && (
                <Label className="text-xs font-normal text-green-400">
                  {selectedPlan?.optionsReserved.toLocaleString(currency)}{" "}
                  options available
                </Label>
              )}
            </div>
            <Input
              max={allowedOptions}
              disabled={grantByAmount}
              type="number"
              {...formik.getFieldProps("optionsGranted")}
            />
            {formik.touched.optionsGranted && formik.errors.optionsGranted && (
              <Error text={formik.errors.optionsGranted} />
            )}
          </div>
        </HStack>
        <HStack className="gap-8">
          <div className="flex-1">
            <Label className="text-sm font-normal">
              Grant Price ({currencySymbol})
            </Label>
            <Input type="number" {...formik.getFieldProps("grantPrice")} />
            {formik.touched.grantPrice && formik.errors.grantPrice && (
              <Error text={formik.errors.grantPrice} />
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Grant Date</Label>
            <Input
              type="date"
              name="dateOfGrant"
              value={formik.values.dateOfGrant}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                if (!e.target.value) return;
                formik.handleChange(e);
              }}
            />
            {formik.touched.dateOfGrant && formik.errors.dateOfGrant && (
              <Error text={formik.errors.dateOfGrant} />
            )}
          </div>
          <div className="flex-1 max-w-[30%]">
            <Label className="block text-sm font-normal">
              Custom Grant Letter
            </Label>
            <div className="py-1.5">
              {formik.values.customizedGrantLetter && mode !== "Clone" ? (
                <>
                  <div className="flex flex-row items-center justify-between cursor-pointer align-center">
                    <div
                      className="text-red-500 underline "
                      onClick={() => setDocumentDialog({ open: true, grant })}
                    >
                      Grant Letter.docx
                    </div>
                    <button
                      onClick={() => handleDelete()}
                      className="ml-6 text-zinc-300 hover:scale-105"
                    >
                      <DeleteIcon />
                    </button>
                    <FileInput
                      accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      file={formik.values.grantLetterFileName || "Replace"}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "grantLetterFileName",
                          e.target.value
                        );
                        handleFileUpload(e);
                      }}
                    />
                  </div>
                </>
              ) : (
                <FileInput
                  accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  file={formik.values.grantLetterFileName || "Attach File"}
                  onChange={(e) => {
                    formik.setFieldValue("grantLetterFileName", e.target.value);
                    handleFileUpload(e);
                  }}
                />
              )}
            </div>
          </div>
        </HStack>
        <Dialog
          open={documentDialog.open}
          onClose={() => setDocumentDialog({ open: false })}
          maxWidth="lg"
        >
          <LazyPdfDocument url={documentDialog?.grant?.grantLetterLink || ""} />
        </Dialog>
        <HStack className="gap-8">
          <VStack className="w-1/3 gap-8">
            <div>
              <Label className="text-sm font-normal">Vesting Schedule</Label>
              <Select
                disabled={mode === "Edit"}
                options={vestingTemplateNames}
                {...formik.getFieldProps("vestingTemplate")}
              />
              {formik.touched.vestingTemplate &&
                formik.errors.vestingTemplate && (
                  <Error text={formik.errors.vestingTemplate} />
                )}
            </div>
            <div>
              <Label className="text-sm font-normal">Vesting Date Type</Label>
              <Select
                options={vestingTypes}
                value={formik.values.vestingDateType}
                textGetter={(option) => option?.description}
                valueGetter={(value) => value?.value}
                onChange={(option) => {
                  formik.setFieldValue(
                    "vestingDateType",
                    vestingTypes.find((v) => v.value === option?.target.value)
                      ?.value
                  );
                }}
              />
            </div>
            <div>
              <Label
                className={`text-sm font-normal ${
                  formik.values.vestingDateType !== "CustomDate" &&
                  "text-gray-400"
                }`}
              >
                Select a Date
              </Label>
              <Input
                type="date"
                disabled={formik.values.vestingDateType !== "CustomDate"}
                name="vestingDate"
                value={formik.values.vestingDate}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  if (!e.target.value) return;
                  formik.handleChange(e);
                }}
              />
              {formik.touched.vestingDate && formik.errors.vestingDate && (
                <Error text={formik.errors.vestingDate} />
              )}
            </div>
          </VStack>
          <HStack className="items-center -mb-10 grow">
            <ProjectionChart
              templateName={formik.values.vestingTemplate}
              optionsGranted={formik.values.optionsGranted}
              vestingStartDate={new Date(formik.values.vestingDate)}
            />
          </HStack>
        </HStack>
        <HStack className="justify-between my-10 justify">
          <ButtonPrimary1 onClick={() => onClose()}>Back</ButtonPrimary1>
          <ButtonPrimary1
            onClick={() => {
              formik.resetForm();
            }}
          >
            Clear
          </ButtonPrimary1>
          <div className="flex items-center">
            <ButtonPrimary
              className={`${!formik.isValid && "bg-gray-400"}`}
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              {isLoading ? (
                <Icon
                  className="animate-spin"
                  icon="lucide:loader-2"
                  width={36}
                />
              ) : (
                "Save & Continue"
              )}
            </ButtonPrimary>
          </div>
        </HStack>
      </VStack>
      <AlertDialog
        open={showDialog && addGrantStatus === "success"}
        message="Grant Uploaded successfully"
        primaryActionText="Create a New Grant"
        secondaryActionText="Ok Got it!"
        onPrimaryAction={() => setShowDialog(false)}
        onSecondaryAction={() => setShowDialog(false)}
        onClose={() => setShowDialog(false)}
      />
      <AlertDialog
        open={showDialog && addGrantStatus === "error"}
        error
        message={errorMessage.message}
        primaryActionText="Retry"
        secondaryActionText="Go Back"
        onPrimaryAction={() => {
          errorMessage.reset();
          setShowDialog(false);
        }}
        onSecondaryAction={() => {
          errorMessage.reset();
          setShowDialog(false);
        }}
        onClose={() => {
          errorMessage.reset();
          setShowDialog(false);
        }}
      />
    </div>
  );
}

function ProjectionChart({
  templateName,
  optionsGranted = 0,
  vestingStartDate = new Date(new Date().getFullYear(), 0, 1),
}: {
  templateName: string;
  optionsGranted?: number;
  vestingStartDate?: Date;
}) {
  const { data: _templates } = useVestingTemplates();
  const templates = _templates || [];
  const vestingTemplates = templates?.filter((schedule) => !schedule.isDefault);
  const template = vestingTemplates.find(
    (template) => template.vestingTemplateName === templateName
  );
  template?.schedules.forEach((t) => {
    t.percentage /= 100;
  });

  const vestings = generateProjections({
    cliffPeriod: template?.cliffPeriod || 12,
    intervalUnit: "Month",
    optionsGranted,
    schedules: template?.schedules || [],
    vestingInterval: template?.vestingInterval || 0,
    vestingPeriod: template?.vestingPeriod || 0,
    vestingStartDate,
    vestingTriggerType: template?.vestingTriggerType || VestingTriggerType.Time,
    vestingType: template?.vestingType || VestingType.Standard,
  });

  const data: ChartData<"bar", number[], unknown> = {
    labels: vestings.map((v) => format(v.date, "MMM yy")),
    datasets: [
      {
        label: "to date",
        data: vestings.map(
          (v) => v.accumulatedVestedOptionsForGrant - v.vestedOptions
        ),
        backgroundColor: "#F8B195",
      },
      {
        label: "on date",
        data: vestings.map((v) => v.vestedOptions),
        backgroundColor: "#F67280",
        borderRadius: 4,
      },
    ],
  };

  return <BarChart data={data} />;
}
