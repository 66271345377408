import { useEffect, useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import * as _ from "lodash";
import { useFormikContext } from "formik";
import { toast } from "react-toastify";
import { Dialog } from "@mui/material";
import {
  Box,
  ButtonPrimary,
  Center,
  HStack,
  VStack,
} from "../../components/utils";
import { globalFilter, sort } from "../../utils/arrays";
import BasicMenu from "../../components/shared/Menu";
import Pagination from "../../components/shared/Pagination";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import {
  Allotment,
  HistoricalRoundModel,
  HistoricalRoundPopupDetail,
  Investor,
  Secondary,
} from "../../types/HistoricDataModel";
import AlertDialog from "../../components/shared/AlertDialog";
import AddRoundName from "../../modals/AddRoundName";
import {
  initialAllotmentName,
  initialPrimaryTransaction,
  initialRoundDetail,
} from "./initValues";
import AddPrimaryTransaction from "../../modals/AddPrimaryTransaction";
import AddConversion from "../../modals/AddConversion";
import AddRedemption from "../../modals/AddRedemption";
import { AllotmentTag, OverViewTags } from "../../components/AllotmentTag";
import AddInvestorProfile from "../../modals/AddInvestorProfileDialog";
import {
  getOptionsForTransaction,
  getTotalAmount,
  getTotalShares,
} from "../../utils/historicUtilities";
import { formatDate } from "../../utils/date";
import { buyBackTag, conversionTag, redemptionTag } from "./constantValues";

export default function PrimaryHistoricTransactionTable() {
  const formik = useFormikContext<HistoricalRoundModel>();
  const { values, setFieldValue } = formik;

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [globalFilterText, setGlobalFilterText] = useState("");

  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    data?: HistoricalRoundPopupDetail | Investor;
    mode?: string;
    index?: number;
  }>({
    open: false,
    mode: "AddRound",
  });
  const [index, setIndex] = useState<number>(values.investors.length);

  const [sortField, setSortField] = useState<{
    field: keyof Investor | "" | undefined;
    ascending: boolean;
  }>({ field: "date", ascending: false });

  const [selectedItems, setSelectedItems] = useState<Investor[]>([]);
  let investorList: Investor[] = values.investors || [];
  investorList = useMemo(() => {
    if (!investorList) return [];
    const filterResult = globalFilter(investorList, globalFilterText, [
      "investorName",
      "amount",
      "par",
      "premium",
      "numberOfShares",
      "date",
      "securityType",
    ]);
    const sortResult = sort(
      filterResult,
      sortField?.field,
      sortField?.ascending
    );
    return sortResult;
  }, [investorList, globalFilterText, sortField]);
  const onSelectionChange = (investor: Investor) => {
    setSelectedItems((state) =>
      state.find((individualInvestor) => individualInvestor === investor)
        ? state.filter((individualInvestor) => individualInvestor !== investor)
        : [...state, investor]
    );
  };

  useEffect(() => {
    if (selectAllChecked) {
      setSelectedItems(investorList);
    } else {
      setSelectedItems([]);
    }
  }, [investorList, selectAllChecked]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return investorList.slice(firstPageIndex, lastPageIndex);
  }, [investorList, currentPage, pageSize]);

  function handleAction(action: Action, investor?: Investor) {
    const index = values.investors.findIndex(
      (individualInvestor) => individualInvestor === investor
    );
    setIndex(index);
    if (action.name === "Edit" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Edit",
        data: investor,
        index,
      });
    } else if (action.name === "Add" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Add",
      });
    } else if (action.name === "Conversion" && !action.disabled) {
      handleAllotmentAdd(
        index,
        values.investors[index].allotment?.length || 0,
        "conversion"
      );
      setDialog({
        open: true,
        mode: "Conversion",
        data: investor,
        index,
      });
    } else if (action.name === "Redemption" && !action.disabled) {
      handleAllotmentAdd(
        index,
        values.investors[index].allotment?.length || 0,
        "redemption"
      );
      setDialog({
        open: true,
        mode: "Redemption",
        data: investor,
        index,
      });
    } else if (action.name === "InvestorProfile" && !action.disabled) {
      setDialog({
        open: true,
        mode: "InvestorProfile",
        data: investor,
        index,
      });
    } else if (action.name === "AddRound" && !action.disabled) {
      handleAddRound(values.historicalRoundPopupDetails.length);
      setDialog({
        open: true,
        mode: "AddRound",
      });
    } else if (action.name === "Delete" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Delete",
        data: investor,
        message: "Do you want to delete this Transaction Details?",
      });
    } else if (action.name === "Clone" && !action.disabled) {
      handleAddTransaction(values.investors.length, investor);
      setDialog({
        open: true,
        mode: "Clone",
        data: investor,
        index,
      });
    }
  }

  const deleteAllInvestors = () => {
    setDialog({
      open: true,
      mode: "DeleteAll",
      message: "Do you want to delete selected Transaction Details?",
    });
  };

  const deleteSelectedInvestors = () => {
    setFieldValue(
      "investors",
      values?.investors?.filter((investor) => !selectedItems.includes(investor))
    );
    setSelectAllChecked(false);

    toast("Transactions Deleted Successfully!", {
      type: "success",
      autoClose: 2000,
    });
    setDialog({ open: false });
  };

  function handleAddRound(index: number) {
    const roundDetail = [...values.historicalRoundPopupDetails];
    roundDetail.splice(index, 0, initialRoundDetail);
    setFieldValue("historicalRoundPopupDetails", roundDetail);
  }

  function handleAllotmentAdd(index: number, length: number, mode: string) {
    if (checkAllotmentAdd(index, mode)) {
      const allotmentDetail = [...(values.investors[index].allotment || [])];
      allotmentDetail.splice(length, 0, initialAllotmentName);
      setFieldValue(`investors[${index}].allotment`, allotmentDetail);
    }
  }
  function checkAllotmentAdd(index: number, mode: string): boolean {
    const length = values.investors[index].allotment?.length || 0;
    if (length < 1) {
      const allotmentDetail = values.investors[index].allotment;
      const allotment = allotmentDetail?.find(
        (allotment) => allotment.name === mode
      );
      return !allotment;
    }
    return true;
  }

  function handleAddTransaction(index: number, investor?: Investor) {
    const investors = [...values.investors];
    investors.splice(index, 0, investor || initialPrimaryTransaction);
    setFieldValue("investors", investors);
  }

  function deleteParticularTransaction(investor?: Investor) {
    setFieldValue(
      "investors",
      values?.investors?.filter(
        (individualInvestor) => individualInvestor !== investor
      )
    );
    setDialog({ open: false });
  }

  const checkPartiallyPaid = (investor: Investor): boolean => {
    const shareprice = (investor?.premium || 0) + (investor?.par || 0);
    const totalAmount =
      shareprice * (investor?.numberOfShares || 0) - shareprice;
    const total = (investor?.amount || 0) - shareprice;
    return total < totalAmount;
  };

  return (
    <div className="w-full bg-white rounded-md mt-10">
      <Box className="w-full bg-white rounded-lg min-w-min">
        <HStack aria-label="toolbar" className="justify-between mb-8">
          <VStack className="min-w-max">
            <p className="text-lg font-medium text-gray-dark">
              Primary Transactions
            </p>
            <p className="text-xs font-medium text-gray-light">
              {currentTableData.length} Results
            </p>
          </VStack>
          <HStack className="h-11 min-w-min">
            {selectedItems.length > 0 && (
              <Center className="p-2 px-3 mx-2 rounded text-slate-dark bg-slate-light">
                <Icon
                  icon="fluent:delete-28-regular"
                  color="#E85936"
                  className="rounded-xl"
                  onClick={deleteAllInvestors}
                  width="20"
                  height="24"
                />
              </Center>
            )}
            <HStack className="items-center p-2 rounded w-72 bg-slate-light text-slate-dark mx-2">
              <Icon icon="fe:search" width="24" className="mr-2 " />
              <input
                type="text"
                className="w-full font-medium border-0 outline-none text-xs2 bg-inherit"
                placeholder="Search"
                value={globalFilterText}
                onChange={(e) => {
                  setGlobalFilterText(e.target.value);
                }}
              ></input>
            </HStack>

            <Center className="p-2 px-3 mx-2 rounded text-slate-dark bg-slate-light">
              <Icon
                icon="fluent:add-28-regular"
                color="#E85936"
                className="rounded-xl"
                onClick={() => {
                  handleAddRound(values.historicalRoundPopupDetails.length);
                  setDialog({
                    open: true,
                    mode: "AddRound",
                  });
                }}
                width="20"
                height="24"
              />
            </Center>

            <ButtonPrimary
              className="min-w-max"
              onClick={() => {
                handleAddTransaction(values.investors.length);
                handleAction({
                  name: "Add",
                  disabled: false,
                });
              }}
            >
              Add
            </ButtonPrimary>
          </HStack>
        </HStack>
        <Dialog open={dialog.open} maxWidth="md">
          {dialog.mode === "Delete" ? (
            <AlertDialog
              onClose={() => setDialog({ open: false })}
              open={dialog.open}
              message={dialog.message}
              onPrimaryAction={() => deleteParticularTransaction(dialog.data)}
              onSecondaryAction={() => setDialog({ open: false })}
            />
          ) : dialog.mode === "DeleteAll" ? (
            <AlertDialog
              onClose={() => setDialog({ open: false })}
              open={dialog.open}
              message={dialog.message}
              onPrimaryAction={() => deleteSelectedInvestors()}
              onSecondaryAction={() => setDialog({ open: false })}
            />
          ) : dialog.mode === "Add" ? (
            <AddPrimaryTransaction
              transactionIndex={values.investors.length - 1}
              mode="Add"
              onPrimaryAction={() => setDialog({ open: false })}
              onSecondaryAction={() => {
                setDialog({ open: false });
              }}
            />
          ) : dialog.mode === "Clone" ? (
            <AddPrimaryTransaction
              mode="Clone"
              transactionIndex={values.investors.length - 1}
              onPrimaryAction={() => setDialog({ open: false })}
              onSecondaryAction={() => setDialog({ open: false })}
            />
          ) : dialog.mode === "Edit" ? (
            <AddPrimaryTransaction
              mode="Edit"
              transactionIndex={dialog.index!}
              onPrimaryAction={() => setDialog({ open: false })}
              onSecondaryAction={() => setDialog({ open: false })}
            />
          ) : dialog.mode === "AddRound" ? (
            <AddRoundName
              historicalRoundIndex={
                values.historicalRoundPopupDetails.length - 1
              }
              onPrimaryAction={() => setDialog({ open: false })}
              onSecondaryAction={() => setDialog({ open: false })}
            />
          ) : dialog.mode === "Conversion" ? (
            <AddConversion
              transactionIndex={dialog.index!}
              onPrimaryAction={() => setDialog({ open: false })}
              onSecondaryAction={() => setDialog({ open: false })}
              transactionType="investors"
            />
          ) : dialog.mode === "Redemption" ? (
            <AddRedemption
              transactionIndex={dialog.index!}
              onPrimaryAction={() => setDialog({ open: false })}
              onSecondaryAction={() => setDialog({ open: false })}
              transactionType="investors"
            />
          ) : dialog.mode === "InvestorProfile" ? (
            <AddInvestorProfile
              transactionIndex={dialog.index!}
              onPrimaryAction={() => setDialog({ open: false })}
              onSecondaryAction={() => setDialog({ open: false })}
              transactionType="investors"
            />
          ) : (
            <div></div>
          )}
        </Dialog>
        <Box className="overflow-auto max-h-full">
          <table className="w-full table-space">
            <thead className="text-xs font-medium text-gray-light">
              <tr className="">
                <td className="py-3">
                  <input
                    type="checkbox"
                    className="accent-orange-501 outline-hidden"
                    checked={selectAllChecked}
                    onChange={(e) => setSelectAllChecked(!selectAllChecked)}
                  ></input>
                </td>
                <>
                  <td
                    className="py-3 px-4  hover:cursor-pointer"
                    onClick={() =>
                      setSortField({
                        field: "investorName",
                        ascending: !sortField?.ascending,
                      })
                    }
                  >
                    Investor Name
                  </td>
                </>

                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "securityType",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Security
                </td>

                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "par",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  PAR
                </td>

                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "premium",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Premium
                </td>

                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "numberOfShares",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  No. of Shares
                </td>

                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "amount",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Amount
                </td>

                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "date",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Date
                </td>

                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "roundName",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Round
                </td>
                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "roundIdentifier",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Allotment
                </td>
                <td className="py-3"></td>
              </tr>
            </thead>
            <tbody className={""}>
              {currentTableData &&
                currentTableData?.map((investor: Investor, index) => (
                  <tr key={index} className="border-t border-dashed ">
                    <td className="py-5 align-top">
                      <Box>
                        <input
                          type="checkbox"
                          className="accent-orange-501 outline-hidden"
                          checked={selectedItems.indexOf(investor) !== -1}
                          onChange={(e) => onSelectionChange(investor)}
                        ></input>
                      </Box>
                    </td>

                    <td className="py-4 px-4 align-top ">
                      <HStack className="w-32 ">
                        <Box>
                          <p className={` text-xs font-medium text-gray-dark `}>
                            {investor.investorName}
                          </p>
                        </Box>
                      </HStack>
                    </td>

                    <td className="py-4 align-top ">
                      <HStack className="w-28 ">
                        <Box>
                          <p className={` text-xs font-medium text-gray-dark `}>
                            {investor.securityType}
                          </p>
                        </Box>
                      </HStack>
                    </td>

                    <td className="py-4 align-top ">
                      <HStack className="w-16 ">
                        <Box>
                          <p className={` text-xs font-medium text-gray-dark `}>
                            {investor.par}
                          </p>
                        </Box>
                      </HStack>
                    </td>

                    <td className="py-4 align-top ">
                      <HStack className="w-16 ">
                        <Box>
                          <p className={` text-xs font-medium text-gray-dark `}>
                            {investor.premium}
                          </p>
                        </Box>
                      </HStack>
                    </td>

                    <td className="py-4 align-top ">
                      <HStack className="w-16 ">
                        <Box>
                          <p className={` text-xs font-medium text-gray-dark `}>
                            {investor.numberOfShares}
                          </p>
                        </Box>
                      </HStack>
                    </td>

                    <td className="py-4 align-top ">
                      <HStack className="w-28 ">
                        <Box>
                          <p className={` text-xs font-medium text-gray-dark `}>
                            {investor.amount}
                          </p>
                        </Box>
                      </HStack>
                    </td>

                    <td className="py-4 align-top ">
                      <HStack className="w-32">
                        <Box>
                          <p className={` text-xs font-medium text-gray-dark `}>
                            {formatDate(investor.date)}
                          </p>
                        </Box>
                      </HStack>
                    </td>

                    <td className="py-4 align-top ">
                      <HStack className="w-32 ">
                        <Box>
                          <p className={` text-xs font-medium text-gray-dark `}>
                            {investor.roundName}
                          </p>
                        </Box>
                      </HStack>
                    </td>

                    <td className="py-4 align-top ">
                      <HStack className="w-32 ">
                        <Box>
                          <HStack>
                            {investor.allotment &&
                              investor.allotment?.map(
                                (allotment: Allotment, index) => (
                                  <AllotmentTag
                                    key={index}
                                    allotment={allotment}
                                  />
                                )
                              )}

                            {investor.par === 0 && investor.premium === 0 && (
                              <OverViewTags tagName={"Bonus"} />
                            )}
                            {checkPartiallyPaid(investor) && (
                              <OverViewTags tagName={"Partly Paid"} />
                            )}
                          </HStack>
                        </Box>
                      </HStack>
                    </td>

                    <td className="px-2 py-4 align-top"></td>
                    <td className="px-2 py-4 align-top">
                      <CTADropdown
                        actions={getOptionsForTransaction(
                          investor?.securityType || "Equity"
                        )}
                        onAction={(action) => handleAction(action, investor)}
                      />
                    </td>
                  </tr>
                ))}
              {currentTableData && (
                <tr key={index} className="border-t border-dashed ">
                  <td className="py-5 align-top"></td>

                  <td className="py-4 px-4 align-top ">
                    <HStack className="w-32 ">
                      <p className={` text-xs font-medium text-black`}>Total</p>
                    </HStack>
                  </td>

                  <td className="py-4 align-top ">
                    <HStack className="w-28 "></HStack>
                  </td>

                  <td className="py-4 align-top ">
                    <HStack className="w-16 "></HStack>
                  </td>

                  <td className="py-4 align-top ">
                    <HStack className="w-16 "></HStack>
                  </td>

                  <td className="py-4 align-top ">
                    <HStack className="w-16 ">
                      <Box>
                        <p className={` text-xs font-medium text-black `}>
                          {getTotalShares(values.investors)}
                        </p>
                      </Box>
                    </HStack>
                  </td>

                  <td className="py-4 align-top ">
                    <HStack className="w-28 ">
                      <Box>
                        <p className={` text-xs font-medium text-black`}>
                          {getTotalAmount(values.investors)}
                        </p>
                      </Box>
                    </HStack>
                  </td>

                  <td className="py-4 align-top ">
                    <HStack className="w-32"></HStack>
                  </td>

                  <td className="py-4 align-top ">
                    <HStack className="w-32 "></HStack>
                  </td>

                  <td className="py-4 align-top ">
                    <HStack className="w-32 "></HStack>
                  </td>

                  <td className="px-2 py-4 align-top"></td>
                  <td className="px-2 py-4 align-top"></td>
                </tr>
              )}
            </tbody>
          </table>
          {values.investors.length <= 0 && (
            <div className="text-black text-center p-4">
              <h1>
                No Primary Transaction Found, Please Add Primary Transaction
              </h1>
            </div>
          )}
        </Box>
        <Box className="flex justify-between mt-8">
          <BasicMenu
            defaultValue={pageSize}
            options={[5, 10, 20, 50, 100]}
            onOptionChange={(value) => setPageSize(value)}
          />
          <Pagination
            className=""
            currentPage={currentPage}
            totalCount={investorList.length}
            pageSize={pageSize}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </Box>
        <Box>
          <HStack className="gap-8 mr-4 mt-8">
            <HStack>
              <AllotmentTag
                key={index}
                allotment={{
                  name: conversionTag.name,
                  identifier: conversionTag.identifier,
                }}
              />
              <p className="text-indigo-800 ">Conversion</p>
            </HStack>
            <HStack>
              <AllotmentTag
                key={index}
                allotment={{
                  name: redemptionTag.name,
                  identifier: redemptionTag.identifier,
                }}
              />
              <p className="text-red-600 ">Redemption</p>
            </HStack>
            <HStack>
              <AllotmentTag
                key={index}
                allotment={{
                  name: buyBackTag.name,
                  identifier: buyBackTag.identifier,
                }}
              />
              <p className="text-blue-600 ">BuyBack</p>
            </HStack>
          </HStack>
        </Box>
      </Box>
    </div>
  );
}
