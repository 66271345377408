import React from "react";
import { Box, HStack, VStack } from "../../components/utils";
import { useQuery } from "../../utils/useQuery";
import BCHeader from "../../shared/BCHeader";
import StatusLabel from "../esopOverview/StatusLabel";
import CreateLiquidationDetails from "./CreateLiquidationDetails";

function CreateLiquidationFlow() {
  const query = useQuery().get("mode");
  return (
    <VStack className="gap-6">
      <BCHeader
        bcTitle={"ESOP Transaction"}
        className="py-3"
        bcSubTitle={
          query === "create"
            ? "Create Liquidation Flow"
            : "Edit Liquidation Flow"
        }
      />
      <VStack className=" bg-[#fefefe]">
        <VStack className="p-6 gap-4 bg-[#fefefe]">
          <Box className="flex items-baseline gap-12">
            <h6 className="text-lg font-semibold text-gray-600">
              {query === "create"
                ? "Create Liquidation Flow"
                : "Edit Liquidation"}
            </h6>
            <div className="items-center px-6 py-1 rounded">
              <StatusLabel state={"" || "Draft"}></StatusLabel>
            </div>
          </Box>
          <CreateLiquidationDetails />
        </VStack>
      </VStack>
    </VStack>
  );
}

export default CreateLiquidationFlow;
