import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Form, Formik, useFormikContext, useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
} from "../../components/utils";
import { Select } from "../../components/shared/Select";
import {
  SpecialGrants,
  useEsopModellingStore,
} from "../../store/esopModellingStore";
import { Input } from "../../components/shared/InputField";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { SwitchButton } from "../../components/shared/SwitchButton";

type StepProps = {
  onBack: () => void;
  onNext: () => void;
};

function SpecialGrantsTab(props: StepProps) {
  const { onBack, onNext } = props;
  const data = useEsopModellingStore();
  const initialSpecialGrants: SpecialGrants = {
    refreshGrants: data.specialGrants.refreshGrants,
    frequency: data.specialGrants.frequency,
    multiplierPercentage: data.specialGrants.multiplierPercentage,
    coverage: data.specialGrants.coverage,
    reserveForAdvisors: data.specialGrants.reserveForAdvisors,
    reserveForFoundingTeam: data.specialGrants.reserveForFoundingTeam,
    reserveForPerformance: data.specialGrants.reserveForPerformance,
  };

  const validationSchema = Yup.object().shape({
    frequency: Yup.number().when("refreshGrants", {
      is: true,
      then: Yup.number().required("Frequency is required"),
    }),
    multiplierPercentage: Yup.number().when("refreshGrants", {
      is: true,
      then: Yup.number().required("Multiplier Percentage is required").max(100),
    }),
    coverage: Yup.number().when("refreshGrants", {
      is: true,
      then: Yup.number().required("Coverage is required").max(100),
    }),
    reserveForAdvisors: Yup.number()
      .required("Reserve for Advisors Percentage is required")
      .max(100),
    reserveForFoundingTeam: Yup.number()
      .required("Reserve for Founding team Percentage is required")
      .max(100),
    reserveForPerformance: Yup.number()
      .required("Reserve for Performance Percentage is required")
      .max(100),
  });

  const handleSubmit = (values: SpecialGrants) => {
    data.setSpecialGrants(values);
  };

  const formik = useFormik({
    initialValues: initialSpecialGrants,
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  return (
    <Box className="w-78 p-4 bg-white rounded-lg ">
      <VStack className="justify-between gap-9 p-4">
        <VStack className="justify-between gap-2">
          <p className="text-lg font-medium text-gray-dark">Special Grants</p>
          <p className="text-s font-medium text-gray-light">
            Set the percentage of stock options you wish to reserve for the
            founding team members, advisors and other special grants not
            included as part of the hiring plans above. Also, provide details of
            any additional grants you wish to make to employees under the plan.
          </p>
        </VStack>

        <HStack className="justify-start">
          <p className={` form-label text-sm font-medium w-1/3 pt-3 `}>
            Reserve for Founding Team
          </p>
          <Box className="w-1/3 flex items-start ml-2">
            <VStack>
              <Input
                type="number"
                placeholder="Reserve for Founding Team"
                className="w-48"
                {...formik.getFieldProps("reserveForFoundingTeam")}
              />
              {formik.touched.reserveForFoundingTeam &&
                formik.errors.reserveForFoundingTeam && (
                  <Error text={formik.errors.reserveForFoundingTeam} />
                )}
            </VStack>
          </Box>
        </HStack>
        <HStack className="justify-start">
          <p className={` form-label text-sm font-medium w-1/3 pt-3 `}>
            Reserve for Advisors
          </p>
          <Box className="w-1/3 flex items-start ml-2">
            <VStack>
              <Input
                type="number"
                placeholder="Reserve for Advisors"
                className="w-48"
                {...formik.getFieldProps("reserveForAdvisors")}
              />
              {formik.touched.reserveForAdvisors &&
                formik.errors.reserveForAdvisors && (
                  <Error text={formik.errors.reserveForAdvisors} />
                )}
            </VStack>
          </Box>
        </HStack>
        <HStack className="justify-start">
          <p className={` form-label text-sm font-medium w-1/3 pt-3 `}>
            Reserve for Performance ( Yearly )
          </p>
          <Box className="w-1/3 flex items-start ml-2">
            <VStack>
              <Input
                type="number"
                placeholder="Reserve for Performance ( Yearly )"
                className="w-48"
                {...formik.getFieldProps("reserveForPerformance")}
              />
              {formik.touched.reserveForPerformance &&
                formik.errors.reserveForPerformance && (
                  <Error text={formik.errors.reserveForPerformance} />
                )}
            </VStack>
          </Box>
        </HStack>
        <HStack className="justify-start">
          <p className={` form-label text-sm font-medium w-1/3 mt-1`}>
            Refresh Grants
          </p>
          <Box className="w-1/3 flex items-start ml-2">
            <SwitchButton
              onClick={() => {
                formik.setFieldValue(
                  "refreshGrants",
                  !formik.values.refreshGrants
                );
              }}
              className=""
              value={formik.values.refreshGrants}
              label=""
            />
          </Box>
        </HStack>
        {formik.values.refreshGrants && (
          <HStack className="justify-start">
            <p className={` form-label text-sm font-medium w-1/3 pt-3 `}>
              Frequency ( Years )
            </p>
            <Box className="w-1/3 flex items-start ml-2">
              <VStack>
                <Input
                  type="number"
                  placeholder="Frequency ( Years )"
                  className="w-48"
                  {...formik.getFieldProps("frequency")}
                />
                {formik.touched.frequency && formik.errors.frequency && (
                  <Error text={formik.errors.frequency} />
                )}
              </VStack>
            </Box>
          </HStack>
        )}
        {formik.values.refreshGrants && (
          <HStack className="justify-start">
            <p className={` form-label text-sm font-medium w-1/3 pt-3 `}>
              Multiplier Percentage
            </p>
            <Box className="w-1/3 flex items-start ml-2">
              <VStack>
                <Input
                  type="number"
                  placeholder="Multiplier Percentage"
                  className="w-48"
                  {...formik.getFieldProps("multiplierPercentage")}
                />
                {formik.touched.multiplierPercentage &&
                  formik.errors.multiplierPercentage && (
                    <Error text={formik.errors.multiplierPercentage} />
                  )}
              </VStack>
            </Box>
          </HStack>
        )}
        {formik.values.refreshGrants && (
          <HStack className="justify-start">
            <p className={` form-label text-sm font-medium w-1/3 pt-3 `}>
              Coverage
            </p>
            <Box className="w-1/3 flex items-start ml-2">
              <VStack>
                <Input
                  type="number"
                  placeholder="Coverage"
                  className="w-48"
                  {...formik.getFieldProps("coverage")}
                />
                {formik.touched.coverage && formik.errors.coverage && (
                  <Error text={formik.errors.coverage} />
                )}
              </VStack>
            </Box>
          </HStack>
        )}
        <HStack className="justify-between mt-12">
          <ButtonPrimary1
            type="reset"
            className="text-red-500 mr-8"
            onClick={() => {
              onBack();
            }}
          >
            Back
          </ButtonPrimary1>
          <ButtonPrimary
            type="submit"
            onClick={() => {
              formik.handleSubmit();
              onNext();
            }}
          >
            Next
          </ButtonPrimary>
        </HStack>
      </VStack>
    </Box>
  );
}

export default SpecialGrantsTab;
