import {
  CompensationTemplate,
  EsopModellingState,
} from "../store/esopModellingStore";
import {
  CompensationTemplateReq,
  EsopModelReq,
  HiringPlanReq,
  Result,
} from "../types/EsopModelling";
import nodeApi from "./nodeApi";

export async function saveEsopModel(body: EsopModelReq): Promise<Result> {
  return nodeApi
    .post("/esopModel/saveModel", body)
    .then((res) => res.data)
    .catch((err) => err);
}

export async function getAllCompensationTemplates(): Promise<Result> {
  return nodeApi
    .get(`/compensationTemplate/getAllTemplates`)
    .then((res) => res.data);
}

export async function saveCompensationTemplate(
  body: CompensationTemplateReq
): Promise<Result> {
  return nodeApi
    .post("/compensationTemplate/saveTemplate", body)
    .then((res) => res.data)
    .catch((err) => err);
}

export async function getAllModels(): Promise<Result> {
  return nodeApi.get(`/esopModel/getAllModels`).then((res) => res.data);
}

export async function getAllHiringPlans(): Promise<Result> {
  return nodeApi.get(`/hiringPlan/getAllHiringPlans`).then((res) => res.data);
}

export async function saveHiringPlan(body: HiringPlanReq): Promise<Result> {
  return nodeApi
    .post("/hiringPlan/saveHiringPlans", body)
    .then((res) => res.data)
    .catch((err) => err);
}

export async function updateCompensationTemplate(
  body: CompensationTemplateReq
): Promise<Result> {
  return nodeApi
    .put(`/compensationTemplate/updateCompenstionTemplate/${body.id}`, body)
    .then((res) => res.data)
    .catch((err) => err);
}

export async function updateHiringPlan(body: HiringPlanReq): Promise<Result> {
  return nodeApi
    .put(`/hiringPlan/updateHiringPlan/${body.id}`, body)
    .then((res) => res.data)
    .catch((err) => err);
}
