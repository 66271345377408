import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Dialog } from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ButtonPrimary, ButtonPrimary1, HStack } from "../../components/utils";
import {
  useGetDiscardHistoricalRound,
  useGetHistoricalRound,
  useGetUserDetails,
  useSaveHistoricalRound,
  useUpdateCaptable,
} from "../../queries/onBoarding";
import BCHeader from "../../shared/BCHeader";
import { HistoricalRoundModel } from "../../types/HistoricDataModel";
import { initialHistoricRoundDetail } from "./initValues";
import ShareCapitalDetail from "./ShareCapitalDetail";
import SplitRatioTable from "./SplitRatioTable";
import EsopPoolTable from "./EsopPoolTable";
import AllHistoricTransactions from "./AllHistoricTransactions";
import AlertDialog from "../../components/shared/AlertDialog";
import { useAuthorizationStore } from "../../store/useAuthorizationStore";

function HistoricPage(): React.ReactElement {
  const { mutate: saveHistoricalRound } = useSaveHistoricalRound();
  const { mutate: updateCaptable } = useUpdateCaptable();
  const { refetch, data: _historicRoundDetail } = useGetHistoricalRound();
  let _companyId: string = "";
  const { refetch: getDiscardHistoricRound } = useGetDiscardHistoricalRound(
    _companyId,
    () => handleDiscard()
  );
  const navigate = useNavigate();

  useEffect(() => {
    _companyId = localStorage.getItem("companyId") || "";
    refetch();
  }, []);

  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
  }>({
    open: false,
  });

  const handleSubmit = (values: HistoricalRoundModel) => {
    updateCaptable(values, {
      onSuccess: () => {
        toast("Historic Details Updated Successfully!", {
          type: "success",
          autoClose: 2000,
        });
        navigate("/onboarding/overview");
      },
      onError: (error: { response: { data: { reason: string } } }) => {
        toast(`${error.response.data.reason}`, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  };

  const discardCompany = () => {
    setDialog({
      open: true,
      message: "Do you want to discard the company?",
    });
  };

  const handleDiscard = () => {
    setDialog({ open: false });
    navigate("/onboarding");
  };

  const initialCompanyDetailsValues: HistoricalRoundModel = _historicRoundDetail
    ? {
        investors: _historicRoundDetail.investors || [],
        splitDetails: _historicRoundDetail.splitDetails || [],
        secondaries: _historicRoundDetail.secondaries || [],
        esopPool: _historicRoundDetail.esopPool || [],
        companyCapitalDetails: _historicRoundDetail.companyCapitalDetails,
        securitiesDropdown: _historicRoundDetail.securitiesDropdown || [],
        investorsDropdown: _historicRoundDetail.investorsDropdown || [],
        historicalRoundPopupDetails:
          _historicRoundDetail.historicalRoundPopupDetails || [],
        notes: _historicRoundDetail.notes || "",
      }
    : initialHistoricRoundDetail;

  const validationCompanyDetailsSchema = Yup.object().shape({
    investors: Yup.array()
      .of(
        Yup.object().shape({
          investorName: Yup.string().required("Investor Name is required"),
          amount: Yup.number().required("Amount is required"),
          par: Yup.number().required("PAR is required"),
          premium: Yup.number().required("Premium is required"),
          numberOfShares: Yup.number().required("Number of shares is required"),
          date: Yup.string().required("Date is required"),
          securityType: Yup.string().required("Security is required"),
          roundName: Yup.string().required("Round Name is required"),
          conversionDetails: Yup.object()
            .shape({
              conversionValue: Yup.string().required(
                "Conversion Value is required"
              ),
              conversionDate: Yup.string().required(
                "Conversion Date is required"
              ),
              numberOfSharesToConvert: Yup.string().required(
                "Number of shares to convert is required"
              ),
              conversionSecurity: Yup.string().required(
                "Conversion Security is required"
              ),
              numberofSharesPostConversion: Yup.string().required(
                "Number of shares post conversion is required"
              ),
            })
            .nullable(),
          redemptionDetails: Yup.object()
            .shape({
              redeemedAmount: Yup.string().required(
                "Redeemed Amount is required"
              ),
              amountLeft: Yup.string().required("Amount Left is required"),
              redeemedDate: Yup.string().required("Redeemed Date is required"),
            })
            .nullable(),
          investorProfile: Yup.object()
            .shape({
              typeOfShareholder: Yup.string().required(
                "Type of Shareholder is required"
              ),
              groupName: Yup.object()
                .shape({
                  groupName: Yup.string().required("Group Name is required"),
                })
                .nullable(),
              legalName: Yup.string().required("Legal Name is required"),
              residentStatus: Yup.string().required(
                "Resident Country is required"
              ),
              nationality: Yup.string().required("Nationality is required"),
            })
            .nullable(),
        })
      )
      .required("Required"),
    splitDetails: Yup.array()
      .of(
        Yup.object().shape({
          splitNumber: Yup.number().required("Split Number is required"),
          securityType: Yup.string().required("Security Type is required"),
          date: Yup.string().required("Date is required"),
        })
      )
      .required("Required"),
    secondaries: Yup.array()
      .of(
        Yup.object().shape({
          buyer: Yup.string().required("Buyer is required"),
          seller: Yup.string().required("Seller is required"),
          amount: Yup.number().required("Amount is required"),
          numberOfShares: Yup.number().required("Number of shares is required"),
          pricePerShare: Yup.number().required("Price per share is required"),
          date: Yup.string().required("Date is required"),
          roundName: Yup.string().required("Round Name is required"),
          securityType: Yup.string().required("SecurityType is required"),
          conversionDetails: Yup.object()
            .shape({
              conversionValue: Yup.string().required(
                "Conversion Value is required"
              ),
              conversionDate: Yup.string().required(
                "Conversion Date is required"
              ),
              numberOfSharesToConvert: Yup.string().required(
                "Number of shares to convert is required"
              ),
              conversionSecurity: Yup.string().required(
                "Conversion Security is required"
              ),
              numberofSharesPostConversion: Yup.string().required(
                "Number of shares post conversion is required"
              ),
            })
            .nullable(),
          redemptionDetails: Yup.object()
            .shape({
              redeemedAmount: Yup.string().required(
                "Redeemed Amount is required"
              ),
              amountLeft: Yup.string().required("Amount Left is required"),
              redeemedDate: Yup.string().required("Redeemed Date is required"),
            })
            .nullable(),
          investorProfile: Yup.object()
            .shape({
              typeOfShareholder: Yup.string().required(
                "Type of Shareholder is required"
              ),
              groupName: Yup.object()
                .shape({
                  groupName: Yup.string().required("Group Name is required"),
                })
                .nullable(),
              legalName: Yup.string().required("Legal Name is required"),
              residentStatus: Yup.string().required(
                "Resident Country is required"
              ),
              nationality: Yup.string().required("Nationality is required"),
            })
            .nullable(),
        })
      )
      .required("Required"),
    esopPool: Yup.array()
      .of(
        Yup.object().shape({
          poolSize: Yup.number().required("Pool Size is required"),
          date: Yup.string().required("Date is required"),
        })
      )
      .required("Required"),
    companyCapitalDetails: Yup.object().shape({
      equityAuthorizedShareCapital: Yup.number().required(
        "Equity Authorized Share Capital is required"
      ),
      preferenceAuthorizedShareCapital: Yup.number().required(
        "Preference Authorized Share Capital is required"
      ),
      equityPaidUpShareCapital: Yup.number().required(
        "Equity PaidUp Share Capital is required"
      ),
      preferencePaidUpShareCapital: Yup.number().required(
        "Preference PaidUp Share Capital is required"
      ),
    }),
    securitiesDropdown: Yup.array().min(1, "Industry is Required"),
    investorsDropdown: Yup.array().min(1, "Industry is Required"),
    historicalRoundPopupDetails: Yup.array()
      .of(
        Yup.object().shape({
          roundName: Yup.string().required("Round Name is required"),
          roundIdentifier: Yup.string().required(
            "Round Identifier Name is required"
          ),
        })
      )
      .required("Required"),
  });

  return (
    <>
      {_historicRoundDetail ? (
        <div className="w-11/12 m-auto min-h-screen ">
          <div className="px-0 text-lg font-medium y-7">
            <h1>Onboarding</h1>
          </div>
          <BCHeader
            className="items-baseline mb-4"
            bcTitle="OnBoarding"
            bcSubTitle="Admin Profile"
          />
          <Formik
            key="historic"
            enableReinitialize={true}
            initialValues={initialCompanyDetailsValues}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            validationSchema={validationCompanyDetailsSchema}
          >
            {(formik) => (
              <Form key="historic">
                <Dialog open={dialog.open} maxWidth="md">
                  <AlertDialog
                    onClose={() => setDialog({ open: false })}
                    open={dialog.open}
                    message={dialog.message}
                    onPrimaryAction={getDiscardHistoricRound}
                    onSecondaryAction={() => setDialog({ open: false })}
                  />
                </Dialog>
                <ShareCapitalDetail />
                <AllHistoricTransactions />
                <EsopPoolTable />
                <SplitRatioTable />
                <HStack className="justify-end m-8">
                  <ButtonPrimary1
                    type="reset"
                    className="text-red-500 mr-8"
                    onClick={discardCompany}
                  >
                    Discard
                  </ButtonPrimary1>
                  <ButtonPrimary1
                    className="text-red-500 mr-8"
                    onClick={() => {
                      saveHistoricalRound(formik.values, {
                        onSuccess: () => {
                          toast("Historic Details Saved Successfully!", {
                            type: "success",
                            autoClose: 2000,
                          });
                        },
                        onError: (error: {
                          response: { data: { reason: string } };
                        }) => {
                          toast(`${error.response.data.reason}`, {
                            type: "error",
                            autoClose: 2000,
                          });
                        },
                      });
                    }}
                  >
                    Save for Later
                  </ButtonPrimary1>
                  <ButtonPrimary type="submit">
                    Generate Cap Table
                  </ButtonPrimary>
                </HStack>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <div className="w-11/12 m-auto  min-h-screen ">
          <Icon
            className="m-auto  animate-spin"
            icon="lucide:loader-2"
            width={36}
          />
        </div>
      )}
    </>
  );
}
export default HistoricPage;
