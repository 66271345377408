import { Icon } from "@iconify/react";
import { Tab } from "../../types/HistoricOverView";
import { HStack, VStack } from "../../components/utils";

export default function RoundWiseTransactionStepper({
  activeRound,
  rounds,
  onActiveRoundStepChange,
}: {
  activeRound: {
    index: number;
    data?: Tab;
    mode?: string;
    name?: string;
  };
  rounds: Tab[];
  onActiveRoundStepChange: (
    index: number,
    data?: Tab,
    mode?: string,
    name?: string
  ) => void;
}) {
  const slideLeft = () => {
    const slider: HTMLElement = document.getElementById("slider")!;
    slider.scrollLeft = (slider?.scrollLeft || 0) - 400;
  };
  const slideRight = () => {
    const slider: HTMLElement = document.getElementById("slider")!;
    slider.scrollLeft = (slider?.scrollLeft || 0) + 400;
  };

  return (
    <div className="p-2 bg-slate-50 rounded-xl">
      <HStack>
        <div className="py-4 hover:cursor-pointer" onClick={slideLeft}>
          <Icon icon="akar-icons:chevron-left" className="text-slate-600" />
        </div>

        <HStack
          id="slider"
          className="pt-4 bg-white rounded-xl text-gray-400 text-[15px] overflow-x-scroll scrollbar-hide"
        >
          {rounds.map(({ roundName, tabType }, i) => (
            <VStack key={i}>
              <button
                type="button"
                className={`peer font-medium mx-5 whitespace-nowrap  ${
                  activeRound.index === i ? "text-orange-501" : ""
                }`}
                onClick={() => {
                  onActiveRoundStepChange(i, rounds[i], tabType, roundName);
                }}
              >
                {roundName}
              </button>

              <div
                className={`mt-2 h-0.5 rounded-full ${
                  activeRound.index === i ? "bg-orange-501" : ""
                }`}
                aria-label="underline"
              ></div>
            </VStack>
          ))}
        </HStack>
        <div className="py-4 hover:cursor-pointer" onClick={slideRight}>
          <Icon
            icon="akar-icons:chevron-right"
            className="text-slate-600 items-center content-center"
          />
        </div>
      </HStack>
    </div>
  );
}
