import { format } from "date-fns";
import {
  AddVestingTemplateReq,
  VestingTriggerType,
  VestingType,
} from "../../types/VestingTemplate";

export const baseEventScheduleDto = {
  eventName: "",
  eventTargetDate: format(new Date(), "yyyy-MM-dd"),
  id: 0,
  vestingDuration: 0,
  vestingInterval: 0,
  sequenceNumber: 0,
  percentage: 0,
  vestingScheduleState: "",
  vestingTriggerType: VestingTriggerType.Event,
};
export const baseTimeScheduleDto = {
  eventName: "",
  eventTargetDate: "",
  id: 0,
  vestingDuration: 0,
  vestingInterval: 0,
  sequenceNumber: 0,
  percentage: 0,
  vestingScheduleState: "",
  vestingTriggerType: VestingTriggerType.Time,
};

export const vestingBaseValues: AddVestingTemplateReq = {
  id: null,
  vestingTemplateName: "",
  vestingTriggerType: VestingTriggerType.Time,
  timeScheduleDtos: [baseTimeScheduleDto],
  eventScheduleDtos: [baseEventScheduleDto],
  cliffPeriod: 12,
  vestingType: VestingType.Standard,
  vestingInterval: 1,
  vestingPeriod: 48,
  includeTimeVesting: false,
  includeEventVesting: false,
  isDefault: false,
};
