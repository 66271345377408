import { InputHTMLAttributes } from "react";

function FileInput(
  props: InputHTMLAttributes<HTMLInputElement> & { file: string }
) {
  const { className, id, type, ...rest } = props;
  let _id = `upload-file`;
  if (id) {
    _id = `${_id}-${id}`;
  }
  const _className = ` block h-8 p-2 font-medium truncate rounded-md cursor-pointer max-w-fit text-slate-dark bg-slate-light ${className} `;

  return (
    <label className={_className} htmlFor={id ? _id : "upload-file"}>
      <input
        type="file"
        className="w-20 custom-file-input"
        id={_id}
        {...rest}
      />
      {props.file.replace(/^.*\\/, "")}
    </label>
  );
}

export default FileInput;
