import { QueryFunctionContext } from "react-query";
import { ChangePasswordDetails, UserProfile } from "../types/UserDetailModel";
import captableApi from "./captableApi";

export async function getUserProfile(
  context: QueryFunctionContext
): Promise<UserProfile> {
  return captableApi.get("/v1/auth/userDetails").then((res) => res.data.data);
}

export async function saveUserProfile(data: UserProfile): Promise<void> {
  return captableApi.put("/v1/auth/userDetails", data);
}

export async function changePassword(data: ChangePasswordDetails) {
  return captableApi
    .post("/v1/auth/changePassword", data)
    .then((res) => res.data);
}
