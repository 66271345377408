import TextField from "@mui/material/TextField";
import React from "react";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import { InputType } from "../utils/interfaces/inputTypes";

const SearchInput = (props: InputType) => {
  const { className, type, placeholder, value, onClick, name } = props;

  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      placeholder={placeholder}
      type={type}
      className={className}
      value={value}
      name={name}
      onChange={onClick}
    />
    // <TextField
    //     InputProps={{

    //     }}
    // />
  );
};

export default SearchInput;
