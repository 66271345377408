import { useState } from "react";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { Dialog } from "@mui/material";
import { SelectButtonGroup } from "../../components/shared/SelectButtonGroup";
import {
  useChangeOptionStates,
  useNextStates,
  useSignAndApproveGrant,
} from "../../queries";
import { Grant, SignatureAttachment } from "../../types/Grant";
import {
  ButtonPrimary,
  VStack,
  HStack,
  Error,
  Box,
} from "../../components/utils";
import { formatWithTimeZone } from "../../utils/date";
import { useError } from "../../store/errorStore";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import { stringFormat } from "../../utils/string";
import { EnvelopeDetails } from "../../types/ESign";
import { useAuthStore } from "../../store";
import { createEnvelope } from "../../api/Esop";
import SignaturePad from "../../shared/signaturePad";

function BulkOptionStateChange({
  grants,
  onClose = () => {},
}: {
  grants: Grant[];
  onClose: () => void;
}) {
  const { data } = useNextStates(grants[0]?.optionHolderId || 0);
  const [nextState, setNextState] = useState(data?.availableStates[0]);
  const { mutate: changeOptionStates, status } = useChangeOptionStates();
  const [showSignaturePad, setShowSignaturePad] = useState(false);
  const { mutate: attachSignature, isLoading: signatureBeingAttached } =
    useSignAndApproveGrant();
  const errorMessage = useError();
  const [note, setNote] = useState("");
  const [error, setError] = useState("");
  const { user } = useAuthStore();
  function handleClick() {
    if (!grants[0].optionHolderId || !nextState) return;
    const ids = grants.map((grant) => grant.optionHolderId);
    if (note === "" && nextState === "WITHDRAW") {
      setError("Note required");
      return;
    }
    if (user?.company?.requestApproverSignature && nextState === "APPROVED") {
      toast("Please Sign In order to approve the grant", {
        type: "info",
        autoClose: 3000,
      });
      setShowSignaturePad(!showSignaturePad);
      return;
    }
    // TODO: Shivam to check esign before state change
    changeOptionStates(
      {
        optionHolderIds: ids,
        optionHolderState: nextState.replace(" ", "_"),
        dateOfTransaction: formatWithTimeZone(new Date().toDateString()),
        note,
      },
      {
        onSuccess: () => {
          if (
            user?.company.isDocuSignEnabled &&
            (nextState === "FOR_APPROVAL" || nextState === "OFFERED")
          ) {
            const envelopDetails: EnvelopeDetails = {
              ids,
              companyId: user?.company.id as number,
              grantState: nextState,
              signerType: "OPTIONS",
            };
            createEnvelope(envelopDetails);
          }
          toast("State Changed Successfully!", { type: "success" });
          onClose();
        },
        onError: (err: any) => {
          errorMessage.setMessage(err.response.data.reason);
          toast(err.response.data.reason, { type: "error", autoClose: 2000 });
        },
      }
    );
  }
  function handleSignaturePad(signature: string) {
    if (signature) {
      signature = signature.replace("data:image/png;base64,", "");
    }
    const ids = grants.map((grant) => grant.optionHolderId);
    const signatureAttachmentObj: SignatureAttachment = {
      signature,
      optionHolderIds: ids,
    };
    attachSignature(signatureAttachmentObj, {
      onSuccess: () => {
        toast("State Changed Successfully!", {
          type: "success",
          autoClose: 2000,
        });
        toast("State Changed Successfully!", { type: "success" });
        onClose();
      },
    });
  }
  return (
    <>
      <div className=" p-4 text-lg font-medium border-b">
        <h6>Bulk Option State Change</h6>
      </div>
      <VStack className="justify-between gap-4 p-10 min-w-[500px] min-h-[300px]">
        <HStack className="text-md item-center text-center">
          Selected {grants.length}
          {grants.length === 1 ? ` grant ` : ` grants `} in{" "}
          {stringFormat(grants[0].optionHolderState)} state.
        </HStack>
        <Box>
          <Label className="text-sm font-normal">Select Grant Status</Label>
          <Select
            options={
              data?.availableStates.map((option) => stringFormat(option)) || []
            }
            onChange={(e) => setNextState(e.target.value)}
          />
        </Box>

        {nextState === "WITHDRAW" && (
          <VStack>
            <Label>Note</Label>
            <Input
              type="text"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
            <Error text={error} />
          </VStack>
        )}
        <ButtonPrimary
          className={`flex items-center self-end justify-center ${
            status === "success" ? "bg-green-500" : ""
          }`}
          disabled={!nextState || status !== "idle"}
          onClick={() => handleClick()}
        >
          {status === "idle" && "Confirm"}
          {status === "loading" && (
            <Icon className=" animate-spin" icon="lucide:loader-2" width={36} />
          )}
          {status === "success" && (
            <Icon icon="clarity:success-standard-line" width={36} />
          )}
        </ButtonPrimary>
      </VStack>
      <Dialog maxWidth="md" fullWidth open={showSignaturePad}>
        <SignaturePad
          onSecondaryAction={() => {
            toast("Grant cannot be approved as Signature is required", {
              type: "error",
              autoClose: 2000,
            });
            setShowSignaturePad(!showSignaturePad);
          }}
          onPrimaryAction={(signature) => handleSignaturePad(signature)}
          isLoading={false}
        />
      </Dialog>
    </>
  );
}
export default BulkOptionStateChange;
