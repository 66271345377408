import { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import {
  VStack,
  HStack,
  ButtonPrimary,
  ButtonSecondary,
  isEsopViewer,
} from "../../components/utils";

interface DialogProps {
  templateName: string;
  templateContent: string;
  onClose: (editedData?: string) => void;
}
interface TemplateContent {
  templateHeader?: string;
  templateBody?: string;
  templateFooter?: string;
}
function EmailTemplateEditorDialog(props: DialogProps) {
  const { templateName, templateContent, onClose } = props;
  const isUserEsopViewer = isEsopViewer();
  const editorRef = useRef<Editor["editor"]>();
  const [separatedTemplateContent, setSeparatedTemplateContent] =
    useState<TemplateContent>({});
  const tearDownEditorAndClose = () => {
    const body = editorRef.current?.getContent();
    const emailTemplateEditedContent = separatedTemplateContent.templateHeader
      ?.concat(body as string)
      .concat(separatedTemplateContent.templateFooter as string);
    onClose(emailTemplateEditedContent);
  };
  useEffect(() => {
    const emailTemplateData = templateContent;
    const headerIndex = emailTemplateData.indexOf("<!-- body start -->");
    const header = emailTemplateData.substring(0, headerIndex);
    const footerIndex = emailTemplateData.indexOf("<!-- body end -->");
    const footer = emailTemplateData.substring(footerIndex);
    const body = emailTemplateData.substring(headerIndex, footerIndex);
    setSeparatedTemplateContent({
      templateBody: body,
      templateFooter: footer,
      templateHeader: header,
    });
  }, [templateContent]);

  return (
    <div>
      <div className="p-4 px-10 mt-4 ml-3 text-lg font-medium border-b stone-700 ">
        <h6 className="flex justify-between">
          Edit {templateName}
          <span onClick={() => onClose()} className="cursor-pointer">
            X
          </span>
        </h6>
      </div>
      <VStack className="justify-between gap-4 p-4 px-10 ">
        <HStack>
          <Editor
            apiKey="l8zomkw48zb8qhvwpahb2g5nodmnma6qjyz1acf2y14t53z6"
            onInit={(_evt, editor) => {
              editorRef.current = editor;
            }}
            initialValue={separatedTemplateContent.templateBody}
            init={{
              height: 500,
              menubar: false,
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
        </HStack>
        {!isUserEsopViewer && (
          <HStack className="flex flex-row-reverse gap-4">
            <ButtonPrimary
              className={`flex items-center self-end justify-center`}
              onClick={() => {
                tearDownEditorAndClose();
              }}
            >
              Save
            </ButtonPrimary>
            <ButtonSecondary
              onClick={() => tearDownEditorAndClose()}
              className="text-gray-400 bg-slate-50"
            >
              Cancel
            </ButtonSecondary>
          </HStack>
        )}
      </VStack>
    </div>
  );
}

export default EmailTemplateEditorDialog;
