import { Link, useLocation, useMatch } from "react-router-dom";
import { Box, HStack } from "../components/utils";
import Profile from "../components/shared/ProfileComponent";
import useIsMobile from "../utils/detectDevice";

function Navbar({
  routes,
}: {
  routes: {
    title: string;
    path: string;
    subRoute: string;
    shouldBeDisplayed: boolean;
  }[];
}) {
  const { isMobile } = useIsMobile();
  return (
    <HStack
      className={`${isMobile ? "h-10 z-999" : "justify-between h-12 bg-white"}`}
    >
      <HStack
        className={`${
          isMobile ? "gap-2" : "gap-8 pt-4 text-gray-dark"
        }   text-xs  aria-label="navbar`}
      >
        {routes.map(({ title, path, subRoute, shouldBeDisplayed }) =>
          shouldBeDisplayed ? (
            <NavLink
              key={path}
              title={title}
              path={path}
              subRoute={subRoute}
            ></NavLink>
          ) : null
        )}
      </HStack>
      {!isMobile && <Profile />}
    </HStack>
  );
}

function NavLink({
  title,
  path,
  subRoute,
}: {
  title: string;
  path: string;
  subRoute: string;
}) {
  const match = useMatch(path);
  const location = useLocation();
  const { isMobile } = useIsMobile();
  return (
    <div
      className={`flex flex-col ${
        isMobile ? "justify-center" : " justify-between"
      }  mx-1 `}
    >
      <Box>
        <Link
          className={`peer ${
            isMobile ? "text-xxs" : "text-[15px] text-slate-500"
          }   mx-2  hover:text-black flex flex-nowrap `}
          to={path}
        >
          {title}
        </Link>
      </Box>
      <Box
        className={`${
          path === match?.pathname || location.pathname.includes(subRoute)
            ? "text-black border-b-2 border-solid border-orange-501"
            : ""
        }`}
      ></Box>
    </div>
  );
}
export default Navbar;
