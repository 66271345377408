import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, VStack } from "../../components/utils";
import { useGetOptionBuyback } from "../../queries/optionBuyback";
import BCHeader from "../../shared/BCHeader";
import BuybackDetails from "./Buybackdetails";
import BuybackEmployeeDetails from "./BuybackEmployeeDetails";
import BuybackEmployeeList from "./BuybackOffersList";
import ExerciseDetails from "./ExerciseDetails";
import useIsMobile from "../../utils/detectDevice";

function StartBuyback() {
  const { id } = useParams();
  const _id = id || "";
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const { data: buybackOverview, refetch, isFetching } = useGetOptionBuyback();
  const optionBuybackOverview = buybackOverview || [];
  const buyback = optionBuybackOverview?.filter(
    (item) => parseInt((item?.id || 0).toString(), 10) === parseInt(_id, 10)
  )[0];
  useEffect(() => {
    refetch();
    // eslint-disable-next-line no-useless-return
    if (isFetching) return;
    else {
      const isValidBuyBack = optionBuybackOverview.filter(
        (optionBuyBack) =>
          parseInt((optionBuyBack.id || 0).toString(), 10) === parseInt(_id, 10)
      );
      if (isValidBuyBack.length === 0) {
        toast(`You don't have permission to access ${_id} buyback`, {
          type: "error",
          autoClose: 3000,
        });
        navigate("/options/esopTransactions");
      }
    }
  }, [optionBuybackOverview, _id]);
  const { isMobile } = useIsMobile();
  return (
    <VStack className="gap-4 py-3">
      <BCHeader bcTitle="ESOP Transaction" bcSubTitle={buyback?.name} />
      <Box
        className={`${
          isMobile ? "w-full" : "w-3/5"
        } px-5 py-5 border-dashed border border-[#FFC700] bg-yellow-50`}
      >
        <p className="text-sm font-medium text-black">
          {`${
            buyback?.transactionType !== "buyback"
              ? "Liquidity programme/program"
              : "Options"
          } ${
            buyback?.transactionType === "buyback" ? "Buy Back" : ""
          } started successfully`}
        </p>
      </Box>
      <div className="gap-4 px-4 py-4 space-y-4">
        {(buyback?.transactionType === "exercise" ||
          buyback?.transactionType === "exercise and liquidate") && (
          <ExerciseDetails />
        )}
        {buyback?.transactionType === "buyback" && <BuybackDetails />}
        <BuybackEmployeeDetails />
        <BuybackEmployeeList />
      </div>
    </VStack>
  );
}

export default StartBuyback;
