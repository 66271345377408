import create from "zustand";

export type AlertDialogProps = {
  open: boolean;
  message?: string;
  error?: boolean;
  primaryActionText?: string;
  secondaryActionText?: string;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
  onClose?: () => void;
};

export type DialogState = {
  dialog: AlertDialogProps;
  setDialog: (props: AlertDialogProps) => void;
};

const useStore = create<DialogState>((set) => ({
  dialog: {
    open: false,
  },
  setDialog: (props) =>
    set((_state: DialogState) => ({
      dialog: { ...props },
    })),
}));

export const useDialogStore = useStore;
