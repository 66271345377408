import create from "zustand";

export type ErrorState = {
  message: string;
  setMessage: (text: string) => void;
  reset: () => void;
};

const initialState = {
  message: "",
};

const errorStore = create<ErrorState>((set) => ({
  ...initialState,
  setMessage: (text) => {
    set((state) => ({ message: text }));
  },
  reset: () => {
    set(() => initialState);
  },
}));

export const useError = errorStore;
