import { PictureObject } from "./Employee";
import { Company } from "./Grant";

export interface VestingTemplate {
  id: number;
  vestingTemplateName: string;
  vestingType: VestingType;
  vestingTriggerType: VestingTriggerType;
  vestingPeriod: number | null;
  cliffPeriod: number;
  vestingInterval: number | null;
  numberOfUsersApllied: number;
  isEditable: boolean;
  isDefault: boolean;
  schedules: Schedule[];
  includeTimeVesting: boolean;
  includeEventVesting: boolean;
}

export enum VestingType {
  Standard = "Standard",
  Custom = "Custom",
}
export enum VestingTriggerType {
  Both = "Both",
  Time = "Time",
  Event = "Event",
}

export interface AddVestingTemplateReq {
  id: number | null;
  vestingTemplateName: string;
  vestingType: VestingType;
  vestingTriggerType: VestingTriggerType;
  cliffPeriod: number;
  timeScheduleDtos: TimeScheduleDto[];
  eventScheduleDtos: EventScheduleDto[];
  vestingPeriod: number | null;
  vestingInterval: number | null;
  percentage?: number;
  includeTimeVesting: boolean;
  includeEventVesting: boolean;
  isDefault: boolean;
}
export interface VestingEventReq {
  eventName: string;
  eventTargetDate: string;
  eventDate: string;
  vestingScheduleState: string;
  isEditable: boolean;
  vestingTemplateName: string;
  employeeNames: string[];
  grantsINumber: number;
  EmployeeName: string;
}

export interface TimeScheduleDto {
  id: number | null;
  sequenceNumber: number;
  percentage: number;
  vestingDuration: number | null;
  vestingInterval: number | null;
  vestingTriggerType: VestingTriggerType | null;
  vestingScheduleState: string;
  eventName: string | null;
  eventTargetDate: string | null;
}

export interface VestingTemplateDetail {
  id: any;
  vestingTemplateName: any;
  isAlreadyInUse: boolean;
  vestingType: VestingType;
  vestingTriggerType: VestingTriggerType;
  cliffPeriod: number;
  vestingInterval: any;
  vestingPeriod: any;
  isDefault: boolean;
  timeScheduleDtos: TimeScheduleDto[] | null;
  eventScheduleDtos: EventScheduleDto[] | null;
}

export interface EventScheduleDto {
  id: number | null;
  sequenceNumber: number;
  percentage: number;
  vestingDuration: number | null;
  vestingInterval: number | null;
  vestingTriggerType: VestingTriggerType | null;
  vestingScheduleState: string;
  eventName: string | null;
  eventTargetDate: any;
}

export interface AddVestingTemplateRes {
  id: number;
  vestingTemplateName: string;
  vestingType: VestingType;
  isAlreadyInUse: boolean;
  isDefault: boolean;
  vestingTriggerType: VestingTriggerType | null;
  cliffPeriod: number | null;
  vestingInterval: number | null;
  vestingPeriod: number | null;
  company: Company;
  intervalUnit: string;
}

export interface Schedule {
  id: number | null;
  sequenceNumber: number;
  percentage: number;
  vestingDuration: number | null;
  vestingInterval: number | null;
  eventName: string | null;
  eventTargetDate: string | null;
  eventCompletionDate?: string | null;
  vestingTriggerType: VestingTriggerType | null;
  vestingScheduleState: string;
}

export interface EmployeeVestingDetail {
  date: string;
  grantId?: string;
  optionHolderId?: number;
  name: any;
  dayNumber: any;
  monthNumber: any;
  vestedOptions: number;
  projectedVestedOptions: number;
  cumulativeVestedOptions: number;
  vestedPercentage: number;
  accumulatedVestedOptionsForGrant: number;
  accumulatedVestingPercentageForGrant: number;
  accumulatedGrantedOptionsForEmployee: number;
  accumulatedVestedOptionsForEmployee: number;
  accumulatedProjectedVestedOptionsForEmployee: number;
  accumulatedExercisedOptionsForEmployee: number;
  accumulatedCashExercisedOptionsForEmployee: number;
  isGrantDate: boolean;
  isCliffDate: boolean;
  isVestDate: boolean;
  isExerciseDate: boolean;
  grantedOptions: number;
  exercisedOptions: number;
  exercisedOptionsWithCash: number;
  isCashExercise: boolean;
  exercisedOptionsForToolTip: number;
  unVestedOptions: number;
  isVestOrExerciseDate: boolean;
  isExercisedAfterVestingPeriod: boolean;
  vestedOfTotalOptions: any;
  triggerType: string;
  eventName: any;
  lastDay?: Date | string;
}

export interface OptionsDetail {
  optionId: number;
  optionHolderId: number;
  employeeId: number;
  imageURL: string;
  employeeIdentifier: string;
  grantIdentifier: string;
  employeeName: string;
  statusId: string;
  employmentStatus: string;
  holdingPercentage: number;
  optionHolding: number;
  optionsGranted: number;
  optionsVested: number;
  optionsExercised: number;
  optionsExercisedWithCash: number;
  grantPrice: number;
  planId: number;
  esopPlanName: string;
  grantDate: string;
  vestingStartingDate: string;
  optionHolderState: string;
  vestingDatetype: string;
  vestingDate: string;
  vesting: Vesting;
  isExerciseRequested: any;
  isVestRequested: any;
  hasGrantLetter: boolean;
  hasPlanLetter: boolean;
  optionsLapsed: number;
  optionsVestedV2: number;
  optionsExercisedV2: number;
  optionsStockExercisedV2: number;
  optionsCashExercisedV2: number;
  optionsLapsedV2: number;
  optionsLapsedWithoutReturnV2: number;
  optionsForfeitedV2: number;
  optionsForfeitedWithoutReturnV2: number;
  isGrantLetterAvailable: boolean;
}

export interface Vesting {
  id: number;
  vestingTemplateName: string;
  vestingType: string;
  isAlreadyInUse: boolean;
  isDefault: boolean;
  vestingTriggerType: string;
  cliffPeriod: number;
  vestingInterval: any;
  vestingPeriod: any;
  company: Company;
  schedules: VestingSchedule[];
  intervalUnit: string;
}

export interface VestingSchedule {
  isESignEnabled: boolean;
  esignType: string;
  emailsToBeSent: boolean;
  faqs: any;
  approverMailToAdmin: boolean;
  isCustomizedPlanEnabled: boolean;
  isAttachmentEnabled: boolean;
  newPortalAccess: boolean;
}

export interface TableDetailsV2 {
  date: string;
  grantId?: string;
  optionHolderId?: number;
  name: any;
  dayNumber: any;
  monthNumber: any;
  vestedOptions: number;
  projectedVestedOptions: number;
  cumulativeVestedOptions: number;
  vestedPercentage: number;
  accumulatedVestedOptionsForGrant: number;
  accumulatedVestingPercentageForGrant: number;
  accumulatedGrantedOptionsForEmployee: number;
  accumulatedVestedOptionsForEmployee: number;
  accumulatedProjectedVestedOptionsForEmployee: number;
  accumulatedExercisedOptionsForEmployee: number;
  accumulatedCashExercisedOptionsForEmployee: number;
  isGrantDate: boolean;
  isCliffDate: boolean;
  isVestDate: boolean;
  isExerciseDate: boolean;
  grantedOptions: number;
  exercisedOptions: number;
  exercisedOptionsWithCash: number;
  isCashExercise: boolean;
  exercisedOptionsForToolTip: number;
  unVestedOptions: number;
  isVestOrExerciseDate: boolean;
  isExercisedAfterVestingPeriod: boolean;
  vestedOfTotalOptions: any;
  triggerType: string;
  eventName: any;
}

export interface EmployeePageDetails {
  id: number;
  employeeName: string;
  imageURL: any;
  grantedOptions: number;
  vestedOptions: number;
  exercisedOptions: number;
  sharesReceived: number;
  status: string;
  statusValuesForDropdown: string[];
  residentStatus: string;
  department: string;
  emailAddress: string;
  nationality: string;
  designation: string;
  contactNumber: string;
  employeeId: string;
  grantLetterURL: string;
  optionsDetails: OptionsDetail[];
  graphDetails: any;
  tableDetails: any;
  tableDetailsV2: TableDetailsV2[];
  isLoginProvided: boolean;
  hasConfirmedNominees: boolean;
  showPPSToEmployees: boolean;
  ppsforEmployee: number;
  dateOfJoin: string;
  manager: string;
  pan: string;
  address: string;
  grade: string;
  employeeIdentification: string;
  employerName: string;
  employmentType: string;
  lastDay: string;
  notes: string;
  profilePicture: PictureObject;
  deactivate: boolean;
  isPausedVesting: boolean;
  totalOptionValue: number;
  totalOptionCost: number;
  userHasLimitedAccessToEmployee: boolean;
}

export interface EmployeeStockTransactionDetails {
  id: number;
  noOfSoldStock: number;
  unrealisedStock?: number;
  pricePerShare: number;
  amountReceived: number;
  transactionType: string;
  employeeId: string;
  transactionId?: string;
  createdAt: string;
  updatedAt: string;
}

export interface Integration {
  secretARN: string;
  token: string;
  tenentname: string;
  status: string;
}

export interface PauseVestingDto {
  employeeId?: number;
  isPausedVesting: boolean;
  esopPlanId?: number;
  optionHolderId?: number;
}
