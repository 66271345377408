export function _trimAll(str: string) {
  return str.trim().replace(/ +(?= )/g, "");
}

export function limitString(str: string, limit: number) {
  if (str.length > limit) {
    return str.substring(0, limit).concat(" . . .");
  } else {
    return str.substring(0, limit);
  }
}

export function stringFormat(str: string) {
  return str.replaceAll("_", " ");
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
