import { Icon } from "@iconify/react";
import { format } from "date-fns";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Input, Label } from "../components/shared/InputField";
import { Select } from "../components/shared/Select";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
} from "../components/utils";
import { useGrants } from "../queries";
import {
  useEmployeeResignationDetails,
  useEmployeementStatusDropdown,
  useInitiateSeparation,
  useSettlementDetails,
} from "../queries/employees";
import { useError } from "../store/errorStore";
import { SeparationReq } from "../types/InitiateSeparation";
import { formatWithTimeZone, modifyDate } from "../utils/date";

type InitiateSepartionType = {
  onClose: () => void;
};
export function InitiateSepartion(props: InitiateSepartionType) {
  const { id } = useParams();
  const _id = id || "";

  const { data: _statusDropdown } = useEmployeementStatusDropdown();
  const dropdown = _statusDropdown || [];
  const statusDropdown = dropdown.filter(
    (statusDropdown) => statusDropdown !== "Employed"
  );
  const { mutate: employeeSeparation, isLoading } = useInitiateSeparation();
  const { data: _grants } = useGrants();
  const grants = _grants || [];

  const errorMessage = useError();

  const { data: _employeeResignationDetails } = useEmployeeResignationDetails(
    parseInt(_id, 10)
  );
  const resignationDetails = _employeeResignationDetails;

  const employeeGrants = grants.filter(
    (grant) => grant.employeeId.toString() === _id
  );
  const { onClose } = props;
  const [overrideVestedShares, setOverrideVestedShares] =
    useState<boolean>(false);
  const [overrideLastWorkingDate, setOverrideLastWorkingDate] =
    useState<boolean>(false);
  const { data: settlementDetails } = useSettlementDetails(parseInt(_id, 10));
  const isLetterAvailable = !!settlementDetails?.s3Url;
  const isLetterSigned = settlementDetails?.isSigned;
  const intialValues = {
    id: resignationDetails?.id || 0,
    employeeName: resignationDetails?.employeeName || "",
    employmentStatus: resignationDetails?.employmentStatus || "",
    lastDay: format(
      new Date(resignationDetails?.lastDay || new Date()),
      "yyyy-MM-dd"
    ),
    totalGranted: resignationDetails?.totalGranted || 0,
    totalVested: resignationDetails?.totalVested || 0,
    totalLapsed: resignationDetails?.totalLapsed || 0,
    totalExercised: resignationDetails?.totalExercised || 0,
    remainingToExercise: resignationDetails?.remainingToExercise || 0,
    lastDateToExercise: format(
      new Date(resignationDetails?.lastDateToExercise || new Date()),
      "yyyy-MM-dd"
    ),
    numberOfSharesToExercise: resignationDetails?.numberOfSharesToExercise || 0,
    isExerciseDateExtended: resignationDetails?.isExerciseDateExtended || false,
    updatedEmailId: resignationDetails?.updatedEmailId || "",
    updatedContactNumber: resignationDetails?.updatedContactNumber || 0,
    updatedResidentStatus: resignationDetails?.updatedResidentStatus || "",
    nationality: resignationDetails?.nationality || "",
    notes: resignationDetails?.notes || "",
    lastVestDate: format(
      new Date(resignationDetails?.lastVestDate || new Date()),
      "yyyy-MM-dd"
    ),
  };

  const validationSchema = Yup.object().shape({
    employeeName: Yup.string().required("Employee name is required"),
    employmentStatus: Yup.string().required("Resignation reason is required"),
    lastDay: Yup.date()
      .required("Last day is required")
      .min(
        modifyDate(resignationDetails?.lastVestDate || "1900-01-01", -1),
        `Last Day cannot be before 
      ${format(
        new Date(resignationDetails?.lastVestDate || "1900-01-01"),
        "dd-MM-yyyy"
      )}`
      ),
  });

  const handleSubmit = (values: SeparationReq) => {
    const employeeResignationDto = { ...values };
    employeeResignationDto.isExerciseDateExtended = overrideLastWorkingDate;
    employeeResignationDto.lastVestDate = formatWithTimeZone(
      employeeResignationDto.lastVestDate
    );
    employeeResignationDto.lastDay = formatWithTimeZone(
      employeeResignationDto.lastDay
    );
    employeeResignationDto.lastDateToExercise = formatWithTimeZone(
      employeeResignationDto.lastDateToExercise
    );
    employeeSeparation(employeeResignationDto, {
      onSuccess: () => {
        toast("Resignation request submitted successfully", {
          type: "success",
          autoClose: 2000,
        });
        onClose();
      },
      onError: (err: any) => {
        errorMessage.setMessage(err.response.data.reason);
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
      },
    });
  };

  return (
    <>
      <div className="">
        <div className="px-10 text-lg font-medium border-b py-7">
          <h6 className="flex justify-between">
            Initiate Separation{" "}
            <span onClick={() => onClose()} className="cursor-pointer">
              X
            </span>{" "}
          </h6>
        </div>
        <VStack className="w-full px-10 py-7 gap-9">
          <Formik
            initialValues={intialValues}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            validationSchema={validationSchema}
          >
            {(formik) => (
              <Form>
                <HStack className="flex-wrap gap-8">
                  <div>
                    <Label className="text-sm font-normal">Employee Name</Label>
                    <Input
                      type="text"
                      disabled
                      {...formik.getFieldProps("employeeName")}
                    />
                    {formik.touched.employeeName &&
                      formik.errors.employeeName && (
                        <Error text={formik.errors.employeeName} />
                      )}
                  </div>
                  <div>
                    <Label className="text-sm font-normal">
                      Separation Due To
                    </Label>
                    <Select
                      options={statusDropdown.sort()}
                      {...formik.getFieldProps("employmentStatus")}
                    />
                    {formik.touched.employmentStatus &&
                      formik.errors.employmentStatus && (
                        <Error text={formik.errors.employmentStatus} />
                      )}
                  </div>
                  <div>
                    <Label className="text-sm font-normal">
                      Last day of Working
                    </Label>
                    <Input
                      type="date"
                      disabled={isLetterAvailable && isLetterSigned}
                      {...formik.getFieldProps("lastDay")}
                    />
                    {formik.touched.lastDay && formik.errors.lastDay && (
                      <Error text={formik.errors.lastDay} />
                    )}
                  </div>
                </HStack>
                <HStack className="py-4">
                  <table className="w-full table-space">
                    <thead className="text-xs font-medium text-gray-light">
                      <tr className="border-b border-dashed ">
                        <td className="px-2">Grant ID</td>
                        <td className="px-2"># OF OPTIONS</td>
                        <td className="px-2"># VESTED</td>
                        <td className="px-2"># LAPSED</td>
                        <td className="px-2"># EXERCISED</td>
                        <td className="px-2">ELIGIBLE TO EXERCISE</td>
                        <td className="px-2">STATE</td>
                      </tr>
                    </thead>
                    <tbody>
                      {employeeGrants.length > 0 ? (
                        employeeGrants.map((grant, index) => (
                          <tr className="border-b border-dashed " key={index}>
                            <td className="px-2 py-2 align-top">
                              {grant.grantIdentifier}
                            </td>
                            <td className="px-2 py-2 align-top">
                              {grant.optionsGranted}
                            </td>
                            <td className="px-2 py-2 align-top">
                              {grant.optionsVested}
                            </td>
                            <td className="px-2 py-2 align-top">
                              {grant.optionsLapsed}
                            </td>
                            <td className="px-2 py-2 align-top">
                              {grant.optionsExercised}
                            </td>
                            <td className="px-2 py-2 align-top">
                              {grant.optionsVested - grant.optionsExercised}
                            </td>
                            <td className="px-2 py-2 align-top">
                              {grant.optionHolderState}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <Box className="flex flex-row items-center justify-center text-green-400">
                          <p>No grants available</p>
                        </Box>
                      )}
                    </tbody>
                  </table>
                </HStack>
                {/* <HStack className="items-center gap-2 py-4">
                  <SwitchButton
                    onClick={() =>
                      setOverrideLastWorkingDate(!overrideLastWorkingDate)
                    }
                    className=""
                    value={overrideLastWorkingDate}
                    label="Override last date to exercise options"
                  />
                </HStack>
                <Box className="pl-16 -mt-4 text-gray-400 text-xs1">
                  <span>
                    Extend exercise window to allow employees to exercise their
                    vested options after the exercise due date to avoid lapse.
                  </span>
                  <Input
                    type="date"
                    className="w-60"
                    disabled={!overrideLastWorkingDate}
                    {...formik.getFieldProps("lastDateToExercise")}
                  />
                </Box> */}
                <HStack className="justify-between mt-4">
                  <ButtonPrimary1 onClick={onClose}>Back</ButtonPrimary1>
                  <ButtonPrimary type="submit">
                    {!isLoading ? (
                      isLetterAvailable && isLetterSigned ? (
                        `Confirm`
                      ) : (
                        `Save & Continue`
                      )
                    ) : (
                      <Icon
                        className="animate-spin"
                        icon="lucide:loader-2"
                        width={36}
                      />
                    )}
                  </ButtonPrimary>
                </HStack>
              </Form>
            )}
          </Formik>
        </VStack>
      </div>
    </>
  );
}
