import { QueryFunctionContext, QueryKey } from "react-query";
import { AxiosResponse } from "axios";
import { AddPlanRes } from "../types/AddPlanRes";
import {
  AddEmployeeReq,
  AddEmployeeRes,
  EmployeesOverviewDto,
  EmployeeStatusDropdown,
  DeleteResponse,
  RevokeAccessResponse,
  UploadEmployeeExcel,
} from "../types/Employee";
import {
  AddPlanReq,
  GenericAxiosResponse,
  ESOPPlandetail,
  EsopPlansSummary,
  UploadPlanDocumentDto,
  CurrentPoolSize,
  EsopAccountingReq,
} from "../types/EsopPlan";
import {
  AddGrantReq,
  AddGrantRes,
  Grant,
  NextPlanStateDto,
  NextStateDto,
  OptionStateChangeReq,
  OptionStateChangeRes,
  PlanStateChangeDto,
  GrantActivity,
  Template,
  EsopCreateDto,
  ReportDto,
  UploadRes,
  ValidateRes,
  CustomResponse,
  ImportRes,
  SignatureAttachment,
  SettlementSignatureDto,
  UpdateSettlementLetterDto,
} from "../types/Grant";
import { JwtPayload, LoginRequest, LoginResponse } from "../types/Login";
import {
  AddVestingTemplateReq,
  AddVestingTemplateRes,
  VestingTemplate,
  VestingTemplateDetail,
  VestingEventReq,
  EmployeeVestingDetail,
  EmployeePageDetails,
  EmployeeStockTransactionDetails,
} from "../types/VestingTemplate";

import { SeparationReq, SeparationRes } from "../types/InitiateSeparation";

import hissaApi from "./hissaApi";
import { Result } from "../types/common";
import { EsopSubsidiaryCompany } from "../types/EsopSubsidiaryCompany";
import {
  CompanyUsersDataModel,
  UserDetailModel,
} from "../types/UserDetailModel";
import { AddOwnershipReq } from "../types/Ownership";
import optionsApi from "./optionsApi";
import nodeApi from "./nodeApi";
import {
  AvailableDocument,
  DocumentInterface,
} from "../types/DocumentsInterface";
import { EnvelopeDetails } from "../types/ESign";
import { TransactionCard } from "../types/Card";
import esopNodeApi from "./esopNodeApi";
import { CaptableUserAccess } from "../layouts/SwitchProduct";
import captableApi from "./captableApi";
import {
  SettlementDetailsDto,
  SettlementDto,
} from "../types/GenerateSettlement";
import { ProjectedVesting } from "../types/Vest";

/**
 * resource: esopPlan
 * action: view
 * @returns
 */

export async function getAllGrants(): Promise<Grant[]> {
  return hissaApi.get("/esop/management/?esopPlan=ALL").then((res) => res.data);
}

/**
 * resource: employee
 * action: view
 * @returns
 */

export async function getEmployeesOverview(): Promise<EmployeesOverviewDto> {
  return hissaApi.get("employee/get/all").then((res) => res.data);
}

/**
 * resource: employee
 * action: view
 * @returns
 */

export async function getEmployeeStatusDropdown(): Promise<EmployeeStatusDropdown> {
  return hissaApi.get("esop/table/dropdown").then((res) => res.data);
}
/**
 * resource: esopPlan
 * action: view
 * @returns
 */

export async function getEsopPlansSummary(): Promise<EsopPlansSummary> {
  return hissaApi.get("dashboard/esop").then((res) => res.data);
}

/**
 * resource: vestingTemplate
 * action: view
 * @returns
 */

export async function getVestingTemplates(): Promise<VestingTemplate[]> {
  return hissaApi.get("esop/vestingtemplates").then((res) => res.data);
}
/**
 * resource: vestingEvent
 * action: view
 * @returns
 */

export async function getVestingEvents(): Promise<VestingEventReq[]> {
  return hissaApi.get("esop/vestevents").then((res) => res.data);
}
/**
 * resource: template
 * action: view
 * @returns
 */

export async function getEmployeeTemplate(): Promise<Template> {
  return hissaApi.get("employee/template").then((res) => res.data);
}
/**
 * resource: template
 * action: view
 * @returns
 */

export async function getGrantTemplate(): Promise<Template> {
  return hissaApi.get("esop/template").then((res) => res.data);
}
/**
 * resource: template
 * action: view
 * @returns
 */

export async function getVestingTemplateDetail({
  queryKey,
}: {
  queryKey: QueryKey;
}): Promise<VestingTemplateDetail> {
  const id = queryKey[1] as number;
  return hissaApi
    .post("vesting/template/import/details", { id })
    .then((res) => res.data);
}

/**
 * resource: template
 * action: view
 * @returns
 */

export async function getVestingTemplateNames(): Promise<String[]> {
  return hissaApi.get("vesting/template/dropdown").then((res) => res.data);
}

/**
 * resource: grant
 * action: create
 * @returns
 */

export async function addGrant(addGrantDto: AddGrantReq): Promise<AddGrantRes> {
  return hissaApi.post("employee/add", addGrantDto).then((res) => res.data);
}

/**
 * resource: grant
 * action: update
 * @returns
 */

export async function editGrant(
  addGrantDto: AddGrantReq
): Promise<AddGrantRes> {
  return hissaApi.put("employee/add", addGrantDto).then((res) => res.data);
}

/**
 * resource: grant
 * action: create
 * @returns
 */

export async function addGrantExcel(
  uploadExcelDto: UploadEmployeeExcel
): Promise<string> {
  return hissaApi
    .post("usingexcelfile/createOptionHolderv2", uploadExcelDto)
    .then((res) => res.data);
}

/**
 * resource: employee
 * action: create
 * @returns
 */

export async function addEmployeeExcel(
  uploadExcelDto: UploadEmployeeExcel
): Promise<string> {
  return hissaApi
    .post("usingexcelfile/createEmployeeV2", uploadExcelDto)
    .then((res) => res.data);
}

/**
 * resource: esopPlan
 * action: create
 * @returns
 */

export async function addPlan(esopPlanDto: AddPlanReq): Promise<AddPlanRes> {
  return hissaApi.post("esop/create", esopPlanDto).then((res) => res.data);
}
/**
 * resource: employee
 * action: create
 * @returns
 */

export async function addEmployee(
  employeeDto: AddEmployeeReq
): Promise<AddEmployeeRes> {
  return hissaApi.post("employee", employeeDto).then((res) => res.data);
}

/**
 * resource: employee
 * action: delete
 * @returns
 */

export async function deleteEmployee(id: number): Promise<DeleteResponse> {
  return hissaApi.delete(`employee/delete/?id=${id}`).then((res) => res.data);
}

/**
 * resource: employee
 * action: delete
 * @returns
 */

export async function revokeEmployeeAccess(
  empId: number
): Promise<RevokeAccessResponse> {
  return hissaApi
    .post(`employee/enable-disable/?employeeId=${empId}&deactivate=true`)
    .then((res) => res.data);
}
/**
 * resource: employee
 * action: create
 * @returns
 */

export async function enableEmployeeAccess(
  empId: number
): Promise<RevokeAccessResponse> {
  return hissaApi
    .post(`employee/enable-disable/?employeeId=${empId}&deactivate=false`)
    .then((res) => res.data);
}
/**
 * resource: employeePage
 * action: view
 * @returns
 */

export async function getEmployeeVestingTable(
  empId: number
): Promise<EmployeeVestingDetail[]> {
  return hissaApi
    .get(`employee/employee_page/vestingTable?EmployeeID=${empId}`)
    .then((res) => res.data);
}

/**
 * resource: employeePage
 * action: view
 * @returns
 */

export async function getEmployeePageDetails(
  context: QueryFunctionContext
): Promise<EmployeePageDetails> {
  return hissaApi
    .get(`employee/employee_page?EmployeeID=${context.queryKey[1]}`)
    .then((res) => res.data);
}

export async function getEmployeeTransactionDetails(): Promise<
  CustomResponse<EmployeeStockTransactionDetails[]>
> {
  return optionsApi
    .get(`v1/liquidityEvent/getEmployeeTransaction`)
    .then((res) => res.data);
}
/**
 * resource: employee
 * action: create
 * @returns
 */

export async function generateCredentials(
  empId: Number[] | undefined
): Promise<void> {
  return hissaApi.get(`employee/login/details?id=${empId}`);
}
/**
 * resource: vestingTemplate
 * action: create
 * @returns
 */

export async function addVestingTemplate(
  employeeDto: AddVestingTemplateReq
): Promise<AddVestingTemplateRes> {
  return hissaApi.post("vesting/template", employeeDto).then((res) => res.data);
}

export async function doLogin(loginDto: LoginRequest): Promise<LoginResponse> {
  return hissaApi.post("login", loginDto).then((res) => res as LoginResponse);
}
/**
 * resource: employee
 * action: create
 * @returns
 */

export async function resignationEmployeeDetails(
  id: number
): Promise<SeparationReq> {
  const status = "Resigned";
  return hissaApi
    .get(
      `employee/employee_page/resignation?EmployeeID=${id}&EmploymentStatus=${status}`
    )
    .then((res) => res.data);
}
/**
 * resource: employee
 * action: create
 * @returns
 */

export async function employeeVestingDetails(
  id: number
): Promise<CustomResponse<ProjectedVesting[]>> {
  return optionsApi.get(`v1/employee/vesting/${id}`).then((res) => res.data);
}

export async function settlementLetterDetails(
  id: number
): Promise<CustomResponse<SettlementDetailsDto>> {
  return optionsApi
    .get(`v1/employee/settlementLetter/${id}`)
    .then((res) => res.data);
}

export async function initiateSeparation(
  initiateSeparationDto: SeparationReq
): Promise<CustomResponse<SeparationRes>> {
  return optionsApi
    .post("v1/employee/resignation", initiateSeparationDto)
    .then((res) => res.data);
}
/**
 * resource: esopPlan
 * action: view
 * @returns
 */

export async function generateSettlementLetter(
  settlementDto: SettlementDto
): Promise<CustomResponse<string>> {
  return optionsApi
    .post("v1/employee/generateFinalSettlementLetter", settlementDto)
    .then((res) => res.data);
}

export async function getPlanDetails(
  planName: string
): Promise<ESOPPlandetail> {
  return hissaApi
    .get("/esop", { params: { planName } })
    .then((res) => res.data);
}
/**
 * resource: esopPlan
 * action: update
 * @returns
 */

export async function editPlan(esopPlanDto: AddPlanReq): Promise<AddPlanRes> {
  return hissaApi.put("esop/edit", esopPlanDto).then((res) => res.data);
}

/**
 * resource: grant
 * action: update
 * @returns
 */

export async function getNextStates(
  context: QueryFunctionContext
): Promise<NextStateDto> {
  return hissaApi
    .get(`employee/grantstate/?optionHolderId=${context.queryKey[1]}`)
    .then((res) => res.data)
    .catch((err) => err);
}

/**
 * resource: grant
 * action: update
 * @returns
 */

export async function changeOptionStates(
  optionStateChangeDto: OptionStateChangeReq
): Promise<OptionStateChangeRes> {
  return hissaApi
    .post("employee/bulkgrantstate", optionStateChangeDto)
    .then((res) => res.data);
}
/**
 * resource: esopPlan
 * action: view
 * @returns
 */

export async function getNextPlanStates(
  context: QueryFunctionContext
): Promise<NextPlanStateDto> {
  return hissaApi
    .get(`esop/planstate?id=${context.queryKey[1]}`)
    .then((res) => res.data)
    .catch((err) => err);
}
/**
 * resource: esopPlan
 * action: update
 * @returns
 */

export async function changePlanState(
  planStateChangeDto: PlanStateChangeDto
): Promise<any> {
  return hissaApi
    .post(`esop/planstate`, planStateChangeDto)
    .then((res) => res.data);
}
/**
 * resource: esopPlan
 * action: update
 * @returns
 */

export async function uploadPlanDocument(
  planDocumentDto: UploadPlanDocumentDto
): Promise<any> {
  return hissaApi
    .post("esop/upload/plan-documentV2", planDocumentDto)
    .then((res) => res.data);
}

/**
 * resource: grant
 * action: view
 * @returns
 */

export async function getAllGrantActivity(): Promise<GrantActivity[]> {
  return hissaApi.get(`esop/grants/activityLog`).then((res) => res.data);
}
/**
 * admin
 * @returns
 */

export async function getCompanyUsers(): Promise<UserDetailModel[]> {
  return hissaApi.get(`/ownership/getUsers`).then((res) => res.data);
}

/**
 * @returns
 */

export async function getUserDetails(): Promise<UserDetailModel> {
  return hissaApi.get("/user").then((res) => res?.data);
}

/**
 * resource: grant
 * action: delete
 * @returns
 */

export async function deleteGrant(id: number[]): Promise<DeleteResponse> {
  const url = `employee/delete/optionholder/?id=${id.join(",")}`;
  return hissaApi.delete(url).then((res) => res.data);
}
/**
 * resource: esopPlan
 * action: delete
 * @returns
 */

export async function deletePlan(
  id: number
): Promise<AxiosResponse<Result, Result>> {
  return hissaApi.delete(`esop/delete?id=${id}`).then((res) => res);
}

/**
 * resource: employee
 * action: view
 * @returns
 */

export async function remindOfferedEmployee(id: number): Promise<any> {
  return hissaApi
    .post(`employee/reminder/accept?optionHolderId=${id}`)
    .then((res) => res);
}
/**
 * resource: financialReports
 * action: view
 * @returns
 */

export async function getGenerateFinancialReport(
  context: QueryFunctionContext
): Promise<ReportDto> {
  return hissaApi
    .get(`report-generation/financial-report/${context.queryKey[1]}`, {
      params: {
        planType: context.queryKey[3],
        includeResignedEmployees: context.queryKey[2],
      },
    })
    .then((res) => res.data);
}

/**
 * resource: financialReports
 * action: view
 * @returns
 */

export async function getGenerateFinancialReportWithVesting(
  context: QueryFunctionContext
): Promise<ReportDto> {
  return hissaApi
    .get(
      `report-generation/financial-report/withVesting/${context.queryKey[1]}/${context.queryKey[2]}`,
      {
        params: {
          planType: context.queryKey[4],
          includeResignedEmployees: context.queryKey[3],
        },
      }
    )
    .then((res) => res.data);
}

/**
 * resource: financialReports
 * action: view
 * @returns
 */

export async function getSH6Report(
  context: QueryFunctionContext
): Promise<ReportDto> {
  return hissaApi
    .get(
      `report-generation/exportSH6?date=${context.queryKey[1]}&id=${context.queryKey[2]}`
    )
    .then((res) => res.data);
}

/**
 * resource: esopPlan
 * action: view
 * @returns
 */

export async function getCreatePlanDetails(): Promise<EsopCreateDto> {
  return hissaApi.get(`/esop/names/dropdown`).then((res) => res.data);
}

/**
 * resource: esopPool
 * action: view
 * @returns
 */

export async function getCurrentPoolSize(): Promise<CurrentPoolSize> {
  return hissaApi.get(`/esop/get_current_pool_size`).then((res) => res.data);
}

/**
 * resource: esopPool
 * action: update
 * @returns
 */

export async function getCapTablePreviewAfterPoolUpdate(
  updatedPreview: any
): Promise<CurrentPoolSize> {
  return hissaApi
    .post(`/esop/get_current_pool_size/preview`, updatedPreview)
    .then((res) => res.data);
}

/**
 * resource: esopPool
 * action: update
 * @returns
 */

export async function addEditPoolSize(
  updatedPreview: any
): Promise<CurrentPoolSize> {
  return hissaApi
    .post(`esop/add_or_edit_pool_size`, updatedPreview)
    .then((res) => res.data);
}

export async function doLogout(): Promise<{}> {
  return hissaApi.get("exit").then((res) => res);
}

export async function doVerifyOtp(
  loginDto: LoginRequest
): Promise<LoginResponse> {
  return hissaApi
    .post("login/verifyOtp", loginDto)
    .then((res) => res as LoginResponse);
}

export async function doResendOtp(emailId: string): Promise<any> {
  return hissaApi.post("login/resendOtp", { emailId }).then((res) => res.data);
}

/**
 * resource: company
 * action: view
 * @returns
 */

export async function getAllSubsidiaryCompanies(): Promise<
  EsopSubsidiaryCompany[]
> {
  return hissaApi
    .get("/esopSubsidiaryCompany/getAllNames")
    .then((res) => res.data);
}
/**
 * resource: *
 * action: *
 * @returns
 */

export async function savePlanOwner(
  ownerShipReq: AddOwnershipReq
): Promise<any> {
  return hissaApi
    .post("/ownership/addOwnership", ownerShipReq)
    .then((res) => res.data);
}

/**
 * resource: *
 * action: *
 * @returns
 */

export async function getOwnersOfPlan(
  context: QueryFunctionContext
): Promise<any> {
  const queryString = context.queryKey[1];
  return hissaApi
    .get(`/ownership/${queryString}`)
    .then((res) => res.data)
    .catch((err) => err);
}

export async function uploadFile(formData: FormData): Promise<UploadRes[]> {
  return optionsApi.post("/v1/files/upload", formData).then((res) => res.data);
}

export async function downloadPoolStatement(): Promise<CustomResponse<string>> {
  return optionsApi.post("/v1/generatePoolStatement").then((res) => res.data);
}

export async function validateExcel(
  context: QueryFunctionContext
): Promise<CustomResponse<ValidateRes>> {
  return optionsApi
    .get(`/v1/imports/all/validate?fileKey=${context.queryKey[1]}`)
    .then((res) => res.data);
}

/**
 * resource: grant
 * action: create
 * @returns
 */

export async function generateReport(
  context: QueryFunctionContext
): Promise<CustomResponse<any>> {
  return optionsApi
    .get(
      `/v1/imports/downloadReport?fileKey=${context.queryKey[1]}&date=${context.queryKey[2]}`
    )
    .then((res) => res.data);
}

export async function downloadTemplate(): Promise<CustomResponse<Template>> {
  return optionsApi.get("/v1/imports/getTemplate").then((res) => res.data);
}

export async function importAll(fileKey: string): Promise<ImportRes[]> {
  return optionsApi
    .post("/v1/imports/all", { fileKey })
    .then((res) => res.data.data);
}
/**
 * resource: esopPlan
 * action: view
 * @returns
 */

export async function getEsopAccounting(
  values: EsopAccountingReq
): Promise<ReportDto> {
  return optionsApi
    .post("v1/exports/esopAccounting", values)
    .then((res) => res.data);
}

/**
 * resource: grant
 * action: create
 * @returns
 */

export async function bulkGenerateGrantLetters(ids: number[]) {
  return hissaApi.post(`employee/generateGrantLetters/?id=${ids.join(",")}`);
}
/**
 * resource: esopPlan
 * action: view
 * @returns
 */

export async function getAllPlanDocs(
  context: QueryFunctionContext
): Promise<Array<DocumentInterface>> {
  return hissaApi
    .get(`/documents/getAllPlanDocs/?planId=${context.queryKey[1]}`)
    .then((res) => res.data);
}

/**
 * resource: esopPlan
 * action: update
 * @returns
 */

export async function updatePlanDocs(
  documentToUpdate: DocumentInterface[]
): Promise<Array<DocumentInterface>> {
  return hissaApi
    .post(`/documents/updateDocuments/`, documentToUpdate)
    .then((res) => res.data);
}

export async function getPresignedUrl(
  context: QueryFunctionContext
): Promise<{ s3Key: string }> {
  return hissaApi
    .get(`/aws/getPresignedUrl/?s3Key=${context.queryKey[1]}`)
    .then((res) => res.data);
}
export async function getPresignedUrlForKey(
  awsKey: string
): Promise<{ s3Key: string }> {
  return hissaApi
    .get(`/aws/getPresignedUrl/?s3Key=${awsKey}`)
    .then((res) => res.data);
}

/**
 * resource: esopPlan
 * action: create
 * @returns
 */

export async function createEnvelope(
  envelopDetails: EnvelopeDetails
): Promise<any> {
  return nodeApi
    .post("/docuSign/createEnvelope", envelopDetails)
    .then((res) => res.data);
}

export async function generateJwtTokenForEmployee(employeeId: number) {
  return hissaApi
    .get(`employee/getAdminViewEmployeeToken?id=${employeeId}`)
    .then((res) => res.data);
}
export async function generateJwtToken(jwtToken: JwtPayload) {
  return nodeApi
    .post("auth/generateJwtToken", jwtToken)
    .then((res) => res.data);
}

export async function getTransactionsCard(): Promise<TransactionCard> {
  return esopNodeApi.get("cards/transactions").then((res) => res.data.data);
}
export async function signAndApproveGrantLetter(
  signatureObj: SignatureAttachment
) {
  return optionsApi
    .post("v1/document/insertSignatureApprover", signatureObj)
    .then((res) => res.data.data);
}

export async function previewSettlementLetter(
  signatureObj: SettlementSignatureDto
): Promise<string> {
  return optionsApi
    .post("v1/document/previewSignatureForSettlementLetter", signatureObj)
    .then((res) => res.data.data);
}

export async function updateSettlementLetter(
  signatureObj: UpdateSettlementLetterDto
): Promise<CustomResponse<string>> {
  return optionsApi
    .post("v1/document/updateSettlementLetterAfterPreviewing", signatureObj)
    .then((res) => res.data.data);
}

export async function validateHrmsConfig(params: any) {
  return optionsApi
    .post("/v1/employee/import/test", params)
    .then((res) => res.data.data);
}

export async function syncFromHRMS(params: any) {
  return optionsApi
    .post("/v1/employee/import/syncData", params)
    .then((res) => res.data.data);
}
export async function getHRMSConfigs(): Promise<
  [{ companyId: string; masterDataType: string; mapperData: any }]
> {
  return optionsApi
    .get("/v1/employee/import/config")
    .then((res) => res.data.data);
}
export async function getSyncHistory() {
  return optionsApi
    .get("/v1/employee/import/syncHistory")
    .then((res) => res.data.data);
}
export async function getCompanyExists(
  context: QueryFunctionContext
): Promise<CaptableUserAccess> {
  const captableCompanyId = context.queryKey[1];
  return captableApi
    .get(`/v1/auth/esopUserAccess?companyId=${captableCompanyId}`)
    .then((res) => res?.data.data);
}
export async function doesCaptableCompanyExists(companyId: any) {
  return captableApi
    .get(`/v1/auth/esopUserAccess?companyId=${companyId}`)
    .then((res) => res?.data.data);
}

export async function setDefaultProduct(): Promise<String> {
  return hissaApi
    .put(`/user/setDefaultProduct?productType=CAPTABLE`)
    .then((res) => res?.data);
}
