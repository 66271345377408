import StatusLabelColors from "../../constants/StatusLabelColors";
import { stringFormat } from "../../utils/string";

function StatusLabel({ state }: { state: string }) {
  state = state.toUpperCase();
  return (
    <span
      className="h-4 capitalize w-20 rounded-sm font-medium text-center text-[9px]  leading-4 px-2 "
      style={{
        color: StatusLabelColors?.[state]?.color,
        backgroundColor: StatusLabelColors?.[state]?.backgroundColor,
      }}
    >
      {stringFormat(state.toLowerCase())}
    </span>
  );
}

export default StatusLabel;
