export interface GenerateEmployeeListReq {
  optionBuybackName: string;
  buybackOfferEnd: string;
  percentage?: number;
  totalOptionVested?: number;
  description: string;
  buybackStartDate: string;
  effectivebuybackDate: string;
  buybackPrice?: number;
  totalEligibleOptions?: number;
  totalEstimatedValue?: number;
}

export interface optionBuybackDetail {
  buyBackOffers?: CumulativeOffer[];
  id?: number;
  companyId?: number;
  type: string;
  name: string;
  description: string;
  percentage: number;
  noOfOptions: number;
  isWillingNessGiven: boolean;
  price: number;
  fmvPrice?: number;
  offers: Offer[];
  cumuOffers: Offer[];
  vestingCutOffDate: string;
  startDate: string;
  endDate: string;
  state: string;
  creationType?: string;
  transactionType: string;
  liquidationEndDate?: string;
  elState?: string;
  sellingPrice?: number;
  isBankDetailAsked?: boolean;
  miscCharges?: number;
  buyerName?: string;
  recurring: boolean;
  recurringInterval: number;
  recurringIntervalUnit: string;
  isSuccessorGenerated?: boolean;
  maxFrequency?: number;
  department?: [];
  minimumTenure?: number;
  minimumVestedOptions?: number;
  minimumWillingness?: number;
  intervalUnit?: string;
  interval?: number;
  isAdvanceFilter?: boolean;
  offerFilter?: OfferFilter;
  participationConstraints: ParticipationConstraints[];
  autoPilot: boolean;
}

export const baseParticipationConstraints = {
  interval: 0,
  intervalUnit: "",
  maxFrequency: 0,
};
export interface ParticipationConstraints {
  maxFrequency: number;
  interval: number;
  intervalUnit: string;
}

export interface OfferFilter {
  recurring?: boolean;
  maxFrequency?: number;
  department?: [];
  minimumTenure?: number;
  minimumVestedOptions?: number;
  minimumWillingness?: number;
  intervalUnit?: string;
  interval?: number;
  participationConstraints: ParticipationConstraints[];
}
export interface StateBuybackDetail {
  ids: any;
  employeeId: number;
  employeeName: string;
  granted: number;
  vested: number;
  exercised: number;
  eligibility: number;
  willingness: number;
  state: string;
  letterUrl: any;
  pricePerOption: any;
  buyBackName: any;
  startDate: any;
  endDate: any;
  buyBackState: string;
  edit?: boolean;
  totalPriceToPay: number;
  stockExercisedOptions?: number;
  cashExercisedOptions?: number;
  forfietedOptions?: number;
}
export interface ExercisableDetails {
  totalVested: number;
  totalExercisedAndForfeited: number;
  totalEligibility: number;
  offers: Offer[];
  cumulativeOffers: CumulativeOffer[];
}

export interface Offer {
  id: any;
  employeeName: string;
  employeeId: number;
  willingness: number;
  eligibility: number;
  amountReceived: number;
  letterUrl: any;
  state: any;
  optionHolderId: number;
  responseDate: any;
  createdAt: any;
  updatedAt: any;
  nextReminderEmailDate: any;
}

export interface CumulativeOffer {
  ids: any;
  employeeId: number;
  employeeName: string;
  granted: number;
  vested: number;
  exercised: number;
  eligibility: number;
  willingness: number;
  state: string;
  letterUrl: string;
  pricePerOption: number;
  buyBackName: string;
  startDate: string;
  endDate: string;
  buyBackState: string;
  edit?: boolean;
  tds?: number;
  stockExercisedOptions?: number;
  cashExercisedOptions?: number;
  forfietedOptions?: number;
}

export interface EmpData {
  name: string;
  grantedOptions: string;
  vestedOptions: string;
  exercisedOptions: string;
  eligibility: string;
}

export interface LiquidationDetails {
  name: string;
  startDate: string;
  endDate: string;
  sellingPrice: number | undefined;
  buyerName: string;
  totalAmount: number | undefined;
}

export interface ErrorInterface {
  httpStatusCode: number;
  errorType: "General" | "Raw" | "Validation" | "Unauthorized";
  message: string;
  errors: string[] | null;
  errorRaw: string;
  errorsValidation: any;
}
