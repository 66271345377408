import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
} from "../../components/utils";
import AddEmployee from "../../modals/AddEmployee";
import { Valuation } from "../../types/ValuationType";
import AddOrEditValuation from "./AddOrEditValuation";
import ValuationTable from "./ValuationTable";
import BCHeader from "../../shared/BCHeader";
import { useAuthorizationStore } from "../../store";

function ValuationOverview() {
  const { authority, permissions: actualPermissions } = useAuthorizationStore();
  const [isUserAdmin, setIsUserAdmin] = useState<boolean>();
  useEffect(() => {
    setIsUserAdmin(
      authority?.includes("ROLE_ADMIN" || "ROLE_ESOP_ADMIN") &&
        actualPermissions?.includes(
          "FULL_ACCESS" || "ADMIN_SAVING_POST" || "ADMIN_SETTINGS_GET"
        )
    );
  }, [authority, actualPermissions]);
  const [dialog, setDialog] = useState<{
    open: boolean;
    data?: Valuation;
  }>({
    open: false,
    data: undefined,
  });
  return (
    <>
      {isUserAdmin && (
        <VStack className="gap-4">
          <div className="flex items-baseline justify-between">
            <BCHeader
              className="items-baseline "
              bcTitle="Settings"
              bcSubTitle="Valuation Reports"
            />
          </div>
          <HStack className="justify-end">
            <ButtonPrimary
              className="flex justify-end flex-reverse-row"
              onClick={() => {
                setDialog({ open: true });
              }}
            >
              Add Valuation
            </ButtonPrimary>
          </HStack>
          <Dialog open={dialog.open} maxWidth="md">
            <AddOrEditValuation
              onClose={() => setDialog({ open: false })}
              data={undefined}
              mode="Add"
            />
          </Dialog>
          <HStack className="flex flex-row">
            <ValuationTable />
          </HStack>
        </VStack>
      )}
    </>
  );
}

export default ValuationOverview;
