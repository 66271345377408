import _ from "lodash";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { useState } from "react";
import {
  HStack,
  Error,
  VStack,
  ButtonPrimary,
  ButtonPrimary1,
} from "../components/utils";
import { Input, Label } from "../components/shared/InputField";
import { useAuthStore } from "../store";
import { Select } from "../components/shared/Select";
import { useChangePassword } from "../queries/userProfile";
import { ChangePasswordDetails } from "../types/UserDetailModel";

export function ChangePassword({
  onSecondaryAction,
}: {
  onSecondaryAction: Function;
}) {
  const { user } = useAuthStore.getState();
  const { mutate: changePassword } = useChangePassword();

  const getCharacterValidationError = (str: string) =>
    `Your password must have at least 1 ${str} character`;

  const ppsValuationSchema = Yup.object().shape({
    currentPassword: Yup.string().required().label("Current Password"),
    newPassword: Yup.string()
      .min(8, "Password must have at least 8 characters")
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
      .matches(
        /[!@#$%^&*()_+{}[\]:;<>,.?~-]/,
        getCharacterValidationError("special character")
      ),
    confirmPassword: Yup.string()
      .required()
      .label("Confirm Password")
      .oneOf([Yup.ref("newPassword")], "Passwords must match"),
  });

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const handleSubmit = (values: any) => {
    changePassword(
      {
        emailId: user?.emailId || "",
        passwordNew: values.newPassword,
        currentPassword: values.currentPassword,
        passwordConfirm: values.confirmPassword,
      },
      {
        onSuccess: () => {
          toast("Password Changed Successfully", {
            type: "success",
            autoClose: 2000,
          });
          onSecondaryAction();
        },
        onError: (err: any) => {
          toast(err.response.data.errorMessage, {
            type: "error",
            autoClose: 2000,
          });
        },
      }
    );
  };

  return (
    <div className="w-full p-3">
      <HStack className="justify-between border-b">
        <p className="mb-3 ml-4 text-lg font-semibold">Change Password</p>
        <span onClick={() => onSecondaryAction()} className="cursor-pointer">
          X
        </span>
      </HStack>
      <Formik
        enableReinitialize={true}
        validationSchema={ppsValuationSchema}
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
      >
        {(formik) => (
          <Form>
            <VStack className="p-3">
              <HStack className="justify-between m-2">
                <Label
                  htmlFor="password"
                  className="block mt-3 mr-5 text-sm font-medium text-gray-700"
                >
                  Current Password
                </Label>
                <VStack>
                  <HStack className="items-center bg-[#EEF1F5] drop-shadow-box focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                    <Input
                      id="currentPassword"
                      type={`${showCurrentPassword ? "text" : "password"}`}
                      autoComplete="current-password"
                      className="block w-full appearance-none rounded-md bg-[#EEF1F5]  px-3 py-2 drop-shadow-box focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      {...formik.getFieldProps(`currentPassword`)}
                    />
                    <Icon
                      icon={`${
                        showCurrentPassword ? "ph:eye-slash" : "ph:eye"
                      }`}
                      width={24}
                      height={24}
                      className="mr-2 cursor-pointer text-orange-501"
                      onClick={() => {
                        setShowCurrentPassword(!showCurrentPassword);
                      }}
                    />
                  </HStack>
                  {formik.touched?.currentPassword &&
                    formik.errors?.currentPassword && (
                      <Error text={formik.errors?.currentPassword} />
                    )}
                </VStack>
              </HStack>
              <HStack className="justify-between m-2">
                <Label
                  htmlFor="password"
                  className="block mt-3 mr-5 text-sm font-medium text-gray-700"
                >
                  New Password
                </Label>
                <VStack>
                  <HStack className="items-center bg-[#EEF1F5] drop-shadow-box focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                    <Input
                      id="newPassword"
                      type={`${showNewPassword ? "text" : "password"}`}
                      autoComplete="current-password"
                      className="block w-full appearance-none rounded-md bg-[#EEF1F5]  px-3 py-2 drop-shadow-box focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      {...formik.getFieldProps(`newPassword`)}
                    />
                    <Icon
                      icon={`${showNewPassword ? "ph:eye-slash" : "ph:eye"}`}
                      width={24}
                      height={24}
                      className="mr-2 cursor-pointer text-orange-501"
                      onClick={() => {
                        setShowNewPassword(!showNewPassword);
                      }}
                    />
                  </HStack>
                  {formik.touched?.newPassword &&
                    formik.errors?.newPassword && (
                      <Error text={formik.errors?.newPassword} />
                    )}
                </VStack>
              </HStack>
              <HStack className="justify-between m-2">
                <Label
                  htmlFor="password"
                  className="block mt-3 mr-5 text-sm font-medium text-gray-700"
                >
                  Confirm Password
                </Label>
                <VStack>
                  <HStack className="items-center bg-[#EEF1F5] drop-shadow-box focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                    <Input
                      id="confirmPassword"
                      type={`${showConfirmPassword ? "text" : "password"}`}
                      autoComplete="current-password"
                      className="block w-full appearance-none rounded-md bg-[#EEF1F5]  px-3 py-2 drop-shadow-box focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      {...formik.getFieldProps(`confirmPassword`)}
                    />
                    <Icon
                      icon={`${
                        showConfirmPassword ? "ph:eye-slash" : "ph:eye"
                      }`}
                      width={24}
                      height={24}
                      className="mr-2 cursor-pointer text-orange-501"
                      onClick={() => {
                        setShowConfirmPassword(!showConfirmPassword);
                      }}
                    />
                  </HStack>
                  {formik.touched?.confirmPassword &&
                    formik.errors?.confirmPassword && (
                      <Error text={formik.errors?.confirmPassword} />
                    )}
                </VStack>
              </HStack>
              <HStack className="justify-between mt-10">
                <ButtonPrimary1
                  onClick={() => {
                    onSecondaryAction();
                  }}
                >
                  Back
                </ButtonPrimary1>
                <ButtonPrimary type="submit">Save</ButtonPrimary>
              </HStack>
            </VStack>
          </Form>
        )}
      </Formik>
    </div>
  );
}
