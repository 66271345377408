import "./App.css";
import "./styles/loader.css";
import "react-toastify/dist/ReactToastify.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import * as AgGrid from "ag-grid-enterprise";
import { GridChartsModule } from "@ag-grid-enterprise/charts";
import { QueryClientProvider } from "react-query";
import { Dialog, ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import Router from "./routes";
import ScrollToTop from "./components/shared/ScrollToTop";
import { muiTheme } from "./theme/mui";
import { queryClient } from "./queries";
import AlertDialog from "./components/shared/AlertDialog";
import { useDialogStore } from "./store/alertDialog";
import { useAuthStore, useAuthorizationStore } from "./store";
import HandleHissaRedirect from "./components/shared/HandleHissaRedirect";
import DialogContainer from "./components/DialogContainer";
import { amplitudeInit, handleClickForTracking } from "./amplitudeAnalytics";

const agGridLicenseKey = process.env.REACT_APP_AG_LICENSE_KEY || "";
AgGrid.LicenseManager.setLicenseKey(agGridLicenseKey);

function App() {
  amplitudeInit();
  useEffect(() => {
    queryClient.prefetchQuery("vestingTemplates");
  });
  useAuthStore();
  useAuthorizationStore();
  return (
    <QueryClientProvider client={queryClient}>
      <ScrollToTop />
      <HandleHissaRedirect />
      <ThemeProvider theme={muiTheme}>
        <div
          onClick={(e) => {
            handleClickForTracking(e);
          }}
        >
          <Router />
        </div>
        <ToastContainer position="bottom-right" autoClose={2000} />
        <AlertDialog {...useDialogStore().dialog} />
        <DialogContainer />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
