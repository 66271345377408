import Dialog from "@mui/material/Dialog";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, HStack, VStack } from "../../components/utils";
import { useEsopPlans, useGrants } from "../../queries";
import BCHeader from "../../shared/BCHeader";
import CardDraft from "../../shared/card-draft";
import CardPend from "../../shared/card-pend";
import CardPool from "../../shared/card-pool";
import { EsopPlan } from "../../types/EsopPlan";
import PendingRequests from "../../shared/PendingRequests";
import { Dropdown } from "../../utils/interfaces/BCHeader";
import GrantsTable from "../esopOverview/GrantsTable";
import AddOrEditPlan from "./AddPlan";
import ESOPPlan from "./ESOPPlan";
import GranvtVSYearsExp from "./granvtVSYearsExp";
import useIsMobile from "../../utils/detectDevice";
import { useAuthorizationStore } from "../../store";

const SinglePlanView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { authority } = useAuthorizationStore();
  const _id = id || "";
  const { data: _grants } = useGrants();
  const { data: _plans, isFetching } = useEsopPlans();
  const [dialog, setDialog] = useState<{
    open: boolean;
    plan?: EsopPlan;
    mode?: "Edit" | "Add" | "Clone";
  }>({ open: false, plan: undefined, mode: "Add" });

  const singlePlan =
    _plans?.filter((plan) => plan.esopPlanId === parseInt(_id, 10)) || [];
  const planFilterName: Dropdown[] = [];
  _plans?.forEach((plan) => {
    planFilterName.push({
      name: plan.planName,
      id: plan.esopPlanId,
    });
  });
  const [selectedPlan, setSelectedPlan] = useState<Dropdown | undefined>();
  const initialFilterValue = useMemo(() => {
    const selectedPlanFilter = planFilterName.filter(
      (plan) => plan.id === parseInt(_id, 10)
    )[0];
    return selectedPlanFilter;
  }, [_id, _plans]);
  useEffect(() => {
    setSelectedPlan(initialFilterValue);
  }, [initialFilterValue, _id]);
  const handleAction = (plan: EsopPlan) => {
    setDialog({ open: true, mode: "Edit", plan });
  };

  const handlePlan = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.value;
    if (id === "Total") {
      navigate("/options/allPlans");
    } else navigate(`/options/planView/${id}`);
  };
  planFilterName.push({ name: "All Plans", id: "Total" });
  const { isMobile } = useIsMobile();
  return (
    <VStack className="gap-4 ">
      <BCHeader
        className="items-baseline"
        bcTitle="All Plans"
        bcSubTitle={selectedPlan?.name || ""}
        onClick={() => handleAction(singlePlan[0])}
        selectOption={handlePlan}
        buttonName="Amend Plan"
        dropdown={planFilterName}
        selectedValue={selectedPlan}
      ></BCHeader>
      <Dialog open={dialog.open} maxWidth="lg">
        <div className="w-[900px] mx-auto bg-white rounded-lg">
          <AddOrEditPlan
            mode="Edit"
            plan={dialog.plan}
            onClose={() => {
              setDialog({ open: false });
            }}
          />
        </div>
      </Dialog>
      <HStack className="grid gap-6 overflow-auto rounded-lg lg:grid-cols-3 md:grid-cols-2">
        <ESOPPlan />
        <CardPool />
        <CardDraft />
      </HStack>
      <HStack className="grid gap-6 overflow-auto rounded-lg lg:grid-cols-2 md:grid-cols-2">
        <PendingRequests />
        <CardPend />
      </HStack>
      {(authority === "ROLE_ADMIN" || authority === "ROLE_ESOP_ADMIN") && (
        <Box className="bg-white ">
          <GranvtVSYearsExp />
        </Box>
      )}
      <Box className={`w-full ${isMobile ? "overflow-x-auto" : "grow"} `}>
        <GrantsTable />
      </Box>
    </VStack>
  );
};

export default SinglePlanView;
