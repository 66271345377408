import React, { useState } from "react";
import { format } from "date-fns";
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  HStack,
  VStack,
  getCompanyName,
} from "../../components/utils";

import { Input, Label } from "../../components/shared/InputField";
import { useGenerateReport } from "../../queries";
import {
  convertBase64ToBlob,
  downloadBlobObject,
} from "../../utils/DownloadFile";

function OnboardingReportDialog({
  open,
  fileKey,
  onClose = () => {},
}: {
  open: boolean;
  fileKey?: string;
  onClose: () => void;
}) {
  const [date, setDate] = useState<string>(format(new Date(), "yyyy-MM-dd"));
  function handleDateUpdate(e: React.ChangeEvent<HTMLInputElement>) {
    const dateValue = format(
      new Date(e.target.value) || new Date(),
      "yyyy-MM-dd"
    );
    setDate(dateValue);
  }
  const { refetch: refetchForGenerateReport, status: reportStatus } =
    useGenerateReport(fileKey || "", date);
  const fileName = `Onboarding Report_${getCompanyName()}.xlsx`;
  async function handleGenerateReport() {
    const res = await refetchForGenerateReport();
    const blob = await convertBase64ToBlob(
      res.data?.data,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
    downloadBlobObject(blob, fileName);
  }
  return (
    <>
      <div className="p-4 text-lg font-medium border-b">
        <h6>Download Grant Onboarding Report</h6>
      </div>
      <VStack>
        <HStack className="items-center gap-4 mt-6 ml-6 ">
          <Label className="text-sm font-normal">Vesting Date</Label>
          <Box>
            <Input type="date" onChange={(e) => handleDateUpdate(e)} />
          </Box>
        </HStack>
        <HStack className="justify-between px-4 py-6 mt-7">
          <ButtonSecondary
            onClick={() => onClose()}
            className="text-gray-400 bg-slate-50"
          >
            Cancel
          </ButtonSecondary>
          <ButtonPrimary onClick={handleGenerateReport}>Generate</ButtonPrimary>
        </HStack>
      </VStack>
    </>
  );
}
export default OnboardingReportDialog;
