import React, { useEffect, useState } from "react";
import { Form, Formik, useFormikContext, useFormik } from "formik";
import * as Yup from "yup";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
  Error,
} from "../../components/utils";
import { ExistingEmployeesDetails } from "../../store/esopModellingStore";
import EsopModellingGrades from "../../constants/EsopModellingGrades";

type AddEmployeesProps = {
  onClose: () => void;
  onPrimaryAction: (data: ExistingEmployeesDetails) => void;
  data?: ExistingEmployeesDetails;
};

function AddEmployeesPerGradeDialog({
  onClose = () => {},
  onPrimaryAction = () => {},
  data,
}: AddEmployeesProps) {
  const currentYear: number = new Date().getFullYear();
  const initialEmployeeDetailsPerGrade: ExistingEmployeesDetails = {
    gradeName: data?.gradeName || "",
    y0: data?.y0 || 0,
    y1: data?.y1 || 0,
    y2: data?.y2 || 0,
    y3: data?.y3 || 0,
    total: data?.total || 0,
  };
  const validationSchema = Yup.object().shape({
    gradeName: Yup.string().required("Grade is required"),
    y0: Yup.number().min(0).required("required"),
    y1: Yup.number().min(0).required("required"),
    y2: Yup.number().min(0).required("required"),
    y3: Yup.number().min(0).required("required"),
  });
  const handleSubmit = (values: ExistingEmployeesDetails) => {
    onPrimaryAction(values);
  };

  const formik = useFormik({
    initialValues: initialEmployeeDetailsPerGrade,
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  useEffect(() => {
    const totalEmployees =
      formik.values.y0 + formik.values.y1 + formik.values.y2 + formik.values.y3;

    formik.setFieldValue("total", totalEmployees);
  }, [formik.values.y0, formik.values.y1, formik.values.y2, formik.values.y3]);

  return (
    <VStack className="w-full gap-9 p-10">
      <Box className="text-lg font-medium border-b">
        <h6 className="justify-between flex">
          Add Employees Per Grade
          <span onClick={() => onClose()} className="cursor-pointer">
            X
          </span>{" "}
        </h6>
      </Box>
      <HStack className="gap-8 ">
        <div className="flex-1">
          <Label className="text-sm font-normal">Grade</Label>
          <Select
            options={EsopModellingGrades}
            {...formik.getFieldProps("gradeName")}
          />
          {formik.touched.gradeName && formik.errors.gradeName && (
            <Error text={formik.errors.gradeName} />
          )}
        </div>
        <div className="flex-1">
          <Label className="text-sm font-normal">{currentYear}</Label>
          <Input
            type="number"
            placeholder="Enter Number"
            className="w-48"
            {...formik.getFieldProps("y0")}
          />
          {formik.touched.y0 && formik.errors.y0 && (
            <Error text={formik.errors.y0} />
          )}
        </div>
      </HStack>
      <HStack className="gap-8 ">
        <div className="flex-1">
          <Label className="text-sm font-normal">{currentYear - 1}</Label>
          <Input
            type="number"
            placeholder="Enter Number"
            className="w-48"
            {...formik.getFieldProps("y1")}
          />
          {formik.touched.y1 && formik.errors.y1 && (
            <Error text={formik.errors.y1} />
          )}
        </div>
        <div className="flex-1">
          <Label className="text-sm font-normal">{currentYear - 2}</Label>
          <Input
            type="number"
            placeholder="Enter Number"
            className="w-48"
            {...formik.getFieldProps("y2")}
          />
          {formik.touched.y2 && formik.errors.y2 && (
            <Error text={formik.errors.y2} />
          )}
        </div>
      </HStack>
      <HStack className="gap-8 ">
        <div className="flex-1">
          <Label className="text-sm font-normal">
            {currentYear - 3} Or Earlier
          </Label>
          <Input
            type="number"
            className="w-48"
            placeholder="Enter Number"
            {...formik.getFieldProps("y3")}
          />
          {formik.touched.y3 && formik.errors.y3 && (
            <Error text={formik.errors.y3} />
          )}
        </div>
      </HStack>
      <HStack className="justify-between mt-12">
        <ButtonPrimary1
          type="reset"
          className="text-red-500 mr-8"
          onClick={() => {
            onClose();
          }}
        >
          Close
        </ButtonPrimary1>
        <ButtonPrimary
          type="submit"
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Create
        </ButtonPrimary>
      </HStack>
    </VStack>
  );
}

export default AddEmployeesPerGradeDialog;
