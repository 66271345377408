import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Dialog } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { Select } from "../../components/shared/Select";
import {
  Box,
  Error,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
} from "../../components/utils";
import { Valuation } from "../../types/ValuationType";
import { Input, Label } from "../../components/shared/InputField";
import FileInput from "../../components/shared/FileInput";
import convertToBase64 from "../../utils/convertToBase64";
import LazyPdfDocument from "../../components/shared/LazyPdfDocument";
import { formatDate, formatWithTimeZone } from "../../utils/date";
import {
  useAddValuation,
  useEditValuation,
  useGetAllRoundNames,
} from "../../queries/Valuation";
import { useAuthStore } from "../../store";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { editValuation } from "../../api/Valuation";

type AddOrEditValuationProps = {
  mode: "Edit" | "Add";
  data?: Valuation;
  onClose: () => void;
};

function AddOrEditValuation(props: AddOrEditValuationProps) {
  const currency = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const { user } = useAuthStore();
  const { data, mode, onClose } = props;
  const baseValues: Valuation = {
    name: "",
    dateOfValuation: "",
    valuationType: "Equity",
    valuation: 0,
    valuationMethodology: "",
    bookValue: 0,
    linkedTo: "",
    valuer: "",
    valuerRgNo: "",
    valuationReport: "",
    valuationReportName: "",
    valuationReportType: "",
    udinNo: "",
    marketValuation: 0,
  };

  function getInitialValuesEditing(data: Valuation | undefined) {
    if (!data) return baseValues;
    return {
      id: data.id,
      name: data.name,
      dateOfValuation: formatDate(data.dateOfValuation),
      valuationType: data.valuationType,
      valuation: data.valuation,
      valuationMethodology: data.valuationMethodology,
      bookValue: data.bookValue,
      linkedTo: data.linkedTo,
      valuer: data.valuer,
      valuerRgNo: data.valuerRgNo,
      valuationReport: data.valuationReport,
      valuationReportName: data.valuationReportName,
      valuationReportType: data.valuationReportType,
      udinNo: data.udinNo,
      isReportOverrided: false,
      marketValuation: data.marketValuation,
    };
  }

  function getInitialValues() {
    if (mode === "Edit") {
      return getInitialValuesEditing(data);
    } else {
      return baseValues;
    }
  }

  const [documentDialog, setDocumentDialog] = useState<{
    open: boolean;
    valuation?: Valuation;
  }>({ open: false, valuation: undefined });

  function handleDelete() {
    formik.setFieldValue("valuationReport", "");
    formik.setFieldValue("valuationReportName", "");
    formik.setFieldValue("valuationReportType", "");
  }

  const { mutate: addValuation, isLoading } = useAddValuation();
  const { mutate: editValuation } = useEditValuation();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("name is required"),
    dateOfValuation: Yup.date().required("Date of valuation is required"),
    valuation: Yup.number()
      .min(0.1, "Valuation should be more than zero")
      .required("Valuation is required"),
    valuationMethodology: Yup.string(),
  });

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema,
    onSubmit: () => {
      const valuationDto = { ...formik.values };
      valuationDto.dateOfValuation = formatWithTimeZone(
        valuationDto.dateOfValuation ? valuationDto.dateOfValuation : ""
      );
      if (mode === "Add") {
        addValuation(valuationDto, {
          onSuccess: () => {
            toast("Valuation Added Successfully!", { type: "success" });
            onClose();
          },
          onError: (err: any) => {
            toast(err.response.data.reason, { type: "error", autoClose: 2000 });
          },
        });
      } else {
        editValuation(valuationDto, {
          onSuccess: () => {
            toast("Valuation Edited Successfully!", { type: "success" });
            onClose();
          },
          onError: (err: any) => {
            toast(err.response.data.reason, { type: "error", autoClose: 2000 });
          },
        });
      }
    },
  });
  async function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];

    const acceptedFiles = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    if (!acceptedFiles.includes(file.type)) {
      toast.error("Please upload pdf or docx file");
      return;
    }
    const acceptedExtensions = [".pdf", ".docx"];
    const accept = acceptedExtensions.some((e: string) =>
      file.name.endsWith(e)
    );
    if (!accept) {
      toast.error("Please upload pdf or docx file");
      return;
    }

    formik.setFieldValue("valuationReportName", file?.name);
    const base64 = await convertToBase64(file);
    formik.setFieldValue("valuationReport", base64);
    formik.setFieldValue("isReportOverrided", true);
    if (
      file.type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    )
      formik.setFieldValue("valuationReportType", "docx");
    else if (file.type === "application/pdf") {
      formik.setFieldValue("valuationReportType", "pdf");
    }
  }
  return (
    <>
      <Box className="px-10 text-lg font-medium border-b py-7">
        <h6 className="flex justify-between">
          {mode === "Edit" ? "Edit Valuation" : "Add Valuation"}{" "}
          <span onClick={() => onClose()} className="cursor-pointer">
            <Icon icon="material-symbols:close-rounded" />
          </span>{" "}
        </h6>
      </Box>
      <VStack className="w-full px-10 py-7 gap-9">
        <HStack className="gap-8">
          <div className="flex-1">
            <Label>Name</Label>
            <Input type="text" {...formik.getFieldProps("name")} />
            {formik.touched.name && formik.errors.name && (
              <Error text={formik.errors.name} />
            )}
          </div>
          <div className="flex-1">
            <Label>Date of Valuation</Label>
            <Input type="date" {...formik.getFieldProps("dateOfValuation")} />
            {formik.touched.dateOfValuation &&
              formik.errors.dateOfValuation && (
                <Error text={formik.errors.dateOfValuation} />
              )}
          </div>
          <div className="flex-1">
            <Label>Security Values</Label>
            <Select
              options={["Equity", "Options"]}
              {...formik.getFieldProps("valuationType")}
            />
          </div>
        </HStack>
        <HStack className="gap-8">
          <div className="flex-1">
            <Label>Valuation Methodology</Label>
            <Input
              type="text"
              {...formik.getFieldProps("valuationMethodology")}
            />
            {formik.touched.valuationMethodology &&
              formik.errors.valuationMethodology && (
                <Error text={formik.errors.valuationMethodology} />
              )}
          </div>
          <div className="flex-1">
            <Label>Valuer</Label>
            <Input type="text" {...formik.getFieldProps("valuer")} />
          </div>
          <div className="flex-1">
            <Label>Valuer Rg No.</Label>
            <Input type="string" {...formik.getFieldProps("valuerRgNo")} />
          </div>
        </HStack>
        <HStack className="gap-8">
          <div className="flex-1">
            <Label>Option Value ({currencySymbol})</Label>
            <Input
              type="number"
              {...formik.getFieldProps("valuation")}
              min={0.1}
            />
            {formik.touched.valuation && formik.errors.valuation && (
              <Error text={formik.errors.valuation} />
            )}
          </div>
          <div className="flex-1">
            <Label>Valuation Report</Label>
            <div className="py-1.5">
              {mode === "Edit" &&
              formik.values.valuationReport !== "" &&
              formik.values.valuationReport !== null ? (
                <div className="flex">
                  <FileInput
                    accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    file={"Valuation-Report"}
                    onChange={(e) => {
                      handleFileUpload(e);
                    }}
                  />
                  <button
                    onClick={() => handleDelete()}
                    className="ml-6 text-zinc-300 hover:scale-105"
                  >
                    <DeleteIcon />
                  </button>
                </div>
              ) : (
                <FileInput
                  accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  file={formik.values.valuationReportName || "Attach File"}
                  onChange={(e) => {
                    handleFileUpload(e);
                  }}
                />
              )}
            </div>
          </div>
          <div className="flex-1"></div>
        </HStack>
        <Dialog
          open={documentDialog.open}
          onClose={() => setDocumentDialog({ open: false })}
          maxWidth="lg"
        >
          <LazyPdfDocument
            url={documentDialog?.valuation?.valuationReport || ""}
          />
        </Dialog>
        <HStack className="justify-between">
          <ButtonPrimary1 onClick={() => onClose()}>Cancel</ButtonPrimary1>
          <ButtonPrimary
            type="submit"
            onClick={(e) => {
              formik.handleSubmit();
            }}
          >
            {!isLoading ? (
              "Save"
            ) : (
              <Icon
                className="animate-spin"
                icon="lucide:loader-2"
                width={36}
              />
            )}
          </ButtonPrimary>
        </HStack>
      </VStack>
    </>
  );
}

export default AddOrEditValuation;
