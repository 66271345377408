import { getNames } from "country-list";
import { useFormikContext } from "formik";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import { Box, Error, HStack, VStack } from "../../components/utils";
import { designationForUserDropDownValues } from "../../constants/DropDownValues";
import { UserProfile } from "../../types/UserDetailModel";

export default function UserProfileForm({
  setDialog,
}: {
  setDialog: Function;
}) {
  const formik = useFormikContext<UserProfile>();
  const { errors, touched, getFieldProps } = formik;

  return (
    <div className="w-full bg-white rounded-md drop-shadow-box">
      <Box className="sm:px-10 px-4 py-4 text-lg font-medium text-left border-b">
        <h6>User Details</h6>
      </Box>
      <VStack className="w-full  sm:px-10 px-4 py-4 gap-6">
        <HStack className="sm:gap-8 gap-4 sm:flex-row flex-col">
          <div className="flex-1">
            <Label className="text-sm font-normal">
              User Name
              <span className="text-rose-400">*</span>
            </Label>
            <Input
              type="text"
              placeholder="Enter User Name"
              className=""
              {...getFieldProps("fullName")}
            />
            {touched.fullName && errors.fullName && (
              <Error text={errors.fullName} />
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">
              Email ID
              <span className="text-rose-400">*</span>
            </Label>
            <Input
              type="email"
              placeholder="Enter Email Address"
              className="cursor-not-allowed"
              {...getFieldProps("emailAddress")}
              disabled={true}
            />
            {touched.emailAddress && errors.emailAddress && (
              <Error text={errors.emailAddress} />
            )}
          </div>
        </HStack>

        <HStack className="sm:gap-8 gap-4 sm:flex-row flex-col">
          <div className="flex-1">
            <Label className="text-sm font-normal">
              Company Name
              <span className="text-rose-400">*</span>
            </Label>
            <Input
              type="text"
              placeholder="Enter Your Company Name"
              className=""
              {...getFieldProps("companyName")}
            />
            {touched.companyName && errors.companyName && (
              <Error text={errors.companyName} />
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">
              Designation
              <span className="text-rose-400">*</span>
            </Label>
            <Select
              options={designationForUserDropDownValues}
              {...getFieldProps("designation")}
            />
            {touched.designation && errors.designation && (
              <Error text={errors.designation} />
            )}
          </div>
        </HStack>

        <HStack className="sm:gap-8 gap-4 sm:flex-row flex-col">
          <div className="flex-1">
            <Label className="text-sm font-normal">PAN</Label>
            <Input
              type="text"
              placeholder="Enter PAN"
              {...getFieldProps("pan")}
            />
            {touched.pan && errors.pan && <Error text={errors.pan} />}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">
              Contact Number
              <span className="text-rose-400">*</span>
            </Label>
            <Input
              type="phone"
              placeholder="Enter Phone Number"
              {...getFieldProps("phoneNumber")}
            />
            {touched.phoneNumber && errors.phoneNumber && (
              <Error text={errors.phoneNumber} />
            )}
          </div>
        </HStack>
        <HStack className="sm:gap-8 gap-4 sm:flex-row flex-col">
          <div className="flex-1">
            <Label className="text-sm font-normal">
              Country Of Residence
              <span className="text-rose-400">*</span>
            </Label>
            <Select
              options={getNames().sort()}
              {...getFieldProps("countryOfResidence")}
            />
            {touched.countryOfResidence && errors.countryOfResidence && (
              <Error text={errors.countryOfResidence} />
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">LinkedIn</Label>
            <Input
              type="text"
              placeholder="Enter LinkedIn Profile ID"
              {...getFieldProps("linkedInProfile")}
            />
            {touched.linkedInProfile && errors.linkedInProfile && (
              <Error text={errors.linkedInProfile} />
            )}
          </div>
        </HStack>
        <HStack className="sm:gap-8 gap-4 sm:flex-row flex-col">
          <div className="flex-1">
            <Label className="text-sm font-normal">Nationality</Label>
            <Select
              options={getNames().sort()}
              {...getFieldProps("nationality")}
            />
            {touched.nationality && errors.nationality && (
              <Error text={errors.nationality} />
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Gender</Label>
            <Select
              options={["Male", "Female", "Others"]}
              {...getFieldProps("gender")}
            />
            {touched.gender && errors.gender && <Error text={errors.gender} />}
          </div>
        </HStack>
        <HStack className="mb-2 mt-5">
          <div
            className="text-blue-600 text-lg font-semibold cursor-pointer"
            onClick={() => setDialog({ open: true })}
          >
            Change Password
          </div>
        </HStack>
      </VStack>
    </div>
  );
}
