import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import _ from "lodash";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { Select } from "../../components/shared/Select";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
} from "../../components/utils";
import {
  HireGrades,
  HiringPlans,
  useEsopModellingStore,
} from "../../store/esopModellingStore";
import AddNewPlan from "./AddNewPlan";
import EditHireGrade from "./EditHireGrade";
import {
  useSaveHiringPlan,
  useGetAllHiringPlans,
  useUpdateHiringPlan,
  useGetAllModels,
} from "../../queries/esopModel";
import { HiringPlanReq, EsopModelReq } from "../../types/EsopModelling";

type StepProps = {
  onBack: () => void;
  onNext: () => void;
};

function HiringPlansModule(props: StepProps) {
  const { onBack, onNext } = props;
  const data = useEsopModellingStore();
  const { data: esopModels } = useGetAllModels();
  const esopModelsList = esopModels?.data || [];
  const { data: _hiringPlans } = useGetAllHiringPlans();
  const { mutate: setHiringPlan } = useSaveHiringPlan();
  const { mutate: updateHiringPlan } = useUpdateHiringPlan();
  const hiringPlansList = _hiringPlans?.data || [];

  useEffect(() => {
    hiringPlansList.map((plan: HiringPlanReq) =>
      data.setHiringPlans(plan.hiringPlanDetails)
    );
  }, [hiringPlansList]);

  const planningPeriod = data.selectedCompensationTemplate.planningPeriod;
  const [selectedHiringPlan, setSelectedHiringPlan] = useState<HiringPlans>(
    !_.isEmpty(data.selectedHiringPlan)
      ? data.selectedHiringPlan
      : {
          description: "",
          planName: "",
          planPeriod: 0,
          hireGrades: [],
        }
  );
  const [selectedPlanName, setSelectedPlanName] = useState(
    selectedHiringPlan.planName || ""
  );
  const [dialog, setDialog] = useState<{
    open: boolean;
    index?: number;
    mode?: "Edit" | "Add";
  }>({
    open: false,
    mode: "Add",
  });

  const [hireGradeDialog, setHireGradeDialog] = useState<{
    open: boolean;
    index?: number;
    mode?: "Edit";
  }>({
    open: false,
    mode: "Edit",
  });

  const planData = data.hiringPlans.map((filter) => filter.planName);

  function handleOnChange(plan: string) {
    if (plan === "Add New Plan") {
      setDialog({ ...dialog, open: true, mode: "Add" });
    } else {
      setSelectedPlanName(plan);
      const tempPlan = data.hiringPlans?.filter(
        (hiringPlan) => hiringPlan.planName === plan
      )[0];
      setSelectedHiringPlan(tempPlan);
    }
  }

  function handleEdit(template: string) {
    setDialog({ ...dialog, open: true, mode: "Edit" });
  }

  function handleAction(action: Action, index: number) {
    if (action.name === "Edit") {
      setHireGradeDialog({
        ...hireGradeDialog,
        open: true,
        mode: "Edit",
        index,
      });
    } else if (action.name === "Delete") {
      const tempHireGrades = [...selectedHiringPlan.hireGrades];
      tempHireGrades.splice(index, 1);
      setSelectedHiringPlan({
        description: selectedHiringPlan.description,
        planName: selectedHiringPlan.planName,
        planPeriod: selectedHiringPlan.planPeriod,
        hireGrades: tempHireGrades,
      });
    }
  }

  function handlePrimaryActionAddPlan(hiringPlan: HiringPlans) {
    setSelectedPlanName(hiringPlan.planName);
    setSelectedHiringPlan(hiringPlan);
    data.setHiringPlans(hiringPlan);
  }

  function handlePrimaryActionEditPlan(hiringPlan: HiringPlans) {
    setSelectedPlanName(hiringPlan.planName);
    setSelectedHiringPlan(hiringPlan);
  }

  function handleEditHireGrade(hireGradeItem: HireGrades) {
    const objIndex = selectedHiringPlan.hireGrades.findIndex(
      (item, index) => hireGradeItem.gradeName === item.gradeName
    );

    const hireGradeCopy = selectedHiringPlan.hireGrades.map((item, index) => {
      if (index === objIndex) {
        return {
          ...selectedHiringPlan.hireGrades[objIndex],
          gradeName: hireGradeItem.gradeName,
          totalEmployeesPerGrade: hireGradeItem.totalEmployeesPerGrade,
          year: hireGradeItem.year,
        };
      } else {
        return item;
      }
    });
    const tempHireGradeObj = {
      description: selectedHiringPlan.description,
      planName: selectedHiringPlan.planName,
      planPeriod: planningPeriod,
      hireGrades: hireGradeCopy,
    };
    setSelectedHiringPlan(tempHireGradeObj);
    data.setHiringPlans(selectedHiringPlan);
  }

  function handleSubmit() {
    data.setSelectedHiringPlan(selectedHiringPlan);
    const existingHiringPlan = hiringPlansList.filter(
      (template: HiringPlanReq) =>
        template.planName === selectedHiringPlan.planName
    )[0];

    if (existingHiringPlan) {
      const existingPlanInModel = esopModelsList.filter(
        (model: EsopModelReq) => model.hiringPlanId === existingHiringPlan.id
      )[0];

      if (existingPlanInModel) {
        toast("Hiring Plan already in use. Please change the name", {
          type: "error",
          autoClose: 2000,
        });
      } else {
        existingHiringPlan.hiringPlanDetails = selectedHiringPlan;
        updateHiringPlan(existingHiringPlan, {
          onSuccess: () => {
            toast("Saved Successfully!", {
              type: "success",
              autoClose: 2000,
            });
          },
          onError: (err: any) => {
            toast(err.response.data.reason, {
              type: "error",
              autoClose: 2000,
            });
          },
        });
        onNext();
      }
    } else {
      const hiringPlanReqObj = {
        planName: selectedHiringPlan.planName,
        description: selectedHiringPlan.description,
        hiringPlanDetails: selectedHiringPlan,
      };
      setHiringPlan(hiringPlanReqObj, {
        onSuccess: () => {
          toast("Saved Successfully!", {
            type: "success",
            autoClose: 2000,
          });
        },
        onError: (err: any) => {
          toast(err.response.data.reason, {
            type: "error",
            autoClose: 2000,
          });
        },
      });
      onNext();
    }
  }

  return (
    <Box className="w-78 p-4 bg-white rounded-lg ">
      <VStack className="justify-between gap-9 p-4">
        <VStack className="justify-between gap-2">
          <p className="text-lg font-medium text-gray-dark">Hiring Plans</p>
          <p className="text-s font-medium text-gray-light">
            Plan your hiring needs for different grades of employees to whom you
            wish to grant stock options over the course of the ESOP plan. You
            can create multiple hiring plans under a particular stock option
            plan.
          </p>
        </VStack>
        <HStack className="justify-start">
          <p className={` form-label text-sm font-medium w-1/3 pt-3 `}>
            Hiring Plans
          </p>
          <Box className="w-1/3 flex items-start ml-2">
            <Select
              options={["Add New Plan", ...planData]}
              value={selectedPlanName}
              onChange={(e) => handleOnChange(e.target.value)}
            />
            {selectedPlanName &&
              selectedPlanName !== "" &&
              selectedPlanName !== "Add New Plan" && (
                <button
                  type="button"
                  onClick={(e) => handleEdit(selectedPlanName)}
                >
                  {" "}
                  <Icon
                    icon={"eva:edit-outline"}
                    className="absolute w-6 h-6 text-gray-400 bg-gray-100 rounded-full cursor-pointer m-2 hover:text-black"
                  />
                </button>
              )}
          </Box>
        </HStack>
        <Dialog open={dialog.open} maxWidth="lg">
          {dialog.mode === "Add" && (
            <AddNewPlan
              onPrimaryAction={(hiringPlan: HiringPlans) => {
                handlePrimaryActionAddPlan(hiringPlan);
                setDialog({ ...dialog, open: false });
              }}
              onClose={() => {
                setDialog({ ...dialog, open: false });
              }}
              mode="Add"
              planningPeriod={planningPeriod}
            />
          )}
          {dialog.mode === "Edit" && (
            <AddNewPlan
              onPrimaryAction={(hiringPlan: HiringPlans) => {
                handlePrimaryActionEditPlan(hiringPlan);
                setDialog({ ...dialog, open: false });
              }}
              onClose={() => {
                setDialog({ ...dialog, open: false });
              }}
              mode="Edit"
              planningPeriod={data.selectedCompensationTemplate.planningPeriod}
              selectedHiringPlan={selectedHiringPlan}
            />
          )}
        </Dialog>
        {selectedHiringPlan.hireGrades.length > 0 &&
          selectedHiringPlan.planName !== "" && (
            <HStack className="justify-between">
              <Box className="max-h-full overflow-auto h-[300px]">
                <table className="w-[600px]  table-space">
                  <thead className="text-xs font-medium text-gray-light">
                    <tr className="border-b border-dashed ">
                      <td className="py-3 hover:cursor-pointer">Grade</td>
                      {Array(planningPeriod)
                        .fill(0)
                        .map((_, i) => (
                          <td className="py-3 hover:cursor-pointer" key={i}>
                            Year {i + 1}
                          </td>
                        ))}
                      <td className="py-3 hover:cursor-pointer"></td>
                    </tr>
                  </thead>
                  <tbody className="font-medium text-dark">
                    {selectedHiringPlan.hireGrades?.map((hireGrade, index) => (
                      <tr
                        key={`${hireGrade.gradeName}-${index}`}
                        className=" border-b border-dashed cursor-pointer hover:bg-slate-50"
                      >
                        <td className="p-2">{hireGrade.gradeName}</td>
                        {Array(planningPeriod)
                          .fill(0)
                          .map((_, yearIndex) => (
                            <td className="p-2" key={`year-${yearIndex}`}>
                              {hireGrade.year[yearIndex]}
                            </td>
                          ))}
                        <td className="px-2 py-4 align-top">
                          <CTADropdown
                            actions={[{ name: "Edit" }, { name: "Delete" }]}
                            onAction={(action) => handleAction(action, index)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
            </HStack>
          )}
        <Dialog open={hireGradeDialog.open} maxWidth="lg">
          {hireGradeDialog.mode === "Edit" && (
            <EditHireGrade
              onPrimaryAction={(hireGradeItem: HireGrades) => {
                handleEditHireGrade(hireGradeItem);
                setHireGradeDialog({ ...hireGradeDialog, open: false });
              }}
              onClose={() => {
                setHireGradeDialog({ ...hireGradeDialog, open: false });
              }}
              data={selectedHiringPlan.hireGrades[hireGradeDialog.index || 0]}
              planningPeriod={planningPeriod}
            />
          )}
        </Dialog>
        <HStack className="justify-between pt-12">
          <ButtonPrimary1
            type="reset"
            className="text-red-500 mr-8"
            onClick={() => {
              onBack();
            }}
          >
            Back
          </ButtonPrimary1>
          <ButtonPrimary
            type="submit"
            onClick={() => {
              handleSubmit();
            }}
          >
            Next
          </ButtonPrimary>
        </HStack>
      </VStack>
    </Box>
  );
}

export default HiringPlansModule;
