import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import BCHeader from "../../shared/BCHeader";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  redirectToHissa,
  VStack,
} from "../../components/utils";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import {
  useAddAdminProfileData,
  useGetImageFile,
  useOnBoardingUploadAdminDetail,
} from "../../queries/onBoarding";
import { AdminProfileDataModel } from "../../types/OnBoardDataModel";

export interface FileTypeModel {
  fileBlob: string;
  fileName?: string;
  fileType?: string;
}

function AdminProfilePage(): React.ReactElement {
  const [imageURL, setImageURL] = useState<FileTypeModel>();
  const [authenticatorFile, setAuthenticatorFile] = useState<FileTypeModel>();
  const { data: _updateInvestorDetail } = useOnBoardingUploadAdminDetail();
  const { mutate: saveAdminProfile } = useAddAdminProfileData();

  const updateInvestorDetail = _updateInvestorDetail;
  const { refetch, data: _getImage } = useGetImageFile(
    updateInvestorDetail?.profilePictureId || ""
  );
  const { refetch: pdfRefectch, data: _getPdf } = useGetImageFile(
    updateInvestorDetail?.authenticatorFileId || ""
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (
      _updateInvestorDetail?.profilePictureId ||
      _updateInvestorDetail?.authenticatorType ||
      _updateInvestorDetail?.designation ||
      _updateInvestorDetail?.authenticatorFileId
    ) {
      navigate("/onboarding/confirmation");
    }
  }, [_updateInvestorDetail, imageURL, authenticatorFile]);

  const validationSchema = Yup.object().shape({
    designation: Yup.string().required("Designation is required"),
    authenticator: Yup.string().required("Authenticator Type is required"),
  });

  const getImages = () => {
    if (_updateInvestorDetail) {
      refetch();
      pdfRefectch();
      setImageURL({
        fileBlob: _getImage || "",
      });
      setAuthenticatorFile({
        fileBlob: _getPdf || "",
      });
    }
  };

  const getInitialValues = () => ({
    profileImageUrl: "",
    designation: _updateInvestorDetail?.designation || "",
    authenticator: _updateInvestorDetail?.authenticatorType || "",
    authenticatorImageUrl: "",
  });

  const handleSubmit = (values: AdminProfileDataModel) => {
    const formData = new FormData();
    formData.append("designation", values.designation);
    formData.append("authenticatorType", values.authenticator);
    if (imageURL)
      formData.append(
        "profilePicture",
        new File([imageURL?.fileBlob], imageURL?.fileName || "profile.jpeg", {
          type: imageURL?.fileType || "image/jpeg",
        })
      );

    if (authenticatorFile)
      formData.append(
        "authenticator",
        new File(
          [authenticatorFile.fileBlob],
          authenticatorFile.fileName || "authenticatorFile.pdf",
          {
            type: authenticatorFile.fileType || "application/pdf",
          }
        )
      );

    saveAdminProfile(formData, {
      onSuccess: (res) => {
        if (
          res.profilePictureId ||
          res.authenticatorFileId ||
          res.designation ||
          res.authenticatorType
        ) {
          navigate("/onboarding/confirmation");
        }
      },
      onError: () => {
        toast("Something Went Wrong!", { type: "error" });
      },
    });
  };

  const isValidPdfFileUploaded = (file: File) => {
    const validExtensions = ["pdf"];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };
  const isValidImageFileUploaded = (file: File) => {
    const validExtensions = ["png", "jpeg", "jpg"];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };

  const handleEditAuthenticatorImage = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e?.target?.files?.[0]) {
      const file = e.target.files[0];
      if (isValidPdfFileUploaded(file)) {
        setAuthenticatorFile({
          fileBlob: URL.createObjectURL(file),
          fileName: file.name,
          fileType: "application/pdf",
        });
      } else {
        toast("File type not supported", { type: "error" });
      }
    }
  };

  const handleEditProfileImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.[0]) {
      const file = e.target.files[0];
      if (isValidImageFileUploaded(file)) {
        setImageURL({
          fileBlob: URL.createObjectURL(file),
          fileName: file.name,
          fileType: `image/${file.type.split("/")[1]}`,
        });
      } else {
        toast("File type not supported", { type: "error" });
      }
    }
  };

  return (
    <>
      {updateInvestorDetail && (
        <div className="w-11/12 m-auto min-h-screen">
          <div className="px-0 text-lg font-medium py-7">
            <h1>Onboarding</h1>
          </div>
          <BCHeader
            className="items-baseline mb-4"
            bcTitle="OnBoarding"
            bcSubTitle="Admin Profile"
          />
          <div className="w-150 container m-auto bg-white rounded-md">
            <Box className="px-10 text-lg text-center font-medium border-b py-7">
              <h6>Admin Profile Details</h6>
            </Box>
            <VStack className="w-full px-10 py-7 gap-9">
              <Formik
                enableReinitialize={true}
                initialValues={getInitialValues()}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
                validationSchema={validationSchema}
              >
                {(formik) => (
                  <>
                    <Form>
                      <VStack className="w-full gap-10 justify-between">
                        <HStack className="justify-between">
                          <div className="flex flex-row justify-between flex-1">
                            <Box>
                              <Label className="text-sm font-normal">
                                Profile Photo
                              </Label>
                            </Box>

                            <Box>
                              {!imageURL ? (
                                <label className="px-2 py-2 rounded-lg cursor-pointer bg-slate-100">
                                  <Input
                                    type="file"
                                    className="hidden"
                                    accept="image/png, image/jpeg"
                                    onChange={handleEditProfileImage}
                                  />
                                  Upload Image
                                </label>
                              ) : (
                                <div className="relative flex flex-col justify-between">
                                  <Label>
                                    <Input
                                      type="file"
                                      accept="image/png, image/jpg, image/jpeg"
                                      className="hidden"
                                      onInput={handleEditProfileImage}
                                      {...formik.getFieldProps(
                                        "profileImageUrl"
                                      )}
                                    />
                                    <Icon
                                      icon={"eva:edit-outline"}
                                      className="absolute z-10 w-6 h-6 text-gray-400 bg-gray-100 rounded-full cursor-pointer -right-2 hover:text-black"
                                    />
                                  </Label>
                                  <img
                                    className="right-0 block w-32 h-32 space-y-3 bg-gray-400 border-2 rounded-lg"
                                    src={imageURL?.fileBlob}
                                    alt="avatar"
                                  />
                                  <Label>
                                    <Icon
                                      icon={"eva:close-fill"}
                                      onClick={() =>
                                        setImageURL({
                                          fileBlob: "",
                                          fileName: "",
                                          fileType: "",
                                        })
                                      }
                                      className="absolute z-10 w-6 h-6 text-gray-400 bg-gray-100 rounded-full cursor-pointer -right-2 -bottom-2 hover:text-black"
                                    />
                                  </Label>
                                </div>
                              )}
                            </Box>
                          </div>
                        </HStack>
                        <div className="flex-1">
                          <Label className="text-sm font-normal">
                            Designation
                            <span className="text-rose-400">*</span>
                          </Label>
                          <Select
                            className="w-full"
                            options={
                              updateInvestorDetail.designationDropDownValues
                            }
                            {...formik.getFieldProps("designation")}
                          />
                          {formik.touched.designation &&
                            formik.errors.designation && (
                              <Error text={formik.errors.designation} />
                            )}
                        </div>
                        <div className="flex-1">
                          <Label className="text-sm font-normal">
                            Authenticator
                            <span className="text-rose-400">*</span>
                          </Label>
                          <Select
                            className="w-full"
                            options={
                              updateInvestorDetail.authenticatorDropDownValues
                            }
                            {...formik.getFieldProps("authenticator")}
                          />
                          {formik.touched.authenticator &&
                            formik.errors.authenticator && (
                              <Error text={formik.errors.authenticator} />
                            )}
                        </div>
                        <div className="flex justify-between">
                          <Box>
                            <Label className="text-sm font-normal">
                              Upload Authenticator
                            </Label>
                          </Box>
                          <Box>
                            {!authenticatorFile ? (
                              <label className="px-2 py-2 rounded-lg cursor-pointer bg-slate-100">
                                <Input
                                  type="file"
                                  className="hidden"
                                  accept="application/pdf"
                                  onInput={handleEditAuthenticatorImage}
                                  {...formik.getFieldProps(
                                    "authenticatorImageUrl"
                                  )}
                                />
                                Upload PDF
                              </label>
                            ) : (
                              <div className="relative flex flex-row justify-between">
                                <Label>
                                  <Input
                                    type="file"
                                    className="hidden"
                                    accept="application/pdf"
                                    onInput={handleEditAuthenticatorImage}
                                    {...formik.getFieldProps(
                                      "authenticatorImageUrl"
                                    )}
                                  />
                                  <Icon
                                    icon={"eva:edit-outline"}
                                    className="absolute z-10 w-6 h-6 text-gray-400 bg-gray-100 rounded-full cursor-pointer -right-2 hover:text-black"
                                  />
                                </Label>
                                <p className="px-8">
                                  {authenticatorFile?.fileName}
                                </p>
                              </div>
                            )}
                          </Box>
                        </div>

                        <HStack className="justify-end">
                          <ButtonPrimary1
                            type="reset"
                            className="text-red-500 mr-8"
                            onClick={() => {
                              redirectToHissa("landing/dashboard/capTable");
                            }}
                          >
                            Go to Dashboard
                          </ButtonPrimary1>
                          <ButtonPrimary type="submit">Continue</ButtonPrimary>
                        </HStack>
                      </VStack>
                    </Form>
                  </>
                )}
              </Formik>
            </VStack>
          </div>
        </div>
      )}
    </>
  );
}

export default AdminProfilePage;
