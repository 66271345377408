import React, { useEffect, useState } from "react";
import { Form, Formik, useFormikContext, useFormik } from "formik";
import * as Yup from "yup";
import { Input, Label } from "../../components/shared/InputField";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
} from "../../components/utils";

type EditSharePriceMultiplierProps = {
  onPrimaryAction: (
    sharePriceMultipliers: number[],
    sharePricePerYear: number[]
  ) => void;
  onClose: () => void;
  sharePriceMultipliers: number[];
  sharePricePerYear: number[];
  planningPeriod: number;
  currentSharePrice: number;
};

type EditSharePriceMultiplierSettings = {
  sharePriceMultipliers: number[];
  sharePricePerYear: number[];
};

function EditSharePriceMultiplier({
  onClose = () => {},
  onPrimaryAction = () => {},
  sharePriceMultipliers,
  sharePricePerYear,
  planningPeriod,
  currentSharePrice,
}: EditSharePriceMultiplierProps) {
  const planningPeriodArr = Array(planningPeriod).fill(0);
  const initialShareMultiplierValues: EditSharePriceMultiplierSettings = {
    sharePriceMultipliers: sharePriceMultipliers || [
      1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    ],
    sharePricePerYear,
  };

  const validationSchema = Yup.object().shape({
    sharePriceMultipliers: Yup.array().of(
      Yup.number().min(0).required("Share Multiplier is required")
    ),
  });

  const handleSubmit = (values: EditSharePriceMultiplierSettings) => {
    onPrimaryAction(values.sharePriceMultipliers, values.sharePricePerYear);
  };

  const formik = useFormik({
    initialValues: initialShareMultiplierValues,
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  useEffect(() => {
    const sharePricePerYear: number[] = [];
    planningPeriodArr.forEach((_, index) => {
      if (index === 0) {
        sharePricePerYear[index] =
          currentSharePrice * formik.values.sharePriceMultipliers[index];
      } else {
        sharePricePerYear[index] =
          sharePricePerYear[index - 1] *
          formik.values.sharePriceMultipliers[index];
      }
    });

    formik.setFieldValue("sharePricePerYear", sharePricePerYear);
  }, [formik.values.sharePriceMultipliers]);

  return (
    <VStack className="w-full gap-9 p-10">
      <Box className="text-lg font-medium border-b">
        <h6 className="justify-between flex">
          Edit
          <span onClick={() => onClose()} className="cursor-pointer">
            X
          </span>{" "}
        </h6>
      </Box>
      <VStack className="gap-8 ">
        {planningPeriodArr?.map((_, index) => (
          <HStack className="flex-1 gap-8" key={`year-${index}`}>
            <p className="text-sm font-normal pt-10">Year {index + 1}</p>
            <VStack>
              <Label className="text-sm font-normal">
                Share Price Multiplier
              </Label>
              <Input
                type="number"
                className="w-24"
                placeholder="Year 1"
                {...formik.getFieldProps(`sharePriceMultipliers[${index}]`)}
              />
              {formik.touched.sharePriceMultipliers
                ? [index] && formik.errors.sharePriceMultipliers
                  ? [index] && (
                      <Error
                        text={formik.errors.sharePriceMultipliers[index]}
                      />
                    )
                  : null
                : null}
            </VStack>
            <VStack>
              <Label className="text-sm font-normal">Price Per Share</Label>
              <Input
                type="number"
                disabled
                className="w-24"
                placeholder="Year 1"
                {...formik.getFieldProps(`sharePricePerYear[${index}]`)}
              />
            </VStack>
          </HStack>
        ))}
      </VStack>
      <HStack className="justify-between mt-12">
        <ButtonPrimary1
          type="reset"
          className="text-red-500 mr-8"
          onClick={() => {
            onClose();
          }}
        >
          Close
        </ButtonPrimary1>
        <ButtonPrimary
          type="submit"
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Create
        </ButtonPrimary>
      </HStack>
    </VStack>
  );
}

export default EditSharePriceMultiplier;
