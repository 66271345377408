import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Box } from "../../components/utils";
import { useGetOptionBuyback } from "../../queries/optionBuyback";
import BCHeader from "../../shared/BCHeader";
import BuybackDetails from "./Buybackdetails";
import BuybackEmployeeDetails from "./BuybackEmployeeDetails";
import BuybackEmployeeList from "./BuybackOffersList";
import ExerciseDetails from "./ExerciseDetails";
import UploadExcel from "./UploadExcel";
import useIsMobile from "../../utils/detectDevice";

function CompleteBuyback() {
  const { id } = useParams();
  const _id = id || "";
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { data: buybackOverview, refetch, isFetching } = useGetOptionBuyback();
  const optionBuybackOverview = buybackOverview || [];
  const buyback = optionBuybackOverview?.filter(
    (item) => parseInt((item?.id || 0).toString(), 10) === parseInt(_id, 10)
  )[0];
  useEffect(() => {
    refetch();
    // eslint-disable-next-line no-useless-return
    if (isFetching) return;
    else {
      const isValidBuyBack = optionBuybackOverview.filter(
        (optionBuyBack) =>
          parseInt((optionBuyBack.id || 0).toString(), 10) === parseInt(_id, 10)
      );
      if (isValidBuyBack.length === 0) {
        toast(`You don't have permission to access ${_id} buyback`, {
          type: "error",
          autoClose: 3000,
        });
        navigate("/options/esopTransactions");
      }
    }
  }, [optionBuybackOverview, _id]);
  const { isMobile } = useIsMobile();
  return (
    <div className="space-y-4">
      <BCHeader bcTitle="ESOP Transaction" bcSubTitle={buyback?.name} />
      <Box
        className={`${
          isMobile ? "w-full" : "w-3/5"
        } px-5 py-5 border border-green-400 border-dashed bg-green-50`}
      >
        <p className="text-sm font-medium text-black">
          {`${
            buyback?.transactionType !== "buyback"
              ? "Liquidity programme/program"
              : "Options"
          }  ${
            buyback?.transactionType === "buyback" ? "Buy Back" : ""
          } ${buyback?.state.toLowerCase()} successfully`}
        </p>
      </Box>
      <div className="flex flex-col gap-4 ">
        {(buyback?.transactionType === "exercise" ||
          buyback?.transactionType === "exercise and liquidate") && (
          <ExerciseDetails />
        )}
        {buyback?.transactionType === "buyback" && <BuybackDetails />}
        <BuybackEmployeeDetails />
        <BuybackEmployeeList />
      </div>
    </div>
  );
}

export default CompleteBuyback;
