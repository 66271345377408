import React, { useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import * as _ from "lodash";
import { Box, HStack, VStack } from "../../components/utils";
import { globalFilter, sort } from "../../utils/arrays";
import {
  CapTable,
  CapTableTransactionModel,
  HistoricOverviewCapTableModel,
} from "../../types/HistoricOverView";
import { Select } from "../../components/shared/Select";
import Avatar from "../esopOverview/Avatar";
import { downloadBlobObject } from "../../utils/DownloadFile";
import {
  useGetReportBasedOnRound,
  useGetReportBasedOnSecurity,
  useGetReportBasedOnShareHolder,
} from "../../queries/onBoarding";
import { getCurrencyType } from "../../utils/currencyFormatter";

export default function HistoricOverviewCapTable({
  capTableTransaction,
}: HistoricOverviewCapTableModel) {
  const [globalFilterText, setGlobalFilterText] = useState("");
  const [sortField, setSortField] = useState<{
    field: keyof CapTableTransactionModel | "" | undefined;
    ascending: boolean;
  }>({ field: "holdingPercentage", ascending: false });
  const capTableTransactionLength = capTableTransaction?.length;
  let totalCaptable: CapTableTransactionModel = {
    ...capTableTransaction[capTableTransactionLength - 1],
    investorType: "",
    roundName: [],
  };
  const currency = getCurrencyType();
  const captableData: CapTableTransactionModel[] = [];
  const investorTypeList = ["ALL"];

  capTableTransaction?.forEach((transaction) => {
    if (transaction.name !== "Total") {
      investorTypeList.push(transaction.name);
      transaction.shareholders.forEach((shareHolder) => {
        const roundNames = transaction.roundDetails.map(
          (round) => round.roundName
        );
        captableData.push({
          ...shareHolder,
          investorType: "",
          roundName: roundNames,
        });
      });
    } else {
      totalCaptable = {
        ...transaction,
        investorType: transaction.name,
        roundName: [],
      };
    }
  });

  let capTableList = captableData || [];
  const capTableLength = capTableList.length;

  capTableList = useMemo(() => {
    const filterResult = globalFilter(capTableList, globalFilterText, [
      "name",
      "holdingPercentage",
      "roundName",
      "investorType",
      "preferenceShares",
      "preferenceSharesFDB",
      "amountInvested",
      "commonShares",
      "totalDilutedShares",
    ]);
    const sortResult = sort(
      filterResult,
      sortField?.field,
      sortField?.ascending
    );
    return sortResult;
  }, [capTableList, globalFilterText, sortField]);

  const changeTheInvestorType = (investorTypeName: string) => {
    setGlobalFilterText(investorTypeName === "ALL" ? "" : investorTypeName);
  };

  const onSelectionOfReportType = (reportTypeName: string) => {
    if (reportTypeName === "Export Round Based Report") {
      fetchRoundBasedReport();
    } else if (reportTypeName === "Export Securities Based Report") {
      fetchSecurityBasedReport();
    } else {
      fetchShareHolderBasedReport();
    }
  };

  async function downloadReport(report: Blob) {
    const binaryData = [];
    binaryData.push(report);
    downloadBlobObject(new Blob(binaryData), "security");
  }

  const { refetch: fetchRoundBasedReport } = useGetReportBasedOnRound(
    (data: Blob) => downloadReport(data)
  );
  const { refetch: fetchSecurityBasedReport } = useGetReportBasedOnSecurity(
    (data: Blob) => downloadReport(data)
  );
  const { refetch: fetchShareHolderBasedReport } =
    useGetReportBasedOnShareHolder((data: Blob) => downloadReport(data));

  return (
    <Box className="bg-white p-8 mt-8">
      <HStack aria-label="toolbar" className="justify-between mb-8">
        <VStack className="min-w-max">
          <p className="text-lg font-medium text-gray-dark">Cap Table</p>
          <p className="text-xs font-medium text-gray-light">
            {captableData.length} Results
          </p>
        </VStack>

        <HStack className="h-11 min-w-min">
          <Select
            options={investorTypeList}
            value={globalFilterText}
            onChange={(e) => changeTheInvestorType(e.target.value)}
          />
          <HStack className="items-center p-2 rounded-lg w-72 bg-slate-50 text-slate-dark mx-2">
            <Icon icon="fe:search" width="24" className="mr-2 " />
            <input
              type="text"
              className="w-full font-medium border-0 outline-none text-xs2 bg-inherit"
              placeholder="Search"
              value={globalFilterText}
              onChange={(e) => {
                setGlobalFilterText(e.target.value);
              }}
            ></input>
          </HStack>
          <Select
            options={[
              "Export Round Based Report",
              "Export Securities Based Report",
              "Export Summary Report",
            ]}
            value={""}
            onChange={(e) => onSelectionOfReportType(e.target.value)}
          />
        </HStack>
      </HStack>
      <Box className="overflow-auto max-h-full">
        <table className="w-full table-space">
          <thead className="text-xs font-medium text-gray-light">
            <tr>
              <td className="py-3 pr-4"></td>
              <td
                className="py-3 w-128 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "name",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Shareholder
              </td>
              <td
                className="py-3 w-72 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "roundName",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Rounds
              </td>
              <td
                className="py-3 pl-4 w-32 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "holdingPercentage",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Holding%(FDB)
              </td>
              <td
                className="py-3 w-72 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "totalDilutedShares",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Total No. of Shares(FDB)
              </td>
              <td
                className="py-3 w-72 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "preferenceSharesFDB",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Preference Shares(FDB)
              </td>
              <td
                className="py-3 w-72 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "preferenceShares",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Preference Shares(Paid up)
              </td>
              <td
                className="py-3 w-72 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "commonShares",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Equity(Paid up)
              </td>
              <td
                className="py-3 w-72 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "amountInvested",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Amount Invested
              </td>
            </tr>
          </thead>
          <tbody className={""}>
            <>
              {capTableList &&
                capTableList?.map((capTableTransaction, index) => (
                  <>
                    {capTableTransaction.name.toLowerCase() !== "total" && (
                      <tr
                        key={capTableTransaction.name}
                        className="border-t border-dashed"
                      >
                        <td className="pr-4">
                          <Avatar name={capTableTransaction.name} />
                        </td>
                        <td className="py-4 w-128 align-top">
                          <HStack>
                            <Box>
                              <p
                                className={` text-xs2 font-semibold text-gray-600 `}
                              >
                                {capTableTransaction.name}
                              </p>
                              <p
                                className={` text-xxs1 font-medium text-gray-400 `}
                              >
                                {capTableTransaction.investorType}
                              </p>
                            </Box>
                          </HStack>
                        </td>

                        <td className="pt-4 align-top overflow-x-scroll scrollbar-hide">
                          <HStack className="w-72">
                            {capTableTransaction.roundName?.map((roundName) => (
                              <p
                                key={roundName}
                                className={`whitespace-nowrap text-xs2 font-medium text-gray-dark bg-slate-100 rounded-lg p-1 mx-2`}
                              >
                                {roundName}
                              </p>
                            ))}
                          </HStack>
                        </td>
                        <td className="py-4 pl-4 w-32 align-top ">
                          <HStack className="w-32 ">
                            <Box>
                              <p
                                className={` text-xs2 font-medium text-black `}
                              >
                                {(
                                  capTableTransaction.holdingPercentage * 100
                                ).toFixed(2)}
                              </p>
                            </Box>
                          </HStack>
                        </td>
                        <td className="py-4 w-72 align-top ">
                          <HStack>
                            <Box>
                              <p
                                className={` text-xs2 font-medium text-black `}
                              >
                                {capTableTransaction.totalDilutedShares.toLocaleString(
                                  currency
                                )}
                              </p>
                            </Box>
                          </HStack>
                        </td>
                        <td className="py-4 w-72 align-top ">
                          <HStack>
                            <Box>
                              <p
                                className={` text-xs2 font-medium text-black `}
                              >
                                {capTableTransaction.preferenceSharesFDB.toLocaleString(
                                  currency
                                )}
                              </p>
                            </Box>
                          </HStack>
                        </td>
                        <td className="py-4 w-72 align-top ">
                          <HStack>
                            <Box>
                              <p
                                className={` text-xs2 font-medium text-black `}
                              >
                                {capTableTransaction.preferenceShares.toLocaleString(
                                  currency
                                )}
                              </p>
                            </Box>
                          </HStack>
                        </td>
                        <td className="py-4 w-72 align-top ">
                          <HStack>
                            <Box>
                              <p
                                className={` text-xs2 font-medium text-black `}
                              >
                                {capTableTransaction.commonShares.toLocaleString(
                                  currency
                                )}
                              </p>
                            </Box>
                          </HStack>
                        </td>
                        <td className="py-4 w-72 align-top ">
                          <HStack>
                            <Box>
                              <p
                                className={` text-xs2 font-medium text-black `}
                              >
                                {capTableTransaction.amountInvested.toLocaleString(
                                  currency
                                )}
                              </p>
                            </Box>
                          </HStack>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              {capTableList.length > 2 && (
                <tr key={"total"} className="border-t border-dashed">
                  <td className="py-3 pr-4"></td>
                  <td className="py-4 w-128 align-top ">
                    <HStack>
                      <Box>
                        <p className={` text-xs2  font-semibold text-black `}>
                          {totalCaptable.name}
                        </p>
                      </Box>
                    </HStack>
                  </td>

                  <td className="py-4 w-72 align-top ">
                    <HStack>
                      <Box>
                        <p className={` text-xs2 font-semibold text-black`}></p>
                      </Box>
                    </HStack>
                  </td>
                  <td className="py-4 pl-4 w-32 align-top ">
                    <HStack className="w-32 ">
                      <Box>
                        <p className={` text-xs2 font-semibold text-black `}>
                          {(totalCaptable.holdingPercentage * 100).toFixed(2)}
                        </p>
                      </Box>
                    </HStack>
                  </td>
                  <td className="py-4 w-72 align-top ">
                    <HStack>
                      <Box>
                        <p className={` text-xs2 font-semibold text-black `}>
                          {totalCaptable.totalDilutedShares.toLocaleString(
                            currency
                          )}
                        </p>
                      </Box>
                    </HStack>
                  </td>
                  <td className="py-4 w-72 align-top ">
                    <HStack>
                      <Box>
                        <p className={` text-xs2 font-semibold text-black `}>
                          {totalCaptable.preferenceSharesFDB.toLocaleString(
                            currency
                          )}
                        </p>
                      </Box>
                    </HStack>
                  </td>
                  <td className="py-4 w-72 align-top ">
                    <HStack>
                      <Box>
                        <p className={` text-xs2 font-semibold text-black `}>
                          {totalCaptable.preferenceShares.toLocaleString(
                            currency
                          )}
                        </p>
                      </Box>
                    </HStack>
                  </td>
                  <td className="py-4 w-72 align-top ">
                    <HStack>
                      <Box>
                        <p className={` text-xs2 font-semibold text-black `}>
                          {totalCaptable.commonShares.toLocaleString(currency)}
                        </p>
                      </Box>
                    </HStack>
                  </td>
                  <td className="py-4 w-72 align-top ">
                    <HStack>
                      <Box>
                        <p className={` text-xs2 font-semibold text-black `}>
                          {totalCaptable.amountInvested.toLocaleString(
                            currency
                          )}
                        </p>
                      </Box>
                    </HStack>
                  </td>
                </tr>
              )}
            </>
          </tbody>
        </table>
        {capTableList.length === 0 && (
          <div className="text-black text-center">
            <h1>No Cap Table Found</h1>
          </div>
        )}
      </Box>
    </Box>
  );
}
