import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { Box, HStack, VStack } from "../../components/utils";
import SideNavbar, { CompanyLogo } from "../SideNavbar";
import Profile from "../../components/shared/ProfileComponent";

function HeaderNavbarMobile() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => {
    setSidebar(!sidebar);
  };
  return (
    <VStack>
      <HStack className="items-center justify-between h-10 mx-2 ">
        <Box className="flex flex-row items-center justify-start">
          {!sidebar ? (
            <Icon
              icon="icon-park-outline:hamburger-button"
              width={28}
              height={28}
              className="cursor-pointer text-gray-dark"
              onClick={() => showSidebar()}
            />
          ) : (
            <Icon
              icon={"material-symbols:close-rounded"}
              width={28}
              height={28}
              className="cursor-pointer text-gray-dark"
              onClick={() => showSidebar()}
            ></Icon>
          )}
          <CompanyLogo />
        </Box>
        <Profile />
      </HStack>
      {sidebar && (
        <VStack>
          <HStack
            className={`z-20 overflow-y-auto flex top-14 h-screen justify-center fixed transition delay-300 ${
              sidebar ? "0" : "-100%"
            }`}
          >
            <SideNavbar />
          </HStack>
        </VStack>
      )}
    </VStack>
  );
}

export default HeaderNavbarMobile;
