import { QueryFunctionContext } from "react-query";
import { OptionSplit, OptionSplitCreateReq } from "../types/OptionSplit";
import hissaApi from "./hissaApi";

export async function getOptionSplits(
  context: QueryFunctionContext
): Promise<OptionSplit[]> {
  return hissaApi.get(`esop/optionSplit`).then((res) => res.data);
}

export async function createOptionSplit(
  optionSplit: OptionSplitCreateReq
): Promise<OptionSplit> {
  return hissaApi
    .post(`esop/optionSplit/create`, optionSplit)
    .then((res) => res.data);
}

export async function completeOptionSplit(
  splitId: number
): Promise<OptionSplit> {
  return hissaApi
    .post(`esop/optionSplit/complete?splitId=${splitId}`)
    .then((res) => res.data);
}
