import { Icon } from "@iconify/react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import React from "react";
import { Box, Center, HStack, VStack } from "../components/utils";
import { _sum } from "../utils/arrays";
import { useEsopPlans } from "../queries";
import { EsopPlan } from "../types/EsopPlan";
import { SemiDoughnutGraph } from "./SemiDoughnutChart";
import { optionsPool } from "./chart-data";
import { getCurrencyType } from "../utils/currencyFormatter";

function CardPool() {
  const { id } = useParams();
  const currency = getCurrencyType();
  const _id = id || "";
  const { data, isPlaceholderData } = useEsopPlans();
  const header = "Options Pool";
  const esopPlans = data || [];
  let plans: EsopPlan[] = [];
  if (_id === "") {
    plans = esopPlans.filter((plan) => plan.planName !== "Total");
  } else {
    plans = esopPlans.filter((plan) => plan.esopPlanId?.toString() === _id);
  }
  const totalOptions = plans.reduce(
    (total, plan) =>
      total + (plan.conversionNumber || 1) * (plan.totalShares || 0),
    0
  );
  const message = `${totalOptions.toLocaleString(currency)} Options`;
  const issuedShares = totalOptions - _sum(plans, "optionsReserved");
  const availableOptions = _sum(plans, "optionsReserved");
  const percent = (100 * issuedShares) / (totalOptions || 1);
  const rows = [
    {
      title: issuedShares.toLocaleString(currency),
      subtitle: "Options Granted",
    },
    {
      title: availableOptions.toLocaleString(currency),
      subtitle: "Options available",
    },
    {
      title: plans.length,
      subtitle: "Plans",
    },
  ];
  const optionsData = {
    labels: ["Granted", "Availaible Options"],
    datasets: [
      {
        label: "# of Votes",
        data: [issuedShares, availableOptions],
        backgroundColor: ["rgba(255,168,0,1)", "rgba(255,168,0,.15"],
        borderColor: ["rgba(255,168,0,1)", "rgba(255,168,0,.15"],
      },
    ],
  };
  const navigate = useNavigate();
  return (
    <VStack
      className={`p-6 bg-white rounded-lg max-h-96 cursor-pointer ${
        isPlaceholderData ? "loading" : ""
      }`}
      onClick={() => {
        navigate("/options/add-edit-pool");
      }}
    >
      <VStack className="mb-0">
        <p className="font-medium text-gray-dark">{header}</p>
        <p className="text-xs1 font-semi-medium text-gray-light">{message}</p>
      </VStack>
      <Box className=" align-center">
        {percent ? (
          <SemiDoughnutGraph options={optionsPool} data={optionsData} />
        ) : (
          <p className="h-10 w-96">.</p>
        )}
      </Box>
      {rows.map((row) => (
        <HStack
          key={row.subtitle}
          className="justify-between p-2 overflow-auto"
        >
          <HStack>
            <Center
              className="items-center w-6 h-6 p-1"
              style={{ backgroundColor: "#F3F6F9", borderRadius: "50%" }}
            >
              <Icon icon="mdi:octahedron" style={{ color: "gray" }} />
            </Center>
            <VStack className="pl-4">
              <p className="font-medium capitalize text-xs2 text-gray-dark">
                {row.title}
              </p>
              <p className="capitalize text-xs1 font-semi-medium text-gray-light">
                {row.subtitle}
              </p>
            </VStack>
          </HStack>
        </HStack>
      ))}
    </VStack>
  );
}
export default CardPool;
