import React from "react";
import { useFormikContext } from "formik";
import { Icon } from "@iconify/react";
import { SiteSettingsRes } from "../../types/SiteSettings";
import { Box, HStack, VStack } from "../../components/utils";
import { SwitchButton } from "../../components/shared/SwitchButton";

const EmailToggles = () => {
  const formik = useFormikContext<SiteSettingsRes>();

  return (
    <VStack className="justify-between p-4">
      <HStack className="justify-between">
        <p className={` form-label text-sm font-medium w-2/3 `}>
          <b>Allow Hissa to send notification Emails</b>
        </p>
        <Box className="flex justify-end pr-20 w-1/3 ml-2">
          <SwitchButton
            onClick={() => {
              formik.setFieldValue(
                "emailsToBeSent",
                !formik.values.emailsToBeSent
              );
            }}
            className=""
            value={formik.values.emailsToBeSent}
            label=""
          />
        </Box>
      </HStack>
      {formik.values.emailsToBeSent && (
        <VStack className="gap-5 pl-10 mt-9">
          <HStack className="justify-between items-center">
            <Icon icon={"fluent-mdl2:radio-bullet"}></Icon>
            <p className={` form-label text-sm font-medium w-2/3 mb-0`}>
              Allow Hissa to send notification Emails to Employees
            </p>
            <Box className="flex justify-end pr-20 w-1/3 ml-2">
              <SwitchButton
                onClick={() => {
                  formik.setFieldValue(
                    "sendEmailsToEmployees",
                    !formik.values.sendEmailsToEmployees
                  );
                }}
                className=""
                value={formik.values.sendEmailsToEmployees}
                label=""
              />
            </Box>
          </HStack>
          {formik.values.sendEmailsToEmployees && (
            <VStack className="justify-between gap-5 pl-10">
              <HStack className="justify-between items-center">
                <Icon icon={"fluent-mdl2:radio-bullet"}></Icon>
                <p className={` form-label text-sm font-medium w-2/3 mb-0`}>
                  Grant related Emails
                </p>
                <Box className="flex justify-end pr-20 w-1/3 ml-2">
                  <SwitchButton
                    onClick={() => {
                      formik.setFieldValue(
                        "sendGrantEmailsToEmployees",
                        !formik.values.sendGrantEmailsToEmployees
                      );
                    }}
                    className=""
                    value={formik.values.sendGrantEmailsToEmployees}
                    label=""
                  />
                </Box>
              </HStack>
              {formik.values.sendGrantEmailsToEmployees && (
                <VStack className="justify-between gap-5 pl-10">
                  <HStack className="justify-between items-center">
                    <Icon
                      icon={"fluent-mdl2:radio-bullet"}
                      className="h-"
                    ></Icon>
                    <p className={` form-label text-sm font-medium w-2/3 mb-0`}>
                      Offered Emails
                    </p>
                    <Box className="flex justify-end pr-20 w-1/3 ml-2">
                      <SwitchButton
                        onClick={() => {
                          formik.setFieldValue(
                            "sendGrantOfferedEmailsToEmployees",
                            !formik.values.sendGrantOfferedEmailsToEmployees
                          );
                        }}
                        className=""
                        value={formik.values.sendGrantOfferedEmailsToEmployees}
                        label=""
                      />
                    </Box>
                  </HStack>
                  <HStack className="justify-between items-center">
                    <Icon
                      icon={"fluent-mdl2:radio-bullet"}
                      className="h-"
                    ></Icon>
                    <p className={` form-label text-sm font-medium w-2/3 mb-0`}>
                      withdrawn Emails
                    </p>
                    <Box className="flex justify-end pr-20 w-1/3 ml-2">
                      <SwitchButton
                        onClick={() => {
                          formik.setFieldValue(
                            "sendGrantWithdrawEmailsToEmployees",
                            !formik.values.sendGrantWithdrawEmailsToEmployees
                          );
                        }}
                        className=""
                        value={formik.values.sendGrantWithdrawEmailsToEmployees}
                        label=""
                      />
                    </Box>
                  </HStack>
                  <HStack className="justify-between items-center">
                    <Icon
                      icon={"fluent-mdl2:radio-bullet"}
                      className="h-"
                    ></Icon>
                    <p className={` form-label text-sm font-medium w-2/3 mb-0`}>
                      Reminder Emails
                    </p>
                    <Box className="flex justify-end pr-20 w-1/3 ml-2">
                      <SwitchButton
                        onClick={() => {
                          formik.setFieldValue(
                            "sendGrantReminderEmailsToEmployees",
                            !formik.values.sendGrantReminderEmailsToEmployees
                          );
                        }}
                        className=""
                        value={formik.values.sendGrantReminderEmailsToEmployees}
                        label=""
                      />
                    </Box>
                  </HStack>
                </VStack>
              )}
              <HStack className="justify-between items-center">
                <Icon icon={"fluent-mdl2:radio-bullet"}></Icon>
                <p className={` form-label text-sm font-medium w-2/3 mb-0`}>
                  Vesting related Emails to Employees
                </p>
                <Box className="flex justify-end pr-20 w-1/3 ml-2">
                  <SwitchButton
                    onClick={() => {
                      formik.setFieldValue(
                        "sendVestingEmailsToEmployees",
                        !formik.values.sendVestingEmailsToEmployees
                      );
                    }}
                    className=""
                    value={formik.values.sendVestingEmailsToEmployees}
                    label=""
                  />
                </Box>
              </HStack>
              {formik.values.sendVestingEmailsToEmployees && (
                <VStack className="justify-between gap-5 pl-10">
                  <HStack className="justify-between items-center">
                    <Icon icon={"fluent-mdl2:radio-bullet"}></Icon>
                    <p className={` form-label text-sm font-medium w-2/3 mb-0`}>
                      Vesting Completion Emails
                    </p>
                    <Box className="flex justify-end pr-20 w-1/3 ml-2">
                      <SwitchButton
                        onClick={() => {
                          formik.setFieldValue(
                            "sendVestingCompleteEmailsToEmployees",
                            !formik.values.sendVestingCompleteEmailsToEmployees
                          );
                        }}
                        className=""
                        value={
                          formik.values.sendVestingCompleteEmailsToEmployees
                        }
                        label=""
                      />
                    </Box>
                  </HStack>
                </VStack>
              )}
              <HStack className="justify-between items-center">
                <Icon icon={"fluent-mdl2:radio-bullet"}></Icon>
                <p className={` form-label text-sm font-medium w-2/3 mb-0`}>
                  Buyback related Emails
                </p>
                <Box className="flex justify-end pr-20 w-1/3 ml-2">
                  <SwitchButton
                    onClick={() => {
                      formik.setFieldValue(
                        "sendBuyBackEmailsToEmployees",
                        !formik.values.sendBuyBackEmailsToEmployees
                      );
                    }}
                    className=""
                    value={formik.values.sendBuyBackEmailsToEmployees}
                    label=""
                  />
                </Box>
              </HStack>
              {formik.values.sendBuyBackEmailsToEmployees && (
                <VStack className="justify-between gap-5 pl-10">
                  <HStack className="justify-between items-center">
                    <Icon
                      icon={"fluent-mdl2:radio-bullet"}
                      className="h-"
                    ></Icon>
                    <p className={` form-label text-sm font-medium w-2/3 mb-0`}>
                      Offered Emails
                    </p>
                    <Box className="flex justify-end pr-20 w-1/3 ml-2">
                      <SwitchButton
                        onClick={() => {
                          formik.setFieldValue(
                            "sendBuyBackOfferedEmailsToEmployees",
                            !formik.values.sendBuyBackOfferedEmailsToEmployees
                          );
                        }}
                        className=""
                        value={
                          formik.values.sendBuyBackOfferedEmailsToEmployees
                        }
                        label=""
                      />
                    </Box>
                  </HStack>
                  <HStack className="justify-between items-center">
                    <Icon icon={"fluent-mdl2:radio-bullet"}></Icon>
                    <p className={` form-label text-sm font-medium w-2/3 mb-0`}>
                      Closed or Completion Emails to Employees
                    </p>
                    <Box className="flex justify-end pr-20 w-1/3 ml-2">
                      <SwitchButton
                        onClick={() => {
                          formik.setFieldValue(
                            "sendBuyBackClosedEmailsToEmployees",
                            !formik.values.sendBuyBackClosedEmailsToEmployees
                          );
                        }}
                        className=""
                        value={formik.values.sendBuyBackClosedEmailsToEmployees}
                        label=""
                      />
                    </Box>
                  </HStack>
                  <HStack className="justify-between items-center">
                    <Icon
                      icon={"fluent-mdl2:radio-bullet"}
                      className="h-"
                    ></Icon>
                    <p className={` form-label text-sm font-medium w-2/3 mb-0`}>
                      Reminder Emails
                    </p>
                    <Box className="flex justify-end pr-20 w-1/3 ml-2">
                      <SwitchButton
                        onClick={() => {
                          formik.setFieldValue(
                            "sendBuyBackReminderEmailsToEmployees",
                            !formik.values.sendBuyBackReminderEmailsToEmployees
                          );
                        }}
                        className=""
                        value={
                          formik.values.sendBuyBackReminderEmailsToEmployees
                        }
                        label=""
                      />
                    </Box>
                  </HStack>
                </VStack>
              )}
            </VStack>
          )}
          <HStack className="justify-between items-center">
            <Icon icon={"fluent-mdl2:radio-bullet"}></Icon>
            <p className={` form-label text-sm font-medium w-2/3 mb-0`}>
              Allow Hissa to Send notification Emails to Admins
            </p>
            <Box className="flex justify-end pr-20 w-1/3">
              <SwitchButton
                onClick={() => {
                  formik.setFieldValue(
                    "sendEmailsToAdmin",
                    !formik.values.sendEmailsToAdmin
                  );
                }}
                className=""
                value={formik.values.sendEmailsToAdmin}
                label=""
              />
            </Box>
          </HStack>
          {formik.values.sendEmailsToAdmin && (
            <VStack className="justify-between gap-5 pl-10">
              <HStack className="justify-between items-center">
                <Icon icon={"fluent-mdl2:radio-bullet"}></Icon>
                <p className={` form-label text-sm font-medium w-2/3 mb-0`}>
                  Grant related Emails
                </p>
                <Box className="flex justify-end pr-20 w-1/3 ml-2">
                  <SwitchButton
                    onClick={() => {
                      formik.setFieldValue(
                        "sendGrantEmailsToAdmin",
                        !formik.values.sendGrantEmailsToAdmin
                      );
                    }}
                    className=""
                    value={formik.values.sendGrantEmailsToAdmin}
                    label=""
                  />
                </Box>
              </HStack>
              <HStack className="justify-between items-center">
                <Icon icon={"fluent-mdl2:radio-bullet"}></Icon>
                <p className={` form-label text-sm font-medium w-2/3 mb-0`}>
                  Plan related Emails
                </p>
                <Box className="flex justify-end pr-20 w-1/3 ml-2">
                  <SwitchButton
                    onClick={() => {
                      formik.setFieldValue(
                        "sendPlanEmailsToAdmin",
                        !formik.values.sendPlanEmailsToAdmin
                      );
                    }}
                    className=""
                    value={formik.values.sendPlanEmailsToAdmin}
                    label=""
                  />
                </Box>
              </HStack>
              <HStack className="justify-between items-center">
                <Icon icon={"fluent-mdl2:radio-bullet"}></Icon>
                <p className={` form-label text-sm font-medium w-2/3 mb-0`}>
                  Transaction related (Buyback/Exercise/Liquidity) Emails
                </p>
                <Box className="flex justify-end pr-20 w-1/3 ml-2">
                  <SwitchButton
                    onClick={() => {
                      formik.setFieldValue(
                        "sendTransactionEmailsToAdmin",
                        !formik.values.sendTransactionEmailsToAdmin
                      );
                    }}
                    className=""
                    value={formik.values.sendTransactionEmailsToAdmin}
                    label=""
                  />
                </Box>
              </HStack>
              <HStack className="justify-between items-center">
                <Icon icon={"fluent-mdl2:radio-bullet"}></Icon>
                <p className={` form-label text-sm font-medium w-2/3 mb-0`}>
                  Reminder Emails
                </p>
                <Box className="flex justify-end pr-20 w-1/3 ml-2">
                  <SwitchButton
                    onClick={() => {
                      formik.setFieldValue(
                        "sendReminderEmailsToAdmin",
                        !formik.values.sendReminderEmailsToAdmin
                      );
                    }}
                    className=""
                    value={formik.values.sendReminderEmailsToAdmin}
                    label=""
                  />
                </Box>
              </HStack>
            </VStack>
          )}
        </VStack>
      )}
    </VStack>
  );
};

export default EmailToggles;
