import Box from "@mui/material/Box";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { Input, Label } from "../components/shared/InputField";
import {
  ButtonPrimary,
  HStack,
  VStack,
  Error,
  ButtonPrimary1,
} from "../components/utils";
import { redemptionTag } from "../pages/onboarding/constantValues";
import {
  HistoricalRoundModel,
  Investor,
  RedemptionDetails,
  Secondary,
} from "../types/HistoricDataModel";

export type AddRedemptionProps = {
  transactionIndex: number;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
  transactionType: "investors" | "secondaries";
};

const AddRedemption = ({
  transactionIndex,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  transactionType = "investors",
}: AddRedemptionProps) => {
  const formik = useFormikContext<HistoricalRoundModel>();
  const {
    values,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
  } = formik;

  const transactionTouch =
    transactionType === "investors"
      ? (touched?.investors?.[transactionIndex] as Investor)
      : (touched?.secondaries?.[transactionIndex] as Secondary);
  const fieldTouched = transactionTouch?.redemptionDetails;
  const error =
    transactionType === "investors"
      ? (errors?.investors?.[transactionIndex] as Investor)
      : (errors?.secondaries?.[transactionIndex] as Secondary);
  const err = error?.redemptionDetails as RedemptionDetails;
  const basicDetailErrors =
    err?.redeemedAmount || err?.amountLeft || err?.redeemedDate;

  const [initialRedemption, setInitialRedemption] =
    useState<RedemptionDetails>();

  useEffect(() => {
    setInitialRedemption(
      values[transactionType][transactionIndex]?.redemptionDetails
    );
  }, []);

  const handleOnCancel = () => {
    const length: number =
      (transactionType === "investors"
        ? values.investors[transactionIndex].allotment?.length
        : values.secondaries[transactionIndex].transactions?.length) || 0;

    if (transactionType === "investors") {
      setFieldValue(
        `${transactionType}[${transactionIndex}].allotment`,
        values.investors[transactionIndex].allotment?.slice(0, length - 1)
      );
    } else {
      setFieldValue(
        `${transactionType}[${transactionIndex}].transactions`,
        values.secondaries[transactionIndex].transactions?.slice(0, length - 1)
      );
    }
    setFieldValue(
      `${transactionType}[${transactionIndex}].redemptionDetails`,
      initialRedemption
    );
    onSecondaryAction();
  };

  function checkAllotmentFeild(): boolean {
    const transactionDetail =
      transactionType === "investors"
        ? values.investors[transactionIndex].allotment
        : values.secondaries[transactionIndex].transactions;
    return !transactionDetail?.find(
      (transaction) => transaction.name === redemptionTag.name
    );
  }

  const doPartialTouch = () => {
    setFieldTouched(
      `${transactionType}[${transactionIndex}].redemptionDetails.redeemedAmount`
    );
    setFieldTouched(
      `${transactionType}[${transactionIndex}].redemptionDetails.amountLeft`
    );
    setFieldTouched(
      `${transactionType}[${transactionIndex}].redemptionDetails.redeemedDate`
    );
  };

  const handleSubmit = () => {
    const length: number =
      (transactionType === "investors"
        ? values.investors[transactionIndex].allotment?.length
        : values.secondaries[transactionIndex].transactions?.length) || 0;
    if (
      transactionType === "investors" &&
      checkAllotmentFeild() &&
      !initialRedemption
    ) {
      setFieldValue(`investors[${transactionIndex}].allotment[${length - 1}]`, {
        name: redemptionTag.name,
        identifier: redemptionTag.identifier,
      });
    } else if (
      transactionType === "secondaries" &&
      checkAllotmentFeild() &&
      !initialRedemption
    ) {
      setFieldValue(
        `secondaries[${transactionIndex}].transactions[${length - 1}]`,
        {
          name: redemptionTag.name,
          identifier: redemptionTag.identifier,
        }
      );
    } else if (transactionType === "investors") {
      setFieldValue(
        `investors[${transactionIndex}].allotment`,
        values.investors[transactionIndex].allotment?.slice(0, length - 1)
      );
    } else {
      setFieldValue(
        `secondaries[${transactionIndex}].transactions`,
        values.secondaries[transactionIndex].transactions?.slice(0, length - 1)
      );
    }
    onPrimaryAction();
  };

  return (
    <>
      <Box className="px-10 text-lg font-medium border-b py-7">
        <h6>Redemption</h6>
      </Box>
      <VStack className="w-full px-10 py-7 gap-9">
        <VStack className="w-full gap-9">
          <HStack className="gap-8 ">
            <div className="flex-1">
              <Label className="text-sm font-normal">Redemption Amount</Label>
              <Input
                type="number"
                placeholder="Enter Redeemed Value"
                className="w-96"
                {...getFieldProps(
                  `${transactionType}[${transactionIndex}].redemptionDetails.redeemedAmount`
                )}
              />
              {fieldTouched?.redeemedAmount && err?.redeemedAmount && (
                <Error text={`${err?.redeemedAmount}`} />
              )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Amount Available</Label>
              <Input
                type="number"
                placeholder="Enter Amount"
                className="w-96"
                {...getFieldProps(
                  `${transactionType}[${transactionIndex}].redemptionDetails.amountLeft`
                )}
              />
              {fieldTouched?.amountLeft && err?.amountLeft && (
                <Error text={`${err?.amountLeft}`} />
              )}
            </div>
          </HStack>
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">Redeemed Date</Label>
              <Input
                type="date"
                placeholder="Eg:DD/MM/YYYY"
                className="w-96"
                {...getFieldProps(
                  `${transactionType}[${transactionIndex}].redemptionDetails.redeemedDate`
                )}
              />
              {fieldTouched?.redeemedDate && err?.redeemedDate && (
                <Error text={`${err?.redeemedDate}`} />
              )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Add Notes</Label>
              <Input
                type="String"
                placeholder="Optional"
                className="w-96"
                {...formik.getFieldProps(
                  `${transactionType}[${transactionIndex}].redemptionDetails.notes`
                )}
              />
            </div>
          </HStack>

          <HStack className="justify-end">
            <ButtonPrimary1
              type="reset"
              className="text-red-500 mr-8"
              onClick={() => {
                handleOnCancel();
                onSecondaryAction();
              }}
            >
              Cancel
            </ButtonPrimary1>
            <ButtonPrimary
              onClick={() => {
                if (basicDetailErrors) {
                  doPartialTouch();
                } else {
                  handleSubmit();
                }
              }}
            >
              Save
            </ButtonPrimary>
          </HStack>
        </VStack>
      </VStack>
    </>
  );
};

export default AddRedemption;
