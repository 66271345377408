import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import SearchDropDown from "../components/shared/SearchDropdown";
import { Select } from "../components/shared/Select";
import {
  Box,
  ButtonPrimary,
  HStack,
  isAdminViewer,
  isEsopViewer,
} from "../components/utils";
import { sort } from "../utils/arrays";
import { BCHeaderType } from "../utils/interfaces/BCHeader";
import useIsMobile from "../utils/detectDevice";

const BCHeader = (props: BCHeaderType) => {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const [lastPage, setLastPage] = useState<string>();
  const isUserAdminViewer = isAdminViewer();
  const isUserEsopViewer = isEsopViewer();

  const {
    className,
    onClick,
    selectOption,
    bcTitle,
    bcMiddleTitle,
    bcSubTitle,
    buttonName,
    dropdown,
    selectedValue,
    ...rest
  } = props;

  const esopTransactionsBackRoutes = [
    "view-option-buy-back",
    "start-buy-back",
    "create-option-buyback",
    "edit-options-buyback",
    "create-exercise-flow",
    "edit-exercise-flow",
    "complete-buy-back",
  ];
  function isPathNamePresent() {
    return esopTransactionsBackRoutes.some((location) =>
      pathname.includes(location)
    );
  }
  const goBack = () => {
    if (pathname.includes("planView")) {
      navigate("/options/allPlans");
    } else if (pathname.includes("employeeDetails")) {
      navigate("/options/allEmployees");
    } else if (isPathNamePresent()) {
      navigate("/options/esopTransactions");
    } else if (pathname.includes("add-edit-pool")) {
      navigate("/options/esopOverview");
    }
  };
  const { isMobile } = useIsMobile();
  return (
    <>
      <Box
        className={`flex ${
          isMobile
            ? "flex-col text-xs"
            : "font-medium text-base flex-row justify-between"
        }   text-gray-600 ${className}`}
      >
        <Box
          className={`${
            isMobile ? "" : "h-6"
          } flex flex-row items-baseline gap-2 pl-2 border-l-4 border-solid border-orange-501`}
        >
          <Box
            className={`${
              bcSubTitle ? "cursor-pointer text-orange-501 hover:underline" : ""
            }`}
            onClick={goBack}
          >
            {bcTitle}
          </Box>
          {bcMiddleTitle && <Box>{bcMiddleTitle}</Box>}
          {bcSubTitle && (
            <>
              <Box className="font-semibold"> {" > "} </Box>
              <Box>{bcSubTitle}</Box>
            </>
          )}
        </Box>
        <Box className={`flex flex-row ${isMobile ? "mb-4 gap-2 " : ""}`}>
          {dropdown && dropdown.length > 0 ? (
            <Box className="bg-white rounded w-44">
              <Select
                className="bg-white w-44"
                value={selectedValue?.id}
                options={sort(dropdown || [], "name")}
                valueGetter={(o) => o.id}
                textGetter={(o) => `${o.name} `}
                onChange={selectOption}
              />
            </Box>
          ) : (
            ""
          )}
          <Box>
            {buttonName ? (
              <ButtonPrimary
                className={`px-5 py-1 ${isMobile ? "" : "ml-4"} h-11`}
                onClick={onClick}
                disabled={isUserAdminViewer || isUserEsopViewer}
              >
                {buttonName}
              </ButtonPrimary>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BCHeader;
