import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      title: { display: true, text: "Date" },
      stacked: true,
    },
    y: {
      title: { display: true, text: "Grants" },
      stacked: true,
    },
  },
};

export function BarChart({
  data,
}: {
  data: ChartData<"bar", number[], unknown>;
}) {
  return <Bar options={options} data={data} />;
}
