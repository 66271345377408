import { CapTable, Shareholder } from "../types/EsopPlan";

export function makeFlat(capTables: CapTable[]): Shareholder[] {
  const shareholders: Shareholder[] = [];
  capTables.forEach((ct) => {
    const groupName = ct.name;
    ct.shareholder.forEach((sh) => {
      shareholders.push({ ...sh, type: groupName });
    });
  });
  return shareholders;
}
