import Box from "@mui/material/Box";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { toast } from "react-toastify";
import {
  ButtonPrimary,
  HStack,
  VStack,
  Error,
  ButtonPrimary1,
} from "../components/utils";
import { Input, Label } from "../components/shared/InputField";
import { Select } from "../components/shared/Select";
import {
  HistoricalRoundModel,
  HistoricalRoundPopupDetail,
  Secondary,
  SecondaryErrorModel,
} from "../types/HistoricDataModel";
import InvestorsNameDropDown from "../components/InvestorsNameDropDown";
import { SwitchButton } from "../components/shared/SwitchButton";
import { useTransactionIndexStore } from "../store/transactionIndexStore";
import {
  initialRoundDetail,
  initialSecondaryTransaction,
  initialTransactionName,
} from "../pages/onboarding/initValues";
import { OverViewTags } from "../components/AllotmentTag";
import AddRoundName from "./AddRoundName";
import { formatDate, formatWithTimeZone } from "../utils/date";
import { buyBackTag } from "../pages/onboarding/constantValues";

export type AddSecondaryTransactionProps = {
  transactionIndex: number;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
  mode: "Add" | "Edit" | "Clone";
};

const AddSecondaryTransaction = ({
  transactionIndex,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  mode,
}: AddSecondaryTransactionProps) => {
  const formik = useFormikContext<HistoricalRoundModel>();
  const { values, errors, touched, setFieldValue, setFieldTouched } = formik;
  const transactionIndexStore = useTransactionIndexStore();
  const [enableClone, setEnableClone] = useState<boolean>(false);
  const investorLength = values.investorsDropdown.length;
  const fieldTouched = touched?.secondaries?.[transactionIndex];
  const err = errors?.secondaries?.[transactionIndex] as SecondaryErrorModel;
  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    data?: HistoricalRoundPopupDetail;
    index?: number;
  }>({
    open: false,
  });
  const basicDetailErrors =
    err?.buyer ||
    err?.seller ||
    err?.amount ||
    err?.numberOfShares ||
    err?.pricePerShare ||
    err?.date ||
    err?.securityType ||
    err?.roundName;
  const [investorTransaction, setInvestorTransaction] = useState<Secondary>();

  useEffect(() => {
    setInvestorTransaction(values.secondaries[transactionIndex]);
  }, [transactionIndex]);

  const handleDelete = () => {
    if (mode === "Edit") {
      setFieldValue(`secondaries[${transactionIndex}]`, investorTransaction);
    } else {
      setFieldValue(
        "secondaries",
        values?.secondaries?.filter((_, index) => index !== transactionIndex)
      );
    }
  };

  function handleAddRound(index: number) {
    const roundDetail = [...values.historicalRoundPopupDetails];
    roundDetail.splice(index, 0, initialRoundDetail);
    setFieldValue("historicalRoundPopupDetails", roundDetail);
    setDialog({
      open: true,
    });
    setFieldValue(`secondaries[${index}].roundName`, undefined);
  }

  const handleOnInput = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "Add Round")
      handleAddRound(values.historicalRoundPopupDetails.length);
  };

  const doPartialTouch = () => {
    setFieldTouched(`secondaries[${transactionIndex}].buyer`);
    setFieldTouched(`secondaries[${transactionIndex}].seller`);
    setFieldTouched(`secondaries[${transactionIndex}].amount`);
    setFieldTouched(`secondaries[${transactionIndex}].numberOfShares`);
    setFieldTouched(`secondaries[${transactionIndex}].pricePerShare`);
    setFieldTouched(`secondaries[${transactionIndex}].date`);
    setFieldTouched(`secondaries[${transactionIndex}].securityType`);
    setFieldTouched(`secondaries[${transactionIndex}].roundName`);
  };

  const isBuyerNameCompany = (): boolean =>
    values.secondaries[transactionIndex].buyer?.toLowerCase() === "company";

  const handleSubmitSaveAndClose = () => {
    if (
      values.secondaries[transactionIndex] !== investorTransaction ||
      mode === "Edit"
    ) {
      if (isBuyerNameCompany() && mode !== "Edit") {
        const length =
          values.secondaries[transactionIndex].transactions?.length || 0;
        setFieldValue(
          `secondaries[${transactionIndex}].transactions[${length}]`,
          {
            name: buyBackTag.name,
            identifier: buyBackTag.identifier,
          }
        );
      }

      if (mode === "Edit") {
        if (
          isBuyerNameCompany() &&
          investorTransaction?.buyer?.toLowerCase() !== "company"
        ) {
          const length =
            values.secondaries[transactionIndex].transactions?.length || 0;
          setFieldValue(
            `secondaries[${transactionIndex}].transactions[${length}]`,
            {
              name: buyBackTag.name,
              identifier: buyBackTag.identifier,
            }
          );
        } else {
          const length =
            values.secondaries[transactionIndex].transactions?.length || 0;
          setFieldValue(
            `secondaries[${transactionIndex}].transactions`,
            values.secondaries[transactionIndex].transactions?.slice(
              0,
              length - 1
            )
          );
        }
      } else {
        setFieldValue(`secondaries[${transactionIndex}].investorProfile`, null);
        setFieldValue(
          `secondaries[${transactionIndex}].conversionDetails`,
          null
        );
        setFieldValue(
          `secondaries[${transactionIndex}].redemptionDetails`,
          null
        );
      }
      toast(
        mode === "Edit"
          ? "Transaction Edited Successfully!"
          : "Transaction Added Successfully!",
        {
          type: "success",
          autoClose: 2000,
        }
      );
      transactionIndexStore.setIndex(transactionIndex);
      onPrimaryAction();
    } else {
      toast("Duplicate Transaction Cant be Added!", {
        type: "error",
        autoClose: 2000,
      });
    }
  };

  function handleAddAllotment(index: number, length: number) {
    const transactionDetail = [
      ...(values.secondaries[index].transactions || []),
    ];
    transactionDetail.splice(length, 0, initialTransactionName);
    setFieldValue(`secondaries[${index}].transactions`, transactionDetail);
  }

  const handleClone = () => {
    handleAddAllotment(
      transactionIndex,
      values.secondaries[transactionIndex].transactions?.length || 0
    );
    if (enableClone) {
      const cloneIndex = transactionIndexStore.index;
      handleAddTransaction(
        values.secondaries.length,
        values.secondaries[cloneIndex]
      );
    } else {
      handleAddTransaction(values.secondaries.length);
    }
    toast("Transaction Added Successfully!", {
      type: "success",
      autoClose: 2000,
    });
  };

  const setSecondaryTransactionDetail = () => {
    if (isBuyerNameCompany()) {
      const length =
        values.secondaries[transactionIndex].transactions?.length || 0;
      setFieldValue(
        `secondaries[${transactionIndex}].transactions[${length}]`,
        {
          name: buyBackTag.name,
          identifier: buyBackTag.identifier,
        }
      );
    }
    setFieldValue(`investors[${transactionIndex}].investorProfile`, null);
    setFieldValue(`investors[${transactionIndex}].conversionDetails`, null);
    setFieldValue(`investors[${transactionIndex}].redemptionDetails`, null);
  };

  const handleSubitSaveAndContinue = () => {
    if (values.secondaries[transactionIndex] !== investorTransaction) {
      setSecondaryTransactionDetail();
      handleClone();
    } else {
      toast("Duplicate Transaction Cant be Added!", {
        type: "error",
        autoClose: 2000,
      });
    }
  };

  function handleAddTransaction(index: number, secondary?: Secondary) {
    const secondaries = [...values.secondaries];
    secondaries.splice(index, 0, secondary || initialSecondaryTransaction);
    setFieldValue("secondaries", secondaries);
  }

  const calculateAmount = () => {
    setFieldValue(
      `secondaries[${transactionIndex}].amount`,
      (values.secondaries[transactionIndex].numberOfShares || 0) *
        (values.secondaries[transactionIndex].pricePerShare || 0)
    );
  };

  return (
    <>
      <Box className="px-5 text-lg font-medium border-b py-5">
        <HStack className="justify-between">
          <h6>Add Secondary Transaction</h6>
          <HStack className="flex flex-row-reverse justify-start  items-center  text-sm font-semibold ">
            {mode !== "Edit" && (
              <SwitchButton
                className="text-xs font-normal items-center m-1"
                value={enableClone}
                label="Retain Current Information"
                onClick={() => {
                  transactionIndexStore.setIndex(transactionIndex);
                  setEnableClone(!enableClone);
                }}
              />
            )}
          </HStack>
        </HStack>
      </Box>
      <Dialog open={dialog.open} maxWidth="md">
        (
        <AddRoundName
          historicalRoundIndex={values.historicalRoundPopupDetails.length - 1}
          onPrimaryAction={() => setDialog({ open: false })}
          onSecondaryAction={() => setDialog({ open: false })}
        />
        )
      </Dialog>
      <VStack className="w-full px-10 py-7 gap-9">
        <VStack className="w-full gap-5">
          <HStack className="gap-5 ">
            <div className="flex-1">
              <Label className="text-sm font-normal">Buyer Name</Label>
              <InvestorsNameDropDown
                dropDownOptions={values.investorsDropdown}
                selectedOptions={values.secondaries[transactionIndex].buyer}
                placeholderText="  Enter Buyer Name"
                shouldCreateNewOption
                updateNewValue={(investorName: string) => {
                  setFieldValue(
                    `secondaries[${transactionIndex}].buyer`,
                    investorName
                  );
                  if (!values.investorsDropdown.includes(investorName))
                    setFieldValue(
                      `investorsDropdown[${investorLength}]`,
                      investorName
                    );
                }}
              />

              {fieldTouched?.buyer && err?.buyer && <Error text={err?.buyer} />}
            </div>

            <div className="flex-1">
              <Label className="text-sm font-normal">Seller Name</Label>
              <InvestorsNameDropDown
                dropDownOptions={values.investorsDropdown}
                selectedOptions={values.secondaries[transactionIndex].seller}
                placeholderText="  Enter Seller Name"
                updateNewValue={(investorName: string) => {
                  if (values.investorsDropdown.includes(investorName))
                    setFieldValue(
                      `secondaries[${transactionIndex}].seller`,
                      investorName
                    );
                }}
              />
              {fieldTouched?.seller && err?.seller && (
                <Error text={err?.seller} />
              )}
            </div>
          </HStack>
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">Number of Shares</Label>
              <Input
                type="number"
                placeholder="Eg:1,200"
                className="w-80"
                {...formik.getFieldProps(
                  `secondaries[${transactionIndex}].numberOfShares`
                )}
              />
              {fieldTouched?.numberOfShares && err?.numberOfShares && (
                <Error text={err?.numberOfShares} />
              )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Price per share</Label>
              <Input
                type="number"
                placeholder="Eg:1,020"
                className="w-80"
                {...formik.getFieldProps(
                  `secondaries[${transactionIndex}].pricePerShare`
                )}
              />
              {fieldTouched?.pricePerShare && err?.pricePerShare && (
                <Error text={err?.pricePerShare} />
              )}
            </div>
          </HStack>
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">Amount</Label>
              <Input
                type="number"
                placeholder="Eg:1,200"
                className="w-80"
                onFocus={() => calculateAmount()}
                readOnly
                {...formik.getFieldProps(
                  `secondaries[${transactionIndex}].amount`
                )}
              />
              {fieldTouched?.amount && err?.amount && (
                <Error text={err?.amount} />
              )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Date</Label>
              <Input
                type="date"
                placeholder="Enter Date"
                className="w-80"
                value={formatDate(values.secondaries[transactionIndex].date)}
                onChange={(e) =>
                  setFieldValue(
                    `secondaries[${transactionIndex}].date`,
                    formatWithTimeZone(e.target.value)
                  )
                }
              />
              {fieldTouched?.date && err?.date && <Error text={err?.date} />}
            </div>
          </HStack>
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">Security type</Label>
              <Select
                options={values.securitiesDropdown}
                {...formik.getFieldProps(
                  `secondaries[${transactionIndex}].securityType`
                )}
              />
              {fieldTouched?.securityType && err?.securityType && (
                <Error text={err?.securityType} />
              )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Round Name</Label>
              <Select
                options={[
                  "Add Round",
                  ...values.historicalRoundPopupDetails.map(
                    (roundDetail) => roundDetail.roundName
                  ),
                ]}
                onInput={handleOnInput}
                {...formik.getFieldProps(
                  `secondaries[${transactionIndex}].roundName`
                )}
              />
              {fieldTouched?.roundName && err?.roundName && (
                <Error text={err?.roundName} />
              )}
            </div>
          </HStack>
          <HStack className="justify-between">
            <HStack className="w-32 py-4">
              <Box>
                <HStack>
                  {isBuyerNameCompany() && (
                    <OverViewTags tagName={buyBackTag.name} />
                  )}
                </HStack>
              </Box>
            </HStack>
            <HStack className="justify-end">
              <ButtonPrimary1
                type="reset"
                className="text-red-500 mr-4"
                onClick={() => {
                  handleDelete();
                  onSecondaryAction();
                }}
              >
                Cancel
              </ButtonPrimary1>
              <ButtonPrimary1
                className="mr-4"
                onClick={() => {
                  if (basicDetailErrors) {
                    doPartialTouch();
                  } else {
                    handleSubmitSaveAndClose();
                  }
                }}
              >
                Save & Close
              </ButtonPrimary1>
              {mode !== "Edit" && (
                <ButtonPrimary
                  onClick={() => {
                    if (basicDetailErrors) {
                      doPartialTouch();
                    } else {
                      handleSubitSaveAndContinue();
                    }
                  }}
                >
                  Save & Add Another
                </ButtonPrimary>
              )}
            </HStack>
          </HStack>
        </VStack>
      </VStack>
    </>
  );
};

export default AddSecondaryTransaction;
