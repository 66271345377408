import { EmployeeExportExcelReq } from "../types/ExportExcelEmployee";
import { GrantExportExcelReq } from "../types/ExportExcelGrant";
import { OptionBuyBackExportExcelReq } from "../types/ExportExcelOptionBuyBack";
import { ReportDto } from "../types/Grant";
import esopNodeApi from "./esopNodeApi";
import hissaApi from "./hissaApi";

export async function exportExcel(reqExcelBody: any): Promise<ReportDto> {
  return esopNodeApi
    .post("/exports/exportExcel", reqExcelBody)
    .then((res) => res.data);
}
