import { useMutation, useQuery } from "react-query";
import {
  optionBuybackDetail,
  StateBuybackDetail,
} from "../types/OptionBuyback";
import { queryClient } from "./client";
import {
  buybackState,
  closeLiquidityEvent,
  completeLiquidityEvent,
  createOptionBuybackDetails,
  deleteBuybackOffer,
  discardBuyback,
  editLiquidityEvent,
  editPilotMode,
  exercisableDetail,
  getOptionBuybackDetails,
  sendLiquidationIntimation,
  startLiquidityEvent,
  uploadEmployeeOffersList,
  uploadTDSExcel,
} from "../api/optionBuyback";
import { randomOfferList, randomOptionBuybackOverview } from "./placeholders";

export function useGetOptionBuyback() {
  return useQuery({
    queryKey: "optionBuybackIverview",
    queryFn: getOptionBuybackDetails,
    placeholderData: Array(10)
      .fill({})
      .map((_i) => randomOptionBuybackOverview()) as optionBuybackDetail[],
  });
}

export function useCreateOptionBuyback() {
  return useMutation({
    mutationKey: "creatOptionBuyback",
    mutationFn: createOptionBuybackDetails,
    onSuccess: (data) => {
      queryClient.invalidateQueries("optionBuybackIverview");
      queryClient.invalidateQueries("buybackState");
      queryClient.refetchQueries("optionBuybackIverview");
      queryClient.refetchQueries("buybackState");
    },
    onError: () => {},
  });
}

export function useExercisableDetails(percentage: number, cutOffDate: string) {
  return useQuery({
    queryKey: ["exercisableDetails", percentage, cutOffDate],
    queryFn: exercisableDetail,
    enabled: !!percentage,
  });
}
export function useCumulativeOffers(percentage: number, cutOffDate: string) {
  return useQuery({
    queryKey: ["exercisableDetails", percentage, cutOffDate],
    queryFn: exercisableDetail,
    select: (data) => data.cumulativeOffers,
    enabled: !!percentage,
  });
}

export function useBuybackState(buybackId: number) {
  return useQuery({
    queryKey: ["buybackState", buybackId],
    queryFn: buybackState,
    enabled: !!buybackId,
    placeholderData: Array(10)
      .fill({})
      .map((_i) => randomOfferList()) as StateBuybackDetail[],
  });
}

export function useDiscardBuyback() {
  return useMutation({
    mutationKey: "discardBuyback",
    mutationFn: discardBuyback,
    onSuccess: () => {
      queryClient.invalidateQueries("optionBuybackIverview");
      queryClient.refetchQueries("optionBuybackIverview");
    },
    onError: () => {},
  });
}

export function useSendLiquidationIntimation() {
  return useMutation({
    mutationKey: "startLiquidation",
    mutationFn: sendLiquidationIntimation,
    onSuccess: () => {
      queryClient.invalidateQueries("optionBuybackIverview");
      queryClient.refetchQueries("optionBuybackIverview");
      queryClient.invalidateQueries("buybackState");
      queryClient.refetchQueries("buybackState");
    },
  });
}

export function useDeleteBuybackOffer() {
  return useMutation({
    mutationKey: "deleteBuybackOffer",
    mutationFn: deleteBuybackOffer,
    onSuccess: () => {
      queryClient.invalidateQueries("buybackState");
      queryClient.refetchQueries("buybackState");
    },
    onError: () => {},
  });
}

export function useUploadEmployeeOffers() {
  return useMutation({
    mutationKey: "uploadEmployeeList",
    mutationFn: uploadEmployeeOffersList,
    onSuccess: (data) => {
      queryClient.setQueryData("buybackOffers", data);
    },
    onError: () => {},
  });
}

export function useGetUploadedBuybackOffers() {
  return useQuery({
    queryFn: undefined,
    queryKey: "buybackOffers",
  });
}

export function useGetUploadedTDS() {
  return useMutation({
    mutationFn: uploadTDSExcel,
    mutationKey: "uploadEmployeeTDSList",
    onSuccess: (data) => {
      queryClient.setQueryData("uploadedTDS", data);
    },
    onError: () => {},
  });
}

export function useGetUploadedTDSList(isFetched: boolean) {
  return useQuery({
    queryKey: "uploadedTDS",
    queryFn: undefined,
    enabled: isFetched,
  });
}

export function useStartLiquidityEvent() {
  return useMutation({
    mutationKey: "StartEvent",
    mutationFn: startLiquidityEvent,
    onSuccess: () => {
      queryClient.invalidateQueries("optionBuybackIverview");
      queryClient.refetchQueries("optionBuybackIverview");
      queryClient.invalidateQueries("buybackState");
      queryClient.refetchQueries("buybackState");
    },
    onError: () => {},
  });
}

export function useCompleteLiquidityEvent() {
  return useMutation({
    mutationKey: "completeEvent",
    mutationFn: completeLiquidityEvent,
    onSuccess: () => {
      queryClient.invalidateQueries("optionBuybackIverview");
      queryClient.refetchQueries("optionBuybackIverview");
      queryClient.invalidateQueries("buybackState");
      queryClient.refetchQueries("buybackState");
    },
  });
}

export function useCloseLiquidityEvent() {
  return useMutation({
    mutationKey: "closeEvent",
    mutationFn: closeLiquidityEvent,
    onSuccess: () => {
      queryClient.invalidateQueries("optionBuybackIverview");
      queryClient.refetchQueries("optionBuybackIverview");
      queryClient.invalidateQueries("buybackState");
      queryClient.refetchQueries("buybackState");
    },
  });
}

export function useEditLiquidityEvent() {
  return useMutation({
    mutationKey: "editEvent",
    mutationFn: editLiquidityEvent,
    onSuccess: () => {
      queryClient.invalidateQueries("optionBuybackIverview");
      queryClient.refetchQueries("optionBuybackIverview");
      queryClient.invalidateQueries("buybackState");
      queryClient.refetchQueries("buybackState");
    },
  });
}

export function useEditPilotMode() {
  return useMutation({
    mutationKey: "pilotAutoMode",
    mutationFn: editPilotMode,
    onSuccess: () => {
      queryClient.invalidateQueries("optionBuybackIverview");
      queryClient.refetchQueries("optionBuybackIverview");
      queryClient.invalidateQueries("buybackState");
      queryClient.refetchQueries("buybackState");
    },
  });
}
