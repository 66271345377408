import { Box } from "@mui/material";
import { getNames } from "country-list";
import { useFormikContext } from "formik";
import { Select } from "../components/shared/Select";
import { Input, Label, TextArea } from "../components/shared/InputField";
import {
  VStack,
  HStack,
  ButtonPrimary1,
  ButtonPrimary,
  Error,
} from "../components/utils";
import { designationDropDownValues } from "../constants/DropDownValues";
import {
  CompanyDetailsPostModel,
  FounderDetailDataModel,
} from "../types/OnBoardDataModel";

export type AddEditFounderProps = {
  founder?: FounderDetailDataModel;
  founderIndex: number;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
  mode?: string;
};

const AddEditFounder = ({
  founder,
  founderIndex,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  mode,
}: AddEditFounderProps) => {
  const formik = useFormikContext<CompanyDetailsPostModel>();

  const {
    values,
    touched,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
    initialErrors,
    setFieldError,
  } = formik;

  const fieldTouched = touched?.founders?.[founderIndex];
  const err = errors?.founders?.[founderIndex] as FounderDetailDataModel;
  const basicDetailErrors =
    err?.fullName || err?.emailId || err?.designation || err?.nationality;

  const handleCancel = () => {
    if (mode === "Add") {
      if (!founder) {
        setFieldValue(
          "founders",
          values?.founders?.filter((_, index) => index !== founderIndex)
        );
        resetForm();
      }
    }
    onSecondaryAction();
  };
  const handleClose = () => {
    onSecondaryAction();
  };

  const doPartialTouch = () => {
    setFieldTouched(`founders[${founderIndex}].fullName`);
    setFieldTouched(`founders[${founderIndex}].emailId`);
    setFieldTouched(`founders[${founderIndex}].designation`);
    setFieldTouched(`founders[${founderIndex}].nationality`);
  };

  const handleSubmit = () => {
    if (founder) {
      setFieldValue(`founders[${founderIndex}].isEdit`, true);
    }
    onPrimaryAction();
  };
  return (
    <>
      <Box className="px-10 text-lg font-medium border-b py-4">
        <h6 className="flex justify-between">
          {!founder ? "Add Founder Details" : "Edit Founder Details"}{" "}
          <span
            onClick={() => {
              handleClose();
            }}
            className="cursor-pointer"
          >
            X
          </span>{" "}
        </h6>
      </Box>
      <VStack className="w-full px-10 py-7 gap-9">
        <VStack className="w-full gap-9">
          <HStack className="gap-8 ">
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Founder Name<span className="text-rose-400">*</span>
              </Label>
              <Input
                type="text"
                placeholder="Enter the founder name"
                className="w-96"
                {...getFieldProps(`founders[${founderIndex}].fullName`)}
              />
              {fieldTouched?.fullName && err?.fullName && (
                <Error text={err?.fullName} />
              )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Designation<span className="text-rose-400">*</span>
              </Label>
              <Select
                options={designationDropDownValues}
                {...getFieldProps(`founders[${founderIndex}].designation`)}
              />
              {fieldTouched?.designation && err?.designation && (
                <Error text={err?.designation} />
              )}
            </div>
          </HStack>
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Email Id
                <span className="text-rose-400">*</span>
              </Label>
              <Input
                type="email"
                placeholder="Eg: example@domain.com"
                className="w-96"
                {...getFieldProps(`founders[${founderIndex}].emailId`)}
              />
              {fieldTouched?.emailId && err?.emailId && (
                <Error text={err?.emailId} />
              )}
            </div>

            <div className="flex-1">
              <Label className="text-sm font-normal">Contact Number</Label>
              <Input
                type="text"
                placeholder="Eg:8045804850"
                className="w-96"
                {...getFieldProps(`founders[${founderIndex}].contactNumber`)}
              />
            </div>
          </HStack>
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">Address</Label>
              <TextArea
                {...getFieldProps(`founders[${founderIndex}].address`)}
              />
            </div>

            <div className="flex-1">
              <Label className="text-sm font-normal">Date of Birth</Label>
              <Input
                type="date"
                placeholder="Eg:DD/MM/YYYY"
                className="w-96"
                {...getFieldProps(`founders[${founderIndex}].dateOfBirth`)}
              />
              {fieldTouched?.dateOfBirth && err?.dateOfBirth && (
                <Error text={err?.dateOfBirth} />
              )}
            </div>
          </HStack>

          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Nationality<span className="text-rose-400">*</span>
              </Label>
              <Select
                className="w-96"
                options={getNames().sort()}
                {...getFieldProps(`founders[${founderIndex}].nationality`)}
              />
              {fieldTouched?.nationality && err?.nationality && (
                <Error text={err?.nationality} />
              )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Linkedin Profile</Label>
              <Input
                type="String"
                placeholder="Linkedin Profile"
                className="w-96"
                {...getFieldProps(`founders[${founderIndex}].linkedInProfile`)}
              />
            </div>
          </HStack>
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">Twitter handle</Label>
              <Input
                type="text"
                placeholder="Twitter Profile"
                className="w-96"
                {...getFieldProps(`founders[${founderIndex}].twitterHandle`)}
              />
            </div>
          </HStack>
          <HStack className="justify-end">
            <ButtonPrimary1
              onClick={() => {
                handleCancel();
              }}
              className="mr-8"
            >
              Cancel
            </ButtonPrimary1>

            <ButtonPrimary
              className="min-w-[120px]"
              onClick={() => {
                if (basicDetailErrors) {
                  doPartialTouch();
                } else {
                  handleSubmit();
                }
              }}
            >
              Save
            </ButtonPrimary>
          </HStack>
        </VStack>
      </VStack>
    </>
  );
};

export default AddEditFounder;
