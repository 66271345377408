import { Icon } from "@iconify/react";
import { useParams } from "react-router-dom";
import { Box, Center, HStack, VStack } from "../components/utils";
import { _sum } from "../utils/arrays";
import { useEsopPlans } from "../queries";
import { EsopPlan } from "../types/EsopPlan";
import { SemiDoughnutGraph } from "./SemiDoughnutChart";
import { optionsPool } from "./chart-data";
import { getCurrencyType } from "../utils/currencyFormatter";
import { useEsopModellingStore } from "../store/esopModellingStore";

function EsopForecast() {
  const data = useEsopModellingStore();

  let employeesNumberOfOptions = 0;
  let employeesPercentageHolding = 0;

  data.summary.yearlyDetailsSummary?.forEach((item, index) => {
    employeesNumberOfOptions += item.noOfOptions;
    employeesPercentageHolding += item.percentageHolding;
  });

  data.summary.additionalCompensation?.forEach((item, index) => {
    employeesNumberOfOptions += item.noOfOptions;
    employeesPercentageHolding += item.percentageHolding;
  });

  const esopPercentage = (
    data.summary.foundingteam.percentageHolding +
    data.summary.advisors.percentageHolding +
    employeesPercentageHolding +
    data.summary.performance.percentageHolding +
    _sum(data.summary.refresh, "percentageHolding")
  ).toFixed(4);

  const esopShares = Math.floor(
    data.summary.foundingteam.noOfOptions +
      data.summary.advisors.noOfOptions +
      employeesNumberOfOptions +
      data.summary.performance.noOfOptions +
      _sum(data.summary.refresh, "noOfOptions")
  );

  const sars = data.summary.advisors.percentageHolding.toFixed(4);
  const sarsOptions = Math.floor(data.summary.advisors.noOfOptions);

  const remainingPool = 100 - parseFloat(esopPercentage) - parseFloat(sars);

  const header = "ESOP Forecast";
  const rows = [
    {
      title: `${esopPercentage} %`,
      subtitle: "ESOP Percentage",
    },
    {
      title: `${sars} %`,
      subtitle: "SARS",
    },
    {
      title: esopShares,
      subtitle: "No. of Options",
    },
  ];
  const optionsData: any = {
    labels: ["ESOP", "SAR", "POOL"],
    datasets: [
      {
        data: [esopPercentage, sars, remainingPool],
        backgroundColor: [
          "rgba(255,168,0,1)",
          "rgba(255,168,0,.15",
          "rgba(10, 66, 114, 0.2)",
        ],
        borderColor: [
          "rgba(255,168,0,1)",
          "rgba(255,168,0,.15",
          "rgba(10, 66, 114, 0.2)",
        ],
        text: esopPercentage,
        text2: `${esopShares} Options for ESOP,`,
        text3: ` ${sarsOptions} Options for SARS`,
      },
    ],
  };
  return (
    <VStack className={`p-6 bg-white rounded-lg h-96`}>
      <VStack className="mb-0">
        <p className="font-medium text-gray-dark">{header}</p>
      </VStack>
      <Box className=" align-center">
        {esopPercentage && (
          <SemiDoughnutGraph
            options={optionsPool}
            data={optionsData}
            graph={"ESOP-Modelling"}
            text2={`${esopShares} Options for ESOP,`}
            text3={`${sarsOptions} Options for SARS`}
          />
        )}
      </Box>
      {rows.map((row) => (
        <HStack key={row.subtitle} className="justify-between p-2">
          <HStack>
            <Center
              className="items-center w-6 h-6 p-1"
              style={{ backgroundColor: "#F3F6F9", borderRadius: "50%" }}
            >
              <Icon icon="mdi:octahedron" style={{ color: "gray" }} />
            </Center>
            <VStack className="pl-4">
              <p className="font-medium capitalize text-xs2 text-gray-dark">
                {row.title}
              </p>
              <p className="capitalize text-xs1 font-semi-medium text-gray-light">
                {row.subtitle}
              </p>
            </VStack>
          </HStack>
        </HStack>
      ))}
    </VStack>
  );
}
export default EsopForecast;
