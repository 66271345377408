import { Autocomplete, createFilterOptions, TextField } from "@mui/material";

export type OptionListModel = {
  dropDownOptions: string[];
  selectedOptions?: string;
  placeholderText?: string;
  shouldCreateNewOption?: boolean;
  updateNewValue: (investorName: string) => void;
};
const filter = createFilterOptions<string>();

export default function InvestorsNameDropDown({
  dropDownOptions,
  placeholderText = "",
  selectedOptions,
  shouldCreateNewOption = false,
  updateNewValue,
}: OptionListModel) {
  const dropDownOption = dropDownOptions;
  return (
    <Autocomplete
      value={selectedOptions}
      options={dropDownOption}
      clearOnBlur
      onChange={(_, newValue) => {
        if (newValue) {
          updateNewValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue === option);
        if (inputValue !== "" && !isExisting && shouldCreateNewOption) {
          filtered.push(inputValue);
        }
        return filtered;
      }}
      selectOnFocus
      handleHomeEndKeys={shouldCreateNewOption}
      id="createableTextfeild"
      renderOption={(props, option) => (
        <li className="mx-2" {...props}>
          {option}
        </li>
      )}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          className="form-input p-16"
          hiddenLabel
          placeholder={placeholderText}
          InputProps={{
            style: {
              paddingLeft: 4,
            },
            disableUnderline: true,
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}
