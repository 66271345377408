import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Icon } from "@iconify/react";
import { HStack } from "../utils";

export default function BasicMenu<T>({
  options,
  defaultValue,
  onOptionChange,
}: {
  options: T[];
  defaultValue: string | number;
  onOptionChange: (value: T) => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e: any) => {
    setAnchorEl(null);
  };
  const handleChange = (o: any) => {
    onOptionChange(o);
    setAnchorEl(null);
  };
  return (
    <div>
      <HStack className="items-center justify-between gap-4 px-2 py-1 text-sm rounded bg-slate-light">
        <span>{defaultValue}</span>
        <button
          type="button"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className=" hover:scale-125"
        >
          <Icon icon="akar-icons:chevron-down" className="text-slate-600" />
        </button>
      </HStack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options.map((o, key) => {
          const option = o as unknown as string;
          return (
            <MenuItem key={key} onClick={() => handleChange(o)}>
              <HStack>
                <p>{option}</p>
              </HStack>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
