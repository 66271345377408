import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  redirectToHissa,
  VStack,
} from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import { Input, Label } from "../../components/shared/InputField";
import {
  useCheckCompanyName,
  useOnBoardingElasticSearch,
} from "../../queries/onBoarding";
import { checkCompanyName } from "../../api/onBoarding";
import {
  CheckCompanyResponse,
  RegisteredCompany,
} from "../../types/OnBoardDataModel";
import { useUserDetails } from "../../queries/login";
import ElasticSearchDropDown from "../../components/ElasticSearchDropDown";

export interface OnboardingCompanyDetails {
  enteredCompanyName: string;
  searchCompanyName: string;
  selectedCinName: string;
}

function OnBoardingScreen(): React.ReactElement {
  const _employee = useUserDetails();
  useEffect(() => {
    const companyName = localStorage.getItem("companyName");
    if (companyName && companyName !== "null")
      navigate("/onboarding/adminProfile");
    else {
      localStorage.setItem("companyName", "null");
      localStorage.setItem("companyId", "null");
    }
  }, []);
  const navigate = useNavigate();
  const [companyDetail, setCompanyDetail] = useState<OnboardingCompanyDetails>({
    enteredCompanyName: "",
    searchCompanyName: "",
    selectedCinName: "",
  });

  const { data: _fetchedCompanyNames } = useOnBoardingElasticSearch(
    companyDetail.searchCompanyName
  );

  const { refetch: checkCompanyNameRefetch } = useCheckCompanyName(
    companyDetail.enteredCompanyName,
    !!_employee.data?.company,
    () => navigateOnSuccess()
  );

  const fetchedCompany = _fetchedCompanyNames || [];

  const validationSchema = Yup.object().shape({
    nameOfTheCompany: Yup.string().required("Company Name is required"),
  });

  function getInitialValues() {
    return {
      nameOfTheCompany: companyDetail.enteredCompanyName || "",
      cin: companyDetail.selectedCinName || "",
    };
  }

  const handleSubmit = (values: RegisteredCompany) => {
    const companyName = { ...values };
    checkCompanyNameRefetch();
  };

  const updateCinNumber = (value: string) => {
    const selectedCompany = fetchedCompany.find(
      (item) => item.nameOfTheCompany === value
    );
    setCompanyDetail((state) => ({
      ...state,
      selectedCinName: selectedCompany?.cin || "",
    }));
  };

  const onElasticSearch = (enteredCompany: string) => {
    setCompanyDetail((state) => ({
      ...state,
      enteredCompanyName: enteredCompany,
    }));
    updateCinNumber(enteredCompany);
    updateElasticSearchList(enteredCompany);
  };

  const updateElasticSearchList = (enteredCompanyName: string) => {
    const matchedCompanyNames = fetchCompanyName().filter((companyName) =>
      companyName.includes(enteredCompanyName)
    );
    if (matchedCompanyNames.length === 0) {
      setCompanyDetail((state) => ({
        ...state,
        searchCompanyName: enteredCompanyName,
      }));
    }
  };

  const fetchCompanyName = () => {
    const companyCin: string[] = [];
    fetchedCompany.map((company) => companyCin.push(company.nameOfTheCompany));
    return companyCin;
  };
  const navigateOnSuccess = () => {
    localStorage.setItem("companyName", companyDetail.enteredCompanyName);
    navigate("/onboarding/adminProfile");
  };

  return (
    <div className="w-11/12 m-auto min-h-screen">
      <div className="px-0 text-lg font-medium py-7">
        <h1>Onboarding</h1>
      </div>
      <BCHeader className="items-baseline mb-4" bcTitle="OnBoarding" />
      <div className="w-150 container m-auto bg-white rounded-md">
        <Box className="px-10 text-lg text-center font-medium border-b py-7">
          <h6>Company Details</h6>
        </Box>
        <VStack className="w-full px-10 py-7 gap-9">
          <Formik
            enableReinitialize={true}
            initialValues={getInitialValues()}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            validationSchema={validationSchema}
          >
            {(formik) => (
              <>
                <Form autoComplete="off">
                  <VStack className="w-full gap-10 items-center">
                    <div>
                      <Label className="text-sm font-normal">
                        Name of the Company
                        <span className="text-rose-400">*</span>
                      </Label>
                      <ElasticSearchDropDown
                        options={fetchCompanyName()}
                        onInput={onElasticSearch}
                        formik={formik}
                      />
                      <p className="text-[9px] text-[#B4B6C6] my-0.5">
                        * Enter the Name appearing in the certificate of
                        incorporation.
                      </p>
                    </div>
                    <div>
                      <Label className="text-sm font-normal">CIN Number</Label>
                      <Input
                        type="text"
                        readOnly={true}
                        placeholder="CIN Number"
                        className="w-128"
                        {...formik.getFieldProps("cin")}
                      />
                    </div>
                    <HStack className="">
                      <ButtonPrimary1
                        type="reset"
                        className="text-red-500 mr-8"
                        onClick={() => {
                          redirectToHissa("landing/dashboard/capTable");
                        }}
                      >
                        Go to Dashboard
                      </ButtonPrimary1>
                      <ButtonPrimary type="submit">Continue</ButtonPrimary>
                    </HStack>
                  </VStack>
                </Form>
              </>
            )}
          </Formik>
        </VStack>
      </div>
    </div>
  );
}

export default OnBoardingScreen;
