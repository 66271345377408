import React, { useEffect, useState } from "react";
import { getNames } from "country-list";
import Box from "@mui/material/Box";
import { useFormikContext } from "formik";
import { Icon } from "@iconify/react";
import { Dialog } from "@mui/material";
import { toast } from "react-toastify";
import { Input, Label, TextArea } from "../components/shared/InputField";
import { Select } from "../components/shared/Select";
import {
  ButtonPrimary,
  HStack,
  VStack,
  Error,
  ButtonPrimary1,
} from "../components/utils";
import {
  HistoricalRoundModel,
  Investor,
  InvestorProfile,
  Secondary,
} from "../types/HistoricDataModel";
import { useGetInvestorDropdown } from "../queries/onBoarding";
import convertToBase64 from "../utils/convertToBase64";
import { PictureObject } from "../types/Employee";
import AddGroupName from "./AddGroupName";

export type AddInvestorProfileProps = {
  transactionIndex: number;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
  transactionType: "investors" | "secondaries";
};

const AddInvestorProfile = ({
  transactionIndex,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  transactionType = "investors",
}: AddInvestorProfileProps) => {
  const formik = useFormikContext<HistoricalRoundModel>();
  const { data: dropDown } = useGetInvestorDropdown();
  const [dialog, setDialog] = useState<{
    open: boolean;
    index?: number;
  }>({
    open: false,
  });
  const {
    values,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
  } = formik;
  const value =
    transactionType === "investors"
      ? (values?.investors?.[transactionIndex] as Investor)
      : (values?.secondaries?.[transactionIndex] as Secondary);

  const transactionTouch =
    transactionType === "investors"
      ? (touched?.investors?.[transactionIndex] as Investor)
      : (touched?.secondaries?.[transactionIndex] as Secondary);

  const fieldTouched = transactionTouch?.investorProfile as InvestorProfile;
  const error =
    transactionType === "investors"
      ? (errors?.investors?.[transactionIndex] as Investor)
      : (errors?.secondaries?.[transactionIndex] as Secondary);
  const err = error?.investorProfile as InvestorProfile;
  const basicDetailErrors =
    err?.legalName ||
    err?.typeOfShareholder ||
    err?.residentStatus ||
    err?.nationality;

  const [imageUploadURL, setimageUploadURL] = useState(
    value.investorProfile?.imageUrl
  );

  const [initialInvestorProfileValue, setInitialInvestorProfileValue] =
    useState<InvestorProfile>();

  useEffect(() => {
    setFieldValue(
      `${transactionType}[${transactionIndex}].investorProfile.legalName`,
      transactionType === "investors"
        ? values.investors[transactionIndex].investorName
        : values.secondaries[transactionIndex].buyer
    );
    setFieldValue(
      `${transactionType}[${transactionIndex}].investorProfile.residentStatus`,
      "India"
    );
    setFieldValue(
      `${transactionType}[${transactionIndex}].investorProfile.nationality`,
      "India"
    );
    setInitialInvestorProfileValue(
      values[transactionType][transactionIndex].investorProfile
    );
  }, []);

  async function handleEditImage(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    const allowedFileExtensions = ["png", "jpg", "jpeg"];
    if (
      allowedFileExtensions.some((extension) => file.name.endsWith(extension))
    ) {
      const base64 = await convertToBase64(file);
      setimageUploadURL(URL.createObjectURL(e.target.files[0]));
      if (
        typeof base64 === "string" &&
        typeof file.type === "string" &&
        typeof file.name === "string"
      ) {
        setFieldValue(
          `${transactionType}[${transactionIndex}].investorProfile.imageBase64String`,
          base64
        );
        setFieldValue(
          `${transactionType}[${transactionIndex}].investorProfile.imageName`,
          file.name
        );
        setFieldValue(
          `${transactionType}[${transactionIndex}].investorProfile.contenttype`,
          file.type
        );
        setFieldValue(
          `${transactionType}[${transactionIndex}].investorProfile.imageUrl`,
          imageUploadURL
        );
      }
    } else {
      toast(
        ` Invalid file type, allowed file types are ${allowedFileExtensions.join(
          ", "
        )}`,
        { type: "error" }
      );
    }
  }

  const handleOnCancel = () => {
    setFieldValue(
      `${transactionType}[${transactionIndex}].investorProfile`,
      initialInvestorProfileValue
    );
    onSecondaryAction();
  };

  const doPartialTouch = () => {
    setFieldTouched(
      `${transactionType}[${transactionIndex}].investorProfile.legalName`
    );
    setFieldTouched(
      `${transactionType}[${transactionIndex}].investorProfile.typeOfShareholder`
    );
    setFieldTouched(
      `${transactionType}[${transactionIndex}].investorProfile.nationality`
    );
    setFieldTouched(
      `${transactionType}[${transactionIndex}].investorProfile.residentStatus`
    );
  };

  const handleGroupOnInput = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "Create Group")
      setDialog({
        open: true,
      });
  };

  const getGroupName = (): string[] => {
    const listOfGroupName: string[] = ["Create Group"];
    dropDown?.groupName.forEach((groupName) => listOfGroupName.push(groupName));
    return listOfGroupName;
  };

  return (
    <>
      <Box className="px-10 text-lg font-medium border-b py-7">
        <h6>Edit Investor Details</h6>
      </Box>
      <Dialog open={dialog.open} maxWidth="md">
        (
        <AddGroupName
          transactionIndex={transactionIndex}
          transactionType={transactionType}
          onPrimaryAction={() => setDialog({ open: false })}
          onSecondaryAction={() => setDialog({ open: false })}
        />
        )
      </Dialog>
      <VStack className="w-full px-10 py-7 gap-9">
        <VStack className="w-full gap-9">
          <HStack className="gap-8 ">
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Legal Name<span className="text-rose-400">*</span>
              </Label>
              <Input
                type="text"
                placeholder="Enter Legal Name"
                className="w-96"
                {...getFieldProps(
                  `${transactionType}[${transactionIndex}].investorProfile.legalName`
                )}
              />
              {fieldTouched?.legalName && err?.legalName && (
                <Error text={err?.legalName} />
              )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Email</Label>
              <Input
                type="email"
                placeholder="Eg: example@domain.com"
                className="w-96"
                {...getFieldProps(
                  `${transactionType}[${transactionIndex}].investorProfile.emailId`
                )}
              />
            </div>
          </HStack>
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">Contact Number</Label>
              <Input
                type="number"
                placeholder="Eg:8080083400"
                className="w-96"
                {...getFieldProps(
                  `${transactionType}[${transactionIndex}].investorProfile.contactNumber`
                )}
              />
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Address</Label>
              <TextArea
                {...getFieldProps(
                  `${transactionType}[${transactionIndex}].investorProfile.registeredAddress`
                )}
              />
            </div>
          </HStack>
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Nationality<span className="text-rose-400">*</span>
              </Label>
              <Select
                options={getNames().sort()}
                {...getFieldProps(
                  `${transactionType}[${transactionIndex}].investorProfile.nationality`
                )}
              />
              {fieldTouched?.nationality && err?.nationality && (
                <Error text={err?.nationality} />
              )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Country of Residency
                <span className="text-rose-400">*</span>
              </Label>
              <Select
                options={getNames().sort()}
                {...getFieldProps(
                  `${transactionType}[${transactionIndex}].investorProfile.residentStatus`
                )}
              />
              {fieldTouched?.residentStatus && err?.residentStatus && (
                <Error text={err?.residentStatus} />
              )}
            </div>
          </HStack>

          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Type of Shareholder
                <span className="text-rose-400">*</span>
              </Label>
              <Select
                options={dropDown?.typeOfShareholder || []}
                {...getFieldProps(
                  `${transactionType}[${transactionIndex}].investorProfile.typeOfShareholder`
                )}
              />
              {fieldTouched?.typeOfShareholder && err?.typeOfShareholder && (
                <Error text={err?.typeOfShareholder} />
              )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Group Name</Label>
              <Select
                options={getGroupName()}
                onInput={handleGroupOnInput}
                {...getFieldProps(
                  `${transactionType}[${transactionIndex}].investorProfile.groupName`
                )}
              />
            </div>
          </HStack>
          <HStack className="justify-between">
            <div></div>
            <div className="flex flex-row justify-between flex-1">
              <Box>
                <Label className="text-sm font-normal">Profile Photo</Label>
              </Box>
              <Box>
                {!imageUploadURL ? (
                  <label className="px-2 py-2 rounded-lg cursor-pointer bg-slate-100">
                    <Input
                      type="file"
                      className="hidden"
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={handleEditImage}
                    />
                    Upload Image
                  </label>
                ) : (
                  <div className="relative flex flex-col justify-between">
                    <Label>
                      <Input
                        type="file"
                        accept="image/png, image/jpg, image/jpeg"
                        className="hidden"
                        onInput={handleEditImage}
                        {...formik.getFieldProps("profileImageUrl")}
                      />
                      <Icon
                        icon={"eva:edit-outline"}
                        className="absolute z-10 w-6 h-6 text-gray-400 bg-gray-100 rounded-full cursor-pointer -right-2 hover:text-black"
                      />
                    </Label>
                    <img
                      className="right-0 block w-32 h-32 space-y-3 bg-gray-400 border-2 rounded-lg"
                      src={imageUploadURL}
                      alt="avatar"
                    />
                    <Label>
                      <Icon
                        icon={"eva:close-fill"}
                        onClick={() => {
                          setimageUploadURL("");

                          setFieldValue(
                            `${transactionType}[${transactionIndex}].investorProfile.imageBase64String`,
                            ""
                          );
                          setFieldValue(
                            `${transactionType}[${transactionIndex}].investorProfile.imageName`,
                            ""
                          );
                          setFieldValue(
                            `${transactionType}[${transactionIndex}].investorProfile.contenttype`,
                            ""
                          );
                          setFieldValue(
                            `${transactionType}[${transactionIndex}].investorProfile.imageUrl`,
                            ""
                          );
                        }}
                        className="absolute z-10 w-6 h-6 text-gray-400 bg-gray-100 rounded-full cursor-pointer -right-2 -bottom-2 hover:text-black"
                      />
                    </Label>
                  </div>
                )}
              </Box>
            </div>
          </HStack>

          <HStack className="justify-end">
            <ButtonPrimary1
              type="reset"
              className="text-red-500 mr-8"
              onClick={() => {
                handleOnCancel();
                onSecondaryAction();
              }}
            >
              Cancel
            </ButtonPrimary1>
            <ButtonPrimary
              onClick={() => {
                if (basicDetailErrors) {
                  doPartialTouch();
                } else {
                  onPrimaryAction();
                }
              }}
            >
              Save
            </ButtonPrimary>
          </HStack>
        </VStack>
      </VStack>
    </>
  );
};

export default AddInvestorProfile;
