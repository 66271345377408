import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Navigate, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Dialog } from "@mui/material";
import AlertDialog from "../../components/shared/AlertDialog";
import { Input, Label } from "../../components/shared/InputField";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  redirectToHissa,
  VStack,
} from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import {
  useCreateCompanyDetails,
  useGetDiscardCompanyFromOnboarding,
  useOnBoardingUploadCompanyDetail,
} from "../../queries/onBoarding";
import { useGrantCreatedDialog } from "../../store/useDialogStore";
import ValuationOverview from "../Valuation/ValuationOverview";
import EsopSubsidiaryCompaniesOverview from "../subsidiaryCompanies/EsopSubsidiaryCompaniesOverview";
import {
  CompanyDetailsPostModel,
  DropDownModel,
} from "../../types/OnBoardDataModel";
import { formatDate } from "../../utils/date";
import { initialCompanyDetail } from "../companyProfile/initValues";

export interface FileTypeModel {
  fileBlob: string;
  fileName?: string;
  fileType?: string;
}

export function Playground() {
  const initialValues = { name: "name", age: 10 };
  const [selectedOption, setSelectedOption] = useState("");
  const { refetch, data: _companyDetail } = useOnBoardingUploadCompanyDetail();
  const [companyLogoImage, setCompanyLogoImage] = useState<FileTypeModel>({
    fileBlob: "",
  });
  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
  }>({
    open: false,
  });
  const [toggle, setToggle] = useState(false);
  const toggleClass = " transform translate-x-5";
  const actions = ["filter1", "filter2", "filter3", "filter4", "filter5"];
  const handleActions = (e: any) => {
    e.stopPropagation();
  };
  const { state: grantCreatedDialog, setState: setGrantCreatedDialog } =
    useGrantCreatedDialog();
  const companyDetail = _companyDetail;

  const validationCompanyDetailsSchema = Yup.object().shape({
    nameOfTheCompany: Yup.string().required("Company Name is required"),
    cinNumber: Yup.string().required("CIN Number is required"),
    typeOfTheCompany: Yup.string().required("Company Type is required"),
    dateOfIncorporation: Yup.string().required(
      "Date of Incorporation is required"
    ),
    companyEmailId: Yup.string()
      .email("Enter Valid Company Email is required")
      .required("Email is required"),
    companyCurrency: Yup.string().required("Company Currency is required"),
    nic: Yup.string().required("NIC is required"),
    industry: Yup.array().min(1, "Industry is Required"),
    category: Yup.array().min(1, "Category is Required"),
    founders: Yup.array()
      .of(
        Yup.object().shape({
          fullName: Yup.string().required("Founder Name is required"),
          emailId: Yup.string()
            .email("Enter Valid Company Email is required")
            .required("Valid EmailID is required"),
          designation: Yup.string().required("Designation is required"),
          nationality: Yup.string().required("Nationality is required"),
        })
      )
      .required("Required"),
    directors: Yup.array()
      .of(
        Yup.object().shape({
          fullName: Yup.string().required("Director Name is required"),
          emailId: Yup.string()
            .email("Enter Valid Company Email is required")
            .required("Valid E-mail ID is required"),
          typeOfDirector: Yup.string().required("Type of Director is required"),
          din: Yup.string().required("DIN Number is required"),
          nationality: Yup.string().required("Nationality is required"),
        })
      )
      .required("Required"),
  });

  const getInitialValue = () =>
    companyDetail
      ? {
          id: parseInt(companyDetail.id, 10),
          nameOfTheCompany: companyDetail.nameOfTheCompany || "",
          dippCertificateNumber: companyDetail.dippCertificateNumber || "",
          cinNumber: companyDetail.cinNumber || "",
          typeOfTheCompany: companyDetail.typeOfTheCompany || "",
          companyCurrency: companyDetail.companyCurrency || "",
          dateOfIncorporation: formatDate(companyDetail.dateOfIncorporation),
          corporateOfficeAddress: companyDetail.corporateOfficeAddress || "",
          companyEmailId: companyDetail.companyEmailId || "",
          companyWebsite: companyDetail.companyWebsite || "",
          companyLogoPath: companyLogoImage?.fileBlob || "",
          doingBusinessAs: companyDetail.doingBusinessAs || "",
          industry: companyDetail.industry || [],
          category: companyDetail.business || [],
          nic: companyDetail.nic || "",
          founders: companyDetail.founders,
          directors: companyDetail.directors,
        }
      : initialCompanyDetail;
  const handleCompanyDetailsSubmit = (values: CompanyDetailsPostModel) => {
    const formData = new FormData();

    formData.append("nameOfTheCompany", values.nameOfTheCompany);
    formData.append("dippCertificateNumber", values.dippCertificateNumber);
    formData.append("doingBusinessAs", values.doingBusinessAs);
    formData.append("cinNumber", values.cinNumber);
    formData.append("typeOfTheCompany", values.typeOfTheCompany);
    formData.append("dateOfIncorporation", "6/27/05");
    formData.append("corporateOfficeAddress", values.corporateOfficeAddress);
    formData.append("companyEmailId", values.companyEmailId);
    formData.append("companyWebsite", values.companyWebsite);
    formData.append("companyCurrency", values.companyCurrency);
    formData.append("nic", values.nic);
    formData.append("companyLogoPath", values.companyLogoPath);
    values.category.forEach((category, index) => {
      formData.append(`business[${index}]`, category);
    });
    values.industry.forEach((industry, index) => {
      formData.append(`industry[${index}]`, industry);
    });
    values?.founders.forEach((founder, index) => {
      formData.append(`founders[${index}].isEdit`, `${founder.isEdit}`);
      formData.append(`founders[${index}].emailId`, `${founder.emailId}`);
      formData.append(`founders[${index}].fullName`, `${founder.fullName}`);
      formData.append(
        `founders[${index}].designation`,
        `${founder.designation}`
      );
      formData.append(
        `founders[${index}].contactNumber`,
        `${founder.contactNumber}`
      );
      formData.append(`founders[${index}].address`, `${founder.address}`);
      formData.append(
        `founders[${index}].nationality`,
        `${founder.nationality}`
      );
      formData.append(
        `founders[${index}].linkedInProfile`,
        `${founder.linkedInProfile}`
      );
      formData.append(
        `founders[${index}].twitterHandle`,
        `${founder.twitterHandle}`
      );
      formData.append(`founders[${index}].isSelected`, `${founder.isSelected}`);
      formData.append(
        `founders[${index}].shouldShowDialog`,
        `${founder.shouldShowDialog}`
      );
    });
    values?.directors.forEach((director, index) => {
      formData.append(`directors[${index}].isEdit`, `${director.isEdit}`);
      formData.append(`directors[${index}].fullName`, `${director.fullName}`);
      formData.append(
        `directors[${index}].typeOfDirector`,
        `${director.typeOfDirector}`
      );
      formData.append(`directors[${index}].din`, `${director.din}`);
      formData.append(`directors[${index}].emailId`, `${director.emailId}`);
      formData.append(`directors[${index}].address`, `${director.address}`);
      formData.append(
        `directors[${index}].contactNumber`,
        `${director.contactNumber}`
      );
      formData.append(
        `directors[${index}].nationality`,
        `${director.nationality}`
      );
      formData.append(
        `directors[${index}].isSelected`,
        `${director.isSelected}`
      );
      formData.append(
        `directors[${index}].shouldShowDialog`,
        `${director.shouldShowDialog}`
      );
    });
    //   createCompanyDetails(formData, {
    //     onSuccess: () => {
    //       toast("Company Details Saved Successfully!", {
    //         type: "success",
    //         autoClose: 2000,
    //       });          },
    //     onError: (error: { response: { data: { reason: string } } }) => {
    //       toast(`${error.response.data.reason}`, {
    //         type: "error",
    //         autoClose: 2000,
    //       });
    //     },
    //   });
    // };
    const validationCompanyDetailsSchema = Yup.object().shape({
      nameOfTheCompany: Yup.string().required("Company Name is required"),
      cinNumber: Yup.string().required("CIN Number is required"),
      typeOfTheCompany: Yup.string().required("Company Type is required"),
      dateOfIncorporation: Yup.string().required(
        "Date of Incorporation is required"
      ),
      companyEmailId: Yup.string()
        .email("Enter Valid Company Email is required")
        .required("Email is required"),
      companyCurrency: Yup.string().required("Company Currency is required"),
      nic: Yup.string().required("NIC is required"),
      industry: Yup.array().min(1, "Industry is Required"),
      category: Yup.array().min(1, "Category is Required"),
      founders: Yup.array()
        .of(
          Yup.object().shape({
            fullName: Yup.string().required("Founder Name is required"),
            emailId: Yup.string()
              .email("Enter Valid Company Email is required")
              .required("Valid EmailID is required"),
            designation: Yup.string().required("Designation is required"),
            nationality: Yup.string().required("Nationality is required"),
          })
        )
        .required("Required"),
      directors: Yup.array()
        .of(
          Yup.object().shape({
            fullName: Yup.string().required("Director Name is required"),
            emailId: Yup.string()
              .email("Enter Valid Company Email is required")
              .required("Valid E-mail ID is required"),
            typeOfDirector: Yup.string().required(
              "Type of Director is required"
            ),
            din: Yup.string().required("DIN Number is required"),
            nationality: Yup.string().required("Nationality is required"),
          })
        )
        .required("Required"),
    });
  };

  return <EsopSubsidiaryCompaniesOverview />;
}

export default Playground;
