import { useState } from "react";
import { Line } from "react-chartjs-2";
import { Box, ButtonPrimary, HStack } from "../../components/utils";
import { useEmployees } from "../../queries";
import { compare, optionsGrantVsYear } from "../../shared/chart-data";
import { Employee } from "../../types/Employee";

function GranvtVSYearsExp() {
  const { data } = useEmployees();
  const filterData = (data || []).filter(
    (employee: { dateOfJoin: string }) => employee.dateOfJoin !== null
  );
  let graphData = filterData.map((employee: Employee) => {
    const date = new Date().toISOString().split("-");
    const empDate = employee.dateOfJoin.split("-");
    const experience =
      ((Number(date[0]) - Number(empDate[0])) * 12 +
        (Number(date[1]) - Number(empDate[1]))) /
      12;
    return {
      name: employee.employeeName,
      x: experience,
      y: employee.totalOptionsGranted || -1,
    };
  });
  graphData = graphData.filter((employee) => employee.y !== -1);
  graphData.sort(compare);
  let y = 0;
  let trend = [{ x: 0, y: 0 }];
  for (let i = 0; i < graphData.length; i++) {
    y = (y * i + graphData[i].y) / (i + 1);
    trend.push({
      x: graphData[i].x,
      y,
    });
  }
  trend = trend.filter(
    (value, index, self) => index === self.findIndex((t) => t.x === value.x)
  );
  const dataPoints = {
    datasets: [
      {
        showLine: false,
        label: "Employees",
        data: graphData,
        borderColor: "rgb(255, 217, 82)",
        backgroundColor: "rgba(255, 217, 82)",
        elements: {
          point: {
            radius: 4,
          },
        },
      },
      {
        pointHitRadius: 0,
        label: "Trend Line",
        data: trend,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        elements: {
          point: {
            radius: 1,
          },
        },
      },
    ],
  };
  const [expandedGraph, setExpandedGraph] = useState(false);
  const [graphText, setGraphText] = useState("+");
  return (
    <Box className="space-y-8 bg-white rounded">
      <HStack
        className={`items-center justify-between p-4 ${
          expandedGraph ? "border-b" : ""
        }`}
      >
        <Box className="w-1/3 text-lg font-medium text-gray-dark">
          Grant by Year
        </Box>
        <ButtonPrimary
          className="p-4"
          onClick={() => {
            setExpandedGraph((state) => !state);
            setGraphText((state) => {
              if (state === "+") {
                return "-";
              } else {
                return "+";
              }
            });
          }}
        >
          {graphText}
        </ButtonPrimary>
      </HStack>
      {expandedGraph && (
        <Box className="bg-white border-black border-solid rounded-lg ">
          <Line options={optionsGrantVsYear} data={dataPoints} />
        </Box>
      )}
    </Box>
  );
}

export default GranvtVSYearsExp;
