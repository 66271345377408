import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { SelectButtonGroup } from "../../components/shared/SelectButtonGroup";
import { useNextPlanState, usePlanStateChange } from "../../queries";
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  HStack,
  VStack,
} from "../../components/utils";
import { formatWithTimeZone } from "../../utils/date";
import { EsopPlan } from "../../types/EsopPlan";
import { useError } from "../../store/errorStore";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import { stringFormat } from "../../utils/string";
import AlertDialog from "../../components/shared/AlertDialog";
import StatusLabel from "./StatusLabel";

function PlanStateChange({
  plan,
  onClose = () => {},
}: {
  plan: EsopPlan;
  onClose: () => void;
}) {
  const { data: nextPlanState, isFetching } = useNextPlanState(
    plan.esopPlanId || 0
  );
  const [showDialog, setShowDialog] = useState(false);
  const [changeNote, setChangeNote] = useState("");
  useEffect(() => {
    if (nextPlanState?.message?.includes("failed")) {
      setShowDialog(true);
      errorMessage.setMessage(nextPlanState.response.data.reason);
    }
  }, [nextPlanState]);
  const [nextState, setNextState] = useState<string>();
  const { mutate: changePlanState, status } = usePlanStateChange();
  const errorMessage = useError();

  const _availableStates = nextPlanState?.availableStates || [];
  const availablestates = _availableStates.map((state) => stringFormat(state));
  function handleClick() {
    if (!plan.esopPlanId || !nextState) return;
    changePlanState(
      {
        esopPlanId: plan.esopPlanId,
        esopPlanState: nextState.replace(" ", "_"),
        dateOfTransaction: formatWithTimeZone(new Date().toDateString()),
        note: changeNote,
      },
      {
        onSuccess: () => {
          toast("State Changed Successfully!", { type: "success" });
          onClose();
        },
        onError: (err: any) => {
          errorMessage.setMessage(err.response.data.reason);
          toast(err.response.data.reason, { type: "error", autoClose: 2000 });
        },
      }
    );
  }
  return (
    <>
      <div className="p-4 text-lg font-medium border-b">
        <h6 className="justify-between flex">
          Change Plan Status{" "}
          <span onClick={() => onClose()} className="cursor-pointer">
            <Icon icon="material-symbols:close-rounded" />
          </span>
        </h6>
      </div>
      <VStack className="justify-between p-10 min-w-[500px] gap-8">
        <HStack className="justify-between">
          <p className="font-semibold">{plan.planName}</p>
          <StatusLabel state={plan.esopPlanState || ""} />
        </HStack>
        <Box className="w-40">
          <Label className="text-sm font-normal">Select Plan Status</Label>
          <Select
            options={availablestates}
            onChange={(e) => setNextState(e.target.value)}
          />
        </Box>
        <Box className="w-96">
          <Label>Note </Label>
          <Input
            type="textarea"
            value={changeNote}
            onChange={(e) => setChangeNote(e.target.value)}
          />
        </Box>

        <HStack className="flex gap-4 justify-end">
          <ButtonSecondary onClick={() => onClose()}>Cancel</ButtonSecondary>
          <ButtonPrimary
            className={`flex items-center self-end justify-center ${
              status === "success" ? "bg-green-500" : ""
            }`}
            disabled={!nextState || status !== "idle"}
            onClick={() => handleClick()}
          >
            {status === "idle" && "Confirm"}
            {status === "loading" && (
              <Icon
                className=" animate-spin"
                icon="lucide:loader-2"
                width={36}
              />
            )}
            {status === "success" && (
              <Icon icon="clarity:success-standard-line" width={36} />
            )}
          </ButtonPrimary>
        </HStack>
      </VStack>
      <AlertDialog
        open={showDialog}
        error
        message={errorMessage.message}
        primaryActionText="Retry"
        secondaryActionText="Go Back"
        onPrimaryAction={() => {
          errorMessage.reset();
          setShowDialog(false);
          onClose();
        }}
        onSecondaryAction={() => {
          errorMessage.reset();
          onClose();
          setShowDialog(false);
        }}
        onClose={() => {
          errorMessage.reset();
          onClose();
          setShowDialog(false);
        }}
      />
    </>
  );
}
export default PlanStateChange;
