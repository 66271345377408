import { Allotment } from "../../types/HistoricDataModel";

export const conversionTag: Allotment = {
  name: "conversion",
  identifier: "C",
};

export const redemptionTag: Allotment = {
  name: "redemption",
  identifier: "R",
};

export const buyBackTag: Allotment = {
  name: "buyBack",
  identifier: "BB",
};

export const bonusTag: Allotment = {
  name: "Bonus",
  identifier: "B",
};

export const nonPricedTag: Allotment = {
  name: "Non Priced",
  identifier: "NP",
};

export const partialTag: Allotment = {
  name: "Partially paid",
  identifier: "PP",
};
