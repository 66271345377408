/* eslint-disable */
import createStore from "zustand";
import { configurePersist } from "zustand-persist";

const { persist: _persist } = configurePersist({
  storage: localStorage,
  rootKey: "root",
});

const persist = _persist as any;

interface Authorization {
  authority: string;
  id?: number;
  isEditable?: boolean;
  name: string;
  permissions: Array<String>;
}

interface AuthorizationStore extends Authorization {
  setAuthorization: (
    auth: Authorization | ((auth: Authorization) => Authorization)
  ) => void;
  clearAuthorization: () => void;
}

export const useAuthorizationStore = createStore<AuthorizationStore>(
  persist(
    {
      key: "authorization",
    },
    (set: any) => ({
      authority: "",
      id: undefined,
      isEditable: false,
      name: "",
      permissions: [],
      setAuthorization: (
        auth: Authorization | ((auth: Authorization) => Authorization)
      ) => {
        if (typeof auth === "function") {
          set((_state: Authorization) => auth(_state));
        } else {
          set((_state: Authorization) => auth);
        }
      },
      clearAuthorization: () => {
        set((_state: any) => ({
          authority: "",
          id: undefined,
          isEditable: false,
          name: "",
          permissions: [],
        }));
      },
    })
  )
);
