import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Icon } from "@iconify/react";
import _ from "lodash";
import BasicMenu from "../../components/shared/Menu";
import Pagination from "../../components/shared/Pagination";
import GrantStatusLabel from "../esopOverview/StatusLabel";
import { Box, HStack, VStack } from "../../components/utils";
import { useGetOptionBuyback } from "../../queries/optionBuyback";
import { globalFilter, sort } from "../../utils/arrays";
import { optionBuybackDetail } from "../../types/OptionBuyback";
import { formatDisplayDate } from "../../utils/date";
import { formatCurrency, getCurrencyType } from "../../utils/currencyFormatter";
import { BuybackType } from "./index";
import DropdownButton from "../../components/shared/DropdownButton";
import useIsMobile from "../../utils/detectDevice";
import { EmptyTable } from "../../components/shared/EmptyTable";
import SortingComponent from "../../components/SortingVariation";

function BuybackTable() {
  const currency = getCurrencyType();
  const navigate = useNavigate();
  const {
    data: _buybackOverview,
    isPlaceholderData,
    isFetching,
  } = useGetOptionBuyback();
  const [active, setActive] = useState<BuybackType>({
    name: "Buy Back",
    value: "buyback",
    disabled: false,
  });
  let buybackOverview = useMemo(
    () => _buybackOverview || [],
    [isPlaceholderData, isFetching]
  );

  const [globalFilterText, setGlobalFilterText] = useState("");
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState(1);

  const buyBackType: BuybackType[] = [
    {
      name: "Buy Back",
      value: "buyback",
      disabled: false,
    },
    {
      name: "Exercise and Liquidate",
      value: "exercise and liquidate",
      disabled: false,
    },
    /** {
    //   name: "Exercise",
    //   value: "exercise",
    //   disabled: false,
    // },
    */
  ];
  const [sortField, setSortField] = useState<{
    field: keyof optionBuybackDetail | "" | undefined;
    ascending: boolean;
  }>({ field: "startDate", ascending: false });

  buybackOverview = useMemo(() => {
    if (!buybackOverview) return [];
    const filterResult = globalFilter(buybackOverview, globalFilterText, [
      "name",
    ]);
    const sortResult = sort(
      filterResult,
      sortField?.field,
      sortField?.ascending
    );
    return sortResult;
  }, [buybackOverview, globalFilterText, sortField]);

  useEffect(() => {
    setCurrentPage(1);
  }, [globalFilterText]);

  //pagination logic
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    const activeTabData = buybackOverview.filter(
      (item) => item.transactionType === active.value
    );
    return activeTabData.slice(firstPageIndex, lastPageIndex);
  }, [buybackOverview, currentPage, pageSize, active]);

  function buybackDetails(item: optionBuybackDetail) {
    if (item.state === "STARTED") {
      navigate(`/options/start-buy-back/${item.id}`);
    } else if (item.state === "COMPLETED") {
      if (
        item.transactionType === "exercise and liquidate" &&
        item.elState !== "DRAFT"
      ) {
        navigate(`/options/liquidation-details/${item.id}`);
        return;
      }
      navigate(`/options/complete-buy-back/${item.id}`);
    } else navigate(`/options/view-option-buy-back/${item.id}`);
  }

  const { isMobile } = useIsMobile();
  return (
    <Box className="p-6 bg-white rounded-lg">
      <Box
        aria-label="toolbar"
        className={`flex ${
          isMobile ? "flex-col mb-4" : "flex-row mb-8"
        } justify-between items-baseline`}
      >
        <HStack className="gap-4">
          <VStack className="min-w-max">
            <p className="text-lg font-medium text-gray-dark">
              {active.value === "exercise"
                ? "Option Exercise Overview"
                : active.value === "buyback"
                ? "Option Buy Back Overview"
                : active.value === "liquidation"
                ? "Option Liquidation Overview "
                : "Option Exercise and Liquidation"}
            </p>
            <p className="text-xs font-medium text-[#B5B5C3]">
              {
                currentTableData.filter(
                  (item) => item.transactionType === active.value
                ).length
              }{" "}
              results
            </p>
          </VStack>
        </HStack>
        <HStack className="gap-4 h-11 min-w-min">
          {buyBackType.map((type) => (
            <Box
              key={type.name}
              onClick={() => setActive(type)}
              className={`text-xs3 font-semibold cursor-pointer py-4   ${
                type.value === active?.value
                  ? "border-b-2 border-solid border-red-500 text-red-500"
                  : "text-gray-201 "
              }`}
            >
              {type.name}
            </Box>
          ))}
        </HStack>
      </Box>
      <Box className="max-h-full overflow-auto">
        <table
          className={` table-space ${isMobile ? "min-w-[1040px]" : "w-full"} `}
        >
          <thead className="text-xs font-medium text-[#B5B5C3] ">
            <tr className=" border-b border-[#E4E6EF] border-dashed">
              <td className="items-center py-3 hover:cursor-pointer">
                <HStack>
                  NAME
                  <SortingComponent
                    fieldName="name"
                    selectedFieldName={sortField?.field || ""}
                    isAscending={sortField?.ascending || false}
                    onChangeSort={() => {
                      setSortField({
                        field: "name",
                        ascending: !sortField?.ascending,
                      });
                    }}
                  />
                </HStack>
              </td>
              <td className="py-3 hover:cursor-pointer">
                <HStack>
                  OFFER START DATE
                  <SortingComponent
                    fieldName="startDate"
                    selectedFieldName={sortField?.field || ""}
                    isAscending={sortField?.ascending || false}
                    onChangeSort={() => {
                      setSortField({
                        field: "startDate",
                        ascending: !sortField?.ascending,
                      });
                    }}
                  />
                </HStack>
              </td>
              <td
                className="py-3 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "endDate",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                <HStack>
                  OFFER END DATE
                  <SortingComponent
                    fieldName="endDate"
                    selectedFieldName={sortField?.field || ""}
                    isAscending={sortField?.ascending || false}
                    onChangeSort={() => {
                      setSortField({
                        field: "endDate",
                        ascending: !sortField?.ascending,
                      });
                    }}
                  />
                </HStack>
              </td>
              <td
                className="py-3 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                <HStack>TARGETED VALUE</HStack>
              </td>
              <td
                className="py-3 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                <HStack> ACTUAL VALUE </HStack>
              </td>
              <td
                className="py-3 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "percentage",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                <HStack>
                  PERCENTAGE (%)
                  <SortingComponent
                    fieldName="percentage"
                    selectedFieldName={sortField?.field || ""}
                    isAscending={sortField?.ascending || false}
                    onChangeSort={() => {
                      setSortField({
                        field: "percentage",
                        ascending: !sortField?.ascending,
                      });
                    }}
                  />
                </HStack>
              </td>
              <td
                className="py-3 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "price",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                <HStack>
                  {`${
                    currentTableData[0]?.transactionType === "buyback"
                      ? "PRICE"
                      : "SALE PRICE"
                  }`}
                  <SortingComponent
                    fieldName="price"
                    selectedFieldName={sortField?.field || ""}
                    isAscending={sortField?.ascending || false}
                    onChangeSort={() => {
                      setSortField({
                        field: "price",
                        ascending: !sortField?.ascending,
                      });
                    }}
                  />
                </HStack>
              </td>
            </tr>
          </thead>
          <tbody
            className={` ${
              isPlaceholderData ? "loading" : ""
            } font-medium text-dark`}
          >
            {currentTableData &&
              currentTableData?.map((listItems, index) => (
                <tr
                  key={index}
                  className=" border-b border-[#E4E6EF] border-dashed cursor-pointer hover:bg-slate-50"
                  onClick={() => buybackDetails(listItems)}
                >
                  <td className="py-2">
                    <VStack>
                      <Box>
                        <p>{listItems.name}</p>
                        <GrantStatusLabel
                          state={listItems.state}
                        ></GrantStatusLabel>
                      </Box>
                    </VStack>
                  </td>
                  <td className="py-2">
                    <Box className="">
                      <p className="">
                        {formatDisplayDate(listItems.startDate)}
                      </p>
                    </Box>
                  </td>
                  <td className="py-2">
                    <Box className="">
                      <p className="">{formatDisplayDate(listItems.endDate)}</p>
                    </Box>
                  </td>
                  <td className="py-2">
                    <Box className="">
                      <p>
                        {formatCurrency(
                          (listItems?.buyBackOffers?.reduce(
                            (
                              accumulator: any,
                              { eligibility }: { eligibility: number }
                            ) => accumulator + eligibility,
                            0
                          ) || 0) *
                            (listItems?.transactionType === "buyback"
                              ? listItems?.price || 0
                              : listItems?.sellingPrice || 0),
                          currency
                        )}
                      </p>
                    </Box>
                  </td>
                  <td className="py-2">
                    <Box className="">
                      <p>
                        {formatCurrency(
                          (listItems?.buyBackOffers
                            ?.filter(
                              (offer) =>
                                offer.state.toLowerCase() === "completed" ||
                                offer.state.toLowerCase() === "accepted" ||
                                offer.state.toLowerCase() ===
                                  "pending_exercised"
                            )
                            .reduce(
                              (
                                accumulator: any,
                                { willingness }: { willingness: number }
                              ) => accumulator + willingness,
                              0
                            ) || 0) *
                            (listItems?.transactionType === "buyback"
                              ? listItems?.price || 0
                              : listItems?.sellingPrice || 0),
                          currency
                        )}{" "}
                      </p>
                    </Box>
                  </td>
                  <td className="py-2">
                    <Box>
                      <p className="">
                        {(listItems.percentage * 100).toPrecision(3)}
                      </p>
                    </Box>
                  </td>
                  <td className="py-2">
                    <Box>
                      <p className="">
                        {" "}
                        {formatCurrency(
                          currentTableData[0]?.transactionType === "buyback"
                            ? listItems.price || 0
                            : listItems.sellingPrice || 0,
                          currency
                        )}
                      </p>
                    </Box>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {currentTableData.length === 0 && <EmptyTable header="No Event" />}
      </Box>
      <Box className="flex justify-between mt-8">
        <BasicMenu
          defaultValue={pageSize}
          options={[5, 10, 20, 50, 100]}
          onOptionChange={(value) => setPageSize(value)}
        />
        <Pagination
          className=""
          currentPage={currentPage}
          totalCount={buybackOverview.length}
          pageSize={pageSize}
          onPageChange={(page: number) => setCurrentPage(page)}
        />
      </Box>
    </Box>
  );
}

export default BuybackTable;

export function CreateTransactiontype({
  actions,
  onAction,
  type,
}: {
  actions: BuybackType[];
  type: string;
  onAction: (action: BuybackType) => void;
}) {
  return (
    <DropdownButton
      className="items-end bg-orange-500 rounded-none"
      actions={actions}
      onAction={onAction}
      render={() => (
        <div className="rounded-none ">
          <Icon
            height={24}
            icon="ri:arrow-drop-down-line"
            className="inline "
          />
        </div>
      )}
    />
  );
}
