import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { useGetOptionBuyback } from "../../queries/optionBuyback";
import BCHeader from "../../shared/BCHeader";
import { useBuybackStore } from "../../store/optionBuyback";
import BuybackDetails from "./Buybackdetails";
import BuybackEmployeeDetails from "./BuybackEmployeeDetails";
import BuybackEmployeeList from "./BuybackOffersList";
import ExerciseDetails from "./ExerciseDetails";
import LiquidationDetails from "./LiquidationDetails";
import useIsMobile from "../../utils/detectDevice";

function ViewOptionBuybackDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const _id = id || "";
  const { data: buybackOverview, refetch, isFetching } = useGetOptionBuyback();
  const optionBuybackOverview = buybackOverview || [];
  const buybackDetail = optionBuybackOverview?.filter(
    (item) => parseInt((item?.id || 0).toString(), 10) === parseInt(_id, 10)
  );
  useEffect(() => {
    refetch();
    // eslint-disable-next-line no-useless-return
    if (isFetching) return;
    else {
      const isValidBuyBack = optionBuybackOverview?.filter(
        (optionBuyBack) =>
          parseInt((optionBuyBack.id || 0)?.toString(), 10) ===
          parseInt(_id, 10)
      );
      if (isValidBuyBack.length === 0) {
        toast(`You don't have permission to access ${_id} buyback`, {
          type: "error",
          autoClose: 3000,
        });
        navigate("/options/esopTransactions");
      }
    }
  }, [optionBuybackOverview, _id]);
  const { isMobile } = useIsMobile();
  return (
    <div className="space-y-4">
      <BCHeader
        bcTitle={"ESOP Transaction"}
        bcSubTitle={buybackDetail[0]?.name}
      />
      <div className="p-4 bg-[#fefefe] gap-4">
        {buybackDetail[0]?.transactionType === "buyback" && <BuybackDetails />}
        {(buybackDetail[0]?.transactionType === "exercise" ||
          buybackDetail[0]?.transactionType === "exercise and liquidate") && (
          <ExerciseDetails />
        )}
        {buybackDetail[0]?.transactionType === "liquidation" && (
          <LiquidationDetails />
        )}
        <BuybackEmployeeDetails />
        <div className={`w-full ${isMobile ? "overflow-x-auto" : "grow"} `}>
          <BuybackEmployeeList />
        </div>
      </div>
    </div>
  );
}

export default ViewOptionBuybackDetails;
