import { Icon } from "@iconify/react";
import {
  Form,
  Formik,
  useFormikContext,
  useFormik,
  FormikConsumer,
} from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
} from "../../components/utils";
import EsopModellingGrades from "../../constants/EsopModellingGrades";
import {
  CompensationTemplate,
  SalaryPerGrades,
} from "../../store/esopModellingStore";

type AddNewTemplateProps = {
  onPrimaryAction: (compensationTemplate: CompensationTemplate) => void;
  onClose: () => void;
  templateName?: string;
  salaryPerGrades?: SalaryPerGrades[];
  planningPeriod?: number;
  mode?: string;
};

function AddNewTemplate({
  mode,
  onClose = () => {},
  onPrimaryAction = () => {},
  templateName,
  salaryPerGrades,
  planningPeriod,
}: AddNewTemplateProps) {
  const [salaryPerGradesArray, setSalaryPerGradesArray] = useState<
    SalaryPerGrades[]
  >(salaryPerGrades || []);
  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState(0);
  const initialTemplateFields: any = {
    note: "",
    templateName: templateName || "",
    gradeName: "",
    division: "",
    role: "",
  };
  const validationSchema = Yup.object().shape({
    templateName: Yup.string().required("Template Name is required"),
    gradeName: Yup.string(),
    division: Yup.string(),
    role: Yup.string(),
  });
  const handleSubmit = (values: any) => {
    onPrimaryAction({
      note: "",
      templateName: values.templateName,
      planningPeriod: planningPeriod || 0,
      salaryPerGrades: salaryPerGradesArray,
    });
  };
  const formik = useFormik({
    initialValues: initialTemplateFields,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  function addNewSalaryPerGrade() {
    if (isEdit) {
      const templates = [...salaryPerGradesArray];
      const newTemplate: SalaryPerGrades = {
        gradeName: formik.values.gradeName,
        minSalary: 0,
        maxSalary: 0,
        optionMultiplier: 0,
        currentEmployees: 0,
        division: formik.values.division,
        role: formik.values.role,
        salary: 0,
      };
      templates.splice(editIndex, 1, newTemplate);
      setSalaryPerGradesArray(templates);
      setIsEdit(false);
    } else {
      const newTemplate: SalaryPerGrades = {
        gradeName: formik.values.gradeName,
        minSalary: 0,
        maxSalary: 0,
        optionMultiplier: 0,
        currentEmployees: 0,
        division: formik.values.division,
        role: formik.values.role,
        salary: 0,
      };
      setSalaryPerGradesArray([...salaryPerGradesArray, newTemplate]);
    }
    formik.setFieldValue("gradeName", "");
    formik.setFieldValue("division", "");
    formik.setFieldValue("role", "");
  }

  function editSalaryPerGrade(index: number) {
    formik.setFieldValue("gradeName", salaryPerGradesArray[index].gradeName);
    formik.setFieldValue("division", salaryPerGradesArray[index].division);
    formik.setFieldValue("role", salaryPerGradesArray[index].role);
    setEditIndex(index);
    setIsEdit(true);
  }

  function removeSalaryPerGrade(index: number) {
    const templates = [...salaryPerGradesArray];
    templates.splice(index, 1);
    setSalaryPerGradesArray(templates);
  }
  return (
    <VStack className="w-[750px] gap-9 p-10">
      <Box className="text-lg font-medium border-b">
        <h6 className="justify-between flex">
          {mode === "Add" ? "Create New Template" : "Edit Template"}
          <span onClick={() => onClose()} className="cursor-pointer">
            X
          </span>{" "}
        </h6>
      </Box>
      <HStack className="gap-8 ">
        <div className="flex-1">
          <Label className="text-sm font-normal">Template Name</Label>
          <Input
            type="text"
            className="w-48"
            placeholder="Template Name"
            {...formik.getFieldProps("templateName")}
          />
          {formik.touched.templateName && formik.errors.templateName && (
            <Error text={formik.errors.templateName as string} />
          )}
        </div>
      </HStack>
      <HStack className="gap-8 ">
        <div className="flex-1">
          <Label className="text-sm font-normal">Grade</Label>
          <Select
            options={EsopModellingGrades}
            {...formik.getFieldProps(`gradeName`)}
          />
          {formik.touched.gradeName && formik.errors.gradeName && (
            <Error text={formik.errors.gradeName as string} />
          )}
        </div>
        <div className="flex-1">
          <Label className="text-sm font-normal">Division/Team</Label>
          <Input
            type="text"
            placeholder="Division/Team"
            {...formik.getFieldProps(`division`)}
          />
          {formik.touched.division && formik.errors.division && (
            <Error text={formik.errors.division as string} />
          )}
        </div>
        <div className="flex-1">
          <Label className="text-sm font-normal">Role</Label>
          <Input
            type="text"
            placeholder="Role"
            {...formik.getFieldProps(`role`)}
          />
          {formik.touched.role && formik.errors.role && (
            <Error text={formik.errors.role as string} />
          )}
        </div>

        <ButtonPrimary
          className="min-w-max mx-2 w-1/5"
          onClick={() => {
            addNewSalaryPerGrade();
          }}
        >
          ADD
        </ButtonPrimary>
      </HStack>
      <VStack className="justify-between">
        <Box className="overflow-auto">
          <table className="table-space w-full">
            <thead className="text-xs font-medium text-gray-light">
              <tr className="border-b border-dashed ">
                <td className="py-3 hover:cursor-pointer">Grades</td>
                <td className="py-3 hover:cursor-pointer">Division</td>
                <td className="py-3 hover:cursor-pointer">Role</td>
                <td className="py-3 hover:cursor-pointer"></td>
                <td className="py-3 hover:cursor-pointer"></td>
              </tr>
            </thead>
            <tbody className="font-medium text-dark">
              {salaryPerGradesArray?.map((salaryPerGrade, gradeIndex) => (
                <tr
                  key={gradeIndex}
                  className=" border-b  border-dashed
                             cursor-pointer hover:bg-slate-50"
                >
                  <td className="py-2 px-2">{salaryPerGrade.gradeName}</td>
                  <td className="py-2 px-2">{salaryPerGrade.division}</td>
                  <td className="py-2 px-2">{salaryPerGrade.role}</td>
                  <td className="">
                    <span
                      onClick={() => editSalaryPerGrade(gradeIndex)}
                      className="cursor-pointer"
                    >
                      <Icon icon="material-symbols:edit" />
                    </span>
                  </td>
                  <td className="">
                    <span
                      onClick={() => removeSalaryPerGrade(gradeIndex)}
                      className="cursor-pointer"
                    >
                      <Icon icon="material-symbols:delete" />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </VStack>
      <HStack className="justify-between mt-12">
        <ButtonPrimary1
          type="reset"
          className="text-red-500 mr-8"
          onClick={() => {
            onClose();
          }}
        >
          Close
        </ButtonPrimary1>
        <ButtonPrimary
          type="submit"
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Create
        </ButtonPrimary>
      </HStack>
    </VStack>
  );
}

export default AddNewTemplate;
