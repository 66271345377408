import { useMatch } from "react-router";
import { useMemo } from "react";
import { Grant } from "../types/Grant";

export function makeRangeData(data: Grant[], _id: string): Grant[] {
  const empDetailPath = useMatch({
    path: "/options/employeeDetails/:id",
    end: true,
    caseSensitive: true,
  });

  const planViewPath = useMatch({
    path: "/options/planView/:id",
    end: true,
    caseSensitive: true,
  });
  let _grants: Grant[] = [];
  if (_id !== "" && empDetailPath?.pathname.includes("employeeDetail")) {
    _grants = data.filter((grant) => grant.employeeId.toString() === _id);
  } else if (_id !== "" && planViewPath?.pathname.includes("planView")) {
    _grants = data?.filter((grant) => grant?.planid?.toString() === _id);
  } else {
    _grants = data;
  }
  return _grants;
}
