/* eslint-disable no-undef */
import React, { useEffect, useRef } from "react";
import { ButtonCancelSecondary, HStack, VStack } from "../utils";
import {
  RangeOptions,
  useGrantFilterStore,
} from "../../store/grantsTableFilter";
import { CheckBoxWithLabel } from "./InputField";
import { createRange } from "../../utils/arrays";

export type FilterProps = {
  name: keyof RangeOptions;
  type?: string;
  className?: string;
  primaryAction: () => void;
  secondaryAction: () => void;
  render: () => JSX.Element;
  lowest: number;
  highest: number;
};
function TableRangeFilters(props: FilterProps) {
  const { render, lowest, highest, name, className } = props;
  const RenderElement = render;
  const [showModal, setShowModal] = React.useState(false);
  const grantTableFilters = useGrantFilterStore();
  const digits = Math.max(Math.floor(Math.log10(Math.abs(highest))), 0) + 1;
  const roundup = highest / digits;
  const itemRange =
    roundup > 100
      ? Math.ceil(Math.round(roundup / 100) * 100)
      : Math.ceil((roundup / 10) * 10);
  const arrayOfRange = createRange(lowest, highest, itemRange);

  const contextRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const checkIfClickOutside = (e: any) => {
      if (!(showModal && contextRef.current?.contains(e.target))) {
        setShowModal(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickOutside);
    };
  }, [showModal]);

  return (
    <div ref={contextRef} className={`flex flex-col items-end ${className}`}>
      <button
        className="inline-flex text-xs text-center rounded-lg peer"
        type="button"
        onClick={() => {
          setShowModal((state) => !state);
        }}
      >
        <RenderElement />
      </button>
      {showModal && (
        <VStack
          id="dropdown"
          className="absolute z-10 mt-8 overflow-auto bg-white border divide-y divide-gray-100 rounded shadow peer-focus:block max-h-80 "
        >
          <p className="px-4 py-1 text-stone-300">More actions</p>
          {arrayOfRange.map((item, index) => (
            <HStack key={index} className="gap-4 p-2 ">
              <CheckBoxWithLabel
                label={item}
                checked={grantTableFilters.inColumnFilter[name].includes(item)}
                labelclassname={"gap-4 px-2"}
                onChange={(e) => {
                  if (e.target.checked) {
                    grantTableFilters.setInColumnFilter(item, name);
                  } else {
                    grantTableFilters.uncheckedSlice(e, item, name);
                  }
                }}
              />
            </HStack>
          ))}
          <HStack className="gap-2 p-1">
            <ButtonCancelSecondary
              className=""
              onClick={() => setShowModal(!showModal)}
            >
              Ok
            </ButtonCancelSecondary>
            <ButtonCancelSecondary
              className=""
              onClick={() => setShowModal(!showModal)}
            >
              Cancel
            </ButtonCancelSecondary>
          </HStack>
        </VStack>
      )}
    </div>
  );
}

export default TableRangeFilters;
