import { useEffect, useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import { Dialog } from "@mui/material";
import * as _ from "lodash";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormikContext } from "formik";
import {
  Box,
  ButtonPrimary,
  Center,
  HStack,
  VStack,
  isEsopViewer,
} from "../../components/utils";
import { globalFilter, sort } from "../../utils/arrays";
import BasicMenu from "../../components/shared/Menu";
import Pagination from "../../components/shared/Pagination";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import AlertDialog from "../../components/shared/AlertDialog";

import {
  CompanyDetailsPostModel,
  DirectorDetailModel,
} from "../../types/OnBoardDataModel";
import { initialDirectorData } from "./initValues";
import AddEditDirector from "../../modals/AddEditDirector";
import Tooltip from "../../components/shared/Tooltip";

export default function DirectorTable() {
  const formik = useFormikContext<CompanyDetailsPostModel>();
  const isUserEsopViewer = isEsopViewer();
  const { values, setFieldValue } = formik;
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const [dialog, setDialog] = useState<{
    message?: string;
    open: boolean;
    director?: DirectorDetailModel;
    mode?: "Edit" | "Add" | "Delete" | "Bulk Delete";
    index?: number;
  }>({
    open: false,
    mode: "Add",
  });

  const [tempDirectorDetails, setTempDirectorDetails] =
    useState<DirectorDetailModel[]>();

  const [selectedDirectorDetail, setSelectedDirectorDetail] =
    useState<DirectorDetailModel>();
  const [globalFilterText, setGlobalFilterText] = useState("");
  const [sortField, setSortField] = useState<{
    field: keyof DirectorDetailModel | "" | undefined;
    ascending: boolean;
  }>({ field: "emailId", ascending: false });

  const [selectedItems, setSelectedItems] = useState<DirectorDetailModel[]>([]);

  let directorsList: DirectorDetailModel[] = values.directors || [];

  directorsList = useMemo(() => {
    if (!directorsList) return [];
    const filterResult = globalFilter(directorsList, globalFilterText, [
      "fullName",
      "emailId",
      "nationality",
      "typeOfDirector",
      "din",
    ]);
    const sortResult = sort(
      filterResult,
      sortField?.field,
      sortField?.ascending
    );
    return sortResult;
  }, [directorsList, globalFilterText, sortField]);

  const onSelectionChange = (director: DirectorDetailModel) => {
    setSelectedItems((directors) =>
      directors.find((individualDirector) => individualDirector === director)
        ? directors.filter(
            (individualDirector) => individualDirector !== director
          )
        : [...directors, director]
    );
  };
  function handleSecondaryAction() {
    setFieldValue("directors", tempDirectorDetails);
  }

  useEffect(() => {
    if (selectAllChecked) {
      setSelectedItems(directorsList);
    } else {
      setSelectedItems([]);
    }
  }, [directorsList, selectAllChecked]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return directorsList.slice(firstPageIndex, lastPageIndex);
  }, [directorsList, currentPage, pageSize]);

  function handleAction(
    director: DirectorDetailModel | undefined,
    action: Action
  ) {
    setTempDirectorDetails(formik.values.directors);
    const index = formik.values.directors.findIndex(
      (individualDirector) => individualDirector === director
    );

    if (action.name === "Edit" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Edit",
        director,
        message: "",
        index,
      });
    } else if (action.name === "Add" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Add",
        director,
        message: "",
      });
    } else if (action.name === "Delete" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Delete",
        director,
        message: "Do you want to delete this Director Details?",
      });
    } else if (action.name === "Bulk Delete" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Bulk Delete",
        director,
        message: "Do you want to delete selected Director Details?",
      });
    }
  }

  function deleteParticularDirector(directors?: DirectorDetailModel) {
    setFieldValue(
      "directors",
      values?.directors?.filter((director) => director !== directors)
    );
    toast("Director Deleted Successfully!", {
      type: "success",
      autoClose: 2000,
    });
    setDialog({ open: false });
  }

  function deleteAllDirectors() {
    if (selectedItems.length === 0) return;
    const directors = selectedItems;
    setFieldValue(
      "directors",
      values?.directors?.filter((director) => !directors?.includes(director))
    );
    handleAction(directors[0], {
      name: "Bulk Delete",
      disabled: false,
    });
    toast("Directors Deleted Successfully!", {
      type: "success",
      autoClose: 2000,
    });
    setDialog({ open: false });
  }

  function deleteSingleDirector() {
    if (selectedItems.length === 0) return;
    const director = values?.directors?.filter((director) =>
      selectedItems.includes(director)
    )[0];
    handleAction(director, {
      name: "Delete",
      disabled: false,
    });
    setSelectAllChecked(false);
  }

  function handleAddDirector(index: number) {
    const directors = [...values.directors];
    directors.splice(index, 0, initialDirectorData);
    setFieldValue("directors", directors);
  }

  return (
    <div className="w-full mt-10 bg-white rounded-md">
      <Box className="w-full p-4 bg-white rounded-lg min-w-min">
        <HStack aria-label="toolbar" className="justify-between mb-8">
          <VStack className="min-w-max">
            <p className="text-lg font-medium text-gray-dark">
              Director Details
            </p>
            <p className="text-xs font-medium text-gray-light">
              {values.directors?.length} Results
            </p>
          </VStack>
          <HStack className="h-11 min-w-min">
            {!isUserEsopViewer && (
              <>
                <Center className="p-2 px-3 mx-2 rounded text-slate-dark bg-slate-light">
                  <Tooltip text="Delete">
                    <Icon
                      icon="fluent:delete-28-regular"
                      color={selectedItems.length === 0 ? "gray" : "#E85936"}
                      className="cursor-pointer rounded-xl"
                      onClick={() => {
                        setDialog({ open: true });
                        handleAction(undefined, {
                          name: "Bulk Delete",
                          disabled: false,
                        });
                      }}
                      width="20"
                      height="24"
                    />
                  </Tooltip>
                </Center>
                <HStack className="items-center p-2 rounded w-72 bg-slate-light text-slate-dark">
                  <Icon icon="fe:search" width="24" className="mr-2 " />
                  <input
                    type="text"
                    className="w-full font-medium border-0 outline-none text-xs2 bg-inherit"
                    placeholder="Search"
                    value={globalFilterText}
                    onChange={(e) => {
                      setGlobalFilterText(e.target.value);
                    }}
                  ></input>
                </HStack>
                <ButtonPrimary
                  className="mx-2 min-w-max"
                  onClick={() => {
                    handleAddDirector(values.directors?.length);
                    handleAction(undefined, {
                      name: "Add",
                      disabled: false,
                    });
                  }}
                >
                  Add
                </ButtonPrimary>
              </>
            )}
            <Dialog open={dialog.open} maxWidth="md">
              {dialog.mode === "Delete" ? (
                <AlertDialog
                  onClose={() => setDialog({ open: false })}
                  open={dialog.open}
                  message={dialog.message}
                  onPrimaryAction={() =>
                    deleteParticularDirector(dialog.director)
                  }
                  onSecondaryAction={() => setDialog({ open: false })}
                />
              ) : dialog.mode === "Edit" ? (
                <AddEditDirector
                  directorIndex={dialog.index || 0}
                  director={dialog.director}
                  onPrimaryAction={() => setDialog({ open: false })}
                  onSecondaryAction={() => {
                    handleSecondaryAction();
                    setDialog({ open: false });
                  }}
                  mode="Edit"
                />
              ) : dialog.mode === "Add" ? (
                <AddEditDirector
                  directorIndex={values.directors.length - 1}
                  onPrimaryAction={() => setDialog({ open: false })}
                  onSecondaryAction={() => {
                    handleSecondaryAction();
                    setDialog({ open: false });
                  }}
                  mode="Add"
                />
              ) : (
                <AlertDialog
                  onClose={() => setDialog({ open: false })}
                  open={dialog.open}
                  message={dialog.message}
                  onPrimaryAction={() => deleteAllDirectors()}
                  onSecondaryAction={() => setDialog({ open: false })}
                />
              )}
            </Dialog>
          </HStack>
        </HStack>
        <Box className="max-h-full overflow-auto">
          <table className="w-full table-space">
            <thead className="text-xs font-medium text-gray-light">
              <tr className="">
                <td className="py-3">
                  <input
                    type="checkbox"
                    className="cursor-pointer accent-orange-501 outline-hidden"
                    checked={selectAllChecked}
                    onChange={(e) => setSelectAllChecked(!selectAllChecked)}
                  ></input>
                </td>

                <>
                  <td
                    className="py-3 hover:cursor-pointer"
                    onClick={() =>
                      setSortField({
                        field: "fullName",
                        ascending: !sortField?.ascending,
                      })
                    }
                  >
                    Director Name
                  </td>
                </>

                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "din",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  DIN Number
                </td>
                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "typeOfDirector",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Type of Director
                </td>
                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "emailId",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Email Id
                </td>

                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "nationality",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Nationality
                </td>
                <td className="py-3"></td>
              </tr>
            </thead>
            {values.directors && (
              <tbody className={""}>
                {currentTableData &&
                  currentTableData?.map((director, index) => (
                    <tr
                      key={director.emailId + index}
                      className="border-t border-dashed "
                    >
                      <td className="py-5 align-top">
                        <Box>
                          <input
                            type="checkbox"
                            className="cursor-pointer accent-orange-501 outline-hidden"
                            checked={selectedItems.indexOf(director) !== -1}
                            onChange={(e) => onSelectionChange(director)}
                          ></input>
                        </Box>
                      </td>

                      <>
                        <td className="py-4 align-top ">
                          <HStack className="w-20 ">
                            <Box>
                              <p
                                className={` text-xs font-medium text-gray-dark `}
                              >
                                {director.fullName}
                              </p>
                            </Box>
                          </HStack>
                        </td>
                      </>
                      <td className="py-4 align-top">
                        <Box className="">
                          <HStack>
                            <p
                              className={` w-12 text-xs font-medium text-gray-dark`}
                            >
                              {director.din}
                            </p>
                          </HStack>
                        </Box>
                      </td>
                      <td className="py-4 align-top">
                        <Box className="">
                          <HStack>
                            <p
                              className={` w-12 text-xs font-medium text-gray-dark`}
                            >
                              {director.typeOfDirector}
                            </p>
                          </HStack>
                        </Box>
                      </td>
                      <td className="py-4 align-top">
                        <Box className="">
                          <HStack>
                            <p
                              className={` w-32 text-xs font-medium text-gray-dark`}
                            >
                              {director.emailId}
                            </p>
                          </HStack>
                        </Box>
                      </td>
                      <td className="py-4 align-top">
                        <Box className="">
                          <p className={` text-xs font-normal text-gray-dark`}>
                            {director.nationality}
                          </p>
                        </Box>
                      </td>
                      <td className="px-2 py-4 align-top">
                        <CTADropdown
                          actions={[
                            {
                              name: "Edit",
                              disabled: isUserEsopViewer,
                            },
                            {
                              name: "Delete",
                              disabled: isUserEsopViewer,
                            },
                          ]}
                          onAction={(action) => handleAction(director, action)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </table>
          {values.directors?.length <= 0 && (
            <div className="text-center text-black">
              <h1>No Director Data Found. Please Add the Director</h1>
            </div>
          )}
        </Box>
        <Box className="flex justify-between mt-8">
          <BasicMenu
            defaultValue={pageSize}
            options={[5, 10, 20, 50, 100]}
            onOptionChange={(value) => setPageSize(value)}
          />
          <Pagination
            className=""
            currentPage={currentPage}
            totalCount={directorsList.length}
            pageSize={pageSize}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </Box>
      </Box>
    </div>
  );
}
