import { Icon } from "@iconify/react";
import Dialog from "@mui/material/Dialog";
import React, { useMemo, useState } from "react";
import { toast } from "react-toastify";
import AlertDialog from "../../components/shared/AlertDialog";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import Tooltip from "../../components/shared/Tooltip";
import { Box, ButtonPrimary, HStack, VStack } from "../../components/utils";
import {
  useDeleteEsopSubsidiaryCompany,
  useEsopSubsidiaryCompaniesDetail,
} from "../../queries/EsopSubsidiaryCompany";
import { EsopSubsidiaryCompany } from "../../types/EsopSubsidiaryCompany";
import { globalFilter } from "../../utils/arrays";
import { limitString } from "../../utils/string";
import AddOrEditEsopSubsidiaryCompany from "./AddOrEditEsopSubsidiaryCompany";

function EsopSubsidiaryCompaniesTable() {
  const { data: _companiesData } = useEsopSubsidiaryCompaniesDetail();
  const [globalFilterText, setGlobalFilterText] = useState("");
  const [dialog, setDialog] = useState<{
    open: boolean;
    data?: EsopSubsidiaryCompany;
    mode: "Add" | "Edit" | "Delete";
  }>({
    open: false,
    data: undefined,
    mode: "Add",
  });
  const companiesData = _companiesData?.sort() || [];

  function handleAction(company: EsopSubsidiaryCompany, action: Action) {
    if (action.name === "Edit Company") {
      setDialog({
        open: true,
        data: company,
        mode: "Edit",
      });
    } else if (action.name === "Delete Company") {
      setDialog({ open: true, data: company, mode: "Delete" });
    }
  }

  const companies = React.useMemo(() => {
    if (!companiesData) return [];
    const filterResult = globalFilter(companiesData, globalFilterText, [
      "companyName",
    ]);
    return filterResult;
  }, [globalFilterText, companiesData]);

  const { mutate: deleteCompany } = useDeleteEsopSubsidiaryCompany();

  function handleDelete() {
    const id = dialog.data?.id || 0;
    deleteCompany(id, {
      onSuccess: (res) => {
        toast(res, {
          type: "success",
          autoClose: 2000,
        });
        setDialog({ open: false, mode: "Delete" });
      },
      onError: (err: any) => {
        toast(err.response.data.message, { type: "error", autoClose: 2000 });
        setDialog({ open: false, mode: "Delete" });
      },
    });
  }

  return (
    <Box className="w-full p-4 bg-white rounded-lg ">
      {dialog.mode === "Delete" ? (
        <Dialog open={dialog.open}>
          <AlertDialog
            open={dialog.open}
            message={"Are you sure you want to delete this subsidiary company"}
            onPrimaryAction={() => handleDelete()}
            onSecondaryAction={() => setDialog({ open: false, mode: "Delete" })}
          />
        </Dialog>
      ) : (
        <Dialog open={dialog.open} maxWidth="md">
          <div className="w-[900px]">
            <AddOrEditEsopSubsidiaryCompany
              onClose={() => setDialog({ open: false, mode: "Add" })}
              data={dialog.data}
              mode={dialog.mode}
            />
          </div>
        </Dialog>
      )}
      <HStack aria-label="toolbar" className="justify-between mb-8">
        <VStack className="min-w-max">
          <p className="text-lg font-medium text-gray-dark">Company Details</p>
          <p className="text-xs font-medium text-gray-light">
            {companies.length} results
          </p>
        </VStack>
        <HStack>
          <HStack className="items-center p-2 rounded w-72 bg-slate-light text-slate-dark">
            <Icon icon="fe:search" width="24" className="mr-2 " />
            <input
              type="text"
              className="w-full text-xs font-normal border-0 outline-none bg-inherit"
              placeholder="Search by Company Name"
              value={globalFilterText}
              onChange={(e) => {
                setGlobalFilterText(e.target.value);
              }}
            ></input>
          </HStack>
          <ButtonPrimary
            className="flex justify-end flex-reverse-row"
            onClick={() => {
              setDialog({ open: true, mode: "Add" });
            }}
          >
            + Add Subsidiary
          </ButtonPrimary>
        </HStack>
      </HStack>
      <table className="w-full table-space ">
        <thead className="text-xs font-medium text-gray-light">
          <tr className="">
            <td className="py-3">Company</td>
            <td className="py-3">CIN</td>
            <td className="py-3">Company Type</td>
            <td className="py-3">Company Currency</td>
            <td className="py-3">Grant Letter Template</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {companies &&
            companies?.map((company) => (
              <tr key={company.id}>
                <td className="py-4 align-top ">
                  <HStack className="">
                    <Box className="">
                      <Tooltip text={company.companyName}>
                        <p className="text-xs font-medium text-gray-dark">
                          {limitString(company.companyName, 40)}
                        </p>
                      </Tooltip>
                    </Box>
                  </HStack>
                </td>
                <td className="py-4 align-top ">
                  <HStack className="">
                    <Box className="">
                      <p className="text-xs font-medium text-gray-dark">
                        {company.cin}
                      </p>
                    </Box>
                  </HStack>
                </td>
                <td className="py-4 align-top ">
                  <HStack className="">
                    <Box className="">
                      <p className="text-xs font-medium text-gray-dark">
                        {company.companyType}
                      </p>
                    </Box>
                  </HStack>
                </td>
                <td className="py-4 align-top ">
                  <HStack className="">
                    <Box className="">
                      <p className="text-xs font-medium text-gray-dark">
                        {company.companyCurrency}
                      </p>
                    </Box>
                  </HStack>
                </td>
                <td className="py-4 align-top ">
                  <HStack className="">
                    <Box className="">
                      {company.grantLetterTemplate !== null ? (
                        <p className="text-xs font-medium text-gray-dark">
                          ESOP_Grant_Letter
                        </p>
                      ) : (
                        ""
                      )}
                    </Box>
                  </HStack>
                </td>
                <td className="px-2 py-4 align-top">
                  <CTADropdown
                    actions={[
                      {
                        name: "Edit Company",
                      },
                      {
                        name: "Delete Company",
                      },
                    ]}
                    onAction={(action) => handleAction(company, action)}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </Box>
  );
}

export default EsopSubsidiaryCompaniesTable;
