import React, { useEffect, useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import * as _ from "lodash";
import { useFormikContext } from "formik";
import { toast } from "react-toastify";
import { formatWithTimeZone, formatDate } from "../../utils/date";
import {
  Box,
  ButtonPrimary,
  Center,
  HStack,
  VStack,
  Error,
} from "../../components/utils";
import { Input } from "../../components/shared/InputField";
import { globalFilter, sort } from "../../utils/arrays";
import BasicMenu from "../../components/shared/Menu";
import Pagination from "../../components/shared/Pagination";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import {
  HistoricalRoundModel,
  SplitDetail,
} from "../../types/HistoricDataModel";
import { Select } from "../../components/shared/Select";

export interface InputFieldFormModel {
  splitNumber?: number;
  securityType?: string;
  date?: string;
  splitNumberTouched?: boolean;
  securityTypeTouched?: boolean;
  dateTouched?: boolean;
}

export default function SplitRatioTable() {
  const formik = useFormikContext<HistoricalRoundModel>();
  const { values, setFieldValue } = formik;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [globalFilterText, setGlobalFilterText] = useState("");
  const [inputField, setInputField] = useState<InputFieldFormModel>({
    splitNumberTouched: false,
    securityTypeTouched: false,
    dateTouched: false,
  });
  const [index, setIndex] = useState<number>(values.splitDetails.length);

  const [sortField, setSortField] = useState<{
    field: keyof SplitDetail | "" | undefined;
    ascending: boolean;
  }>({ field: "splitNumber", ascending: false });

  const [selectedItems, setSelectedItems] = useState<SplitDetail[]>([]);
  let splitList: SplitDetail[] = values.splitDetails || [];

  splitList = useMemo(() => {
    if (!splitList) return [];
    const filterResult = globalFilter(splitList, globalFilterText, [
      "splitNumber",
      "securityType",
      "date",
    ]);
    const sortResult = sort(
      filterResult,
      sortField?.field,
      sortField?.ascending
    );
    return sortResult;
  }, [splitList, globalFilterText, sortField]);

  const onSelectionChange = (item: SplitDetail) => {
    setSelectedItems((items) =>
      items.find((i) => i === item)
        ? items.filter((i) => i !== item)
        : [...items, item]
    );
  };

  const handleSave = (split: SplitDetail) => {
    if (split.date) {
      setFieldValue(
        `splitDetails[${index}].date`,
        formatWithTimeZone(split.date)
      );
    }
    setFieldValue(`splitDetails[${index}].securityType`, split.securityType);
    setFieldValue(`splitDetails[${index}].splitNumber`, split.splitNumber);
  };

  useEffect(() => {
    if (selectAllChecked) {
      setSelectedItems(splitList);
    } else {
      setSelectedItems([]);
    }
  }, [splitList, selectAllChecked]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return splitList.slice(firstPageIndex, lastPageIndex);
  }, [splitList, currentPage, pageSize]);

  function handleAction(action: Action, split: SplitDetail) {
    if (action.name === "Edit" && !action.disabled) {
      setIndex(
        values.splitDetails.findIndex((splitDetails) => split === splitDetails)
      );
      setInputField({
        splitNumber: split?.splitNumber,
        securityType: split?.securityType,
        date: split?.date,
      });
      setShowInline(true);
    } else if (action.name === "Delete" && !action.disabled) {
      deleteSplit(split);
    }
  }

  const deleteAllSelectedSplits = () => {
    setFieldValue(
      "splitDetails",
      values?.splitDetails?.filter((split) => !selectedItems.includes(split))
    );
    toast("Selected Split Deleted Successfully!", {
      type: "success",
      autoClose: 2000,
    });
    setSelectAllChecked(false);
  };

  function deleteSplit(split: SplitDetail) {
    setFieldValue(
      "splitDetails",
      values?.splitDetails?.filter(
        (individualSplit) => individualSplit !== split
      )
    );
    toast("Split Deleted Successfully!", {
      type: "success",
      autoClose: 2000,
    });
    setShowInline(false);
  }

  const [showInline, setShowInline] = useState<boolean>(false);

  return (
    <div className="w-full bg-white rounded-md mt-10">
      <Box className="w-full p-4 bg-white rounded-lg min-w-min">
        <HStack aria-label="toolbar" className="justify-between mb-8">
          <VStack className="min-w-max">
            <p className="text-lg font-medium text-gray-dark">Split Ratio</p>
            <p className="text-xs font-medium text-gray-light">
              {values.splitDetails.length} Results
            </p>
          </VStack>
          <HStack className="h-11 min-w-min">
            {selectedItems.length > 0 && (
              <Center className="p-2 px-3 mx-2 rounded text-slate-dark bg-slate-light">
                <Icon
                  icon="fluent:delete-28-regular"
                  color="#E85936"
                  className="rounded-xl"
                  onClick={deleteAllSelectedSplits}
                  width="20"
                  height="24"
                />
              </Center>
            )}
            <HStack className="items-center p-2 rounded w-72 bg-slate-light text-slate-dark mx-2">
              <Icon icon="fe:search" width="24" className="mr-2 " />
              <input
                type="text"
                className="w-full font-medium border-0 outline-none text-xs2 bg-inherit"
                placeholder="Search"
                value={globalFilterText}
                onChange={(e) => {
                  setGlobalFilterText(e.target.value);
                }}
              ></input>
            </HStack>
            <ButtonPrimary
              className="min-w-max"
              onClick={() => {
                setIndex(values.splitDetails.length);
                setInputField({
                  splitNumber: undefined,
                  securityType: undefined,
                  date: undefined,
                });

                setShowInline(true);
              }}
            >
              Add
            </ButtonPrimary>
          </HStack>
        </HStack>
        <Box className="overflow-auto max-h-full">
          <table className="w-full table-space">
            <thead className="text-xs font-medium text-gray-light">
              <tr>
                <td className="py-3 w-12 mr-4">
                  <input
                    type="checkbox"
                    className="accent-orange-501 w-12 outline-hidden"
                    checked={selectAllChecked}
                    onChange={(e) => setSelectAllChecked(!selectAllChecked)}
                  ></input>
                </td>
                <td
                  className="py-3 w-72 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "splitNumber",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Split Ratio
                </td>
                <td
                  className="py-3 w-72 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "securityType",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Security
                </td>
                <td
                  className="py-3 hover:cursor-pointer w-72"
                  onClick={() =>
                    setSortField({
                      field: "date",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Date
                </td>
                <td className="py-3 w-56"></td>
              </tr>
            </thead>
            <tbody className={""}>
              {values.splitDetails && showInline && (
                <tr>
                  <td className="py-3 mr-4">
                    <input
                      type="checkbox"
                      className="accent-orange-501 w-12 outline-hidden"
                      checked={selectAllChecked}
                      hidden
                      onChange={(e) => setSelectAllChecked(!selectAllChecked)}
                    ></input>
                  </td>
                  <td className="py-3  w-72">
                    <Box>
                      <Input
                        type="number"
                        placeholder="Enter Split Ratio"
                        className={"w-72"}
                        value={inputField.splitNumber}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setInputField((state) => ({
                            ...state,
                            splitNumber: parseInt(e.target.value, 10),
                          }))
                        }
                      />
                      {!inputField.splitNumber &&
                        inputField.splitNumberTouched && (
                          <Error text={"Split Number is Required"} />
                        )}
                    </Box>
                  </td>

                  <td className="py-3 w-72">
                    <Select
                      options={values.securitiesDropdown}
                      className={"w-72"}
                      value={inputField.securityType}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        setInputField((state) => ({
                          ...state,
                          securityType: e.target.value,
                        }))
                      }
                    />
                    {!inputField.securityType &&
                      inputField.securityTypeTouched && (
                        <Error text={"Security Type is Required"} />
                      )}
                  </td>
                  <td className="py-3 w-72">
                    <Input
                      type="date"
                      placeholder="Date"
                      className={"w-72"}
                      value={inputField.date}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setInputField((state) => ({
                          ...state,
                          date: e.target.value,
                        }))
                      }
                    />
                    {!inputField.date && inputField.dateTouched && (
                      <Error text={"Date is Required"} />
                    )}
                  </td>
                  <td className="w-56">
                    <HStack>
                      <Center className="p-2 px-3 mx-2 rounded text-slate-dark bg-slate-light">
                        <Icon
                          icon="fluent:delete-28-regular"
                          color="#E85936"
                          type="reset"
                          className="rounded-xl"
                          onClick={() => {
                            setShowInline(false);
                          }}
                          width="20"
                          height="24"
                        />
                      </Center>
                      <ButtonPrimary
                        onClick={() => {
                          if (
                            inputField.date &&
                            inputField.dateTouched &&
                            inputField.securityType
                          ) {
                            handleSave(inputField);
                            setShowInline(false);
                          } else {
                            setInputField((state) => ({
                              ...state,
                              splitNumberTouched: true,
                              dateTouched: true,
                              securityTypeTouched: true,
                            }));
                          }
                        }}
                      >
                        Save
                      </ButtonPrimary>
                    </HStack>
                  </td>
                </tr>
              )}
              {values.splitDetails &&
                currentTableData.map((splitDetail) => (
                  <tr
                    key={`${index}${splitDetail.date}`}
                    className="border-t border-dashed "
                  >
                    <td className="py-3 mr-4">
                      <Box>
                        <input
                          type="checkbox"
                          className="accent-orange-501  w-12 outline-hidden"
                          checked={selectedItems.indexOf(splitDetail) !== -1}
                          onChange={(e) => onSelectionChange(splitDetail)}
                        ></input>
                      </Box>
                    </td>
                    <td className="py-4 w-72 align-top ">
                      <HStack>
                        <Box>
                          <p
                            className={`w-72 text-xs font-medium text-gray-dark `}
                          >
                            {splitDetail.splitNumber}
                          </p>
                        </Box>
                      </HStack>
                    </td>
                    <td className="py-4 w-72 align-top ">
                      <HStack className="w-72 ">
                        <Box className="">
                          <p
                            className={`w-72 text-xs font-medium text-gray-dark`}
                          >
                            {splitDetail.securityType}
                          </p>
                        </Box>
                      </HStack>
                    </td>
                    <td className="py-4 align-top  w-72">
                      <Box>
                        <p
                          className={`w-72 text-xs font-medium text-gray-dark `}
                        >
                          {formatDate(splitDetail.date)}
                        </p>
                      </Box>
                    </td>
                    <td className="py-3 w-72"></td>
                    <td className="px-2 py-4 w-72 align-top">
                      <CTADropdown
                        actions={[
                          {
                            name: "Edit",
                            disabled: false,
                          },
                          {
                            name: "Delete",
                            disabled: false,
                          },
                        ]}
                        onAction={(action) => handleAction(action, splitDetail)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {values.splitDetails.length <= 0 && !showInline && (
            <div className="text-black text-center">
              <h1>No Splits Found, Please Add the Splits</h1>
            </div>
          )}
        </Box>
        <Box className="flex justify-between mt-8">
          <BasicMenu
            defaultValue={pageSize}
            options={[5, 10, 20, 50, 100]}
            onOptionChange={(value) => setPageSize(value)}
          />
          <Pagination
            className=""
            currentPage={currentPage}
            totalCount={splitList.length}
            pageSize={pageSize}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </Box>
      </Box>
    </div>
  );
}
