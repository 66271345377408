import { Valuation } from "../types/ValuationType";
import hissaApi from "./hissaApi";

export async function getValuationDetails(): Promise<Valuation[]> {
  return hissaApi.get("/valuation/getvaluation").then((res) => res.data);
}

export async function addValuation(valuation: Valuation): Promise<Valuation> {
  return hissaApi
    .post("/valuation/addValuation", valuation)
    .then((res) => res.data)
    .catch((err) => err);
}

export async function editValuation(valuation: Valuation): Promise<Valuation> {
  return hissaApi
    .put(`/valuation/editValuation/${valuation.id}`, valuation)
    .then((res) => res.data)
    .catch((err) => err);
}

export async function getAllRoundNames(): Promise<string[]> {
  return hissaApi.get("/valuation/getAllRoundNames").then((res) => res.data);
}
