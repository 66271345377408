import { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import Footer from "../components/shared/Footer";
import { Box, ButtonPrimary, redirectToHissa } from "../components/utils";
import { useAuthorizationStore } from "../store";
import HeaderNavbarMobile from "./MobileLayout/HeaderNavbarMobile";
import NavbarMobile from "./MobileLayout/NavbarMobile";
import Navbar from "./Navbar";
import SideNavbar from "./SideNavbar";
import useIsMobile from "../utils/detectDevice";
import SwitchProduct from "./SwitchProduct";

interface NavBarRoute {
  title: string;
  path: string;
  shouldBeDisplayed: boolean;
  subRoute?: string;
}

function DashboardLayout() {
  const navigate = useNavigate();
  const allRoutes = {
    options: [
      {
        title: "Overview",
        path: "/options/esopOverview",
        shouldBeDisplayed: true,
        subRoute: "add-edit-pool",
      },
      {
        title: "ESOP Plan",
        path: "/options/allPlans",
        shouldBeDisplayed: true,
        subRoute: "planView",
      },
      {
        title: "All Option Holders",
        path: "/options/allEmployees",
        shouldBeDisplayed: true,
        subRoute: "employeeDetails",
      },
      {
        title: "Vesting Schedules",
        path: "/options/vestingSchedules",
        shouldBeDisplayed: true,
        subRoute: "vestingSchedule",
      },
      {
        title: "ESOP Transaction",
        path: "/options/esopTransactions",
        shouldBeDisplayed: true,
        subRoute:
          "view-option-buy-back" ||
          "create-option-buyback" ||
          "edit-options-buyback",
      },
      {
        title: "Playground",
        path: "/options/playground",
        shouldBeDisplayed: false,
        subRoute: "playGround",
      },
    ],
    settings: [
      {
        title: "Company Profile",
        path: "/settings/companyProfile",
        shouldBeDisplayed: true,
      },
      {
        title: "Site Settings",
        path: "/settings/siteSettings",
        shouldBeDisplayed: true,
      },
      {
        title: "FAQ Settings",
        path: "/settings/faq",
        shouldBeDisplayed: true,
      },
      {
        title: "Valuation Reports",
        path: "/settings/valuationReports",
        shouldBeDisplayed: true,
      },
    ],
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogOpen = (value: boolean) => {
    setDialogOpen(value);
  };
  const { authority, permissions } = useAuthorizationStore();
  const location = useLocation();
  const [navBarRoutes, setNavBarRoutes] = useState([]);
  useEffect(() => {
    const currentPath = location.pathname.split("/")[1];
    let navBarRoutes: NavBarRoute[] = [];
    if (currentPath === "options") {
      navBarRoutes = allRoutes.options;
    } else if (currentPath === "settings") {
      navBarRoutes = allRoutes.settings;
    }

    if (
      !(
        authority === "ROLE_ADMIN" ||
        authority === "ROLE_ESOP_ADMIN" ||
        authority === "ROLE_ESOP_VIEWER"
      )
    ) {
      navBarRoutes = navBarRoutes.filter((item) => {
        if (item.title === "ESOP Transaction") {
          item.shouldBeDisplayed = false;
        }
        return item;
      });
    }
    setNavBarRoutes(navBarRoutes as []);
    if (navBarRoutes.length > 0 && location.pathname.endsWith(currentPath)) {
      navigate(navBarRoutes[0].path);
    }
  }, [location, authority]);

  const { isMobile } = useIsMobile();
  useEffect(() => {
    const scroller = document.querySelector("#outlet");
    scroller?.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      {!isMobile ? (
        <div className="flex">
          <div className="relative w-28  h-screen overflow-hidden">
            <SideNavbar setDialogOpen={handleDialogOpen} />
          </div>
          {dialogOpen && (
            <div className=" absolute bg-black w-96">
              <SwitchProduct setDialogOpen={handleDialogOpen}></SwitchProduct>
            </div>
          )}
          <div className="flex-1 h-screen overflow-y-auto" id="outlet">
            <div className="relative w-full min-h-screen">
              <div className={`sticky top-0 z-20  drop-shadow-sm`}>
                <Navbar routes={navBarRoutes} />
              </div>
              <Box className="max-w-screen-xl p-4 pb-32 mx-auto">
                <Outlet />
              </Box>
              <div></div>
              <div className="absolute bottom-0 w-full mt-6 ">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex">
          <div className="flex-1 h-screen overflow-y-auto" id="outlet">
            <div className="relative w-full min-h-screen">
              <div
                className={`sticky top-0 z-10 py-2 drop-shadow-sm bg-gray-light rounded-b`}
              >
                <HeaderNavbarMobile />
              </div>
              <div className="fixed bottom-0 z-20 w-full text-xs rounded-t-md bg-gray-light">
                <Navbar routes={navBarRoutes} />
              </div>
              <Box className="max-w-screen-xl p-4 pb-32 mx-auto">
                <Outlet />
              </Box>
              <div></div>
              <div
                className={`absolute w-full ${
                  isMobile
                    ? "bottom-14 mx-4 text-xs items-center flex flex-row"
                    : "mt-6 bottom-0"
                }`}
              >
                <Footer />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default DashboardLayout;
