import { QueryFunctionContext } from "react-query";
import { Result } from "../types/Exercise";
import { VestableOptionsDetails, VestReq, PlanVesting } from "../types/Vest";
import { PauseVestingDto, VestingEventReq } from "../types/VestingTemplate";
import hissaApi from "./hissaApi";
import optionsApi from "./optionsApi";
import { CustomResponse } from "../types/Grant";

export async function getVestableOptionsDetails(
  context: QueryFunctionContext
): Promise<VestableOptionsDetails> {
  return hissaApi
    .get(`employee/vest/?id=${context.queryKey[1]}`)
    .then((res) => res.data);
}

export async function doVestRequest(
  vestRequestDto: VestReq
): Promise<VestableOptionsDetails> {
  return hissaApi.post(`employee/vest`, vestRequestDto).then((res) => res.data);
}

export async function getVestRequestDetails(
  context: QueryFunctionContext
): Promise<VestableOptionsDetails> {
  return hissaApi
    .get(`employee/vest/approve`, { params: { id: context.queryKey[1] } })
    .then((res) => res.data);
}

export async function approveVesting(approveVestReq: VestReq): Promise<Result> {
  return hissaApi
    .post(`employee/vest/approve`, approveVestReq)
    .then((res) => res.data);
}

export async function getAllVestings(): Promise<PlanVesting[]> {
  return hissaApi.get(`esop/vestings`).then((res) => res.data);
}

export async function getEventDetails(
  eventName: string
): Promise<VestingEventReq> {
  return hissaApi
    .get(`esop/vesteventpopup?eventName=${eventName}`)
    .then((res) => res.data);
}

export async function getEventDetailsForApproval(
  eventName: string
): Promise<VestingEventReq> {
  return hissaApi
    .get(`esop/vestevents/approve?eventName=${eventName}`)
    .then((res) => res.data);
}

export async function eventApproval(
  vestEventReq: VestingEventReq
): Promise<Result> {
  return hissaApi.post(`esop/vestevents`, vestEventReq).then((res) => res.data);
}

export async function approveEvent(
  vestEventReq: VestingEventReq
): Promise<Result> {
  return hissaApi
    .post(`esop/vestevents/approve`, vestEventReq)
    .then((res) => res.data);
}

export async function pauseVestingForEmployee(
  pausedVestingDto: PauseVestingDto
): Promise<string> {
  return hissaApi
    .post(`vesting/pause/employee`, pausedVestingDto)
    .then((res) => res.data);
}
