import { useNavigate } from "react-router";
import { VStack, HStack } from "../components/utils";

function SomeOtherPage() {
  const navigate = useNavigate();
  return (
    <VStack className="p-4 m-4 bg-blue-200 rounded-lg">
      <button
        className="w-1/6 bg-gray-200 rounded-full"
        onClick={() => navigate("/dashboard/app")}
      >
        Home
      </button>
      {[1, 2, 3, 4, 5].map((i, key) => (
        <HStack
          key={key}
          className="justify-between p-4 m-4 bg-white rounded-lg"
        >
          {[1, 2, 3, 4, 5].map((j, key) => (
            <h6
              key={key}
              className="p-4 m-4 font-bold text-teal-200 bg-teal-600 rounded-lg"
            >
              ABCD {i} {j}
            </h6>
          ))}
        </HStack>
      ))}
      <button className="bg-red-400" onClick={() => {}}>
        UPDATE DATA
      </button>
    </VStack>
  );
}

export default SomeOtherPage;
