import create from "zustand";

export type GraphFilterState = {
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  reset: () => void;
};
const initialState = {
  startDate: new Date(2000, 1, 1),
  endDate: new Date(2099, 1, 1),
};
const useStore = create<GraphFilterState>((set) => ({
  ...initialState,
  setStartDate: (date) => {
    set((state) => ({ ...state, startDate: date }));
  },
  setEndDate: (date) => {
    set((state) => ({ ...state, endDate: date }));
  },
  reset: () => {
    set(() => initialState);
  },
}));

export const useGraphFilter = useStore;
