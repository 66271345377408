import React from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router";
import { Box, HStack, VStack, isEsopViewer } from "../../components/utils";
import BuybackTable from "./OptionBuybackTable";
import DropdownButton from "../../components/shared/DropdownButton";
import { Action } from "../../components/shared/Dropdown";
import SummaryCard, { summaryTypes } from "../../components/shared/SummaryCard";
import useIsMobile from "../../utils/detectDevice";
import { useGetOptionBuyback } from "../../queries/optionBuyback";

function TransactionSummary() {
  const navigate = useNavigate();
  const isUserEsopViewer = isEsopViewer();
  const { data: _buybackOverview, isPlaceholderData } = useGetOptionBuyback();

  function handleAction(action: Action) {
    if (action.name === "Buy Back" && !action.disabled && !isUserEsopViewer) {
      navigate("/options/create-option-buyback?mode=create");
    }
    if (action.name === "Exercise" && !action.disabled && !isUserEsopViewer) {
      navigate("/options/create-exercise-flow?mode=create");
    }
    if (
      action.name === "Exercise and Liquidate" &&
      !action.disabled &&
      !isUserEsopViewer
    ) {
      navigate("/options/create-exercise-liquidation-flow?mode=create");
    }
    if (action.name === "Liquidate" && !action.disabled && !isUserEsopViewer) {
      navigate("/options/create-liquidation-flow?mode=create");
    }
  }
  const { isMobile } = useIsMobile();

  const isCreateDisable = (_buybackOverview || []).some(
    (option) => option.state.toLowerCase() !== "completed"
  );
  const summary: summaryTypes[] = [
    {
      name: "Buy Back",
      transactionEvent: (_buybackOverview || []).filter(
        (overview) =>
          overview.transactionType === "buyback" &&
          overview.state.toUpperCase() === "COMPLETED"
      ),
    },
    // {
    //   name: "Exercise",
    //   transactionEvent: (_buybackOverview || []).filter(
    //     (overview) =>
    //       overview.transactionType === "exercise" &&
    //       overview.state.toUpperCase() === "COMPLETED"
    //   ),
    // },
    {
      name: "Exercise and Liquidation",
      transactionEvent: (_buybackOverview || []).filter(
        (overview) =>
          overview.transactionType === "exercise and liquidate" &&
          overview.state.toUpperCase() === "COMPLETED"
      ),
    },
  ];
  return (
    <VStack className="p-2 bg-white rounded">
      {/* header */}
      <HStack className="justify-between mx-2">
        <VStack>
          <p className="text-sm font-medium text-gray-dark">
            Transaction Summary
          </p>
        </VStack>
        {!isUserEsopViewer && (
          <HStack className="items-center justify-between rounded ">
            <AddTransaction
              actions={[
                {
                  name: "Buy Back",
                  disabled: isCreateDisable,
                },
                {
                  name: "Exercise and Liquidate",
                  disabled: isCreateDisable,
                },
              ]}
              onAction={(action) => handleAction(action)}
            />
          </HStack>
        )}
      </HStack>
      {/* summary cards */}
      <HStack
        className={`${
          isPlaceholderData ? "loading" : ""
        } grid gap-4 md:grid-cols-${summary.length} sm:grid-cols-1`}
      >
        {summary.map((card, index) => (
          <SummaryCard key={index} {...card} />
        ))}
      </HStack>
      {/* summary table */}
      <Box className={`w-full ${isMobile ? "overflow-x-auto" : ""} `}>
        <BuybackTable />
      </Box>
    </VStack>
  );
}

export default TransactionSummary;

export function AddTransaction({
  actions,
  onAction,
}: {
  actions: Action[];
  onAction: (action: Action) => void;
}) {
  const { isMobile } = useIsMobile();
  return (
    <DropdownButton
      className="items-center"
      actions={actions}
      onAction={onAction}
      render={() => (
        <div
          className={`${
            isMobile ? "text-xs whitespace-nowrap" : ""
          } flex items-center rounded`}
        >
          <p>Add ESOP Transaction</p>
          <Icon height={24} icon="ri:arrow-drop-down-line" className="inline" />
        </div>
      )}
    />
  );
}
