import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Dialog } from "@mui/material";
import { toast } from "react-toastify";
import {
  useCreateCompanyDetails,
  useGetDiscardCompanyFromOnboarding,
  useOnBoardingUploadCompanyDetail,
  useShareCapitalDetails,
} from "../../queries/onBoarding";
import {
  CompanyDetailsPostModel,
  DropDownModel,
} from "../../types/OnBoardDataModel";
import { formatDate, formatWithTimeZone } from "../../utils/date";
import { initialCompanyDetail } from "./initValues";
import { useGrantCreatedDialog } from "../../store/useDialogStore";
import ValuationOverview from "../Valuation/ValuationOverview";
import BCHeader from "../../shared/BCHeader";
import { Select } from "../../components/shared/Select";
import FileInput from "../../components/shared/FileInput";
import AlertDialog from "../../components/shared/AlertDialog";
import CompanyDetailForm from "../onboarding/CompanyDetailForm";
import BusinessDetailForm from "../onboarding/BusinessDetailForm";
import FounderTable from "../onboarding/FounderTable";
import DirectorTable from "../onboarding/DirectorTable";
import { useAuthorizationStore, useAuthStore } from "../../store";
import {
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  isEsopViewer,
} from "../../components/utils";
import {
  useGetShareCapitalDetails,
  useSaveShareCapitalDetails,
} from "../../queries/siteSettings";
import CompanyShareCapital from "./CompanyShareCapital";
import { saveShareCapitalDetail } from "../../api/siteSettings";
import { CompanyCapitalDetails } from "../../types/HistoricDataModel";

export interface FileTypeModel {
  fileBlob: string;
  fileName?: string;
  fileType?: string;
}
export function CompanyProfile() {
  const { authority, permissions: actualPermissions } = useAuthorizationStore();
  const [isUserAdmin, setIsUserAdmin] = useState<boolean>();
  useEffect(() => {
    setIsUserAdmin(
      authority?.includes("ROLE_ADMIN" || "ROLE_ESOP_ADMIN") &&
        actualPermissions?.includes(
          "FULL_ACCESS" || "ADMIN_SAVING_POST" || "ADMIN_SETTINGS_GET"
        )
    );
  }, [authority, actualPermissions]);
  const initialValues = { name: "name", age: 10 };
  const { user } = useAuthStore();
  const isUserEsopViewer = isEsopViewer();
  const [selectedOption, setSelectedOption] = useState("");
  const { data: getShareCapitalDetail } = useGetShareCapitalDetails();
  const { data: _companyDetail } = useOnBoardingUploadCompanyDetail();
  const { mutate: createCompanyDetails } = useCreateCompanyDetails();
  const { mutate: createShareCapitalDetail } = useSaveShareCapitalDetails();

  const companyDetail = _companyDetail;
  const [companyLogoImage, setCompanyLogoImage] = useState<FileTypeModel>({
    fileBlob: "",
  });
  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
  }>({
    open: false,
  });
  const [toggle, setToggle] = useState(false);
  const toggleClass = " transform translate-x-5";
  const actions = ["filter1", "filter2", "filter3", "filter4", "filter5"];
  const handleActions = (e: any) => {
    e.stopPropagation();
  };
  const { state: grantCreatedDialog, setState: setGrantCreatedDialog } =
    useGrantCreatedDialog();
  const getInitialValue = () =>
    companyDetail
      ? {
          id: parseInt(companyDetail.id, 10),
          nameOfTheCompany: companyDetail.nameOfTheCompany || "",
          dippCertificateNumber: companyDetail.dippCertificateNumber || "",
          cinNumber: companyDetail.cinNumber || "",
          typeOfTheCompany: companyDetail.typeOfTheCompany || "",
          companyCurrency: companyDetail.companyCurrency || "",
          dateOfIncorporation: formatDate(companyDetail.dateOfIncorporation),
          corporateOfficeAddress: companyDetail.corporateOfficeAddress || "",
          companyEmailId: companyDetail.companyEmailId || "",
          companyWebsite: companyDetail.companyWebsite || "",
          companyLogoPath: companyLogoImage?.fileBlob || "",
          doingBusinessAs: companyDetail.doingBusinessAs || "",
          industry: companyDetail.industry || [],
          category: companyDetail.business || [],
          nic: companyDetail.nic || "",
          founders: companyDetail.founders,
          directors: companyDetail.directors,
          equityAuthorizedShareCapital:
            getShareCapitalDetail?.equityAuthorizedShareCapital || 0,
          preferenceAuthorizedShareCapital:
            getShareCapitalDetail?.preferenceAuthorizedShareCapital || 0,
          equityPaidUpShareCapital:
            getShareCapitalDetail?.equityPaidUpShareCapital || 0,
          preferencePaidUpShareCapital:
            getShareCapitalDetail?.preferencePaidUpShareCapital || 0,
        }
      : initialCompanyDetail;
  const handleCompanyDetailsSubmit = (values: CompanyDetailsPostModel) => {
    const formData = new FormData();
    const incorpDate = new Date(values.dateOfIncorporation).toUTCString();
    formData.append("id", (values.id || companyDetail?.id) as string);
    formData.append("nameOfTheCompany", values.nameOfTheCompany);
    formData.append("dippCertificateNumber", values.dippCertificateNumber);
    formData.append("doingBusinessAs", values.doingBusinessAs);
    formData.append("cinNumber", values.cinNumber);
    formData.append("typeOfTheCompany", values.typeOfTheCompany);
    formData.append("dateOfIncorporation", incorpDate);
    formData.append("corporateOfficeAddress", values.corporateOfficeAddress);
    formData.append("companyEmailId", values.companyEmailId);
    formData.append("companyWebsite", values.companyWebsite);
    formData.append("companyCurrency", values.companyCurrency);
    formData.append("nic", values.nic);
    formData.append("companyLogoPath", values.companyLogoPath);
    values.category.forEach((category, index) => {
      formData.append(`business[${index}]`, category);
    });
    values.industry.forEach((industry, index) => {
      formData.append(`industry[${index}]`, industry);
    });
    values?.founders.forEach((founder, index) => {
      formData.append(`founders[${index}].isEdit`, `${founder.isEdit}`);
      formData.append(`founders[${index}].emailId`, `${founder.emailId}`);
      formData.append(`founders[${index}].fullName`, `${founder.fullName}`);
      formData.append(
        `founders[${index}].designation`,
        `${founder.designation}`
      );
      formData.append(
        `founders[${index}].contactNumber`,
        `${founder.contactNumber}`
      );
      formData.append(`founders[${index}].address`, `${founder.address}`);
      formData.append(
        `founders[${index}].nationality`,
        `${founder.nationality}`
      );
      formData.append(
        `founders[${index}].linkedInProfile`,
        `${founder.linkedInProfile}`
      );
      formData.append(
        `founders[${index}].twitterHandle`,
        `${founder.twitterHandle}`
      );
      formData.append(`founders[${index}].isSelected`, `${founder.isSelected}`);
      formData.append(
        `founders[${index}].shouldShowDialog`,
        `${founder.shouldShowDialog}`
      );
    });
    values?.directors.forEach((director, index) => {
      formData.append(`directors[${index}].isEdit`, `${director.isEdit}`);
      formData.append(`directors[${index}].fullName`, `${director.fullName}`);
      formData.append(
        `directors[${index}].typeOfDirector`,
        `${director.typeOfDirector}`
      );
      formData.append(`directors[${index}].din`, `${director.din}`);
      formData.append(`directors[${index}].emailId`, `${director.emailId}`);
      formData.append(`directors[${index}].address`, `${director.address}`);
      formData.append(
        `directors[${index}].contactNumber`,
        `${director.contactNumber}`
      );
      formData.append(
        `directors[${index}].nationality`,
        `${director.nationality}`
      );
      formData.append(
        `directors[${index}].isSelected`,
        `${director.isSelected}`
      );
      formData.append(
        `directors[${index}].shouldShowDialog`,
        `${director.shouldShowDialog}`
      );
    });
    createCompanyDetails(formData, {
      onSuccess: () => {
        toast("Company Profile updated Successfully!", {
          type: "success",
          autoClose: 2000,
        });
      },
      onError: (error: { response: { data: { reason: string } } }) => {
        toast(`${error.response.data.reason}`, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
    const companyCapitalDetailsDto: CompanyCapitalDetails = {
      equityAuthorizedShareCapital: values.equityAuthorizedShareCapital,
      preferenceAuthorizedShareCapital: values.preferenceAuthorizedShareCapital,
      equityPaidUpShareCapital: values.equityPaidUpShareCapital,
      preferencePaidUpShareCapital: values.preferenceAuthorizedShareCapital,
    };
    createShareCapitalDetail(companyCapitalDetailsDto);
  };
  const validationCompanyDetailsSchema = Yup.object().shape({
    nameOfTheCompany: Yup.string().required("Company Name is required"),
    cinNumber: Yup.string().required("CIN Number is required"),
    typeOfTheCompany: Yup.string().required("Company Type is required"),
    dateOfIncorporation: Yup.string().required(
      "Date of Incorporation is required"
    ),
    companyEmailId: Yup.string()
      .email("Enter Valid Company Email is required")
      .required("Email is required"),
    companyCurrency: Yup.string().required("Company Currency is required"),
    nic: Yup.string().required("NIC is required"),
    industry: Yup.array().min(1, "Industry is Required"),
    category: Yup.array().min(1, "Category is Required"),
    founders: Yup.array()
      .of(
        Yup.object().shape({
          fullName: Yup.string().required("Founder Name is required"),
          emailId: Yup.string()
            .email("Valid Founder Email Id is required")
            .required("Valid Email Id is required"),
          nationality: Yup.string().required("Nationality is required"),
        })
      )
      .required("Required"),
    directors: Yup.array()
      .of(
        Yup.object().shape({
          fullName: Yup.string().required("Director Name is required"),
          emailId: Yup.string()
            .email("Valid Director Email Id is required")
            .required("Valid Email Id is required"),
          typeOfDirector: Yup.string().required("Type of Director is required"),
          din: Yup.string().required("DIN Number is required"),
          nationality: Yup.string().required("Nationality is required"),
        })
      )
      .required("Required"),
  });

  const getDropDownValue = () => {
    const dropDownOptions: DropDownModel = {
      selectedCategoryValues: companyDetail?.business,
      selectedIndustryValues: companyDetail?.industry,
      industryDropdownValues: companyDetail?.industryDropdownValues || [],
      categoryDropdownValues: companyDetail?.categoryDropdownValues || [],
    };
    return dropDownOptions;
  };
  const isEsopOnlyCompany = user?.company.isEsopOnlyCompany;

  return (
    <>
      {isUserAdmin && (
        <div className="gap-4 mx-auto">
          <BCHeader
            className="items-baseline mb-4"
            bcTitle="Settings"
            bcSubTitle="Company profile"
          />
          <Formik
            enableReinitialize={true}
            initialValues={getInitialValue()}
            onSubmit={(values: CompanyDetailsPostModel, error: any) => {
              handleCompanyDetailsSubmit(values);
            }}
            validationSchema={validationCompanyDetailsSchema}
          >
            {(formik) => (
              <>
                <Form>
                  <Dialog open={dialog.open} maxWidth="md">
                    <AlertDialog
                      onClose={() => setDialog({ open: false })}
                      open={dialog.open}
                      message={dialog.message}
                      onSecondaryAction={() => setDialog({ open: false })}
                    />
                  </Dialog>
                  <CompanyDetailForm
                    companyLogoImage={companyLogoImage?.fileBlob}
                    setCompanyLogoImage={setCompanyLogoImage}
                  />
                  {!isEsopOnlyCompany && <CompanyShareCapital />}
                  <BusinessDetailForm dropDownValue={getDropDownValue()} />
                  <FounderTable />
                  <DirectorTable />
                  {!isUserEsopViewer && (
                    <HStack className="justify-end m-8">
                      <ButtonPrimary type="submit">Save</ButtonPrimary>
                    </HStack>
                  )}
                </Form>
              </>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}

export default CompanyProfile;
