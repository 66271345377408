export const Check = () => (
  <svg
    viewBox="0 0 16 16"
    width={"1em"}
    height={"1em"}
    fill="currentColor"
    display={"block"}
  >
    <path
      d="M13 .156l-1.406 1.438-5.594 5.594-1.594-1.594-1.406-1.438-2.844 2.844 1.438 1.406 3 3 1.406 1.438 1.406-1.438 7-7 1.438-1.406-2.844-2.844z"
      transform="translate(0 1)"
    />
  </svg>
);
