import randomColor from "randomcolor";
import seedrandom from "seedrandom";
import { Center, Box } from "../../components/utils";

function Avatar({ name, status }: { name: string; status?: string }) {
  const seed = name;
  const backgroundColor = randomColor({
    luminosity: "dark",
    format: "rgba",
    alpha: 0.2,
    seed,
  });
  const color = randomColor({
    luminosity: "dark",
    format: "rgba",
    alpha: 1,
    seed,
  });
  return (
    <Center className="relative w-8 h-8 loader-linear ">
      <Center
        style={{
          backgroundColor,
          color,
        }}
        className="flex w-8 h-8 rounded-full "
      >
        <p className="text-base font-medium ">{name.charAt(0)}</p>
      </Center>
      {status && status !== "Employed" ? (
        <Box
          className="absolute bottom-0 right-0 flex w-2.5 h-2.5
         bg-red-500 border-2 border-white rounded-full "
        ></Box>
      ) : (
        ""
      )}
    </Center>
  );
}
export default Avatar;
