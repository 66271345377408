import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Icon } from "@iconify/react";
import { format } from "date-fns";
import { Bar } from "react-chartjs-2";
import { Box, HStack, VStack } from "../../components/utils";
import { useVestingTables } from "../../queries";
import { formatDisplayDate } from "../../utils/date";
import { EmployeeVestingDetail } from "../../types/VestingTemplate";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Vesting Projections",
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  borderRadius: 4,
  scales: {
    x: {
      title: {
        display: true,
        text: "Year",
      },
      categoryPercentage: 1.0,
      barPercentage: 1.0,
      stacked: true,
      grid: {
        borderDash: [1, 3],
        color: "#e8e8e8",
      },
      ticks: {
        font: { size: 12 },
        padding: 1,
      },
    },
    y: {
      title: {
        display: true,
        text: "Grants",
      },
      grid: {
        borderDash: [1, 3],
        color: "#e8e8e8",
      },
      ticks: {
        font: { size: 12 },
        maxTicksLimit: 5,
      },
    },
  },
};

const VestingSchedule = () => {
  const { id } = useParams();
  const _id = id || "";
  const { data: _vestingSchedule } = useVestingTables(parseInt(_id, 10));

  const filteredData = _vestingSchedule?.filter((vesting) => {
    if (vesting.lastDay) {
      if (
        new Date(vesting.lastDay).setHours(0, 0, 0, 0) <=
        new Date(vesting.date).setHours(0, 0, 0, 0)
      ) {
        return false;
      } else return true;
    } else return true;
  });
  const [showTable, setShowTable] = useState(false);

  const vestingScheduleTemplate = filteredData || [];
  const data = {
    labels: vestingScheduleTemplate?.map((vestSchedule) =>
      format(new Date(vestSchedule.date), "yyyy-MM-dd")
    ),
    datasets: [
      {
        label: "Exercised",
        data: vestingScheduleTemplate.map(
          (exercised) => exercised.accumulatedExercisedOptionsForEmployee
        ),
        backgroundColor: "rgb(80,205,137)",
      },
      {
        label: "Vested",
        data: vestingScheduleTemplate.map(
          (vested) => vested.accumulatedVestedOptionsForEmployee
        ),
        backgroundColor: "rgb(248,177,149)",
      },
      {
        label: "Granted",
        data: vestingScheduleTemplate.map(
          (granted) => granted.accumulatedGrantedOptionsForEmployee
        ),
        backgroundColor: "rgb(246,114,128)",
      },
    ],
  };

  return (
    <Box className="p-4 bg-white ">
      <HStack className="justify-between">
        <VStack>
          <p className="font-semibold">Vesting Schedule</p>
        </VStack>

        {!showTable ? (
          <button
            onClick={() => {
              setShowTable(!showTable);
            }}
          >
            <Icon
              className="hover:text-orange-501"
              icon="mdi:table"
              height={25}
            />
          </button>
        ) : (
          <button
            onClick={() => {
              setShowTable(!showTable);
            }}
          >
            <Icon
              icon="mdi:graph-bar"
              className="hover:text-orange-501"
              height={25}
            />
          </button>
        )}
      </HStack>
      <Box className="mt-6 overflow-y-auto h-96">
        {showTable ? (
          <table className="w-full table-space ">
            <thead className="text-xs font-medium text-gray-light">
              <tr className="">
                <td className="py-3">DATE</td>
                <td className="py-3">GRANTED</td>
                <td className="py-3">VESTED</td>
                <td className="py-3">NET VESTED</td>
                <td className="py-3">STOCK EXERCISE</td>
                <td className="py-3">CASHED OUT</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {vestingScheduleTemplate &&
                vestingScheduleTemplate?.map((ves, i) => (
                  <tr key={i}>
                    <td className="py-4 align-top ">
                      <HStack className="">
                        <Box className="">
                          <p className="text-xs font-medium text-gray-dark">
                            {formatDisplayDate(ves.date)}
                          </p>
                        </Box>
                      </HStack>
                    </td>
                    <td className="py-4 align-top ">
                      <HStack className="">
                        <Box className="">
                          <p className="text-xs font-medium text-gray-dark">
                            {ves.grantedOptions.toLocaleString()}
                          </p>
                        </Box>
                      </HStack>
                    </td>
                    <td className="py-4 align-top ">
                      <HStack className="">
                        <Box className="">
                          <p className="text-xs font-medium text-gray-dark">
                            {ves.vestedOptions.toLocaleString()}
                          </p>
                        </Box>
                      </HStack>
                    </td>
                    <td className="py-4 align-top ">
                      <HStack className="">
                        <Box className="">
                          <p className="text-xs font-medium text-gray-dark">
                            {ves?.accumulatedVestedOptionsForEmployee?.toLocaleString()}
                          </p>
                        </Box>
                      </HStack>
                    </td>
                    <td className="py-4 align-top ">
                      <HStack className="">
                        <Box className="">
                          <p className="text-xs font-medium text-gray-dark">
                            {(
                              ves.exercisedOptions -
                              ves.exercisedOptionsWithCash
                            ).toLocaleString()}
                          </p>
                        </Box>
                      </HStack>
                    </td>
                    <td className="py-4 align-top ">
                      <HStack className="">
                        <Box className="">
                          <p className="text-xs font-medium text-gray-dark">
                            {ves.exercisedOptionsWithCash.toLocaleString()}
                          </p>
                        </Box>
                      </HStack>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <Bar options={options} data={data} />
        )}
      </Box>
    </Box>
  );
};

export default VestingSchedule;
