import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BCHeader from "../../shared/BCHeader";
import ActivityLog from "./ActivityLog";
import Documents from "./Documents";
import Profile from "./profile";
import { useEmployees, useGrants } from "../../queries";
import EmployeeHeader from "./EmployeeHeader";
import GrantsVSGrade from "./GrantsVSGrade";
import { Employee } from "../../types/Employee";
import { Box, HStack, VStack } from "../../components/utils";
import SearchAutoComplete from "../../components/shared/SearchAutoComplete";

function EmployeeDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: _employees, isFetching, isPlaceholderData } = useEmployees();
  const employees: Employee[] = useMemo(
    () => _employees || [],
    [id, isPlaceholderData]
  );
  const { data: _grants } = useGrants();

  const [activeTab, setActiveTab] = useState<string>("Grant Details");
  const [selectedEmp, setSelectedEmp] = useState<Employee>();
  const showTabs = (e: React.MouseEvent<HTMLElement>) => {
    setActiveTab((e.target as any).innerHTML);
    e.stopPropagation();
  };
  function getSelectedValue(employee: any) {
    setSelectedEmp(employee);
  }

  useEffect(() => {
    if (!selectedEmp) return;
    navigate(`/options/employeeDetails/${selectedEmp?.id}`);
  }, [selectedEmp]);

  useEffect(() => {
    if (isFetching) return;
    const initialFilterValue = employees?.filter(
      (emp) => emp.id.toString() === id
    );
    if (initialFilterValue.length === 0) {
      toast(`You don't have permission to access this Employee with id:${id}`, {
        type: "error",
        autoClose: 3000,
      });
      navigate("/options/allEmployees/");
    }
    setSelectedEmp(initialFilterValue[0]);
  }, [id, isFetching]);

  return (
    <VStack className="gap-4">
      <HStack className="justify-between min-h-[45px] items-center">
        <BCHeader
          className=" items-baseline"
          bcTitle="All Option Holders"
          bcSubTitle={selectedEmp?.employeeName || ""}
        />
        {selectedEmp ? (
          <Box className="bg-white rounded w-48 min-h-[45px]">
            <SearchAutoComplete
              data={employees}
              getSelectedValue={getSelectedValue}
              selectedOption={selectedEmp}
              field={"employeeName"}
            />
          </Box>
        ) : (
          ""
        )}
      </HStack>
      <EmployeeHeader activeTab={activeTab} onClick={showTabs} />
      {activeTab === "Grant Details" && <GrantsVSGrade />}
      {activeTab === "Profile" && <Profile />}
      {activeTab === "Full Activity Log" && <ActivityLog />}
      {activeTab === "Documents" && <Documents />}
    </VStack>
  );
}

export default EmployeeDetails;
