import createStore from "zustand";
import { Grant } from "../types/Grant";
import { OptionSplit } from "../types/OptionSplit";
import { VestingEventReq, VestingTemplate } from "../types/VestingTemplate";

interface GrantDialog {
  open: boolean;
  grant?: Grant;
  grantId?: number;
  variant?: "created" | "edit" | "state change";
}

interface VestingDialog {
  open: boolean;
  template?: VestingTemplate;
  mode?: "Add" | "Edit" | "View";
}
interface EventDialog {
  open: boolean;
  event?: VestingEventReq;
  variant?: "edit" | "complete";
}

interface OptionSplitDialog {
  open: boolean;
  split?: OptionSplit;
  mode?: "Add" | "Edit" | "View";
}
interface AdvanceFilterDialog {
  open: boolean;
  id?: string;
}
interface StateStore<T> {
  state: T;
  setState: (state: T | ((state?: T) => T)) => void;
}

export const useExerciseRequestDialog = createStore<StateStore<GrantDialog>>(
  (set) => ({
    state: { open: false },
    setState: (input) => {
      if (typeof input === "function") {
        return set((store) => ({ ...store, state: input(store.state) }));
      } else {
        return set((store) => ({ ...store, state: input }));
      }
    },
  })
);

export const useEditEventDetailsDialog = createStore<StateStore<EventDialog>>(
  (set) => ({
    state: { open: false },
    setState: (input) => {
      if (typeof input === "function") {
        return set((store) => ({ ...store, state: input(store.state) }));
      } else {
        return set((store) => ({ ...store, state: input }));
      }
    },
  })
);

export const useExerciseApproveDialog = createStore<StateStore<GrantDialog>>(
  (set) => ({
    state: { open: false },
    setState: (input) => {
      if (typeof input === "function") {
        return set((store) => ({ ...store, state: input(store.state) }));
      } else {
        return set((store) => ({ ...store, state: input }));
      }
    },
  })
);

export const useVestingApproveDialog = createStore<StateStore<GrantDialog>>(
  (set) => ({
    state: { open: false },
    setState: (input) => {
      if (typeof input === "function") {
        return set((store) => ({ ...store, state: input(store.state) }));
      } else {
        return set((store) => ({ ...store, state: input }));
      }
    },
  })
);

export const useVestingScheduleDialog = createStore<StateStore<VestingDialog>>(
  (set) => ({
    state: { open: false },
    setState: (input) => {
      if (typeof input === "function") {
        return set((store) => ({ ...store, state: input(store.state) }));
      } else {
        return set((store) => ({ ...store, state: input }));
      }
    },
  })
);

export const useGrantCreatedDialog = createStore<StateStore<GrantDialog>>(
  (set) => ({
    state: { open: false },
    setState: (input) => {
      if (typeof input === "function") {
        return set((store) => ({ ...store, state: input(store.state) }));
      } else {
        return set((store) => ({ ...store, state: input }));
      }
    },
  })
);

export const useOptionSplitDialog = createStore<StateStore<OptionSplitDialog>>(
  (set) => ({
    state: { open: false },
    setState: (input) => {
      if (typeof input === "function") {
        return set((store) => ({ ...store, state: input(store.state) }));
      } else {
        return set((store) => ({ ...store, state: input }));
      }
    },
  })
);

export const useAdvanceFilterDialog = createStore<
  StateStore<AdvanceFilterDialog>
>((set) => ({
  state: { open: false },
  setState: (input) => {
    if (typeof input === "function") {
      return set((store) => ({ ...store, state: input(store.state) }));
    } else {
      return set((store) => ({ ...store, state: input }));
    }
  },
}));
