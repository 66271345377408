import { Formik, Form, useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { Input, Label } from "../../components/shared/InputField";
import {
  Box,
  Center,
  VStack,
  HStack,
  ButtonPrimary,
  Error,
  ButtonPrimary1,
  isAdminViewer,
  isEsopViewer,
} from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { CapTable, CurrentPoolSize, Shareholder } from "../../types/EsopPlan";
import {
  useCurrentPoolSize,
  useEditAddPoolSize,
  useIsPoolExists,
  usePreview,
} from "../../queries/esopPlan";
import Avatar from "./Avatar";
import { formatDate, formatWithTimeZone } from "../../utils/date";
import { makeFlat } from "../../utils/makeFlat";
import { usePoolStore } from "../../store/poolSizeStore";
import { useAuthorizationStore, useAuthStore } from "../../store";
import StatusLabelColors from "../../constants/StatusLabelColors";
import SortingComponent from "../../components/SortingVariation";
import Tooltip from "../../components/shared/Tooltip";
import useIsMobile from "../../utils/detectDevice";
import { sort } from "../../utils/arrays";
import ImpactedEmployees from "../allPlans/ImpactedEmployees";
import RoundIdentifiers from "./RoundIdentifiers";

function AddOrEditPoolSize() {
  const navigate = useNavigate();
  const userPermissions = useAuthorizationStore();
  const { user } = useAuthStore();
  const { data: _tableData, isFetching } = useCurrentPoolSize();
  const { data: isPoolExists } = useIsPoolExists();
  const [isEditable, setIsEditable] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const capTable = _tableData?.capTable || [];
  const capsecuritiesTable = _tableData?.instrumentCapTable || [];
  const { mutate: preview, isLoading } = usePreview();
  const { mutate: saveUpdatedPoolSize } = useEditAddPoolSize();
  const [toggle, setToggle] = useState(false);
  const [percentageToggle, setPercentageToggle] = useState(false);
  const [tableType, setTableType] = useState<Shareholder[]>([]);
  const [showMoreIndex, setShowMoreIndex] = useState(false);
  const [previewData, setPreviewData] = useState<CurrentPoolSize>();
  const [sortField, setSortField] = useState<{
    field: keyof Shareholder | "" | undefined;
    ascending: boolean;
  }>({ field: "", ascending: false });
  const isUserAdminViewer = isAdminViewer();
  const isUserEsopViewer = isEsopViewer();

  const anyOngoingTransaction = _tableData?.onGoingTranscation || false;
  const isEsopOnlyCompany = user?.company.isEsopOnlyCompany;
  useEffect(() => {
    if (user?.company.isEsopOnlyCompany) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }, [user]);

  const poolStore = usePoolStore();
  useEffect(() => {
    if (previewData) {
      if (!toggle && !isFetching) {
        setTableType(makeFlat(previewData.capTable));
      } else {
        setTableType(makeFlat(previewData.instrumentCapTable));
      }
      return;
    }
    if (!toggle && !isFetching) {
      setTableType(makeFlat(capTable));
    } else {
      setTableType(makeFlat(capsecuritiesTable));
    }
  }, [toggle, isFetching]);
  useEffect(() => {
    sort(tableType || [], sortField?.field, sortField?.ascending);
  }, [sortField]);
  useEffect(() => {
    poolStore.setPoolPercentage(_tableData?.poolPercentage || 0);
    poolStore.setPoolSize(_tableData?.poolSize || 0);
    poolStore.setEditDate(formatDate(new Date().toUTCString()));
  }, [isFetching]);
  const initialValues = {
    percentage: (poolStore.poolPercentage * 100).toPrecision(4),
    poolSize: poolStore.poolSize,
    editDate: poolStore.editDate,
  };
  const [poolValue, setPoolValues] = useState({
    poolPercenatge: 0,
    poolSize: "",
    editDate: "",
  });

  const validationSchema = Yup.object().shape({
    percentage: Yup.number().required("Percentage is required").max(100),
    poolSize: Yup.string().required("Pool size is required"),
    editDate: Yup.string().required("date is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: () => {
      const submitDto = {
        ..._tableData,
        isPoolSizeGiven: !percentageToggle,
        poolPercentage: parseInt(formik.values.percentage, 10) / 100,
        poolSize: parseInt(formik.values.poolSize.toString(), 10),
        poolCreationDate: formatWithTimeZone(formik.values.editDate),
      };
      setPoolValues({
        poolPercenatge: parseInt(formik.values.percentage, 10),
        poolSize: formik.values.poolSize.toString(),
        editDate: formik.values.editDate,
      });
      preview(submitDto, {
        onSuccess: (data) => {
          setPreviewData(data);
          poolStore.setPoolPercentage(data.poolPercentage || 0);
          poolStore.setPoolSize(data.poolSize || 0);
          poolStore.setEditDate(formatDate(data.poolCreationDate));
          if (toggle) {
            setTableType(makeFlat(data.capTable));
          } else {
            setTableType(makeFlat(data.instrumentCapTable));
          }
        },
        onError: (error: any) => {
          toast(
            `Please switch off the toggle or ${error?.response?.data?.reason}`,
            {
              type: "error",
              autoClose: 2000,
            }
          );
        },
      });
    },
  });
  const [loading, setLoading] = useState(false);
  function submitUpdatedPool() {
    setLoading(true);
    let percentage: any = 0;
    if (!percentageToggle) {
      percentage = previewData?.poolPercentage;
    }
    let saveDto = {};
    if (isEsopOnlyCompany) {
      saveDto = {
        poolSize: parseInt(formik.values.poolSize.toString(), 10),
        poolCreationDate: formatWithTimeZone(formik.values.editDate),
      };
    } else {
      saveDto = {
        ...previewData,
        isPoolSizeGiven: !percentageToggle,
        poolPercentage: percentageToggle
          ? poolValue.poolPercenatge / 100
          : percentage,
        poolSize: parseInt(poolValue.poolSize, 10),
        poolCreationDate: formatWithTimeZone(poolValue.editDate),
      };
    }
    saveUpdatedPoolSize(saveDto, {
      onSuccess: () => {
        toast("Pool updated successfully", {
          type: "success",
          autoClose: 2000,
        });
        navigate("/options/esopOverview");
        setLoading(false);
      },
      onError: () => {
        toast("Something went wrong", { type: "error", autoClose: 5000 });
        setLoading(false);
      },
    });
  }
  const { isMobile } = useIsMobile();

  return (
    <VStack className="gap-4 mx-auto">
      <BCHeader
        className="items-baseline py-3"
        bcTitle="ESOP Overview"
        bcSubTitle="Options pool"
      ></BCHeader>
      <Box
        className={`${
          isMobile ? "p-4 overflow-auto" : "p-8 min-w-min"
        } " bg-white rounded-lg"`}
      >
        <HStack
          aria-label="toolbar"
          className="items-center justify-between mb-8"
        >
          <h4
            className={`${
              isMobile ? "text-sm1" : "text-lg"
            } font-medium text-gray-dark`}
          >
            Options Pool
          </h4>
          {isEditable === false && (
            <ButtonPrimary
              className="min-w-max"
              disabled={isUserAdminViewer || isUserEsopViewer}
              onClick={() => {
                if (
                  !(
                    userPermissions.authority === "ROLE_ADMIN" ||
                    userPermissions.authority === "ROLE_ESOP_ADMIN"
                  )
                ) {
                  toast("You do not have permission to Add/Edit pool", {
                    type: "error",
                    autoClose: 3000,
                  });
                  return;
                }
                setIsEditable(true);
              }}
            >
              {isPoolExists ? "Edit" : "Add"}
            </ButtonPrimary>
          )}
        </HStack>
        <VStack>
          <Box
            className={`${
              isEsopOnlyCompany
                ? "flex flex-row items-center gap-4"
                : " flex flex-col gap-4"
            }`}
          >
            <Box
              className={`flex ${
                isMobile
                  ? "flex-col"
                  : "flex-row items-center whitespace-nowrap gap-4"
              }`}
            >
              <Label className="font-medium text-sm1 text-gray-dark">
                Pool Edit Date:{" "}
              </Label>
              <Input
                disabled={!isEditable}
                type="date"
                className={`${isMobile ? "" : "w-60"} ${
                  isEditable
                    ? "text-gray-dark"
                    : "text-gray-light cursor-not-allowed"
                }`}
                {...formik.getFieldProps("editDate")}
              />
              {formik.touched && formik.errors.editDate && (
                <Error text={formik.errors.editDate} />
              )}
            </Box>
            {!isEsopOnlyCompany && (
              <HStack className="flex-row-reverse justify-end gap-4 font-medium text-sm1 text-gray-dark">
                <SwitchButton
                  className={`${
                    isEditable
                      ? "text-gray-dark cursor-pointer"
                      : "text-gray-dark cursor-not-allowed"
                  }`}
                  value={percentageToggle}
                  label="Pool Percentage"
                  onClick={() => {
                    if (!isEditable) return;
                    setPercentageToggle(!percentageToggle);
                  }}
                />
              </HStack>
            )}
            <Box
              className={`flex ${
                isMobile ? "flex-col" : "flex-row "
              } justify-start gap-4`}
            >
              {!isEsopOnlyCompany && (
                <Box
                  className={`flex items-center ${
                    !isEditable
                      ? "flex-row gap-4 items-baseline text-gray-600"
                      : ""
                  } py-2 text-sm font-medium  min-w-min`}
                >
                  <Label className="font-medium text-sm1 text-gray-dark">
                    Pool Percentage:{" "}
                  </Label>
                  {isEditable && (
                    <Input
                      type="number"
                      className={` ${isMobile ? "" : "w-60"} ${
                        !percentageToggle
                          ? "placeholder-gray-light text-gray-light cursor-not-allowed"
                          : ""
                      }`}
                      disabled={!percentageToggle}
                      autoFocus={percentageToggle}
                      placeholder="%"
                      {...formik.getFieldProps("percentage")}
                    />
                  )}
                  {isEditable === false && (
                    <Tooltip text={initialValues.percentage}>
                      <p
                        className={`${
                          isEditable
                            ? "text-gray-dark"
                            : "text-gray-light cursor-not-allowed"
                        }`}
                      >
                        {initialValues.percentage.slice(
                          0,
                          initialValues.percentage.indexOf(".") + 3
                        )}
                      </p>
                    </Tooltip>
                  )}
                  {formik.touched && formik.errors.percentage && (
                    <Error text={formik.errors.percentage} />
                  )}
                </Box>
              )}
              {
                <Box
                  className={`flex flex-row ${
                    !isEditable ? "gap-4 items-baseline" : ""
                  } ${
                    isEsopOnlyCompany ? "flex flex-row" : ""
                  } items-center gap-4 py-2 text-sm font-medium text-gray-600 min-w-min`}
                >
                  <Label className="font-medium text-sm1 text-gray-dark">
                    Pool size:{" "}
                  </Label>
                  {isEditable && (
                    <Box className={`flex items-center gap-4 `}>
                      <Input
                        type="number"
                        className={`${
                          percentageToggle
                            ? "placeholder-gray-light text-gray-light cursor-not-allowed"
                            : ""
                        }`}
                        disabled={percentageToggle}
                        autoFocus={!percentageToggle}
                        placeholder=""
                        {...formik.getFieldProps("poolSize")}
                      />{" "}
                      Options
                    </Box>
                  )}
                  {isEditable === false && (
                    <p
                      className={`${
                        isEditable
                          ? "text-gray-dark"
                          : "text-gray-light cursor-not-allowed"
                      }`}
                    >
                      {initialValues.poolSize}
                    </p>
                  )}
                  {formik.touched && formik.errors.poolSize && (
                    <Error text={formik.errors?.poolSize?.toString()} />
                  )}
                </Box>
              }
            </Box>
          </Box>

          {isEditable && !isEsopOnlyCompany && (
            <Box
              className={`flex mt-2 ${
                isMobile ? "justify-center" : "justify-end"
              }`}
            >
              {!isLoading ? (
                <ButtonPrimary
                  type="submit"
                  onClick={() => {
                    setCanSave(true);
                    formik.handleSubmit();
                  }}
                >
                  Preview
                </ButtonPrimary>
              ) : (
                <Icon
                  className="animate-spin"
                  icon="lucide:loader-2"
                  width={36}
                />
              )}
            </Box>
          )}
          {isEditable === false}
        </VStack>
        {!isEsopOnlyCompany && (
          <VStack aria-label="toolbar" className="justify-between mt-4">
            <p className="mb-4 text-lg font-medium text-gray-dark">Dilution</p>
            <HStack className="gap-4 font-medium text-sm1 text-gray-dark">
              <SwitchButton
                className={toggle ? "cursor-default" : ""}
                value={toggle}
                leftLabel="Shareholder"
                label="Securities"
                onClick={() => {
                  setToggle(!toggle);
                }}
              />
            </HStack>

            <Box className={`"justify-between gap-4 py-8 mb-8"`}>
              <Box className={`max-h-full overflow-x-auto`}>
                <table
                  className={`${
                    isMobile ? "overflow-x-auto" : "w-full table-sapce"
                  } whitespace-nowrap `}
                >
                  <thead className="text-xs font-medium text-gray-light">
                    <tr className="">
                      <td className="py-3"></td>
                      <td className="py-3"></td>
                      <td className="py-3 hover:cursor-pointer">
                        <HStack>
                          {toggle === false ? "Shareholder" : "Securities"}
                          <SortingComponent
                            fieldName="name"
                            selectedFieldName={sortField?.field || ""}
                            isAscending={sortField?.ascending || false}
                            onChangeSort={() => {
                              setSortField({
                                field: "name",
                                ascending: !sortField?.ascending,
                              });
                            }}
                          />
                        </HStack>
                      </td>
                      <td>
                        {" "}
                        <HStack className="items-center justify-start py-3 whitespace-nowrap hover:cursor-pointer">
                          Rounds
                        </HStack>
                      </td>
                      <td className="py-3 hover:cursor-pointer">
                        <HStack>
                          Pre-Holding %
                          <SortingComponent
                            fieldName="prePercentage"
                            selectedFieldName={sortField?.field || ""}
                            isAscending={sortField?.ascending || false}
                            onChangeSort={() => {
                              setSortField({
                                field: "prePercentage",
                                ascending: !sortField?.ascending,
                              });
                            }}
                          />
                        </HStack>
                      </td>
                      <td className="py-3 hover:cursor-pointer">
                        <HStack>
                          Post-Holding %
                          <SortingComponent
                            fieldName="postPercentage"
                            selectedFieldName={sortField?.field || ""}
                            isAscending={sortField?.ascending || false}
                            onChangeSort={() => {
                              setSortField({
                                field: "postPercentage",
                                ascending: !sortField?.ascending,
                              });
                            }}
                          />
                        </HStack>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {tableType &&
                      tableType.length > 0 &&
                      tableType?.map((template, _templateIndex) => (
                        <tr
                          key={template.uuid}
                          className="border-t border-dashed "
                        >
                          <td className="py-3">
                            <input
                              type="checkbox"
                              className="mt-2 accent-orange-501 outline-hidden"
                            />
                          </td>
                          <td className="py-3">
                            <Avatar name={template.name} />
                          </td>
                          <td className="py-3">
                            <VStack>
                              {template.name}
                              <p className="text-xs text-gray-300">
                                {template.type}
                              </p>
                            </VStack>
                          </td>
                          <td
                            className={`flex gap-2 py-3 overflow-auto  w-48 `}
                          >
                            <RoundIdentifiers
                              roundNames={template.roundDetails.map(
                                (round) => round.roundIdentifier
                              )}
                            />
                          </td>
                          <td className="py-3">
                            {(template.prePercentage * 100).toLocaleString()}
                          </td>
                          <td className="py-3">
                            {(template.postPercentage * 100).toLocaleString()}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Box>
            </Box>
          </VStack>
        )}
        <HStack className="justify-between py-8 justify">
          <Box>
            <Link to={`/options/esopOverview`}>
              {" "}
              <ButtonPrimary1 className="background: #F5F9FB;">
                Cancel
              </ButtonPrimary1>
            </Link>
          </Box>
          <div className="flex items-center">
            {canSave && (
              <Tooltip text={_tableData?.onGoingTranscation || "Save"}>
                <ButtonPrimary
                  className="min-w-[120px]"
                  disabled={!!anyOngoingTransaction || isUserEsopViewer}
                  onClick={() => {
                    submitUpdatedPool();
                  }}
                >
                  {!loading ? (
                    "Save"
                  ) : (
                    <Icon
                      className="animate-spin"
                      icon="lucide:loader-2"
                      width={24}
                    />
                  )}
                </ButtonPrimary>
              </Tooltip>
            )}
          </div>
        </HStack>
      </Box>
    </VStack>
  );
}
export default AddOrEditPoolSize;
