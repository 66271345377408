import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";
import {
  getAllFaq,
  getFaqSetting,
  setAllFaq,
  setEmployeeFaqSettings,
} from "../api/faq";
import { queryClient } from "./client";

export function useGetAllFaq() {
  return useQuery({
    queryKey: "getAllFaq",
    queryFn: getAllFaq,
  });
}

export function useSetAllFaq() {
  return useMutation({
    mutationKey: "",
    mutationFn: setAllFaq,
    onSuccess: () => {
      queryClient.invalidateQueries("getAllFaqSettings");
      queryClient.refetchQueries("getAllFaqSettings");
      queryClient.invalidateQueries("getAllFaq");
      queryClient.refetchQueries("getAllFaq");
    },
    onError: (error: { response: { data: { resultCode: string } } }) => {},
  });
}

export function useSetFaqSettings() {
  return useMutation({
    mutationKey: "faqEmployee",
    mutationFn: setEmployeeFaqSettings,
    onSuccess: () => {},
    onError: (error: { response: { data: { resultCode: string } } }) => {},
  });
}

export function useGetAllFaqSettings() {
  return useQuery({
    queryKey: "getAllFaqSettings",
    queryFn: getFaqSetting,
    select: (data) => data.faqForEmployee,
  });
}
