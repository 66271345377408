import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { format } from "date-fns";
import { Grant } from "../../types/Grant";
import {
  ButtonPrimary,
  ButtonSecondary,
  HStack,
  VStack,
} from "../../components/utils";

import { useApproveVesting, useVestRequestDetails } from "../../queries/vest";
import { VestReq } from "../../types/Vest";
import { useError } from "../../store/errorStore";
import { formatDisplayDate } from "../../utils/date";
import { getCurrencyType } from "../../utils/currencyFormatter";

function VestApproval({
  grant,
  onClose = () => {},
}: {
  grant: Grant;
  onClose: () => void;
}) {
  const currency = getCurrencyType();
  const { data } = useVestRequestDetails(grant.optionHolderId || 0);
  const { mutate: approveVesting, status } = useApproveVesting();
  const errorMessage = useError();

  function handleApprove() {
    if (status === "success") {
      onClose();
    } else {
      approveVesting(data as VestReq, {
        onSuccess: () => {
          toast("Vesting Approved !", { type: "success" });
          onClose();
        },
        onError: (err: any) => {
          errorMessage.setMessage(err.response.data.reason);
          toast(err.response.data.reason, { type: "error", autoClose: 2000 });
        },
      });
    }
  }

  return (
    <>
      <div className="p-4 px-10 mt-4 ml-3 text-lg font-medium stone-700">
        <h6 className="flex justify-between">
          Approve Vesting{" "}
          <span onClick={() => onClose()} className="cursor-pointer ">
            X
          </span>
        </h6>
      </div>
      <div className="border-b"></div>
      <VStack className="justify-between gap-4 p-4 px-10  min-h-[300px]">
        <HStack aria-label="section body" className="flex-wrap grow">
          {[
            { field: "Employee Name", value: data?.employeeName },
            {
              field: "Plan Name",
              value: data?.planName,
            },
            {
              field: "Event Completion Date",
              value:
                data?.eventDate &&
                formatDisplayDate(new Date(data?.eventDate).toUTCString()),
            },
            {
              field: "Options Available To Vest",
              value: data?.numberOfSharesToVest?.toLocaleString(currency),
            },
            {
              field: "Percentage",
              value: (
                (data?.percentageOfSharesToVest || 0) * 100
              ).toLocaleString(),
            },
            {
              field: "Return Unvested Options To Pool",
              value: data?.isResetUnvestedShares === true ? "Yes" : "No",
            },
          ].map(({ field, value }, i) => (
            <HStack key={i} className="w-1/2 gap-8 p-3">
              <span className="text-sm font-normal text-stone-700">
                {field}
              </span>
              <span className="text-sm font-medium text-gray-600">
                {value?.toString()}
              </span>
            </HStack>
          ))}
        </HStack>
        <HStack className="flex flex-row-reverse gap-4">
          <ButtonPrimary
            className={`flex items-center self-end justify-center ${
              status === "success" ? "bg-green-500" : ""
            }`}
            onClick={() => handleApprove()}
          >
            {(status === "idle" || status === "error") && "Approve Request"}
            {status === "loading" && (
              <Icon
                className=" animate-spin"
                icon="lucide:loader-2"
                width={36}
              />
            )}
            {status === "success" && (
              <Icon icon="clarity:success-standard-line" width={36} />
            )}
          </ButtonPrimary>
          <ButtonSecondary
            onClick={() => onClose()}
            className="text-gray-400 bg-slate-50"
          >
            Cancel
          </ButtonSecondary>
        </HStack>
      </VStack>
    </>
  );
}
export default VestApproval;
