import React, { useEffect, useState } from "react";
import { ChevronUp } from "../../utils/assets/chevronUp";
import { Check } from "../../utils/assets/check";
import { ChvronDown } from "../../utils/assets/chevronDown";
import useIsMobile from "../../utils/detectDevice";
import { HStack, VStack } from "../utils";

export type OptionTypes = {
  value: string;
};
export type MultiSelectPropsType = {
  multiple: boolean;
  placeholder: string;
  label?: string;
  _className?: string;
  options: OptionTypes[];
  handleSelection: (action: any) => void;
  optionValues: any[];
};
export function MultiSelect(props: MultiSelectPropsType) {
  const {
    multiple,
    placeholder,
    label,
    _className,
    handleSelection,
    optionValues,
  } = props;
  const [state, setState] = useState<any>({
    values: optionValues,
    focusedValue: -1,
    isFocused: false,
    isOpen: false,
    typed: "",
  });

  const { isMobile } = useIsMobile();
  function onClick() {
    setState({ ...state, isOpen: !state.isOpen });
  }

  function onDeleteOption(e: any) {
    const { value } = e.currentTarget.dataset;

    setState((prevState: any) => {
      const [...values] = prevState.values;
      const index = values.indexOf(value);
      values.splice(index, 1);
      return { values };
    });
  }
  useEffect(() => {
    handleSelection(state.values);
  }, [state]);

  function onClickOption(e: any) {
    const { value } = e.currentTarget.dataset;
    setState((prevState: any) => {
      if (!multiple) {
        return {
          values: [value],
          isOpen: false,
        };
      }

      const [...values] = prevState.values;
      const index = values.indexOf(value);

      if (index === -1) {
        values.push(value);
      } else {
        values.splice(index, 1);
      }
      return { values };
    });
  }

  function stopPropagation(e: any) {
    e.stopPropagation();
  }

  function renderValues() {
    const { values } = state;

    if (values.length === 0) {
      return <div className="placeholder text-sm py-0.5">{placeholder}</div>;
    }

    if (multiple) {
      return values.map((value: any) => (
        <div
          key={value}
          className="relative z-10 p-2 m-1 text-white items-center text-sm font-medium rounded value multiple bg-orange-501"
          onClick={(e) => stopPropagation(e)}
        >
          {value}
          <span
            data-value={value}
            onClick={(e) => onDeleteOption(e)}
            className="absolute py-0.5 px-1  cursor-pointer r-0 t-0"
          >
            <X />
          </span>
        </div>
      ));
    }

    return <VStack className="">{values[0]}</VStack>;
  }

  function renderOptions() {
    const { options } = props;
    const { isOpen } = state;

    if (!isOpen) {
      return null;
    }

    return (
      <div className="options">
        {options.map((option: OptionTypes, index: number) =>
          renderOption(option, index)
        )}
      </div>
    );
  }

  function renderOption(option: OptionTypes, index: number) {
    const { values, focusedValue } = state;

    const { value } = option;

    const selected = values.includes(value);

    let className = "option ";
    if (selected) className += " selected";
    if (index === focusedValue) className += " focused";

    return (
      <div
        key={value}
        data-value={value}
        className={className}
        onClick={(e) => onClickOption(e)}
      >
        {multiple ? (
          <span className="checkbox">{selected ? <Check /> : null}</span>
        ) : null}
        {value}
      </div>
    );
  }

  const { isOpen } = state;

  return (
    <VStack className={` rounded relative `}>
      <HStack
        className="relative items-center justify-between h-full border border-solid multiple-form-select"
        onClick={() => onClick()}
      >
        <div className="">{renderValues()}</div>
        <div className="absolute flex items-center p-2 text-center right-2">
          {isOpen ? <ChevronUp /> : <ChvronDown />}
        </div>
      </HStack>
      <div>{renderOptions()}</div>
    </VStack>
  );
}
export const X = () => (
  <svg
    viewBox="0 0 16 16"
    width={"1em"}
    height={"1em"}
    fill="currentColor"
    display={"block"}
  >
    <path d="M2 .594l-1.406 1.406.688.719 5.281 5.281-5.281 5.281-.688.719 1.406 1.406.719-.688 5.281-5.281 5.281 5.281.719.688 1.406-1.406-.688-.719-5.281-5.281 5.281-5.281.688-.719-1.406-1.406-.719.688-5.281 5.281-5.281-5.281-.719-.688z" />
  </svg>
);
