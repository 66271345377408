import { Icon } from "@iconify/react";
import { usePagination, DOTS } from "./usePagination";

const Pagination = (props: any) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul className="flex flex-row items-center gap-4">
      <li>
        <button disabled={currentPage === 1} onClick={onPrevious}>
          <Icon
            icon="ant-design:down-outlined"
            width="10"
            height="14"
            rotate={1}
            className={`hover:scale-125 hover:bg-slate-100 rounded-sm
             ${currentPage === 1 ? "text-gray-light" : "text-gray-dark"}
              `}
          />
        </button>
      </li>
      {paginationRange.map((pageNumber: number, key: number) => {
        if (pageNumber === DOTS) {
          return (
            <li key={key}>
              <p>&#8230;</p>
            </li>
          );
        }
        return (
          <li key={key}>
            <button
              disabled={pageNumber === currentPage}
              className={` text-sm rounded-md h-9 w-9  ${
                pageNumber === currentPage
                  ? "bg-orange-501 text-white "
                  : "text-gray-dark"
              }`}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </button>
          </li>
        );
      })}
      <li className="pagination-">
        <button disabled={currentPage === lastPage} onClick={onNext}>
          <Icon
            icon="ant-design:down-outlined"
            width="10"
            height="14"
            rotate={3}
            className={`hover:scale-125 hover:bg-slate-100 rounded-sm
             ${currentPage === lastPage ? "text-gray-light" : "text-gray-dark"}
              `}
          />
        </button>
      </li>
    </ul>
  );
};

export default Pagination;
