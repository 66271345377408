import { useMutation, useQuery } from "react-query";
import {
  approveVesting,
  doVestRequest,
  getAllVestings,
  getEventDetails,
  getVestableOptionsDetails,
  getVestRequestDetails,
  eventApproval,
  approveEvent,
  getEventDetailsForApproval,
  pauseVestingForEmployee,
} from "../api/vest";
import { queryClient } from "./client";

export function useVestableOptionsDetails(optionHolderId: number) {
  return useQuery({
    queryKey: ["vestableOptions", optionHolderId],
    queryFn: getVestableOptionsDetails,
  });
}

export function useDoVestRequest() {
  return useMutation({
    mutationKey: "doVestRequest",
    mutationFn: doVestRequest,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.refetchQueries("allGrants");
    },
    onError: () => {},
  });
}

export function useVestRequestDetails(optionHolderId: number) {
  return useQuery({
    queryKey: ["vestRequestDetails", optionHolderId],
    queryFn: getVestRequestDetails,
    staleTime: 0,
  });
}

export function useEventDetails(eventName: string) {
  return useQuery({
    queryKey: "eventDetails",
    queryFn: () => getEventDetails(eventName),
    staleTime: 0,
  });
}

export function useEventDetailsForApproval(eventName: string) {
  return useQuery({
    queryKey: "eventDetails",
    queryFn: () => getEventDetailsForApproval(eventName),
    staleTime: 0,
  });
}

export function useApproveVesting() {
  return useMutation({
    mutationKey: "approveVesting",
    mutationFn: approveVesting,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.refetchQueries("allGrants");
    },
    onError: (error) => {},
  });
}

export function useAllVestings() {
  return useQuery({
    queryKey: "allVestings",
    queryFn: getAllVestings,
    staleTime: 60000,
    select: (vestings) => {
      vestings = vestings.filter(
        (vesting) => vesting.optionHolderState !== "TERMINATED"
      );
      vestings = vestings.sort(
        (a, b) =>
          new Date(a.projectedVestingDate).getTime() -
          new Date(b.projectedVestingDate).getTime()
      );
      let cumulativeActualVestedOptions = 0;
      let cumulativeProjectedVestedOptions = 0;

      const vestingsWithCumulation = [];
      for (const vesting of vestings) {
        cumulativeActualVestedOptions += vesting.actualVestedOptions;
        cumulativeProjectedVestedOptions += vesting.projectedVestedOptions;
        const vestingExtended = {
          ...vesting,
          cumulativeActualVestedOptions,
          cumulativeProjectedVestedOptions,
        };
        vestingsWithCumulation.push(vestingExtended);
      }
      return vestingsWithCumulation;
    },
  });
}

export function useDoUpdateEventDetails() {
  return useMutation({
    mutationKey: "updateEvent",
    mutationFn: eventApproval,
    onSuccess: () => {
      queryClient.invalidateQueries("vestingEvents");
      queryClient.refetchQueries("vestingEvents");
    },
    onError: () => {},
  });
}

export function useDoApproveEvent() {
  return useMutation({
    mutationKey: "updateEvent",
    mutationFn: approveEvent,
    onSuccess: () => {
      queryClient.invalidateQueries("vestingEvents");
      queryClient.refetchQueries("vestingEvents");
    },
    onError: () => {},
  });
}

export function usePauseVesting() {
  return useMutation({
    mutationKey: "pauseVesting",
    mutationFn: pauseVestingForEmployee,
    onSuccess: () => {
      queryClient.invalidateQueries("employeePageDetails");
      queryClient.refetchQueries("employeePageDetails");
    },
    onError: () => {},
  });
}
