import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  approveExercise,
  getAllExercises,
  getExercisableOptionsDetails,
  getExerciseRequestDetails,
  requestToExercise,
} from "../api/exercise";
import { Result } from "../types/Exercise";
import { queryClient } from "./client";

export function useExercisableOptionsDetails(optionHolderId: number) {
  return useQuery({
    queryKey: ["exercisableOptions", optionHolderId],
    queryFn: getExercisableOptionsDetails,
    staleTime: 0,
  });
}

export function useRequestToExercise() {
  return useMutation({
    mutationKey: "doExerciseRequest",
    mutationFn: requestToExercise,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.refetchQueries("esopPlansSummary");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("allGrants");
    },
    onError: () => {},
  });
}

export function useExerciseRequestDetails(optionHolderId: number) {
  return useQuery({
    queryKey: ["exerciseRequestDetails", optionHolderId],
    queryFn: getExerciseRequestDetails,
    staleTime: 0,
    retry: false,
    onError: (error: AxiosError<Result>) =>
      toast(`Cannot Approve Exercise Because ${error.response?.data.reason}`, {
        type: "error",
        autoClose: 2000,
      }),
  });
}

export function useApproveExercise() {
  return useMutation({
    mutationKey: "approveExercise",
    mutationFn: approveExercise,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");

      queryClient.refetchQueries("esopPlansSummary");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("allGrants");
    },
    onError: () => {},
  });
}

export function useAllExercises() {
  return useQuery({
    queryKey: "allExercises",
    queryFn: getAllExercises,
    staleTime: 60000,
    select: (exercises) => {
      exercises = exercises.filter(
        (exercise) => exercise.state !== "Forfeited"
      );
      exercises = exercises.sort(
        (a, b) =>
          new Date(a.exerciseDate).getTime() -
          new Date(b.exerciseDate).getTime()
      );
      let cumulativeExercisedOptions = 0;

      const exercisesWithCumulation = [];
      for (const exercise of exercises) {
        cumulativeExercisedOptions +=
          exercise.stockExercisedOptions + exercise.cashExercisedOptions;
        const exerciseExtended = {
          ...exercise,
          cumulativeExercisedOptions,
        };
        exercisesWithCumulation.push(exerciseExtended);
      }
      return exercisesWithCumulation;
    },
  });
}
