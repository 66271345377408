import { useMutation, useQuery } from "react-query";
import { useAuthStore } from "../store/useAuthStore";
import {
  doLogin,
  doLogout,
  doResendOtp,
  doVerifyOtp,
  getCompanyExists,
  getHRMSConfigs,
  getUserDetails,
  setDefaultProduct,
} from "../api/Esop";
import { useAuthorizationStore } from "../store";
import { LoginResponse } from "../types/Login";
import { queryClient } from "./client";

export function uselogin() {
  return useMutation({
    mutationKey: "login",
    mutationFn: doLogin,
    onSuccess: (loginDto) => {
      if (loginDto && loginDto?.data?.reason !== "OTP Sent") {
        handleLogin(loginDto);
      }
    },
    onError: () => {},
  });
}

export function useUserDetails() {
  return useQuery({
    queryKey: "userDetails",
    queryFn: getUserDetails,
    onSuccess: (data) => {
      useAuthorizationStore.setState((store) => ({
        ...store,
        ...data.authorities[0],
      }));
      useAuthStore.setState((store) => ({
        ...store,
        user: data,
      }));
    },
  });
}

export function useLogout() {
  return useQuery({
    queryKey: "logout",
    queryFn: doLogout,
    onSuccess: () => {
      queryClient.clear();
    },
  });
}

export function useVerifyOtp() {
  return useMutation({
    mutationKey: "verification",
    mutationFn: doVerifyOtp,
    onSuccess: (verifiedLoginDto) => {
      if (verifiedLoginDto) handleLogin(verifiedLoginDto);
    },
    onError: () => {},
  });
}
export function useResendOtp() {
  return useMutation({
    mutationKey: "resend",
    mutationFn: doResendOtp,
  });
}

function handleLogin(loginDto: LoginResponse) {
  const accesstoken = loginDto.headers.accesstoken;
  if (accesstoken) {
    localStorage.setItem("accesstoken", accesstoken);
    localStorage.setItem("companyId", loginDto.data.company.id.toString());
  }
  queryClient.invalidateQueries("userDetails");
}

export function useGetCompanyExists(companyId: string) {
  return useQuery({
    queryKey: ["esopCompanyExists", companyId],
    queryFn: getCompanyExists,
  });
}
export function useCheckConfigExists() {
  return useQuery({
    queryKey: "hrmsconfig",
    queryFn: getHRMSConfigs,
  });
}

export function useSetDefaultProduct() {
  return useMutation({
    mutationKey: "setDefaultProduct",
    mutationFn: setDefaultProduct,
    onSuccess: () => {},
    onError: () => {},
  });
}
