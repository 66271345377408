import React from "react";
import {
  Box,
  ButtonSecondary,
  Center,
  HStack,
  VStack,
} from "../../components/utils";

const ActivityLog = () => {
  const data = [
    {
      title: "Cliffors is yet accept offer",
      time: "8 days ago",
    },
    {
      title: "Cliffors is yet accept offer",
      time: "7 days ago",
    },
    {
      title: "Cliffors is yet accept offer",
      time: "4 days ago",
    },
    {
      title: "Cliffors is yet accept offer",
      time: "8 days ago",
    },
  ];
  const activityLogData = [
    {
      time: "9:15",
      content: "content data",
      date: "23/12/2021",
      image: "A",
    },
    {
      time: "8:15",
      content: "content data",
      date: "23/12/2021",
      image: "A",
    },
    {
      time: "7:15",
      content: "content data",
      date: "23/12/2021",
      image: "A",
    },
    {
      time: "6:15",
      content: "content data",
      date: "23/12/2021",
      image: "A",
    },
    {
      time: "5:15",
      content: "content data",
      date: "23/12/2021",
      image: "A",
    },
    {
      time: "4:15",
      content: "content data",
      date: "23/12/2021",
      image: "A",
    },
  ];
  return (
    <Box>
      <HStack className="flex-wrap justify-start gap-6 p-6 ">
        <Box className="w-[48%] p-4 bg-white rounded">
          <Box className="mb-4">Full Activity log</Box>
          {activityLogData &&
            activityLogData.map((item) => (
              <>
                <Box className="w-full">
                  <Box className="flex flex-row justify-between p-2">
                    <Box className="flex flex-row justify-start space-x-3 ">
                      <Box>{item.time}</Box>
                      <Box className="relative items-center">
                        <Box className="flex w-4 h-4 border border-gray-600 border-solid rounded-full"></Box>
                        <Box className="absolute h-full border border-b-2 border-gray-600 border-solid left-2 top-4"></Box>
                      </Box>
                      <Box className="content">{item.content}</Box>
                    </Box>
                    <Box className="flex items-center space-x-4">
                      <Box>{item.date}</Box>
                      <Box className="w-6 h-6 border border-gray-600 border-solid rounded-full">
                        <Center>
                          <p>{item.image}</p>
                        </Center>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            ))}
        </Box>
        <Box className="w-[48%] p-4 bg-white rounded">
          <Box className="mb-4">Pending Actions</Box>
          {data.map((item, i) => (
            <HStack key={i} className="justify-between m-4">
              <VStack>
                <p className="text-xs3">{item.title}</p>
                <p className="text-xs1">{item.time}</p>
              </VStack>
              <ButtonSecondary className="h-6 px-2 py-1 text-xs2 text-slate-500 bg-slate-50">
                Notify on Email
              </ButtonSecondary>
            </HStack>
          ))}
        </Box>
      </HStack>
    </Box>
  );
};

export default ActivityLog;
