import React, { useEffect, useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import * as _ from "lodash";
import { useFormikContext } from "formik";
import { toast } from "react-toastify";
import { Dialog } from "@mui/material";
import {
  Box,
  ButtonPrimary,
  Center,
  HStack,
  VStack,
} from "../../components/utils";
import { globalFilter, sort } from "../../utils/arrays";
import BasicMenu from "../../components/shared/Menu";
import Pagination from "../../components/shared/Pagination";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import {
  HistoricalRoundModel,
  HistoricalRoundPopupDetail,
  Secondary,
  Transaction,
} from "../../types/HistoricDataModel";
import {
  initialSecondaryTransaction,
  initialRoundDetail,
  initialTransactionName,
} from "./initValues";
import AddRoundName from "../../modals/AddRoundName";
import AlertDialog from "../../components/shared/AlertDialog";
import AddConversion from "../../modals/AddConversion";
import AddRedemption from "../../modals/AddRedemption";
import AddSecondaryTransaction from "../../modals/AddSecondaryTransaction";
import { AllotmentTag } from "../../components/AllotmentTag";
import AddInvestorProfile from "../../modals/AddInvestorProfileDialog";
import {
  getOptionsForTransaction,
  getTotalAmount,
  getTotalShares,
} from "../../utils/historicUtilities";
import { formatDate } from "../../utils/date";
import { conversionTag, redemptionTag } from "./constantValues";

export default function SecondaryHistoricTransactionTable() {
  const formik = useFormikContext<HistoricalRoundModel>();
  const { values, setFieldValue } = formik;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [globalFilterText, setGlobalFilterText] = useState("");
  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    data?: HistoricalRoundPopupDetail | Secondary;
    mode?: string;
    index?: number;
  }>({
    open: false,
  });

  const [sortField, setSortField] = useState<{
    field: keyof Secondary | "" | undefined;
    ascending: boolean;
  }>({ field: "date", ascending: false });

  const [selectedItems, setSelectedItems] = useState<Secondary[]>([]);
  let secondariesList: Secondary[] = values.secondaries || [];
  secondariesList = useMemo(() => {
    if (!secondariesList) return [];
    const filterResult = globalFilter(secondariesList, globalFilterText, [
      "buyer",
      "seller",
      "amount",
      "numberOfShares",
      "pricePerShare",
      "date",
      "securityType",
      "roundName",
    ]);
    const sortResult = sort(
      filterResult,
      sortField?.field,
      sortField?.ascending
    );
    return sortResult;
  }, [secondariesList, globalFilterText, sortField]);

  const onSelectionChange = (secondaryTransaction: Secondary) => {
    setSelectedItems((state) =>
      state.find(
        (individualSecondaryTransaction) =>
          individualSecondaryTransaction === secondaryTransaction
      )
        ? state.filter(
            (individualSecondaryTransaction) =>
              individualSecondaryTransaction !== secondaryTransaction
          )
        : [...state, secondaryTransaction]
    );
  };

  useEffect(() => {
    if (selectAllChecked) {
      setSelectedItems(secondariesList);
    } else {
      setSelectedItems([]);
    }
  }, [secondariesList, selectAllChecked]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return secondariesList.slice(firstPageIndex, lastPageIndex);
  }, [secondariesList, currentPage, pageSize]);

  function handleAction(action: Action, secondaryTransaction?: Secondary) {
    const index = values.secondaries.findIndex(
      (individualSecondaries) => individualSecondaries === secondaryTransaction
    );
    if (action.name === "Edit" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Edit",
        data: secondaryTransaction,
        index,
      });
    } else if (action.name === "Popup" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Popup",
      });
    } else if (action.name === "Conversion" && !action.disabled) {
      handleTransactionAdd(
        index,
        values.secondaries[index].transactions?.length || 0,
        conversionTag.name
      );
      setDialog({
        open: true,
        mode: "Conversion",
        data: secondaryTransaction,
        index,
      });
    } else if (action.name === "Redemption" && !action.disabled) {
      handleTransactionAdd(
        index,
        values.secondaries[index].transactions?.length || 0,
        redemptionTag.name
      );
      setDialog({
        open: true,
        mode: "Redemption",
        data: secondaryTransaction,
        index,
      });
    } else if (action.name === "InvestorProfile" && !action.disabled) {
      setDialog({
        open: true,
        mode: "InvestorProfile",
        data: secondaryTransaction,
        index,
      });
    } else if (action.name === "AddRound" && !action.disabled) {
      handleAddRound(values.historicalRoundPopupDetails.length);
      setDialog({
        open: true,
        mode: "AddRound",
      });
    } else if (action.name === "Delete" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Delete",
        data: secondaryTransaction,
        message: "Do you want to delete this Transaction Details?",
      });
    } else if (action.name === "Clone" && !action.disabled) {
      handleAddTransaction(values.secondaries.length, secondaryTransaction);
      setDialog({
        open: true,
        mode: "Clone",
        data: secondaryTransaction,
        index,
      });
    }
  }

  function handleAddRound(index: number) {
    const roundDetail = [...values.historicalRoundPopupDetails];
    roundDetail.splice(index, 0, initialRoundDetail);
    setFieldValue("historicalRoundPopupDetails", roundDetail);
  }

  function handleAddTransaction(
    index: number,
    secondaryTransaction?: Secondary
  ) {
    const secondaryTransactions = [...values.secondaries];
    secondaryTransactions.splice(
      index,
      0,
      secondaryTransaction || initialSecondaryTransaction
    );
    setFieldValue("secondaries", secondaryTransactions);
  }

  const deleteAllSecondaryTransaction = () => {
    setDialog({
      open: true,
      mode: "DeleteAll",
      message: "Do you want to delete selected Transaction Details?",
    });
  };

  const deleteAllSelectedTransaction = () => {
    setFieldValue(
      "secondaries",
      values?.secondaries?.filter(
        (secondaryTransaction) => !selectedItems.includes(secondaryTransaction)
      )
    );
    toast("Selected Secondary Transactions Deleted Successfully!", {
      type: "success",
      autoClose: 2000,
    });
    setSelectAllChecked(false);
  };

  function deleteParticularSecondaryTransaction(
    secondaryTransaction?: Secondary
  ) {
    setFieldValue(
      "secondaries",
      values?.secondaries?.filter(
        (individualSecondaryTransaction) =>
          individualSecondaryTransaction !== secondaryTransaction
      )
    );
    toast("Secondary Transaction Deleted Successfully!", {
      type: "success",
      autoClose: 2000,
    });
  }

  function handleTransactionAdd(index: number, length: number, mode: string) {
    if (checkTransactionAdd(index, mode)) {
      const transactionDetail = [
        ...(values.secondaries[index].transactions || []),
      ];
      transactionDetail.splice(length, 0, initialTransactionName);
      setFieldValue(`secondaries[${index}].transactions`, transactionDetail);
    }
  }

  function checkTransactionAdd(index: number, mode: string): boolean {
    const length = values.secondaries[index].transactions?.length || 0;
    if (length < 1) {
      const transactionsDetail = values.secondaries[index].transactions;
      const transactions = transactionsDetail?.find(
        (transaction) => transaction.name === mode
      );
      return !transactions;
    }
    return true;
  }

  return (
    <div className="w-full bg-white rounded-md mt-10 pb-4">
      <Box className="w-full bg-white rounded-lg min-w-min">
        <HStack aria-label="toolbar" className="justify-between mb-8">
          <VStack className="min-w-max">
            <p className="text-lg font-medium text-gray-dark">
              Secondary Transactions
            </p>
            <p className="text-xs font-medium text-gray-light">
              {currentTableData.length} Results
            </p>
          </VStack>
          <HStack className="h-11 min-w-min">
            {selectedItems.length > 0 && (
              <Center className="p-2 px-3 mx-2 rounded text-slate-dark bg-slate-light">
                <Icon
                  icon="fluent:delete-28-regular"
                  color="#E85936"
                  className="rounded-xl"
                  onClick={deleteAllSecondaryTransaction}
                  width="20"
                  height="24"
                />
              </Center>
            )}
            <HStack className="items-center p-2 rounded w-72 bg-slate-light text-slate-dark mx-2">
              <Icon icon="fe:search" width="24" className="mr-2 " />
              <input
                type="text"
                className="w-full font-medium border-0 outline-none text-xs2 bg-inherit"
                placeholder="Search"
                value={globalFilterText}
                onChange={(e) => {
                  setGlobalFilterText(e.target.value);
                }}
              ></input>
            </HStack>
            <Center className="p-2 px-3 mx-2 rounded text-slate-dark bg-slate-light">
              <Icon
                icon="fluent:add-28-regular"
                color="#E85936"
                className="rounded-xl"
                onClick={() => {
                  handleAddRound(values.historicalRoundPopupDetails.length);
                  setDialog({
                    open: true,
                    mode: "AddRound",
                  });
                }}
                width="20"
                height="24"
              />
            </Center>
            <ButtonPrimary
              className="min-w-max"
              onClick={() => {
                handleAddTransaction(values.secondaries.length);
                handleAction({
                  name: "Popup",
                  disabled: false,
                });
              }}
            >
              Add
            </ButtonPrimary>
          </HStack>
        </HStack>
        <Dialog open={dialog.open} maxWidth="md">
          {dialog.mode === "Delete" ? (
            <AlertDialog
              onClose={() => setDialog({ open: false })}
              open={dialog.open}
              message={dialog.message}
              onPrimaryAction={() => {
                deleteParticularSecondaryTransaction(dialog.data);
                setDialog({ open: false });
              }}
              onSecondaryAction={() => setDialog({ open: false })}
            />
          ) : dialog.mode === "DeleteAll" ? (
            <AlertDialog
              onClose={() => setDialog({ open: false })}
              open={dialog.open}
              message={dialog.message}
              onPrimaryAction={() => deleteAllSelectedTransaction()}
              onSecondaryAction={() => setDialog({ open: false })}
            />
          ) : dialog.mode === "Popup" ? (
            <AddSecondaryTransaction
              mode="Add"
              transactionIndex={values.secondaries.length - 1}
              onPrimaryAction={() => setDialog({ open: false })}
              onSecondaryAction={() => {
                setDialog({ open: false });
              }}
            />
          ) : dialog.mode === "Clone" ? (
            <AddSecondaryTransaction
              mode="Clone"
              transactionIndex={values.secondaries.length - 1}
              onPrimaryAction={() => setDialog({ open: false })}
              onSecondaryAction={() => setDialog({ open: false })}
            />
          ) : dialog.mode === "Edit" ? (
            <AddSecondaryTransaction
              transactionIndex={dialog.index!}
              mode="Edit"
              onPrimaryAction={() => setDialog({ open: false })}
              onSecondaryAction={() => setDialog({ open: false })}
            />
          ) : dialog.mode === "AddRound" ? (
            <AddRoundName
              historicalRoundIndex={
                values.historicalRoundPopupDetails.length - 1
              }
              onPrimaryAction={() => setDialog({ open: false })}
              onSecondaryAction={() => setDialog({ open: false })}
            />
          ) : dialog.mode === "Conversion" ? (
            <AddConversion
              transactionIndex={dialog.index!}
              onPrimaryAction={() => setDialog({ open: false })}
              onSecondaryAction={() => setDialog({ open: false })}
              transactionType="secondaries"
            />
          ) : dialog.mode === "Redemption" ? (
            <AddRedemption
              transactionIndex={dialog.index!}
              onPrimaryAction={() => setDialog({ open: false })}
              onSecondaryAction={() => setDialog({ open: false })}
              transactionType="secondaries"
            />
          ) : dialog.mode === "InvestorProfile" ? (
            <AddInvestorProfile
              transactionIndex={dialog.index!}
              onPrimaryAction={() => setDialog({ open: false })}
              onSecondaryAction={() => setDialog({ open: false })}
              transactionType="secondaries"
            />
          ) : (
            <div></div>
          )}
        </Dialog>
        <Box className="overflow-auto max-h-full">
          <table className="w-full table-space">
            <thead className="text-xs font-medium text-gray-light">
              <tr className="">
                <td className="py-3">
                  <input
                    type="checkbox"
                    className="accent-orange-501 outline-hidden"
                    checked={selectAllChecked}
                    onChange={(e) => setSelectAllChecked(!selectAllChecked)}
                  ></input>
                </td>
                <>
                  <td
                    className="py-3 px-4  hover:cursor-pointer"
                    onClick={() =>
                      setSortField({
                        field: "buyer",
                        ascending: !sortField?.ascending,
                      })
                    }
                  >
                    Buyer
                  </td>
                </>

                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "seller",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Seller
                </td>

                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "pricePerShare",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Price/Share
                </td>
                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "numberOfShares",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  No. of Share
                </td>

                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "amount",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Amount
                </td>

                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "date",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Date
                </td>

                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "securityType",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Security
                </td>

                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "roundName",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Round
                </td>
                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "conversionDetails",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Transfer
                </td>
                <td className="py-3"></td>
              </tr>
            </thead>
            <tbody className={""}>
              {values.secondaries &&
                currentTableData?.map(
                  (secondaryTransaction: Secondary, index) => (
                    <tr key={index} className="border-t border-dashed ">
                      <td className="py-5 align-top">
                        <Box>
                          <input
                            type="checkbox"
                            className="accent-orange-501 outline-hidden"
                            checked={
                              selectedItems.indexOf(secondaryTransaction) !== -1
                            }
                            onChange={(e) =>
                              onSelectionChange(secondaryTransaction)
                            }
                          ></input>
                        </Box>
                      </td>

                      <td className="py-4 px-4 align-top ">
                        <HStack className="w-32 ">
                          <Box>
                            <p
                              className={` text-xs font-medium text-gray-dark `}
                            >
                              {secondaryTransaction.buyer}
                            </p>
                          </Box>
                        </HStack>
                      </td>

                      <td className="py-4 align-top ">
                        <HStack className="w-32 ">
                          <Box>
                            <p
                              className={` text-xs font-medium text-gray-dark `}
                            >
                              {secondaryTransaction.seller}
                            </p>
                          </Box>
                        </HStack>
                      </td>

                      <td className="py-4 align-top ">
                        <HStack className="w-20">
                          <Box>
                            <p
                              className={` text-xs font-medium text-gray-dark `}
                            >
                              {secondaryTransaction.pricePerShare}
                            </p>
                          </Box>
                        </HStack>
                      </td>
                      <td className="py-4 align-top ">
                        <HStack className="w-24 ">
                          <Box>
                            <p
                              className={` text-xs font-medium text-gray-dark `}
                            >
                              {secondaryTransaction.numberOfShares}
                            </p>
                          </Box>
                        </HStack>
                      </td>

                      <td className="py-4 align-top ">
                        <HStack className="w-28 ">
                          <Box>
                            <p
                              className={` text-xs font-medium text-gray-dark `}
                            >
                              {secondaryTransaction.amount}
                            </p>
                          </Box>
                        </HStack>
                      </td>

                      <td className="py-4 align-top ">
                        <HStack className="w-32 ">
                          <Box>
                            <p
                              className={` text-xs font-medium text-gray-dark `}
                            >
                              {formatDate(secondaryTransaction.date)}
                            </p>
                          </Box>
                        </HStack>
                      </td>

                      <td className="py-4 align-top ">
                        <HStack className="w-20">
                          <Box>
                            <p
                              className={` text-xs font-medium text-gray-dark `}
                            >
                              {secondaryTransaction.securityType}
                            </p>
                          </Box>
                        </HStack>
                      </td>

                      <td className="py-4 align-top ">
                        <HStack className="w-32 ">
                          <Box>
                            <p
                              className={` text-xs font-medium text-gray-dark `}
                            >
                              {secondaryTransaction.roundName}
                            </p>
                          </Box>
                        </HStack>
                      </td>

                      <td className="py-4 align-top ">
                        <HStack className="w-32 ">
                          <Box>
                            <HStack>
                              {secondaryTransaction.transactions &&
                                secondaryTransaction.transactions?.map(
                                  (transaction: Transaction, index) => (
                                    <AllotmentTag
                                      key={index}
                                      allotment={transaction}
                                    />
                                  )
                                )}
                            </HStack>
                          </Box>
                        </HStack>
                      </td>

                      <td className="px-2 py-4 align-top"></td>
                      <td className="px-2 py-4 align-top">
                        <CTADropdown
                          actions={getOptionsForTransaction(
                            secondaryTransaction.securityType || "Equity"
                          )}
                          onAction={(action) =>
                            handleAction(action, secondaryTransaction)
                          }
                        />
                      </td>
                    </tr>
                  )
                )}
              {currentTableData && (
                <tr key="total" className="border-t border-dashed ">
                  <td className="py-5 align-top">
                    <Box></Box>
                  </td>

                  <td className="py-4 px-4 align-top ">
                    <HStack className="w-32 ">
                      <Box>
                        <p className={` text-xs font-medium text-black`}>
                          Total
                        </p>
                      </Box>
                    </HStack>
                  </td>

                  <td className="py-4 align-top ">
                    <HStack className="w-32 "></HStack>
                  </td>

                  <td className="py-4 align-top ">
                    <HStack className="w-20"></HStack>
                  </td>
                  <td className="py-4 align-top ">
                    <HStack className="w-24 ">
                      <Box>
                        <p className={` text-xs font-medium text-gray-dark `}>
                          {getTotalShares(values.secondaries)}
                        </p>
                      </Box>
                    </HStack>
                  </td>

                  <td className="py-4 align-top ">
                    <HStack className="w-28 ">
                      <Box>
                        <p className={` text-xs font-medium text-gray-dark `}>
                          {getTotalAmount(values.secondaries)}
                        </p>
                      </Box>
                    </HStack>
                  </td>

                  <td className="py-4 align-top ">
                    <HStack className="w-32 "></HStack>
                  </td>

                  <td className="py-4 align-top ">
                    <HStack className="w-20"></HStack>
                  </td>

                  <td className="py-4 align-top ">
                    <HStack className="w-32 "></HStack>
                  </td>

                  <td className="py-4 align-top ">
                    <HStack className="w-32 "></HStack>
                  </td>

                  <td className="px-2 py-4 align-top"></td>
                  <td className="px-2 py-4 align-top"></td>
                </tr>
              )}
            </tbody>
          </table>
          {values.secondaries.length <= 0 && (
            <div className="text-black text-center p-4">
              <h1>
                No Secondary Transaction Found, , Please Add Secondary
                Transaction
              </h1>
            </div>
          )}
        </Box>
        <Box className="flex justify-between mt-8">
          <BasicMenu
            defaultValue={pageSize}
            options={[5, 10, 20, 50, 100]}
            onOptionChange={(value) => setPageSize(value)}
          />
          <Pagination
            className=""
            currentPage={currentPage}
            totalCount={secondariesList.length}
            pageSize={pageSize}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </Box>
      </Box>
    </div>
  );
}
