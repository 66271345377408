import { format } from "date-fns";
import randomColor from "randomcolor";
import { Action } from "../components/shared/Dropdown";
import { Investor, Secondary } from "../types/HistoricDataModel";

export function getOptionsForTransaction(securityType: string): Action[] {
  const options = [
    {
      name: "Edit",
      disabled: false,
    },
    {
      name: "Clone",
      disabled: false,
    },
    {
      name: "Delete",
      disabled: false,
    },
    {
      name: "InvestorProfile",
      disabled: false,
    },
  ];
  switch (securityType) {
    case "Equity":
      break;
    case "CCPS":
      options.push({
        name: "Conversion",
        disabled: false,
      });
      break;
    case "Note":
      options.push({
        name: "Conversion",
        disabled: false,
      });
      break;
    case "CCD":
      options.push({
        name: "Conversion",
        disabled: false,
      });
      break;
    case "OCD":
      options.push({
        name: "Conversion",
        disabled: false,
      });
      options.push({
        name: "Redemption",
        disabled: false,
      });
      break;
    case "NCD":
      options.push({
        name: "Redemption",
        disabled: false,
      });
      break;
    case "RPS":
      options.push({
        name: "Conversion",
        disabled: false,
      });
      options.push({
        name: "Redemption",
        disabled: false,
      });
      break;
    case "OCPS":
      options.push({
        name: "Conversion",
        disabled: false,
      });
      options.push({
        name: "Redemption",
        disabled: false,
      });
      break;
    case "Warrant":
      options.push({
        name: "Conversion",
        disabled: false,
      });
      options.push({
        name: "Redemption",
        disabled: false,
      });
      break;
    default:
      options.push({
        name: "Conversion",
        disabled: false,
      });
      options.push({
        name: "Redemption",
        disabled: false,
      });
      break;
  }
  return options;
}

export const getTotalAmount = (
  transactions: Investor[] | Secondary[]
): number => {
  let total = 0;
  transactions.forEach((transaction: Investor | Secondary) => {
    total += transaction?.amount || 0;
  });
  return total;
};

export const getTotalShares = (
  transactions: Investor[] | Secondary[]
): number => {
  let total = 0;
  transactions?.forEach((transaction: Investor | Secondary) => {
    total += transaction?.numberOfShares || 0;
  });
  return total;
};

export function getRandomColor(seedValuesForColor: string[]): string[] {
  const backgroundColor: string[] = [];
  seedValuesForColor.forEach((seedValue) => {
    const color = randomColor({
      luminosity: "bright",
      alpha: 0.3,
      seed: seedValue,
    });
    backgroundColor.push(color);
  });
  return backgroundColor;
}
