import { QueryFunctionContext } from "react-query";
import hissaApi from "./hissaApi";
import {
  ApproveExerciseReq,
  ExercisableOptionsDetails,
  ExerciseReq,
  ExerciseRequestDetails,
  Result,
  PlanExercise,
} from "../types/Exercise";

/**
 * resource: grant
 * action: view
 * @returns
 */

export async function getExercisableOptionsDetails(
  context: QueryFunctionContext
): Promise<ExercisableOptionsDetails> {
  return hissaApi
    .get(`esop/exercise/holder`, { params: { id: context.queryKey[1] } })
    .then((res) => res.data);
}

/**
 * resource: grant
 * action: requestExercise
 * @returns
 */

export async function requestToExercise(
  exerciseRequests: ExerciseReq[]
): Promise<Result> {
  return hissaApi
    .post(`esop/exercise`, exerciseRequests)
    .then((res) => res.data);
}

/**
 * resource: grant
 * action: view
 * @returns
 */

export async function getExerciseRequestDetails(
  context: QueryFunctionContext
): Promise<ExerciseRequestDetails> {
  return hissaApi
    .get(`esop/exercise/approve`, { params: { id: context.queryKey[1] } })
    .then((res) => res.data);
}

/**
 * resource: grant
 * action: approveExercise
 * @returns
 */

export async function approveExercise(
  approveExerciseReq: ApproveExerciseReq
): Promise<Result> {
  return hissaApi
    .post(`esop/exercise/approve`, approveExerciseReq)
    .then((res) => res.data);
}

/**
 * resource: grant
 * action: view
 * @returns
 */

export async function getAllExercises(): Promise<PlanExercise[]> {
  return hissaApi.get(`esop/exercises`).then((res) => res.data);
}
