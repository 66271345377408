import { Icon } from "@iconify/react";
import { Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { format } from "date-fns";
import { Input, Label } from "../../components/shared/InputField";
import { SwitchButton } from "../../components/shared/SwitchButton";
import {
  Box,
  ButtonPrimary,
  HStack,
  VStack,
  Error,
  ButtonPrimary1,
  isEsopViewer,
} from "../../components/utils";
import {
  useCreateOptionBuyback,
  useEditLiquidityEvent,
  useGetOptionBuyback,
  useGetUploadedBuybackOffers,
  useUploadEmployeeOffers,
} from "../../queries/optionBuyback";
import { useBuybackStore } from "../../store/optionBuyback";
import {
  baseParticipationConstraints,
  optionBuybackDetail,
} from "../../types/OptionBuyback";
import convertToBase64 from "../../utils/convertToBase64";
import { formatDate, formatWithTimeZone } from "../../utils/date";
import { useQuery } from "../../utils/useQuery";
import useIsMobile from "../../utils/detectDevice";
import { Select } from "../../components/shared/Select";
import { useEmployeeDepartment } from "../../queries";
import { MultiSelect, OptionTypes } from "../../components/shared/MultiSelect";
import StatusLabel from "../esopOverview/StatusLabel";
import Tooltip from "../../components/shared/Tooltip";
import { getCurrencySymbol } from "../../utils/currencyFormatter";

function CreateBuybackDetails() {
  const { id } = useParams();
  const isUserEsopViewer = isEsopViewer();
  const currencySymbol = getCurrencySymbol();
  const query = useQuery();
  const searchParam = query.get("mode");
  const _id = id || "";
  const navigate = useNavigate();
  const { data: departments } = useEmployeeDepartment();
  const deprt: OptionTypes[] = [];
  (departments || []).forEach((department) => {
    deprt.push({ value: department });
  });
  const { data: optionBuybackOverview } = useGetOptionBuyback();
  let optionBuybackList: optionBuybackDetail[] = [];
  const optionBuyback = optionBuybackOverview?.filter(
    (list) => parseInt((list?.id || 0).toString(), 10) === parseInt(_id, 10)
  );
  optionBuybackList = optionBuyback || [];
  const optionBuybackStore = useBuybackStore();

  function createInitialValues(): optionBuybackDetail {
    return {
      id: 0,
      name: "",
      description: "",
      percentage: 0,
      noOfOptions: 0,
      price: 0,
      offers: [],
      cumuOffers: [],
      vestingCutOffDate: "",
      startDate: formatDate(new Date().toString()),
      endDate: "",
      state: "",
      type: "",
      transactionType: "buyback",
      isWillingNessGiven: false,
      recurring: false,
      recurringInterval: 0,
      recurringIntervalUnit: "",
      isSuccessorGenerated: false,
      department: [],
      minimumTenure: 0,
      minimumVestedOptions: 0,
      minimumWillingness: 0,
      isAdvanceFilter: false,
      autoPilot: true,
      participationConstraints: [
        { interval: 0, intervalUnit: "", maxFrequency: 0 },
      ],
    };
  }

  function editInitialValues(): optionBuybackDetail {
    return {
      id: optionBuybackList[0]?.id,
      companyId: optionBuybackList[0]?.companyId,
      name: optionBuybackList[0]?.name,
      description: optionBuybackList[0]?.description,
      percentage: (optionBuybackList[0]?.percentage || 0) * 100,
      noOfOptions: optionBuybackList[0]?.noOfOptions,
      price: optionBuybackList[0]?.price,
      offers: optionBuybackList[0]?.offers,
      cumuOffers: optionBuybackList[0]?.offers,
      vestingCutOffDate: formatDate(
        new Date(
          optionBuybackList[0]?.vestingCutOffDate || "1970-01-01"
        ).toString()
      ),
      startDate: formatDate(
        new Date(optionBuybackList[0]?.startDate || "1970-01-01").toString()
      ),
      endDate: formatDate(
        new Date(optionBuybackList[0]?.endDate || "1970-01-01").toString()
      ),
      state: optionBuybackList[0]?.state,
      type: optionBuybackList[0]?.type,
      transactionType: optionBuybackList[0]?.transactionType,
      isWillingNessGiven: false,
      recurring: optionBuybackList[0]?.recurring,
      autoPilot: optionBuybackList[0]?.autoPilot,
      recurringInterval: optionBuybackList[0]?.recurringInterval,
      recurringIntervalUnit: optionBuybackList[0]?.recurringIntervalUnit,
      isSuccessorGenerated: optionBuybackList[0]?.isSuccessorGenerated,
      department: optionBuybackList[0]?.offerFilter?.department || [],
      minimumTenure: optionBuybackList[0]?.offerFilter?.minimumTenure || 0,
      minimumVestedOptions:
        optionBuybackList[0]?.offerFilter?.minimumVestedOptions || 0,
      minimumWillingness:
        optionBuybackList[0]?.offerFilter?.minimumWillingness || 0,
      participationConstraints:
        optionBuybackList[0]?.offerFilter?.participationConstraints || [],
    };
  }

  const validationSchema = Yup.object().shape({
    recurring: Yup.boolean(),
    name: Yup.string()
      .required("Name required")
      .min(3, "Name should be atleast 3 character long"),
    endDate: Yup.string().required("Buy Back offer end date required"),
    percentage: Yup.number()
      .required("Percentage required")
      .max(100, "Percentage should be less than 100")
      .positive(),
    description: Yup.string()
      .required("Event description is required")
      .min(10, "minimum 10 charcters required"),
    vestingCutOffDate: Yup.string().required(
      "Effective Buy Back date required"
    ),
    price: Yup.number().required("Buy Back price required").positive(),
    recurringInterval: Yup.number().when(["recurring"], {
      is: (recurring: boolean) => recurring,
      then: Yup.number()
        .positive("Recurring Interval must be a positive number")
        .integer("Recurring interval must be a integer")
        .required("Recurring Interval Required when creating recurring event"),
    }),
    recurringIntervalUnit: Yup.string().when(["recurring"], {
      is: (recurring: boolean) => recurring,
      then: Yup.string().required(
        "Recurring Interval unit Required when creating recurring event"
      ),
    }),
    minimumTenure: Yup.number().integer("Only accept integer value"),
    minimumVestedOptions: Yup.number().integer("Only accept integer value"),
    minimumWillingness: Yup.number().integer("Only accepts integer value"),
  });
  function getInitialValues() {
    if (searchParam === "edit") {
      return editInitialValues();
    } else {
      return createInitialValues();
    }
  }

  const { mutate: createOptionBuyback, isLoading } = useCreateOptionBuyback();
  const { mutate: editLiquidityEvent } = useEditLiquidityEvent();
  const { data: _offers } = useGetUploadedBuybackOffers();
  const offers = _offers as any;

  const { mutate: uploadOffers } = useUploadEmployeeOffers();
  const [uploadMessage, setUploadMessage] = useState<string>();
  async function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    const excelFile = { file: base64 };
    if (
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      uploadOffers(excelFile, {
        onSuccess: () => {
          setUploadMessage("Offer List uploaded successfully");
        },
        onError: () => {},
      });
      // eslint-disable-next-line no-useless-return
      return;
    }
  }
  useEffect(() => {
    if (
      (optionBuybackList[0]?.department || [])?.length > 0 &&
      searchParam === "edit"
    ) {
      setShowAdvanceFilters(true);
    }
  }, [optionBuybackList]);
  const { isMobile } = useIsMobile();
  const [showAdvanceFilters, setShowAdvanceFilters] = useState(false);

  function handleSubmit(vlaues: optionBuybackDetail) {
    let message = "Option Buy-Back successfully edited";

    const buybackOptionsDto = vlaues;
    buybackOptionsDto.startDate = formatWithTimeZone(
      buybackOptionsDto.startDate
    );
    if (!buybackOptionsDto.recurring) {
      buybackOptionsDto.recurringInterval = 0;
      buybackOptionsDto.recurringIntervalUnit = "";
      buybackOptionsDto.autoPilot = false;
    }
    buybackOptionsDto.endDate = formatWithTimeZone(buybackOptionsDto.endDate);
    buybackOptionsDto.vestingCutOffDate = formatWithTimeZone(
      buybackOptionsDto.vestingCutOffDate
    );
    buybackOptionsDto.percentage /= 100;
    buybackOptionsDto.state = "DRAFT";
    buybackOptionsDto.type = "Cash";
    if (searchParam === "create") {
      message = "Option buy-back successfully created";
      delete buybackOptionsDto.id;
    }
    if (
      buybackOptionsDto.percentage === 0 &&
      (searchParam === "create" || searchParam === "edit")
    ) {
      buybackOptionsDto.offers = offers || [];
      buybackOptionsDto.cumuOffers = offers || [];
    }
    if (searchParam === "edit") {
      editLiquidityEvent(buybackOptionsDto, {
        onSuccess: (resData) => {
          toast(message, {
            type: "success",
            autoClose: 2000,
          });
          optionBuybackStore.setShowdetails(true);
          navigate(`/options/view-option-buy-back/${resData.id}`);
        },
        onError: () => {
          toast("Something went wrong", { type: "error", autoClose: 5000 });
        },
      });
      return;
    }
    createOptionBuyback(buybackOptionsDto, {
      onSuccess: (resData) => {
        toast(message, {
          type: "success",
          autoClose: 2000,
        });
        optionBuybackStore.setShowdetails(true);
        navigate(`/options/view-option-buy-back/${resData.id}`);
      },
      onError: () => {
        toast("Something went wrong", { type: "error", autoClose: 5000 });
      },
    });
  }

  return (
    <>
      {" "}
      <Formik
        initialValues={getInitialValues()}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {(formik) => (
          <>
            <Form>
              <Box
                className={`flex ${
                  isMobile
                    ? "flex-col"
                    : "flex-row justify-between gap-12  items-center "
                }  p-4 bg-white border-b border-solid rounded`}
              >
                <Box className="flex flex-row items-center">
                  <h6
                    className={`${
                      isMobile ? "text-xs whitespace-nowrap" : "text-lg"
                    }  font-semibold text-gray-600`}
                  >
                    {searchParam === "create"
                      ? "Create option Buy Back"
                      : "Edit option Buy Back"}
                  </h6>
                  <div className="items-center px-6 py-1 rounded">
                    <StatusLabel
                      state={optionBuybackList[0]?.state || "Draft"}
                    />
                  </div>
                </Box>
                <HStack className="flex-row-reverse items-center justify-start gap-4">
                  {formik.values.recurring && (
                    <div className="flex flex-row-reverse items-center justify-start gap-1">
                      <SwitchButton
                        value={formik.values?.autoPilot}
                        onClick={(e) => {
                          formik.setFieldValue(
                            "autoPilot",
                            !formik.values?.autoPilot
                          );
                        }}
                        label={"Auto Pilot"}
                      />
                      <Tooltip
                        text={`Auto Pilot mode 'ON' will not require manual actions for start/close of a liquidity event. `}
                        _className="w-[400px]"
                      >
                        <Icon
                          icon="material-symbols:info-outline-rounded"
                          height={24}
                          width={24}
                          className="w-full text-xs font-medium rounded cursor-pointer text-slate-dark"
                        />
                      </Tooltip>
                    </div>
                  )}
                  <SwitchButton
                    className="text-xs font-medium text-gray-dark"
                    value={formik.values.recurring}
                    onClick={(e) => {
                      formik.setFieldValue(
                        "recurring",
                        !formik.values.recurring
                      );
                    }}
                    label={"Recurring Buy Back"}
                  />
                </HStack>
              </Box>
              <VStack className="gap-4 rounded">
                <VStack className="gap-8 p-4 bg-white rounded-md">
                  <Box
                    className={` flex ${
                      isMobile
                        ? " flex-col gap-4"
                        : "flex-row  justify-between gap-12"
                    } `}
                  >
                    <VStack className="w-full">
                      <Label>Buy Back Name</Label>
                      <Input type="text" {...formik.getFieldProps("name")} />
                      {formik.touched.name && formik.errors.name && (
                        <Error text={formik.errors.name} />
                      )}
                    </VStack>
                    <VStack className="w-full">
                      <Label>Buy Back Price ({currencySymbol})</Label>
                      <Input type="number" {...formik.getFieldProps("price")} />
                      {formik.touched.price && formik.errors.price && (
                        <Error text={formik.errors.price} />
                      )}
                    </VStack>
                  </Box>
                  <Box
                    className={` flex ${
                      isMobile
                        ? " flex-col gap-4"
                        : "flex-row  justify-between gap-12"
                    } `}
                  >
                    <VStack className="w-full">
                      <Label>Start Date</Label>
                      <Input
                        type="date"
                        {...formik.getFieldProps("startDate")}
                        min={format(new Date(), "yyyy-MM-dd")}
                      />
                      {formik.touched.startDate && formik.errors.startDate && (
                        <Error text={formik.errors.startDate} />
                      )}
                    </VStack>
                    <VStack className="w-full">
                      <Label>End Date</Label>
                      <Input
                        type="date"
                        {...formik.getFieldProps("endDate")}
                        min={formik.values.startDate}
                      />
                      {formik.touched.endDate && formik.errors.endDate && (
                        <Error text={formik.errors.endDate} />
                      )}
                    </VStack>
                  </Box>
                  <Box
                    className={` flex ${
                      isMobile
                        ? " flex-col gap-4"
                        : "flex-row  justify-between items-center gap-12"
                    } `}
                  >
                    <VStack className="w-full">
                      <Label>Description</Label>
                      <Input
                        type="text"
                        {...formik.getFieldProps("description")}
                      />
                      {formik.touched.description &&
                        formik.errors.description && (
                          <Error text={formik.errors.description} />
                        )}
                    </VStack>
                    {formik.values.recurring && (
                      <VStack className="w-full">
                        <Label>Recurring interval</Label>
                        <HStack
                          className={` items-center rounded bg-slate-light text-slate-dark`}
                        >
                          <Input
                            type="number"
                            placeholder="Enter Interval"
                            {...formik.getFieldProps("recurringInterval")}
                          />
                          <Box className="border-r-2 border-solid border-orange-501">
                            <p className="text-slate-light">|</p>
                          </Box>
                          <Select
                            options={["MONTH", "QUARTER", "YEAR"]}
                            className="flex flex-1 w-96 "
                            {...formik.getFieldProps("recurringIntervalUnit")}
                          />
                        </HStack>
                        <HStack className="justify-between">
                          {formik.touched.recurringInterval &&
                            formik.errors.recurringInterval && (
                              <Error text={formik.errors.recurringInterval} />
                            )}
                          {formik.touched.recurringIntervalUnit &&
                            formik.errors.recurringIntervalUnit && (
                              <Error
                                text={formik.errors.recurringIntervalUnit}
                              />
                            )}
                        </HStack>
                      </VStack>
                    )}
                  </Box>
                </VStack>
                <VStack className="bg-white rounded-md ">
                  <Box
                    className={` flex ${
                      isMobile
                        ? " flex-col gap-4"
                        : "flex-row  justify-between gap-12"
                    } border-b border-solid p-4 font-semibold text-xs text-gray-600`}
                  >
                    <h6
                      className={`${
                        isMobile ? "text-xs whitespace-nowrap" : "text-lg"
                      }  font-semibold text-gray-600"`}
                    >
                      Employee Related filters{" "}
                    </h6>
                  </Box>
                  <VStack className="gap-8 p-4 ">
                    <Box
                      className={` flex ${
                        isMobile
                          ? " flex-col gap-4"
                          : "flex-row  justify-between gap-12"
                      } `}
                    >
                      <VStack className="w-full">
                        <Label>Percentage of vested options</Label>
                        <Input
                          type="number"
                          {...formik.getFieldProps("percentage")}
                        />
                        {formik.touched.percentage &&
                          formik.errors.percentage && (
                            <Error text={formik.errors.percentage} />
                          )}
                      </VStack>
                      <VStack className="w-full">
                        <Label>Vesting Cut Off Date </Label>
                        <Input
                          type="date"
                          max={formik.values.startDate}
                          {...formik.getFieldProps("vestingCutOffDate")}
                        />
                        {formik.touched.vestingCutOffDate &&
                          formik.errors.vestingCutOffDate && (
                            <Error text={formik.errors.vestingCutOffDate} />
                          )}
                      </VStack>
                    </Box>
                    <HStack className="items-center gap-2 before:border-b-2 before:border-dashed before:m-auto after:flex-1 after:border-dashed after:content-[''] after:border-b-2">
                      <ButtonPrimary1
                        onClick={() => {
                          setShowAdvanceFilters(!showAdvanceFilters);
                          formik.setFieldValue(
                            "isAdvanceFilter",
                            !showAdvanceFilters
                          );
                        }}
                        className="flex flex-row bg-transparent border border-solid cursor-pointer border-orange-501 hover:bg-transparent"
                      >
                        Advance Filters
                        {showAdvanceFilters ? (
                          <Icon
                            icon="material-symbols:keyboard-arrow-down-rounded "
                            className=""
                          />
                        ) : (
                          <Icon
                            icon="material-symbols:keyboard-arrow-down-rounded "
                            className=""
                          />
                        )}
                      </ButtonPrimary1>
                    </HStack>
                    {showAdvanceFilters && (
                      <VStack className="gap-6">
                        <Box
                          className={` flex ${
                            isMobile
                              ? " flex-col gap-4"
                              : "flex-row  justify-between gap-12"
                          } `}
                        >
                          <VStack className="w-full">
                            <Label>Department (Optional)</Label>
                            <MultiSelect
                              _className="w-full"
                              multiple={true}
                              placeholder={"--Select departments--"}
                              options={deprt || []}
                              handleSelection={(e) => {
                                formik.setFieldValue("department", e);
                              }}
                              optionValues={formik.values.department || []}
                            />
                            {formik.touched.department &&
                              formik.errors.department && (
                                <Error text={formik.errors.department} />
                              )}
                          </VStack>
                          <VStack className="w-full">
                            <Label>
                              Minimum Employment Tenure (Year) (Optional)
                            </Label>
                            <Input
                              type="number"
                              {...formik.getFieldProps("minimumTenure")}
                            />
                            {formik.touched.minimumTenure &&
                              formik.errors.minimumTenure && (
                                <Error text={formik.errors.minimumTenure} />
                              )}
                          </VStack>
                        </Box>

                        <Box
                          className={` flex ${
                            isMobile
                              ? " flex-col gap-4"
                              : "flex-row  justify-between gap-12"
                          } `}
                        >
                          <VStack className="w-full">
                            <Label>Minimum Eligible Options (Optional)</Label>
                            <Input
                              type="number"
                              {...formik.getFieldProps("minimumVestedOptions")}
                            />
                            {formik.touched.minimumVestedOptions &&
                              formik.errors.minimumVestedOptions && (
                                <Error
                                  text={formik.errors.minimumVestedOptions}
                                />
                              )}
                          </VStack>

                          <VStack className="w-full">
                            <Label>Minimum Willingness (Optional)</Label>
                            <Input
                              type="number"
                              {...formik.getFieldProps("minimumWillingness")}
                            />
                            {formik.touched.minimumWillingness &&
                              formik.errors.minimumWillingness && (
                                <Error
                                  text={formik.errors.minimumWillingness}
                                />
                              )}
                          </VStack>
                        </Box>
                        <EventParticipationConstraints />
                      </VStack>
                    )}
                    {!isUserEsopViewer && (
                      <HStack
                        className={`${
                          isMobile ? "justify-center" : "flex-row-reverse"
                        }`}
                      >
                        <ButtonPrimary
                          type="submit"
                          className="items-center px-8 "
                        >
                          {!isLoading ? (
                            searchParam === "create" ? (
                              "Generate Offer List"
                            ) : (
                              "Save"
                            )
                          ) : (
                            <Icon
                              className="animate-spin"
                              icon="lucide:loader-2"
                              width={36}
                            />
                          )}
                        </ButtonPrimary>
                      </HStack>
                    )}
                  </VStack>
                </VStack>
              </VStack>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}

export default CreateBuybackDetails;

export function EventParticipationConstraints() {
  const formik = useFormikContext<optionBuybackDetail>();
  const { isMobile } = useIsMobile();
  const { values, errors, touched, getFieldProps, setFieldValue } = formik;
  function handleAdd() {
    const constraints = [...values.participationConstraints];
    const newConstraints = baseParticipationConstraints;
    constraints.push(newConstraints);

    setFieldValue("participationConstraints", constraints);
  }

  return (
    <>
      {values?.participationConstraints?.map((_, index) => {
        const constraints = values?.participationConstraints[index];
        const fieldTouched = touched?.participationConstraints?.[index];
        const err = errors?.participationConstraints?.[index] as any;
        return (
          <Box className={`flex flex-col`} key={index}>
            <Box
              className={`flex ${
                isMobile
                  ? " flex-col gap-4"
                  : "flex-row justify-start gap-12 items-center"
              } `}
            >
              <Box
                className={`${
                  isMobile ? "flex-col" : "flex-row items-center gap-4"
                } flex  justify-start `}
              >
                <Label className="whitespace-nowrap">
                  Maximum Participation (Optional)
                </Label>
                <Input
                  type="number"
                  placeholder="Enter Participation Number"
                  value={constraints.maxFrequency}
                  onChange={(e) =>
                    setFieldValue(
                      `participationConstraints[${index}].maxFrequency`,
                      e.target.value
                    )
                  }
                />
                {fieldTouched?.maxFrequency && err?.maxFrequency && (
                  <Error text={err?.maxFrequency} />
                )}
              </Box>
              <Box
                className={`${
                  isMobile ? "flex-col" : "flex-row items-center gap-4"
                } flex  justify-start `}
              >
                <Label className="whitespace-nowrap">
                  No of times (Optional){" "}
                </Label>
                <HStack
                  className={` items-center rounded bg-slate-light text-slate-dark`}
                >
                  <Input
                    type="number"
                    placeholder="Enter Interval"
                    value={constraints.interval}
                    onChange={(e) =>
                      setFieldValue(
                        `participationConstraints[${index}].interval`,
                        e.target.value
                      )
                    }
                  />
                  <Box className="border-r-2 border-solid border-orange-501">
                    <p className="text-slate-light">|</p>
                  </Box>
                  <Select
                    options={["MONTH", "QUARTER", "YEAR"]}
                    className=""
                    value={constraints.intervalUnit}
                    onChange={(e) =>
                      setFieldValue(
                        `participationConstraints[${index}].intervalUnit`,
                        e.target.value
                      )
                    }
                  />
                </HStack>
                {fieldTouched?.intervalUnit && err?.intervalUnit && (
                  <Error text={err?.intervalUnit} />
                )}
              </Box>
            </Box>
          </Box>
        );
      })}
      <ButtonPrimary1
        onClick={() => {
          handleAdd();
        }}
        className="max-w-min"
      >
        +Add
      </ButtonPrimary1>
    </>
  );
}
