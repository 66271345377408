import { useMutation, useQuery } from "react-query";
import {
  getAllUser,
  getEmailTemplateContent,
  getEmailTemplateNames,
  getShareCapitalDetail,
  getSiteSettingDetails,
  saveEmailTemplateContent,
  saveShareCapitalDetail,
  setSiteSettingDetails,
} from "../api/siteSettings";
import { queryClient } from "./client";

export function useGetSiteSettings() {
  return useQuery({
    queryKey: "getSiteSettingDetails",
    queryFn: getSiteSettingDetails,
    enabled: false,
  });
}

export function useSetSiteSettings() {
  return useMutation({
    mutationKey: "setSiteSettings",
    mutationFn: setSiteSettingDetails,
    onSuccess: () => {
      queryClient.invalidateQueries("getSiteSettingDetails");
      queryClient.refetchQueries("getSiteSettingDetails");
      queryClient.invalidateQueries("employeePageDetail");
      queryClient.refetchQueries("employeePageDetail");
    },
  });
}

export function useGetEmailTemplateNames() {
  return useQuery({
    queryKey: "emailTemplateNames",
    queryFn: getEmailTemplateNames,
  });
}
export function useGetEmailTemplateContent(templateName: string) {
  return useQuery({
    queryKey: ["emailTemplate", templateName],
    queryFn: getEmailTemplateContent,
    enabled: false,
  });
}

export function useSaveEmailTemplate() {
  return useMutation({
    mutationKey: "saveEmailTemplate",
    mutationFn: saveEmailTemplateContent,
  });
}

export function useGetAllCompanyUsers() {
  return useQuery({
    queryKey: "allCompanyUsers",
    queryFn: getAllUser,
  });
}

export function useGetShareCapitalDetails() {
  return useQuery({
    queryKey: "getShareCapitalDetails",
    queryFn: getShareCapitalDetail,
  });
}

export function useSaveShareCapitalDetails() {
  return useMutation({
    mutationKey: "saveShareCapitalDetails",
    mutationFn: saveShareCapitalDetail,
    onSuccess: () => {},
    onError: (error: { response: { data: { reason: string } } }) => {},
  });
}
