import { QueryFunctionContext } from "react-query";
import { CompanyCapitalDetails } from "../types/HistoricDataModel";
import { ShareCapitalDetailsType } from "../types/ShareCapital";
import { SiteSettingsRes, TemplateContent } from "../types/SiteSettings";
import { UserDetailModel } from "../types/UserDetailModel";
import hissaApi from "./hissaApi";

// details res type
export async function getSiteSettingDetails(): Promise<SiteSettingsRes> {
  return hissaApi.get("/AdminSetting/settingsV2").then((res) => res.data);
}

export async function setSiteSettingDetails(
  settingDetails: SiteSettingsRes
): Promise<SiteSettingsRes> {
  return hissaApi
    .post("/AdminSetting/saveV2", settingDetails)
    .then((res) => res.data);
}

export async function getEmailTemplateNames(): Promise<string[]> {
  return hissaApi.get("email-template/dropdown").then((res) => res.data);
}

export async function getEmailTemplateContent(
  context: QueryFunctionContext
): Promise<TemplateContent> {
  const query = `email-template/fetch?emailTemplateType=${context.queryKey[1]}`;
  return hissaApi.get(query).then((res) => res.data);
}

export async function saveEmailTemplateContent(
  templateContent: TemplateContent
): Promise<string> {
  return hissaApi.post("/email-template/upload", templateContent);
}

export async function getAllUser(): Promise<UserDetailModel[]> {
  return hissaApi.get("/user/getAllUser").then((res) => res?.data);
}

export async function getShareCapitalDetail(): Promise<CompanyCapitalDetails> {
  return hissaApi.get(`capital`).then((res) => res.data);
}

export async function saveShareCapitalDetail(
  companyCapitalDetails: CompanyCapitalDetails
): Promise<CompanyCapitalDetails> {
  return hissaApi.post(`capital`, companyCapitalDetails);
}
