import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { Box, ButtonPrimary, HStack, VStack } from "../../components/utils";
import { useCompanyUsers, useOwnerShip, useOwnersOfPlan } from "../../queries";
import { EsopPlan } from "../../types/EsopPlan";
import { AddOwnershipReq } from "../../types/Ownership";
import { User, UserDetailModel } from "../../types/UserDetailModel";

type AddOrEditOwnershipProps = {
  data?: EsopPlan;
  onClose: () => void;
};
type OptionType = { value: string; label: string };
export const AddOrEditOwnership = (props: AddOrEditOwnershipProps) => {
  const { data: _userData } = useCompanyUsers();
  const { data, onClose } = props;
  const { data: _ownerData, isFetched } = useOwnersOfPlan(
    data?.esopPlanId || 0
  );
  const [user, setUser] = useState<UserDetailModel[]>();
  const userNames = _userData?.map((userData: UserDetailModel) => ({
    value: userData.fullName,
    label: userData.fullName,
  }));
  const [planMode, setPlanMode] = useState(data?.scope === "PRIVATE");
  const [planModeType, setPlanModeType] = useState(data?.scope);
  useEffect(() => {
    const ownerNames = _ownerData?.map((ownerData: UserDetailModel) => ({
      value: ownerData.fullName,
      label: ownerData.fullName,
    }));
    setSelectedValue(ownerNames);
  }, [isFetched]);

  function showEmployeeList(e: React.MouseEvent<HTMLElement>) {
    setPlanMode(!planMode);
  }
  useEffect(() => {
    if (planMode === true) {
      setPlanModeType("PRIVATE");
    } else {
      setPlanModeType("PUBLIC");
    }
  }, [planMode]);
  const [selectedValue, setSelectedValue] = useState<OptionType[]>();
  function getSelectedUser(e: OptionType[]) {
    setSelectedValue(e);
  }
  useEffect(() => {
    if (!selectedValue) return;
    const selectedUsers: UserDetailModel[] = [];
    _userData?.forEach((userDetail) => {
      selectedValue.forEach((currentUser) => {
        if (userDetail.fullName === currentUser?.value) {
          selectedUsers.push(userDetail);
        }
      });
    });
    setUser(selectedUsers);
  }, [selectedValue]);

  const { mutate: savePlanOwner } = useOwnerShip();
  function submit() {
    const ownerShipData: AddOwnershipReq = {
      resourceId: data?.esopPlanId || 0,
      resourceType: "Plan",
      users: user || [],
      ownership: true,
      scope: planModeType || "PUBLIC",
    };
    if (user?.length === 0 && planModeType === "PRIVATE") {
      toast("Provide users", { type: "error", autoClose: 2000 });
      return;
    }
    savePlanOwner(ownerShipData, {
      onSuccess: () => {
        toast("Ownership modified successfully", {
          type: "success",
          autoClose: 2000,
        });
        onClose();
      },
      onError: () => {},
    });
  }
  return (
    <>
      <div className="p-4 text-lg font-medium border-b">
        <h6 className="flex justify-between">
          Modify Plan Ownership{" "}
          <span onClick={() => onClose()} className="cursor-pointer">
            <Icon icon="material-symbols:close-rounded" />
          </span>
        </h6>
      </div>
      <VStack className="px-8 ">
        <HStack className="gap-4 p-8">
          <div>
            <SwitchButton
              value={planMode}
              label={planModeType || ""}
              onClick={(e) => showEmployeeList(e)}
            />
          </div>

          {planModeType === "PRIVATE" ? (
            <div className="w-full">
              <Select
                options={userNames}
                isMulti
                value={selectedValue}
                onChange={(e) => getSelectedUser(e as OptionType[])}
              />
            </div>
          ) : (
            <div className="w-full">
              <Select
                placeholder={"This plan is public for Everyone"}
                isDisabled
              />
            </div>
          )}
        </HStack>
        <HStack className="justify-end px-8 pt-32 row-reverse">
          <ButtonPrimary onClick={() => submit()}>Submit </ButtonPrimary>
        </HStack>
      </VStack>
    </>
  );
};

export default AddOrEditOwnership;
