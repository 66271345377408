import React, { useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import * as _ from "lodash";
import { format } from "date-fns";
import { Box, HStack, VStack } from "../../components/utils";
import { globalFilter, sort } from "../../utils/arrays";
import BasicMenu from "../../components/shared/Menu";
import { Secondary } from "../../types/HistoricDataModel";
import Pagination from "../../components/shared/Pagination";
import { TabTransactionDetail, Tab } from "../../types/HistoricOverView";
import { getTotalAmount, getTotalShares } from "../../utils/historicUtilities";

export default function OverviewSecondaryTransactionTable({
  tabTransactionDetail,
}: TabTransactionDetail) {
  const values =
    tabTransactionDetail?.investorData.map((element) => ({
      buyer: element.buyerName,
      seller: element.sellerName,
      amount: element.amount,
      numberOfShares: element.numberOfShares,
      pricePerShare: element.pricePerShare,
      securityType: element.securityType,
      roundName: element.roundName,
      date: element.date,
    })) || [];
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [globalFilterText, setGlobalFilterText] = useState("");

  const [sortField, setSortField] = useState<{
    field: keyof Secondary | "" | undefined;
    ascending: boolean;
  }>({ field: "date", ascending: false });

  let secondaryList: Secondary[] = values || [];

  secondaryList = useMemo(() => {
    if (!secondaryList) return [];
    const filterResult = globalFilter(secondaryList, globalFilterText, [
      "buyer",
      "seller",
      "amount",
      "numberOfShares",
      "pricePerShare",
      "date",
      "securityType",
      "roundName",
    ]);
    const sortResult = sort(
      filterResult,
      sortField?.field,
      sortField?.ascending
    );
    return sortResult;
  }, [secondaryList, globalFilterText, sortField]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return secondaryList.slice(firstPageIndex, lastPageIndex);
  }, [secondaryList, currentPage, pageSize]);

  return (
    <Box>
      <HStack aria-label="toolbar" className="justify-between mb-8">
        <VStack className="min-w-max"></VStack>
        <HStack className="h-11 min-w-min">
          <HStack className="items-center p-2 rounded-lg w-72 bg-white text-slate-dark mx-2">
            <Icon icon="fe:search" width="24" className="mr-2 " />
            <input
              type="text"
              className="w-full font-medium border-0 outline-none text-xs2 bg-inherit"
              placeholder="Search"
              value={globalFilterText}
              onChange={(e) => {
                setGlobalFilterText(e.target.value);
              }}
            ></input>
          </HStack>
        </HStack>
      </HStack>

      <Box className="overflow-auto max-h-full">
        <table className="w-full table-space">
          <thead className="text-xs font-medium text-gray-light">
            <tr>
              <td
                className="py-3 px-4  hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "buyer",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Buyer
              </td>

              <td
                className="py-3 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "seller",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Seller
              </td>

              <td
                className="py-3 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "pricePerShare",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Price/Share
              </td>
              <td
                className="py-3 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "numberOfShares",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                No. of Share
              </td>

              <td
                className="py-3 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "amount",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Amount
              </td>

              <td
                className="py-3 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "date",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Date
              </td>

              <td
                className="py-3 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "securityType",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Security
              </td>

              <td
                className="py-3 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "roundName",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Round
              </td>
            </tr>
          </thead>
          <tbody className={""}>
            {values &&
              currentTableData?.map(
                (secondaryTransaction: Secondary, index) => (
                  <tr
                    key={secondaryTransaction.uuid}
                    className="border-t border-dashed "
                  >
                    <td className="py-4 px-4 align-top ">
                      <HStack className="w-32 ">
                        <Box>
                          <p className={` text-xs font-medium text-gray-dark `}>
                            {secondaryTransaction.buyer}
                          </p>
                        </Box>
                      </HStack>
                    </td>

                    <td className="py-4 align-top ">
                      <HStack className="w-32 ">
                        <Box>
                          <p className={` text-xs font-medium text-gray-dark `}>
                            {secondaryTransaction.seller}
                          </p>
                        </Box>
                      </HStack>
                    </td>

                    <td className="py-4 align-top ">
                      <HStack className="w-28">
                        <Box>
                          <p className={` text-xs font-medium text-gray-dark `}>
                            {secondaryTransaction.pricePerShare}
                          </p>
                        </Box>
                      </HStack>
                    </td>
                    <td className="py-4 align-top ">
                      <HStack className="w-28 ">
                        <Box>
                          <p className={` text-xs font-medium text-gray-dark `}>
                            {secondaryTransaction.numberOfShares}
                          </p>
                        </Box>
                      </HStack>
                    </td>

                    <td className="py-4 align-top ">
                      <HStack className="w-28 ">
                        <Box>
                          <p className={` text-xs font-medium text-gray-dark `}>
                            {secondaryTransaction.amount}
                          </p>
                        </Box>
                      </HStack>
                    </td>

                    <td className="py-4 align-top ">
                      <HStack className="w-32 ">
                        <Box>
                          <p className={` text-xs font-medium text-gray-dark `}>
                            {format(
                              new Date(secondaryTransaction.date || new Date()),
                              "yyyy-MM-dd"
                            )}
                          </p>
                        </Box>
                      </HStack>
                    </td>

                    <td className="py-4 align-top ">
                      <HStack className="w-20">
                        <Box>
                          <p className={` text-xs font-medium text-gray-dark `}>
                            {secondaryTransaction.securityType}
                          </p>
                        </Box>
                      </HStack>
                    </td>

                    <td className="py-4 align-top ">
                      <HStack className="w-32 ">
                        <Box>
                          <p className={` text-xs font-medium text-gray-dark `}>
                            {secondaryTransaction.roundName}
                          </p>
                        </Box>
                      </HStack>
                    </td>
                  </tr>
                )
              )}
            {values.length > 0 && (
              <tr key="total" className="border-t border-dashed ">
                <td className="py-4 px-4 align-top ">
                  <HStack className="w-32 ">
                    <Box>
                      <p className={` text-xs font-medium text-black`}>Total</p>
                    </Box>
                  </HStack>
                </td>

                <td className="py-4 align-top ">
                  <HStack className="w-32 "></HStack>
                </td>

                <td className="py-4 align-top ">
                  <HStack className="w-28"></HStack>
                </td>
                <td className="py-4 align-top ">
                  <HStack className="w-28 ">
                    <p className={` text-xs font-medium text-gray-dark `}>
                      {getTotalShares(currentTableData)}
                    </p>
                  </HStack>
                </td>

                <td className="py-4 align-top ">
                  <HStack className="w-28 ">
                    <p className={` text-xs font-medium text-gray-dark `}>
                      {getTotalAmount(currentTableData)}
                    </p>
                  </HStack>
                </td>

                <td className="py-4 align-top ">
                  <HStack className="w-32 "></HStack>
                </td>

                <td className="py-4 align-top ">
                  <HStack className="w-20"></HStack>
                </td>

                <td className="py-4 align-top ">
                  <HStack className="w-32 "></HStack>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {values.length === 0 && (
          <div className="text-black text-center p-4">
            <h1>No Secondary Transaction Found</h1>
          </div>
        )}
      </Box>
      <Box className="flex justify-between mt-8">
        <BasicMenu
          defaultValue={pageSize}
          options={[5, 10, 20, 50, 100]}
          onOptionChange={(value) => setPageSize(value)}
        />
        <Pagination
          currentPage={currentPage}
          totalCount={values.length}
          pageSize={pageSize}
          onPageChange={(page: number) => setCurrentPage(page)}
        />
      </Box>
    </Box>
  );
}
