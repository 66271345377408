import { useFormikContext } from "formik";
import { Input, Label } from "../../components/shared/InputField";
import { Box, Error, HStack, VStack } from "../../components/utils";
import { CompanyCapitalDetails } from "../../types/HistoricDataModel";

export default function CompanyShareCapital() {
  const formik = useFormikContext<CompanyCapitalDetails>();
  const { values, errors, touched, getFieldProps } = formik;
  const fieldTouched = touched;
  const err = errors;
  return (
    <div className="w-full mt-10 bg-white rounded-md">
      <Box className="px-10 py-4 text-lg font-medium text-left border-b">
        <h6>Share Capital Setting</h6>
      </Box>
      <VStack className="w-full gap-6 px-10 py-4">
        <VStack className="gap-2">
          <h6 className="text-lg">Authorised Capital</h6>
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Equity
                <span className="text-rose-400">*</span>
              </Label>
              <Input
                type="number"
                placeholder="Eg: 984839"
                className="w-120"
                {...getFieldProps("equityAuthorizedShareCapital")}
              />
              {fieldTouched?.equityAuthorizedShareCapital &&
                err?.equityAuthorizedShareCapital && (
                  <Error text={err?.equityAuthorizedShareCapital} />
                )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Preference
                <span className="text-rose-400">*</span>
              </Label>
              <Input
                type="number"
                placeholder="Eg: 984839"
                className="w-120"
                {...getFieldProps("preferenceAuthorizedShareCapital")}
              />
              {fieldTouched?.preferenceAuthorizedShareCapital &&
                err?.preferenceAuthorizedShareCapital && (
                  <Error text={err?.preferenceAuthorizedShareCapital} />
                )}
            </div>
          </HStack>
        </VStack>
        <VStack className="gap-2">
          <h6 className="text-lg">Paid Up Capital</h6>
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Equity
                <span className="text-rose-400">*</span>
              </Label>
              <Input
                type="number"
                placeholder="Eg: 984839"
                className="w-120"
                {...getFieldProps("equityPaidUpShareCapital")}
              />
              {fieldTouched?.equityAuthorizedShareCapital &&
                err?.equityAuthorizedShareCapital && (
                  <Error text={err?.equityAuthorizedShareCapital} />
                )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Preference
                <span className="text-rose-400">*</span>
              </Label>
              <Input
                type="number"
                placeholder="Eg: 984839"
                className="w-120"
                {...getFieldProps("preferencePaidUpShareCapital")}
              />
              {fieldTouched?.preferencePaidUpShareCapital &&
                err?.preferencePaidUpShareCapital && (
                  <Error text={err?.preferencePaidUpShareCapital} />
                )}
            </div>
          </HStack>
        </VStack>
      </VStack>
    </div>
  );
}
