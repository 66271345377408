import create from "zustand";

export type PoolState = {
  poolSize: number;
  poolPercentage: number;
  editDate: string;
  setPoolSize: (poolSize: number) => void;
  setPoolPercentage: (poolPercentage: number) => void;
  setEditDate: (editDate: string) => void;
};
const initialState = {
  editDate: "",
  poolSize: 0,
  poolPercentage: 0,
};
export const usePoolStore = create<PoolState>((set) => ({
  ...initialState,
  setPoolSize: (poolSize) => {
    set(() => ({ poolSize }));
  },
  setPoolPercentage: (poolPercentage) => {
    set(() => ({ poolPercentage }));
  },
  setEditDate: (editDate) => {
    set(() => ({ editDate }));
  },
}));
