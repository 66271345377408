import { useVestingTemplates } from "../../queries";
import VestingTemplatesTable from "./VestingTemplatesTable";
import { Box, VStack } from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import useIsMobile from "../../utils/detectDevice";

export function VestingTemplates() {
  const { data: _vestingSchedules } = useVestingTemplates();
  const vestingSchedules = _vestingSchedules?.filter(
    (schedule) => !schedule.isDefault
  );
  const { isMobile } = useIsMobile();
  return (
    <VStack className="gap-4 rounded-lg">
      <BCHeader className="items-baseline py-3" bcTitle="Vesting Schedule" />
      <Box className={`w-full ${isMobile ? "overflow-x-auto" : "grow"} `}>
        <VestingTemplatesTable data={vestingSchedules || []} />
      </Box>
    </VStack>
  );
}
