import createStore from "zustand";
import { configurePersist } from "zustand-persist";
import produce from "immer";

const { persist: _persist } = configurePersist({
  storage: localStorage,
  rootKey: "root",
});

const persist = _persist as any;

export interface Foundingteam {
  noOfOptions: number;
  percentageHolding: number;
}

export interface Advisor {
  noOfOptions: number;
  percentageHolding: number;
}

export interface Performance {
  noOfOptions: number;
  percentageHolding: number;
}

export interface GradeDetailsPerYear {
  gradeName: string;
  noOfEmployees: number;
  salary: number;
  optionMultiplier: number;
  optionValue: number;
  totalValue: number;
  noOfOptions: number;
  percentageHolding: number;
}

export interface YearlyDetailsSummary {
  year: number;
  valuation: number;
  sharePrice: number;
  totalNoOfShares: number;
  percentageHolding: number;
  noOfOptions: number;
  gradeDetailsPerYear: GradeDetailsPerYear[];
}

export interface AdditionalCompensation {
  grade: string;
  totalValue: number;
  noOfOptions: number;
  percentageHolding: number;
}

export interface GradeDetailsSummary {
  gradeName: string;
  salary: number;
  optionMultiplier: number;
  optionsValue: number;
  numberOfEmployee: number;
  totalValue: number;
  numberOfOptions: number;
  holdingPercentage: number;
}

export interface Summary {
  foundingteam: Foundingteam;
  advisors: Advisor;
  additionalCompensation: AdditionalCompensation[];
  yearlyDetailsSummary: YearlyDetailsSummary[];
  gradeDetailsSummary: GradeDetailsSummary[];
  refresh: Refresh[];
  performance: Performance;
}

export interface Refresh {
  year: number;
  grade: string;
  totalValue: number;
  noOfOptions: number;
  percentageHolding: number;
}

export type SpecialGrants = {
  refreshGrants: boolean;
  frequency: number;
  multiplierPercentage: number;
  coverage: number;
  reserveForAdvisors: number;
  reserveForFoundingTeam: number;
  reserveForPerformance: number;
};

export type HireGrades = {
  gradeName: string;
  totalEmployeesPerGrade: number;
  year: number[];
};

export type HiringPlans = {
  description: string;
  planName: string;
  planPeriod: number;
  hireGrades: HireGrades[];
};

export type ExistingEmployeesDetails = {
  gradeName: string;
  y0: number;
  y1: number;
  y2: number;
  y3: number;
  total: number;
};

export type SalaryPerGrades = {
  gradeName: string;
  optionMultiplier: number;
  currentEmployees: number;
  minSalary: number;
  maxSalary: number;
  division: string;
  role: string;
  salary: number;
};

export type CompensationTemplate = {
  note: string;
  templateName: string;
  planningPeriod: number;
  salaryPerGrades: SalaryPerGrades[];
};

export type AdditionalMultiplier = {
  beforeDate?: string;
  multiple?: number;
};

export type EsopModellingState = {
  selectedModel: string;
  modelName: string;
  description: string;
  currentSharePrice: number;
  valuation: number;
  compensationTemplate: CompensationTemplate[];
  selectedCompensationTemplate: CompensationTemplate;
  existingEmployeesDetails: ExistingEmployeesDetails[];
  isAdjustedOptionMulitplier: boolean;
  additionalMultiplier: AdditionalMultiplier[];
  hiringPlans: HiringPlans[];
  selectedHiringPlan: HiringPlans;
  sharePriceMultipliers: number[];
  valuationPerYear: number[];
  noOfSharesPerYear: number[];
  sharePricePerYear: number[];
  specialGrants: SpecialGrants;
  summary: Summary;
  setSelectedModel: (text: string) => void;
  setModelName: (text: string) => void;
  setDescription: (text: string) => void;
  setCurrentSharePrice: (currentSharePrice: number) => void;
  setValuation: (valuation: number) => void;
  setCompensationTemplate: (compensationTemplate: CompensationTemplate) => void;
  setSelectedCompensationTemplate: (
    compensationTemplate: CompensationTemplate
  ) => void;
  setExistingEmployeesDetails: (
    existingEmployeesDetails: ExistingEmployeesDetails
  ) => void;
  setExistingEmployeesDetailsFromStore: (
    existingEmployeesDetails: ExistingEmployeesDetails[]
  ) => void;
  setIsAdjustedOptionMulitplier: (isAdjustedOptionMulitplier: boolean) => void;
  setAdditionalMultiplierFromStore: (
    multiplier: AdditionalMultiplier[]
  ) => void;
  setAdditionalMultiplier: (additionalMultiplier: AdditionalMultiplier) => void;
  removeAdditionalMultiplier: (index: number) => void;
  removeExistingEmployeesDetails: (index: number) => void;
  setHiringPlans: (hiringPlans: HiringPlans) => void;
  setSelectedHiringPlan: (hiringPlan: HiringPlans) => void;
  setSharePriceMultipliers: (sharePriceMultipliers: number[]) => void;
  setValuationPerYear: (valuationPerYear: number[]) => void;
  setNoOfSharesPerYear: (noOfSharesPerYear: number[]) => void;
  setSharePricePerYear: (sharePricePerYear: number[]) => void;
  setSpecialGrants: (specialGrants: SpecialGrants) => void;
  setSummary: (summary: Summary) => void;
  reset: () => void;
};

const initialState = {
  selectedModel: "",
  modelName: "",
  description: "",
  currentSharePrice: 0,
  valuation: 0,
  compensationTemplate: [],
  selectedCompensationTemplate: {},
  existingEmployeesDetails: [],
  isAdjustedOptionMulitplier: false,
  additionalMultiplier: [],
  hiringPlans: [],
  selectedHiringPlan: {},
  sharePriceMultipliers: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  valuationPerYear: [],
  noOfSharesPerYear: [],
  sharePricePerYear: [],
  specialGrants: {
    refreshGrants: false,
    frequency: 0,
    multiplierPercentage: 0,
    coverage: 0,
    reserveForAdvisors: 0,
    reserveForFoundingTeam: 0,
    reserveForPerformance: 0,
  },
  summary: {
    foundingteam: {
      noOfOptions: 0,
      percentageHolding: 0,
    },
    advisors: {
      noOfOptions: 0,
      percentageHolding: 0,
    },
    additionalCompensation: [
      {
        grade: "",
        totalValue: 0,
        noOfOptions: 0,
        percentageHolding: 0,
      },
    ],
    yearlyDetailsSummary: [
      {
        year: 0,
        valuation: 0,
        sharePrice: 0,
        totalNoOfShares: 0,
        percentageHolding: 0,
        noOfOptions: 0,
        gradeDetailsPerYear: [
          {
            gradeName: "",
            noOfEmployees: 0,
            salary: 0,
            optionMultiplier: 0,
            optionValue: 0,
            totalValue: 0,
            noOfOptions: 0,
            percentageHolding: 0,
          },
        ],
      },
    ],
    gradeDetailsSummary: [
      {
        gradeName: "",
        salary: 0,
        optionMultiplier: 0,
        optionsValue: 0,
        numberOfEmployee: 0,
        totalValue: 0,
        numberOfOptions: 0,
        holdingPercentage: 0,
      },
    ],
    refresh: [
      {
        year: 0,
        grade: "",
        totalValue: 0,
        noOfOptions: 0,
        percentageHolding: 0,
      },
    ],
    performance: {
      noOfOptions: 0,
      percentageHolding: 0,
    },
  },
};
export const useEsopModellingStore = createStore<EsopModellingState>(
  persist(
    {
      key: "auth",
    },
    (set: any) => ({
      ...initialState,
      setSelectedModel: (text: string) => {
        set(() => ({ selectedModel: text }));
      },
      setModelName: (text: string) => {
        set(() => ({ modelName: text }));
      },
      setDescription: (text: string) => {
        set(() => ({ description: text }));
      },
      setCurrentSharePrice: (currentSharePrice: number) => {
        set(() => ({ currentSharePrice }));
      },
      setValuation: (valuation: number) => {
        set(() => ({ valuation }));
      },
      setCompensationTemplate: (template: CompensationTemplate) => {
        set(
          produce((state: EsopModellingState) => {
            let isEdit = false;
            state.compensationTemplate.map((item) => {
              if (item.templateName === template.templateName) {
                isEdit = true;
                item.planningPeriod = template.planningPeriod;
                item.salaryPerGrades = template.salaryPerGrades;
                return item;
              } else {
                return item;
              }
            });
            if (isEdit === false) {
              return {
                compensationTemplate: [...state.compensationTemplate, template],
              };
            } else {
              return state;
            }
          })
        );
      },
      setSelectedCompensationTemplate: (value: CompensationTemplate) => {
        set(() => ({ selectedCompensationTemplate: value }));
      },
      setExistingEmployeesDetails: (details: ExistingEmployeesDetails) => {
        set((state: EsopModellingState) => ({
          existingEmployeesDetails: [
            ...state.existingEmployeesDetails,
            details,
          ],
        }));
      },
      setExistingEmployeesDetailsFromStore: (
        details: ExistingEmployeesDetails[]
      ) => {
        set(() => ({ existingEmployeesDetails: details }));
      },
      setIsAdjustedOptionMulitplier: (value: number) => {
        set(() => ({ isAdjustedOptionMulitplier: value }));
      },
      setAdditionalMultiplierFromStore: (
        multiplier: AdditionalMultiplier[]
      ) => {
        set(() => ({ additionalMultiplier: multiplier }));
      },
      setAdditionalMultiplier: (multiplier: AdditionalMultiplier) => {
        set((state: EsopModellingState) => ({
          additionalMultiplier: [...state.additionalMultiplier, multiplier],
        }));
      },
      removeAdditionalMultiplier: (index: number) => {
        set((state: EsopModellingState) => {
          const array = [...state.additionalMultiplier];
          if (index !== -1) {
            array.splice(index, 1);
          }
          return { additionalMultiplier: array };
        });
      },
      removeExistingEmployeesDetails: (index: number) => {
        set((state: EsopModellingState) => {
          const array = [...state.existingEmployeesDetails];
          if (index !== -1) {
            array.splice(index, 1);
          }
          return { existingEmployeesDetails: array };
        });
      },
      setHiringPlans: (plan: HiringPlans) => {
        set(
          produce((state: EsopModellingState) => {
            let isEdit = false;
            state.hiringPlans.map((item) => {
              if (item.planName === plan.planName) {
                isEdit = true;
                item.description = plan.description;
                item.planName = plan.planName;
                item.planPeriod = plan.planPeriod;
                item.hireGrades = plan.hireGrades;
                return item;
              } else {
                return item;
              }
            });
            if (isEdit === false) {
              return {
                hiringPlans: [...state.hiringPlans, plan],
              };
            } else {
              return state;
            }
          })
        );
      },
      setSelectedHiringPlan: (value: HiringPlans) => {
        set(() => ({ selectedHiringPlan: value }));
      },
      setSharePriceMultipliers: (sharePriceMultipliers: number[]) => {
        set(() => ({ sharePriceMultipliers }));
      },
      setValuationPerYear: (valuationPerYear: number[]) => {
        set(() => ({ valuationPerYear }));
      },
      setNoOfSharesPerYear: (noOfSharesPerYear: number[]) => {
        set(() => ({ noOfSharesPerYear }));
      },
      setSharePricePerYear: (sharePricePerYear: number[]) => {
        set(() => ({ sharePricePerYear }));
      },
      setSpecialGrants: (specialGrant: SpecialGrants) => {
        set(
          produce((state: EsopModellingState) => {
            state.specialGrants = specialGrant;
          })
        );
      },
      setSummary: (summary: Summary) => {
        set(() => ({ summary }));
      },
      reset: () => {
        set(() => initialState);
      },
    })
  )
);
