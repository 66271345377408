import { Input, Label } from "../../components/shared/InputField";
import { Box, HStack, VStack } from "../../components/utils";
import { CompanyCapitalDetails } from "../../types/HistoricDataModel";

export type ShareCapitalDetailOverViewModel = {
  companyCapitalDetails: CompanyCapitalDetails;
};

export default function ShareCapitalDetailOverView({
  companyCapitalDetails,
}: ShareCapitalDetailOverViewModel) {
  return (
    <div className="w-full bg-white rounded-md mt-10">
      <Box className="px-10 text-lg text-left font-medium border-b py-4">
        <h6>Share Capital Setting</h6>
      </Box>
      <VStack className="w-full  px-10 py-4 gap-6">
        <VStack className="gap-2">
          <h6 className="text-lg">Authorised Capital</h6>
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">Equity</Label>
              <Input
                type="number"
                className="w-72"
                readOnly
                value={companyCapitalDetails?.equityAuthorizedShareCapital}
              />
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Preference</Label>
              <Input
                type="number"
                className="w-72"
                readOnly
                value={companyCapitalDetails?.preferenceAuthorizedShareCapital}
              />
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Total</Label>
              <Input
                readOnly
                type="number"
                className="w-72"
                value={
                  (companyCapitalDetails?.equityAuthorizedShareCapital || 0) +
                  (companyCapitalDetails?.preferenceAuthorizedShareCapital || 0)
                }
              />
            </div>
          </HStack>
        </VStack>
        <VStack className="gap-2">
          <h6 className="text-lg">Paid Up Capital</h6>
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">Equity</Label>
              <Input
                type="number"
                className="w-72"
                readOnly
                value={companyCapitalDetails?.equityAuthorizedShareCapital}
              />
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Preference</Label>
              <Input
                type="number"
                className="w-72"
                readOnly
                value={companyCapitalDetails?.preferencePaidUpShareCapital}
              />
            </div>

            <div className="flex-1">
              <Label className="text-sm font-normal">Total</Label>
              <Input
                readOnly
                type="number"
                className="w-72"
                value={
                  (companyCapitalDetails?.equityPaidUpShareCapital || 0) +
                  (companyCapitalDetails?.preferencePaidUpShareCapital || 0)
                }
              />
            </div>
          </HStack>
        </VStack>
      </VStack>
    </div>
  );
}
