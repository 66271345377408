import { useQuery } from "react-query";
import { getAllGrants } from "../api/Esop";

export type RequestType =
  | "Vest Request"
  | "Exercise Request"
  | "Grant Created"
  | "Grant Offered"
  | "Grant For Approval";

export type PendingRequest = {
  id?: number;
  planId?: number;
  type: RequestType;
  subject: string;
  requestDate?: Date;
  exerciseDate?: Date;
  lastDate?: Date;
};

export function usePendingRequests() {
  const { data: grants, ...rest } = useQuery({
    queryKey: "allGrants",
    queryFn: getAllGrants,
  });
  const pendingVestRequests: PendingRequest[] = (grants || [])
    .filter((grant) => grant.isVestRequested)
    .map((grant) => ({
      id: grant.optionHolderId,
      planId: grant.planid,
      type: "Vest Request",
      subject: `${grant.optionHolderName} has requested for vesting`,
      requestDate: grant.vestRequestDate
        ? new Date(grant.vestRequestDate)
        : new Date(),
    }));
  const pendingExerciseRequests: PendingRequest[] = (grants || [])
    .filter((grant) => grant.isExerciseRequested)
    .map((grant) => ({
      id: grant.optionHolderId,
      planId: grant.planid,
      type: "Exercise Request",
      subject: `${grant.optionHolderName} has requested exercise`,
      requestDate: grant.exerciseRequestDate
        ? new Date(grant.exerciseRequestDate)
        : new Date(),
    }));
  return {
    requests: [...pendingVestRequests, ...pendingExerciseRequests],
    ...rest,
  };
}
