import {
  bonusTag,
  buyBackTag,
  conversionTag,
  nonPricedTag,
  partialTag,
  redemptionTag,
} from "../pages/onboarding/constantValues";
import { Allotment, Transaction } from "../types/HistoricDataModel";

export function AllotmentTag({
  allotment,
}: {
  allotment: Allotment | Transaction;
}) {
  const cssBackGroundColor: Map<string, string> = new Map([
    [redemptionTag.name, "border-red-600 text-red-600 "],
    [conversionTag.name, "border-indigo-800 text-indigo-800 "],
    [buyBackTag.name, "border-blue-600 text-indigo-800 "],
  ]);

  return (
    <div
      className={`px-1 mb-1 mx-1 text-xs border-2 rounded-md font-semibold
     ${cssBackGroundColor.get(allotment.name)}`}
    >
      <p>{allotment?.identifier}</p>
    </div>
  );
}

export function OverViewTags({ tagName }: { tagName: string }) {
  const cssTextAndBackGroundColor: Map<string, string> = new Map([
    [bonusTag.name, "bg-red-600 "],
    [partialTag.name, "bg-indigo-800 "],
    [nonPricedTag.name, "bg-blue-600 "],
  ]);

  return (
    <div
      className={`px-1 mb-1 mx-1 text-xs rounded-md font-semibold text-white
    ${cssTextAndBackGroundColor.get(tagName)}`}
    >
      <p>{tagName}</p>
    </div>
  );
}
