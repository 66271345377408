import axios, { AxiosError, AxiosRequestHeaders } from "axios";
import { revalidateAuth } from "../components/utils";
import { useAuthStore } from "../store";

const esopNodeApi = axios.create({
  baseURL:
    localStorage.getItem("esopNodeApi") ||
    process.env.REACT_APP_ESOP_NODE_API ||
    "http://localhost:4000",
});
esopNodeApi.defaults.headers.post["Content-Type"] = "application/json";
esopNodeApi.interceptors.request.use((config) => {
  const accesstoken =
    localStorage.getItem("accesstoken") || useAuthStore.getState().accessToken;
  revalidateAuth();
  if (accesstoken && !config.url?.endsWith("/login")) {
    (config.headers as AxiosRequestHeaders).companyId =
      localStorage.getItem("companyId") || "";
    (config.headers as AxiosRequestHeaders).Authorization = accesstoken;
  }

  return config;
});

export default esopNodeApi;
