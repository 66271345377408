/* eslint-disable no-unsafe-optional-chaining */
import { Form, Formik } from "formik";
import _ from "lodash";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { Input, Label } from "../../components/shared/InputField";
import {
  Box,
  ButtonPrimary,
  HStack,
  VStack,
  isEsopViewer,
} from "../../components/utils";
import {
  useBuybackState,
  useEditPilotMode,
  useGetOptionBuyback,
} from "../../queries/optionBuyback";
import { useBuybackStore } from "../../store/optionBuyback";
import { optionBuybackDetail } from "../../types/OptionBuyback";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { formatDisplayDate } from "../../utils/date";
import StatusLabel from "../esopOverview/StatusLabel";
import useIsMobile from "../../utils/detectDevice";
import { useAdvanceFilterDialog } from "../../store/useDialogStore";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { Select } from "../../components/shared/Select";
import Tooltip from "../../components/shared/Tooltip";

function BuybackDetails() {
  const currencySymbol = getCurrencySymbol();
  const isUserEsopViewer = isEsopViewer();
  const currency = getCurrencyType();
  const navigate = useNavigate();
  const { id } = useParams();
  const _id = id || "";
  const optionBuybackStore = useBuybackStore();
  const { data: optionBuybackOverview, isFetching } = useGetOptionBuyback();
  // Actions
  const { state: showAdvanceFilterDialog, setState: setShowAdvanceFilters } =
    useAdvanceFilterDialog();

  let buybackDetail: optionBuybackDetail[] = [];
  if (_id !== " ") {
    buybackDetail = (optionBuybackOverview || [])?.filter(
      (item) => parseInt((item.id || 0)?.toString(), 10) === parseInt(_id, 10)
    );
  }

  const {
    refetch,
    data: _buybackStateData,
    isPlaceholderData,
  } = useBuybackState(parseInt(_id, 10));
  const buybackStateData = _buybackStateData || [];

  function getTotalEligibility() {
    let totalEligibility = 0;
    buybackStateData.forEach((item) => {
      totalEligibility += item.eligibility;
    });
    return totalEligibility;
  }
  function getTotalVestedShare() {
    let totalVestedShare = 0;
    buybackStateData.forEach((item) => {
      totalVestedShare += item.vested;
    });
    return totalVestedShare;
  }

  useEffect(() => {
    if (isFetching) return;
    refetch();
  }, [isFetching]);

  function editDetails() {
    navigate(`/options/edit-options-buyback/${_id}?mode=edit`);
    optionBuybackStore.setShowdetails(false);
  }
  const { isMobile } = useIsMobile();
  const { mutate: pilotMode, isLoading } = useEditPilotMode();
  function editAutoPilot(id: number | undefined, mode: boolean) {
    const pilotDto: { eventId: number | undefined; mode: boolean } = {
      eventId: id,
      mode,
    };
    // eslint-disable-next-line no-useless-return
    if (!id) return;
    pilotMode(pilotDto, {
      onSuccess: (data) => {
        toast(data, { autoClose: 2000, type: "success" });
      },
      onError: (err) => {
        toast("Something went wrong", { autoClose: 2000, type: "error" });
      },
    });
  }
  return (
    <VStack className={`${isPlaceholderData ? "loading" : ""} "gap-4 "`}>
      {/* {buybackDetail?.length > 0 && ( */}
      <>
        <Box
          className={`flex ${
            isMobile
              ? "flex-col"
              : "flex-row justify-between gap-12  items-center "
          }  p-4 bg-white border-b border-solid rounded`}
        >
          <Box className="flex items-center gap-12">
            <p
              className={`${
                isMobile ? "text-sm" : "text-lg"
              }  font-medium text-stone-700"`}
            >
              {buybackDetail[0]?.name}
            </p>
            <StatusLabel state={buybackDetail[0]?.state}></StatusLabel>
          </Box>
          <HStack className="items-center justify-start gap-12 ">
            <div className="flex flex-row-reverse items-center justify-start gap-2">
              <SwitchButton
                className="text-sm font-medium cursor-default text-gray-dark"
                value={buybackDetail[0]?.recurring}
                label={"Recurring Buy Back"}
              />
            </div>
            {buybackDetail[0]?.recurring && (
              <div className="flex flex-row-reverse items-center justify-start gap-1">
                {isLoading && (
                  <Icon
                    className="animate-spin text-orange-501"
                    icon="lucide:loader-2"
                    width={24}
                    height={24}
                  />
                )}
                <SwitchButton
                  value={buybackDetail[0]?.autoPilot}
                  label={"AutoPilot"}
                  className="text-sm font-medium cursor-pointer text-gray-dark"
                  onClick={() => {
                    if (!isUserEsopViewer)
                      editAutoPilot(
                        buybackDetail[0]?.id,
                        !buybackDetail[0]?.autoPilot
                      );
                  }}
                />

                <Tooltip
                  text={`Auto Pilot mode 'ON' will not require manual actions for start/close of a liquidity event. `}
                  _className="w-[400px]"
                >
                  <Icon
                    icon="material-symbols:info-outline-rounded"
                    height={24}
                    width={24}
                    className="w-full text-xs font-medium rounded cursor-pointer text-slate-dark"
                  />
                </Tooltip>
              </div>
            )}
          </HStack>
        </Box>
        <VStack
          className={`${
            isMobile ? "p-2" : "p-6"
          } gap-4 bg-[#fefefe] rounded m drop-shadow-md`}
        >
          <VStack className="gap-8">
            <Box
              className={` flex ${
                isMobile ? "flex-col gap-8" : "flex-row  justify-between gap-12"
              } `}
            >
              <div
                className={` flex flex-row items-center flex-1 gap-4 ${
                  isMobile ? "justify-between " : " justify-start "
                } `}
              >
                <div
                  className={`${
                    isMobile ? "whitespace-nowrap" : ""
                  } w-full text-sm text-gray-600 flex-nowrap whitespace-nowrap flex-1`}
                >
                  <p>Option Buy Back name</p>
                </div>
                <div
                  className={`${
                    isMobile ? "text-end" : " "
                  } text-black bg-white flex-1 `}
                >
                  <p>{buybackDetail[0]?.name}</p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-start flex-1 gap-4">
                {buybackDetail[0]?.percentage > 0 ? (
                  <>
                    <div
                      className={`${
                        isMobile ? "whitespace-nowrap" : ""
                      } w-full flex-1 text-sm text-gray-600 flex-nowrap whitespace-nowrap`}
                    >
                      <p>Percentage (%)</p>
                    </div>
                    <div
                      className={`${
                        isMobile ? "text-end" : " "
                      } text-black bg-white flex-1 `}
                    >
                      <p>{(buybackDetail[0]?.percentage || 0) * 100}</p>
                    </div>
                  </>
                ) : (
                  <p>Offer list uploaded successfully</p>
                )}
              </div>
            </Box>
            <Box
              className={` flex ${
                isMobile ? " flex-col gap-8" : "flex-row  justify-start gap-12"
              } `}
            >
              <div className="flex flex-row items-center justify-between flex-1 gap-4">
                <div
                  className={`${
                    isMobile ? "whitespace-nowrap" : ""
                  } w-full flex-1 text-sm text-gray-600 flex-nowrap whitespace-nowrap`}
                >
                  {" "}
                  <p>Buy Back price ({currencySymbol})</p>
                </div>
                <div
                  className={`${
                    isMobile ? "text-end" : " "
                  } text-black bg-white flex-1 `}
                >
                  <p>{buybackDetail[0]?.price}</p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between flex-1 gap-4">
                <div
                  className={`${
                    isMobile ? "whitespace-nowrap" : ""
                  } w-full flex-1 text-sm text-gray-600 flex-nowrap whitespace-nowrap`}
                >
                  {" "}
                  <p>Buy Back start date</p>
                </div>
                <div
                  className={`${
                    isMobile ? "text-end" : " "
                  } text-black bg-white flex-1 `}
                >
                  <p>
                    {formatDisplayDate(
                      buybackDetail[0]?.startDate || "1979-01-01"
                    )}
                  </p>
                </div>
              </div>
            </Box>
            <Box
              className={` flex ${
                isMobile ? " flex-col gap-8" : "flex-row  justify-start gap-12"
              } `}
            >
              <div className="flex flex-row items-center justify-between flex-1 gap-4">
                <div
                  className={`${
                    isMobile ? "whitespace-nowrap" : ""
                  } w-full flex-1 text-sm text-gray-600 flex-nowrap whitespace-nowrap`}
                >
                  {" "}
                  <p>Estimated value ({currencySymbol})</p>
                </div>
                <div
                  className={`${
                    isMobile ? "text-end" : " "
                  } text-black bg-white flex-1 `}
                >
                  <p>
                    {(
                      getTotalEligibility() * buybackDetail[0]?.price
                    ).toLocaleString()}
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between flex-1 gap-4">
                <div
                  className={`${
                    isMobile ? "whitespace-nowrap" : ""
                  } w-full flex-1 text-sm text-gray-600 flex-nowrap whitespace-nowrap`}
                >
                  {" "}
                  <p>Effective Buy Back date</p>
                </div>
                <div
                  className={`${
                    isMobile ? "text-end" : " "
                  } text-black bg-white flex-1 `}
                >
                  <p>
                    {formatDisplayDate(
                      buybackDetail[0]?.vestingCutOffDate || "1979-01-01"
                    )}
                  </p>
                </div>
              </div>
            </Box>
            <Box
              className={` flex ${
                isMobile ? " flex-col gap-8" : "flex-row  justify-start gap-12"
              } `}
            >
              <div className="flex flex-row items-center justify-between flex-1 gap-4">
                <div className="flex-1 w-full text-sm text-gray-600 flex-nowrap">
                  <p>Vested options</p>
                </div>
                <div
                  className={`${
                    isMobile ? "text-end" : " "
                  } text-black bg-white flex-1 `}
                >
                  <p>{getTotalVestedShare()}</p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-start flex-1 gap-4">
                <div className="flex-1 w-full text-sm text-gray-600 flex-nowrap">
                  <p>Buy-back offer end date</p>
                </div>
                <div
                  className={`${
                    isMobile ? "text-end" : " "
                  } text-black bg-white flex-1 `}
                >
                  <p>
                    {formatDisplayDate(
                      buybackDetail[0]?.endDate || "1979-01-01"
                    )}
                  </p>
                </div>
              </div>
            </Box>
            <Box
              className={` flex ${
                isMobile ? " flex-col gap-8" : "flex-row  justify-start gap-12"
              } `}
            >
              <div className="flex flex-row items-center justify-between flex-1 gap-4">
                <div className="flex-1 w-full text-sm text-gray-600 flex-nowrap">
                  {" "}
                  <p> Eligible options</p>
                </div>
                <div
                  className={`${
                    isMobile ? "text-end" : " "
                  } text-black bg-white flex-1 `}
                >
                  <p>{getTotalEligibility()}</p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between flex-1 gap-4">
                <div className="flex-1 w-full text-sm text-gray-600 flex-nowrap">
                  <p>Description</p>
                </div>
                <div
                  className={`${
                    isMobile ? "text-end" : " "
                  } text-black bg-white flex-1 `}
                >
                  <p>{buybackDetail[0]?.description}</p>
                </div>
              </div>
            </Box>
            {buybackDetail[0]?.recurring && (
              <VStack className="w-full">
                <p>Recurring interval</p>
                <HStack
                  className={`max-w-min items-center rounded bg-slate-light text-slate-dark`}
                >
                  <Input
                    type="number"
                    placeholder="Enter Interval"
                    disabled
                    value={buybackDetail[0]?.recurringInterval}
                  />
                  <Box className="w-full h-full border-r-2 border-solid border-orange-501"></Box>
                  <Select
                    options={["MONTH", "QUARTER", "YEAR"]}
                    className="flex flex-1 w-96 "
                    disabled
                    value={buybackDetail[0]?.recurringIntervalUnit}
                  />
                </HStack>
              </VStack>
            )}
            <Box
              onClick={() => setShowAdvanceFilters({ open: true, id: _id })}
              className="p-2 px-5 py-2 font-normal border border-dashed rounded cursor-pointer max-w-min whitespace-nowrap h-11 text-sm1 focus:opacity-90 hover:bg-slate-100 border-orange-501 "
            >
              Show Advance Filter
            </Box>
          </VStack>
          <HStack className="flex-row-reverse self-end">
            {buybackDetail[0]?.state === "DRAFT" && !isUserEsopViewer ? (
              <ButtonPrimary
                className="flex items-center self-end justify-center px-8 flex-nowrap "
                onClick={editDetails}
              >
                Edit Details
              </ButtonPrimary>
            ) : (
              ""
            )}
          </HStack>
        </VStack>
      </>
      {/* // )} */}
    </VStack>
  );
}

export default BuybackDetails;
