import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import { format } from "date-fns";
import { Box, HStack, VStack } from "../../components/utils";
import { useEsopPlans } from "../../queries/esopPlan";
import { EsopPlan } from "../../types/EsopPlan";
import AddOrEditPlan from "./AddPlan";
import { limitString, stringFormat } from "../../utils/string";
import Tooltip from "../../components/shared/Tooltip";
import { getCurrencyType } from "../../utils/currencyFormatter";
import useIsMobile from "../../utils/detectDevice";

const ESOPPlan = () => {
  const [dialog, setDialog] = useState<{
    open: boolean;
    plan?: EsopPlan;
    mode?: "Edit" | "Add" | "Clone";
  }>({ open: false, plan: undefined, mode: "Add" });

  const currency = getCurrencyType();
  const { id } = useParams();
  const _id = id || "";
  const { data: _plans } = useEsopPlans();
  const plans = _plans || [];
  const _employeePlans = plans?.filter(
    (plan) => plan?.esopPlanId === parseInt(_id, 10)
  );
  function editPlan(plan: EsopPlan) {
    setDialog({ open: true, mode: "Edit", plan });
  }

  const { isMobile } = useIsMobile();
  return (
    <>
      {_employeePlans.length > 0 && (
        <VStack className="bg-white rounded-lg h-96 ">
          <Box
            className={`grid grid-cols-2 gap-32 p-6 bg-wave bg-center bg-repeat rounded-lg place-content-between h-1/2 items-center`}
          >
            <VStack className="w-60">
              <Box className="font-medium text-white">
                {_employeePlans[0]?.planName}
              </Box>
            </VStack>
            <Tooltip
              text={stringFormat(_employeePlans[0]?.esopPlanState || "")}
            >
              <Box className="grid h-10 font-semibold text-center text-white bg-opacity-25 rounded bg-green-op place-items-center xl:w-20 md:w-16 md:text-xxs xl:text-xs ">
                {stringFormat(
                  limitString(_employeePlans[0]?.esopPlanState || "", 6)
                )}
              </Box>
            </Tooltip>
          </Box>
          <Dialog open={dialog.open} maxWidth="lg">
            <div className="w-[900px] mx-auto bg-white rounded-lg">
              <AddOrEditPlan
                mode="Edit"
                plan={dialog.plan}
                onClose={() => {
                  setDialog({ open: false });
                }}
              />
            </div>
          </Dialog>
          <HStack className="flex-wrap justify-between w-full px-2 py-2 -mt-12 overflow-auto grow h-1/2">
            {[
              {
                field: "Total Options",
                value: parseInt(
                  (
                    (_employeePlans[0]?.totalShares || 0) *
                    (_employeePlans[0]?.conversionNumber || 1)
                  ).toString(),
                  10
                ).toLocaleString(currency),
              },
              {
                field: "Holding %",
                value: (
                  (_employeePlans[0]?.planHoldingPercentage || 0) * 100
                ).toFixed(2),
              },
              { field: "Plan Type", value: _employeePlans[0]?.planType },
              {
                field: "Plan Start Date",
                value:
                  _employeePlans &&
                  format(
                    new Date(_employeePlans[0]?.planStartDate),
                    "dd MMM yyyy"
                  ),
              },
            ].map(({ field, value }, i) => (
              <Box
                key={i}
                className={` text-center border rounded-lg py-6 pb-2 mx-auto my-1 bg-slate-100 ${
                  isMobile ? "flex flex-col w-full" : " md:w-28 xl:w-36 sm:28"
                }`}
              >
                <p
                  className={`text-sm font-semibold ${
                    field === "Total Options"
                      ? "text-blue-500"
                      : field === "Holding %"
                      ? "text-amber-500"
                      : field === "Plan Type"
                      ? "text-teal-400"
                      : "text-rose-500"
                  }`}
                >
                  {value}
                </p>
                <p className="text-gray-500">{field}</p>
              </Box>
            ))}
          </HStack>
        </VStack>
      )}
    </>
  );
};
export default ESOPPlan;
