import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";

import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
} from "../../components/utils";
import {
  CheckBoxWithLabel,
  Input,
  Label,
} from "../../components/shared/InputField";
import { syncFromHRMS, validateHrmsConfig } from "../../api/Esop";
import { useCheckConfigExists, useUploadFile } from "../../queries";
import { Select } from "../../components/shared/Select";
import { UploadRes } from "../../types/Grant";
import Checkbox from "../../shared/checkbox";

type ImportProps = {
  onClose: () => void;
  onSuccess: () => void;
};
type GreytipCredentials = {
  greytip_host: string;
  api_user_name: string;
  api_user_password: string;
};
function EmployeeImport(props: ImportProps) {
  const { onClose, onSuccess } = props;
  const { mutate: upload, status: uploadStatus } = useUploadFile();
  const [excelFile, setExcelFile] = useState<UploadRes>();
  const [importMode, setImportMode] = useState("");
  const { data, isLoading } = useCheckConfigExists();
  const [configValid, setConfigValid] = useState(false);
  const [operationInProgress, setOperationInProgress] = useState(false);
  const [consentGiven, setConsentGiven] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      const greyTip = data?.find(
        (dataItem) => dataItem.masterDataType === "GREYTIP"
      );
      if (greyTip) setImportMode("GreyTip");
    }
  }, [data]);
  const availablePlatForms = [
    {
      name: "GreyTip",
      logo: "/greytip.png",
      disabled: false,
    },
    {
      name: "Excel",
      logo: "/excel.avif",
      disabled: true,
    },
  ];
  const [greyTipConfig, setGreyTipConfig] = useState<{
    type: string;
    credentialData: GreytipCredentials;
  }>({
    type: "GREYTIP",
    credentialData: {
      greytip_host: "",
      api_user_name: "",
      api_user_password: "",
    },
  });
  const handleConfigChange = (
    field: keyof GreytipCredentials,
    value: string
  ) => {
    setGreyTipConfig((prevConfig) => ({
      ...prevConfig,
      credentialData: {
        ...prevConfig.credentialData,
        [field]: value,
      },
    }));
  };
  async function handleExcelUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files?.[0];
    const formData = new FormData();
    formData.append("files", file);
    upload(formData, {
      onSuccess: (response) => {
        setExcelFile(response[0]);
      },
      onError: (err: any) => {
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
      },
    });
  }
  const handleSubmit = async () => {
    setOperationInProgress(true);
    if (configValid) {
      syncFromHRMS(greyTipConfig)
        .then((res) => {
          toast("Data Sync SuccessFul", {
            type: "success",
            autoClose: 2000,
          });
          setConfigValid(true);
          setOperationInProgress(false);
          onSuccess();
        })
        .catch((err) => {
          toast("Error occured while syncing", {
            type: "error",
            autoClose: 2000,
          });
          setConfigValid(false);
          setOperationInProgress(false);
        });
    } else {
      validateHrmsConfig(greyTipConfig)
        .then((res) => {
          toast("Credentials Validated, Please proceed to Import.", {
            type: "success",
            autoClose: 2000,
          });
          setConfigValid(true);
          setOperationInProgress(false);
        })
        .catch((err) => {
          toast("Error ocurred while verifying credentials", {
            type: "error",
            autoClose: 2000,
          });
          setConfigValid(false);
          setOperationInProgress(false);
        });
    }
  };
  return (
    <>
      <Box className="px-10 text-lg w-full font-medium border-b py-7">
        <h6 className="flex justify-between">
          Import Employees{" "}
          <span onClick={() => onClose()} className="cursor-pointer">
            X
          </span>{" "}
        </h6>
      </Box>
      <HStack className="w-full p-4 justify-evenly">
        {importMode === "" &&
          availablePlatForms.map((platform) => (
            <HStack
              key={platform.name}
              className={`gap-4 ${
                platform.disabled ? "cursor-not-allowed" : ""
              }`}
            >
              <VStack className="justify-between">
                <img
                  src={platform.logo}
                  alt={platform.name}
                  height={200}
                  width={200}
                  className="mb-4 aspect-auto"
                />
                <div className="w-full align-middle justify-center text-center">
                  <h3 className="text-sm font-medium">
                    Import from {platform.name}
                  </h3>
                  <ButtonPrimary
                    className={`${
                      platform.disabled ? "cursor-not-allowed" : ""
                    }`}
                    disabled={platform.disabled}
                    onClick={() => setImportMode(platform.name)}
                    type="submit"
                  >
                    Import
                  </ButtonPrimary>
                </div>
              </VStack>
            </HStack>
          ))}
        {importMode === "GreyTip" && (
          <HStack key="greytip-import">
            <VStack className="justify-between">
              <Box className="px-10 flex justify-center text-lg w-full font-medium">
                <h6 className="flex justify-between">
                  Enter Your Greytip Credentials
                </h6>
              </Box>
              <HStack className="gap-8 pt-8">
                <div className="flex-1">
                  <Label className="text-sm font-normal">GreyTip Host</Label>
                  <Input
                    autoComplete="false"
                    type="text"
                    placeholder="eg : *.greythr.com"
                    onChange={(e) =>
                      handleConfigChange("greytip_host", e.target.value)
                    }
                  />
                </div>
                <div className="flex-1">
                  <Label className="text-sm font-normal">
                    GreyTip API Username
                  </Label>
                  <Input
                    type="text"
                    onChange={(e) =>
                      handleConfigChange("api_user_name", e.target.value)
                    }
                  />
                </div>
                <div className="flex-1">
                  <Label className="text-sm font-normal">
                    GreyTip API Password
                  </Label>
                  <Input
                    type="password"
                    onChange={(e) =>
                      handleConfigChange("api_user_password", e.target.value)
                    }
                  />
                </div>
              </HStack>
              <HStack className="mt-8">
                <CheckBoxWithLabel
                  label="By checking this box, I authorize HISSA to access my Employee data from Greytip."
                  labelclassname={"ml-2"}
                  onChange={(e) => setConsentGiven(e.target.checked)}
                />
              </HStack>
            </VStack>
          </HStack>
        )}
      </HStack>
      {importMode !== "" && (
        <HStack className="justify-end gap-4 p-3">
          <ButtonPrimary1
            type="reset"
            className="text-red-500"
            onClick={() => setImportMode("")}
          >
            Cancel
          </ButtonPrimary1>
          {!configValid && !operationInProgress && (
            <ButtonPrimary
              disabled={!configValid && !consentGiven}
              onClick={handleSubmit}
              type="submit"
            >
              Continue
            </ButtonPrimary>
          )}
          {configValid && !operationInProgress && (
            <ButtonPrimary onClick={handleSubmit} type="submit">
              Import
            </ButtonPrimary>
          )}
          {operationInProgress && (
            <Icon
              className="animate-spin text-orange-501"
              icon="lucide:loader-2"
              width={36}
            />
          )}
        </HStack>
      )}
    </>
  );
}
const FieldMapper = (props: { mapperData: any }) => {
  const { mapperData } = props;
  const allValues = Object.values(mapperData).map((val: any) => val.field);
  return (
    <table className="justify-between gap-4 w-3/4 ">
      <thead className="flex flex-row justify-between gap-4 w-3/4 ">
        <tr>
          <th>Internal Fields</th>
          <th>External Fields</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(mapperData).map((key) => (
          <tr
            key={key}
            className="flex flex-row justify-between align-middle border-b border-dashed rounded py-2"
          >
            <td>{key}</td>
            <td>
              <Select
                value={mapperData[key]?.field}
                options={allValues}
                name=""
                id=""
              ></Select>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default EmployeeImport;
