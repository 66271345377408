import { Icon } from "@iconify/react";
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import produce from "immer";
import { AxiosError } from "axios";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import {
  ButtonPrimary,
  HStack,
  VStack,
  ButtonSecondary,
} from "../../components/utils";
import { EsopPlan } from "../../types/EsopPlan";
import {
  useGetAllPlanDocs,
  useGetPresignedUrl,
  useUpdatePlanDocs,
} from "../../queries/esopPlan";
import FileInput from "../../components/shared/FileInput";
import { DocumentInterface } from "../../types/DocumentsInterface";
import convertToBase64 from "../../utils/convertToBase64";

type DocumentsDialogProps = {
  onClose: () => void;
  plan?: EsopPlan;
};

export default function DocumentsDialog(props: DocumentsDialogProps) {
  const { onClose, plan } = props;
  const { data, isFetched } = useGetAllPlanDocs(plan?.esopPlanId || 0);
  const { mutate: updatePlanDoc } = useUpdatePlanDocs();
  const [currentDocumentPath, setCurrentDocumentPath] = useState("");
  const [alreadyFetchedPresignedUrls, setFetchedPresignedUrls] = useState(
    new Map()
  );
  const [availablePlanDocs, setAvailablePlanDocs] = useState<
    Array<DocumentInterface>
  >([]);
  const existingPlanDocs = data;
  const allowedDocumentTypes = ["ESOP_PLAN", "ESOP_BOARD", "OTHERS"];
  useEffect(() => {
    setAvailablePlanDocs(data || []);
  }, [isFetched]);
  const baseDocument: DocumentInterface = {
    documentName: "",
    entityType: "ESOP_PLAN",
    toBeAdded: true,
    entityId: plan?.esopPlanId || 0,
  };
  const allowedExtensions = ["docx", "doc", "pdf"];
  const {
    refetch,
    data: presignedUrl,
    isFetched: presignedUrlFetched,
  } = useGetPresignedUrl(currentDocumentPath);

  async function fetchAndViewDocument(
    documentPath: DocumentInterface["documentPath"]
  ) {
    const existingPresignedUrl = alreadyFetchedPresignedUrls.get(
      documentPath
    ) as string;
    if (existingPresignedUrl) {
      window.open(existingPresignedUrl, "_blank", "noreferrer");
    }
    setCurrentDocumentPath(documentPath as string);
  }

  useEffect(() => {
    if (presignedUrlFetched && presignedUrl) {
      const fetchedPresignedUrlCopy = alreadyFetchedPresignedUrls;
      fetchedPresignedUrlCopy.set(currentDocumentPath, presignedUrl?.s3Key);
      setFetchedPresignedUrls(fetchedPresignedUrlCopy);
      window.open(presignedUrl?.s3Key, "_blank", "noreferrer");
    }
  }, [presignedUrlFetched]);

  useEffect(() => {
    if (currentDocumentPath) refetch();
  }, [currentDocumentPath]);

  function handleSubmit() {
    if (
      availablePlanDocs.some(
        (doc) =>
          doc.documentName === undefined ||
          doc.documentName === "" ||
          !validateDocumentType(doc.entityType) ||
          doc.b64File === undefined ||
          doc.b64File === ""
      )
    ) {
      toast(`Please fill all the mandatory fields`, {
        type: "error",
        autoClose: 3000,
      });
      return;
    }
    if (existingPlanDocs !== availablePlanDocs) {
      updatePlanDoc(availablePlanDocs, {
        onSuccess() {
          toast(`Document uploaded successfully!`, {
            type: "success",
            autoClose: 3000,
          });
          onClose();
        },
        onError(error) {
          toast((error as AxiosError).response?.data as string, {
            type: "error",
            autoClose: 3000,
          });
        },
      });
    } else {
      onClose();
    }
  }

  async function handleFileInput(e: any, index: number) {
    const file = e.target.files[0] as File;
    if (file) {
      const splitFileName = file.name.split(".");
      const fileExtension = splitFileName[splitFileName.length - 1];
      if (!allowedExtensions.includes(fileExtension))
        toast(
          `Invalid file type, only ${allowedExtensions.toString()} are allowed`,
          { type: "error", autoClose: 3000 }
        );
      else {
        const b64File = (await convertToBase64(file)) as string;
        setAvailablePlanDocs(
          produce((availablePlanDocs) => {
            availablePlanDocs[index].b64File = b64File;
            availablePlanDocs[index].contentType = file.type;
          })
        );
      }
    }
  }

  function handleAdd() {
    setAvailablePlanDocs(
      produce((availablePlanDocs) => {
        const newDocument = baseDocument;
        availablePlanDocs.push(newDocument);
      })
    );
  }

  function handleEdit(
    index: number,
    changedField: keyof DocumentInterface,
    changeEvent: any
  ) {
    setAvailablePlanDocs(
      produce((availablePlanDocs) => {
        const document = availablePlanDocs[index];
        document.isEdited = true;
        document[changedField] = changeEvent.target.value;
      })
    );
  }

  function handleDelete(deleteIndex: number) {
    setAvailablePlanDocs(
      produce((availablePlanDocs) => {
        const document = availablePlanDocs[deleteIndex];
        if (document.toBeAdded) availablePlanDocs.splice(deleteIndex, 1);
        document.toBeDeleted = true;
      })
    );
  }

  function validateDocumentType(docuemntType: DocumentInterface["entityType"]) {
    return (
      (docuemntType !== undefined || docuemntType !== null) &&
      typeof docuemntType === "string" &&
      allowedDocumentTypes.includes(docuemntType)
    );
  }

  return (
    <div>
      <div className="justify-between px-10 text-lg font-medium border-b py-7">
        <h6 className="flex justify-between">
          Upload Documents
          <span onClick={() => onClose()} className="cursor-pointer">
            X
          </span>
        </h6>
      </div>
      <VStack aria-label="header" className="gap-y-2 px-4">
        {availablePlanDocs.length > 0 && (
          <HStack className="gap-x-8 mt-4">
            <div className="flex-1">
              <Label className="text-sm font-normal">Document Name</Label>
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Document Type</Label>
            </div>
            <div className="flex-1"></div>
          </HStack>
        )}
        {availablePlanDocs?.map((document, index: number) => (
          <HStack className="gap-x-8" key={index}>
            <div className="flex-1">
              <Input
                type="text"
                value={document.documentName}
                onChange={(e) => handleEdit(index, "documentName", e)}
              />
            </div>
            <div className="flex-1">
              <Select
                className=""
                options={["ESOP_PLAN", "ESOP_BOARD"]}
                onChange={(e) => handleEdit(index, "entityType", e)}
                value={document.entityType}
              />
            </div>
            <div className="flex items-center flex-1 gap-4">
              {!document.toBeAdded ? (
                <HStack className="gap-x-2 items-center">
                  <Icon
                    width={20}
                    className="cursor-pointer text-gray-400 hover:text-orange-501"
                    onClick={() => fetchAndViewDocument(document.documentPath)}
                    icon={"ic:baseline-remove-red-eye"}
                  />
                  <FileInput
                    file={"Upload file"}
                    id={index.toString()}
                    className="cursor-not-allowed"
                    disabled
                  />
                </HStack>
              ) : (
                <HStack className="gap-x-2 items-center">
                  <Icon
                    width={20}
                    className="cursor-not-allowed text-gray-400"
                    icon={"ic:baseline-remove-red-eye"}
                  />
                  <FileInput
                    file={"Upload file"}
                    id={index.toString()}
                    className="cursor-pointer"
                    disabled={!baseDocument}
                    onChange={(e) => handleFileInput(e, index)}
                  />
                </HStack>
              )}
              {document.toBeDeleted ? (
                <div className="text-red-600 text-xs"> Will be deleted</div>
              ) : (
                <Icon
                  width={20}
                  className="cursor-pointer text-gray-400 hover:text-orange-501"
                  onClick={() => handleDelete(index)}
                  icon={"ic:baseline-delete"}
                />
              )}
              {document.toBeAdded && document.b64File !== undefined && (
                <div className="text-green-400 text-xs">Will be uploaded</div>
              )}
            </div>
          </HStack>
        ))}
        <HStack className="mt-8 justify-center">
          <ButtonSecondary
            className="text-[11px] leading-[20px] font-medium py-1 bg-slate-201 text-slate-501 h-auto px-2 flex flex-col items-center"
            onClick={() => handleAdd()}
          >
            Add a Document
          </ButtonSecondary>
        </HStack>
      </VStack>
      <HStack className="justify-end p-8 justify">
        <div className="px-4">
          <ButtonPrimary
            className="min-w-[120px]"
            onClick={() => handleSubmit()}
          >
            Save
          </ButtonPrimary>
        </div>
        <div className="flex items-center">
          <ButtonSecondary className="min-w-[120px]" onClick={() => onClose()}>
            Close
          </ButtonSecondary>
          <div>{false && <CircularProgress size={32} />}</div>
        </div>
      </HStack>
    </div>
  );
}
