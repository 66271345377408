import { Employee } from "../types/Employee";
import { EsopPlan } from "../types/EsopPlan";

const SAR_CONTRACTOR_ALLOWED_COUNTRIES = ["SGD"];

export default function canHolderBeAssignedToThisPlan(
  plan: string,
  employeeType: string,
  userCompanyCurrency: string
) {
  if (employeeType === "EMPLOYEE") return true;
  if (
    SAR_CONTRACTOR_ALLOWED_COUNTRIES.includes(
      userCompanyCurrency?.substring(0, 3)
    ) &&
    employeeType === "CONSULTANT"
  ) {
    return true;
  }
  if (employeeType === "CONSULTANT" && plan === "SAR") {
    return true;
  }
  return false;
}
