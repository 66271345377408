import { useMutation, useQuery } from "react-query";
import { differenceInYears } from "date-fns/esm";
import * as _ from "lodash";
import { queryClient } from "./client";
import { _trimAll } from "../utils/string";
import { randomEmployee, randomEmployeeOverview } from "./placeholders";
import {
  addEmployee,
  addEmployeeExcel,
  deleteEmployee,
  employeeVestingDetails,
  enableEmployeeAccess,
  generateCredentials,
  generateSettlementLetter,
  getAllSubsidiaryCompanies,
  getEmployeePageDetails,
  getEmployeesOverview,
  getEmployeeStatusDropdown,
  getEmployeeTemplate,
  getEmployeeTransactionDetails,
  initiateSeparation,
  remindOfferedEmployee,
  resignationEmployeeDetails,
  revokeEmployeeAccess,
  settlementLetterDetails,
} from "../api/Esop";

export function useEmployees() {
  return useQuery({
    queryKey: "employeesOverview",
    queryFn: getEmployeesOverview,
    select: (data) => {
      data.employees.sort(
        (a, b) =>
          new Date(b.createdAt || 0).valueOf() -
          new Date(a.createdAt || 0).valueOf()
      );
      return data.employees.map((employee) => {
        const lastWorkDay = employee.lastDay
          ? new Date(employee.lastDay)
          : new Date();
        return {
          ...employee,
          experience: differenceInYears(new Date(), lastWorkDay),
        };
      });
    },
    placeholderData: randomEmployeeOverview(),
  });
}
export function useEmployeesDepartment() {
  return useQuery({
    queryKey: "employeesOverview",
    queryFn: getEmployeesOverview,
    select: (data) => data,
  });
}

export function useEmployeeStatus() {
  return useQuery({
    queryKey: "employeesOverview",
    queryFn: getEmployeesOverview,
    select: (data) =>
      _.compact(
        _.uniq(data.employees.map((status) => status.employmentStatus))
      ) || [],
  });
}

export function useEmployeeDepartment() {
  return useQuery({
    queryKey: "employeesOverview",
    queryFn: getEmployeesOverview,
    select: ({ employees }) => {
      employees.sort((a, b) => (a.department as any) - (b.department as any));
      const uniqEmployeesByDepartments = _.uniqBy(employees, (employee) =>
        _trimAll(employee.department.toLowerCase())
      );
      return uniqEmployeesByDepartments
        .map((employee) => _trimAll(employee.department))
        .sort();
    },
  });
}

export function useEmployeesJoiningDateRange() {
  return useQuery({
    queryKey: "employeesOverview",
    queryFn: getEmployeesOverview,
    select: ({ employees }) => {
      const joiningDates = employees?.map(
        (employee) => new Date(employee.dateOfJoin)
      );
      const dateOfJoinStart = _.min(joiningDates);
      const dateOfJoinEnd = _.max(joiningDates);
      return { dateOfJoinStart, dateOfJoinEnd };
    },
  });
}

export function useAddEmployee() {
  return useMutation({
    mutationKey: "addEmployee",
    mutationFn: addEmployee,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.invalidateQueries("employeePageDetails");
      queryClient.refetchQueries("allGrants");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("employeePageDetails");
      queryClient.refetchQueries("esopPlansSummary");
    },
    onError: () => {},
  });
}

export function useEditEmployee() {
  return useMutation({
    mutationKey: "addEmployee",
    mutationFn: addEmployee,
    onSuccess: () => {
      queryClient.invalidateQueries("employeePageDetails");
      queryClient.refetchQueries("employeePageDetails");
    },
    onError: () => {},
  });
}

export function useEmployeeTemplate() {
  return useQuery({
    queryKey: "employeeTemplate",
    queryFn: getEmployeeTemplate,
    select: (data) => data.s3link,
  });
}

export function useAddEmployeeExcel() {
  return useMutation({
    mutationKey: "addEmployeeExcel",
    mutationFn: addEmployeeExcel,
    onSuccess: () => {
      queryClient.invalidateQueries("employeesOverview");
      queryClient.refetchQueries("employeesOverview");
    },
    onError: (error: { response: { data: { reason: string } } }) => {},
  });
}

export function useEmployeeResignationDetails(id: number) {
  return useQuery({
    queryKey: ["employeeResignationDetails", id],
    queryFn: () => resignationEmployeeDetails(id),
  });
}

export function useEmployeeVestingDetails(id: number) {
  return useQuery({
    queryKey: ["employeeVestingDetails", id],
    queryFn: () => employeeVestingDetails(id),
  });
}

export function useSettlementDetails(id: number) {
  return useQuery({
    queryKey: ["settlementLetterDetails", id],
    queryFn: () => settlementLetterDetails(id),
    select: (data) => data.data,
  });
}

export function useInitiateSeparation() {
  return useMutation({
    mutationKey: "initiateSeparation",
    mutationFn: initiateSeparation,
    onSuccess: () => {
      queryClient.invalidateQueries("employeesOverview");
      queryClient.invalidateQueries("allGrants");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("allGrants");
      queryClient.invalidateQueries("employeePageDetails");
      queryClient.refetchQueries("employeePageDetails");
    },
    onError: () => {},
  });
}

export function useGenerateSettlementLetter() {
  return useMutation({
    mutationKey: "generateSettlementLetter",
    mutationFn: generateSettlementLetter,
    onSuccess: () => {
      queryClient.invalidateQueries("settlementLetterDetails");
      queryClient.refetchQueries("settlementLetterDetails");
    },
  });
}

export function useGenerateCredentials() {
  return useMutation({
    mutationKey: "generateCredential",
    mutationFn: generateCredentials,
    onSuccess: () => {
      queryClient.invalidateQueries("employeePageDetails");
      queryClient.refetchQueries("employeePageDetails");
    },
    onError: () => {},
  });
}
export function useEmployeementStatusDropdown() {
  return useQuery({
    queryKey: "employementStatusDropdown",
    queryFn: getEmployeeStatusDropdown,
    select: (data) =>
      data.employmentStatus.map((status) => status.employmentStatus),
  });
}

export function useDeleteEmployee() {
  return useMutation({
    mutationKey: "deleteEmployee",
    mutationFn: deleteEmployee,
    onSuccess: () => {
      queryClient.invalidateQueries("employeesOverview");
      queryClient.refetchQueries("employeesOverview");
    },
    onError: () => {},
  });
}

export function useRevokeAccess() {
  return useMutation({
    mutationKey: "revokeEmployeeAccess",
    mutationFn: revokeEmployeeAccess,
    onSuccess: () => {
      queryClient.invalidateQueries("employeesOverview");
      queryClient.invalidateQueries("employeePageDetails");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("employeePageDetails");
    },
  });
}
export function useEnableAccess() {
  return useMutation({
    mutationKey: "enableEmployeeAccess",
    mutationFn: enableEmployeeAccess,
    onSuccess: () => {
      queryClient.invalidateQueries("employeesOverview");
      queryClient.invalidateQueries("employeePageDetails");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("employeePageDetails");
    },
  });
}

export function useEmployeeAcceptReminder() {
  return useMutation({
    mutationKey: "remindOfferedEmployee",
    mutationFn: remindOfferedEmployee,
  });
}

export function useEmployeePageDetails(empId: number) {
  return useQuery({
    queryKey: ["employeePageDetails", empId],
    queryFn: getEmployeePageDetails,
    select: (data) => data,
  });
}

export function useEmployeeTransactionDetails() {
  return useQuery({
    queryKey: ["employeeTransactionDetails"],
    queryFn: getEmployeeTransactionDetails,
    select: (data) => data,
  });
}

export function useSubsidiaryCompanyNames() {
  return useQuery({
    queryKey: "getAllSubsidiaryCompanyNames",
    queryFn: getAllSubsidiaryCompanies,
  });
}
