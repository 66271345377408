/* eslint-disable no-useless-computed-key */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as _ from "lodash";
import { Icon } from "@iconify/react";
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  HStack,
  VStack,
} from "../../components/utils";

import { VestingEventReq } from "../../types/VestingTemplate";
import {
  useDoApproveEvent,
  useDoUpdateEventDetails,
  useEventDetails,
  useEventDetailsForApproval,
} from "../../queries/vest";
import {
  formatDate,
  formatDisplayDate,
  formatWithTimeZone,
} from "../../utils/date";
import { Input, Label } from "../../components/shared/InputField";
import { useEditEventDetailsDialog } from "../../store/useDialogStore";
import { useError } from "../../store/errorStore";
import { useGrants } from "../../queries";

function EditEventDetails({
  event,
  onClose = () => {},
}: {
  event: VestingEventReq;
  onClose: () => void;
}) {
  const { state: editEventDetailsDialog, setState: setEditEventDetailsDialog } =
    useEditEventDetailsDialog();
  const { data: _grants } = useGrants();
  const eventPlanDetails: {
    eventName: string;
    vestingTemplateName: string;
    planName: string;
  }[] = [];
  _grants?.forEach((grant) => {
    if (grant.vestingTemplateName === event.vestingTemplateName) {
      eventPlanDetails.push({
        ["eventName"]: event.eventName,
        ["vestingTemplateName"]: event.vestingTemplateName,
        ["planName"]: grant.planName,
      });
    }
  });
  const { data: eventDetails } = useEventDetails(event.eventName || "");
  const { data: eventApproveDetails } = useEventDetailsForApproval(
    event.eventName || ""
  );
  const errorMessage = useError();
  const eventdata: VestingEventReq =
    editEventDetailsDialog.variant === "edit"
      ? (eventDetails as VestingEventReq)
      : (eventApproveDetails as VestingEventReq);
  const { mutate: approveEvent, status: approvedstatus } = useDoApproveEvent();
  const { mutate: eventApproval, status: forApprovalstatus } =
    useDoUpdateEventDetails();
  const status =
    editEventDetailsDialog.variant === "edit"
      ? approvedstatus
      : forApprovalstatus;
  const [targetDate, setTargetDate] = useState(
    formatDate(eventdata?.eventTargetDate || new Date().toDateString())
  );
  useEffect(() => {
    if (!eventdata) return;
    eventdata.eventTargetDate = formatWithTimeZone(targetDate);
  }, [targetDate, eventdata]);

  function handleApprove() {
    if (status === "success") {
      onClose();
    } else if (editEventDetailsDialog.variant === "edit") {
      eventApproval(eventdata as VestingEventReq, {
        onSuccess: () => {
          toast("Event Details updated successfully!", { type: "success" });
          onClose();
        },
        onError: (err: any) => {
          errorMessage.setMessage(err.response.data.reason);
          toast(err.response.data.reason, { type: "error", autoClose: 2000 });
        },
      });
    } else {
      approveEvent(eventdata as VestingEventReq, {
        onSuccess: () => {
          toast("Event Details updated successfully!", { type: "success" });
          onClose();
        },
        onError: (err: any) => {
          errorMessage.setMessage(err.response.data.reason);
          toast(err.response.data.reason, { type: "error", autoClose: 2000 });
        },
      });
    }
  }
  return (
    <>
      <div className="p-4 text-lg font-medium border-b ">
        <h6 className="justify-between flex">
          {editEventDetailsDialog.variant === "edit"
            ? "Edit Event Details"
            : "Approve Event Details"}
          <span onClick={() => onClose()} className="cursor-pointer">
            X
          </span>
        </h6>
      </div>
      <VStack className="justify-between gap-4 p-4 mx-auto min-w-min min-h-min">
        <HStack className="items-baseline justify-start gap-8">
          <Box className="items-center gap-4">
            <Label>Event Name</Label> - {eventdata?.eventName}
          </Box>
          <Box className="flex items-center gap-4 flex-nowrap">
            <Label className="flex-nowrap">Event date completion - </Label>
            <Input
              disabled={editEventDetailsDialog.variant !== "edit"}
              className="w-48"
              type="date"
              value={targetDate}
              onChange={(e) => setTargetDate(e.target.value)}
            />
          </Box>
        </HStack>
        <VStack className="gap-4">
          <Box>
            <table className="w-full table-space">
              <thead className="text-xs font-medium text-gray-light">
                <tr className="flex justify-between border-b border-gray-200 border-dashed">
                  <td className="py-2 w-60">Event Name</td>
                  <td className="py-2 w-60">Vesting Template Name</td>
                  <td className="py-2 w-60">Plan Name</td>
                </tr>
              </thead>
              <tbody>
                {_.uniqBy(eventPlanDetails, "planName").map((event, index) => (
                  <tr
                    key={index}
                    className="flex justify-between border-b border-gray-200 border-dashed"
                  >
                    <td className="py-2 w-60">{event.eventName}</td>
                    <td className="py-2 w-60">{event.vestingTemplateName}</td>
                    <td className="py-2 w-60">{event.planName}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
          <Box>
            <table className="w-full table-space">
              <thead className="text-xs font-medium text-gray-light">
                <tr className="flex justify-between border-b border-gray-200 border-dashed">
                  <td className="py-2 w-60">Event name</td>
                  <td className="py-2 w-60">Employees Impacted</td>
                  <td className="py-2 w-60">Event Target Date</td>
                </tr>
              </thead>
              <tbody>
                {event.employeeNames.map((item, index) => (
                  <tr
                    key={index}
                    className="flex justify-between border-b border-gray-200 border-dashed"
                  >
                    <td className="py-2 w-60">{event.eventName}</td>
                    <td className="py-2 w-60">{item}</td>
                    <td className="py-2 w-60">
                      {formatDisplayDate(event.eventTargetDate)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        </VStack>

        <HStack className="justify-between ">
          <ButtonSecondary onClick={() => onClose()}>Cancel</ButtonSecondary>
          <ButtonPrimary
            className={`flex items-center self-end justify-center max-w-xs ${
              status === "success" ? "bg-green-500" : ""
            }`}
            onClick={() => handleApprove()}
          >
            {(status === "idle" || status === "error") && "Update"}
            {status === "loading" && (
              <Icon
                className=" animate-spin"
                icon="lucide:loader-2"
                width={36}
              />
            )}
            {status === "success" && (
              <Icon icon="clarity:success-standard-line" width={36} />
            )}
          </ButtonPrimary>
        </HStack>
      </VStack>
    </>
  );
}
export default EditEventDetails;
