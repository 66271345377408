import React, { useRef } from "react";
import { toast } from "react-toastify";
import { ButtonPrimary, HStack, VStack } from "../../components/utils";
import { useUploadImportedTransaction } from "../../queries/onBoarding";
import PrimaryHistoricTransactionTable from "./PrimaryHistoricTransactionTable";
import SecondaryHistoricTransactionTable from "./SecondaryHistoricTransactionTable";

export default function AllHistoricTransactions() {
  const { mutate: importCaptable } = useUploadImportedTransaction();
  const fileRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    const allowedFileExtensions = ["xlsx", "xls", "ods"];
    if (
      allowedFileExtensions.some((extension) => file.name.endsWith(extension))
    ) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("isHistoricalScreen", `true`);
      importCaptable(formData, {
        onSuccess: () => {
          toast("Excel Sheet Uploaded Successfully!", {
            type: "success",
            autoClose: 2000,
          });
        },
        onError: (error: { response: { data: { reason: string } } }) => {
          toast(`${error.response.data.reason}`, {
            type: "error",
            autoClose: 2000,
          });
        },
      });
    } else {
      toast(
        ` Invalid file type, allowed file types are ${allowedFileExtensions.join(
          ", "
        )}`,
        { type: "error" }
      );
    }
  }

  return (
    <div className="w-full pt-5 bg-white rounded-md mt-10  px-4">
      <HStack aria-label="toolbar" className="justify-between mb-8">
        <VStack className="min-w-max">
          <p className="text-lg font-medium text-gray-dark">Historical Round</p>
        </VStack>
        <HStack className="justify-end">
          <ButtonPrimary
            className="min-w-max"
            onClick={() => {
              fileRef.current.click();
            }}
          >
            <input
              ref={fileRef}
              onChange={handleChange}
              multiple={false}
              type="file"
              accept=".xlsx, .xls, .ods"
              hidden
            />
            <div className="text-dark hover:text-red-500">Import</div>
          </ButtonPrimary>
        </HStack>
      </HStack>
      <PrimaryHistoricTransactionTable />
      <SecondaryHistoricTransactionTable />
    </div>
  );
}
