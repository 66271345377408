import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Dialog, Switch } from "@mui/material";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
  Error,
} from "../components/utils";
import { Input, Label } from "../components/shared/InputField";
import { SettlementDto } from "../types/GenerateSettlement";
import {
  useEmployeeResignationDetails,
  useEmployeeVestingDetails,
  useGenerateSettlementLetter,
} from "../queries/employees";
import { formatWithTimeZone, modifyDate } from "../utils/date";
import { useError } from "../store/errorStore";
import { useGrants } from "../queries/esopPlan";
import { Select } from "../components/shared/Select";
import { getCurrencySymbol, getCurrencyType } from "../utils/currencyFormatter";
import { ProjectedVesting } from "../types/Vest";

type InitiateSepartionType = {
  onClose: () => void;
};
export function SettlementLetter(props: InitiateSepartionType) {
  const { id } = useParams();
  const _id = id || "";
  const { onClose } = props;
  const currencySymbol = getCurrencySymbol();
  const {
    mutate: generateSettlementLetter,
    isLoading: isSettlementLetterLoading,
  } = useGenerateSettlementLetter();
  const errorMessage = useError();
  const { data: resignationDetails } = useEmployeeResignationDetails(
    parseInt(_id, 10)
  );
  const intialValues: SettlementDto = {
    employeeId: parseInt(_id, 10),
    employeeName: resignationDetails?.employeeName || "",
    dateOfExercise: format(new Date(), "yyyy-MM-dd"),
    fmv: 0,
    includePauseVesting: false,
    isExercise: false,
    isCash: false,
    optionsToExercise: 0,
    lastDay: format(new Date(), "yyyy-MM-dd"),
  };
  const { data: _grants, isLoading: isGrantLoading } = useGrants();
  const grants = _grants ?? [];
  const employeeGrants = grants.filter(
    (grant) =>
      grant.employeeId.toString() === _id &&
      (grant.optionHolderState === "GRANTED" ||
        grant.optionHolderState === "RESIGNED")
  );
  const totalEligibleOptions = _.sumBy(
    employeeGrants,
    (grant) =>
      (grant.optionsVested - grant.optionsExercised - grant.optionsForfeited) *
      grant.allowedPercentageForResigned
  );
  const validationSchema = Yup.object().shape({
    employeeId: Yup.number(),
    isCash: Yup.boolean(),
    optionsToExercise: Yup.number().required().max(totalEligibleOptions),
    lastDay: Yup.date()
      .required("Last day is required")
      .min(
        modifyDate(resignationDetails?.lastVestDate || "1900-01-01", -1),
        `Last Day cannot be before 
    ${format(
      new Date(resignationDetails?.lastVestDate || "1900-01-01"),
      "dd-MM-yyyy"
    )}`
      ),
    exerciseDate: Yup.date()
      .required("Exercise date is required")
      .min(
        modifyDate(resignationDetails?.lastVestDate || "1900-01-01", -1),
        `Last Day cannot be before 
    ${format(
      new Date(resignationDetails?.lastVestDate || "1900-01-01"),
      "dd-MM-yyyy"
    )}`
      ),
  });
  const { data: _employeeVestings, isLoading } = useEmployeeVestingDetails(
    parseInt(_id, 10)
  );
  const employeeVestings = _employeeVestings?.data ?? [];
  const [pausedOptions, setPausedOptions] = useState(0);
  function handleSubmit(values: SettlementDto) {
    const settlementDto = { ...values };
    settlementDto.lastDay = formatWithTimeZone(settlementDto.lastDay);
    settlementDto.dateOfExercise = formatWithTimeZone(
      settlementDto.dateOfExercise
    );
    generateSettlementLetter(settlementDto, {
      onSuccess: () => {
        toast("Letter Generated Successfully", {
          type: "success",
          autoClose: 2000,
        });
        onClose();
      },
      onError: (err: any) => {
        errorMessage.setMessage(err.response.data.reason);
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
      },
    });
  }
  const formik = useFormik({
    initialValues: intialValues,
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  function updateGrantsBasedOnDate(lastDay: string) {
    let pausedVesting = 0;
    const vestingsToBeAdded: ProjectedVesting[] = [];
    employeeGrants.forEach((grant) => {
      const grantVestings = employeeVestings.filter(
        (vesting) =>
          grant.optionHolderId === parseInt(vesting.optionHolderId, 10)
      );
      grantVestings.forEach((vesting) => {
        if (
          new Date(vesting.projectedVestingDate) <= new Date() &&
          vesting.triggerType === "Time" &&
          vesting.vestState === "Active"
        ) {
          pausedVesting += vesting.projectedVestedOptions;
          if (formik.values.includePauseVesting) {
            vestingsToBeAdded.push(vesting);
          }
        } else if (
          new Date(vesting.projectedVestingDate) <= new Date(lastDay)
        ) {
          vesting.actualVestedOptions = vesting.projectedVestedOptions;
        }
      });
      grant.optionsVested = _.sumBy(
        grantVestings,
        (vesting) => vesting.actualVestedOptions
      );
    });
    setPausedOptions(pausedVesting);
  }
  useEffect(() => {
    updateGrantsBasedOnDate(formik.values.lastDay);
  }, [
    formik.values.lastDay,
    formik.values.includePauseVesting,
    isLoading,
    isGrantLoading,
  ]);
  const currency = getCurrencyType();
  return (
    <>
      <div className="py-7 px-10 text-lg font-medium border-b">
        <h6 className="flex justify-between">
          Generate Settlement Letter
          <span onClick={() => onClose()} className="cursor-pointer">
            X
          </span>
        </h6>
      </div>
      <VStack className="gap-9 py-14 w-full px-10">
        <HStack className="justify-between gap-8">
          <div className="w-1/2">
            <Label className="text-sm font-normal">Employee Name</Label>
            <Input
              type="text"
              disabled
              {...formik.getFieldProps("employeeName")}
            />
            {formik.touched.employeeName && formik.errors.employeeName && (
              <Error text={formik.errors.employeeName} />
            )}
          </div>
          <div className="w-1/2">
            <Label className="text-sm font-normal">Last day of Working</Label>
            <Input type="date" {...formik.getFieldProps("lastDay")} />
            {formik.touched.lastDay && formik.errors.lastDay && (
              <Error text={formik.errors.lastDay} />
            )}
          </div>
        </HStack>
        <HStack className="py-4">
          <table className="table-space w-full">
            <thead className="text-gray-light text-xs font-medium">
              <tr className=" border-b border-dashed">
                <td className="px-2">Grant ID</td>
                <td className="px-2">GRANTED</td>
                <td className="px-2">VESTED</td>
                <td className="px-2">LAPSED</td>
                <td className="px-2">EXERCISED</td>
                <td className="px-2">ELIGIBLE TO EXERCISE</td>
                <td className="px-2">STATE</td>
              </tr>
            </thead>
            <tbody>
              {employeeGrants.length > 0 ? (
                employeeGrants.map((grant, index) => {
                  const eligibleOptions =
                    (grant.optionsVested -
                      grant.optionsExercised -
                      grant.optionsForfeited) *
                    grant.allowedPercentageForResigned;
                  return (
                    <tr
                      className=" border-b border-dashed"
                      key={grant.optionHolderId}
                    >
                      <td className="px-2 py-2 align-top">
                        {grant.grantIdentifier}
                      </td>
                      <td className="px-2 py-2 align-top">
                        {grant.optionsGranted}
                      </td>
                      <td className="px-2 py-2 align-top">
                        {grant.optionsVested}
                      </td>
                      <td className="px-2 py-2 align-top">
                        {grant.optionsLapsed}
                      </td>
                      <td className="px-2 py-2 align-top">
                        {grant.optionsExercised}
                      </td>
                      <td className="px-2 py-2 align-top">
                        {formik.errors.lastDay ? 0 : eligibleOptions}
                      </td>
                      <td className="px-2 py-2 align-top">
                        {grant.optionHolderState}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <Box className="flex flex-row items-center justify-center text-green-400">
                  <p>No grants available</p>
                </Box>
              )}
            </tbody>
          </table>
        </HStack>
        {pausedOptions !== 0 && (
          <>
            <HStack className="items-center gap-8 ml-3">
              <p className=" text-xs font-normal">
                {pausedOptions} number of options are not vested because of
                paused vesting
              </p>
            </HStack>
            <HStack className="items-center w-1/2 gap-8 ml-3">
              <Label className=" text-sm font-normal">
                Include paused vesting?
              </Label>
              <Switch
                checked={formik.values.includePauseVesting}
                onChange={() =>
                  formik.setFieldValue(
                    "includePauseVesting",
                    !formik.values.includePauseVesting
                  )
                }
                value={formik.values.includePauseVesting}
              />
            </HStack>
          </>
        )}
        <HStack
          className={`items-center w-1/2 gap-8 ml-3 ${
            totalEligibleOptions === 0 ? "hidden" : ""
          }`}
        >
          <Label className=" text-sm font-normal">
            Do you want to exercise?
          </Label>
          <Switch
            checked={formik.values.isExercise}
            onChange={() =>
              formik.setFieldValue("isExercise", !formik.values.isExercise)
            }
            value={formik.values.isExercise}
          />
        </HStack>
        <HStack
          className={`gap-8  justify-between ${
            !formik.values.isExercise ? "hidden" : ""
          }`}
        >
          <div className="w-1/2">
            <Label className="text-sm font-normal">Type of Exercise</Label>
            <Select
              options={["Cash", "Stock"]}
              onChange={(e) => {
                if (e.target.value === "Cash") {
                  formik.setFieldValue("isCash", true);
                } else {
                  formik.setFieldValue("isCash", false);
                }
              }}
            />
            {formik.touched.isCash && formik.errors.isCash && (
              <Error text={formik.errors.isCash} />
            )}
          </div>
          <div className="w-1/2">
            <div className="whitespace-nowrap flex items-center justify-between">
              <Label className={`text-sm font-normal`}>No of Options</Label>
              {
                <Label className=" text-xs font-normal text-green-400">
                  {totalEligibleOptions.toLocaleString(currency)} options
                  available
                </Label>
              }
            </div>
            <Input
              type="number"
              min={1}
              max={totalEligibleOptions}
              {...formik.getFieldProps("optionsToExercise")}
            />
            {formik.touched.optionsToExercise &&
              formik.errors.optionsToExercise && (
                <Error text={formik.errors.optionsToExercise} />
              )}
          </div>
        </HStack>
        <HStack
          className={`gap-8 justify-between ${
            !formik.values.isExercise ? "hidden" : ""
          }`}
        >
          <div className=" w-1/2">
            <Label className="text-sm font-normal">Date Of Exercise</Label>
            <Input type="date" {...formik.getFieldProps("dateOfExercise")} />
            {formik.touched.dateOfExercise && formik.errors.dateOfExercise && (
              <Error text={formik.errors.dateOfExercise} />
            )}
          </div>
          <div className="w-1/2">
            <Label>Fair Market Value(FMV) Price ({currencySymbol})</Label>
            <Input
              type="number"
              placeholder="Enter selling price"
              {...formik.getFieldProps("fmv")}
            />
            {formik.touched.fmv && formik.errors.fmv && (
              <Error text={formik.errors.fmv} />
            )}
          </div>
        </HStack>
        <HStack className="justify-between mt-4">
          <ButtonPrimary1 onClick={onClose}>Back</ButtonPrimary1>
          <ButtonPrimary
            type="button"
            onClick={() => {
              handleSubmit(formik.values);
            }}
          >
            {!isSettlementLetterLoading ? (
              `Generate`
            ) : (
              <Icon
                className="animate-spin"
                icon="lucide:loader-2"
                width={36}
              />
            )}
          </ButtonPrimary>
        </HStack>
      </VStack>
    </>
  );
}
