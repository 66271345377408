import React from "react";

export type SelectInputProps<T> = {
  options: T[];
  placeholder?: string;
  valueGetter?: (option: T) => any;
  textGetter?: (option: T) => string;
} & React.SelectHTMLAttributes<HTMLSelectElement>;

export function Select<T>(props: SelectInputProps<T>) {
  const {
    options,
    value,
    placeholder,
    valueGetter: _valueGetter,
    textGetter: _textGetter,
    className: _className,
    ...rest
  } = props;
  const defaultGetter = (option: any) => option?.toString();
  const valueGetter = _valueGetter || defaultGetter;
  const textGetter = _textGetter || defaultGetter;
  return (
    <select
      className={` form-select ${_className} ${
        props.disabled ? "cursor-not-allowed" : ""
      }`}
      value={value}
      {...rest}
    >
      <option value={""}>{!placeholder ? "--Select--" : placeholder}</option>
      {options.map((option, i) => (
        <option key={i} value={valueGetter(option)}>
          {textGetter(option)}
        </option>
      ))}
    </select>
  );
}
