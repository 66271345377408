import TextField from "@mui/material/TextField";
import React from "react";
import { FormikProps } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import { RegisteredCompany } from "../types/OnBoardDataModel";

export type ElasticSearchInputValue = {
  options?: string[];
  formik: FormikProps<RegisteredCompany>;
  onInput?: (enteredCompanyName: string) => void;
};

export default function ElasticSearchDropDown({
  options = [],
  onInput = () => {},
  formik,
}: ElasticSearchInputValue) {
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onInput(e.target.value);
  };

  return (
    <Autocomplete
      freeSolo
      id="free-solo-2-demo"
      disableClearable
      options={options}
      renderInput={(params) => (
        <div className="w-128 ">
          <TextField
            hiddenLabel
            type="text"
            className="w-128 border-0 form-input px-20 outline-none border-none rounded-lg form-input items-center "
            placeholder="Enter Company Name"
            variant="standard"
            {...params}
            {...formik.getFieldProps("nameOfTheCompany")}
            InputProps={{
              onSelect: onInputChange,
              disableUnderline: true,
              ...params.InputProps,
              type: "search",
            }}
          />
        </div>
      )}
    />
  );
}
