import { useMutation, useQuery } from "react-query";
import {
  changePassword,
  getUserProfile,
  saveUserProfile,
} from "../api/userProfile";
import { queryClient } from "./client";

export function useGetUserProfile() {
  return useQuery({
    queryKey: "getUserProfile",
    queryFn: getUserProfile,
  });
}

export function useSaveUserProfile() {
  return useMutation({
    mutationKey: "saveUserProfile",
    mutationFn: saveUserProfile,
    onSuccess: () => {
      queryClient.invalidateQueries("getUserProfile");
      queryClient.refetchQueries("getUserProfile");
    },
  });
}

export function useChangePassword() {
  return useMutation({
    mutationKey: "changePassword",
    mutationFn: changePassword,
  });
}
