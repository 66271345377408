export const initialFounderData = {
  fullName: "",
  emailId: "",
  designation: "",
  contactNumber: 0,
  address: "",
  dateOfBirth: "",
  nationality: "India",
  linkedInProfile: "",
  twitterHandle: "",
  isEdit: false,
  isSelected: false,
  shouldShowDialog: false,
};

export const initialDirectorData = {
  fullName: "",
  typeOfDirector: "",
  emailId: "",
  din: "",
  address: "",
  contactNumber: 0,
  nationality: "India",
  dateOfBirth: "",
  countryOfResidence: "",
  dateOfAppointement: "",
  dateOfBoardResoluitonForAppointement: "",
  isEdit: false,
  isSelected: false,
  shouldShowDialog: false,
};

export const initialCompanyDetail = {
  nameOfTheCompany: "",
  dippCertificateNumber: "",
  cinNumber: "",
  typeOfTheCompany: "",
  companyCurrency: "INR - ₹",
  dateOfIncorporation: "",
  corporateOfficeAddress: "",
  companyEmailId: "",
  companyWebsite: "",
  companyLogoPath: "",
  doingBusinessAs: "",
  business: "",
  industry: [],
  category: [],
  nic: "",
  founders: [],
  directors: [],
};

export const initialHistoricRoundDetail = {
  investors: [],
  splitDetails: [],
  secondaries: [],
  esopPool: [
    {
      poolSize: undefined,
      date: undefined,
    },
  ],
  companyCapitalDetails: {
    equityAuthorizedShareCapital: undefined,
    preferenceAuthorizedShareCapital: undefined,
    equityPaidUpShareCapital: undefined,
    preferencePaidUpShareCapital: undefined,
  },
  conversionDetails: {
    conversionValue: undefined,
    conversionDate: undefined,
    numberOfSharesToConvert: undefined,
    conversionSecurity: undefined,
    numberofSharesPostConversion: undefined,
    notes: "",
  },
  securitiesDropdown: [],
  investorsDropdown: [],
  historicalRoundPopupDetails: [],
  notes: "",
};

export const initialRoundDetail = {
  roundName: "",
  roundIdentifier: "",
  isPricedRound: true,
  roundConversionRatio: undefined,
  roundVotingRightsRatio: undefined,
  updateConversionRatios: [],
};
export const initialAllotmentName = {
  name: "",
  identifier: "",
};

export const initialTransactionName = {
  name: "",
  identifier: "",
};

export const initialPrimaryTransaction = {
  investorName: "",
  amount: undefined,
  par: undefined,
  premium: undefined,
  numberOfShares: undefined,
  date: "",
  securityType: "",
  roundName: "",
};

export const initialPrimaryTransaction2 = {
  investorName: "",
  amount: 0,
  par: 0,
  premium: 0,
  numberOfShares: 0,
  date: "",
  securityType: "",
  roundName: "",
};

export const initialSecondaryTransaction = {
  buyer: "",
  seller: "",
  amount: undefined,
  numberOfShares: undefined,
  pricePerShare: undefined,
  date: "",
  securityType: "",
  roundName: "",
};

export const initialGroupName = {
  groupName: "",
  description: "",
};
