/* eslint-disable no-undef */
import { Icon } from "@iconify/react";
import React, { useEffect, useRef } from "react";
import * as _ from "lodash";
import Slider from "@mui/material/Slider";
import { useGrantFilterStore } from "../../store/grantsTableFilter";
import { Grant } from "../../types/Grant";
import { ButtonPrimary, ButtonSecondary, HStack } from "../utils";
import { Input, Label } from "./InputField";
import { Select } from "./Select";
import { capitalizeFirstLetter, stringFormat } from "../../utils/string";

export function ContextModal({
  className = "items-end",
  primaryAction,
  secondaryAction,
  data,
  render = () => (
    <Icon icon="el:filter" className={`text-white rounded`} height={20} />
  ),
}: {
  primaryAction: () => void;
  secondaryAction: (
    minOptionsVested: number,
    maxOptionsVested: number,
    minOptionsGranted: number,
    maxOptionsGranted: number
  ) => void;
  className?: string;
  data: Grant[];
  render?: () => JSX.Element;
}) {
  const RenderElement = render;
  const [showModal, setShowModal] = React.useState(false);
  const filter = useGrantFilterStore();

  const contextRef = useRef<HTMLDivElement>(null);
  const minOptionsVested = _.minBy(data, "optionsVested")?.optionsVested || 0;
  const maxOptionsVested = _.maxBy(data, "optionsVested")?.optionsVested || 0;
  const minOptionsGranted =
    _.minBy(data, "optionsGranted")?.optionsGranted || 0;
  const maxOptionsGranted =
    _.maxBy(data, "optionsGranted")?.optionsGranted || 0;

  const handleChange = (event: Event, newValue: any) => {
    filter.setMinOptionsVested(newValue[0]);
    filter.setMaxOptionsVested(newValue[1]);
  };
  const handleChangeGranted = (event: Event, newValue: any) => {
    filter.setMinOptionsGranted(newValue[0]);
    filter.setMaxOptionsGranted(newValue[1]);
  };

  const changeMinOptionsVested = (minOptionsVested: number) => {
    filter.setMinOptionsVested(minOptionsVested);
  };

  const changeMaxOptionsVested = (maxOptionsVested: number) => {
    filter.setMaxOptionsVested(maxOptionsVested);
  };

  const changeMinOptionsGranted = (minOptionsGranted: number) => {
    filter.setMinOptionsGranted(minOptionsGranted);
  };

  const changeMaxOptionsGranted = (maxOptionsGranted: number) => {
    filter.setMaxOptionsGranted(maxOptionsGranted);
  };

  function handleInitialState() {
    filter.setMinOptionsVested(minOptionsVested);
    filter.setMaxOptionsVested(maxOptionsVested);
    filter.setMinOptionsGranted(minOptionsGranted);
    filter.setMaxOptionsGranted(maxOptionsGranted);
  }

  useEffect(() => {
    const checkIfClickOutside = (e: any) => {
      if (!(showModal && contextRef.current?.contains(e.target))) {
        setShowModal(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickOutside);
    };
  }, [showModal]);
  return (
    <div className={`flex flex-col ${className} `} ref={contextRef}>
      <button
        className="inline-flex text-xs text-center rounded-lg peer"
        type="button"
        onClick={() => {
          handleInitialState();
          setShowModal((state) => !state);
        }}
      >
        <RenderElement />
      </button>
      {showModal && (
        <div
          id="dropdown"
          className="absolute z-10 mt-8 bg-white border rounded shadow peer-focus:block 2xl:right-60 xl:right-10 lg:right-8 md:right-2"
        >
          <ul
            className="py-1 text-xs font-normal text-dark m-2 filter-container"
            aria-labelledby="dropdownDefault"
          >
            {filter.grantFilter.map((item, index) => (
              <li className="m-4" key={index}>
                <Label>{item.name}</Label>
                {item.name === "To Date" || item.name === "From Date" ? (
                  <Input
                    type={
                      item.name === "To Date" || item.name === "From Date"
                        ? "date"
                        : "text"
                    }
                    value={item.value}
                    onChange={(e) => {
                      filter.advanceGrantFilter(e, item);
                    }}
                  />
                ) : (
                  <Select
                    options={
                      item.name === "Plan Name"
                        ? _.uniqBy(data, "planName").map(
                            (grant) => grant.planName
                          )
                        : item.name === "Vesting Schedule"
                        ? _.uniqBy(data, "vestingTemplateName").map(
                            (grant) => grant.vestingTemplateName
                          )
                        : item.name === "Grant Status"
                        ? _.uniqBy(data, "optionHolderState").map((grant) =>
                            capitalizeFirstLetter(
                              stringFormat(
                                grant.optionHolderState.toLowerCase()
                              )
                            )
                          )
                        : item.name === "Recent Activity"
                        ? ["Recently Drafted"]
                        : []
                    }
                    placeholder={
                      item.name === "Plan Name"
                        ? "All Plans"
                        : item.name === "Vesting Schedule"
                        ? "All Schedules"
                        : ""
                    }
                    value={filter.grantFilter[index].value}
                    onChange={(e) => {
                      filter.advanceGrantFilter(e, item);
                    }}
                  />
                )}
              </li>
            ))}
            <li className="m-4">
              <Label>Options Vested</Label>
              <Slider
                getAriaLabel={() => "Number range"}
                value={[filter.minOptionsVested, filter.maxOptionsVested]}
                onChange={handleChange}
                valueLabelDisplay="auto"
                min={minOptionsVested}
                max={maxOptionsVested}
              />
              <HStack className="justify-between">
                <div className="flex-1">
                  <Label className="text-sm font-normal">Min Value</Label>
                  <Input
                    type="number"
                    placeholder="Min"
                    className="w-28"
                    value={filter.minOptionsVested}
                    onChange={(e) =>
                      changeMinOptionsVested(e.target.valueAsNumber)
                    }
                  />
                </div>
                <div className="flex-1">
                  <Label className="text-sm font-normal">Max Value</Label>
                  <Input
                    type="number"
                    placeholder="Max"
                    className="w-28"
                    value={filter.maxOptionsVested}
                    onChange={(e) =>
                      changeMaxOptionsVested(e.target.valueAsNumber)
                    }
                  />
                </div>
              </HStack>
            </li>
            <li className="m-4">
              <Label>Options Granted</Label>
              <Slider
                getAriaLabel={() => "Number range"}
                value={[filter.minOptionsGranted, filter.maxOptionsGranted]}
                onChange={handleChangeGranted}
                valueLabelDisplay="auto"
                min={minOptionsGranted}
                max={maxOptionsGranted}
                className="p-4"
              />
              <HStack className="justify-between">
                <div className="flex-1">
                  <Label className="text-sm font-normal">Min Value</Label>
                  <Input
                    type="number"
                    placeholder="Min"
                    value={filter.minOptionsGranted}
                    className="w-28"
                    onChange={(e) =>
                      changeMinOptionsGranted(e.target.valueAsNumber)
                    }
                  />
                </div>
                <div className="flex-1">
                  <Label className="text-sm font-normal">Max Value</Label>
                  <Input
                    type="number"
                    placeholder="Max"
                    className="w-28"
                    value={filter.maxOptionsGranted}
                    onChange={(e) =>
                      changeMaxOptionsGranted(e.target.valueAsNumber)
                    }
                  />
                </div>
              </HStack>
            </li>
          </ul>
          <HStack className="justify-end gap-4 p-4">
            <ButtonSecondary
              className=""
              onClick={() => {
                setShowModal(false);
                secondaryAction(
                  minOptionsVested,
                  maxOptionsVested,
                  minOptionsGranted,
                  maxOptionsGranted
                );
              }}
            >
              Reset
            </ButtonSecondary>
            <ButtonPrimary
              className=""
              onClick={() => {
                setShowModal(false);
                filter.setFilterButtonFocused(true);
                primaryAction();
              }}
            >
              Apply
            </ButtonPrimary>
          </HStack>
        </div>
      )}
    </div>
  );
}
