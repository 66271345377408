import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { Input } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
} from "../../components/utils";
import { useEsopModellingStore } from "../../store/esopModellingStore";
import EditSharePriceMultiplier from "./EditSharePriceMultiplier";

type StepProps = {
  onBack: () => void;
  onNext: () => void;
};

function Valuation(props: StepProps) {
  const { onBack, onNext } = props;
  const data = useEsopModellingStore();
  const [currentValuation, setCurrentValuation] = useState(data.valuation || 0);
  const [currentSharePrice, setCurrentSharePrice] = useState(
    data.currentSharePrice || 0
  );
  const [dialog, setDialog] = useState<{
    open: boolean;
    index?: number;
    mode?: "Edit";
  }>({
    open: false,
    mode: "Edit",
  });
  const planningPeriod = data.selectedCompensationTemplate.planningPeriod;
  const planningPeriodArr = Array(planningPeriod).fill(0);

  function handleAction(action: Action) {
    if (action.name === "Edit") {
      setDialog({ ...dialog, open: true, mode: "Edit" });
    }
  }

  function setSharePricePerYear(currentSharePrice: number) {
    const sharePricePerYear: number[] = [];
    planningPeriodArr.forEach((_, index) => {
      if (index === 0) {
        sharePricePerYear[index] =
          currentSharePrice * data.sharePriceMultipliers[index];
      } else {
        sharePricePerYear[index] =
          sharePricePerYear[index - 1] * data.sharePriceMultipliers[index];
      }
    });

    data.setSharePricePerYear(sharePricePerYear);
  }

  function setValuationPerYear(
    valuation: number,
    sharePriceMultipliers: number[]
  ) {
    const valuationPerYear: number[] = [];
    planningPeriodArr.forEach((_, index) => {
      if (index === 0) {
        valuationPerYear[index] = valuation * sharePriceMultipliers[index];
      } else {
        valuationPerYear[index] =
          valuationPerYear[index - 1] * sharePriceMultipliers[index];
      }
    });

    data.setValuationPerYear(valuationPerYear);
  }

  function onChangeCurrentValuation(e: React.ChangeEvent<HTMLInputElement>) {
    data.setValuation(e.target.valueAsNumber);
    setCurrentValuation(e.target.valueAsNumber);
    setValuationPerYear(e.target.valueAsNumber, data.sharePriceMultipliers);
  }

  function onChangeCurrentSharePrice(e: React.ChangeEvent<HTMLInputElement>) {
    data.setCurrentSharePrice(e.target.valueAsNumber);
    setCurrentSharePrice(e.target.valueAsNumber);
    setSharePricePerYear(e.target.valueAsNumber);
  }

  function editSharePriceMultiplier(
    sharePriceMultipliers: number[],
    sharePricePerYear: number[]
  ) {
    data.setSharePriceMultipliers(sharePriceMultipliers);
    data.setSharePricePerYear(sharePricePerYear);
    setValuationPerYear(currentValuation, sharePriceMultipliers);
  }

  return (
    <Box className="w-78 p-4 bg-white rounded-lg ">
      <VStack className="justify-between gap-9 p-4">
        <VStack className="justify-between gap-2">
          <p className="text-lg font-medium text-gray-dark">Valuation</p>
          <p className="text-s font-medium text-gray-light">
            This section accounts for the change in the value of the company
            over the course of its lifecycle. This is required to understand the
            worth of the options once it converts to equity.
          </p>
        </VStack>

        <HStack className="justify-start">
          <p className={` form-label text-sm font-medium w-1/3 mt-3 `}>
            Current Valuation
          </p>
          <Box className="w-1/3 flex items-start ml-2">
            <Input
              type="number"
              placeholder="Current Valuation"
              className="w-48"
              value={currentValuation}
              onChange={(e) => {
                onChangeCurrentValuation(e);
              }}
            />
          </Box>
        </HStack>
        <HStack className="justify-start">
          <p className={` form-label text-sm font-medium w-1/3 mt-3 `}>
            Current Share Price
          </p>
          <Box className="w-1/3 flex items-start ml-2">
            <Input
              type="number"
              placeholder="Current Share Price"
              className="w-48"
              value={currentSharePrice}
              onChange={(e) => {
                onChangeCurrentSharePrice(e);
              }}
            />
          </Box>
        </HStack>
        <HStack className="justify-between">
          <Box className="max-h-full overflow-auto h-[300px]">
            <table className="w-[600px]  table-space">
              <thead className="text-xs font-medium text-gray-light">
                <tr className="border-b border-dashed ">
                  <td className="py-3 hover:cursor-pointer">
                    <HStack className="ml-6">
                      Year of Grant<VStack className="h-4"></VStack>
                    </HStack>
                  </td>
                  <td className="py-3 hover:cursor-pointer">
                    <HStack className="ml-6">
                      Share Price Multiple<VStack className="h-4"></VStack>
                    </HStack>
                  </td>
                  <td className="py-3 hover:cursor-pointer">
                    <HStack className="ml-6">
                      Price Per Share<VStack className="h-4"></VStack>
                    </HStack>
                  </td>
                  <td className="py-3 hover:cursor-pointer"></td>
                </tr>
              </thead>
              {currentSharePrice > 0 && (
                <tbody className="font-medium text-dark">
                  {planningPeriodArr.map((_, yearIndex) => (
                    <tr
                      className=" border-b  border-dashed cursor-pointer hover:bg-slate-50"
                      key={`year-${yearIndex}`}
                    >
                      <td className="py-2 text-center">Year {yearIndex + 1}</td>
                      <td className="py-2 text-center">
                        {data.sharePriceMultipliers[yearIndex]}
                      </td>
                      <td className="py-2 text-center">
                        {data.sharePricePerYear[yearIndex]}
                      </td>
                      <td className="px-2 py-4 align-top">
                        <CTADropdown
                          actions={[{ name: "Edit" }]}
                          onAction={(action) => handleAction(action)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </Box>
        </HStack>
        <Dialog open={dialog.open} maxWidth="lg">
          {dialog.mode === "Edit" && (
            <EditSharePriceMultiplier
              onPrimaryAction={(
                sharePriceMultipliers: number[],
                sharePricePerYear: number[]
              ) => {
                editSharePriceMultiplier(
                  sharePriceMultipliers,
                  sharePricePerYear
                );
                setDialog({ ...dialog, open: false });
              }}
              onClose={() => {
                setDialog({ ...dialog, open: false });
              }}
              sharePriceMultipliers={data.sharePriceMultipliers}
              sharePricePerYear={data.sharePricePerYear}
              planningPeriod={planningPeriod}
              currentSharePrice={currentSharePrice}
            />
          )}
        </Dialog>
        <HStack className="justify-between pt-12">
          <ButtonPrimary1
            type="reset"
            className="text-red-500 mr-8"
            onClick={() => {
              onBack();
            }}
          >
            Back
          </ButtonPrimary1>
          <ButtonPrimary
            type="submit"
            onClick={() => {
              onNext();
            }}
          >
            Next
          </ButtonPrimary>
        </HStack>
      </VStack>
    </Box>
  );
}

export default Valuation;
