import randomColor from "randomcolor";
import React, { useState } from "react";
import { Center, HStack } from "../../components/utils";
import StatusLabelColors from "../../constants/StatusLabelColors";

function RoundIdentifiers({
  roundNames,
  itemsToDisplay = 3,
}: {
  roundNames: string[];
  itemsToDisplay?: number;
}) {
  const totalItems = roundNames.length;
  const [expanded, setExpanded] = useState(false);
  const listToDisplay = roundNames.slice(0, itemsToDisplay);
  const remaining = totalItems - itemsToDisplay;

  return (
    <>
      {expanded ? (
        <HStack
          className="max-w-56 overflow-x-auto gap-2 h-9"
          onClick={() => setExpanded(false)}
        >
          {roundNames.map((employee, index) => (
            <TabItem name={employee} key={`${employee}-${index}`} />
          ))}
        </HStack>
      ) : (
        <>
          {" "}
          {listToDisplay.map((item, _listIndex) => (
            <TabItem name={item} key={`${item}-${_listIndex}`} />
          ))}{" "}
          {remaining > 0 && (
            <div
              className="hover:cursor-pointer"
              onClick={() => setExpanded(true)}
            >
              <TabItem name={`+${remaining}`} />{" "}
            </div>
          )}
        </>
      )}
    </>
  );
}

function TabItem({ name }: { name: string }) {
  return (
    <div
      className="h-6 capitalize rounded-sm font-medium text-center text-[11px] leading-4 px-2 "
      style={{
        color: StatusLabelColors?.ROUND?.color,
        backgroundColor: StatusLabelColors?.ROUND?.backgroundColor,
      }}
    >
      <Center className="py-1.5">
        <p>{name}</p>
      </Center>
    </div>
  );
}

export default RoundIdentifiers;
