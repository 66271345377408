import { Icon } from "@iconify/react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import _ from "lodash";
import { Dialog } from "@mui/material";
import { useState } from "react";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
} from "../components/utils";
import { useError } from "../store/errorStore";
import SignaturePad from "../shared/signaturePad";
import {
  usePreviewSettlementLetter,
  useUpdateSettlementLetterAfterPreviewing,
} from "../queries";
import {
  SettlementSignatureDto,
  UpdateSettlementLetterDto,
} from "../types/Grant";
import LazyPdfDocument from "../components/shared/LazyPdfDocument";
import { convertBase64ToBlob } from "../utils/DownloadFile";

export function SendSettlementLetter({
  onClose = () => {},
}: {
  onClose: () => void;
}) {
  const { id } = useParams();
  const _id = id || "";
  const errorMessage = useError();
  const { mutate: previewSettlementLetter, isLoading } =
    usePreviewSettlementLetter();
  const { mutate: updateDocument, isLoading: sendingSettlementLetter } =
    useUpdateSettlementLetterAfterPreviewing();
  const [settlementPreviewLink, setSettlementPreviewLink] =
    useState<UpdateSettlementLetterDto>({
      employeeId: parseInt(_id, 10),
      fileUrl: "",
      base64file: "",
    });
  const [isSignaturePadOpen, setIsSignaturePadOpen] = useState(true);
  function sendFinalSettlementLetter() {
    updateDocument(settlementPreviewLink, {
      onSuccess: (res) => {
        onClose();
        toast(res.data, {
          type: "success",
          autoClose: 2000,
        });
      },
      onError: (err: any) => {
        onClose();
        errorMessage.setMessage(err.response);
        toast(err.response, { type: "error", autoClose: 2000 });
      },
    });
  }
  const handleSignaturePad = (signature: string) => {
    if (signature) {
      signature = signature.replace("data:image/png;base64,", "");
    }
    const signatureAttachmentObj: SettlementSignatureDto = {
      signature,
      employeeId: parseInt(_id, 10),
    };
    previewSettlementLetter(signatureAttachmentObj, {
      onSuccess: async (data) => {
        const blob = await convertBase64ToBlob(data, "application/pdf");
        const objectURL = URL.createObjectURL(blob);
        setSettlementPreviewLink({
          employeeId: parseInt(_id, 10),
          fileUrl: objectURL,
          base64file: data,
        });
        setIsSignaturePadOpen(false);
      },
    });
  };
  return (
    <>
      <Dialog maxWidth="md" fullWidth open={isSignaturePadOpen}>
        <SignaturePad
          onSecondaryAction={() => onClose()}
          onPrimaryAction={(signature) => handleSignaturePad(signature)}
          isLoading={isLoading}
        />
      </Dialog>
      <Dialog maxWidth="md" fullWidth open={!isSignaturePadOpen}>
        <Box className="h-100">
          <span className="p-1">Review your signature</span>
          <div className="h-1/4 max-h-96 overflow-y-auto">
            <LazyPdfDocument url={settlementPreviewLink.fileUrl} />
          </div>
          <HStack className="justify-between p-4 mt-4">
            <ButtonPrimary1
              onClick={() => setIsSignaturePadOpen(!isSignaturePadOpen)}
            >
              Back
            </ButtonPrimary1>
            <ButtonPrimary
              type="button"
              onClick={() => {
                sendFinalSettlementLetter();
              }}
            >
              {!sendingSettlementLetter ? (
                `Confirm and Send`
              ) : (
                <Icon
                  className="animate-spin"
                  icon="lucide:loader-2"
                  width={36}
                />
              )}
            </ButtonPrimary>
          </HStack>
        </Box>
      </Dialog>
    </>
  );
}
