import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { ButtonPrimary, HStack, VStack } from "../components/utils";
import esopLogo from "../utils/assets/esop.svg";
import esopLogoGrayed from "../utils/assets/esop-grayed.svg";
import { useAuthStore } from "../store";
import { useGetCompanyExists, useSetDefaultProduct } from "../queries/login";
import { doesCaptableCompanyExists } from "../api/Esop";

export interface CaptableUserAccess {
  captableExists: boolean;
  canUserAccess: boolean;
}

function SwitchProduct({ setDialogOpen }: any) {
  const { user } = useAuthStore();
  const { mutate: setDefaultCaptable } = useSetDefaultProduct();

  const [isCaptableExists, setCaptableExists] = useState(false);
  const canUserAccess = isCaptableExists;
  const esopRole = "";
  useEffect(() => {
    if (user?.company.uuid === undefined || user.company.uuid == null) {
      setCaptableExists(false);
    } else {
      doesCaptableCompanyExists(user?.company.uuid).then(
        (res: { canUserAccess: boolean; captableExists: boolean }) => {
          if (res.canUserAccess && res.captableExists) {
            setCaptableExists(true);
          }
        },
        (err) => {
          setCaptableExists(false);
        }
      );
    }
  }, [user]);

  const dialog = useRef<HTMLDivElement>(null);

  const checkIfClickOutside = (e: any) => {
    if (dialog?.current && !dialog?.current?.contains(e.target)) {
      setDialogOpen(false);
    }
  };
  document.addEventListener("mousedown", checkIfClickOutside);

  const redirectToCaptable = async () => {
    if (
      user?.company?.uuid !== undefined &&
      user?.company?.uuid &&
      isCaptableExists
    ) {
      const accessToken = localStorage.getItem("accesstoken") || "";
      await setDefaultCaptable();
      const captableUrl = `${process.env.REACT_APP_OPTIONS_URL}/landing/redirect/${user?.company.uuid}/${accessToken}`;
      window.location.href = captableUrl;
    }
  };

  return (
    <div>
      <div
        id="dropdown"
        className="absolute z-40 bg-white mt-12 text-black border divide-y divide-gray-500 rounded shadow w-72"
        ref={dialog}
      >
        <ul
          className="py-1 text-xs font-normal text-dark w-full"
          aria-labelledby="dropdownDefault"
        >
          <HStack>
            <div className="font-medium text-base p-3">Switch Platform</div>
          </HStack>

          <HStack
            className={`justify-between m-1 p-1 hover:bg-gray-100 ${
              isCaptableExists && canUserAccess
                ? "cursor-pointer"
                : "bg-gray-100 cursor-not-allowed"
            } items-center`}
            onClick={redirectToCaptable}
          >
            <HStack className="items-center">
              <Icon
                icon="raphael:piechart"
                color={`${
                  isCaptableExists && canUserAccess ? "#E85936" : "#9CA3AF"
                }`}
                width="30"
                height="30"
              ></Icon>
              <p
                className={`font-semibold text-base p-1 ${
                  isCaptableExists && canUserAccess
                    ? "text-orange-600"
                    : "text-gray-400"
                }`}
              >
                Cap Table
              </p>
            </HStack>
            <div className="p-1">
              {isCaptableExists && canUserAccess ? (
                <div>
                  {esopRole && (
                    <p
                      className={`w-fit mr-4 h-fit px-1 py-0.5 text-xs rounded-md font-semibold bg-inherit border text-blue-600 border-blue-600 px-2`}
                    >
                      {esopRole}
                    </p>
                  )}
                </div>
              ) : (
                <div>
                  <Icon
                    icon="il:lock"
                    width="18"
                    height="18"
                    className="mr-10"
                    color="gray"
                  />
                </div>
              )}
            </div>
          </HStack>
          <HStack className="justify-between m-1 p-1 hover:bg-gray-100 items-center">
            <HStack className="items-center">
              <img className="h-8" src={esopLogo} alt="esop" color="#E85936" />
              <p className="font-semibold text-base p-1 text-orange-600">
                ESOP
              </p>
            </HStack>
          </HStack>
        </ul>
      </div>
    </div>
  );
}

export default SwitchProduct;
