import create from "zustand";
import { Shareholder } from "../types/EsopPlan";
import {
  InstrumentChartModel,
  RoundNameChartModel,
  ShareHolderChartModel,
} from "../types/HistoricOverView";

export type ChartState = {
  shareHolder: ShareHolderChartModel[];
  instrument: InstrumentChartModel[];
  round: RoundNameChartModel[];
  setShareHolder: (chartData: ShareHolderChartModel[]) => void;
  setInstrument: (chartData: InstrumentChartModel[]) => void;
  setRound: (chartData: RoundNameChartModel[]) => void;
  reset: () => void;
};

const initialState = {
  shareHolder: [],
  instrument: [],
  round: [],
};

const historicChartStore = create<ChartState>((set) => ({
  ...initialState,

  setShareHolder: (chartData: ShareHolderChartModel[]) => {
    set((state) => ({
      shareHolder: chartData,
    }));
  },
  setInstrument: (chartData: InstrumentChartModel[]) => {
    set((state) => ({
      instrument: chartData,
    }));
  },
  setRound: (chartData: RoundNameChartModel[]) => {
    set((state) => ({
      round: chartData,
    }));
  },
  reset: () => {
    set(() => initialState);
  },
}));

export const useHistoricChartStore = historicChartStore;
