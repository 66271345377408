import {
  DeleteRes,
  EsopSubsidiaryCompany,
} from "../types/EsopSubsidiaryCompany";
import hissaApi from "./hissaApi";

/**
 * resource: esopPlan
 * action: view
 * @returns
 */

export async function getEsopSubsidiaryCompanies(): Promise<
  EsopSubsidiaryCompany[]
> {
  return hissaApi.get("/esopSubsidiaryCompany/getAll").then((res) => res.data);
}

/**
 * resource: esopPlan
 * action: create
 * @returns
 */

export async function addSubsidiaryCompany(
  company: EsopSubsidiaryCompany
): Promise<EsopSubsidiaryCompany> {
  return hissaApi
    .post("/esopSubsidiaryCompany/addCompany", company)
    .then((res) => res.data)
    .catch((err) => err);
}

/**
 * resource: esopPlan
 * action: update
 * @returns
 */

export async function editSubsidiaryCompany(
  company: EsopSubsidiaryCompany
): Promise<EsopSubsidiaryCompany> {
  return hissaApi
    .put("/esopSubsidiaryCompany/editCompany", company)
    .then((res) => res.data)
    .catch((err) => err);
}

/**
 * resource: esopPlan
 * action: update
 * @returns
 */

export async function deleteSubsidiaryCompany(id: number): Promise<String> {
  return hissaApi
    .delete(`/esopSubsidiaryCompany/delete/?id=${id}`)
    .then((res) => res.data);
}
