import React from "react";
import { VStack } from "../../components/utils";
import BCHeader from "../../shared/BCHeader";

import TransactionSummary from "./TransactionSummary";

export type BuybackType = {
  name: string;
  value: string;
  disabled: boolean;
};

function BuybackIndex() {
  return (
    <VStack className="gap-4">
      <BCHeader bcTitle="ESOP Transaction" className="items-baseline py-3" />
      <TransactionSummary />
    </VStack>
  );
}

export default BuybackIndex;
