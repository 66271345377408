import React, { useEffect } from "react";
import _ from "lodash";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router";
import { Box, HStack, VStack, isEsopViewer } from "../utils";
import { optionBuybackDetail } from "../../types/OptionBuyback";
import { formatDisplayDate } from "../../utils/date";
import {
  useBuybackState,
  useGetOptionBuyback,
} from "../../queries/optionBuyback";
import { formatCurrency, getCurrencyType } from "../../utils/currencyFormatter";
import Tooltip from "./Tooltip";

export type summaryTypes = {
  name: string;
  transactionEvent: optionBuybackDetail[];
};
function SummaryCard(props: summaryTypes) {
  const currency = getCurrencyType();
  const isUserEsopViewer = isEsopViewer();
  const navigate = useNavigate();
  const { name, transactionEvent } = props;
  const latestSorted = _.sortBy(
    transactionEvent,
    (o) => o.startDate
  ).reverse()[0];

  const {
    data: _optionBuyBack,
    isPlaceholderData,
    isLoading,
    isFetching,
  } = useGetOptionBuyback();

  const isCreateDisabled = (_optionBuyBack || []).some(
    (ob) => ob.state.toLowerCase() !== "completed"
  );
  const { data: _buybackStateData, refetch } = useBuybackState(
    latestSorted?.id || 0
  );

  useEffect(() => {
    refetch();
  }, [latestSorted]);
  function getTotalMoney() {
    let totalWillingNess = 0;
    (_buybackStateData || []).forEach((offer) => {
      if (
        offer.state === "COMPLETED" ||
        offer.state === "ACCEPTED" ||
        offer.state === "PENDING_EXERCISED"
      ) {
        totalWillingNess += offer.willingness;
      }
    });
    return totalWillingNess;
  }
  function createEvent(name: string): void {
    if (name === "Exercise") {
      navigate("/options/create-exercise-flow?mode=create");
    } else if (name === "Buy Back") {
      navigate("/options/create-option-buyback?mode=create");
    } else if (name === "Exercise and Liquidation") {
      navigate("/options/create-exercise-liquidation-flow?mode=create");
    }
  }
  return (
    <>
      <VStack
        className={`${
          isPlaceholderData ? "loading" : " "
        } gap-2 m-2 rounded shadow-md border-red h-44 max-h-52`}
      >
        {latestSorted ? (
          <>
            <HStack className="items-center justify-between gap-2 p-4 ">
              <div className="text-sm font-medium text-gray-dark">
                <p>{name}</p>
              </div>
              <div className="font-medium text-xxs text-gray-dark">
                <p>{formatDisplayDate(latestSorted?.startDate)}</p>
              </div>
            </HStack>
            <HStack className={`${isFetching ? "loading" : "justify-center"} `}>
              <VStack className="items-center">
                <p className="text-xl text-green-600">
                  {formatCurrency(
                    getTotalMoney() *
                      (latestSorted.transactionType === "buyback"
                        ? latestSorted?.price || 0
                        : latestSorted?.sellingPrice || 0),
                    currency
                  )}
                </p>
                <p className="text-xxs text-gray-dark">
                  (
                  {latestSorted.transactionType === "buyback"
                    ? "Cash out"
                    : "Exercise "}{" "}
                  value)
                </p>
              </VStack>
            </HStack>
            <HStack className="justify-between p-4">
              <div className="font-medium text-xxs text-gray-dark">
                {latestSorted.transactionType === "buyback"
                  ? "Price Per Share"
                  : "Sale Price "}{" "}
              </div>
              <div className="text-xs font-medium text-gray-dark">
                {formatCurrency(
                  latestSorted.transactionType === "buyback"
                    ? latestSorted?.price || 0
                    : latestSorted?.sellingPrice || 0,
                  currency
                )}
              </div>
            </HStack>
          </>
        ) : (
          <>
            <HStack className="justify-between gap-2 p-4 ">
              <div className="text-sm font-medium text-gray-dark">{name}</div>
            </HStack>
            <HStack className="justify-center ">
              <VStack className="items-center gap-2">
                <Tooltip
                  text={`${
                    isCreateDisabled
                      ? "To create a new Event, Please complete your previous closed events"
                      : ""
                  }`}
                  _className="w-[200px]"
                >
                  <Icon
                    icon="fluent:add-circle-28-regular"
                    className={`${
                      isCreateDisabled
                        ? "text-opacity-20 cursor-not-allowed"
                        : ""
                    } cursor-pointer text-orange-501 h-11 w-11`}
                    onClick={() => {
                      if (isCreateDisabled || isUserEsopViewer) return;
                      createEvent(name);
                    }}
                  />
                </Tooltip>
                <Box className="items-center text-xs text-gray-dark">{`Add ${name} event`}</Box>
              </VStack>
            </HStack>
          </>
        )}
      </VStack>
    </>
  );
}

export default SummaryCard;
