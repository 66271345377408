import { QueryFunctionContext } from "react-query";
import { FaqForEmployeeInterface, Faqparam } from "../types/faqPara";
import hissaApi from "./hissaApi";

export async function setAllFaq(faqDto: Faqparam[]): Promise<Faqparam[]> {
  return hissaApi.post(`/company/setFaq`, faqDto).then((res) => res.data);
}

export async function getAllFaq(): Promise<Faqparam[]> {
  return hissaApi.get(`/company/getFaq`).then((res) => res.data);
}

export async function getFaqSetting(): Promise<FaqForEmployeeInterface> {
  return hissaApi.get(`/AdminSetting/settings`).then((res) => res.data);
}

export async function setEmployeeFaqSettings(faqForEmployeeParam: {
  faqForEmployee: boolean;
}): Promise<FaqForEmployeeInterface> {
  return hissaApi
    .post(`/AdminSetting/save`, faqForEmployeeParam)
    .then((res) => res.data);
}
