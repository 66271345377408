/* eslint-disable max-len */
/* eslint-disable consistent-return */
import { CircularProgress, Switch } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
import { Formik, useFormik, useFormikContext } from "formik";
import { format } from "date-fns";
import * as Yup from "yup";
import { ChartData, Legend } from "chart.js";
import * as React from "react";
import { toast } from "react-toastify";
import sumBy from "lodash/sumBy";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import {
  ButtonPrimary1,
  Error,
  ButtonPrimary,
  HStack,
  VStack,
  ButtonSecondary,
} from "../../components/utils";
import {
  useVestingTemplates,
  useAddVestingTemplate,
} from "../../queries/vestingTemplate";
import {
  baseEventScheduleDto,
  baseTimeScheduleDto,
  vestingBaseValues,
} from "./initValues";
import {
  AddVestingTemplateReq,
  VestingTemplate,
  VestingTemplateDetail,
  EventScheduleDto,
  VestingTriggerType,
  VestingType,
  AddVestingTemplateRes,
} from "../../types/VestingTemplate";
import { generateProjections } from "./generateProjections";
import { BarChart } from "./BarChart";
import { formatWithTimeZone } from "../../utils/date";
import { useError } from "../../store/errorStore";
import { useVestingScheduleDialog } from "../../store/useDialogStore";

type AddOrEditTemplateProps = {
  mode?: "Edit" | "Add" | "View";
  onClose: () => void;
  template?: VestingTemplate;
  onCreate?: (template: AddVestingTemplateRes) => void;
};

export function AddOrEditVestingTemplate({
  mode = "Add",
  onClose = () => {},
  onCreate = () => {},
  template,
}: AddOrEditTemplateProps) {
  const { setState: setDialog } = useVestingScheduleDialog();
  const [selectedTemplate, setSelectedTemplate] = useState<
    VestingTemplate | undefined
  >();
  const { data: _vestingTemplates } = useVestingTemplates();
  const vestingTemplates = _vestingTemplates || [];
  const vestingScheduleTemplates = vestingTemplates?.filter(
    (schedule) => !schedule.isDefault
  );
  const _vestingTemplateNames = vestingScheduleTemplates?.map(
    (v) => v.vestingTemplateName
  );
  const vestingTemplateNames = _vestingTemplateNames || [];
  const { mutate: addTemplate, isLoading: addTemplateLoading } =
    useAddVestingTemplate();
  const { mutate: editTemplate, isLoading: editTemplateLoading } =
    useAddVestingTemplate();
  const isLoading = mode === "Edit" ? editTemplateLoading : addTemplateLoading;

  const errorMessage = useError();

  const validationSchema = Yup.object().shape({
    vestingTemplateName: Yup.string().required("required"),
    // .notOneOf(vestingTemplateNames.map((v) => v.toString())),
    vestingTriggerType: Yup.string().oneOf(Object.keys(VestingTriggerType)),
    timeScheduleDtos: Yup.array().of(
      Yup.object({
        percentage: Yup.number(),
        vestingInterval: Yup.number(),
        vestingDuration: Yup.number(),
      })
    ),
    eventScheduleDtos: Yup.array().of(
      Yup.object({
        percentage: Yup.number(),
        eventName: Yup.string(),
        eventTargetDate: Yup.date(),
      })
    ),
    percentage: Yup.number().when("vestingType", {
      is: "Custom",
      then: Yup.number().test(
        "percentage",
        "total percentage should be 100",
        (_value, context) => {
          const value = context.parent as AddVestingTemplateReq;
          if (
            (!value.timeScheduleDtos || value.timeScheduleDtos.length < 1) &&
            (!value.eventScheduleDtos || value.eventScheduleDtos.length < 1)
          )
            return true;
          const totalPercentage =
            sumBy(value.eventScheduleDtos, (dto) => dto.percentage || 0) +
            sumBy(value.timeScheduleDtos, (dto) => dto.percentage || 0);
          return totalPercentage === 100;
        }
      ),
    }),
  });

  function getInitialValues() {
    if ((mode === "Edit" || mode === "View") && template) {
      const temp = getValuesForEditing(template) as AddVestingTemplateReq;
      temp.timeScheduleDtos.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
      temp.eventScheduleDtos.sort(
        (a, b) => a.sequenceNumber - b.sequenceNumber
      );
      return temp;
    } else {
      return selectedTemplate || vestingBaseValues;
    }
  }

  function getFormValuesFromImport(
    template: VestingTemplate | undefined
  ): AddVestingTemplateReq | undefined {
    if (template) {
      return {
        ...template,
        id: null,
        vestingTemplateName: "",
        includeTimeVesting:
          template?.vestingTriggerType === VestingTriggerType.Time ||
          template?.vestingTriggerType === VestingTriggerType.Both,
        includeEventVesting:
          template?.vestingTriggerType === VestingTriggerType.Event ||
          template?.vestingTriggerType === VestingTriggerType.Both,
        timeScheduleDtos: [
          ...template.schedules.filter(
            (s) => s.vestingTriggerType === VestingTriggerType.Time
          ),
        ],
        eventScheduleDtos: [
          ...template.schedules.filter(
            (s) => s.vestingTriggerType === VestingTriggerType.Event
          ),
        ],
      };
    }
  }
  function getValuesForEditing(
    template: VestingTemplate | undefined
  ): AddVestingTemplateReq | undefined {
    if (template) {
      return {
        ...template,
        includeTimeVesting:
          template?.vestingTriggerType === VestingTriggerType.Time ||
          template?.vestingTriggerType === VestingTriggerType.Both,
        includeEventVesting:
          template?.vestingTriggerType === VestingTriggerType.Event ||
          template?.vestingTriggerType === VestingTriggerType.Both,
        timeScheduleDtos: [
          ...template.schedules.filter(
            (s) => s.vestingTriggerType === VestingTriggerType.Time
          ),
        ],
        eventScheduleDtos: [
          ...template.schedules.filter(
            (s) => s.vestingTriggerType === VestingTriggerType.Event
          ),
        ],
      };
    }
  }

  function handleImport(
    e: any,
    setValues: (
      values: React.SetStateAction<VestingTemplate | AddVestingTemplateReq>,
      shouldValidate?: boolean | undefined
    ) => void
  ) {
    const template = vestingTemplates.find(
      (t) => t.id.toString() === e.target.value
    );
    if (!template) return;
    setSelectedTemplate(template);
    setValues(getFormValuesFromImport(template) || vestingBaseValues);
  }

  return (
    <Formik
      initialValues={getInitialValues() || vestingBaseValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        let template = { ...values } as AddVestingTemplateReq;
        let triggerType =
          values.includeTimeVesting && !values.includeEventVesting
            ? VestingTriggerType.Time
            : VestingTriggerType.Event;
        triggerType =
          values.includeTimeVesting && values.includeEventVesting
            ? VestingTriggerType.Both
            : triggerType;
        triggerType =
          !values.includeTimeVesting && !values.includeEventVesting
            ? VestingTriggerType.Time
            : triggerType;
        const timeScheduleDtos =
          template.vestingType === VestingType.Custom &&
          values.includeTimeVesting
            ? [
                ...template.timeScheduleDtos.map((s) => ({
                  ...s,
                  eventTargetDate: null,
                  vestingScheduleState: "ForApproval",
                  percentage: s.percentage / 100,
                })),
              ]
            : [];
        const eventScheduleDtos =
          template.vestingType === VestingType.Custom &&
          values.includeEventVesting
            ? [
                ...template.eventScheduleDtos.map((s) => ({
                  ...s,
                  eventTargetDate: formatWithTimeZone(s.eventTargetDate),
                  vestingScheduleState: "ForApproval",
                  percentage: s.percentage / 100,
                })),
              ]
            : [];
        template = {
          ...template,
          timeScheduleDtos,
          eventScheduleDtos,
        };
        template.vestingTriggerType = triggerType;
        template.isDefault = false;
        if (mode === "Edit") {
          editTemplate(template, {
            onSuccess: (data) => {
              resetForm();
              onCreate(data);
              toast("Template edited successfully", { type: "success" });
              onClose();
            },
            onError: (err: any) => {
              errorMessage.setMessage(err.response.data.reason);
              toast(err.response.data.reason, {
                type: "error",
                autoClose: 2000,
              });
            },
          });
        } else {
          addTemplate(template, {
            onSuccess: (data) => {
              resetForm();
              onCreate(data);
              toast("Template Added successfully", { type: "success" });
              onClose();
            },
            onError: (err: any) => {
              errorMessage.setMessage(err.response.data.reason);
              toast(err.response.data.reason, {
                type: "error",
                autoClose: 2000,
              });
            },
          });
        }
      }}
    >
      {({
        values,
        touched,
        errors,
        getFieldProps,
        isValid,
        resetForm,
        handleSubmit,
        setValues,
        setFieldValue,
      }) => (
        <VStack className="w-full gap-9">
          <HStack className="gap-8 ">
            <VStack className="flex-1 gap-9">
              <div>
                <Label className="text-sm font-normal">
                  Import Vesting Schedule
                </Label>
                <Select
                  disabled={mode === "Edit" || mode === "View"}
                  options={vestingTemplates}
                  valueGetter={(o) => o.id}
                  textGetter={(o) => o.vestingTemplateName}
                  onChange={(e) => handleImport(e, setValues)}
                />
              </div>
              <div>
                <Label className="text-sm font-normal">Vesting Type</Label>
                <Select
                  disabled={mode === "View"}
                  options={["Standard", "Custom"]}
                  {...getFieldProps("vestingType")}
                />
              </div>
            </VStack>
            <div className="flex-1">
              <ProjectionChart
                includeTimeVesting={values.includeTimeVesting}
                includeEventVesting={values.includeEventVesting}
              />
            </div>
          </HStack>
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Vesting Template Name
              </Label>
              <Input
                disabled={mode === "View"}
                {...getFieldProps("vestingTemplateName")}
              />
              {touched.vestingTemplateName && errors.vestingTemplateName && (
                <Error text={errors.vestingTemplateName} />
              )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Vesting Cliff (Months)
              </Label>
              <Input
                type="number"
                disabled={mode === "View"}
                className=""
                {...getFieldProps("cliffPeriod")}
              />
              {touched.cliffPeriod && errors.cliffPeriod && (
                <Error text={errors.cliffPeriod} />
              )}
            </div>
          </HStack>
          {values.vestingType === VestingType.Standard && (
            <HStack className="gap-8">
              <div className="flex-1">
                <Label className="text-sm font-normal">
                  Vesting Interval (Months)
                </Label>
                <Input
                  type="number"
                  disabled={mode === "View"}
                  {...getFieldProps("vestingInterval")}
                />
                {touched.vestingInterval && errors.vestingInterval && (
                  <Error text={errors.vestingInterval} />
                )}
              </div>
              <div className="flex-1">
                <Label className="text-sm font-normal">
                  Vesting Period (Months)
                </Label>
                <Input
                  type="number"
                  disabled={mode === "View"}
                  className=""
                  {...getFieldProps("vestingPeriod")}
                />
                {touched.vestingPeriod && errors.vestingPeriod && (
                  <Error text={errors.vestingPeriod} />
                )}
              </div>
            </HStack>
          )}
          {values.vestingType === VestingType.Custom && mode !== "View" && (
            <>
              <HStack className="flex-row-reverse items-center justify-end">
                <Switch
                  checked={values.includeTimeVesting}
                  onChange={() => {
                    setFieldValue(
                      "includeTimeVesting",
                      !values.includeTimeVesting
                    );
                  }}
                  value={values.includeTimeVesting}
                />
                <HandleSwitchToggle />
                <Label className="mb-0 text-sm font-medium">
                  Include Time Vesting
                </Label>
              </HStack>
              {values.includeTimeVesting && <TimeScheduleDtos mode={mode} />}
              <HStack className="flex-row-reverse items-center justify-end">
                <Switch
                  onChange={() => {
                    setFieldValue(
                      "includeEventVesting",
                      !values.includeEventVesting
                    );
                  }}
                  value={values.includeEventVesting}
                  checked={values.includeEventVesting}
                />
                <HandleSwitchToggle />
                <Label className="mb-0 text-sm font-medium">
                  Include Event Vesting
                </Label>
              </HStack>
              {values.includeEventVesting && <EventScheduleDtos mode={mode} />}
            </>
          )}
          {values.vestingType === VestingType.Custom && mode === "View" && (
            <>
              <HStack className="items-center">
                {values.includeTimeVesting && (
                  <Label className="mb-0 text-sm font-normal">
                    Time Vesting
                  </Label>
                )}
              </HStack>
              {values.includeTimeVesting && <TimeSchedule />}
              <HStack className="items-center">
                <Label className="mb-0 text-sm font-normal">
                  Event Vesting
                </Label>
              </HStack>
              {values.includeEventVesting && <EventSchedule />}
            </>
          )}
          <HStack className="justify-between my-10 justify">
            <ButtonPrimary1 onClick={() => onClose()}>Back</ButtonPrimary1>
            <ButtonPrimary1
              onClick={() => {
                resetForm();
              }}
            >
              Clear
            </ButtonPrimary1>
            <div className="flex items-center">
              {mode !== "View" && (
                <ButtonPrimary
                  disabled={isLoading}
                  className={`${!isValid && "bg-gray-400"}`}
                  onClick={(e) => {
                    handleSubmit();
                  }}
                >
                  {mode === "Edit" ? "Update Template" : "Create Template"}
                </ButtonPrimary>
              )}
              {mode === "View" && (
                <ButtonPrimary
                  disabled={
                    isLoading ||
                    !template?.isEditable ||
                    (template?.numberOfUsersApllied || 1) > 0
                  }
                  className={`${
                    !template?.isEditable &&
                    "bg-gray-400 hover:bg-gray-400 cursor-not-allowed"
                  }`}
                  onClick={(e) => {
                    if (
                      template?.isEditable &&
                      (template?.numberOfUsersApllied || 1) === 0
                    ) {
                      setDialog({ open: false });
                      setDialog({ open: true, template, mode: "Edit" });
                    }
                  }}
                >
                  Edit Template
                </ButtonPrimary>
              )}

              <div className="">
                {isLoading && <CircularProgress size={32} />}
              </div>
            </div>
          </HStack>
        </VStack>
      )}
    </Formik>
  );
}

function HandleSwitchToggle() {
  const formik = useFormikContext<AddVestingTemplateReq>();
  useEffect(() => {
    if (!formik.values.includeTimeVesting)
      formik.setFieldValue("timeScheduleDtos", [baseTimeScheduleDto]);
  }, [formik.values.includeTimeVesting]);
  useEffect(() => {
    if (!formik.values.includeEventVesting)
      formik.setFieldValue("eventScheduleDtos", [baseEventScheduleDto]);
  }, [formik.values.includeEventVesting]);
  return <div />;
}

export function AddVestingTemplateWrapper({
  mode = "Add",
  onClose = () => {},
  onCreate = () => {},
  template,
}: AddOrEditTemplateProps) {
  return (
    <div>
      <div className="px-10 text-lg font-medium border-b py-7">
        <h6 className="flex justify-between">
          {mode === "Edit"
            ? "Edit Template"
            : mode === "View"
            ? template?.vestingTemplateName
            : "Add New Template"}
          <span onClick={() => onClose()} className="cursor-pointer">
            X
          </span>
        </h6>
      </div>
      <div className="px-10 pt-4">
        <AddOrEditVestingTemplate
          mode={mode}
          onClose={onClose}
          onCreate={onCreate}
          template={template}
        />
      </div>
    </div>
  );
}

function TimeScheduleDtos({ mode }: { mode?: "Edit" | "Add" | "View" }) {
  const formik = useFormikContext<AddVestingTemplateReq>();
  const { values, errors, touched, getFieldProps, setFieldValue } = formik;
  function handleAdd(index: number) {
    const schedules = [...values.timeScheduleDtos];
    const newSchedule = baseTimeScheduleDto;
    schedules.splice(index + 1, 0, newSchedule);
    setFieldValue("timeScheduleDtos", schedules);
  }
  function handleDelete(index: number) {
    setFieldValue(
      "timeScheduleDtos",
      values?.timeScheduleDtos?.filter((_, i) => i !== index)
    );
  }
  return (
    <VStack aria-label="header" className="gap-y-2">
      <HStack className="gap-x-8">
        <div className="flex-1 ">
          <Label className="text-sm font-normal">Percentage (%)</Label>
        </div>
        <div className="flex-1 ">
          <Label className="text-sm font-normal">
            Vesting Interval (Months)
          </Label>
        </div>
        <div className="flex-1 ">
          <Label className="text-sm font-normal">
            Vesting Duration (Months)
          </Label>
        </div>
        <div className="flex self-center flex-1 gap-2">
          {values?.timeScheduleDtos?.length === 0 && (
            <>
              <ButtonSecondary
                className="text-[11px] leading-[20px] font-medium py-1 bg-slate-201 text-slate-501 h-auto px-2"
                onClick={() => handleAdd(0)}
              >
                Add a Row
              </ButtonSecondary>
              <button
                onClick={() => handleDelete(0)}
                className="text-zinc-300 hover:scale-105"
              >
                <DeleteIcon />
              </button>
            </>
          )}
        </div>
      </HStack>
      {values?.timeScheduleDtos?.map((_, index) => {
        const timeScheduleDto = values.timeScheduleDtos[index];
        const fieldTouched = touched?.timeScheduleDtos?.[index];
        const err = errors?.timeScheduleDtos?.[index] as any;
        return (
          <HStack className="gap-x-8" key={index}>
            <div className="flex-1">
              <Input
                type="number"
                max={100}
                {...getFieldProps(`timeScheduleDtos[${index}].percentage`)}
              />
              {fieldTouched?.percentage && err?.percentage && (
                <Error text={err?.percentage} />
              )}
              {fieldTouched?.percentage && <Error text={errors?.percentage} />}
            </div>
            <div className="flex-1">
              <Input
                type="number"
                className=""
                {...getFieldProps(`timeScheduleDtos[${index}].vestingInterval`)}
              />
              {fieldTouched?.vestingInterval && err?.vestingInterval && (
                <Error text={err?.vestingInterval} />
              )}
            </div>
            <div className="flex-1">
              <Input
                type="number"
                className=""
                {...getFieldProps(`timeScheduleDtos[${index}].vestingDuration`)}
              />
              {fieldTouched?.vestingInterval && err?.vestingInterval && (
                <Error text={err?.vestingInterval} />
              )}
            </div>
            <div className="flex self-center flex-1 gap-2">
              <ButtonSecondary
                className="text-[11px] leading-[20px] font-medium py-1 bg-slate-201 text-slate-501 h-auto px-2"
                onClick={() => handleAdd(index)}
              >
                Add a Row
              </ButtonSecondary>
              <button
                onClick={() => handleDelete(index)}
                className="text-zinc-300 hover:scale-105"
              >
                <DeleteIcon />
              </button>
            </div>
          </HStack>
        );
      })}
    </VStack>
  );
}

function TimeSchedule() {
  const formik = useFormikContext<AddVestingTemplateReq>();
  const { values, getFieldProps } = formik;
  return (
    <VStack aria-label="header" className="gap-y-2">
      <HStack className="gap-x-8">
        <div className="flex-1 ">
          <Label className="text-sm font-normal">Percentage (%)</Label>
        </div>
        <div className="flex-1 ">
          <Label className="text-sm font-normal">
            Vesting Interval (Months)
          </Label>
        </div>
        <div className="flex-1 ">
          <Label className="text-sm font-normal">
            Vesting Duration (Months)
          </Label>
        </div>
      </HStack>
      {values?.timeScheduleDtos?.map((_, index) => {
        const timeScheduleDto = values.timeScheduleDtos[index];
        return (
          <HStack className="gap-x-8" key={index}>
            <div className="flex-1">
              <Input
                disabled={true}
                value={Math.floor(timeScheduleDto.percentage)}
              />
            </div>
            <div className="flex-1">
              <Input
                disabled={true}
                {...getFieldProps(`timeScheduleDtos[${index}].vestingInterval`)}
              />
            </div>
            <div className="flex-1">
              <Input
                disabled={true}
                {...getFieldProps(`timeScheduleDtos[${index}].vestingDuration`)}
              />
            </div>
          </HStack>
        );
      })}
    </VStack>
  );
}

function EventScheduleDtos({ mode }: { mode?: "Edit" | "Add" | "View" }) {
  const formik = useFormikContext<AddVestingTemplateReq>();
  const { values, errors, touched, getFieldProps, setFieldValue } = formik;
  function handleAdd(index: number) {
    const schedules = [...values.eventScheduleDtos];
    const newSchedule = baseEventScheduleDto;
    schedules.splice(index + 1, 0, newSchedule);
    setFieldValue("eventScheduleDtos", schedules);
  }
  function handleDelete(index: number) {
    setFieldValue(
      "eventScheduleDtos",
      values?.eventScheduleDtos?.filter((_, i) => i !== index)
    );
  }
  return (
    <VStack aria-label="header" className="gap-y-2">
      <HStack className="gap-x-8">
        <div className="flex-1 ">
          <Label className="text-sm font-normal">Percentage (%)</Label>
        </div>
        <div className="flex-1 ">
          <Label className="text-sm font-normal">Event Name</Label>
        </div>
        <div className="flex-1 ">
          <Label className="text-sm font-normal">Target Date</Label>
        </div>
        <div className="flex self-center flex-1 gap-2">
          {values?.eventScheduleDtos?.length === 0 && (
            <>
              <ButtonSecondary
                className="text-[11px] leading-[20px] font-medium py-1 bg-slate-201 text-slate-501 h-auto px-2"
                onClick={() => handleAdd(0)}
              >
                Add a Row
              </ButtonSecondary>
              <button
                onClick={() => handleDelete(0)}
                className="text-zinc-300 hover:scale-105"
              >
                <DeleteIcon />
              </button>
            </>
          )}
        </div>
      </HStack>
      {values?.eventScheduleDtos?.map((_, index) => {
        const eventScheduleDto = values.eventScheduleDtos[index];
        const fieldTouched = touched?.eventScheduleDtos?.[index];
        const err = errors?.eventScheduleDtos?.[index] as any;
        return (
          <HStack className="gap-x-8" key={index}>
            <div className="flex-1">
              <Input
                type="number"
                {...getFieldProps(`eventScheduleDtos[${index}].percentage`)}
              />
              {fieldTouched?.percentage && err?.percentage && (
                <Error text={err?.percentage} />
              )}
              <Error text={errors?.percentage} />
            </div>
            <div className="flex-1">
              <Input
                type="text"
                className=""
                {...getFieldProps(`eventScheduleDtos[${index}].eventName`)}
              />
              {fieldTouched?.eventName && err?.eventName && (
                <Error text={err?.eventName} />
              )}
            </div>
            <div className="flex-1">
              <Input
                type="date"
                className=""
                {...getFieldProps(
                  `eventScheduleDtos[${index}].eventTargetDate`
                )}
              />
              {fieldTouched?.eventTargetDate && err?.eventTargetDate && (
                <Error text={err?.eventTargetDate} />
              )}
            </div>
            <div className="flex self-center flex-1 gap-2">
              <ButtonSecondary
                className="text-[11px] leading-[20px] font-medium py-1 bg-slate-201 text-slate-501 h-auto px-2"
                onClick={() => handleAdd(index)}
              >
                Add a Row
              </ButtonSecondary>
              <button
                onClick={() => handleDelete(index)}
                className="text-zinc-300 hover:scale-105"
              >
                <DeleteIcon />
              </button>
            </div>
          </HStack>
        );
      })}
    </VStack>
  );
}

function EventSchedule() {
  const formik = useFormikContext<AddVestingTemplateReq>();
  const { values, getFieldProps } = formik;
  return (
    <VStack aria-label="header" className="gap-y-2">
      <HStack className="gap-x-8">
        <div className="flex-1 ">
          <Label className="text-sm font-normal">Percentage (%)</Label>
        </div>
        <div className="flex-1 ">
          <Label className="text-sm font-normal">Event Name</Label>
        </div>
        <div className="flex-1 ">
          <Label className="text-sm font-normal">Target Date</Label>
        </div>
      </HStack>
      {values?.eventScheduleDtos?.map((_, index) => {
        const eventScheduleDto = values.eventScheduleDtos[index];
        return (
          <HStack className="gap-x-8" key={index}>
            <div className="flex-1">
              <Input
                value={Math.floor(eventScheduleDto.percentage)}
                disabled={true}
              />
            </div>
            <div className="flex-1">
              <Input
                disabled={true}
                {...getFieldProps(`eventScheduleDtos[${index}].eventName`)}
              />
            </div>
            <div className="flex-1">
              <Input
                disabled={true}
                {...getFieldProps(
                  `eventScheduleDtos[${index}].eventTargetDate`
                )}
              />
            </div>
          </HStack>
        );
      })}
    </VStack>
  );
}

function ProjectionChart({
  includeTimeVesting,
  includeEventVesting,
}: {
  includeTimeVesting: boolean;
  includeEventVesting: boolean;
}) {
  const formik = useFormikContext<AddVestingTemplateReq>();
  const { values: template } = formik;
  const timeSchedules = template?.timeScheduleDtos?.map((s) => ({
    ...s,
    percentage: s.percentage / 100 || 0,
  }));
  const eventSchedules = template?.eventScheduleDtos?.map((s) => ({
    ...s,
    percentage: s.percentage / 100 || 0,
  }));
  let schedules = [
    ...(includeTimeVesting ? timeSchedules || [] : []),
    ...(includeEventVesting ? eventSchedules || [] : []),
  ];
  schedules = schedules.map((s, i) => ({ ...s, sequenceNumber: i + 1 }));

  const vestings = generateProjections({
    cliffPeriod: template.cliffPeriod,
    intervalUnit: "Month",
    optionsGranted: 1000,
    schedules,
    vestingInterval: template.vestingInterval || 0,
    vestingPeriod: template.vestingPeriod || 0,
    vestingStartDate: new Date(new Date().getFullYear(), 0, 1),
    vestingTriggerType: template.vestingTriggerType,
    vestingType: template.vestingType,
  });

  const data: ChartData<"bar", number[], unknown> = {
    labels: vestings.map((v) => format(v.date, "MMM yy")),
    datasets: [
      {
        label: "to date",
        data: vestings.map(
          (v) =>
            (v.accumulatedVestingPercentageForGrant - v.vestedPercentage) * 100
        ),
        backgroundColor: "#F8B195",
      },
      {
        label: "on date",
        data: vestings.map((v) => v.vestedPercentage * 100),
        backgroundColor: "#F67280",
        borderRadius: 4,
      },
    ],
  };

  return <BarChart data={data} />;
}
