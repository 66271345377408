import { Role, GrantStates } from "../types/common";

const rolePermissions = {
  ROLE_ADMIN: {
    GRANT: {
      STATE_CHANGE: [
        "CREATED",
        "FOR_APPROVAL",
        "APPROVED",
        "OFFERED",
        "GRANTED",
        "WITHDRAW",
      ],
      EDIT: ["CREATED", "FOR_APPROVAL", "APPROVED", "WITHDRAW", "NOT_APPROVED"],
    },
    VEST: {
      RAISE: true,
      APPROVE: true,
    },
    EXCERCISE: {
      RAISE: true,
      APPROVE: true,
    },
    EVENT: {
      STATE_CHANGE: ["ACTIVE", "FOR_APPROVAL"],
    },
  },
  ROLE_OPTIONS_CREATOR: {
    GRANT: {
      STATE_CHANGE: ["CREATED", "APPROVED"],
      EDIT: ["CREATED", "WITHDRAW", "NOT_APPROVED"],
    },
    VEST: {
      RAISE: true,
      APPROVE: false,
    },
    EXCERCISE: {
      RAISE: false,
      APPROVE: false,
    },
    EVENT: {
      STATE_CHANGE: ["ACTIVE"],
    },
  },
  ROLE_OPTIONS_APPROVER: {
    GRANT: {
      STATE_CHANGE: ["CREATED", "FOR_APPROVAL", "APPROVED"],
      EDIT: ["CREATED", "FOR_APPROVAL", "APPROVED", "WITHDRAW", "NOT_APPROVED"],
    },
    VEST: {
      RAISE: true,
      APPROVE: true,
    },
    EXCERCISE: {
      RAISE: false,
      APPROVE: false,
    },
    EVENT: {
      STATE_CHANGE: ["ACTIVE", "FOR_APPROVAL"],
    },
  },
  ROLE_ESOP_ADMIN: {
    GRANT: {
      STATE_CHANGE: [
        "CREATED",
        "FOR_APPROVAL",
        "APPROVED",
        "OFFERED",
        "GRANTED",
        "WITHDRAW",
      ],
      EDIT: ["CREATED", "FOR_APPROVAL", "APPROVED", "WITHDRAW"],
    },
    VEST: {
      RAISE: true,
      APPROVE: true,
    },
    EXCERCISE: {
      RAISE: true,
      APPROVE: true,
    },
    EVENT: {
      STATE_CHANGE: ["ACTIVE", "FOR_APPROVAL"],
    },
  },
  ROLE_ESOP_VIEWER: {
    GRANT: {
      STATE_CHANGE: [""],
      EDIT: [""],
    },
    VEST: {
      RAISE: false,
      APPROVE: false,
    },
    EXCERCISE: {
      RAISE: false,
      APPROVE: false,
    },
    EVENT: {
      STATE_CHANGE: [""],
    },
  },
};

export const canUserPerformActionOnGrant = (
  userRole: Role,
  actionEvent: "STATE_CHANGE" | "EDIT",
  currentState: GrantStates
) =>
  userRole !== "ADMIN_VIEWER" &&
  userRole !== "ROLE_ESOP_VIEWER" &&
  rolePermissions[userRole].GRANT[actionEvent].includes(currentState);
export const canUserPerformActionOnEvent = (
  userRole: Role,
  actionEvent: "VEST" | "EXCERCISE" | "EVENT_STATE_CHANGE",
  request?: "RAISE" | "APPROVE",
  eventState?: "ACTIVE" | "FOR_APPROVAL"
) => {
  if (userRole === "ADMIN_VIEWER" || userRole === "ROLE_ESOP_VIEWER")
    return false;
  const userPermissions = rolePermissions[userRole];
  if ((actionEvent === "EXCERCISE" || actionEvent === "VEST") && request) {
    return userPermissions[actionEvent][request];
  } else if (actionEvent === "EVENT_STATE_CHANGE" && eventState) {
    return userPermissions.EVENT.STATE_CHANGE.includes(eventState);
  }
  return false;
};
