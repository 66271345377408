const convertToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(
        fileReader?.result?.toString().replace("data:", "").replace(/^.+,/, "")
      );
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });

export default convertToBase64;
