import { Icon } from "@iconify/react";
import { Form, Formik, useFormikContext, useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import { SwitchButton } from "../../components/shared/SwitchButton";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
} from "../../components/utils";
import EsopModellingGrades from "../../constants/EsopModellingGrades";
import {
  HireGrades,
  HiringPlans,
  useEsopModellingStore,
} from "../../store/esopModellingStore";

type HiringPlanProps = {
  onPrimaryAction: (hiringPlan: HiringPlans) => void;
  onClose: () => void;
  mode?: string;
  planningPeriod: number;
  selectedHiringPlan?: HiringPlans;
};

function AddNewPlan({
  mode,
  onClose = () => {},
  onPrimaryAction = () => {},
  planningPeriod,
  selectedHiringPlan,
}: HiringPlanProps) {
  const data = useEsopModellingStore();
  const planningPeriodArr = Array(planningPeriod).fill(0);
  const initialTemplateFields: HiringPlans = {
    description: selectedHiringPlan?.description || "",
    planName: selectedHiringPlan?.planName || "",
    planPeriod: planningPeriod || 0,
    hireGrades: selectedHiringPlan?.hireGrades || [],
  };
  const validationSchema = Yup.object().shape({
    planName: Yup.string().required("Plan Name is required"),
    description: Yup.string().required("Description is required"),
    hireGrades: Yup.array().of(
      Yup.object({
        gradeName: Yup.string().required("Grade is Required"),
        year: Yup.array().of(
          Yup.number().min(0).required("Number of Hires is required")
        ),
      })
    ),
  });
  const handleSubmit = (values: HiringPlans) => {
    if (
      formik.values.hireGrades.length <
      data.selectedCompensationTemplate.salaryPerGrades.length
    ) {
      toast("Please enter hire plan per grade", {
        type: "error",
        autoClose: 2000,
      });
      return;
    }
    onPrimaryAction(values);
  };
  const formik = useFormik({
    initialValues: initialTemplateFields,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  function addNewHireGrade() {
    const newHireGrades = [...formik.values.hireGrades];
    const hireGradeObj: HireGrades = {
      gradeName: "",
      totalEmployeesPerGrade: 0,
      year: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    };
    newHireGrades.splice(formik.values.hireGrades.length, 0, hireGradeObj);
    formik.setFieldValue("hireGrades", newHireGrades);
  }

  function removeHireGrade(index: number) {
    const hireGradesCopy = [...formik.values.hireGrades];
    hireGradesCopy.splice(index, 1);
    formik.setFieldValue("hireGrades", hireGradesCopy);
  }

  return (
    <VStack className="w-[900px] gap-9 p-10">
      <Box className="text-lg font-medium border-b">
        <h6 className="justify-between flex">
          {mode === "Add" ? "Create New Plan" : "Edit Plan"}
          <span onClick={() => onClose()} className="cursor-pointer">
            X
          </span>{" "}
        </h6>
      </Box>
      <HStack className="gap-8 ">
        <div className="flex-1">
          <Label className="text-sm font-normal">Hiring Plan Name</Label>
          <Input
            type="text"
            placeholder="Plan Name"
            {...formik.getFieldProps("planName")}
          />
          {formik.touched.planName && formik.errors.planName && (
            <Error text={formik.errors.planName} />
          )}
        </div>
        <div className="flex-1">
          <Label className="text-sm font-normal">Description</Label>
          <Input
            type="text"
            placeholder="Description"
            {...formik.getFieldProps("description")}
          />
          {formik.touched.description && formik.errors.description && (
            <Error text={formik.errors.description} />
          )}
        </div>
      </HStack>
      {formik.values.hireGrades?.map((salaryPerGrade, index) => {
        const fieldTouched = formik.touched?.hireGrades?.[index];
        const err = formik.errors?.hireGrades?.[index] as any;
        return (
          <HStack
            className="gap-8 "
            key={`${salaryPerGrade.gradeName}-${index}`}
          >
            <div className="flex-1">
              <Label className="text-sm font-normal">Grade</Label>
              <Select
                options={EsopModellingGrades}
                {...formik.getFieldProps(`hireGrades[${index}].gradeName`)}
              />
              {fieldTouched?.gradeName && err?.gradeName && (
                <Error text={err?.gradeName} />
              )}
            </div>
            <VStack>
              <HStack>
                {planningPeriodArr.map((_, yearIndex) => (
                  <div className="flex-1 px-4" key={`year-${yearIndex}`}>
                    <Label className="text-sm font-normal">
                      Year {yearIndex + 1}
                    </Label>
                    <Input
                      type="number"
                      placeholder="Number of Hires"
                      className="w-24"
                      {...formik.getFieldProps(
                        `hireGrades[${index}].year[${yearIndex}]`
                      )}
                    />
                    {fieldTouched?.year && err?.year && (
                      <Error text={err?.year[yearIndex]} />
                    )}
                  </div>
                ))}
              </HStack>
            </VStack>
            <div className="flex-1">
              <button
                type="button"
                onClick={() => {
                  removeHireGrade(index);
                }}
              >
                <Icon icon="typcn:delete" height={24} className="mt-6" />
              </button>
            </div>
          </HStack>
        );
      })}
      {formik.values.hireGrades.length <
        data.selectedCompensationTemplate.salaryPerGrades.length && (
        <ButtonPrimary
          className="min-w-max mx-2 w-1/5"
          onClick={() => {
            addNewHireGrade();
          }}
        >
          ADD
        </ButtonPrimary>
      )}
      <HStack className="justify-between mt-12">
        <ButtonPrimary1
          type="reset"
          className="text-red-500 mr-8"
          onClick={() => {
            onClose();
          }}
        >
          Close
        </ButtonPrimary1>
        <ButtonPrimary
          type="submit"
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Create
        </ButtonPrimary>
      </HStack>
    </VStack>
  );
}

export default AddNewPlan;
