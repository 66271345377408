import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router";
import {
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
} from "../../components/utils";
import {
  useApproveHistoricalRound,
  useGetDiscardHistoricalRound,
  useGetHistoricalRound,
  useGetInstrumentChart,
  useGetRoundChart,
  useGetShareHolderChart,
  useUpdateCaptable,
} from "../../queries/onBoarding";
import BCHeader from "../../shared/BCHeader";
import AlertDialog from "../../components/shared/AlertDialog";
import ShareCapitalDetailOverView from "./ShareCaptialOverView";
import {
  RoundChartCard,
  InstrumentChartCard,
  ShareHolderChartCard,
} from "./HistoricalOverviewCharts";
import { Tab } from "../../types/HistoricOverView";
import OverviewSplitTable from "./OverviewSplitTable";
import OverviewPrimaryTransactionTable from "./OverviewPrimaryTransactionTable";
import OverviewSecondaryTransactionTable from "./OverviewSecondaryTransactionTable";
import HistoricOverviewCapTable from "./HistoricOverviewCaptable";
import OverviewEsopTable from "./OverviewEsopTable";
import { useAuthStore } from "../../store";
import RoundWiseTransactionStepper from "./HistoricTablesComponent";

function HistoricOverView(): React.ReactElement {
  const { mutate: updateCaptable, data: _historicOverViewDetail } =
    useUpdateCaptable();
  const { refetch, data: _historicData } = useGetHistoricalRound();
  const authStoredData = useAuthStore();
  const { mutate: approveHistoricalRound } = useApproveHistoricalRound();
  const _companyId = authStoredData.user?.company?.id.toString() || "";
  const { refetch: getDiscardHistoricRound } = useGetDiscardHistoricalRound(
    _companyId,
    () => handleDiscard()
  );
  const { mutate: getRoundChart } = useGetRoundChart();
  const { mutate: getInstrumentChart } = useGetInstrumentChart();
  const { mutate: getShareHolderChart } = useGetShareHolderChart();
  const navigate = useNavigate();

  const rounds = _historicOverViewDetail?.tabs || [];

  useEffect(() => {
    refetch().then((response) => {
      if (response.status === "success") {
        updateCaptable(response.data);
        getRoundChart(response.data);
        getInstrumentChart(response.data);
        getShareHolderChart(response.data);
      }
    });
  }, []);

  const [activeRound, setActiveRound] = useState<{
    index: number;
    data?: Tab;
    mode?: string;
    name?: string;
  }>({
    index: 0,
  });
  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    mode?: "delete" | "approve";
  }>({
    open: false,
  });

  const discardCompany = () => {
    setDialog({
      mode: "delete",
      open: true,
      message: "Do you want to discard the Company?",
    });
  };
  const approveCompany = () => {
    setDialog({
      open: true,
      mode: "approve",
      message: "Do you want to approve the Company?",
    });
  };

  const handleDiscard = () => {
    getDiscardHistoricRound();
    setDialog({ open: false });
    navigate("/onboarding");
  };

  const handleApprove = () => {
    if (_historicData) {
      approveHistoricalRound(_historicData, {
        onSuccess: () => {
          toast("Historic Details Saved Successfully!", {
            type: "success",
            autoClose: 2000,
          });
        },
        onError: (error: { response: { data: { reason: string } } }) => {
          toast(`${error.response.data.reason}`, {
            type: "error",
            autoClose: 2000,
          });
        },
      });
      setDialog({ open: false });
    } else {
      toast(`please refresh the page and try again!`, {
        type: "error",
        autoClose: 2000,
      });
    }
  };

  return (
    <>
      {_historicOverViewDetail ? (
        <div className="w-11/12 m-auto min-h-screen ">
          <div className="px-0 text-lg font-medium y-7">
            <h1>Onboarding</h1>
          </div>
          <BCHeader
            className="items-baseline mb-4"
            bcTitle="OnBoarding"
            bcSubTitle="Historical Round Summary"
          />

          <Dialog open={dialog.open} maxWidth="md">
            {dialog.mode === "delete" ? (
              <AlertDialog
                onClose={() => setDialog({ open: false })}
                open={dialog.open}
                message={dialog.message}
                onPrimaryAction={handleDiscard}
                onSecondaryAction={() => setDialog({ open: false })}
              />
            ) : (
              <AlertDialog
                onClose={() => setDialog({ open: false })}
                open={dialog.open}
                message={dialog.message}
                onPrimaryAction={handleApprove}
                onSecondaryAction={() => setDialog({ open: false })}
              />
            )}
          </Dialog>
          <VStack>
            <ShareCapitalDetailOverView
              companyCapitalDetails={
                _historicOverViewDetail?.companyCapitalDetails
              }
            />
            <div className="w-full pt-5 bg-white rounded-md pb-5 mt-10  px-4">
              <HStack aria-label="toolbar" className="justify-between mb-8">
                <VStack className="min-w-max">
                  <p className="text-lg font-medium text-gray-dark">
                    Historical Round
                  </p>
                </VStack>
              </HStack>

              {rounds && (
                <VStack>
                  <RoundWiseTransactionStepper
                    activeRound={activeRound}
                    onActiveRoundStepChange={(index, data, mode, name) => {
                      setActiveRound({
                        index,
                        data,
                        mode,
                        name,
                      });
                    }}
                    rounds={rounds}
                  ></RoundWiseTransactionStepper>
                  {activeRound.data && (
                    <div
                      className={`flex flex-col bg-slate-50 rounded-xl mt-4 p-4`}
                    >
                      {activeRound.mode === "ESOP" && (
                        <OverviewEsopTable
                          tabTransactionDetail={activeRound.data}
                        />
                      )}
                      {activeRound.mode === "SPLIT" && (
                        <OverviewSplitTable
                          tabTransactionDetail={activeRound.data}
                        />
                      )}
                      {activeRound.mode === "Round" && (
                        <OverviewPrimaryTransactionTable
                          tabTransactionDetail={activeRound.data}
                        />
                      )}
                      {activeRound.mode === "Secondaries" && (
                        <OverviewSecondaryTransactionTable
                          tabTransactionDetail={activeRound.data}
                        />
                      )}
                    </div>
                  )}
                </VStack>
              )}
            </div>
            <HStack className="grid gap-6 mt-6 overflow-auto rounded-lg lg:grid-cols-3 md:grid-cols-2">
              <ShareHolderChartCard />
              <InstrumentChartCard />
              <RoundChartCard />
            </HStack>
            <HistoricOverviewCapTable
              capTableTransaction={_historicOverViewDetail.capTable}
            />

            <HStack className="justify-end m-8">
              <ButtonPrimary1
                type="reset"
                className="text-red-500 mr-8"
                onClick={discardCompany}
              >
                Discard
              </ButtonPrimary1>
              <ButtonPrimary1
                className="text-red-500 mr-8"
                onClick={() => {
                  navigate("/onboarding/historic");
                }}
              >
                Back
              </ButtonPrimary1>
              <ButtonPrimary onClick={approveCompany}>Approve</ButtonPrimary>
            </HStack>
          </VStack>
        </div>
      ) : (
        <div className="w-11/12 m-auto  min-h-screen ">
          <Icon
            className="m-auto  animate-spin"
            icon="lucide:loader-2"
            width={36}
          />
        </div>
      )}
    </>
  );
}
export default HistoricOverView;
