import { Icon } from "@iconify/react";
import Dialog from "@mui/material/Dialog";
import React, { useState } from "react";
import Tooltip from "../../components/shared/Tooltip";
import { Box, HStack, VStack } from "../../components/utils";
import { useValuationDetails } from "../../queries/Valuation";
import { Valuation } from "../../types/ValuationType";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { formatDisplayDate } from "../../utils/date";
import { limitString } from "../../utils/string";
import AddOrEditValuation from "./AddOrEditValuation";

function ValuationTable() {
  const currency = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const { data: _valuationData } = useValuationDetails();
  const [dialog, setDialog] = useState<{
    open: boolean;
    data?: Valuation;
  }>({
    open: false,
    data: undefined,
  });
  const valuationData =
    _valuationData?.sort(
      (data1, data2) =>
        Number(new Date(data1.dateOfValuation)) -
        Number(new Date(data2.dateOfValuation))
    ) || [];

  function editRow(val: Valuation) {
    setDialog({ open: true, data: val });
  }
  return (
    <Box className="w-full p-4 bg-white rounded-lg ">
      <Dialog open={dialog.open} maxWidth="md">
        <AddOrEditValuation
          onClose={() => setDialog({ open: false })}
          data={dialog.data}
          mode="Edit"
        />
      </Dialog>
      <HStack aria-label="toolbar" className="justify-between mb-8">
        <VStack className="min-w-max">
          <p className="text-lg font-medium text-gray-dark">
            Valuation Details
          </p>
          <p className="text-xs font-medium text-gray-light">
            {valuationData.length} valuation reports
          </p>
        </VStack>
      </HStack>
      <table className="w-full table-space ">
        <thead className="text-xs font-medium text-gray-light">
          <tr className="">
            <td className="py-3">NAME</td>
            <td className="py-3">DATE</td>
            <td className="py-3">TYPE</td>
            <td className="py-3">VALUATION(PPS)</td>
            <td className="py-3">METHODOLOGY</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {valuationData &&
            valuationData?.map((val) => (
              <tr key={val.id}>
                <td className="py-4 align-top ">
                  <HStack className="">
                    <Box className="">
                      <Tooltip text={val.name}>
                        <p className="text-xs font-medium text-gray-dark">
                          {limitString(val.name, 40)}
                        </p>
                      </Tooltip>
                    </Box>
                  </HStack>
                </td>
                <td className="py-4 align-top ">
                  <HStack className="">
                    <Box className="">
                      <p className="text-xs font-medium text-gray-dark">
                        {formatDisplayDate(val.dateOfValuation)}
                      </p>
                    </Box>
                  </HStack>
                </td>
                <td className="py-4 align-top ">
                  <HStack className="">
                    <Box className="">
                      <p className="text-xs font-medium text-gray-dark">
                        {val.valuationType}
                      </p>
                    </Box>
                  </HStack>
                </td>
                <td className="py-4 align-top ">
                  <HStack className="">
                    <Box className="">
                      <p className="text-xs font-medium text-gray-dark">
                        {val.valuation?.toLocaleString(currency)}
                      </p>
                    </Box>
                  </HStack>
                </td>
                <td className="py-4 align-top ">
                  <HStack className="">
                    <Box className="">
                      <p className="text-xs font-medium text-gray-dark">
                        {val.valuationMethodology}
                      </p>
                    </Box>
                  </HStack>
                </td>
                <td className="py-4 align-top ">
                  <button>
                    <Icon
                      icon="akar-icons:pencil"
                      width="27"
                      onClick={() => editRow(val)}
                      className="h-4 transition duration-100 ease-in-out border-white border-solid rounded-full text-orange-501 ring-2 ring-white hover:scale-100 hover:ring-blue-800"
                    />
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </Box>
  );
}

export default ValuationTable;
