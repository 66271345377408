import { useNavigate, useParams } from "react-router";
import { SwitchButton } from "../../components/shared/SwitchButton";
import {
  Box,
  HStack,
  VStack,
  ButtonPrimary,
  isEsopViewer,
} from "../../components/utils";
import {
  useBuybackState,
  useGetOptionBuyback,
} from "../../queries/optionBuyback";
import { useBuybackStore } from "../../store/optionBuyback";
import { optionBuybackDetail } from "../../types/OptionBuyback";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { formatDisplayDate } from "../../utils/date";
import StatusLabel from "../esopOverview/StatusLabel";
import useIsMobile from "../../utils/detectDevice";
import { useAdvanceFilterDialog } from "../../store/useDialogStore";

function ExerciseDetails() {
  const currencySymbol = getCurrencySymbol();
  const isUserEsopViewer = isEsopViewer();
  const currency = getCurrencyType();
  const navigate = useNavigate();

  const { id } = useParams();
  const _id = id || "";
  const { state: showAdvanceFilterDialog, setState: setShowAdvanceFilters } =
    useAdvanceFilterDialog();
  const { data: optionBuybackOverview, isFetching } = useGetOptionBuyback();
  let buybackDetail: optionBuybackDetail[] = [];
  if (_id !== " ") {
    buybackDetail = (optionBuybackOverview || [])?.filter(
      (item) => parseInt((item?.id || 0).toString(), 10) === parseInt(_id, 10)
    );
  }

  function editDetails() {
    navigate(`/options/edit-exercise-flow/${_id}?mode=edit`);
  }
  const { data: _buybackStateData, isPlaceholderData } = useBuybackState(
    parseInt(_id, 10)
  );
  const buybackStateData = _buybackStateData || [];
  function getTotalEligibility() {
    let totalEligibility = 0;
    buybackStateData.forEach((item) => {
      totalEligibility += item.eligibility;
    });
    return totalEligibility;
  }
  function getTotalVestedShare() {
    let totalVestedShare = 0;
    buybackStateData.forEach((item) => {
      totalVestedShare += item.vested;
    });
    return totalVestedShare;
  }
  const { isMobile } = useIsMobile();
  return (
    <VStack
      className={`${isPlaceholderData && isFetching ? "loading" : ""} "gap-4 "`}
    >
      <Box
        className={`flex ${
          isMobile
            ? "flex-col"
            : "flex-row justify-between gap-12  items-center "
        }  p-4 bg-white border-b border-solid rounded`}
      >
        <Box className="flex items-center gap-12">
          <p
            className={`${
              isMobile ? "text-sm" : "text-lg"
            }  font-medium text-stone-700"`}
          >
            {buybackDetail[0]?.name}
          </p>
          <StatusLabel state={buybackDetail[0]?.state}></StatusLabel>
        </Box>
      </Box>
      <VStack
        className={`${
          isMobile ? "p-2" : "p-6"
        } gap-4 bg-[#fefefe] rounded m drop-shadow-md`}
      >
        <VStack className="gap-8">
          <Box
            className={` flex ${
              isMobile ? "flex-col gap-8" : "flex-row  justify-between gap-12"
            } `}
          >
            <div
              className={` flex flex-row items-center flex-1 gap-4 ${
                isMobile ? "justify-between " : " justify-start "
              } `}
            >
              <div
                className={`${
                  isMobile ? "whitespace-nowrap" : ""
                } w-full text-sm text-gray-600 flex-nowrap whitespace-nowrap flex-1`}
              >
                <p>Liquidity Program Name</p>
              </div>
              <div
                className={`${
                  isMobile ? "text-end" : " "
                } text-black bg-white flex-1 `}
              >
                <p>{buybackDetail[0]?.name}</p>
              </div>
            </div>
            <div className="flex flex-row items-center justify-between flex-1 gap-4">
              <div className="flex-1 w-full text-sm text-gray-600 flex-nowrap">
                <p>Description</p>
              </div>
              <div
                className={`${
                  isMobile ? "text-end" : " "
                } text-black bg-white flex-1 `}
              >
                <p>{buybackDetail[0]?.description}</p>
              </div>
            </div>
          </Box>
          <Box
            className={` flex ${
              isMobile ? " flex-col gap-8" : "flex-row  justify-start gap-12"
            } `}
          >
            <div className="flex flex-row items-center justify-between flex-1 gap-4">
              <div
                className={`${
                  isMobile ? "whitespace-nowrap" : ""
                } w-full flex-1 text-sm text-gray-600 flex-nowrap whitespace-nowrap`}
              >
                {" "}
                <p>Sale Price ({currencySymbol})</p>
              </div>
              <div
                className={`${
                  isMobile ? "text-end" : " "
                } text-black bg-white flex-1 `}
              >
                <p>
                  {buybackDetail[0]?.sellingPrice?.toLocaleString(currency)}
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center justify-between flex-1 gap-4">
              <div
                className={`${
                  isMobile ? "whitespace-nowrap" : ""
                } w-full flex-1 text-sm text-gray-600 flex-nowrap whitespace-nowrap`}
              >
                {" "}
                <p>Program start date</p>
              </div>
              <div
                className={`${
                  isMobile ? "text-end" : " "
                } text-black bg-white flex-1 `}
              >
                <p>
                  {formatDisplayDate(
                    buybackDetail[0]?.startDate || "1979-01-01"
                  )}
                </p>
              </div>
            </div>
          </Box>
          <Box
            className={` flex ${
              isMobile ? " flex-col gap-8" : "flex-row  justify-start gap-12"
            } `}
          >
            <div className="flex flex-row items-center justify-between flex-1 gap-4">
              <div
                className={`${
                  isMobile ? "whitespace-nowrap" : ""
                } w-full flex-1 text-sm text-gray-600 flex-nowrap whitespace-nowrap`}
              >
                {" "}
                <p>Fair Market Value(FMV) Price ({currencySymbol})</p>
              </div>
              <div
                className={`${
                  isMobile ? "text-end" : " "
                } text-black bg-white flex-1 `}
              >
                <p>{buybackDetail[0]?.fmvPrice}</p>
              </div>
            </div>
            <div className="flex flex-row items-center justify-between flex-1 gap-4">
              <div
                className={`${
                  isMobile ? "whitespace-nowrap" : ""
                } w-full flex-1 text-sm text-gray-600 flex-nowrap whitespace-nowrap`}
              >
                {" "}
                <p>Effective Program date</p>
              </div>
              <div
                className={`${
                  isMobile ? "text-end" : " "
                } text-black bg-white flex-1 `}
              >
                <p>
                  {formatDisplayDate(
                    buybackDetail[0]?.vestingCutOffDate || "1979-01-01"
                  )}
                </p>
              </div>
            </div>
          </Box>
          <Box
            className={` flex ${
              isMobile ? " flex-col gap-8" : "flex-row  justify-start gap-12"
            } `}
          >
            <div className="flex flex-row items-center justify-between flex-1 gap-4">
              <div className="flex-1 w-full text-sm text-gray-600 flex-nowrap">
                <p>Vested options</p>
              </div>
              <div
                className={`${
                  isMobile ? "text-end" : " "
                } text-black bg-white flex-1 `}
              >
                <p>{getTotalVestedShare().toLocaleString(currency)}</p>
              </div>
            </div>
            <div className="flex flex-row items-center justify-start flex-1 gap-4">
              <div className="flex-1 w-full text-sm text-gray-600 flex-nowrap">
                <p>Program end date</p>
              </div>
              <div
                className={`${
                  isMobile ? "text-end" : " "
                } text-black bg-white flex-1 `}
              >
                <p>
                  {formatDisplayDate(buybackDetail[0]?.endDate || "1979-01-01")}
                </p>
              </div>
            </div>
          </Box>
          <Box
            className={` flex ${
              isMobile ? " flex-col gap-8" : "flex-row  justify-start gap-12"
            } `}
          >
            <div className="flex flex-row items-center justify-between flex-1 gap-4">
              <div className="flex-1 w-full text-sm text-gray-600 flex-nowrap">
                {" "}
                <p> Eligible options</p>
              </div>
              <div
                className={`${
                  isMobile ? "text-end" : " "
                } text-black bg-white flex-1 `}
              >
                <p>{getTotalEligibility()}</p>
              </div>
            </div>
            <div className="flex flex-row items-center justify-start flex-1 gap-4">
              {buybackDetail[0]?.percentage > 0 ? (
                <>
                  <div
                    className={`${
                      isMobile ? "whitespace-nowrap" : ""
                    } w-full flex-1 text-sm text-gray-600 flex-nowrap whitespace-nowrap`}
                  >
                    <p>Percentage (%)</p>
                  </div>
                  <div
                    className={`${
                      isMobile ? "text-end" : " "
                    } text-black bg-white flex-1 `}
                  >
                    <p>{(buybackDetail[0]?.percentage || 0) * 100}</p>
                  </div>
                </>
              ) : (
                <p>Offer list uploaded successfully</p>
              )}
            </div>
          </Box>
          <Box
            className={` flex ${
              isMobile ? " flex-col gap-8" : "flex-row  justify-start gap-12"
            } `}
          >
            <div className="flex flex-row items-center justify-between flex-1 gap-4">
              <div
                className={`${
                  isMobile ? "whitespace-nowrap" : ""
                } w-full flex-1 text-sm text-gray-600 flex-nowrap whitespace-nowrap`}
              >
                {" "}
                <p>Estimated value ({currencySymbol})</p>
              </div>
              <div
                className={`${
                  isMobile ? "text-end" : " "
                } text-black bg-white flex-1 `}
              >
                <p>
                  {(
                    getTotalEligibility() *
                    (buybackDetail[0]?.sellingPrice || 0)
                  ).toLocaleString(currency)}
                </p>
              </div>
            </div>
            <div
              className={`flex ${
                isMobile ? "whitespace-nowrap" : ""
              } w-full flex-1 text-sm flex-row items-center gap-4 text-gray-600 flex-nowrap whitespace-nowrap`}
            >
              <SwitchButton
                value={buybackDetail[0]?.isWillingNessGiven}
                label={"Ask Willingness"}
                className="text-sm text-gray-600 cursor-default"
              />
            </div>
          </Box>
          <Box
            className={` flex ${
              isMobile ? " flex-col gap-8" : "flex-row  justify-start gap-12"
            } `}
          >
            <div className="flex flex-row items-center justify-between flex-1 gap-4">
              <div
                className={`${
                  isMobile ? "whitespace-nowrap" : ""
                } w-full flex-1 text-sm text-gray-600 flex-nowrap whitespace-nowrap`}
              >
                {" "}
                <p>Miscellaneous Charges ({currencySymbol})</p>
              </div>
              <div
                className={`${
                  isMobile ? "text-end" : " "
                } text-black bg-white flex-1 `}
              >
                <p>{buybackDetail[0]?.miscCharges}</p>
              </div>
            </div>
            <div className="flex flex-row items-center justify-between flex-1 gap-4"></div>
          </Box>
          <Box
            onClick={() => setShowAdvanceFilters({ open: true, id: _id })}
            className="p-2 px-5 py-2 font-normal border border-dashed rounded cursor-pointer max-w-min whitespace-nowrap h-11 text-sm1 focus:opacity-90 hover:bg-slate-100 border-orange-501 "
          >
            Show Advance Filter
          </Box>
        </VStack>
        <HStack className="flex-row-reverse self-end">
          {buybackDetail[0]?.state === "DRAFT" && !isUserEsopViewer ? (
            <ButtonPrimary
              className="flex items-center self-end justify-center px-8 flex-nowrap "
              onClick={editDetails}
            >
              Edit Details
            </ButtonPrimary>
          ) : (
            ""
          )}
        </HStack>
      </VStack>
    </VStack>
  );
}

export default ExerciseDetails;
