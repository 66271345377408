import { Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { Input, Label } from "../../components/shared/InputField";
import { SwitchButton } from "../../components/shared/SwitchButton";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
  isEsopViewer,
} from "../../components/utils";
import { useBuybackStore } from "../../store/optionBuyback";
import { optionBuybackDetail } from "../../types/OptionBuyback";
import { formatDate, formatWithTimeZone } from "../../utils/date";
import { useQuery } from "../../utils/useQuery";
import {
  useCreateOptionBuyback,
  useEditLiquidityEvent,
  useExercisableDetails,
  useGetOptionBuyback,
  useGetUploadedBuybackOffers,
  useUploadEmployeeOffers,
} from "../../queries/optionBuyback";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import convertToBase64 from "../../utils/convertToBase64";
import { MultiSelect, OptionTypes } from "../../components/shared/MultiSelect";
import useIsMobile from "../../utils/detectDevice";
import { useEmployeeDepartment } from "../../queries";
import StatusLabel from "../esopOverview/StatusLabel";
import { EventParticipationConstraints } from "./CreateBuybackDetails";
import { Select } from "../../components/shared/Select";

function CreateEmployeesList() {
  const buybackStore = useBuybackStore();
  const isUserEsopViewer = isEsopViewer();
  const currencySymbol = getCurrencySymbol();
  const { pathname } = useLocation();
  const { id } = useParams();
  const _id = id || "";
  const query = useQuery();
  const searchParam = query.get("mode");
  const navigate = useNavigate();
  const { data: departments } = useEmployeeDepartment();
  const deprt: OptionTypes[] = [];
  (departments || []).forEach((department) => {
    deprt.push({ value: department });
  });

  const { data: optionBuybackOverview } = useGetOptionBuyback();
  let optionBuybackList: optionBuybackDetail[] = [];
  const optionBuyback = optionBuybackOverview?.filter(
    (list) => parseInt((list?.id || 0).toString(), 10) === parseInt(_id, 10)
  );
  optionBuybackList = optionBuyback || [];
  const [bcTitle, setBCTitle] = useState<string>();
  useEffect(() => {
    if (pathname.includes("create-exercise-liquidation-flow")) {
      if (searchParam === "create") {
        setBCTitle("Create Liquidity Program");
      } else {
        setBCTitle("Edit Liquidity Program");
      }
    } else if (pathname.includes("create-exercise-flow")) {
      if (searchParam === "create") {
        setBCTitle("Create Exercise Program");
      } else {
        setBCTitle("Edit Exercise Program");
      }
    }
  }, [pathname]);

  function getInitialValues() {
    if (searchParam === "edit") {
      return getEditInitialValues();
    } else {
      return getCreateInitialValues();
    }
  }
  function getCreateInitialValues(): optionBuybackDetail {
    return {
      id: 0,
      name: "",
      description: "",
      percentage: 0,
      noOfOptions: 0,
      price: 0,
      offers: [],
      cumuOffers: [],
      isWillingNessGiven: false,
      vestingCutOffDate: "",
      startDate: formatDate(new Date().toString()),
      endDate: "",
      state: "",
      type: "",
      recurring: false,
      autoPilot: false,
      recurringInterval: 0,
      recurringIntervalUnit: "",
      isSuccessorGenerated: false,
      creationType: buybackStore.exerciseEmployeeList,
      transactionType: "exercise and liquidate",
      liquidationEndDate: "",
      sellingPrice: 0,
      isBankDetailAsked: false,
      miscCharges: 0,
      buyerName: "",
      maxFrequency: 0,
      department: [],
      minimumTenure: 0,
      minimumVestedOptions: 0,
      minimumWillingness: 0,
      fmvPrice: 0,
      participationConstraints: [
        { interval: 0, intervalUnit: "", maxFrequency: 0 },
      ],
    };
  }
  function getEditInitialValues(): optionBuybackDetail {
    return {
      id: optionBuybackList[0]?.id,
      companyId: optionBuybackList[0]?.companyId,
      name: optionBuybackList[0]?.name,
      description: optionBuybackList[0]?.description,
      percentage: (optionBuybackList[0]?.percentage || 0) * 100,
      noOfOptions: optionBuybackList[0]?.noOfOptions,
      price: optionBuybackList[0]?.price,
      offers: optionBuybackList[0]?.offers,
      cumuOffers: optionBuybackList[0]?.offers,
      vestingCutOffDate: formatDate(
        new Date(
          optionBuybackList[0]?.vestingCutOffDate || "1970-01-01"
        ).toString()
      ),
      startDate: formatDate(new Date().toString()),
      endDate: formatDate(
        new Date(optionBuybackList[0]?.endDate || "1970-01-01").toString()
      ),
      state: optionBuybackList[0]?.state,
      type: optionBuybackList[0]?.type,
      isWillingNessGiven:
        optionBuybackList && optionBuybackList[0]?.isWillingNessGiven,
      creationType: buybackStore.exerciseEmployeeList,
      transactionType: optionBuybackList[0]?.transactionType,
      liquidationEndDate: "",
      sellingPrice: optionBuybackList[0]?.sellingPrice,
      miscCharges: optionBuybackList[0]?.miscCharges || 0,
      buyerName: "",
      isBankDetailAsked: optionBuybackList[0]?.isBankDetailAsked,
      fmvPrice: optionBuybackList[0]?.fmvPrice,
      recurring: optionBuybackList[0]?.recurring,
      autoPilot: optionBuybackList[0]?.autoPilot,
      recurringInterval: optionBuybackList[0]?.recurringInterval,
      recurringIntervalUnit: optionBuybackList[0]?.recurringIntervalUnit,
      isSuccessorGenerated: optionBuybackList[0]?.isSuccessorGenerated,
      maxFrequency: optionBuybackList[0]?.maxFrequency,
      department: optionBuybackList[0]?.offerFilter?.department || [],
      minimumTenure: optionBuybackList[0]?.offerFilter?.minimumTenure || 0,
      minimumVestedOptions:
        optionBuybackList[0]?.offerFilter?.minimumVestedOptions || 0,
      minimumWillingness:
        optionBuybackList[0]?.offerFilter?.minimumWillingness || 0,
      participationConstraints:
        optionBuybackList[0]?.offerFilter?.participationConstraints || [],
    };
  }
  const validationSchema = Yup.object().shape({
    transactionType: Yup.string(),
    recurring: Yup.boolean(),
    name: Yup.string()
      .required("Name required")
      .min(3, "Name should be atleast 3 character long"),
    endDate: Yup.string().required("End date required"),
    percentage: Yup.number()
      .when(["showUpload"], {
        is: false,
        then: Yup.number()
          .required("Percentage required")
          .max(100, "Percentage should be less than 100")
          .positive(),
      })
      .required("Percentage required")
      .max(100, "Percentage should be less than 100"),
    description: Yup.string()
      .required("Description required")
      .min(10, "minimum 10 charcters required"),

    sellingPrice: Yup.number().when("transactionType", {
      is: "exercise and liquidate",
      then: Yup.number().required("sale price required").positive(),
    }),
    fmvPrice: Yup.number().when("transactionType", {
      is: "exercise and liquidate",
      then: Yup.number().required("fmv price required").positive(),
    }),
    minimumTenure: Yup.number().integer("Only accept integer value"),
    minimumVestedOptions: Yup.number().integer("Only accept integer value"),
    minimumWillingness: Yup.number().integer("Only accepts integer value"),
  });
  const { mutate: uploadOffers } = useUploadEmployeeOffers();
  const [uploadMessage, setUploadMessage] = useState<string>();
  async function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    const excelFile = { file: base64 };
    if (
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      uploadOffers(excelFile, {
        onSuccess: () => {
          setUploadMessage("Offer List uploaded successfully");
        },
        onError: () => {},
      });
      // eslint-disable-next-line no-useless-return
      return;
    }
  }
  const { mutate: editLiquidityEvent } = useEditLiquidityEvent();
  const { data: _offers } = useGetUploadedBuybackOffers();
  const offers = _offers as any;
  const { mutate: createOptionBuyback, isLoading } = useCreateOptionBuyback();
  function handleSubmit(values: optionBuybackDetail) {
    let message = "Liquidity program successfully edited";
    const exerciseFlowDto = { ...values };
    exerciseFlowDto.startDate = formatWithTimeZone(exerciseFlowDto.startDate);
    exerciseFlowDto.endDate = formatWithTimeZone(exerciseFlowDto.endDate);
    exerciseFlowDto.liquidationEndDate = formatWithTimeZone(
      exerciseFlowDto.liquidationEndDate || "9999-12-01"
    );
    exerciseFlowDto.vestingCutOffDate = formatWithTimeZone(
      exerciseFlowDto.vestingCutOffDate
    );
    exerciseFlowDto.percentage /= 100;
    exerciseFlowDto.state = "DRAFT";
    exerciseFlowDto.type = "Liquidity";
    exerciseFlowDto.elState = "DRAFT";
    if (!exerciseFlowDto.miscCharges) {
      exerciseFlowDto.miscCharges = 0;
    }
    exerciseFlowDto.transactionType =
      pathname.split("/")[2] === "create-exercise-flow"
        ? "exercise"
        : "exercise and liquidate";
    if (searchParam === "create") {
      message = "Liquidity Program successfully created";
      delete exerciseFlowDto.id;
    }
    if (
      exerciseFlowDto.percentage === 0 &&
      (searchParam === "create" || searchParam === "edit")
    ) {
      exerciseFlowDto.offers = offers || [];
    }
    if (searchParam === "edit") {
      editLiquidityEvent(exerciseFlowDto, {
        onSuccess: (resData) => {
          toast(message, {
            type: "success",
            autoClose: 2000,
          });
          navigate(`/options/view-option-buy-back/${resData.id}`);
        },
        onError: () => {
          toast("Something went wrong", { type: "error", autoClose: 5000 });
        },
      });
      return;
    }
    createOptionBuyback(exerciseFlowDto, {
      onSuccess: (data) => {
        toast("Option exercise successfully cretaed", {
          type: "success",
          autoClose: 2000,
        });
        navigate(`/options/view-option-buy-back/${data.id}`);
      },
      onError: (err) => {},
    });
  }

  const [showUpload, setShowUpload] = useState(false);
  useEffect(() => {
    if (optionBuybackList[0]?.percentage <= 0 && searchParam === "edit") {
      setShowUpload(true);
    }
  }, [optionBuybackList]);
  const { isMobile } = useIsMobile();
  const [showAdvanceFilters, setShowAdvanceFilters] = useState(false);

  return (
    <>
      {" "}
      <Formik
        initialValues={getInitialValues()}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {(formik) => (
          <Form>
            <Box
              className={`flex ${
                isMobile
                  ? "flex-col"
                  : "flex-row justify-between gap-12  items-center "
              }  p-4 bg-white border-b border-solid rounded`}
            >
              <Box className="flex flex-row items-center">
                <h6
                  className={`${
                    isMobile ? "text-xs whitespace-nowrap" : "text-lg"
                  }  font-semibold text-gray-600`}
                >
                  {bcTitle}
                </h6>
                <div className="items-center px-6 py-1 rounded">
                  <StatusLabel state={optionBuybackList[0]?.state || "Draft"} />
                </div>
              </Box>
            </Box>
            <VStack className="gap-4 rounded">
              <VStack className="gap-8 p-4 bg-white rounded-md">
                <Box
                  className={` flex ${
                    isMobile
                      ? " flex-col gap-4"
                      : "flex-row  justify-between gap-12"
                  } items-center`}
                >
                  <VStack className="w-full">
                    <Label>Liquidity Program Name</Label>
                    <Input type="text" {...formik.getFieldProps("name")} />
                    {formik.touched.name && formik.errors.name && (
                      <Error text={formik.errors.name} />
                    )}
                  </VStack>
                  <VStack className="w-full">
                    <Label>Description</Label>
                    <Input
                      type="text"
                      {...formik.getFieldProps("description")}
                    />
                    {formik.touched.description &&
                      formik.errors.description && (
                        <Error text={formik.errors.description} />
                      )}
                  </VStack>
                </Box>
                <Box
                  className={` flex ${
                    isMobile
                      ? " flex-col gap-4"
                      : "flex-row  justify-between gap-12"
                  } `}
                >
                  <VStack className="w-full">
                    <Label>Start Date</Label>
                    <Input
                      type="date"
                      {...formik.getFieldProps("startDate")}
                      min={format(new Date(), "yyyy-MM-dd")}
                    />
                    {formik.touched.startDate && formik.errors.startDate && (
                      <Error text={formik.errors.startDate} />
                    )}
                  </VStack>
                  <VStack className="w-full">
                    <Label>End Date</Label>
                    <Input
                      type="date"
                      {...formik.getFieldProps("endDate")}
                      min={formik.values.startDate}
                    />
                    {formik.touched.endDate && formik.errors.endDate && (
                      <Error text={formik.errors.endDate} />
                    )}
                  </VStack>
                </Box>
                <Box
                  className={` flex ${
                    isMobile
                      ? " flex-col gap-4"
                      : "flex-row  justify-between items-center gap-12"
                  } `}
                >
                  <VStack className="w-full">
                    <Label>Sale Price ({currencySymbol})</Label>
                    <Input
                      type="number"
                      placeholder="Enter sale price"
                      {...formik.getFieldProps("sellingPrice")}
                    />
                    {formik.touched.sellingPrice &&
                      formik.errors.sellingPrice && (
                        <Error text={formik.errors.sellingPrice} />
                      )}
                  </VStack>
                  <VStack className="w-full">
                    <Label>
                      Fair Market Value(FMV) Price ({currencySymbol})
                    </Label>
                    <Input
                      type="number"
                      placeholder="Enter FMV price"
                      {...formik.getFieldProps("fmvPrice")}
                    />
                    {formik.touched.fmvPrice && formik.errors.fmvPrice && (
                      <Error text={formik.errors.fmvPrice} />
                    )}
                  </VStack>
                </Box>

                <Box
                  className={` flex ${
                    isMobile
                      ? " flex-col gap-4"
                      : "flex-row  justify-between items-center gap-12"
                  } `}
                >
                  <VStack className="w-full">
                    <Label>
                      Miscellaneous Charges({currencySymbol}) (Optional)
                    </Label>
                    <Input
                      type="number"
                      placeholder="Enter additional charges"
                      {...formik.getFieldProps("miscCharges")}
                    />
                    {formik.touched.miscCharges &&
                      formik.errors.miscCharges && (
                        <Error text={formik.errors.miscCharges} />
                      )}
                  </VStack>
                </Box>
              </VStack>
              <VStack className="bg-white rounded-md ">
                <Box
                  className={` flex ${
                    isMobile
                      ? " flex-col gap-4"
                      : "flex-row  justify-between gap-12"
                  } border-b border-solid p-4 font-semibold text-xs text-gray-600`}
                >
                  <h6
                    className={`${
                      isMobile ? "text-xs whitespace-nowrap" : "text-lg"
                    }  font-semibold text-gray-600"`}
                  >
                    Employee-Related filters{" "}
                  </h6>
                </Box>
                <VStack className="gap-8 p-4 ">
                  <Box
                    className={` flex ${
                      isMobile
                        ? " flex-col gap-4"
                        : "flex-row  justify-between gap-12"
                    } `}
                  >
                    <VStack className="w-full">
                      <Label>Percentage of vested options</Label>
                      <Input
                        type="number"
                        {...formik.getFieldProps("percentage")}
                      />
                      {formik.touched.percentage &&
                        formik.errors.percentage && (
                          <Error text={formik.errors.percentage} />
                        )}
                    </VStack>
                    <VStack className="w-full">
                      <Label>Vesting Cut Off Date </Label>
                      <Input
                        type="date"
                        max={formik.values.startDate}
                        {...formik.getFieldProps("vestingCutOffDate")}
                      />
                      {formik.touched.vestingCutOffDate &&
                        formik.errors.vestingCutOffDate && (
                          <Error text={formik.errors.vestingCutOffDate} />
                        )}
                    </VStack>
                  </Box>
                  <HStack className="justify-start w-1/2 ">
                    <HStack className="flex-row-reverse items-center justify-end w-1/2 gap-4">
                      <SwitchButton
                        value={formik.values.isWillingNessGiven}
                        className="font-medium text-gray-dark"
                        label={"Ask Willingness"}
                        onClick={() => {
                          formik.setFieldValue(
                            "isWillingNessGiven",
                            !formik.values.isWillingNessGiven
                          );
                        }}
                      />
                    </HStack>
                  </HStack>
                  <HStack className="items-center gap-2 before:border-b-2 before:border-dashed before:m-auto after:flex-1 after:border-dashed after:content-[''] after:border-b-2">
                    <ButtonPrimary1
                      onClick={() => {
                        formik.setFieldValue(
                          "isAdvanceFilter",
                          !showAdvanceFilters
                        );
                        setShowAdvanceFilters(!showAdvanceFilters);
                      }}
                      className="bg-transparent border border-solid cursor-pointer border-orange-501 hover:bg-transparent"
                    >
                      Advance filters
                    </ButtonPrimary1>
                  </HStack>
                  {showAdvanceFilters && (
                    <VStack className="gap-6">
                      <Box
                        className={` flex ${
                          isMobile
                            ? " flex-col gap-4"
                            : "flex-row  justify-between gap-12"
                        } `}
                      >
                        <VStack className="w-full">
                          <Label>Department (Optional)</Label>
                          <MultiSelect
                            _className="w-full"
                            multiple={true}
                            placeholder={"--Select departments--"}
                            options={deprt || []}
                            handleSelection={(e) => {
                              formik.setFieldValue("department", e);
                            }}
                            optionValues={formik.values.department || []}
                          />
                          {formik.touched.department &&
                            formik.errors.department && (
                              <Error text={formik.errors.department} />
                            )}
                        </VStack>
                        <VStack className="w-full">
                          <Label>
                            Minimum Employment Tenure (in Years) (Optional)
                          </Label>
                          <Input
                            type="number"
                            {...formik.getFieldProps("minimumTenure")}
                          />
                          {formik.touched.minimumTenure &&
                            formik.errors.minimumTenure && (
                              <Error text={formik.errors.minimumTenure} />
                            )}
                        </VStack>
                      </Box>
                      <Box
                        className={` flex ${
                          isMobile
                            ? " flex-col gap-4"
                            : "flex-row  justify-between gap-12"
                        } `}
                      >
                        <VStack className="w-full">
                          <Label>Minimum Eligible Options (Optional)</Label>
                          <Input
                            type="number"
                            {...formik.getFieldProps("minimumVestedOptions")}
                          />
                          {formik.touched.minimumVestedOptions &&
                            formik.errors.minimumVestedOptions && (
                              <Error
                                text={formik.errors.minimumVestedOptions}
                              />
                            )}
                        </VStack>

                        <VStack className="w-full">
                          <Label>Minimum Willingness (Optional)</Label>
                          <Input
                            type="number"
                            {...formik.getFieldProps("minimumWillingness")}
                          />
                          {formik.touched.minimumWillingness &&
                            formik.errors.minimumWillingness && (
                              <Error text={formik.errors.minimumWillingness} />
                            )}
                        </VStack>
                      </Box>
                      <EventParticipationConstraints />
                    </VStack>
                  )}
                  {!isUserEsopViewer && (
                    <HStack
                      className={`${
                        isMobile ? "justify-center" : "justify-end"
                      }`}
                    >
                      <ButtonPrimary type="submit">
                        {!isLoading ? (
                          searchParam === "create" ? (
                            "Generate Offer List"
                          ) : (
                            "Save"
                          )
                        ) : (
                          <Icon
                            className="animate-spin"
                            icon="lucide:loader-2"
                            width={36}
                          />
                        )}
                      </ButtonPrimary>
                    </HStack>
                  )}
                </VStack>
              </VStack>
            </VStack>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default CreateEmployeesList;
