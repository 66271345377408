import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { Box, VStack } from "../../components/utils";
import { useGetOptionBuyback } from "../../queries/optionBuyback";
import BCHeader from "../../shared/BCHeader";
import { useBuybackStore } from "../../store/optionBuyback";
import BuybackEmployeeDetails from "./BuybackEmployeeDetails";
import BuybackEmployeeList from "./BuybackOffersList";
import LiquidationDetails from "./LiquidationDetails";

function LiquidationView() {
  const { id } = useParams();
  const _id = id || "";
  const navigate = useNavigate();
  const { data: buybackOverview, refetch, isFetching } = useGetOptionBuyback();
  const optionBuybackOverview = buybackOverview || [];
  const buyback = optionBuybackOverview?.filter(
    (item) => parseInt((item?.id || 0).toString(), 10) === parseInt(_id, 10)
  )[0];
  useEffect(() => {
    refetch();
    // eslint-disable-next-line no-useless-return
    if (isFetching) return;
    else {
      const isValidBuyBack = optionBuybackOverview.filter(
        (optionBuyBack) =>
          parseInt((optionBuyBack.id || 0).toString(), 10) === parseInt(_id, 10)
      );
      if (isValidBuyBack.length === 0) {
        toast(`You don't have permission to access ${_id} buyback`, {
          type: "error",
          autoClose: 3000,
        });
        navigate("/options/esopTransactions");
      }
    }
  }, [optionBuybackOverview, _id]);
  return (
    <VStack className="gap-4 py-3">
      <BCHeader bcTitle={"ESOP Transaction"} bcSubTitle={buyback?.name} />
      {buyback?.elState !== "DRAFT" && (
        <Box
          className={`w-3/5 px-5 py-5 border-dashed border ${
            buyback?.elState !== "CLOSED"
              ? "border-[#FFC700] bg-yellow-50"
              : "border-green-500 bg-green-100"
          } `}
        >
          <p className="text-sm font-medium text-black">
            {`Liquidation of ${buyback?.name} event ${
              buyback?.elState === "ACTIVE" ? "Started" : "Closed"
            } successfully`}
          </p>
        </Box>
      )}
      <div className=" p-4 bg-[#fefefe] gap-4">
        <LiquidationDetails />
        <BuybackEmployeeDetails />
        {/* {buyback?.transactionType === "exercise and liquidate" &&
          buyback?.elState === "ACTIVE" && buyback?.state === 'COMPLETED' ? (
          <UploadExcel />) :
          ( */}
        <BuybackEmployeeList />
        {/* )} */}
      </div>
    </VStack>
  );
}

export default LiquidationView;
