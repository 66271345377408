import { useContext, useEffect, useState } from "react";
import { Box, Dialog } from "@mui/material";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import {
  useGetEmailTemplateContent,
  useGetEmailTemplateNames,
  useSaveEmailTemplate,
} from "../../queries/siteSettings";
import {
  VStack,
  HStack,
  ButtonPrimary,
  ButtonSecondary,
  isEsopViewer,
} from "../../components/utils";
import { Select } from "../../components/shared/Select";
import EmailTemplateEditorDialog from "./EmailTemplateEditorDialog";
import { SiteSettingsContext } from "./SiteSettings";

interface EditTemplateDialog {
  templateName: string;
  templateContent: string;
  editMode: boolean;
}
function EmailTemplateSettings() {
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const isUserEsopViewer = isEsopViewer();
  const [editState, setEditState] = useState(false);
  const [editDialogData, setEditDialogData] = useState<EditTemplateDialog>({
    templateName: "",
    templateContent: "",
    editMode: false,
  });
  const isError = useContext(SiteSettingsContext);
  const defaultEmailTemplate = () => `<div> No Content Exists </div>`;
  const [templateContent, setTemplateContent] = useState(defaultEmailTemplate);
  const { mutate: saveEmailTemplate } = useSaveEmailTemplate();
  const { data: templateNames, isFetched: areTemplateNamesFetched } =
    useGetEmailTemplateNames();
  const {
    data: fetchedTemplateContent,
    isFetched: templateContentFetched,
    refetch: refetchedTemplateContent,
  } = useGetEmailTemplateContent(selectedTemplate);
  useEffect(() => {
    if (editState) {
      setEditDialogData({
        templateName: selectedTemplate,
        templateContent,
        editMode: true,
      });
    }
  }, [editState]);
  function handleSubmit() {
    const emailTemplateDto = {
      emailTemplateName: selectedTemplate,
      emailBodyFile: btoa(templateContent),
      emailTemplateType: selectedTemplate,
    };
    saveEmailTemplate(emailTemplateDto, {
      onSuccess: () => {
        toast("Saved Successfully!", {
          type: "success",
          autoClose: 2000,
        });
      },
      onError: (err: any) => {
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
      },
    });
  }
  useEffect(() => {
    if (selectedTemplate.length > 1) {
      refetchedTemplateContent();
      setEditState(false);
    }
  }, [selectedTemplate]);
  useEffect(() => {
    if (
      templateContentFetched &&
      fetchedTemplateContent?.emailBodyFile != null
    ) {
      setTemplateContent(atob(fetchedTemplateContent.emailBodyFile));
    } else {
      setTemplateContent(defaultEmailTemplate);
    }
  }, [fetchedTemplateContent, templateContentFetched]);
  function handleEditDialogClose(editedData?: string) {
    if (editedData) {
      setTemplateContent(editedData);
    }
  }
  return (
    <Box className="md:w-[425px] lg:w-[850px] p-4 bg-white rounded-lg ">
      <VStack className="justify-between p-4 gap-9">
        <Box className="items-center mt-6 text-lg font-medium text-gray-dark">
          Email Template Settings
        </Box>
        <HStack className="gap-8">
          <p className="flex items-center text-sm font-medium form-label">
            Select an Email Template
          </p>
          <div className="flex-row">
            {areTemplateNamesFetched && (
              <Select
                className="cursor-pointer w-128"
                onChange={(e) => setSelectedTemplate(e.target.value)}
                options={templateNames as string[]}
              />
            )}
          </div>
          {/* <Box className="flex items-start ml-5 cursor-pointer"> */}
          <button
            className=""
            disabled={selectedTemplate === ""}
            onClick={(e) => {
              e.preventDefault();
              setEditState(!editState);
            }}
          >
            <Icon
              icon={
                editState ? "ic:baseline-remove-red-eye" : "akar-icons:pencil"
              }
              width="27"
              className={`h-4 transition duration-100 ease-in-out border-white border-solid rounded-full  ring-2 ring-white  ${
                selectedTemplate === ""
                  ? "cursor-not-allowed text-gray-500"
                  : "cursor-pointer hover:h-6 text-orange-501 hover:-translate-y-1 hover:scale-100 hover:ring-blue-800"
              }`}
            />
          </button>
          {/* </Box> */}
        </HStack>
        <HStack className="justify-between">
          {templateContentFetched && (
            <div
              key={selectedTemplate}
              className="overflow-y-scroll max-h-96"
              dangerouslySetInnerHTML={{ __html: templateContent }}
            ></div>
          )}
        </HStack>
      </VStack>
      <Dialog open={editDialogData.editMode} maxWidth="md">
        {editDialogData.templateContent && (
          <div className="w-[900px]">
            <EmailTemplateEditorDialog
              templateName={editDialogData.templateName}
              templateContent={editDialogData.templateContent}
              onClose={(editedData) => {
                handleEditDialogClose(editedData);
                setEditDialogData({
                  templateContent: "",
                  templateName: "",
                  editMode: false,
                });
                setEditState(false);
              }}
            />
          </div>
        )}
      </Dialog>
      {!isUserEsopViewer && (
        <HStack className="flex flex-row-reverse gap-4 ">
          <ButtonPrimary
            className={`flex items-center self-end justify-center`}
            disabled={isError}
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
          </ButtonPrimary>
          <ButtonSecondary
            onClick={() => {
              refetchedTemplateContent();
            }}
            className="text-gray-400 bg-slate-50"
          >
            Cancel
          </ButtonSecondary>
        </HStack>
      )}
    </Box>
  );
}

export default EmailTemplateSettings;
