import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { ChartData } from "../pages/allEmployees/DepartmentChart";
import { optionsPlan } from "./chart-data";

export const options = optionsPlan;

export function DoughnutChart({ data }: { data: ChartData }) {
  const total = data.datasets[0].data.length;
  const plugins = [
    {
      id: "0",
      beforeDraw(chart: ChartJS) {
        const { width } = chart;
        const { height } = chart;
        const { ctx } = chart;
        ctx.restore();
        const fontSize = (height / 80).toFixed(2);
        ctx.font = `${fontSize}em poppins`;
        ctx.textBaseline = "top";
        ctx.save();
      },
    },
  ];
  return <Doughnut options={options} data={data} plugins={plugins} />;
}
