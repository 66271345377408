export const optionsPool = {
  rotation: 270,
  circumference: 180,
  radius: 120,
  maintainAspectRatio: false,
  responsive: true,
  cutout: 68,
  plugins: {
    tooltip: {
      displayColors: false,
    },
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Doughnut Chart",
      color: "blue",
      font: {
        size: 24,
      },
      responsive: true,
      animation: {
        animateScale: true,
      },
    },
  },
  elements: {
    arc: {
      spacing: -7,
      borderWidth: 0,
      borderRadius: 25,
    },
  },
};
export const optionsPlan = {
  maintainAspectRatio: false,
  responsive: true,
  cutout: 68,
  plugins: {
    tooltip: {
      displayColors: false,
    },
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Doughnut Chart",
      color: "blue",
      font: {
        size: 24,
      },
      responsive: true,
      animation: {
        animateScale: true,
      },
    },
  },
  elements: {
    arc: {
      spacing: -5,
      borderWidth: 5,
      borderRadius: 10,
    },
  },
};
export const optionsJoiningYear = {
  responsive: true,
  categoryPercentage: 0.3,
  barPercentage: 0.4,
  borderRadius: 4,
  plugins: {
    legend: {
      display: false,
      position: "top" as const,
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
  },
  elements: {
    bar: {
      borderWidth: 0,
      padding: 10,
    },
  },
  scales: {
    x: {
      stacked: false,
      title: {
        display: true,
        text: "Year of Joining",
      },
      grid: {
        display: false,
        offset: true,
      },
      ticks: {
        font: { size: 12 },
        padding: 10,
      },
    },
    y: {
      title: {
        display: true,
        text: "Number of Option Holder",
      },
      grid: {
        borderDash: [1, 3],
        color: "#e8e8e8",
      },
      ticks: {
        font: { size: 12 },
      },
    },
  },
};
export function compare(a: { x: number }, b: { x: number }) {
  if (a.x < b.x) {
    return -1;
  }
  if (a.x > b.x) {
    return 1;
  }
  return 0;
}
export const optionsDepartment: Object = {
  rotation: 270,
  circumference: 180,
  maintainAspectRatio: false,
  responsive: true,
  radius: 140,
  cutout: 130,
  cutoutPercentage: 80,
  plugins: {
    tooltip: {
      displayColors: false,
    },
    legend: {
      display: true,
      displayColors: false,
      position: "bottom",
      labels: {
        usePointStyle: true,
        padding: 15,
        boxWidth: 0.5,
        boxHeight: 0.5,
      },
    },
    title: {
      display: false,
      text: "Doughnut Chart",
      color: "blue",
      font: {
        size: 24,
      },
      responsive: true,
      animation: {
        animateScale: true,
      },
    },
  },
  elements: {
    arc: {
      spacing: -10,
      borderWidth: 10,
      borderRadius: 10,
    },
  },
};
export const optionsGrantVsYear: Object = {
  plugins: {
    tooltip: {
      bodyFont: {
        weight: "bold",
        size: "14px",
      },

      displayColors: false,
      backgroundColor: "#e85936",
      callbacks: {
        label: (tooltipitem: {
          raw: {
            name: string;
            y: { toLocaleString: () => string };
            x: {
              toFixed: (arg0: number) => {
                (): number;
                new (): number;
                toLocaleString: { (): string; new (): string };
              };
            };
          };
        }) => {
          const label =
            tooltipitem.raw.name !== undefined
              ? [tooltipitem.raw.name, " "]
              : ["Average Options Granted", " "];
          label.push(`Options Granted: ${tooltipitem.raw.y.toLocaleString()}`);
          label.push(
            `No of years: ${tooltipitem.raw.x.toFixed(2).toLocaleString()}`
          );
          label.push(" ");
          return label;
        },
        title: () => null,
      },
    },
    legend: {
      display: false,
    },
  },
  tension: 0.5,
  scales: {
    y: {
      title: {
        display: true,
        text: "Options Granted",
      },
      beginAtZero: true,
      grid: {
        display: false,
      },
    },
    x: {
      title: {
        display: true,
        text: "Years of Experience",
      },
      type: "linear",
      position: "bottom",
      grid: {
        display: false,
      },
    },
  },
};
