import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import { Switch } from "@mui/material";
import * as Yup from "yup";
import { Grant } from "../../types/Grant";
import {
  ButtonPrimary,
  ButtonSecondary,
  Error,
  HStack,
  VStack,
} from "../../components/utils";

import { Input, Label } from "../../components/shared/InputField";
import {
  useExercisableOptionsDetails,
  useRequestToExercise,
} from "../../queries/exercise";
import { ExerciseReq } from "../../types/Exercise";
import { formatDate, formatWithTimeZone } from "../../utils/date";
import { useError } from "../../store/errorStore";
import { formatCurrency, getCurrencyType } from "../../utils/currencyFormatter";

function ExerciseRequest({
  grant,
  onClose = () => {},
}: {
  grant: Grant;
  onClose: () => void;
}) {
  const currency = getCurrencyType();
  const { data } = useExercisableOptionsDetails(grant.optionHolderId || 0);
  const { mutate: requestToExercise, status } = useRequestToExercise();
  const errorMessage = useError();
  const conversionRatio = data?.conversionRatio || 1;
  const initialValues: Partial<ExerciseReq> = {
    ...data,
    optionHolderId: data?.optionHolderId,
    numberOfShares: 0,
    isCashExercise: false,
    dateOfExercise: formatDate(new Date().toDateString()),
    conversionRatio,
  };
  const validationSchema = Yup.object({
    numberOfShares: Yup.number()
      .required("required")
      .positive("should not be zero")
      .when("isCashExercise", {
        is: false,
        then: Yup.number().test(
          "numberOfShares",
          `Number of shares should be a multiple of ${1 / conversionRatio}`,
          (value) => {
            if (
              (value || 0) * conversionRatio ===
              Math.floor((value || 0) * conversionRatio)
            ) {
              return true;
            }
            return false;
          }
        ),
      }),
    dateOfExercise: Yup.date().required("required"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      values.optionHolderId = grant.optionHolderId;
      const exerciseReq = {
        ...data,
        ...values,
        dateOfExercise: formatWithTimeZone(
          values?.dateOfExercise || new Date().toDateString()
        ),
      };
      requestToExercise([exerciseReq] as ExerciseReq[], {
        onSuccess: () => {
          resetForm();
          toast("Exercise Request Submitted!", { type: "success" });
          onClose();
        },
        onError: (err: any) => {
          errorMessage.setMessage(err.response.data.reason);
          toast(err.response.data.reason, { type: "error", autoClose: 2000 });
        },
      });
    },
  });
  const { touched, errors } = formik;
  const showMessage =
    formik.touched?.numberOfShares &&
    !formik.values?.isCashExercise &&
    !formik.errors.numberOfShares;
  return (
    <>
      <div className="p-4 px-10 mt-4 ml-3 text-lg font-medium border-b stone-700 ">
        <h6 className="flex justify-between">
          Request to Exercise Options{" "}
          <span onClick={() => onClose()} className="cursor-pointer">
            X
          </span>
        </h6>
      </div>
      <VStack className="justify-between gap-4 p-4 px-10 ">
        <HStack aria-label="section body" className="flex-wrap grow">
          {[
            { field: "Employee Name", value: data?.optionHolderName },
            {
              field: "Plan Name",
              value: data?.esopPlanName,
            },
            {
              field: "Exercise Price",
              value: formatCurrency(data?.exercisePrice || 0, currency),
            },
            {
              field: "Options Available To Exercise",
              value: data?.numberOfShares,
            },
          ].map(({ field, value }, i) => (
            <HStack key={i} className="w-1/2 gap-8 p-3">
              <span className="text-sm font-normal text-stone-700">
                {field}
              </span>
              <span className="text-sm font-medium text-gray-600">
                {value?.toString()}
              </span>
            </HStack>
          ))}
        </HStack>
        <HStack className="gap-8">
          <div className="flex-1 ml-3">
            <Label className="text-sm font-normal">Options To Exercise</Label>
            <Input
              type="number"
              min={1}
              max={data?.numberOfShares}
              {...formik.getFieldProps("numberOfShares")}
            />
            {touched.numberOfShares && errors.numberOfShares && (
              <Error text={errors.numberOfShares} />
            )}
            {showMessage && (
              <Label className="text-xs font-normal">
                {formik.values.numberOfShares} options will be exercised into{" "}
                {(formik.values.numberOfShares || 1) *
                  (formik.values.conversionRatio || 1)}{" "}
                shares
              </Label>
            )}
          </div>
          <HStack className="items-center flex-1 gap-8 p-4 mt-3 mr-4">
            <Label className="text-sm font-normal">Date Of Exercise</Label>
            <Input type="date" {...formik.getFieldProps("dateOfExercise")} />
            {touched.dateOfExercise && errors.dateOfExercise && (
              <Error text={errors.dateOfExercise} />
            )}
          </HStack>
        </HStack>
        <HStack className="items-center w-1/2 gap-8 ml-3">
          <Label className="text-sm font-normal ">Cash Out?</Label>
          <Switch
            checked={formik.values.isCashExercise}
            onChange={(e) =>
              formik.setFieldValue(
                "isCashExercise",
                !formik.values.isCashExercise
              )
            }
            value={formik.values.isCashExercise}
          />
        </HStack>
        <HStack className="flex flex-row-reverse gap-4">
          <ButtonPrimary
            className={`flex items-center self-end justify-center ${
              status === "success" ? "bg-green-500" : ""
            }`}
            onClick={() => {
              if (status === "success") {
                onClose();
              } else {
                formik.handleSubmit();
              }
            }}
          >
            {(status === "idle" || status === "error") && "Send Request"}
            {status === "loading" && (
              <Icon
                className=" animate-spin"
                icon="lucide:loader-2"
                width={36}
              />
            )}
            {status === "success" && (
              <Icon icon="clarity:success-standard-line" width={36} />
            )}
          </ButtonPrimary>
          <ButtonSecondary
            onClick={() => onClose()}
            className="text-gray-400 bg-slate-50"
          >
            Cancel
          </ButtonSecondary>
        </HStack>
      </VStack>
    </>
  );
}
export default ExerciseRequest;
