import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router";
import {
  CellClickedEvent,
  ColDef,
  GridApi,
  SideBarDef,
  ValueParserParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useAuthStore } from "../../store";
import { Employee } from "../../types/Employee";
import Tooltip from "../../components/shared/Tooltip";

import {
  Box,
  HStack,
  isAdminViewer,
  isEsopViewer,
} from "../../components/utils";
import { useAuthorizationStore } from "../../store/useAuthorizationStore";
import Avatar from "../esopOverview/Avatar";
import { Action, CTADropdown } from "../../components/shared/Dropdown";

import { limitString } from "../../utils/string";

function EmployeesTableAgGrid({
  handleAction,
  employeeTableData,
  onSelectedRows,
  gridRef,
}: {
  employeeTableData: Employee[];
  handleAction: (action: Action, employees: Employee[]) => void;
  onSelectedRows: (item: Employee[]) => void;
  gridRef: any;
}) {
  const { user } = useAuthStore();
  const userPermission = useAuthorizationStore();
  const isUserAdminViewer = isAdminViewer();
  const isUserEsopViewer = isEsopViewer();
  const chartThemes = useMemo<string[]>(() => ["ag-pastel", "ag-vivid"], []);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const gridApi = useRef<GridApi | null>(null);

  // route params logic
  const isPlaceholderData = false;
  const navigate = useNavigate();
  const sideBar = useMemo<SideBarDef>(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          width: 225,
          maxWidth: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    }),
    []
  );
  const filterparams = {
    filterParams: {
      filterOptions: [
        "lessThan",
        {
          displayKey: "lessThanWithNulls",
          displayName: "Less Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue < filterValue,
        },
        "greaterThan",
        {
          displayKey: "greaterThanWithNulls",
          displayName: "Greater Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue > filterValue,
        },
        {
          displayKey: "betweenExclusive",
          displayName: "Between (Exclusive)",
          predicate: ([fv1, fv2]: any, cellValue: number | null) =>
            cellValue == null ||
            // eslint-disable-next-line no-mixed-operators
            (fv1 < cellValue && fv2 > cellValue),
          numberOfInputs: 2,
        },
      ],
    },
  };

  // const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
  //   if (cellParams.column.getColId() !== "action-column")
  //     navigate(
  //       `/options/employeeDetails/${
  //         employeeTableData[cellParams.id as number].id
  //       }`
  //     );
  // };

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const rowData = employeeTableData.find(
        (row) =>
          row.employeeIdentificationString === cellParams?.data?.employeeId
      );
      if (rowData) {
        navigate(`/options/employeeDetails/${rowData.id}`);
      }
    }
  };

  function numberValueParser(params: ValueParserParams) {
    const res = Number.parseInt(params.newValue, 10);
    if (Number.isNaN(res)) {
      return undefined;
    }
    return res;
  }
  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));
  const normaliseValue = (value: string | number) => {
    if (typeof value === "number") return value;
    if (typeof value === "string") {
      // check if it can be converted to number first
      // assume if commas are there
      if (isValidDate(value)) return new Date(value).valueOf();
      try {
        value = value.replaceAll(",", "");
      } catch (TypeError) {
        value = value.replace(/,./g, "");
      }
      return parseFloat(value);
    }
    return value;
  };
  const sortComparator = (
    valueA: number | string,
    valueB: number | string,
    ..._: any[]
  ) => normaliseValue(valueA) - normaliseValue(valueB);
  const stringToNumberConvertor = (formattedNumber: string) =>
    parseInt(formattedNumber.toString().replaceAll(",", ""), 10);

  const handleSelectionChanged = (item: any) => {
    const selectedRows = item.api.getSelectedRows();
    onSelectedRows(selectedRows);
  };

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline text-xs font-medium text-gray-dark leading-5",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      comparator: sortComparator,
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );
  const columnDefs: ColDef[] = [
    {
      headerName: "EMPLOYEE NAME",
      checkboxSelection: true,
      width: 200,
      autoHeight: true,
      cellStyle: { "padding-top": "15px" },
      wrapText: true,
      headerCheckboxSelection: true,
      field: "employeeName",
      filter: "agSetColumnFilter",
      filterValueGetter: (params) =>
        params.getValue("employeeName").props.employeeName,
      initialWidth: 200,
      minWidth: 200,
      sortable: true,
      filterParams: {
        comparator: sortComparator,
      },
      comparator(valueA, valueB, ..._other) {
        return valueA.props.employeeName?.localeCompare(
          valueB.props.employeeName
        );
      },
      menuTabs: ["filterMenuTab"],
      cellRendererParams: ({ value }: { value: any }) => value.props,
      cellRenderer: EmployeeInfoRender,
    },
    {
      headerName: "DATE OF JOINING",
      width: 200,
      cellStyle: { "padding-top": "15px" },
      field: "dateofjoining",
      sortable: true,
      filter: "agMultiColumnFilter",
      autoHeight: true,
      wrapText: true,
      comparator(valueA, valueB, ..._other) {
        return valueA.props.dateOfJoin?.localeCompare(valueB.props.dateOfJoin);
      },
      filterParams: {
        maxNumConditions: 5,
        buttons: ["reset"],
        filters: [
          {
            title: "Employee Date",
            display: "subMenu",
            filterParams: {
              maxNumConditions: 5,
              inRangeInclusive: true,
              buttons: ["reset"],
              comparator: (
                filterLocalDateAtMidnight: Date,
                cellValue: { props: Employee }
              ) => {
                const filterDate = filterLocalDateAtMidnight;
                filterDate.setHours(0, 0, 0, 0);
                const cellDate = new Date(cellValue.props?.dateOfJoin);
                cellDate.setHours(0, 0, 0, 0);
                const filterDateAsEpoch = filterDate.valueOf();
                const cellEpoch = cellDate.valueOf();
                return cellEpoch - filterDateAsEpoch;
              },
            },
            filter: "agDateColumnFilter",
          },
        ],
      },
      menuTabs: ["filterMenuTab"],
      cellRendererParams: ({ value }: { value: any }) => value.props,
      cellRenderer: EmployeeDate,
    },
    {
      headerName: "EMPLOYEE ID",
      width: 200,
      field: "employeeId",
      filter: "agSetColumnFilter",
      filterValueGetter: (params) => params.getValue("employeeId"),
      filterParams: filterparams,
      menuTabs: ["filterMenuTab"],
      sortable: true,
      autoHeight: true,
      wrapText: true,
    },
    {
      headerName: "EMAIL ID",
      width: 200,
      cellStyle: { "line-height": "20px", "padding-top": "15px" },
      field: "email",
      filter: "agSetColumnFilter",
      filterValueGetter: (params) => params.getValue("email"),
      filterParams: {
        comparator: sortComparator,
      },
      comparator(valueA, valueB, ..._other) {
        return valueA.props.email?.localeCompare(valueB.props.email);
      },
      sortable: true,
      menuTabs: ["filterMenuTab"],
      suppressSizeToFit: true,
      autoHeight: true,
      wrapText: true,
    },
  ];

  const rowData = useMemo(
    () =>
      employeeTableData?.map((employee) => ({
        avatar: (
          <Avatar name={employee.employeeName} status={employee.employeeName} />
        ),
        employeeName: (
          <EmployeeInfoRender
            employeeName={employee.employeeName}
            employeeDesignation={employee.employeeDesignation}
            department={employee.department}
            employmentStatus={employee.employmentStatus}
          />
        ),
        dateofjoining: (
          <EmployeeDate
            dateOfJoin={employee.dateOfJoin}
            lastDay={employee.lastDay}
          />
        ),
        employeeId: employee.employeeIdentificationString,
        email: employee.email,
      })),
    [employeeTableData]
  );
  const onPageSizeChanged = useCallback(() => {
    const value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridApi.current?.paginationSetPageSize(Number(value));
  }, []);
  const onGridReady = (params: any) => {
    gridApi.current = params.api;
    gridRef.current = params.api;
  };
  return (
    <AgGridReact
      alwaysShowHorizontalScroll
      rowClass={"border-t border-dashed cursor-pointer hover:bg-slate-50 "}
      alwaysMultiSort
      onGridReady={onGridReady}
      sideBar={sideBar}
      animateRows={true}
      defaultColDef={defaultColDef}
      rowData={rowData}
      suppressCopyRowsToClipboard={true}
      suppressCopySingleCellRanges={true}
      suppressCellFocus={true}
      suppressMenuHide={true}
      columnDefs={columnDefs}
      onCellClicked={handleCellClick}
      rowMultiSelectWithClick={true}
      rowSelection="multiple"
      onSelectionChanged={handleSelectionChanged}
      suppressRowClickSelection={true}
      pagination={true}
      overlayNoRowsTemplate={
        '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
      }
    ></AgGridReact>
  );
}

function EmployeeInfoRender(emp: {
  employeeName: string;
  employeeDesignation: string;
  department: string;
  employmentStatus: string;
}) {
  return (
    <HStack>
      <Avatar name={emp.employeeName} status={emp.employmentStatus} />
      <Box className="ml-2">
        <p className="text-xs font-medium text-gray-dark">{emp.employeeName}</p>
        <p className=" capitalize text-xxs text-gray-light">
          {(emp.employeeDesignation !== null
            ? emp.employeeDesignation
            : ""
          ).toLowerCase() || " "}{" "}
          |{" "}
          {(emp.department !== null ? emp.department : "").toLowerCase() || " "}
        </p>
      </Box>
    </HStack>
  );
}

function EmployeeDate(emp: { dateOfJoin?: string; lastDay?: string }) {
  const formattedDate = new Date(emp.dateOfJoin || " ")
    .toUTCString()
    .slice(5, 17);
  const formattedLastDay = new Date(emp.lastDay || "")
    .toUTCString()
    .slice(5, 17);
  return (
    <Box className="">
      <HStack>
        <Box>
          <p className="text-xs font-medium text-gray-dark">{formattedDate}</p>
          {emp.lastDay && (
            <p className="capitalize text-xxs text-gray-light">{`Resigned on: ${formattedLastDay}`}</p>
          )}
        </Box>
      </HStack>
    </Box>
  );
}

export default EmployeesTableAgGrid;
