import { Icon } from "@iconify/react";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
} from "../../components/utils";
import {
  CompensationTemplate,
  SalaryPerGrades,
} from "../../store/esopModellingStore";

type AddSalaryPerGradeDialogProps = {
  onPrimaryAction: (salaryPerGrade: SalaryPerGrades) => void;
  onClose: () => void;
  data: SalaryPerGrades;
  gradeList: string[];
};

function AddSalaryPerGradeDialog({
  onClose = () => {},
  onPrimaryAction = () => {},
  data,
  gradeList,
}: AddSalaryPerGradeDialogProps) {
  const initialSalaryPerGradeFields: SalaryPerGrades = {
    gradeName: data?.gradeName || "",
    optionMultiplier: data?.optionMultiplier,
    currentEmployees: data?.currentEmployees,
    minSalary: data?.minSalary,
    maxSalary: data?.maxSalary,
    division: data?.division || "",
    role: data?.role || "",
    salary: data?.salary,
  };

  const validationSchema = Yup.object().shape({
    gradeName: Yup.string().required("Grade Name is required"),
    optionMultiplier: Yup.number().required("Option Multiplier is required"),
    minSalary: Yup.number().required("Minimum Salary is required"),
    maxSalary: Yup.number().required("Maximum Salary is required"),
  });
  const handleSubmit = (values: SalaryPerGrades) => {
    onPrimaryAction(values);
  };
  return (
    <Formik
      key="addSalaryPerGradeTemplate"
      initialValues={initialSalaryPerGradeFields}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {(formik) => (
        <Form key="salaryPerGrade">
          <VStack className="w-full gap-9 p-10">
            <Box className="text-lg font-medium border-b">
              <h6 className="justify-between flex">
                Grade Compensation
                <span onClick={() => onClose()} className="cursor-pointer">
                  X
                </span>{" "}
              </h6>
            </Box>
            <HStack className="gap-8 ">
              <div className="flex-1">
                <Label className="text-sm font-normal">Grade</Label>
                <Select
                  options={gradeList}
                  {...formik.getFieldProps("gradeName")}
                />
                {formik.touched.gradeName && formik.errors.gradeName && (
                  <Error text={formik.errors.gradeName} />
                )}
              </div>
              <div className="flex-1">
                <Label className="text-sm font-normal">Minimum Salary</Label>
                <Input
                  type="number"
                  placeholder="Minimum Salary"
                  {...formik.getFieldProps("minSalary")}
                />
                {formik.touched.minSalary && formik.errors.minSalary && (
                  <Error text={formik.errors.minSalary} />
                )}
              </div>
            </HStack>
            <HStack className="gap-8 ">
              <div className="flex-1">
                <Label className="text-sm font-normal">Maximum Salary</Label>
                <Input
                  type="number"
                  placeholder="Maximum Salary"
                  {...formik.getFieldProps("maxSalary")}
                />
                {formik.touched.maxSalary && formik.errors.maxSalary && (
                  <Error text={formik.errors.maxSalary} />
                )}
              </div>
              <div className="flex-1">
                <Label className="text-sm font-normal">Option Multiplier</Label>
                <Input
                  type="number"
                  placeholder="Option Multiplier"
                  {...formik.getFieldProps("optionMultiplier")}
                />
                {formik.touched.optionMultiplier &&
                  formik.errors.optionMultiplier && (
                    <Error text={formik.errors.optionMultiplier} />
                  )}
              </div>
            </HStack>
            <HStack className="justify-between mt-12">
              <ButtonPrimary1
                type="reset"
                className="text-red-500 mr-8"
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </ButtonPrimary1>
              <ButtonPrimary type="submit">Create</ButtonPrimary>
            </HStack>
          </VStack>
        </Form>
      )}
    </Formik>
  );
}

export default AddSalaryPerGradeDialog;
