import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { CircularProgress, Dialog } from "@mui/material";
import { ExpandButton } from "../../components/shared/ExpandButton";
import { useGrantCreatedDialog } from "../../store/useDialogStore";
import {
  VStack,
  HStack,
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  Error,
} from "../../components/utils";
import {
  useSignAndApproveGrant,
  useChangeOptionStates,
  useEsopPlans,
  useGrant,
  useNextStates,
} from "../../queries";
import StatusLabel from "./StatusLabel";
import { Label, TextArea } from "../../components/shared/InputField";
import { SwitchButton } from "../../components/shared/SwitchButton";
import {
  formatDate,
  formatDisplayDate,
  formatWithTimeZone,
} from "../../utils/date";
import { stringFormat } from "../../utils/string";
import { useError } from "../../store/errorStore";
import AlertDialog from "../../components/shared/AlertDialog";
import { formatCurrency, getCurrencyType } from "../../utils/currencyFormatter";
import { createEnvelope } from "../../api/Esop";
import { useAuthStore } from "../../store";
import { EnvelopeDetails } from "../../types/ESign";
import SignaturePad from "../../shared/signaturePad";
import { SignatureAttachment } from "../../types/Grant";

export function GrantCreated() {
  const currency = getCurrencyType();
  const { state: grantCreatedDialog, setState: setGrantCreatedDialog } =
    useGrantCreatedDialog();
  const [showDialog, setShowDialog] = useState(false);
  const [showSignaturePad, setShowSignaturePad] = useState(false);
  const { grant, isFetching } = useGrant(grantCreatedDialog.grantId || 0);
  const { data: _plans } = useEsopPlans();
  const errorMessage = useError();
  const { user } = useAuthStore();
  useEffect(() => {
    // eslint-disable-next-line no-useless-return
    if (isFetching) return;
    else {
      const plan = (_plans || []).filter(
        (plan) => plan.esopPlanId === grant?.planid
      )[0];
      if (plan?.esopPlanState === "Amendment") {
        setShowDialog(true);
        errorMessage.setMessage(
          "Plan needs to be in active state to update the Grant State"
        );
      }
    }
  }, [grant, isFetching]);
  const { data: nextStates } = useNextStates(grantCreatedDialog.grantId || 0);
  useEffect(() => {
    if (nextStates?.message?.includes("failed")) {
      setShowDialog(true);
      errorMessage.setMessage(nextStates.response.data.reason);
    }
  }, [nextStates]);
  const { mutate: changeOptionStates, isLoading } = useChangeOptionStates();
  const { mutate: attachSignature, isLoading: signatureBeingAttached } =
    useSignAndApproveGrant();
  const availableState = nextStates?.availableStates;
  const [expanded, setExpanded] = useState(true);
  const [emailCheck, setEmailCheck] = useState(false);
  const [notes, setNotes] = useState("");

  function handleClick() {
    if (!grant?.optionHolderId) return;
    changeOptionStates(
      {
        optionHolderIds: [grant.optionHolderId],
        optionHolderState: "FOR_APPROVAL",
        note: notes,
        mailToBeSent: emailCheck,
        dateOfTransaction: formatWithTimeZone(new Date().toUTCString()),
      },
      {
        onSuccess: () => {
          if (user?.company.isDocuSignEnabled) {
            const envelopDetails: EnvelopeDetails = {
              ids: [grant.optionHolderId],
              companyId: user?.company.id as number,
              grantState: "FOR_APPROVAL",
              signerType: "OPTIONS",
            };
            createEnvelope(envelopDetails);
          }
          toast("State Changed Successfully!", {
            type: "success",
            autoClose: 2000,
          });
          setGrantCreatedDialog({ open: false });
        },
        onError: (err: any) => {
          errorMessage.setMessage(err.response.data.reason);
          toast(err.response.data.reason, { type: "error", autoClose: 2000 });
          setGrantCreatedDialog({ open: false });
        },
      }
    );
  }
  const handleSignaturePad = (signature: string) => {
    if (signature) {
      signature = signature.replace("data:image/png;base64,", "");
    }
    setShowSignaturePad(!showSignaturePad);
    const signatureAttachmentObj: SignatureAttachment = {
      signature,
      optionHolderIds: [grant?.optionHolderId || 0],
    };
    attachSignature(signatureAttachmentObj, {
      onSuccess: () => {
        toast("State Changed Successfully!", {
          type: "success",
          autoClose: 2000,
        });
        setGrantCreatedDialog({ open: false });
      },
    });
  };
  const [noteError, setNoteError] = useState("");
  function updateGrantStatus(state: string) {
    if (!grant?.optionHolderId || !state) return;
    if (user?.company?.requestApproverSignature && state === "APPROVED") {
      toast("Please Sign In order to approve the grant", {
        type: "info",
        autoClose: 3000,
      });
      setShowSignaturePad(!showSignaturePad);
      return;
    }
    if (notes === "" && grant?.optionHolderState === "GRANTED") {
      setNoteError("Please Enter Note");
      return;
    }
    if (
      user?.company.isDocuSignEnabled &&
      (state === "APPROVED" || state === "GRANTED") &&
      !grant.envelopeSigned
    ) {
      toast(
        "State changes are not permitted because the required signatories have not signed.",
        {
          type: "error",
          autoClose: 2000,
        }
      );
      return;
    }
    if (
      user?.company.isDigioEnabled &&
      state === "GRANTED" &&
      !grant.envelopeSigned
    ) {
      toast(
        "State changes are not permitted because the required signatories have not signed.",
        {
          type: "error",
          autoClose: 2000,
        }
      );
      return;
    }
    changeOptionStates(
      {
        optionHolderIds: [grant.optionHolderId],
        optionHolderState: state,
        note: notes,
        mailToBeSent: emailCheck,
        dateOfTransaction: formatWithTimeZone(new Date().toUTCString()),
      },
      {
        onSuccess: () => {
          if (
            user?.company.isDocuSignEnabled &&
            (state === "FOR_APPROVAL" || state === "OFFERED")
          ) {
            const envelopDetails: EnvelopeDetails = {
              ids: [grant.optionHolderId],
              companyId: user?.company.id as number,
              grantState: state,
              signerType: "OPTIONS",
            };
            createEnvelope(envelopDetails);
          }
          toast("State Changed Successfully!", {
            type: "success",
            autoClose: 2000,
          });
          setGrantCreatedDialog({ open: false });
        },
        onError: (err: any) => {
          errorMessage.setMessage(err.response.data.reason);
          toast(err.response.data.reason, { type: "error", autoClose: 2000 });
          setGrantCreatedDialog({ open: false });
        },
      }
    );
  }
  return (
    <>
      <div className="py-7 px-10 text-lg font-medium border-b">
        <h6 className="flex justify-between">
          {grantCreatedDialog.variant === "state change"
            ? "Update Grant Status"
            : "Grant added successfully"}
          <span
            className="cursor-pointer"
            onClick={() => setGrantCreatedDialog({ open: false })}
          >
            X
          </span>
        </h6>
      </div>
      <VStack className="grow justify-between px-10">
        {true && (
          <>
            <HStack
              aria-label="section"
              className=" py-4 mb-10 border-b border-dashed"
            >
              <Box className="py-2 pr-4">
                <ExpandButton
                  expanded={expanded}
                  onClick={() => setExpanded((state) => !state)}
                ></ExpandButton>
              </Box>
              <VStack className="flex-1">
                <Box
                  aria-label="section header"
                  className=" flex flex-row items-center gap-4 mb-2"
                >
                  <Box>
                    <h5 className="text-sm font-medium text-[#030303]">
                      Grant Details
                    </h5>
                    <h6 className="text-gray-light text-sm font-normal">
                      {grant?.planName}
                    </h6>
                  </Box>
                  <StatusLabel state={grant?.optionHolderState || ""} />
                </Box>
                {expanded && (
                  <HStack aria-label="section body" className="grow flex-wrap">
                    {[
                      {
                        field: "Name of the Holder",
                        value: grant?.optionHolderName,
                      },
                      {
                        field: "Granted",
                        value: grant?.optionsGranted?.toLocaleString(currency),
                      },
                      {
                        field: "Grant Price",
                        value: formatCurrency(grant?.grantPrice || 0, currency),
                      },
                      {
                        field: "Grant Date",
                        value:
                          grant?.grantDate &&
                          formatDisplayDate(
                            new Date(grant.grantDate)?.toUTCString()
                          ),
                      },
                      {
                        field: "Vested",
                        value: grant?.optionsVested?.toLocaleString(currency),
                      },
                      {
                        field: "Grant ID",
                        value: grant?.grantIdentifier,
                      },
                      {
                        field: "Vested Schedule",
                        value: grant?.vestingTemplateName,
                      },
                      {
                        field: "Plan Name",
                        value: grant?.planName,
                      },
                      {
                        field: "Vesting Date Type",
                        value: grant?.vestingDateType,
                      },
                      {
                        field: "Vesting Start Date",
                        value:
                          grant?.vestingDate &&
                          formatDisplayDate(
                            new Date(grant.vestingDate).toUTCString()
                          ),
                      },
                    ].map(({ field, value }, i) => (
                      <HStack key={i} className=" w-1/2 p-1">
                        <span className="w-1/2 text-sm font-normal text-gray-400">
                          {field}
                        </span>
                        <span className="w-1/2 text-xs3 text-[#030303] font-medium">
                          {value?.toString()}
                        </span>
                      </HStack>
                    ))}
                  </HStack>
                )}
              </VStack>
            </HStack>
            {grantCreatedDialog.variant === "state change" && (
              <HStack className=" gap-8">
                <div className="flex-1">
                  <Label className="text-sm font-normal">Notes</Label>
                  <TextArea
                    value={notes}
                    disabled={
                      grant?.optionHolderState !== "GRANTED" &&
                      grant?.optionHolderState !== "OFFERED"
                    }
                    className={`${
                      grant?.optionHolderState !== "GRANTED" &&
                      grant?.optionHolderState !== "OFFERED"
                        ? "cursor-not-allowed"
                        : ""
                    }`}
                    onChange={(e) => {
                      setNotes(e.target.value);
                      if (notes) setNoteError("");
                    }}
                  />
                  {noteError !== "" ? <Error text={noteError} /> : ""}
                </div>

                <HStack className="gap-8 px-10 text-sm font-normal">
                  <SwitchButton
                    value={emailCheck}
                    label="Send Email to Employee"
                    className={`${
                      grant?.optionHolderState !== "OFFERED" &&
                      grant?.optionHolderState !== "GRANTED"
                        ? "text-gray-400 cursor-not-allowed"
                        : ""
                    } `}
                    onClick={(e) => {
                      if (
                        grant?.optionHolderState === "GRANTED" ||
                        grant?.optionHolderState === "OFFERED"
                      )
                        setEmailCheck(!emailCheck);
                    }}
                  />
                </HStack>
              </HStack>
            )}
          </>
        )}
      </VStack>
      {grantCreatedDialog.variant === "state change" ? (
        <>
          <HStack className="justify justify-between p-8">
            <div className="flex items-start">
              <ButtonPrimary1
                onClick={() => setGrantCreatedDialog({ open: false })}
              >
                Back
              </ButtonPrimary1>
            </div>
            <div className="flex items-center space-x-4">
              {availableState &&
                availableState.map((state) => (
                  <ButtonPrimary
                    disabled={isLoading || signatureBeingAttached}
                    key={state}
                    onClick={(e) => updateGrantStatus(state)}
                    className={``}
                  >
                    {stringFormat(state)}
                  </ButtonPrimary>
                ))}
              {isLoading && <CircularProgress size={32} />}
              {signatureBeingAttached && <CircularProgress size={32} />}
            </div>
          </HStack>
        </>
      ) : (
        <>
          <HStack className="justify justify-between p-8">
            <div className="flex items-start">
              <ButtonPrimary1
                onClick={() => setGrantCreatedDialog({ open: false })}
              >
                Close
              </ButtonPrimary1>
            </div>
            <div className="flex items-center">
              <ButtonPrimary onClick={() => handleClick()}>
                Send for approval
              </ButtonPrimary>
            </div>
          </HStack>
        </>
      )}
      <Dialog maxWidth="md" fullWidth open={showSignaturePad}>
        <SignaturePad
          onSecondaryAction={() => {
            toast("Grant cannot be approved as Signature is required", {
              type: "error",
              autoClose: 2000,
            });
            setShowSignaturePad(!showSignaturePad);
          }}
          onPrimaryAction={(signature) => handleSignaturePad(signature)}
          isLoading={signatureBeingAttached}
        />
      </Dialog>
      <AlertDialog
        open={showDialog}
        error
        message={errorMessage.message}
        primaryActionText="Retry"
        secondaryActionText="Go Back"
        onPrimaryAction={() => {
          errorMessage.reset();
          setGrantCreatedDialog({ open: false });
          setShowDialog(false);
        }}
        onSecondaryAction={() => {
          errorMessage.reset();
          setGrantCreatedDialog({ open: false });
          setShowDialog(false);
        }}
        onClose={() => {
          setGrantCreatedDialog({ open: false });
          errorMessage.reset();
          setShowDialog(false);
        }}
      />
    </>
  );
}
