/* eslint-disable import/no-extraneous-dependencies */
import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { FileUploader } from "react-drag-drop-files";
import html2canvas from "html2canvas";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  HStack,
  VStack,
} from "../components/utils";
import convertToBase64 from "../utils/convertToBase64";
import { Input } from "../components/shared/InputField";

const SignaturePad = ({
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  isLoading,
}: {
  onPrimaryAction: (data: string) => void;
  onSecondaryAction: () => void;
  isLoading: boolean;
}) => {
  const [mode, setMode] = useState("");
  const canvasRef = useRef<SignatureCanvas>(null);
  const [uploadedImage, setUploadedImage] = useState("");
  const [generatedSignature, setGeneratedSignature] = useState("");
  return (
    <Box className="h-100">
      <HStack className="justify-between p-4 px-10 mt-3 text-lg font-medium border-b stone-700">
        <span></span>
        <span className="cursor-pointer" onClick={() => onSecondaryAction()}>
          X
        </span>
      </HStack>
      {mode === "" && (
        <HStack className="h-full mt-4 justify-evenly">
          <VStack>
            <Box className="w-full h-60">
              <Box
                onClick={() => setMode("draw")}
                className="w-56 h-56 p-4 text-center align-middle cursor-pointer hover:border-orange-501 hover:border-4 hover:border-solid text-slate-501 justify-middle"
              >
                <div className="p-4 text-base underline">Draw Signature</div>
                <div className="flex justify-center">
                  <Icon
                    className="cursor-pointer  text-orange-501"
                    icon="material-symbols:draw"
                    width={36}
                  />
                </div>
                <div className="mt-2">
                  Go artistic. Use your mouse or trackpad to make a statement.
                </div>
              </Box>
            </Box>
          </VStack>
          <VStack>
            <Box className="w-full h-60">
              <Box
                onClick={() => setMode("type")}
                className="w-56 h-56 p-4 text-center align-middle cursor-pointer hover:border-orange-501 hover:border-4 hover:border-solid text-slate-501 justify-middle"
              >
                <div className="p-4 text-base underline">Type Signature</div>
                <div className="flex justify-center">
                  <Icon
                    className="cursor-pointer text-orange-501"
                    icon="tabler:keyboard"
                    width={36}
                  />
                </div>
                <div>
                  Type your name and choose the font that best matches your
                  personality.
                </div>
              </Box>
            </Box>
          </VStack>
        </HStack>
      )}
      {mode === "draw" && (
        <HStack>
          <Icon
            onClick={() => setMode("")}
            className="cursor-pointer  text-orange-501"
            icon="ion:arrow-back"
            width={36}
          />
          <VStack>
            <HStack className="justify-center pt-10">
              Draw your Signature Here
            </HStack>
            <HStack className="p-4 mr-4 bg-slate-300">
              <SignatureCanvas
                penColor="black"
                ref={canvasRef}
                canvasProps={{ width: "240px", height: "120px", className: "" }}
                backgroundColor="rgb(255, 255, 255)"
              />
            </HStack>
          </VStack>
          {false && (
            <VStack className="">
              <>
                <HStack className="justify-center pt-10">
                  Upload your Signature Here
                </HStack>
                <HStack className="mt-6">
                  <DragDrop onImageUpload={setUploadedImage} />
                </HStack>
              </>

              {uploadedImage && (
                <HStack className="mt-6">
                  <img src={uploadedImage} alt="" />
                </HStack>
              )}
            </VStack>
          )}
        </HStack>
      )}
      {mode === "type" && (
        <HStack>
          <Icon
            onClick={() => setMode("")}
            className="cursor-pointer  text-orange-501"
            icon="ion:arrow-back"
            width={36}
          />
          <SignatureGenerator selectedSignaure={setGeneratedSignature} />
        </HStack>
      )}
      {mode !== "" && (
        <HStack className="justify-end">
          <ButtonPrimary
            className="m-2"
            disabled={isLoading}
            onClick={() => {
              if (mode === "draw") {
                if (uploadedImage !== "") {
                  onPrimaryAction(uploadedImage);
                } else
                  onPrimaryAction(
                    canvasRef?.current?.getTrimmedCanvas().toDataURL() || ""
                  );
              } else if (generatedSignature !== "") {
                onPrimaryAction(generatedSignature);
              }
            }}
          >
            {!isLoading ? (
              `Save`
            ) : (
              <Icon
                className="animate-spin"
                icon="lucide:loader-2"
                width={36}
              />
            )}
          </ButtonPrimary>
          <ButtonSecondary
            className="m-2"
            onClick={() => canvasRef?.current?.clear()}
          >
            Clear
          </ButtonSecondary>
        </HStack>
      )}
    </Box>
  );
};

function DragDrop(props: { onImageUpload: any }) {
  const { onImageUpload } = props;
  const [file, setFile] = useState("");
  const fileTypes = ["JPG", "JPEG", "PNG"];
  const isValidImageFileUploaded = (file: File) => {
    const validExtensions = ["png", "jpeg", "jpg"];
    const fileExtension = file.type.split("/")[1];
    if (!validExtensions.includes(fileExtension)) {
      return false;
    }
    if (file.size / 1024 > 50) {
      toast(
        "File size should be less than 50kb, please upload a different file",
        {
          type: "error",
        }
      );
      return false;
    }
    return true;
  };
  const handleChange = async (file: any) => {
    if (file && file.length > 0) {
      const fileToCheck = file[0];
      if (!isValidImageFileUploaded(fileToCheck)) {
        return;
      }
      const img = new Image();
      img.src = URL.createObjectURL(fileToCheck);
      img.onload = async () => {
        const width = img.width;
        const height = img.height;
        if (width > 240 || height > 120) {
          toast(
            "File height and width should be less than 120px and 240px, please upload a different file",
            {
              type: "error",
            }
          );
        } else {
          convertToBase64(file[0]).then((data) => {
            setFile(data as string);
            onImageUpload(data);
          });
        }
      };
    }
  };
  return (
    <FileUploader
      handleChange={handleChange}
      multiple="false"
      name="file"
      types={fileTypes}
    />
  );
}

const SignatureGenerator = (props: { selectedSignaure: any }) => {
  const [name, setName] = useState("");
  const [signatures, setSignatures] = useState([]);
  const [selectedSignature, setSelectedSignature] = useState("");

  const onSignatureSelect = (signtaure: string) => {
    setSelectedSignature(signtaure);
    props.selectedSignaure(signtaure);
  };
  const generateSignatureStyles = () => {
    const generatedSignatures = [];

    for (let i = 0; i < 10; i++) {
      const style = {
        fontFamily: [
          "cursive",
          "Great Vibes",
          "Nothing You Could Do",
          "serif",
          "Marck Script",
        ][Math.floor(Math.random() * 5)],
        color: ["black", "blue"][Math.floor(Math.random() * 2)],
        textTransform: ["uppercase", "lowercase", "capitalize"][
          Math.floor(Math.random() * 3)
        ],
        fontSize: "18px",
      };
      generatedSignatures.push({ name, style });
    }

    setSignatures(generatedSignatures as []);
  };

  return (
    <HStack className="w-full h-64 mt-8 mb-4 justify-evenly">
      <VStack>
        <HStack>
          <Input
            type="text"
            placeholder="Enter Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </HStack>
        <HStack className="mt-4">
          <ButtonPrimary onClick={generateSignatureStyles}>
            {signatures.length > 0 ? "Generate More" : "Generate Signatures"}
          </ButtonPrimary>
        </HStack>
        {selectedSignature && (
          <HStack className="mt-8">
            <table>
              <tbody>
                <tr className="">Selected Signature</tr>
                <tr>
                  {selectedSignature && (
                    <img className="max-w-xs mt-4" src={selectedSignature} />
                  )}
                </tr>
              </tbody>
            </table>
          </HStack>
        )}
      </VStack>
      <VStack className="w-1/2 overflow-y-auto">
        <table>
          <thead>
            <tr>
              <td>Generated Signatures:</td>
            </tr>
          </thead>
          <tbody>
            {signatures.length > 0 &&
              signatures.map((signature: { style: {}; name: any }, index) => (
                <tr key={index} style={signature?.style}>
                  <td key={index} style={signature?.style}>
                    <TextToImage
                      onSignatureSelect={onSignatureSelect}
                      style={signature.style}
                      text={signature.name}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </VStack>
    </HStack>
  );
};
const TextToImage = (props: {
  text: string;
  style: any;
  onSignatureSelect: any;
}) => {
  const { text, style, onSignatureSelect } = props;
  const imageRef = useRef<any>();

  const convertToImage = () => {
    imageRef.current.style.backgroundColor = "transparent";
    html2canvas(imageRef.current as HTMLElement).then((canvas) => {
      // Convert the canvas to a data URL
      const image = canvas.toDataURL();
      onSignatureSelect(image);
    });
  };

  return (
    <div
      onClick={convertToImage}
      className="p-4 m-4 cursor-pointer max-h-32 max-w-[240px]"
      ref={imageRef}
      style={style}
    >
      {text}
    </div>
  );
};

export default SignaturePad;
