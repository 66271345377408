import create from "zustand";
import { ExercisableDetails } from "../types/OptionBuyback";

export type OptionBuybackStoreType = {
  totalExercisableOptions: ExercisableDetails;
  showDetails: boolean;
  hideEditDetails: boolean;
  transactionType: string;
  exerciseEmployeeList: string;
  setTotalExercisableOptions: (data: ExercisableDetails) => void;
  setShowdetails: (flag: boolean) => void;
  setHideEditDetails: (flag: boolean) => void;
  setTransactionType: (text: string) => void;
  setExerciseEmployeeList: (text: string) => void;
};
const initialState = {
  showDetails: false,
  totalExercisableOptions: {
    totalVested: 0,
    totalExercisedAndForfeited: 0,
    totalEligibility: 0,
    offers: [],
    cumulativeOffers: [],
  },
  hideEditDetails: false,
  transactionType: "",
  exerciseEmployeeList: "Generate Employee List",
};
const optionBuybackStore = create<OptionBuybackStoreType>((set) => ({
  ...initialState,
  setShowdetails: (flag) => {
    set(() => ({ showDetails: flag }));
  },
  setTotalExercisableOptions: (data) => {
    set(() => ({ totalExercisableOptions: data }));
  },
  setHideEditDetails: (flag) => {
    set(() => ({ hideEditDetails: flag }));
  },
  setTransactionType: (text) => {
    set(() => ({ transactionType: text }));
  },
  setExerciseEmployeeList: (text) => {
    set(() => ({ exerciseEmployeeList: text }));
  },
}));

export const useBuybackStore = optionBuybackStore;
