import randomColor from "randomcolor";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { _sum } from "../utils/arrays";
import { Box, HStack, VStack } from "../components/utils";
import { DoughnutChart } from "./DoughnutChart";
import { useEsopModellingStore } from "../store/esopModellingStore";

ChartJS.register(ArcElement, Tooltip, Legend);
const ESOPHoldingPercentage = () => {
  const modelData = useEsopModellingStore();
  const grades = modelData.summary.gradeDetailsSummary.map(
    (item) => item.gradeName
  );
  const gradesNumberOfOptions = modelData.summary.gradeDetailsSummary.map(
    (item) => item.numberOfOptions
  );
  const header = "Holding Percentage";

  const backgroundColor: string[] = [];
  for (let i = 0; i < modelData.summary.gradeDetailsSummary.length; i++) {
    const color = randomColor({
      luminosity: "bright",
      alpha: 0.3,
      seed: grades[i],
    });
    backgroundColor.push(color);
  }
  const gradeSummaryData = {
    labels: grades,
    datasets: [
      {
        label: "# of Votes",
        data: gradesNumberOfOptions,
        backgroundColor,
        borderColor: backgroundColor,
      },
    ],
  };
  return (
    <VStack className={`p-6 bg-white rounded-lg max-h-96`}>
      <VStack className="mb-4">
        <p className="font-medium text-gray-dark">{header}</p>
      </VStack>
      <Box className="w-50 h-70 align-center">
        {modelData && <DoughnutChart data={gradeSummaryData} />}
      </Box>
      <VStack className="overflow-auto ">
        {modelData.summary.gradeDetailsSummary.map((gradeDetails, index) => {
          const backgroundColor = randomColor({
            luminosity: "bright",
            alpha: 0.3,
            seed: gradeDetails.gradeName,
          });
          return (
            <HStack key={index} className="justify-between p-2">
              <HStack>
                <Box
                  style={{ backgroundColor }}
                  className="w-4 h-4 mt-2 rounded"
                ></Box>
                <VStack className="pl-4">
                  <p className="font-medium text-xs2 text-gray-dark">
                    {gradeDetails.gradeName}
                  </p>
                  <p className="text-xs1 font-semi-medium text-gray-light">
                    {gradeDetails.holdingPercentage.toFixed(4)} % -{" "}
                    {Math.floor(gradeDetails.numberOfOptions)} Options
                  </p>
                </VStack>
              </HStack>
            </HStack>
          );
        })}
      </VStack>
    </VStack>
  );
};

export default ESOPHoldingPercentage;
