import axios, { AxiosError, AxiosRequestHeaders } from "axios";
import {
  clearCredentials,
  decodeAuthToken,
  revalidateAuth,
  redirectToHissa,
} from "../components/utils";
import { useAuthStore } from "../store";

const hissaApi = axios.create({
  baseURL:
    localStorage.getItem("apiUrl") ||
    process.env.REACT_APP_API ||
    "http://localhost:8080",
});
hissaApi.defaults.headers.post["Content-Type"] = "application/json";
hissaApi.interceptors.request.use((config) => {
  const accesstoken =
    localStorage.getItem("accesstoken") || useAuthStore.getState().accessToken;
  revalidateAuth();
  if (accesstoken && !config.url?.endsWith("/login")) {
    (config.headers as AxiosRequestHeaders).companyId =
      localStorage.getItem("companyId") || "";
    (config.headers as AxiosRequestHeaders).AccessToken = accesstoken;
  }
  // use X-USR-TOKEN for logout only
  if (config.url === "exit") {
    (config.headers as AxiosRequestHeaders)["x-user-token"] = decodeAuthToken(
      accesstoken || ""
    ).xUserToken;
    delete (config.headers as AxiosRequestHeaders).AccessToken;
  }
  return config;
});
hissaApi.interceptors.response.use(
  (res) => {
    if (res.status === 401) {
      clearCredentials();
      useAuthStore.setState((store) => ({ ...store, isAuthenticated: false }));
      returnToLegacyPostLogout();
      return Promise.reject(res);
    }
    return res;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      clearCredentials();
      useAuthStore.setState((store) => ({ ...store, isAuthenticated: false }));
      returnToLegacyPostLogout();
    }
    return Promise.reject(error);
  }
);

const returnToLegacyPostLogout = () => {
  const hostName = window.location.hostname;
  if (hostName !== "localhost") redirectToHissa("", true);
};
export default hissaApi;
