import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { toast } from "react-toastify";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { Select } from "../../components/shared/Select";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
} from "../../components/utils";
import {
  AdditionalMultiplier,
  ExistingEmployeesDetails,
  useEsopModellingStore,
} from "../../store/esopModellingStore";
import AddEmployeesPerGradeDialog from "./AddEmployeesPerGradeDialog";
import { SwitchButton } from "../../components/shared/SwitchButton";
import AddAdditionalMultiplierDialog from "./AddAdditionalMultiplierDialog";
import { Input } from "../../components/shared/InputField";
import { formatDate, formatWithTimeZone } from "../../utils/date";

type StepProps = {
  onBack: () => void;
  onNext: () => void;
};

function ExistingEmployees(props: StepProps) {
  const { onBack, onNext } = props;
  const data = useEsopModellingStore();
  const currentYear: number = new Date().getFullYear();
  const [dialog, setDialog] = useState<{
    open: boolean;
    index?: number;
    mode?: "Edit" | "Add" | "Delete";
  }>({
    open: false,
    mode: "Add",
  });
  const [additionalMultiplierDialog, setAdditionalMultiplierDialog] = useState<{
    open: boolean;
    index?: number;
    mode?: "Edit" | "Add" | "Delete";
  }>({
    open: false,
    mode: "Add",
  });

  function handleAction(
    action: Action,
    index: number,
    item: ExistingEmployeesDetails
  ) {
    if (action.name === "Edit") {
      setDialog({ ...dialog, open: true, mode: "Edit", index });
    } else if (action.name === "Delete") {
      data.removeExistingEmployeesDetails(
        data.existingEmployeesDetails.indexOf(item)
      );
    }
  }

  function handleAddingAdditionalMultiplier(
    action: Action,
    index: number,
    item: AdditionalMultiplier
  ) {
    if (action.name === "Edit") {
      setAdditionalMultiplierDialog({
        ...additionalMultiplierDialog,
        open: true,
        mode: "Edit",
        index,
      });
    } else if (action.name === "Delete") {
      data.removeAdditionalMultiplier(data.additionalMultiplier.indexOf(item));
    }
  }

  function handleAdd() {
    setDialog({ ...dialog, open: true, mode: "Add" });
  }

  function handleAddAdditionalMultiplier() {
    setAdditionalMultiplierDialog({
      ...additionalMultiplierDialog,
      open: true,
      mode: "Add",
    });
  }

  function handlePrimaryActionEditEmployeePerGrade(
    existingEmployeesDetails: ExistingEmployeesDetails
  ) {
    data.removeExistingEmployeesDetails(dialog.index || 0);
    data.setExistingEmployeesDetails(existingEmployeesDetails);
  }

  function handleSubmit() {
    if (
      data.existingEmployeesDetails.length <
      data.selectedCompensationTemplate.salaryPerGrades.length
    ) {
      toast("Please enter existing Employee data", {
        type: "error",
        autoClose: 2000,
      });
      return;
    }
    onNext();
  }

  return (
    <Box className="w-full p-4 bg-white rounded-lg ">
      <VStack className="justify-between gap-9 p-4">
        <VStack className="justify-between gap-2">
          <p className="text-lg font-medium text-gray-dark">
            Existing Employees
          </p>
          <p className="text-s font-medium text-gray-light">
            Create provision for existing employees to whom you wish to grant
            options. You can either add the details of the employees as a line
            item or import the employee records in bulk. If you wish to provide
            options to employees who have joined before the ESOP plan was
            created, select ‘Yes’, else ‘No’. The percentage of ESOP grantable
            may vary depending on the grade and the number of years the employee
            has worked in the organization. Accordingly, specify the number of
            employees joined under each grade over the years.
          </p>
        </VStack>
        <VStack className="justify-between mt-6">
          <Box className="max-h-full overflow-auto">
            <table className="w-[600px] table-space">
              <thead className="text-xs font-medium text-gray-light">
                <tr className="border-b border-dashed ">
                  <td className="py-3 hover:cursor-pointer">Grade</td>
                  <td className="py-3 hover:cursor-pointer">{currentYear}</td>
                  <td className="py-3 hover:cursor-pointer">
                    {currentYear - 1}
                  </td>
                  <td className="py-3 hover:cursor-pointer">
                    {currentYear - 2}
                  </td>
                  <td className="py-3 hover:cursor-pointer">
                    {currentYear - 3} or Earlier
                  </td>
                  <td className="py-3 hover:cursor-pointer"></td>
                </tr>
              </thead>
              <tbody className="font-medium text-dark">
                {data.existingEmployeesDetails?.map(
                  (employeesDetails, index) => (
                    <tr
                      key={`${employeesDetails.gradeName}-${index}`}
                      className=" border-b border-dashed cursor-pointer hover:bg-slate-50"
                    >
                      <td className="p-2">{employeesDetails.gradeName}</td>
                      <td className="p-2">{employeesDetails.y0}</td>
                      <td className="p-2">{employeesDetails.y1}</td>
                      <td className="p-2">{employeesDetails.y2}</td>
                      <td className="p-2">{employeesDetails.y3}</td>
                      <td className="px-2 py-4 align-top">
                        <CTADropdown
                          actions={[{ name: "Edit" }, { name: "Delete" }]}
                          onAction={(action) =>
                            handleAction(action, index, employeesDetails)
                          }
                        />
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </Box>
          <Box>
            <ButtonPrimary
              className="min-w-max w-1/5 p-4"
              onClick={() => {
                handleAdd();
              }}
            >
              ADD
            </ButtonPrimary>
            <Dialog open={dialog.open} maxWidth="lg">
              {dialog.mode === "Add" && (
                <AddEmployeesPerGradeDialog
                  onPrimaryAction={(
                    existingEmployeesDetails: ExistingEmployeesDetails
                  ) => {
                    data.setExistingEmployeesDetails(existingEmployeesDetails);
                    setDialog({ ...dialog, open: false });
                  }}
                  onClose={() => {
                    setDialog({ ...dialog, open: false });
                  }}
                />
              )}

              {dialog.mode === "Edit" && (
                <AddEmployeesPerGradeDialog
                  onPrimaryAction={(
                    existingEmployeesDetails: ExistingEmployeesDetails
                  ) => {
                    handlePrimaryActionEditEmployeePerGrade(
                      existingEmployeesDetails
                    );
                    setDialog({ ...dialog, open: false });
                  }}
                  onClose={() => {
                    setDialog({ ...dialog, open: false });
                  }}
                  data={data.existingEmployeesDetails[dialog.index || 0]}
                />
              )}
            </Dialog>
          </Box>
        </VStack>
        <VStack className="justify-between">
          <HStack className="justify-between gap-9 p-4">
            <p className={` form-label text-sm font-medium w-2/3 `}>
              Adjust for the time in company before ESOP Plan
            </p>
            <Box className="w-1/3 flex items-start">
              <SwitchButton
                onClick={() => {
                  data.setIsAdjustedOptionMulitplier(
                    !data.isAdjustedOptionMulitplier
                  );
                }}
                className=""
                value={data.isAdjustedOptionMulitplier}
                label=""
              />
            </Box>
          </HStack>
          {data.isAdjustedOptionMulitplier && (
            <Box className="max-h-full overflow-auto">
              <table className="w-[400px] table-space">
                <thead className="text-xs font-medium text-gray-light">
                  <tr className="border-b border-dashed ">
                    <td className="py-3 hover:cursor-pointer">Joining Date</td>
                    <td className="py-3 hover:cursor-pointer">
                      Additional Multiplier (%)
                    </td>
                    <td className="py-3 hover:cursor-pointer"></td>
                  </tr>
                </thead>
                <tbody className="font-medium text-dark">
                  {data.additionalMultiplier.length > 0 &&
                    data.additionalMultiplier.map(
                      (additionalMultiplierItem, index) => (
                        <tr
                          key={`${additionalMultiplierItem.beforeDate}-${index}`}
                          className=" border-b border-dashed cursor-pointer hover:bg-slate-50"
                        >
                          <td className="py-2 px-2">
                            <Input
                              type="date"
                              disabled
                              value={formatDate(
                                additionalMultiplierItem.beforeDate
                              )}
                            />
                          </td>
                          <td className="py-2 px-2">
                            {additionalMultiplierItem.multiple} %
                          </td>
                          <td className="px-2 py-4 align-top">
                            <CTADropdown
                              actions={[{ name: "Edit" }, { name: "Delete" }]}
                              onAction={(action) =>
                                handleAddingAdditionalMultiplier(
                                  action,
                                  index,
                                  additionalMultiplierItem
                                )
                              }
                            />
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </Box>
          )}
          {data.isAdjustedOptionMulitplier && (
            <ButtonPrimary
              className="min-w-max w-1/5"
              onClick={() => {
                handleAddAdditionalMultiplier();
              }}
            >
              ADD
            </ButtonPrimary>
          )}
          <Dialog open={additionalMultiplierDialog.open} maxWidth="lg">
            {additionalMultiplierDialog.mode === "Add" && (
              <AddAdditionalMultiplierDialog
                onPrimaryAction={(
                  additionalMultiplier: AdditionalMultiplier
                ) => {
                  data.setAdditionalMultiplier(additionalMultiplier);
                  setAdditionalMultiplierDialog({
                    ...additionalMultiplierDialog,
                    open: false,
                  });
                }}
                onClose={() => {
                  setAdditionalMultiplierDialog({
                    ...additionalMultiplierDialog,
                    open: false,
                  });
                }}
              />
            )}

            {additionalMultiplierDialog.mode === "Edit" && (
              <AddAdditionalMultiplierDialog
                onPrimaryAction={(
                  additionalMultiplier: AdditionalMultiplier
                ) => {
                  data.removeAdditionalMultiplier(
                    additionalMultiplierDialog.index || 0
                  );
                  data.setAdditionalMultiplier(additionalMultiplier);
                  setAdditionalMultiplierDialog({
                    ...additionalMultiplierDialog,
                    open: false,
                  });
                }}
                onClose={() => {
                  setAdditionalMultiplierDialog({
                    ...additionalMultiplierDialog,
                    open: false,
                  });
                }}
                data={
                  data.additionalMultiplier[
                    additionalMultiplierDialog.index || 0
                  ]
                }
              />
            )}
          </Dialog>
          <HStack className="justify-between pt-12">
            <ButtonPrimary1
              type="reset"
              className="text-red-500 mr-8"
              onClick={() => {
                onBack();
              }}
            >
              Back
            </ButtonPrimary1>
            <ButtonPrimary
              type="submit"
              onClick={() => {
                handleSubmit();
              }}
            >
              Next
            </ButtonPrimary>
          </HStack>
        </VStack>
      </VStack>
    </Box>
  );
}

export default ExistingEmployees;
