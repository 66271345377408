import { Icon } from "@iconify/react";
import randomColor from "randomcolor";
import { toast } from "react-toastify";
import { Dialog } from "@mui/material";
import { useState } from "react";
import _ from "lodash";
import {
  Box,
  VStack,
  HStack,
  Center,
  isAdminViewer,
  isEsopViewer,
} from "../components/utils";
import { useEmployeeAcceptReminder, useRecentGrantOffered } from "../queries";
import { useError } from "../store/errorStore";
import AlertDialog from "../components/shared/AlertDialog";
import { calculateDateDiffTillNow } from "../utils/date";

const CardPend = () => {
  const header = "Pending with Option Holder";
  const message = "Grant(s) Offered To Option Holders";
  const { data: _recentGrantOffered } = useRecentGrantOffered();
  const recentGrantOffered = _.uniqBy(_recentGrantOffered, (grant) => grant.id);
  const { mutate: sendReminder } = useEmployeeAcceptReminder();
  const errorMessage = useError();
  const isUserAdminViewer = isAdminViewer();
  const isUserEsopViewer = isEsopViewer();
  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    primaryAction?: () => void;
  }>({ open: false, message: "" });

  function sendRemind(id: number) {
    sendReminder(id, {
      onSuccess: () => {
        toast("Reminder Email sent successfully", {
          type: "success",
          autoClose: 2000,
        });
        setDialog({ open: false });
      },
      onError: (err: any) => {
        errorMessage.setMessage(err.response.data.reason);
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
      },
    });
  }
  function handleAction({ id }: { id: number }) {
    setDialog({
      open: true,
      message: "Are you sure you want to send reminder Email ?",
      primaryAction: () => sendRemind(id),
    });
  }
  return (
    <VStack className="p-6 overflow-auto bg-white rounded-lg max-h-96">
      <VStack className="mb-4">
        <p className="font-medium text-gray-dark">{header}</p>
        <p className="text-xs1 font-semi-medium text-gray-light">{message}</p>
      </VStack>
      <VStack className="overflow-auto">
        {recentGrantOffered.map((row) => {
          const backgroundColor = randomColor({
            luminosity: "dark",
            format: "rgba",
            alpha: 0.2,
            seed: row.subject,
          });
          return (
            <HStack key={row.id} className="justify-between p-2">
              <HStack>
                <Box
                  style={{ backgroundColor }}
                  className="w-4 h-4 mt-2 rounded"
                ></Box>
                <VStack className="pl-4">
                  <p className="font-medium text-xs2 text-gray-dark">
                    {row.subject}
                  </p>
                  <p className="text-xs1 font-semi-medium text-gray-light">
                    {calculateDateDiffTillNow(row.requestDate)}
                  </p>
                </VStack>
              </HStack>
              <button
                onClick={() =>
                  !(isUserAdminViewer || isUserEsopViewer)
                    ? handleAction({ id: row.id || 0 })
                    : null
                }
              >
                <Icon
                  icon="el:envelope"
                  width="27"
                  className={`h-4 transition duration-100 ease-in-out border-white border-solid rounded-full text-orange-501 ring-2 ring-white hover:p-1 hover:h-6 hover:text-orange-501 hover:-translate-y-1 hover:scale-100 hover:ring-blue-800 ${
                    isUserAdminViewer || isUserEsopViewer
                      ? "cursor-not-allowed"
                      : ""
                  }`}
                />
              </button>
            </HStack>
          );
        })}
        {recentGrantOffered.length === 0 && (
          <Center className="h-48">
            <p className="p-4 font-medium text-teal-600 rounded">
              No Pending Requests with Employee !!{" "}
            </p>
          </Center>
        )}
      </VStack>
      <Dialog
        open={dialog.open}
        onClose={() => setDialog({ open: false })}
        maxWidth="md"
      >
        <AlertDialog
          onClose={() => setDialog({ open: false })}
          open={dialog.open}
          message={dialog.message}
          onPrimaryAction={dialog.primaryAction}
          onSecondaryAction={() => setDialog({ open: false })}
        />
      </Dialog>
    </VStack>
  );
};

export default CardPend;
