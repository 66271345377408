import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, HStack, VStack } from "../../components/utils";
import {
  useBuybackState,
  useGetOptionBuyback,
} from "../../queries/optionBuyback";
import { optionBuybackDetail } from "../../types/OptionBuyback";
import { formatCurrency, getCurrencyType } from "../../utils/currencyFormatter";

function BuybackEmployeeDetails() {
  const currency = getCurrencyType();
  const { id } = useParams();
  const _id = id || "";
  const {
    data: buybackOverview,
    isFetching,
    isPlaceholderData,
  } = useGetOptionBuyback();
  const optionBuybackOverview = buybackOverview || [];

  const buybackDetail = useMemo(
    () =>
      optionBuybackOverview?.filter(
        (item) => parseInt((item?.id || 0).toString(), 10) === parseInt(_id, 10)
      )[0],
    [isPlaceholderData, isFetching]
  );
  const { data: _buybackStateData } = useBuybackState(parseInt(_id, 10));
  const buybackStateData = _buybackStateData || [];

  useEffect(() => {
    let willingNess = 0;
    if (!buybackDetail && buybackStateData?.length <= 0) return;
    buybackStateData?.forEach((item) => {
      willingNess += item.willingness;
    });
  }, [buybackDetail, buybackStateData]);

  let totalAcceptedOffersByEmployee = 0;
  let totalUnacceptedOffersByEmployee = 0;
  buybackStateData.forEach((item) => {
    if (
      item.state.toLowerCase() === "accepted" ||
      item.state.toLowerCase() === "completed" ||
      item.state.toLowerCase() === "pending_exercised"
    ) {
      totalAcceptedOffersByEmployee += 1 ?? 0;
    } else if (
      item.state.toLowerCase() === "expired" ||
      item.state.toLowerCase() === "rejected"
    ) {
      totalUnacceptedOffersByEmployee += 1 ?? 0;
    }
  });

  let totalWillingNess = 0;
  function getTotalWillingNess() {
    buybackStateData?.forEach((item) => {
      if (
        item.state.toLowerCase() === "accepted" ||
        item.state.toLowerCase() === "completed" ||
        item.state.toLowerCase() === "pending_exercised"
      )
        totalWillingNess += item.willingness;
    });
    return totalWillingNess;
  }
  function getTotalEligibility() {
    let totalEligibility = 0;
    buybackStateData.forEach((item) => {
      totalEligibility += item.eligibility;
    });
    return totalEligibility;
  }
  function getTotalCashOut() {
    let totalEligibility = 0;
    buybackStateData.forEach((item) => {
      totalEligibility += item.willingness;
    });
    return totalEligibility;
  }
  function getTotalPriceToPay() {
    let totalPrice = 0;
    buybackStateData?.forEach((item) => {
      if (
        item.state.toUpperCase() === "ACCEPTED" ||
        item.state.toUpperCase() === "COMPLETED" ||
        item.state.toUpperCase() === "PENDING_EXERCISED"
      ) {
        totalPrice += item?.totalPriceToPay || 0;
      }
    });
    return totalPrice;
  }
  function calculateDaysToBuybackAccept(buybackdetails: optionBuybackDetail) {
    const daysDiff = Math.ceil(
      (new Date(buybackDetail?.endDate).getTime() - new Date().getTime()) /
        (1000 * 3600 * 24)
    );
    if (daysDiff > 0) {
      return daysDiff;
    } else return 0;
  }
  const empDetail = [
    {
      name: `Days left to Accept ${
        buybackDetail?.transactionType === "buyback"
          ? "Buy Back"
          : "Liquidity program"
      }`,
      total: calculateDaysToBuybackAccept(buybackDetail)
        .toString()
        .concat(" days"),
      display: true,
    },
    {
      name: "Eligible Employees",
      total: _buybackStateData?.length,
      display: true,
    },
    {
      name: "Accepted/Rejected",
      total: `${
        buybackDetail?.state?.toLowerCase() !== "draft"
          ? `${totalAcceptedOffersByEmployee} / ${totalUnacceptedOffersByEmployee}`
          : "Event yet to start"
      }`,
      display: true,
    },
    {
      name: ` Total ${
        buybackDetail?.transactionType === "buyback" ? "Cash out" : "Amount"
      } `,
      total: `${formatCurrency(
        getTotalCashOut() *
          (buybackDetail?.transactionType === "buyback"
            ? buybackDetail?.price || 0
            : buybackDetail?.sellingPrice || 0),
        currency
      )}`,
      display: true,
    },
    {
      name: "amount payable by Employees",
      total: `${formatCurrency(getTotalPriceToPay(), currency)}`,
      display: true,
    },
    {
      name: "Net payout",
      total: `${formatCurrency(
        getTotalCashOut() *
          (buybackDetail?.transactionType === "buyback"
            ? buybackDetail?.price || 0
            : buybackDetail?.sellingPrice || 0) -
          getTotalPriceToPay(),
        currency
      )}`,
      display: true,
    },
    {
      name: "Accepted Options / Eligible Options",
      total: `${getTotalWillingNess().toLocaleString(
        currency
      )} / ${getTotalEligibility().toLocaleString(currency)}`,
      display: true,
    },
  ];

  return (
    <VStack className={`${isPlaceholderData ? "loading" : ""} bg-[#fefefe]`}>
      <div className="flex px-6 py-4 font-semibold text-md text-gray-dark">
        <h6>Employee Details</h6>
      </div>
      <div className="flex items-center p-4 rounded drop-shadow-md">
        <CardContainer cards={empDetail} />
      </div>
    </VStack>
  );
}

export default BuybackEmployeeDetails;

export const CardContainer = (props: any) => (
  <div className="flex items-center py-2 cards-container ">
    {props.cards
      .filter((item: any) => item.display === true)
      .map((card: any, index: number) => (
        <HStack
          key={index}
          className="items-center w-full gap-4 mx-2 border rounded cursor-pointer card whitespace-nowrap drop-shadow-sm"
        >
          <Card title={card.name} content={card.total} />
        </HStack>
      ))}
  </div>
);

export const Card = (props: any) => (
  <VStack className="items-center gap-4 px-12 py-8 font-medium text-center text-black text-xs3 ">
    <p className="capitalize">{props.title}</p>
    <p className="font-normal whitespace-nowrap ">{props.content}</p>
  </VStack>
);
