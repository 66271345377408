import { Autocomplete, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { CompanyDetailsPostModel } from "../../types/OnBoardDataModel";

export type OptionListModel = {
  dropDownOptions: string[];
  feild: string;
  selectedOptions?: string[];
};

export default function MultipleDropDown({
  dropDownOptions,
  feild,
  selectedOptions,
}: OptionListModel) {
  const formik = useFormikContext<CompanyDetailsPostModel>();
  const { setFieldValue } = formik;

  return (
    <Autocomplete
      multiple
      options={dropDownOptions}
      onChange={(_, newOptions: string[]) => {
        setFieldValue(feild, newOptions);
      }}
      filterSelectedOptions
      defaultValue={selectedOptions}
      renderInput={(params) => (
        <div className=" w-120 bg-[#F6F9FB]">
          <TextField
            {...params}
            className="text-sm font-normal"
            hiddenLabel
            variant="standard"
            type="search"
            placeholder="Select Value"
            InputProps={{
              disableUnderline: true,
              ...params.InputProps,
            }}
          />
        </div>
      )}
    />
  );
}
