import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  Box,
  ButtonPrimary1,
  redirectToHissa,
  VStack,
} from "../../components/utils";
import { useGetUserDetails } from "../../queries/onBoarding";

import BCHeader from "../../shared/BCHeader";

function VerificationPage(): React.ReactElement {
  const { data: _userDetail } = useGetUserDetails();
  const userDetail = _userDetail;
  const navigate = useNavigate();
  let companyName: string | null = "";

  useEffect(() => {
    companyName = localStorage.getItem("companyName");
    if (
      userDetail?.isAllowedAccess &&
      userDetail?.isVerified &&
      userDetail.searchedCompany === companyName
    ) {
      toast("Verified Successfully", {
        type: "success",
        autoClose: 2000,
      });
      navigate("/onboarding/companyDetail");
    }
  });

  return (
    <>
      <div className="w-11/12 m-auto min-h-screen">
        <div className="px-0 text-lg font-medium py-7">
          <h1>Onboarding</h1>
        </div>
        <BCHeader
          className="items-baseline mb-4"
          bcTitle="OnBoarding"
          bcSubTitle="Admin Profile"
        />
        <div className="w-150 container m-auto bg-white rounded-md">
          <Box className="px-10 text-lg text-center font-medium border-b py-7">
            <h6>Account Confirmation</h6>
          </Box>
          <VStack className="w-full px-10 py-7 gap-10 items-center">
            <p className="text-xl  font-medium">Welcome to Hissa</p>
            <p className="text-md text-center">
              Thank you for signing up for your new account at Hissa.You will
              get the confirmation mail once the admin verify your account.
            </p>

            <ButtonPrimary1
              type="reset"
              className="text-red-500 mt-12 mr-8 font-medium text-sm"
              onClick={(e) => {
                redirectToHissa("landing/dashboard/capTable");
              }}
            >
              Go to Dashboard
            </ButtonPrimary1>
          </VStack>
        </div>
      </div>
    </>
  );
}

export default VerificationPage;
