import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { Input, Label } from "../../components/shared/InputField";
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  Center,
  Error,
  HStack,
  isAdminViewer,
  isEsopViewer,
  VStack,
} from "../../components/utils";
import { AddEmployeeReq, Employee, PictureObject } from "../../types/Employee";
import {
  useEditEmployee,
  useEmployeePageDetails,
  useEmployees,
  useGenerateCredentials,
} from "../../queries/employees";
import { formatDate, formatWithTimeZone } from "../../utils/date";
import { useError } from "../../store/errorStore";
import convertToBase64 from "../../utils/convertToBase64";
import { Select } from "../../components/shared/Select";
import useIsMobile from "../../utils/detectDevice";
import { useAuthorizationStore } from "../../store/useAuthorizationStore";

export const initialPictureValues: PictureObject = {
  picture: "",
  fileName: "",
  fileType: "",
};

function Profile() {
  const { authority } = useAuthorizationStore();
  const [profilePicture, setProfilePicture] =
    useState<PictureObject>(initialPictureValues);
  const [disableState, setDisabledState] = useState(false);
  const { id } = useParams();
  const _id = id || "";
  const errorMessage = useError();
  const { data: employeePageDetail } = useEmployeePageDetails(
    parseInt(_id, 10)
  );
  const isUserAdminViewer = isAdminViewer();
  const isUserEsopViewer = isEsopViewer();
  useEffect(() => {
    if (employeePageDetail?.status !== "Employed") setDisabledState(true);
  }, [employeePageDetail]);
  useEffect(() => {
    setimageUploadURL("");
  }, [id]);
  const { mutate: addEmployee, isLoading } = useEditEmployee();
  const [imageUploadURL, setimageUploadURL] = useState(
    employeePageDetail && employeePageDetail?.imageURL
  );
  const initialValues: AddEmployeeReq = {
    employeeName: employeePageDetail?.employeeName || "",
    employeeDesignation: employeePageDetail?.designation || "",
    contactNumber:
      employeePageDetail?.contactNumber &&
      parseInt(employeePageDetail?.contactNumber || "0", 10) > 0
        ? parseInt(employeePageDetail?.contactNumber || "0", 10)
        : undefined,
    department: employeePageDetail?.department || "",
    dateOfJoin: formatDate(
      new Date(employeePageDetail?.dateOfJoin || "2001-1-1").toUTCString() ||
        new Date("2001-1-1").toUTCString()
    ),
    imageURL: employeePageDetail?.imageURL,
    pan: employeePageDetail?.pan || "",
    id: employeePageDetail?.id || 0,
    address: employeePageDetail?.address || "",
    email: employeePageDetail?.emailAddress || "",
    manager: employeePageDetail?.manager || "",
    residentialStatus: employeePageDetail?.residentStatus || "",
    legalName: employeePageDetail?.employeeName || "",
    nationality: employeePageDetail?.nationality || "",
    grade: employeePageDetail?.grade || "",
    employeeIdentification: employeePageDetail?.employeeIdentification || "",
    employmentStatus: employeePageDetail?.status || "",
    company: employeePageDetail?.employerName || "",
    employmentType: employeePageDetail?.employmentType || "",
    lastDay:
      employeePageDetail?.status === "Resigned"
        ? formatDate(employeePageDetail?.lastDay) || undefined
        : undefined,
    notes: employeePageDetail?.notes || "",
    profilePicture: employeePageDetail?.profilePicture || initialPictureValues,
    employeeImagePath: employeePageDetail?.imageURL,
  };
  const validationSchema = Yup.object().shape({
    employeeName: Yup.string()
      .required("Name is required")
      .min(3, "Minimum 3 letter"),
    contactNumber: Yup.number(),
    dateOfJoin: Yup.string().required("Date of joining is required"),
    employeeIdentification: Yup.string().required(
      "Employee Identification ID is required"
    ),
    employeeDesignation: Yup.string(),
    department: Yup.string().required("Department is required"),
    employmentType: Yup.string().required("Employment Type is required"),
  });

  const handleFormSubmit = (values: AddEmployeeReq) => {
    const editEmployeeDto = { ...values };
    editEmployeeDto.dateOfJoin = formatWithTimeZone(editEmployeeDto.dateOfJoin);
    if (
      editEmployeeDto.employmentStatus === "Resigned" &&
      editEmployeeDto.lastDay !== undefined
    ) {
      editEmployeeDto.lastDay = formatWithTimeZone(
        editEmployeeDto.lastDay || "2001-1-1"
      );
    }
    editEmployeeDto.profilePicture = profilePicture;
    editEmployeeDto.email = editEmployeeDto.email.trim();
    addEmployee(editEmployeeDto, {
      onSuccess: () => {
        toast("Employee Details Edited Successfully!", { type: "success" });
      },
      onError: (err: any) => {
        errorMessage.setMessage(err.response.data.reason);
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
      },
    });
  };
  async function handleEditImage(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];

    const acceptedFiles = ["image/png", "image/jpg", "image/jpeg"];
    if (!acceptedFiles.includes(file.type)) {
      toast.error("Please upload image file");
      return;
    }
    const acceptedExtensions = [".png", "jpeg", ".jpg"];
    const accept = acceptedExtensions.some((e: string) =>
      file.name.endsWith(e)
    );
    if (!accept) {
      toast.error("Please upload image file");
      return;
    }

    const base64 = await convertToBase64(file);
    setimageUploadURL(URL.createObjectURL(e.target.files[0]));
    if (
      typeof base64 === "string" &&
      typeof file.type === "string" &&
      typeof file.name === "string"
    ) {
      setProfilePicture({
        picture: base64,
        fileType: file.type,
        fileName: file.name,
      });
    }
  }
  const { isMobile } = useIsMobile();
  return (
    <>
      <Box className="my-6 bg-white">
        <HStack className="items-baseline justify-between p-6">
          <Box className="py-2 text-lg font-semibold text-gray-600 ">
            Profile Details
          </Box>
        </HStack>
        <Box className="w-full border-b"></Box>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values) => {
            handleFormSubmit(values);
          }}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <>
              <Form>
                <Box
                  className={`${
                    isMobile
                      ? "m-6"
                      : "flex-1 m-6 flex-row md:justify-start md:flex"
                  }`}
                >
                  <Label className="justify-start px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                    Avatar
                  </Label>
                  <VStack className="gap-4">
                    <Box className="relative w-32">
                      <Label>
                        <input
                          type="file"
                          accept="image/png, image/jpg, image/jpeg"
                          className="hidden"
                          disabled={disableState}
                          onChange={handleEditImage}
                        />
                        <Icon
                          icon={"eva:edit-outline"}
                          className={`${
                            disableState ? "cursor-not-allowed" : ""
                          } absolute z-10 w-6 h-6 text-gray-400 bg-gray-100 rounded-full cursor-pointer -right-3 hover:text-black`}
                        />
                      </Label>
                      {employeePageDetail?.imageURL === "" ? (
                        <Center className="relative w-32 h-32 bg-gray-100 ">
                          <Center className="flex w-32 h-32 rounded-full ">
                            <p className="text-base font-bold ">
                              {formik.values.employeeName.charAt(0)}
                            </p>
                          </Center>
                        </Center>
                      ) : (
                        <img
                          className="block w-32 h-32 space-y-3 bg-gray-400 border-2 rounded-lg"
                          src={imageUploadURL || employeePageDetail?.imageURL}
                          alt="avatar"
                        />
                      )}

                      <Icon
                        icon={"eva:close-fill"}
                        onClick={() => {
                          setProfilePicture(initialPictureValues);
                          setimageUploadURL("");
                        }}
                        className={`${
                          disableState ? "cursor-not-allowed" : ""
                        } absolute z-10 w-6 h-6 text-gray-400 bg-gray-100 rounded-full cursor-pointer -right-3 -bottom-3 hover:text-black`}
                      />
                    </Box>
                    <Box className="text-gray-400">
                      Allowed file types: png, jpg, jpeg.
                    </Box>
                  </VStack>
                </Box>
                <Box
                  className={`${
                    isMobile
                      ? "m-6"
                      : "flex-1 m-6 flex-row md:justify-start md:flex"
                  }`}
                >
                  <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                    Name
                  </Label>
                  <Box>
                    <Input
                      className={`${isMobile ? "" : "w-96"} ${
                        disableState ? "cursor-not-allowed" : ""
                      } text-xs1`}
                      type="text"
                      disabled={disableState}
                      placeholder="Enter Name"
                      {...formik.getFieldProps("employeeName")}
                    />
                    {formik.touched.employeeName &&
                      formik.errors.employeeName && (
                        <Error text={formik.errors.employeeName} />
                      )}
                  </Box>
                </Box>
                <Box
                  className={`${
                    isMobile
                      ? "m-6"
                      : "flex-1 m-6 flex-row md:justify-start md:flex"
                  }`}
                >
                  <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                    Email ID
                  </Label>
                  <Box>
                    <Input
                      className={`${isMobile ? "" : "w-96"} ${
                        !(
                          authority === "ROLE_ADMIN" ||
                          authority === "ROLE_ESOP_ADMIN"
                        )
                          ? "cursor-not-allowed"
                          : ""
                      } text-xs1`}
                      type="text"
                      placeholder="Email ID"
                      disabled={
                        !(
                          authority === "ROLE_ADMIN" ||
                          authority === "ROLE_ESOP_ADMIN"
                        )
                      }
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <Error text={formik.errors.email} />
                    )}
                  </Box>
                </Box>
                <Box
                  className={`${
                    isMobile
                      ? "m-6"
                      : "flex-1 m-6 flex-row md:justify-start md:flex"
                  }`}
                >
                  <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                    Mobile
                  </Label>
                  <Box>
                    <Input
                      className={`${isMobile ? "" : "w-96"} ${
                        disableState ? "cursor-not-allowed" : ""
                      } text-xs1`}
                      type="text"
                      disabled={disableState}
                      placeholder="Contact Number"
                      {...formik.getFieldProps("contactNumber")}
                    />
                    {formik.touched.contactNumber &&
                      formik.errors.contactNumber && (
                        <Error text={formik.errors.contactNumber} />
                      )}
                  </Box>
                </Box>
                <Box
                  className={`${
                    isMobile
                      ? "m-6"
                      : "flex-1 m-6 flex-row md:justify-start md:flex"
                  }`}
                >
                  <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                    PAN
                  </Label>
                  <Input
                    className={`${isMobile ? "" : "w-96"} ${
                      disableState ? "cursor-not-allowed" : ""
                    } text-xs1 `}
                    type="text"
                    disabled={disableState}
                    placeholder="PAN Number"
                    {...formik.getFieldProps("pan")}
                  />
                </Box>
                <Box
                  className={`${
                    isMobile
                      ? "m-6"
                      : "flex-1 m-6 flex-row md:justify-start md:flex"
                  }`}
                >
                  <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                    Emp ID
                  </Label>
                  <Box>
                    <Input
                      className={`${isMobile ? "" : "w-96"} ${
                        disableState ? "cursor-not-allowed" : ""
                      } text-xs1`}
                      type="text"
                      placeholder="Employee ID"
                      disabled={disableState}
                      {...formik.getFieldProps("employeeIdentification")}
                    />
                    {formik.touched.employeeIdentification &&
                      formik.errors.employeeIdentification && (
                        <Error text={formik.errors.employeeIdentification} />
                      )}
                  </Box>
                </Box>
                <Box
                  className={`${
                    isMobile
                      ? "m-6"
                      : "flex-1 m-6 flex-row md:justify-start md:flex"
                  }`}
                >
                  <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                    Employee Type
                  </Label>
                  <Box>
                    <Select
                      className={`text-xs1 ${isMobile ? "" : "w-96"}
                      ${disableState ? "cursor-not-allowed" : ""}`}
                      options={["EMPLOYEE", "CONSULTANT"]}
                      disabled={disableState}
                      {...formik.getFieldProps("employmentType")}
                    />
                    {formik.touched.employmentType &&
                      formik.errors.employmentType && (
                        <Error text={formik.errors.employmentType} />
                      )}
                  </Box>
                </Box>

                <Box
                  className={`${
                    isMobile
                      ? "m-6"
                      : "flex-1 m-6 flex-row md:justify-start md:flex"
                  }`}
                >
                  <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                    Date of Joining
                  </Label>
                  <Box>
                    <Input
                      className={`${isMobile ? "" : "w-96"} ${
                        disableState ? "cursor-not-allowed" : ""
                      } text-xs1`}
                      type="date"
                      disabled={disableState}
                      placeholder="Select Date"
                      {...formik.getFieldProps("dateOfJoin")}
                    />
                    {formik.touched.dateOfJoin && formik.errors.dateOfJoin && (
                      <Error text={formik.errors.dateOfJoin} />
                    )}
                  </Box>
                </Box>
                {employeePageDetail?.status !== "Employed" && (
                  <Box
                    className={`${
                      isMobile
                        ? "m-6"
                        : "flex-1 m-6 flex-row md:justify-start md:flex"
                    }`}
                  >
                    <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                      Last Working day
                    </Label>
                    <Box>
                      <Input
                        className={` ${isMobile ? "" : "w-96"} ${
                          disableState ? "cursor-not-allowed" : ""
                        } text-xs1`}
                        type="date"
                        disabled={disableState}
                        placeholder="Select Date"
                        {...formik.getFieldProps("lastDay")}
                      />
                      {formik.touched.lastDay && formik.errors.lastDay && (
                        <Error text={formik.errors.lastDay} />
                      )}
                    </Box>
                  </Box>
                )}
                <Box
                  className={`${
                    isMobile
                      ? "m-6"
                      : "flex-1 m-6 flex-row md:justify-start md:flex"
                  }`}
                >
                  <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                    Designation
                  </Label>
                  <Box>
                    <Input
                      className={` ${isMobile ? "" : "w-96"} ${
                        disableState ? "cursor-not-allowed" : ""
                      } text-xs1`}
                      type="text"
                      disabled={disableState}
                      placeholder="Employee Designation"
                      {...formik.getFieldProps("employeeDesignation")}
                    />
                    {formik.touched.employeeDesignation &&
                      formik.errors.employeeDesignation && (
                        <Error text={formik.errors.employeeDesignation} />
                      )}
                  </Box>
                </Box>
                <Box
                  className={`${
                    isMobile
                      ? "m-6"
                      : "flex-1 m-6 flex-row md:justify-start md:flex"
                  }`}
                >
                  <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                    Department
                  </Label>
                  <Box>
                    <Input
                      className={` ${isMobile ? "" : "w-96"} ${
                        disableState ? "cursor-not-allowed" : ""
                      } text-xs1`}
                      type="text"
                      disabled={disableState}
                      placeholder="Enter Department Name"
                      {...formik.getFieldProps("department")}
                    />
                    {formik.touched.department && formik.errors.department && (
                      <Error text={formik.errors.department} />
                    )}
                  </Box>
                </Box>
                <Box className="w-full border-b"></Box>
                <Box
                  className={`${
                    isMobile ? "justify-center" : "justify-end"
                  } flex flex-row `}
                >
                  <ButtonSecondary
                    disabled={isUserAdminViewer || isUserEsopViewer}
                    onClick={() => formik.resetForm()}
                    className="m-2 "
                  >
                    Discard
                  </ButtonSecondary>
                  <ButtonPrimary
                    type="submit"
                    className="m-2"
                    disabled={isUserAdminViewer || isUserEsopViewer}
                  >
                    {!isLoading ? (
                      `Save changes`
                    ) : (
                      <Icon
                        className="animate-spin"
                        icon="lucide:loader-2"
                        width={36}
                      />
                    )}
                  </ButtonPrimary>
                </Box>
              </Form>
            </>
          )}
        </Formik>
      </Box>
    </>
  );
}
export default Profile;
