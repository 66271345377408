import React, { useEffect, useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import * as _ from "lodash";
import { useFormikContext } from "formik";
import { toast } from "react-toastify";
import {
  Box,
  ButtonPrimary,
  Center,
  HStack,
  VStack,
  Error,
} from "../../components/utils";
import { Input } from "../../components/shared/InputField";
import { globalFilter, sort } from "../../utils/arrays";
import BasicMenu from "../../components/shared/Menu";
import Pagination from "../../components/shared/Pagination";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { EsopPool, HistoricalRoundModel } from "../../types/HistoricDataModel";
import { formatDate, formatWithTimeZone } from "../../utils/date";

export interface InputFieldFormModel {
  poolSize?: number;
  date?: string;
  poolSizeTouched?: boolean;
  dateTouched?: boolean;
}

export default function EsopPoolTable() {
  const formik = useFormikContext<HistoricalRoundModel>();
  const { values, setFieldValue } = formik;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [globalFilterText, setGlobalFilterText] = useState("");

  const [inputField, setInputField] = useState<InputFieldFormModel>({
    poolSizeTouched: false,
    dateTouched: false,
  });

  const [showInline, setShowInline] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(values.esopPool.length);

  const [sortField, setSortField] = useState<{
    field: keyof EsopPool | "" | undefined;
    ascending: boolean;
  }>({ field: "date", ascending: false });

  const [selectedItems, setSelectedItems] = useState<EsopPool[]>([]);
  let esopList: EsopPool[] = values.esopPool || [];

  esopList = useMemo(() => {
    if (!esopList) return [];
    const filterResult = globalFilter(esopList, globalFilterText, [
      "poolSize",
      "date",
    ]);
    const sortResult = sort(
      filterResult,
      sortField?.field,
      sortField?.ascending
    );
    return sortResult;
  }, [esopList, globalFilterText, sortField]);

  const onSelectionChange = (esopPool: EsopPool) => {
    setSelectedItems((esop) =>
      esop.find((individualEsop) => individualEsop === esopPool)
        ? esop.filter((individualEsop) => individualEsop !== esopPool)
        : [...esop, esopPool]
    );
  };

  const handleSave = (esopPool: EsopPool) => {
    if (esopPool.date)
      setFieldValue(
        `esopPool[${index}].date`,
        formatWithTimeZone(esopPool.date)
      );
    setFieldValue(`esopPool[${index}].poolSize`, esopPool.poolSize);
    setFieldValue(`esopPool[${index}].orderNumber`, null);
  };

  useEffect(() => {
    if (selectAllChecked) {
      setSelectedItems(esopList);
    } else {
      setSelectedItems([]);
    }
  }, [esopList, selectAllChecked]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return esopList.slice(firstPageIndex, lastPageIndex);
  }, [esopList, currentPage, pageSize]);

  function handleAction(action: Action, esop?: EsopPool) {
    if (action.name === "Edit" && !action.disabled) {
      setIndex(values.esopPool.findIndex((esopPool) => esop === esopPool));
      setInputField({
        poolSize: esop?.poolSize,
        date: esop?.date,
      });
      setShowInline(true);
    } else if (action.name === "Delete" && !action.disabled) {
      deleteEsop(esop);
    }
  }

  const deleteAllSelectedEsop = () => {
    setFieldValue(
      "esopPool",
      values?.esopPool?.filter((esop) => !selectedItems.includes(esop))
    );
    setSelectAllChecked(false);
  };

  function deleteEsop(esop?: EsopPool) {
    setFieldValue(
      "esopPool",
      values?.esopPool?.filter((individualEsop) => individualEsop !== esop)
    );
    toast("Esop Deleted Successfully!", {
      type: "success",
      autoClose: 2000,
    });
    setShowInline(false);
  }

  return (
    <div className="w-full bg-white rounded-md mt-10">
      <Box className="w-full p-4 bg-white rounded-lg min-w-min">
        <HStack aria-label="toolbar" className="justify-between mb-8">
          <VStack className="min-w-max">
            <p className="text-lg font-medium text-gray-dark">Esop Pool</p>
            <p className="text-xs font-medium text-gray-light">
              {values.esopPool.length} Results
            </p>
          </VStack>
          <HStack className="h-11 min-w-min">
            {selectedItems.length > 0 && (
              <Center className="p-2 px-3 mx-2 rounded text-slate-dark bg-slate-light">
                <Icon
                  icon="fluent:delete-28-regular"
                  color="#E85936"
                  className="rounded-xl"
                  onClick={deleteAllSelectedEsop}
                  width="20"
                  height="24"
                />
              </Center>
            )}
            <HStack className="items-center p-2 rounded w-72 bg-slate-light text-slate-dark mx-2">
              <Icon icon="fe:search" width="24" className="mr-2 " />
              <input
                type="text"
                className="w-full font-medium border-0 outline-none text-xs2 bg-inherit"
                placeholder="Search"
                value={globalFilterText}
                onChange={(e) => {
                  setGlobalFilterText(e.target.value);
                }}
              ></input>
            </HStack>
            <ButtonPrimary
              className="min-w-max"
              onClick={() => {
                setIndex(values.esopPool.length);
                setInputField({
                  poolSize: undefined,
                  date: undefined,
                });

                setShowInline(true);
              }}
            >
              Add
            </ButtonPrimary>
          </HStack>
        </HStack>
        <Box className="overflow-auto max-h-full">
          <table className="w-full table-space">
            <thead className="text-xs font-medium text-gray-light">
              <tr className="">
                <td className="py-3 w-12 mr-4">
                  <input
                    type="checkbox"
                    className="accent-orange-501 w-12 outline-hidden"
                    checked={selectAllChecked}
                    onChange={(e) => setSelectAllChecked(!selectAllChecked)}
                  ></input>
                </td>

                <td
                  className="py-3 w-72 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "poolSize",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Share Alloted
                </td>

                <td
                  className="py-3 w-72 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "date",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Date
                </td>

                <td className="py-3 w-56"></td>
              </tr>
            </thead>
            <tbody className={""}>
              {values.esopPool && showInline && (
                <tr>
                  <td className="py-3 w-12 mr-4">
                    <input
                      type="checkbox"
                      className="accent-orange-501 w-12 outline-hidden"
                      hidden
                      checked={selectAllChecked}
                      onChange={(e) => setSelectAllChecked(!selectAllChecked)}
                    ></input>
                  </td>

                  <td className="py-3  w-72">
                    <Box>
                      <Input
                        type="number"
                        placeholder="Enter Esop Pool"
                        className={"w-72"}
                        value={inputField.poolSize}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setInputField((state) => ({
                            ...state,
                            poolSize: parseInt(e.target.value, 10),
                          }))
                        }
                      />
                      {!inputField.poolSize && inputField.poolSizeTouched && (
                        <Error text={"Esop Pool is Required"} />
                      )}
                    </Box>
                  </td>
                  <td className="py-3  w-72">
                    <Input
                      type="date"
                      placeholder="Date"
                      className={"w-72"}
                      value={inputField.date}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setInputField((state) => ({
                          ...state,
                          date: e.target.value,
                        }));
                      }}
                    />
                    {!inputField.date && inputField.dateTouched && (
                      <Error text={"Date is Required"} />
                    )}
                  </td>
                  <td className="px-2 w-56 py-4 align-top">
                    <HStack>
                      <Center className="p-2 px-3 mx-2 rounded text-slate-dark bg-slate-light">
                        <Icon
                          icon="fluent:delete-28-regular"
                          color="#E85936"
                          type="reset"
                          className="rounded-xl"
                          onClick={() => {
                            setShowInline(false);
                          }}
                          width="20"
                          height="24"
                        />
                      </Center>
                      <ButtonPrimary
                        onClick={() => {
                          if (inputField.date && inputField.poolSize) {
                            handleSave({
                              poolSize: inputField.poolSize,
                              date: inputField.date,
                            });

                            setShowInline(false);
                          } else {
                            setInputField((state) => ({
                              ...state,
                              poolSizeTouched: true,
                              dateTouched: true,
                            }));
                          }
                        }}
                      >
                        Save
                      </ButtonPrimary>
                    </HStack>
                  </td>
                </tr>
              )}
              {values.esopPool &&
                currentTableData?.map((esopPool, index) => (
                  <tr
                    key={`${index}${esopPool.date}`}
                    className="border-t border-dashed "
                  >
                    <td className="py-5 w-12 mr-4 align-top">
                      <Box>
                        <input
                          type="checkbox"
                          className="accent-orange-501 w-12 outline-hidden"
                          checked={selectedItems.indexOf(esopPool) !== -1}
                          onChange={(e) => onSelectionChange(esopPool)}
                        ></input>
                      </Box>
                    </td>

                    <td className="py-4 w-72 align-top ">
                      <HStack>
                        <Box>
                          <p className={` text-xs font-medium text-gray-dark `}>
                            {esopPool.poolSize}
                          </p>
                        </Box>
                      </HStack>
                    </td>

                    <td className="py-4 w-32 align-top ">
                      <HStack className="w-32 ">
                        <Box>
                          <p className={` text-xs font-medium text-gray-dark `}>
                            {formatDate(esopPool.date)}
                          </p>
                        </Box>
                      </HStack>
                    </td>
                    <td className="px-2 w-56 py-4 align-top">
                      <CTADropdown
                        actions={[
                          {
                            name: "Edit",
                            disabled: false,
                          },
                          {
                            name: "Delete",
                            disabled: false,
                          },
                        ]}
                        onAction={(action) => handleAction(action, esopPool)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {values.esopPool.length <= 0 && !showInline && (
            <div className="text-black text-center">
              <h1>No Esop Pool Found, Please Add the Esops</h1>
            </div>
          )}
        </Box>
        <Box className="flex justify-between mt-8">
          <BasicMenu
            defaultValue={pageSize}
            options={[5, 10, 20, 50, 100]}
            onOptionChange={(value) => setPageSize(value)}
          />
          <Pagination
            className=""
            currentPage={currentPage}
            totalCount={esopList.length}
            pageSize={pageSize}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </Box>
      </Box>
    </div>
  );
}
