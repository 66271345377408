import { Icon } from "@iconify/react";
import Dialog from "@mui/material/Dialog";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { HStack, redirectToHissa, VStack } from "../components/utils";
import { useAuthorizationStore, useAuthStore } from "../store";
import useIsMobile from "../utils/detectDevice";
import SwitchProduct from "./SwitchProduct";

interface SideNavItemType {
  name: string;
  permissions: Array<string>;
  icon: string;
  path: string;
  useCurrentPortal?: boolean;
  enabledForEsopOnlyCompany?: boolean;
  enabled?: boolean;
}
function SideNavbar({ setDialogOpen }: any) {
  const { authority, permissions: actualPermissions } = useAuthorizationStore();

  const { user } = useAuthStore();

  const sideNavItemsMain: Array<SideNavItemType> = [
    {
      name: "Options",
      permissions: [
        "ROLE_ADMIN",
        "ADMIN_VIEWER",
        "ROLE_OPTIONS_VIEWER",
        "ROLE_OPTIONS_CREATOR",
        "ROLE_OPTIONS_APPROVER",
        "ROLE_ESOP_ADMIN",
        "ROLE_ESOP_VIEWER",
      ],
      icon: "bx:list-ul",
      path: "/options",
      enabledForEsopOnlyCompany: true,
      useCurrentPortal: true,
    },
    {
      name: "Settings",
      permissions: ["ROLE_ADMIN", "ROLE_ESOP_ADMIN", "ROLE_ESOP_VIEWER"],
      icon: "ci:settings-filled",
      enabledForEsopOnlyCompany: true,
      path: "/settings",
      useCurrentPortal: true,
    },
  ];
  const isEsopOnlyCompany = user?.company.isEsopOnlyCompany;
  const [routes, setRoutes] = useState(sideNavItemsMain);

  useMemo(() => {
    let sideNavItmes: Array<SideNavItemType> = sideNavItemsMain.filter(
      (sideNavItem) => sideNavItem.permissions.includes(authority)
    );
    sideNavItmes = sideNavItmes.filter((item) => {
      if (item.name === "Settings") {
        return (
          actualPermissions.includes("FULL_ACCESS") ||
          actualPermissions.includes("ADMIN_SAVING_POST") ||
          actualPermissions.includes("ADMIN_SETTINGS_GET")
        );
      }
      return true;
    });
    if (user?.company.isEsopOnlyCompany) {
      sideNavItmes = sideNavItmes.filter(
        (sideNavItem) => sideNavItem.enabledForEsopOnlyCompany
      );
    }
    setRoutes(sideNavItmes);
  }, [isEsopOnlyCompany, authority]);
  const { isMobile } = useIsMobile();
  return (
    <VStack className={`h-full side-navigation`}>
      {!isMobile && <CompanyLogo setDialogOpen={setDialogOpen} />}
      <div className="overflow-y-auto justify-self-auto">
        {routes.map((sideNavItem) => (
          <SideNavItem key={sideNavItem.name} sideNavItem={sideNavItem} />
        ))}
      </div>
    </VStack>
  );
}
export function CompanyLogo({ setDialogOpen }: any) {
  const { isMobile } = useIsMobile();
  return (
    <HStack
      className={`sticky -ml-2 top-0 p-[9px] items-center gap-[4px] ${
        isMobile ? "h-10" : "pb-6"
      } `}
    >
      <HStack className=" items-center rounded-sm hover:bg-orange-501 hover:bg-opacity-70 hover:cursor-pointer pl-0.5 pr-0.5 pt-0 pb-0">
        <Icon
          icon="mdi:dots-grid"
          color="white"
          height={32}
          width={24}
          onClick={() => setDialogOpen(true)}
        />
      </HStack>
      <img
        className="w-[56px] h-8"
        src="https://equion-dev.s3.us-west-2.amazonaws.com/logonew.svg"
        alt="hissa_logo"
      />
    </HStack>
  );
}
function SideNavItem({ sideNavItem }: { sideNavItem: SideNavItemType }) {
  const match = useMatch(`/${sideNavItem.path.split("/")[1]}/*`);
  const navigate = useNavigate();
  return (
    <HStack className="pt-1 pb-1" key={sideNavItem.name}>
      <div
        onClick={() =>
          sideNavItem.useCurrentPortal
            ? navigate(sideNavItem.path)
            : redirectToHissa(sideNavItem.path)
        }
        className={`pt-2 rounded-md mx-auto relative text-center hover:bg-orange-501 hover:bg-opacity-70 hover:cursor-pointer h-16 w-20 flex flex-col space-y-2 ${
          match ? "bg-orange-501" : null
        }`}
      >
        <Icon
          className={`mx-auto ${match ? "text-white" : "text-orange-501"}`}
          height={18}
          width={18}
          icon={sideNavItem.icon}
        ></Icon>
        <span className="font-normal text-white text-xs1 poppins-font font-xxs">
          {sideNavItem.name}
        </span>
      </div>
    </HStack>
  );
}
export default SideNavbar;
