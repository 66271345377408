import React, { useEffect, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import { ChartData } from "chart.js";
import { Icon } from "@iconify/react";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
} from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import { useEsopModellingStore } from "../../store/esopModellingStore";
import { Select } from "../../components/shared/Select";
import { Input, Label } from "../../components/shared/InputField";
import ExistingEmployees from "./ExistingEmployees";
import CreateCompensationTemplate from "./CompensationTemplate";
import HiringPlansModule from "./HiringPlans";
import Valuation from "./Valuation";
import SpecialGrantsTab from "./SpecialGrants";
import Summary from "./Summary";
import {
  useGetAllCompensationTemplates,
  useGetAllHiringPlans,
  useGetAllModels,
} from "../../queries/esopModel";
import EsopForecast from "../../shared/esop-forecast";
import ESOPHoldingPercentage from "../../shared/esop-holding-percentage";
import { _sum } from "../../utils/arrays";
import {
  CompensationTemplateReq,
  EsopModelReq,
  HiringPlanReq,
} from "../../types/EsopModelling";

function EsopModelling() {
  const [activeTab, setActiveTab] = useState<string>("Compensation Template");
  const [activeGraph, setActiveGraph] = useState<string>("Percentage Holding");
  const data = useEsopModellingStore();
  const { data: esopModels } = useGetAllModels();
  const { data: _compensationTemplates } = useGetAllCompensationTemplates();
  const { data: _hiringPlans } = useGetAllHiringPlans();
  const compensationTemplatesList = _compensationTemplates?.data || [];
  const hiringPlansList = _hiringPlans?.data || [];
  useEffect(() => {
    compensationTemplatesList.map((template: CompensationTemplateReq) =>
      data.setCompensationTemplate(template.compensationTemplateDetails)
    );
  }, [compensationTemplatesList]);

  useEffect(() => {
    hiringPlansList.map((plan: HiringPlanReq) =>
      data.setHiringPlans(plan.hiringPlanDetails)
    );
  }, [hiringPlansList]);
  const esopModelsList = esopModels?.data || [];
  const [open, setOpen] =
    data.selectedModel === "Add New Model" ? useState(true) : useState(false);

  const percentageHoldingGraphData: ChartData<"line", number[], string> = {
    labels: [
      "Year 0",
      ...data.summary.yearlyDetailsSummary.map((item) => `Year ${item.year}`),
    ],
    datasets: [
      {
        label: "Percentage Holding",
        data: [
          data.summary.additionalCompensation &&
          data.summary.additionalCompensation.length > 0
            ? _sum(data.summary.additionalCompensation, "percentageHolding")
            : 0,
          ...data.summary.yearlyDetailsSummary.map(
            (item) => item.percentageHolding
          ),
        ],
        borderColor: "rgb(255, 99, 132)",
        pointStyle: "line",
      },
    ],
  };

  const pricePerShareGraphData: ChartData<"line", number[], string> = {
    labels: data.summary.yearlyDetailsSummary.map(
      (item) => `Year ${item.year}`
    ),
    datasets: [
      {
        label: "Price Per Share",
        data: data.sharePricePerYear,
        borderColor: "rgb(255, 99, 132)",
        pointStyle: "line",
      },
    ],
  };

  const numberOfOptionsGraphData: ChartData<"line", number[], string> = {
    labels: [
      "Year 0",
      ...data.summary.yearlyDetailsSummary.map((item) => `Year ${item.year}`),
    ],
    datasets: [
      {
        label: "Number of Options",
        data: [
          data.summary.additionalCompensation &&
          data.summary.additionalCompensation.length > 0
            ? _sum(data.summary.additionalCompensation, "noOfOptions")
            : 0,
          ...data.summary.yearlyDetailsSummary.map((item) => item.noOfOptions),
        ],
        borderColor: "rgb(255, 99, 132)",
        pointStyle: "line",
      },
    ],
  };

  function getGraphData() {
    if (activeGraph === "Percentage Holding") {
      return percentageHoldingGraphData;
    } else if (activeGraph === "Number of Options") {
      return numberOfOptionsGraphData;
    } else if (activeGraph === "Price Per Share") {
      return pricePerShareGraphData;
    }
    return percentageHoldingGraphData;
  }

  function handleOnChange(model: string) {
    data.reset();
    data.setSelectedModel(model);
    if (model === "Add New Model") {
      compensationTemplatesList.map((template: CompensationTemplateReq) =>
        data.setCompensationTemplate(template.compensationTemplateDetails)
      );
      hiringPlansList.map((plan: HiringPlanReq) =>
        data.setHiringPlans(plan.hiringPlanDetails)
      );

      setActiveTab("Compensation Template");
      setOpen(true);
    } else {
      setOpen(false);
      const selectedEsopModel = esopModelsList.filter(
        (item: EsopModelReq) => item.modelName === model
      )[0]?.esopModel;
      data.setAdditionalMultiplierFromStore(
        selectedEsopModel.additionalMultiplier
      );
      data.setCurrentSharePrice(selectedEsopModel.currentSharePrice);
      data.setDescription(selectedEsopModel.description);
      data.setExistingEmployeesDetailsFromStore(
        selectedEsopModel.existingEmployeesDetails
      );
      data.setIsAdjustedOptionMulitplier(
        selectedEsopModel.isAdjustedOptionMulitplier
      );
      data.setModelName(selectedEsopModel.modelName);
      data.setNoOfSharesPerYear(selectedEsopModel.noOfSharesPerYear);
      data.setSelectedCompensationTemplate(
        selectedEsopModel.selectedCompensationTemplate
      );
      data.setSelectedHiringPlan(selectedEsopModel.selectedHiringPlan);
      data.setSharePriceMultipliers(selectedEsopModel.sharePriceMultipliers);
      data.setSharePricePerYear(selectedEsopModel.sharePricePerYear);
      data.setSpecialGrants(selectedEsopModel.specialGrants);
      data.setSummary(selectedEsopModel.summary);
      data.setValuation(selectedEsopModel.valuation);
      data.setValuationPerYear(selectedEsopModel.valuationPerYear);
    }
  }

  function handleEdit(modelName: string) {
    setOpen(true);
  }

  function discardModelDetails() {
    data.setModelName("");
    data.setDescription("");
  }

  function saveModelDetails() {}
  return (
    <VStack className="gap-2 bg-gray-100">
      <VStack className="gap-4 bg-gray-100 px-10 py-5">
        <div className="flex items-baseline justify-between">
          <BCHeader
            className="items-baseline "
            bcTitle="Modelling"
            bcSubTitle="ESOP Modelling"
          />
        </div>
        <Box className="my-auto rounded-lg drop-shadow-md">
          <VStack>
            <VStack className="justify-between gap-2 p-4 bg-white">
              <Box className="items-center text-lg font-semibold text-gray-dark pt-6">
                ESOP Modelling
              </Box>
              <p className={` text-sm font-small `}>
                Use ESOP modeling to create multiple ESOP models with different
                compensation structures, grants and hiring plans. The tool will
                accordingly calculate the percentage of stock options to be
                reserved for future grants.
              </p>
            </VStack>
            <VStack className="justify-between p-4 bg-white">
              <Label className="text-sm font-medium">Select a Model</Label>
              <Box className="w-full flex items-start">
                <Select
                  options={[
                    "Add New Model",
                    ...esopModelsList.map(
                      (item: EsopModelReq) => item.modelName
                    ),
                  ]}
                  className="w-72"
                  value={data.selectedModel}
                  onChange={(e) => handleOnChange(e.target.value)}
                />
                {data.selectedModel && data.selectedModel !== "Add New Model" && (
                  <button
                    type="button"
                    onClick={(e) => handleEdit(data.selectedModel)}
                  >
                    {" "}
                    <Icon
                      icon={"eva:edit-outline"}
                      className="m-2 w-6 h-6 text-gray-400 bg-gray-100 rounded-full cursor-pointer hover:text-black"
                    />
                  </button>
                )}
              </Box>
            </VStack>
          </VStack>
        </Box>
      </VStack>
      <VStack className="gap-4 bg-gray-100 px-10 pb-5">
        {open && (
          <Box className="my-auto rounded-lg drop-shadow-md">
            <VStack className="justify-between gap-2 p-4 bg-white">
              <Box className="items-center text-lg font-medium pt-4">
                New ESOP Model
              </Box>
              <HStack className="justify-start">
                <p className={` form-label text-sm font-medium w-1/3 pt-4 `}>
                  Model Name
                </p>
                <Box className="w-1/3 flex items-start">
                  <Input
                    type="text"
                    placeholder="Model Name"
                    className="w-48"
                    value={data.modelName}
                    onChange={(e) => data.setModelName(e.target.value)}
                  />
                </Box>
              </HStack>
              <HStack className="justify-start">
                <p className={` form-label text-sm font-medium w-1/3 pt-4 `}>
                  Description
                </p>
                <Box className="w-1/3 flex items-start">
                  <Input
                    type="text"
                    placeholder="Description"
                    className="w-96"
                    value={data.description}
                    onChange={(e) => data.setDescription(e.target.value)}
                  />
                </Box>
              </HStack>
              <HStack className="justify-end">
                <ButtonPrimary1
                  type="reset"
                  className="mr-8 text-red-500"
                  onClick={() => {
                    discardModelDetails();
                  }}
                >
                  Cancel
                </ButtonPrimary1>
                <ButtonPrimary
                  type="submit"
                  onClick={() => {
                    saveModelDetails();
                  }}
                >
                  Save
                </ButtonPrimary>
              </HStack>
            </VStack>
          </Box>
        )}
      </VStack>
      {data.summary.foundingteam.noOfOptions +
        data.summary.advisors.noOfOptions +
        _sum(data.summary.yearlyDetailsSummary, "noOfOptions") +
        data.summary.performance.noOfOptions +
        _sum(data.summary.refresh, "noOfOptions") >
        0 && (
        <HStack className="gap-2 rounded-lg">
          <div className="w-1/4">
            <EsopForecast />
          </div>
          <div className="w-1/4">
            <ESOPHoldingPercentage />
          </div>
          <div className="w-2/4">
            <VStack className={`p-12 bg-white rounded-lg max-h-96 `}>
              <HStack className="justify-between">
                <VStack className="mb-0">
                  <p className="font-medium text-gray-dark">Time Based</p>
                </VStack>
                <VStack>
                  <Select
                    options={[
                      "Percentage Holding",
                      "Number of Options",
                      "Price Per Share",
                    ]}
                    value={activeGraph}
                    onChange={(e) => setActiveGraph(e.target.value)}
                  />
                </VStack>
              </HStack>
              <Line
                className="flex"
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  plugins: {
                    decimation: {
                      enabled: true,
                      samples: 500,
                      algorithm: "lttb",
                    },
                  },
                  scales: {
                    y: {
                      title: {
                        display: true,
                        text: activeGraph,
                        font: {
                          size: 14,
                        },
                      },
                    },
                    x: {
                      title: {
                        display: true,
                        text: "Year",
                        font: {
                          size: 14,
                        },
                      },
                    },
                  },
                }}
                data={getGraphData()}
              />
            </VStack>
          </div>
        </HStack>
      )}
      <Box className="my-auto rounded-lg h-full drop-shadow-md">
        <HStack className="w-full gap-4">
          <Box className="bg-white rounded-lg w-21 min-w-min p-4">
            <Box className="items-center p-4 text-lg font-medium text-gray-dark whitespace-nowrap"></Box>
            <VStack className="justify-between">
              <Box
                className={`cursor-pointer  form-label text-sm font-medium   p-4  whitespace-nowrap ${
                  activeTab === "Compensation Template"
                    ? "rounded text-orange-501"
                    : ""
                }`}
                onClick={() => setActiveTab("Compensation Template")}
              >
                Compensation Template
              </Box>
              <Box
                className={`cursor-pointer  form-label text-sm font-medium   p-4  whitespace-nowrap ${
                  activeTab === "Existing Employees"
                    ? "rounded text-orange-501"
                    : ""
                }`}
                onClick={() => setActiveTab("Existing Employees")}
              >
                Existing Employees
              </Box>
              <Box
                className={`cursor-pointer  form-label text-sm font-medium   p-4  whitespace-nowrap ${
                  activeTab === "Hiring Plans" ? "rounded text-orange-501" : ""
                }`}
                onClick={() => setActiveTab("Hiring Plans")}
              >
                Hiring Plans
              </Box>
              <Box
                className={`cursor-pointer  form-label text-sm font-medium   p-4  whitespace-nowrap ${
                  activeTab === "Valuation" ? "rounded text-orange-501" : ""
                }`}
                onClick={() => setActiveTab("Valuation")}
              >
                Valuation
              </Box>
              <Box
                className={`cursor-pointer  form-label text-sm font-medium   p-4  whitespace-nowrap ${
                  activeTab === "Special Grants"
                    ? "rounded text-orange-501"
                    : ""
                }`}
                onClick={() => setActiveTab("Special Grants")}
              >
                Special Grants
              </Box>
              <Box
                className={`cursor-pointer  form-label text-sm font-medium   p-4  whitespace-nowrap ${
                  activeTab === "Summary" ? "rounded text-orange-501" : ""
                }`}
                onClick={() => setActiveTab("Summary")}
              >
                Summary
              </Box>
            </VStack>
          </Box>
          <Box className="bg-white flex grow">
            {activeTab === "Compensation Template" && (
              <CreateCompensationTemplate
                onNext={() => setActiveTab("Existing Employees")}
              />
            )}
            {activeTab === "Existing Employees" && (
              <ExistingEmployees
                onBack={() => setActiveTab("Compensation Template")}
                onNext={() => setActiveTab("Hiring Plans")}
              />
            )}
            {activeTab === "Hiring Plans" && (
              <HiringPlansModule
                onBack={() => setActiveTab("Existing Employees")}
                onNext={() => setActiveTab("Valuation")}
              />
            )}
            {activeTab === "Valuation" && (
              <Valuation
                onBack={() => setActiveTab("Hiring Plans")}
                onNext={() => setActiveTab("Special Grants")}
              />
            )}
            {activeTab === "Special Grants" && (
              <SpecialGrantsTab
                onBack={() => setActiveTab("Valuation")}
                onNext={() => setActiveTab("Summary")}
              />
            )}
            {activeTab === "Summary" && (
              <Summary onBack={() => setActiveTab("Special Grants")} />
            )}
          </Box>
        </HStack>
      </Box>
    </VStack>
  );
}

export default EsopModelling;
