/* eslint-disable linebreak-style */
import React from "react";

type InputWithLabelProps = {
  label: string;
  errorMessage?: string;
  error?: boolean;
  helperText?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export function InputWithLabel(props: InputWithLabelProps) {
  const { label, error, errorMessage, ...rest } = props;
  const className = `bg-gray-50 border border-gray-500 text-gray-900  placeholder-gray-700  text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 ${props.className}`;
  return (
    <div className="mb-6">
      <label
        htmlFor={props.id}
        className="block mb-2 text-sm font-medium text-dark"
      >
        {label}
      </label>
      <input className={className} {...rest} autoComplete="off" />
      {Boolean(error || errorMessage) && (
        <p className="mt-2 text-sm text-red-600 ">
          <span className="font-medium">Error !</span> {errorMessage}
        </p>
      )}
    </div>
  );
}

type CheckBoxWithLabelProps = {
  label: string;
  labelclassname: string;
  errorMessage?: string;
  error?: boolean;
  helperText?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export function CheckBoxWithLabel(props: CheckBoxWithLabelProps) {
  const { label, error, errorMessage, ...rest } = props;
  const className = props.className;
  const _labelClass = props.labelclassname;
  return (
    <div className="flex flex-row items-center">
      <input type="checkbox" className={className} {...rest} />
      <label htmlFor={props.id} className={` ${_labelClass}`}>
        {label}
      </label>
      {Boolean(error || errorMessage) && (
        <p className="mt-2 text-sm text-red-600 ">
          <span className="font-medium">Error !</span> {errorMessage}
        </p>
      )}
    </div>
  );
}

export function Input(props: React.InputHTMLAttributes<HTMLInputElement>) {
  const { className: _className, placeholder, ...rest } = props;
  const className = `${_className} form-input `;
  return (
    <input
      {...rest}
      className={className}
      placeholder={placeholder}
      onWheel={(e) => e.currentTarget.blur()}
      autoComplete="off"
    ></input>
  );
}
type InputWithTextProps = {
  optionsAvailable?: number;
} & React.InputHTMLAttributes<HTMLInputElement>;

export function InputWithText(props: InputWithTextProps) {
  const {
    className: _className,
    optionsAvailable,
    placeholder,
    ...rest
  } = props;
  const className = `${_className} form-input pr-52 appearance-none`;
  return (
    <div className="relative">
      <input {...rest} className={className} placeholder={placeholder}></input>
      <p className="absolute text-green-500 right-4 top-4">
        {optionsAvailable
          ? `${optionsAvailable?.toLocaleString()} available options`
          : ``}
      </p>
    </div>
  );
}
export function TextArea(
  props: React.TextareaHTMLAttributes<HTMLTextAreaElement>
) {
  const { className: _className, ...rest } = props;
  const className = `${_className} form-input `;
  return (
    <textarea {...rest} className={className} autoComplete="off"></textarea>
  );
}

export function Radio(props: React.InputHTMLAttributes<HTMLInputElement>) {
  const { className: _className, ...rest } = props;
  const className = `${_className} form-radio `;
  return <input {...rest} type="radio" className={className}></input>;
}
export function Label(props: React.LabelHTMLAttributes<HTMLLabelElement>) {
  const { className: _className, ...rest } = props;
  const defaultClass = ` form-label `;
  return <label {...rest} className={`${defaultClass + _className} `}></label>;
}

export function LabelWithRadio() {
  return <div className="w-[265px] h-[60px] rounded border-dashed"></div>;
}
