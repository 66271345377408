import { init, setUserId, track } from "@amplitude/analytics-browser";
import jwtDecode from "jwt-decode";

export const amplitudeInit = () => {
  init(process.env.REACT_APP_AMPLITUDE_API || "", undefined, {});
};

export const trackEvent = (
  eventName: string,
  properties?: Map<string, any>
) => {
  track(eventName, properties);
};

export function handleClickForTracking(event: any) {
  if (
    (event.target as HTMLButtonElement).tagName === "BUTTON" ||
    (event.target as HTMLElement).tagName === "A"
  ) {
    const actionName =
      (event.target as HTMLButtonElement).getAttribute("data-event-name") ||
      event.target.innerText;
    const screen = window.location.pathname;
    const eventName = `${screen} ${actionName}`;
    const accesstoken = localStorage.getItem("accesstoken");
    if (accesstoken) {
      let { emailId } = decodeAuthToken(accesstoken);
      emailId = emailId?.padStart(10, "_");
      setUserId(emailId);
    }
    track(eventName, undefined, {});
  }
}

export function decodeAuthToken(accesstoken: string) {
  const decodedToken = jwtDecode(accesstoken) as {
    id: string;
    emailId: string;
  };
  return decodedToken;
}
