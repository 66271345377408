import { format } from "date-fns";
import { useFormik, useFormikContext } from "formik";
import * as Yup from "yup";
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  getCompanyName,
  HStack,
  VStack,
} from "../../components/utils";

import { Input, Label } from "../../components/shared/InputField";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { FinancialReportReq } from "../../types/EsopPlan";
import {
  useGenerateFinancialReport,
  useGenerateFinancialReportWithVesting,
} from "../../queries";
import { ReportDto } from "../../types/Grant";
import { formatDate } from "../../utils/date";
import {
  convertBase64ToBlob,
  downloadBlobObject,
} from "../../utils/DownloadFile";
import { Select } from "../../components/shared/Select";

function FinancialReportDialog({
  open,
  onClose = () => {},
}: {
  open: boolean;
  onClose: () => void;
}) {
  const initialValues: FinancialReportReq = {
    isVestingScheduleIncluded: false,
    showFutureVestings: false,
    date: formatDate(new Date().toUTCString()),
    includeResignedEmployees: false,
    planType: "ALL",
  };
  const validationSchema = Yup.object({
    date: Yup.date().required("required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (values.isVestingScheduleIncluded === true) {
        vestingRefetch();
      } else {
        refetch();
      }
    },
  });

  const fileName = `Financial Report_${getCompanyName()}.xlsx`;

  async function downloadReport(vestingFinancialReport: ReportDto) {
    onClose();
    const blob = await convertBase64ToBlob(
      vestingFinancialReport.base64File,
      vestingFinancialReport.fileType
    );
    downloadBlobObject(blob, fileName);
  }
  const { refetch: vestingRefetch } = useGenerateFinancialReportWithVesting(
    format(new Date(formik.values.date), "yyyy-MM-dd"),
    formik.values.showFutureVestings,
    formik.values.includeResignedEmployees,
    formik.values.planType,
    (data: ReportDto) => downloadReport(data)
  );
  const { refetch } = useGenerateFinancialReport(
    format(new Date(formik.values.date), "yyyy-MM-dd"),
    formik.values.includeResignedEmployees,
    formik.values.planType,
    (data: ReportDto) => downloadReport(data)
  );

  return (
    <>
      <div className="p-4 text-lg font-medium border-b">
        <h6 className="flex justify-between">
          Download Financial Report
          <span className="cursor-pointer" onClick={() => onClose()}>
            X
          </span>
        </h6>
      </div>
      <VStack>
        <HStack className="flex-wrap items-baseline justify-start mr-4">
          <Box className="flex flex-wrap items-center">
            <SwitchButton
              className="mt-4 ml-4 mr-4"
              value={formik.values.isVestingScheduleIncluded}
              label="Include Vesting Schedule"
              onClick={() => {
                formik.setFieldValue(
                  "isVestingScheduleIncluded",
                  !formik.values.isVestingScheduleIncluded
                );
              }}
            />
          </Box>
        </HStack>
        {formik.values.isVestingScheduleIncluded && (
          <HStack className="flex-wrap items-baseline justify-start mr-4">
            <Box className="flex flex-wrap items-center">
              <SwitchButton
                className="mt-4 ml-4 mr-4"
                value={formik.values.showFutureVestings}
                label="Show future vestings as zero"
                onClick={() => {
                  formik.setFieldValue(
                    "showFutureVestings",
                    !formik.values.showFutureVestings
                  );
                }}
              />
            </Box>
          </HStack>
        )}
        <HStack className="flex-wrap items-baseline justify-start mr-4">
          <Box className="flex flex-wrap items-center">
            <SwitchButton
              className="mt-4 ml-4 mr-4"
              value={formik.values.includeResignedEmployees}
              label="Include Resigned Employees"
              onClick={() => {
                formik.setFieldValue(
                  "includeResignedEmployees",
                  !formik.values.includeResignedEmployees
                );
              }}
            />
          </Box>
        </HStack>
        <HStack className="items-center justify-between gap-4 mt-6 ml-6 pr-[50px]">
          <Label className="text-sm font-normal">Vesting Date</Label>
          <Box>
            <Input
              type="date"
              value={formik.values.date}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                if (!e.target.value) return;
                formik.setFieldValue("date", e.target.value);
              }}
            />
          </Box>
        </HStack>
        <HStack className="items-center justify-between gap-4 mt-6 ml-6 pr-[88px]">
          <Label className="text-sm font-normal">Plan Type</Label>
          <Box>
            <Select
              options={["ALL", "SAR", "ESOP"]}
              value={formik.values.planType}
              onChange={(e) => formik.setFieldValue("planType", e.target.value)}
            ></Select>
          </Box>
        </HStack>
        <HStack className="justify-between px-4 py-6 mt-7">
          <ButtonSecondary
            onClick={() => onClose()}
            className="text-gray-400 bg-slate-50"
          >
            Cancel
          </ButtonSecondary>
          <ButtonPrimary onClick={() => formik.handleSubmit()}>
            Generate
          </ButtonPrimary>
        </HStack>
      </VStack>
    </>
  );
}
export default FinancialReportDialog;
