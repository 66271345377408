import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import Dialog from "@mui/material/Dialog";
import { useNavigate } from "react-router";
import {
  HStack,
  Box,
  VStack,
  isAdminViewer,
  getCompanyName,
} from "../../components/utils";
import GrantsTable from "./GrantsTable";
import CardPool from "../../shared/card-pool";
import CardPlan from "../../shared/card-plan";
import CardResign from "../../shared/card-resign";
import CardDraft from "../../shared/card-draft";
import CardPend from "../../shared/card-pend";
import VestingDetails from "./vestingDetails";
import BCHeader from "../../shared/BCHeader";
import AddEmployee from "../../modals/AddEmployee";
import { useEsopPlans, useGrants, useIsPoolExists } from "../../queries";
import PendingRequests from "../../shared/PendingRequests";
import CardPendingApproval from "../../shared/card-pending-approval";
import DropdownButton from "../../components/shared/DropdownButton";
import { Action } from "../../components/shared/Dropdown";
import { AddOrEditGrant } from "./AddOrEditGrant";
import AddOrEditPlan from "../allPlans/AddPlan";
import AlertDialog from "../../components/shared/AlertDialog";
import PlansTable from "../allPlans/PlansTable";
import useIsMobile from "../../utils/detectDevice";
import {
  convertBase64ToBlob,
  downloadBlobObject,
} from "../../utils/DownloadFile";
import { downloadPoolStatement } from "../../api/Esop";

function EsopOverview(): React.ReactElement {
  const navigate = useNavigate();
  const [grantDialogOpen, setGrantDialogOpen] = useState(false);
  const [planDialogOpen, setPlanDialogOpen] = useState(false);
  const [employeeDialogOpen, setEmployeeDialogOpen] = useState(false);
  const { data } = useEsopPlans();
  const { data: isPoolExists } = useIsPoolExists();
  const companyName = getCompanyName();
  async function downloadPoolStatementExcel() {
    const res = await downloadPoolStatement();
    if (res.data) {
      const fileName = `Pool Statement_${companyName}.xlsx`;
      const blob = await convertBase64ToBlob(
        res.data,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
      downloadBlobObject(blob, fileName);
    }
  }

  const handleAction = (action: Action) => {
    if (action.disabled) {
      return;
    }
    if (action.name === "Create a New grant") {
      setGrantDialogOpen(!grantDialogOpen);
    }
    if (action.name === "Create a New Plan") {
      setPlanDialogOpen(!planDialogOpen);
    }
    if (action.name === "Add Option Holder") {
      setEmployeeDialogOpen(!employeeDialogOpen);
    }
    if (action.name === "Edit Pool" || action.name === "Add Pool") {
      navigate("/options/add-edit-pool");
    }
    if (action.name === "Download Pool Statement") {
      downloadPoolStatementExcel();
    }
  };
  const { isMobile } = useIsMobile();
  const isUserAdminViewer = isAdminViewer();
  return (
    <VStack className="gap-4">
      <div className={`flex items-baseline justify-between`}>
        <BCHeader className="items-baseline" bcTitle="ESOP Overview" />
        <DropdownButton
          actions={[
            {
              name: "Create a New Plan",
              disabled: isUserAdminViewer,
            },
            {
              name: "Create a New grant",
              disabled: isUserAdminViewer,
            },
            {
              name: "Add Option Holder",
              disabled: isUserAdminViewer,
            },
            {
              name: `${isPoolExists ? "Edit Pool" : "Add Pool"}`,
              disabled: isUserAdminViewer,
            },
            {
              name: "Download Pool Statement",
              disabled: isUserAdminViewer,
            },
          ]}
          onAction={(action) => handleAction(action)}
        />
      </div>
      <div className="grid gap-6 overflow-auto rounded-lg lg:grid-cols-3 md:grid-cols-2">
        <CardPool />
        <CardPlan />
        <CardDraft />
        <CardPend />
        <PendingRequests />
        <CardPendingApproval />
      </div>
      <VestingDetails />
      <Box className={`w-full ${isMobile ? "overflow-x-auto" : "grow"} `}>
        {data && <PlansTable data={data} />}
      </Box>
      <Dialog open={employeeDialogOpen} maxWidth="md">
        <AddEmployee
          onClose={() => setEmployeeDialogOpen(!employeeDialogOpen)}
          data={[]}
        />
      </Dialog>
      <Dialog open={grantDialogOpen} maxWidth="md">
        <AddOrEditGrant onClose={() => setGrantDialogOpen(!grantDialogOpen)} />
      </Dialog>
      <Dialog open={planDialogOpen} maxWidth="lg">
        <div className="w-[900px] mx-auto bg-white rounded-lg">
          <AddOrEditPlan
            mode="Add"
            onClose={() => setPlanDialogOpen(!planDialogOpen)}
          />
        </div>
      </Dialog>
    </VStack>
  );
}

export default EsopOverview;
