import { Navigate, useRoutes } from "react-router-dom";
import OptionsLayout from "./layouts/dashboard";
import NotFound from "./pages/Page404";
import SomeOtherPage from "./pages/SomeOtherPage";
import AllEmployees from "./pages/allEmployees/AllEmployees";
import EsopOverview from "./pages/esopOverview/EsopOverview";
import EmployeeDetails from "./pages/employeeDetails/EmployeeDetails";
import { Playground } from "./pages/playground";
import AllPlans from "./pages/allPlans";
import SinglePlanView from "./pages/allPlans/SinglePlanView";
import { VestingTemplates } from "./pages/vestingSchedules";
import LoginPage from "./pages/login/loginPage";
import { useAuthStore } from "./store";
import BuybackIndex from "./pages/optionBuyback";
import CreateOptionBuyback from "./pages/optionBuyback/CreateOptionBuyback";
import StartBuyback from "./pages/optionBuyback/StartBuyback";
import CompleteBuyback from "./pages/optionBuyback/CompleteBuyback";
import ViewOptionBuybackDetails from "./pages/optionBuyback/ViewOptionBuybackDetails";
import AdminProfilePage from "./pages/onboarding/AdminProfilePage";
import OnBoardingScreen from "./pages/onboarding/OnboardingPage";
import VerificationPage from "./pages/onboarding/VerificationPage";
import AddOrEditPoolSize from "./pages/esopOverview/AddOrEditPoolSize";
import CompanyDetail from "./pages/onboarding/CompanyDetail";
import Historic from "./pages/onboarding/HistoricPage";
import SiteSettings from "./pages/settings/SiteSettings";
import EsopModelling from "./pages/modelling/EsopModelling";
import HistoricOverView from "./pages/onboarding/HistoricOverview";
import CreateExerciseFlow from "./pages/optionBuyback/CreateExerciseFlow";
import CreateLiquidationFlow from "./pages/optionBuyback/CreateLiquidationFlow";
import { OptionSplit } from "./pages/optionSplit/OptionSplit";
import LiquidationView from "./pages/optionBuyback/LiquidationView";
import GrantDetails from "./pages/esopOverview/HistoricOnboarding";
import Faq from "./pages/FAQ/Faq";
import { CompanyProfile } from "./pages/companyProfile/CompanyProfile";
import ValuationOverview from "./pages/Valuation/ValuationOverview";
import UserProfilePage from "./pages/userProfile/UserProfilePage";

export default function Router() {
  const { isAuthenticated } = useAuthStore();
  return useRoutes([
    {
      path: "/login",
      element: !isAuthenticated ? <LoginPage /> : <Navigate to="/" replace />,
    },
    {
      path: "/onboarding/adminProfile",
      element: isAuthenticated ? (
        <AdminProfilePage />
      ) : (
        <Navigate to="/" replace />
      ),
    },
    {
      path: "/onboarding",
      element: isAuthenticated ? (
        <OnBoardingScreen />
      ) : (
        <Navigate to="/" replace />
      ),
    },
    {
      path: "/onboarding/confirmation",
      element: isAuthenticated ? (
        <VerificationPage />
      ) : (
        <Navigate to="/" replace />
      ),
    },
    {
      path: "/onboarding/companyDetail",
      element: isAuthenticated ? (
        <CompanyDetail />
      ) : (
        <Navigate to="/" replace />
      ),
    },
    {
      path: "/onboarding/historic",
      element: isAuthenticated ? <Historic /> : <Navigate to="/" replace />,
    },
    {
      path: "/onboarding/overview",
      element: isAuthenticated ? (
        <HistoricOverView />
      ) : (
        <Navigate to="/" replace />
      ),
    },
    {
      path: "/options",
      element: isAuthenticated ? (
        <OptionsLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        { path: "esopOverview", element: <EsopOverview /> },
        {
          path: "add-edit-pool",
          element: <AddOrEditPoolSize />,
        },
        {
          path: "allEmployees",
          element: <AllEmployees />,
          children: [
            { path: "employeeDetails/:id", element: <EmployeeDetails /> },
          ],
        },
        { path: "employeeDetails/:id", element: <EmployeeDetails /> },
        { path: "onboarding/historic", element: <GrantDetails /> },
        {
          path: "allPlans",
          element: <AllPlans />,
          children: [{ path: "planView/:id", element: <SinglePlanView /> }],
        },
        { path: "planView/:id", element: <SinglePlanView /> },
        { path: "vestingSchedules", element: <VestingTemplates /> },
        { path: "esopTransactions", element: <BuybackIndex /> },
        {
          path: "create-option-buyback",
          element: <CreateOptionBuyback />,
        },
        { path: "create-exercise-flow", element: <CreateExerciseFlow /> },
        {
          path: "create-exercise-liquidation-flow",
          element: <CreateExerciseFlow />,
        },
        { path: "create-liquidation-flow", element: <CreateLiquidationFlow /> },
        { path: "liquidation-details/:id", element: <LiquidationView /> },
        {
          path: "edit-options-buyback/:id",
          element: <CreateOptionBuyback />,
        },
        {
          path: "edit-exercise-flow/:id",
          element: <CreateExerciseFlow />,
        },
        {
          path: "edit-liquidation-flow/:id",
          element: <CreateLiquidationFlow />,
        },
        {
          path: "start-buy-back/:id",
          element: <StartBuyback />,
        },
        { path: "complete-buy-back/:id", element: <CompleteBuyback /> },
        {
          path: "view-option-buy-back/:id",
          element: <ViewOptionBuybackDetails />,
        },
        {
          path: "playground",
          element: <Playground />,
        },
        {
          path: "optionSplit",
          element: <OptionSplit />,
        },

        {
          path: "someOtherPage",
          element: isAllowed("someOtherPage") ? (
            <SomeOtherPage />
          ) : (
            <NotFound />
          ),
        },
      ],
    },
    {
      path: "/settings",
      element: isAuthenticated ? (
        <OptionsLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        { path: "companyProfile", element: <CompanyProfile /> },
        { path: "siteSettings", element: <SiteSettings /> },
        { path: "faq", element: <Faq /> },
        { path: "valuationReports", element: <ValuationOverview /> },
      ],
    },
    {
      path: "/modelling",
      element: isAuthenticated ? (
        <OptionsLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [{ path: "esopModellingTab", element: <EsopModelling /> }],
    },
    {
      path: "/allPlans",
      element: <AllPlans />,
    },
    {
      path: "/404",
      element: <NotFound />,
    },
    { path: "*", element: <Navigate to="/404" replace /> },
    { path: "/", element: <Navigate to="/options/esopOverview" replace /> },
    {
      path: "/settings",
      element: <Navigate to="/settings/siteSettings" replace />,
    },
    {
      path: "/modelling",
      element: <Navigate to="/modelling/esopModellingTab" replace />,
    },
    {
      path: "/user",
      element: isAuthenticated ? (
        <OptionsLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [{ path: "profile", element: <UserProfilePage /> }],
    },
  ]);
}
function isAllowed(route: string) {
  // check from user object permissions
  // ideally a call
  return true;
}
