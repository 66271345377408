import { lazy, Suspense } from "react";

const PdfDocument = lazy(() => import("./PdfDocument"));

function LazyPdfDocument({ url, name }: { url: string; name?: string }) {
  return (
    <Suspense fallback={<div>Document loading...</div>}>
      <PdfDocument url={url} name={name} />
    </Suspense>
  );
}

export default LazyPdfDocument;
