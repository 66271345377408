import React, { useState } from "react";
import { HStack, VStack } from "../../components/utils";
import EsopSubsidiaryCompaniesTable from "./EsopSubsidiaryCompaniesTable";

function EsopSubsidiaryCompaniesOverview() {
  return (
    <VStack className="gap-4">
      <HStack className="flex flex-row">
        <EsopSubsidiaryCompaniesTable />
      </HStack>
    </VStack>
  );
}

export default EsopSubsidiaryCompaniesOverview;
