import { HStack } from "../utils";

export type SelectButtonGroupProps<T> = {
  options: T[];
  value: T;
  onChange: (option: T) => void;
  textGetter?: (option: T) => string | undefined;
};

export function SelectButtonGroup<T>(props: SelectButtonGroupProps<T>) {
  const { options, onChange, value } = props;
  const defaultGetter = (option: any) => option.toString();

  const textGetter = props.textGetter || defaultGetter;

  return (
    <HStack className="w-full gap-6 ">
      {options.map((option, i) => {
        const isSelected = option === value;
        return (
          <HStack
            key={i}
            onClick={() => {
              onChange(option);
            }}
            className={`flex-1 h-16 rounded border-dashed border items-center cursor-pointer gap-4 pl-4
                      ${isSelected ? "border-orange-501" : " "}`}
          >
            <div
              className={`h-5 w-5 rounded-full border-4  bg-gray-100 aspect-square
                        ${
                          isSelected ? "border-orange-501" : "border-gray-100"
                        }`}
            ></div>
            <span className="font-medium text-xs2 text-dark ">
              {textGetter(option)}
            </span>
          </HStack>
        );
      })}
    </HStack>
  );
}
