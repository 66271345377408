import { useFormikContext } from "formik";
import { Input, Label } from "../../components/shared/InputField";
import { Box, Error, HStack, VStack } from "../../components/utils";
import {
  CompanyDetailsPostModel,
  DropDownModel,
} from "../../types/OnBoardDataModel";
import MultipleDropDown from "./MultiDropDown";

export type BusinessDetailFormDetail = {
  dropDownValue: DropDownModel;
};

export default function BusinessDetailForm({
  dropDownValue,
}: BusinessDetailFormDetail) {
  const formik = useFormikContext<CompanyDetailsPostModel>();
  const { errors, touched, getFieldProps } = formik;
  return (
    <div className="w-full bg-white rounded-md mt-10">
      <Box className="px-10 text-lg text-left font-medium border-b py-4">
        <h6>Business Details</h6>
      </Box>
      <VStack className="w-full  px-10 py-7 gap-9 ">
        <HStack className="gap-8">
          {dropDownValue?.selectedCategoryValues && (
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Category
                <span className="text-rose-400">*</span>
              </Label>
              <MultipleDropDown
                feild="category"
                selectedOptions={dropDownValue?.selectedCategoryValues}
                dropDownOptions={dropDownValue?.categoryDropdownValues}
              />
              {touched.category && errors.category && (
                <Error text={errors.category as string} />
              )}
            </div>
          )}
          {dropDownValue.selectedIndustryValues && (
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Industry
                <span className="text-rose-400">*</span>
              </Label>
              <MultipleDropDown
                feild="industry"
                selectedOptions={dropDownValue.selectedIndustryValues}
                dropDownOptions={dropDownValue.industryDropdownValues}
              />
              {touched.industry && errors.industry && (
                <Error text={errors.industry as string} />
              )}
            </div>
          )}
        </HStack>
        <HStack className="gap-8">
          <div className="flex-1">
            <Label className="text-sm font-normal">
              NIC Code
              <span className="text-rose-400">*</span>
            </Label>
            <Input
              type="text"
              className="W-120"
              placeholder="NIC Code"
              {...getFieldProps("nic")}
            />

            {touched.nic && errors.nic && <Error text={errors.nic} />}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Doing Business as</Label>
            <Input
              className="w-120"
              type="text"
              {...getFieldProps("doingBusinessAs")}
            />
            {touched.doingBusinessAs && errors.doingBusinessAs && (
              <Error text={errors.doingBusinessAs} />
            )}
          </div>
        </HStack>
      </VStack>
    </div>
  );
}
