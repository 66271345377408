import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useFormikContext } from "formik";
import { Input, Label } from "../components/shared/InputField";
import { Select } from "../components/shared/Select";
import {
  ButtonPrimary,
  HStack,
  VStack,
  Error,
  ButtonPrimary1,
} from "../components/utils";
import {
  ConversionDetails,
  HistoricalRoundModel,
  Investor,
  Secondary,
} from "../types/HistoricDataModel";
import { conversionTag } from "../pages/onboarding/constantValues";

export type AddConversionProps = {
  transactionIndex: number;
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  transactionType: "investors" | "secondaries";
};

const AddConversion = ({
  transactionIndex,
  onPrimaryAction,
  onSecondaryAction,
  transactionType,
}: AddConversionProps) => {
  const formik = useFormikContext<HistoricalRoundModel>();
  const {
    values,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
  } = formik;

  const fieldTouched = touched?.[transactionType]?.[transactionIndex]
    ?.conversionDetails as unknown as ConversionDetails;
  const transactionError =
    transactionType === "investors"
      ? (errors?.investors?.[transactionIndex] as Investor)
      : (errors?.secondaries?.[transactionIndex] as Secondary);
  const err = transactionError?.conversionDetails as ConversionDetails;

  const basicDetailErrors =
    err?.conversionValue ||
    err?.conversionDate ||
    err?.numberOfSharesToConvert ||
    err?.conversionSecurity ||
    err?.numberofSharesPostConversion;

  const [initialConversion, setInitialConversion] =
    useState<ConversionDetails>();
  useEffect(() => {
    setInitialConversion(
      values[transactionType][transactionIndex]?.conversionDetails
    );
  }, []);

  function checkAllotmentFeild(): boolean {
    const transactionDetail =
      transactionType === "investors"
        ? values.investors[transactionIndex].allotment
        : values.secondaries[transactionIndex].transactions;
    return !transactionDetail?.find(
      (transaction) => transaction.name === conversionTag.name
    );
  }

  const doPartialTouch = () => {
    setFieldTouched(
      `${transactionType}[${transactionIndex}].conversionDetails.conversionValue`
    );
    setFieldTouched(
      `${transactionType}[${transactionIndex}].conversionDetails.conversionDate`
    );
    setFieldTouched(
      `${transactionType}[${transactionIndex}].conversionDetails.numberOfSharesToConvert`
    );
    setFieldTouched(
      `${transactionType}[${transactionIndex}].conversionDetails.conversionSecurity`
    );
    setFieldTouched(
      `${transactionType}[${transactionIndex}].conversionDetails.numberofSharesPostConversion`
    );
  };

  const handleOnCancel = () => {
    const length: number =
      (transactionType === "investors"
        ? values.investors[transactionIndex].allotment?.length
        : values.secondaries[transactionIndex].transactions?.length) || 0;

    if (transactionType === "investors") {
      setFieldValue(
        `${transactionType}[${transactionIndex}].allotment`,
        values.investors[transactionIndex].allotment?.slice(0, length - 1)
      );
    } else {
      setFieldValue(
        `${transactionType}[${transactionIndex}].transactions`,
        values.secondaries[transactionIndex].transactions?.slice(0, length - 1)
      );
    }
    setFieldValue(
      `${transactionType}[${transactionIndex}].conversionDetails`,
      initialConversion
    );
    onSecondaryAction();
  };

  const handleSubmit = () => {
    const length: number =
      (transactionType === "investors"
        ? values.investors[transactionIndex].allotment?.length
        : values.secondaries[transactionIndex].transactions?.length) || 0;
    if (
      transactionType === "investors" &&
      checkAllotmentFeild() &&
      !initialConversion
    ) {
      setFieldValue(`investors[${transactionIndex}].allotment[${length - 1}]`, {
        name: conversionTag.name,
        identifier: conversionTag.identifier,
      });
    } else if (
      transactionType === "secondaries" &&
      checkAllotmentFeild() &&
      !initialConversion
    ) {
      setFieldValue(
        `secondaries[${transactionIndex}].transactions[${length - 1}]`,
        {
          name: conversionTag.name,
          identifier: conversionTag.identifier,
        }
      );
    } else if (transactionType === "investors") {
      setFieldValue(
        `investors[${transactionIndex}].allotment`,
        values.investors[transactionIndex].allotment?.slice(0, length - 1)
      );
    } else {
      setFieldValue(
        `secondaries[${transactionIndex}].transactions`,
        values.secondaries[transactionIndex].transactions?.slice(0, length - 1)
      );
    }
    onPrimaryAction();
  };

  return (
    <>
      <Box className="px-10 text-lg font-medium border-b py-7">
        <h6>Conversation</h6>
      </Box>
      <VStack className="w-full px-10 py-7 gap-9">
        <VStack className="w-full gap-9">
          <HStack className="gap-8 ">
            <div className="flex-1">
              <Label className="text-sm font-normal">Conversation</Label>
              <Input
                type="number"
                placeholder="Conversation Share Price"
                className="w-96"
                {...getFieldProps(
                  `${transactionType}[${transactionIndex}].conversionDetails.conversionValue`
                )}
              />
              {fieldTouched?.conversionValue && err?.conversionValue && (
                <Error text={`${err?.conversionValue}`} />
              )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">FDB Shares</Label>
              <Input
                type="number"
                placeholder="Enter FDB Shares"
                className="w-96"
                {...getFieldProps(
                  `${transactionType}[${transactionIndex}].conversionDetails.numberofSharesPostConversion`
                )}
              />
              {fieldTouched?.numberofSharesPostConversion &&
                err?.numberofSharesPostConversion && (
                  <Error text={`${err?.numberofSharesPostConversion}`} />
                )}
            </div>
          </HStack>
          <HStack className="gap-8 ">
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Number of shares to be converted
              </Label>
              <Input
                type="number"
                placeholder="Eg:10,000"
                className="w-96"
                {...getFieldProps(
                  `${transactionType}[${transactionIndex}].conversionDetails.numberOfSharesToConvert`
                )}
              />
              {fieldTouched?.numberOfSharesToConvert &&
                err?.numberOfSharesToConvert && (
                  <Error text={`${err?.numberOfSharesToConvert}`} />
                )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Conversation Date</Label>
              <Input
                type="date"
                placeholder="Select COnversation Date"
                className="w-96"
                {...getFieldProps(
                  `${transactionType}[${transactionIndex}].conversionDetails.conversionDate`
                )}
              />
              {fieldTouched?.conversionDate && err?.conversionDate && (
                <Error text={`${err?.conversionDate}`} />
              )}
            </div>
          </HStack>
          <HStack className="gap-8 justify-start">
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Conversation Security
              </Label>
              <Select
                options={["Equity", "CCPS"]}
                {...getFieldProps(
                  `${transactionType}[${transactionIndex}].conversionDetails.conversionSecurity`
                )}
              />
              {fieldTouched?.conversionSecurity && err?.conversionSecurity && (
                <Error text={`${err?.conversionSecurity}`} />
              )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Notes</Label>
              <Input
                type="text"
                placeholder="Optional"
                className="w-96"
                {...getFieldProps(
                  `${transactionType}[${transactionIndex}].conversionDetails.notes`
                )}
              />
            </div>
          </HStack>
          <HStack className="justify-between">
            <div className="flex-1"></div>
            <HStack className="justify-end">
              <ButtonPrimary1
                type="reset"
                className="text-red-500 mr-8"
                onClick={() => {
                  handleOnCancel();
                  onSecondaryAction();
                }}
              >
                Cancel
              </ButtonPrimary1>
              <ButtonPrimary
                onClick={() => {
                  if (basicDetailErrors) {
                    doPartialTouch();
                  } else {
                    handleSubmit();
                  }
                }}
              >
                Save
              </ButtonPrimary>
            </HStack>
          </HStack>
        </VStack>
      </VStack>
    </>
  );
};

export default AddConversion;
