import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { Select } from "../../components/shared/Select";
import {
  Box,
  Error,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
} from "../../components/utils";
import { Input, Label, TextArea } from "../../components/shared/InputField";
import FileInput from "../../components/shared/FileInput";
import convertToBase64 from "../../utils/convertToBase64";
import { EsopSubsidiaryCompany } from "../../types/EsopSubsidiaryCompany";
import {
  useAddEsopSubsidiaryCompany,
  useEditEsopSubsidiaryCompany,
} from "../../queries/EsopSubsidiaryCompany";
import { currencies } from "../../constants/CompanyCurrencyType";

type AddOrEditEsopSubsidiaryCompanyProps = {
  mode: "Edit" | "Add" | "Delete";
  data?: EsopSubsidiaryCompany;
  onClose: () => void;
};

function AddOrEditEsopSubsidiaryCompany(
  props: AddOrEditEsopSubsidiaryCompanyProps
) {
  const { data, mode, onClose } = props;
  const baseValues: EsopSubsidiaryCompany = {
    companyName: "",
    companyType: "Subsidiary",
    cin: "",
    companyCurrency: "INR - ₹",
    notes: "",
    grantLetterTemplate: "",
    grantLetterTemplateName: "",
    grantLetterType: "",
    isTemplateOverrided: false,
  };

  function getInitialValuesEditing(data: EsopSubsidiaryCompany | undefined) {
    if (!data) return baseValues;
    return {
      id: data.id,
      companyName: data.companyName,
      companyType: data.companyType,
      cin: data.cin,
      companyCurrency: data.companyCurrency,
      notes: data.notes,
      grantLetterTemplate: data.grantLetterTemplate,
      grantLetterTemplateName: data.grantLetterTemplateName,
      grantLetterType: data.grantLetterType,
      isTemplateOverrided: false,
    };
  }

  function getInitialValues() {
    if (mode === "Edit") {
      return getInitialValuesEditing(data);
    } else {
      return baseValues;
    }
  }

  function handleDelete() {
    formik.setFieldValue("grantLetterTemplate", "");
    formik.setFieldValue("grantLetterType", "");
  }

  const { mutate: addSubsidiaryCompany } = useAddEsopSubsidiaryCompany();
  const { mutate: editSubsidiaryCompany } = useEditEsopSubsidiaryCompany();

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("Company name is required"),
  });

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema,
    onSubmit: () => {
      const companyDto = { ...formik.values };
      if (mode === "Add") {
        addSubsidiaryCompany(companyDto, {
          onSuccess: () => {
            toast("Subsidiary company Added Successfully!", {
              type: "success",
            });
            onClose();
          },
          onError: (err: any) => {
            toast(err.response.data.reason, { type: "error", autoClose: 2000 });
          },
        });
      } else {
        editSubsidiaryCompany(companyDto, {
          onSuccess: () => {
            toast("Subsidiary company Edited Successfully!", {
              type: "success",
            });
            onClose();
          },
          onError: (err: any) => {
            toast(err.response.data.reason, { type: "error", autoClose: 2000 });
          },
        });
      }
    },
  });
  async function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];

    formik.setFieldValue("grantLetterTemplateName", file?.name);
    const base64 = await convertToBase64(file);
    formik.setFieldValue("grantLetterTemplate", base64);
    formik.setFieldValue("isTemplateOverrided", true);
  }
  return (
    <VStack className="w-full">
      <Box className="px-10 text-lg font-medium border-b py-7">
        <h6 className="flex justify-between">
          {mode === "Edit"
            ? "Edit Subsidiary Company"
            : "Add Subsidiary Company"}{" "}
          <span onClick={() => onClose()} className="cursor-pointer">
            <Icon icon="material-symbols:close-rounded" />
          </span>{" "}
        </h6>
      </Box>
      <VStack className="px-10 py-7 gap-9">
        <HStack className="gap-8">
          <div className="flex-1">
            <Label>Company Name</Label>
            <Input type="text" {...formik.getFieldProps("companyName")} />
            {formik.touched.companyName && formik.errors.companyName && (
              <Error text={formik.errors.companyName} />
            )}
          </div>
          <div className="flex-1">
            <Label>Company Type</Label>
            <Select
              options={["Subsidiary"]}
              {...formik.getFieldProps("companyType")}
            />
          </div>
        </HStack>
        <HStack className="gap-8">
          <div className="flex-1">
            <Label>CIN</Label>
            <Input type="text" {...formik.getFieldProps("cin")} />
            {formik.touched.cin && formik.errors.cin && (
              <Error text={formik.errors.cin} />
            )}
          </div>
          <div className="flex-1">
            <Label>Company Currency</Label>
            <Select
              options={currencies}
              {...formik.getFieldProps("companyCurrency")}
            />
          </div>
        </HStack>
        <HStack className="gap-8">
          <div className="flex-1">
            <Label>Notes</Label>
            <TextArea {...formik.getFieldProps("notes")} />
          </div>
          <div className="flex-1">
            <Label>Grant Letter Template</Label>
            <div className="py-1.5">
              {mode === "Edit" &&
              formik.values.grantLetterTemplate !== "" &&
              formik.values.grantLetterTemplate !== null ? (
                <div className="flex">
                  <FileInput
                    accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    file={"ESOP_Grant_Letter"}
                    onChange={(e) => {
                      handleFileUpload(e);
                    }}
                  />
                  <button
                    onClick={() => handleDelete()}
                    className="ml-6 text-zinc-300 hover:scale-105"
                  >
                    <DeleteIcon />
                  </button>
                </div>
              ) : (
                <FileInput
                  accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  file={formik.values.grantLetterTemplateName || "Attach File"}
                  onChange={(e) => {
                    handleFileUpload(e);
                  }}
                />
              )}
            </div>
          </div>
        </HStack>
        <HStack className="justify-between">
          <ButtonPrimary1 onClick={() => onClose()}>Cancel</ButtonPrimary1>
          <ButtonPrimary
            type="submit"
            onClick={(e) => {
              formik.handleSubmit();
            }}
          >
            Save
          </ButtonPrimary>
        </HStack>
      </VStack>
    </VStack>
  );
}

export default AddOrEditEsopSubsidiaryCompany;
