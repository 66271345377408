/* eslint-disable max-len */
/* eslint-disable etc/no-commented-out-code */
import React from "react";
import { produce } from "immer";
import { create } from "zustand";
import { Action } from "../components/shared/Dropdown";

export type FilterValues = {
  name: string;
  value: string;
  apply: Boolean;
};
export type RangeOptions = {
  optionsGranted: string[];
  optionsVested: string[];
  optionsExercisedWithStock: string[];
  optionsExercisedWithCash: string[];
  optionsExercised: string[];
  grantDate: string[];
};
export type GrantsTableFilter = {
  applyFilter: boolean;
  minOptionsVested: number;
  maxOptionsVested: number;
  minOptionsGranted: number;
  maxOptionsGranted: number;
  grantFilter: FilterValues[];
  inColumnFilter: RangeOptions;
  filterButtonFocused: boolean;
  uncheckedSlice: (
    e: React.ChangeEvent<HTMLElement>,
    value: string,
    field: keyof RangeOptions
  ) => void;
  advanceGrantFilter: (
    e: React.ChangeEvent<HTMLElement>,
    filterType: Action
  ) => void;
  delete: (
    e: React.ChangeEvent<HTMLElement>,
    value: string,
    field: keyof RangeOptions
  ) => void;
  setInColumnFilter: (value: string, field: keyof RangeOptions) => void;
  setFilterButtonFocused: (focus: boolean) => void;
  setApplyFilter: (focus: boolean) => void;
  setMinOptionsVested: (value: number) => void;
  setMaxOptionsVested: (value: number) => void;
  setMinOptionsGranted: (value: number) => void;
  setMaxOptionsGranted: (value: number) => void;
  reset: (
    minOptionsVested: number,
    maxOptionsVested: number,
    minOptionsGranted: number,
    maxOptionsGranted: number
  ) => void;
};

const initialState = {
  applyFilter: false,
  minOptionsVested: 0,
  maxOptionsVested: Number.MAX_VALUE,
  minOptionsGranted: 0,
  maxOptionsGranted: Number.MAX_VALUE,
  inColumnFilter: {
    optionsGranted: [],
    optionsVested: [],
    optionsExercised: [],
    optionsExercisedWithCash: [],
    optionsExercisedWithStock: [],
    grantDate: [],
  },
  grantFilter: [
    { name: "From Date", value: "", apply: false },
    {
      name: "To Date",
      value: "",
      apply: false,
    },
    { name: "Plan Name", value: "", apply: false },
    { name: "Vesting Schedule", value: "", apply: false },
    { name: "Grant Status", value: "", apply: false },
    { name: "Recent Activity", value: "", apply: false },
  ],
  filterButtonFocused: false,
};

export const useGrantFilterStore = create<GrantsTableFilter>((set) => ({
  ...initialState,
  setFilterButtonFocused: (focus) => {
    set((state) => ({ ...state, filterButtonFocused: focus }));
  },
  setApplyFilter: (focus: boolean) => {
    set((state) => ({ ...state, applyFilter: focus }));
  },
  setInColumnFilter: (value: string, field: keyof RangeOptions) => {
    set(
      produce((state: GrantsTableFilter) => {
        state.inColumnFilter[field].push(value);
      })
    );
  },
  uncheckedSlice: (_e, value, field: keyof RangeOptions) => {
    set(
      produce((state: GrantsTableFilter) => {
        const indexToBeDeleted = state.inColumnFilter[field].indexOf(value);
        state.inColumnFilter[field].splice(indexToBeDeleted, 1);
      })
    );
  },
  delete: (e, value, field: keyof RangeOptions) => {
    set(
      produce((state: GrantsTableFilter) => {
        const dateToBeDeleted = state.inColumnFilter[field].indexOf(value);
        state.inColumnFilter[field].splice(dateToBeDeleted, 1);
      })
    );
  },
  advanceGrantFilter: (e, filterType) => {
    set(
      produce((state: GrantsTableFilter) => {
        state.grantFilter.map((item) => {
          if (item.name === filterType.name) {
            item.value = (e.target as any).value;
            item.apply = true;
            return item;
          } else return item;
        });
      })
    );
  },
  setMinOptionsVested: (value: number) => {
    set((state: GrantsTableFilter) => ({ ...state, minOptionsVested: value }));
  },
  setMaxOptionsVested: (value: number) => {
    set((state: GrantsTableFilter) => ({ ...state, maxOptionsVested: value }));
  },
  setMinOptionsGranted: (value: number) => {
    set((state: GrantsTableFilter) => ({
      ...state,
      minOptionsGranted: value,
    }));
  },
  setMaxOptionsGranted: (value: number) => {
    set((state: GrantsTableFilter) => ({
      ...state,
      maxOptionsGranted: value,
    }));
  },
  reset: (
    minOptionsVested: number,
    maxOptionsVested: number,
    minOptionsGranted: number,
    maxOptionsGranted: number
  ) => {
    set(() => ({
      ...initialState,
      minOptionsVested,
      maxOptionsVested,
      minOptionsGranted,
      maxOptionsGranted,
    }));
  },
}));
