import { Action, CTADropdown } from "../../components/shared/Dropdown";
import {
  Box,
  Center,
  HStack,
  VStack,
  isEsopViewer,
} from "../../components/utils";
import { useVestingEvents } from "../../queries";
import { useAuthorizationStore } from "../../store";
import { useEditEventDetailsDialog } from "../../store/useDialogStore";
import { VestingEventReq } from "../../types/VestingTemplate";
import { formatDate, formatDisplayDate } from "../../utils/date";
import GrantStatusLabel from "../esopOverview/StatusLabel";
import ImpactedEmployees from "./ImpactedEmployees";

function VestingEvents() {
  const { data: _vestingEvents, isPlaceholderData } = useVestingEvents();
  const isUserEsopViewer = isEsopViewer();
  const vestingEvents = _vestingEvents || [];
  const { setState: setEditEventDetailsDialog } = useEditEventDetailsDialog();
  const { authority } = useAuthorizationStore();
  function handleAction(event: VestingEventReq, action: Action) {
    if (action.disabled) return;
    if (action.name === "Edit" && event.vestingScheduleState === "Active") {
      setEditEventDetailsDialog({ open: true, event, variant: "edit" });
    } else if (
      action.name === "Complete" &&
      event.vestingScheduleState === "ForApproval"
    ) {
      setEditEventDetailsDialog({ open: true, event, variant: "complete" });
    }
  }
  function checkRoleValidation(item: VestingEventReq) {}
  return (
    <VStack className="p-6 bg-white rounded-lg max-h-96">
      <Box className="mb-4 font-medium text-gray-dark">Vesting Events</Box>
      <HStack className="overflow-auto">
        <table className="w-full table-space">
          <thead>
            <tr className="text-gray-400 border-b border-dashed font-mediuum text-xs1">
              <td className="px-2 uppercase">Event</td>
              <td className="px-2 uppercase">Target Date</td>
              <td className="px-2 uppercase">Employee Impacted</td>
              <td className="px-2 uppercase"></td>
            </tr>
          </thead>
          <tbody className={isPlaceholderData ? "loading" : ""}>
            {vestingEvents &&
              vestingEvents.map((item, index) => (
                <tr
                  key={index}
                  className="font-medium border-b border-dashed text-xs1"
                >
                  <td className="px-2 py-2">
                    <VStack>
                      <p>{item.eventName}</p>
                      <p className="w-20 ">
                        <GrantStatusLabel
                          state={item.vestingScheduleState}
                        ></GrantStatusLabel>
                      </p>
                    </VStack>
                  </td>
                  <td className="px-2 py-2">
                    <p>{formatDisplayDate(item.eventTargetDate)}</p>
                  </td>
                  <td>
                    <div className="relative flex flex-row py-2 -space-x-2 ">
                      {Array.isArray(item.employeeNames) &&
                        item.employeeNames
                          ?.slice(0, 3)
                          ?.map((employee) => (
                            <ImpactedEmployees
                              key={employee}
                              name={employee}
                              impactedEmployees={item.employeeNames}
                            />
                          ))}

                      <div
                        className={`relative w-8 h-8 text-center text-white ${
                          item.employeeNames.length > 3
                            ? "bg-black ring-2 ring-white border-2 border-white border-solid hover:ring-blue-800"
                            : ""
                        } rounded-full `}
                      >
                        <Center className="py-1.5">
                          <p>+{item.employeeNames.length - 3}</p>
                        </Center>
                      </div>
                    </div>
                  </td>
                  <td className="relative px-6 text-black text-xs4">
                    <Center>
                      <CTADropdown
                        actions={[
                          {
                            name: "Edit",
                            disabled:
                              item.vestingScheduleState === "ForApproval" ||
                              item.vestingScheduleState === "Complete" ||
                              isUserEsopViewer,
                          },
                          {
                            name: "Complete",
                            disabled:
                              item.vestingScheduleState === "Complete" ||
                              item.vestingScheduleState === "Active" ||
                              isUserEsopViewer ||
                              (item.vestingScheduleState === "ForApproval" &&
                                authority === "ROLE_OPTIONS_CREATOR"),
                          },
                        ]}
                        onAction={(action) => handleAction(item, action)}
                      />
                    </Center>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </HStack>
      {vestingEvents.length === 0 && (
        <Center className="h-48">
          <p className="p-4 font-medium text-teal-600 rounded">
            No Vesting Events !!{" "}
          </p>
        </Center>
      )}
    </VStack>
  );
}

export default VestingEvents;
