import * as _ from "lodash";
import { initialPictureValues } from "../pages/employeeDetails/profile";
import { Employee, EmployeesOverviewDto } from "../types/Employee";
import { EsopCard, EsopPlan, EsopPlansSummary } from "../types/EsopPlan";
import { Grant } from "../types/Grant";
import { optionBuybackDetail } from "../types/OptionBuyback";
import {
  VestingTemplate,
  VestingTriggerType,
  VestingType,
  VestingEventReq,
} from "../types/VestingTemplate";

export const randomGrant = (): Grant => ({
  planid: _.random(100000, 200000),
  planName: "x".padStart(_.random(20, 50), "x"),
  planType: "x".padStart(_.random(20, 50), "x"),
  optionHolderId: _.random(100000, 200000),
  grantIdentifier: "x".padStart(_.random(3, 6), "x"),
  optionHolderName: "x".padStart(_.random(15, 25), "x"),
  optionHolderState: "x".padStart(_.random(15, 25), "x"),
  optionsGranted: _.random(100000, 200000),
  grantPrice: _.random(1000, 2000),
  allowedPercentageForResigned: _.random(0, 1),
  grantDate: "2020-10-06T18:30:00.000+0000",
  optionsVested: _.random(100000, 200000),
  optionsLapsed: _.random(100000, 200000),
  optionsExercisedWithStock: _.random(100, 200000),
  optionsExercisedWithCash: _.random(100, 200000),
  optionsForfeited: _.random(100, 200000),
  grantDateD: new Date(),
  employeeId: _.random(100000, 200000),
  employementStatus: null,
  optionsExercised: _.random(100, 10000),
  employeeName: "x".padStart(_.random(15, 25), "x"),
  employeeIdentifier: "x".padStart(_.random(6, 7), "x"),
  isExerciseRequested: false,
  isVestRequested: false,
  isExercise: false,
  customizedGrantLetter: false,
  vestingId: _.random(100000, 200000),
  vestingTemplateName: "x".padStart(_.random(20, 40), "x"),
  statusId: "x".padStart(_.random(7, 8), "x"),
  vestingDate: "2013-10-06T18:30:00.000+0000",
  exerciseDate: "2013-10-06T18:30:00.000+0000",
  vestingDateType: "GrantDate",
  dateOfJoin: "2012-03-31T18:30:00.000+0000",
  cliffPeriod: 24,
  vestingTriggerType: "Time",
  isPausedVesting: false,
});

export const randomPlan = (): EsopPlan => ({
  esopPlanId: _.random(100000, 200000),
  planName: "x".padStart(_.random(20, 50), "x"),
  planDescription: "x".padStart(_.random(50, 100), "x"),
  vestedShares: _.random(1000000, 2000000),
  issuedShares: _.random(1000000, 2000000),
  planType: "",
  planHoldingPercentage: _.random(100000, 200000),
  exercisedShares: _.random(1000000, 2000000),
  esopPlanState: "x".padStart(_.random(6, 8), "x"),
  optionsReserved: _.random(0, 0),
  totalShares: _.random(0, 0),
  optionsIssuedV2: _.random(1000000, 2000000),
  optionsVestedV2: _.random(1000000, 2000000),
  optionsExercisedV2: _.random(1000000, 2000000),
  optionsStockExercisedV2: _.random(1000000, 2000000),
  optionsCashExercisedV2: _.random(1000000, 2000000),
  optionsLapsedV2: _.random(1000000, 2000000),
  optionsForfeitedV2: _.random(1000000, 2000000),
  optionsAvailableV2: _.random(1000000, 2000000),
  planStartDate: "2013-09-30T18:30:00.000+0000",
  planDocument: "",
  conversionNumber: 1,
  conversionShares: 1,
  poolShares: 1,
});

export const randomEsopCard = (): EsopCard => ({
  totalOptionsInPool: _.random(100000, 200000),
  numberOfPlans: _.random(100000, 200000),
  totalOptionIssued: _.random(100000, 200000),
  totalOptionReserved: _.random(100000, 200000),
  optionPercentage: _.random(0.55, 0.85),
  optionVestedCount: _.random(100000, 200000),
  optionVestedPercentage: _.random(0.55, 0.85),
  optionExercisedCount: _.random(100000, 200000),
  optionExercisedPercentage: _.random(100000, 200000),
  employeesUnderPlan: _.random(100000, 200000),
  othersUnderPlan: _.random(100000, 200000),
  totalOptionsIssuedV2: _.random(100000, 200000),
  totalOptionsAvailableV2: _.random(100000, 200000),
  totalOptionsVestedV2: _.random(100000, 200000),
  totalOptionsExercisedV2: _.random(100000, 200000),
});

export const randomEsopOverview = (): EsopPlansSummary => ({
  esopTableDtoList: Array(10)
    .fill(0)
    .map((_i) => randomPlan()),
  esopCardDto: randomEsopCard(),
  isPoolExists: true,
});

export const randomEmployee = (): Employee => ({
  id: _.random(100000, 200000),
  employeeName: "x".padStart(_.random(15, 25), "x"),
  legalName: "x".padStart(_.random(15, 25), "x"),
  employeeIdentificationString: "x".padStart(_.random(6, 7), "x"),
  employeeDesignation: "x".padStart(_.random(15, 25), "x"),
  employmentStatus: "x".padStart(_.random(8, 10), "x"),
  address: "x".padStart(_.random(100, 200), "x"),
  contactNumber: "x".padStart(_.random(10, 10), "x"),
  residentialStatus: "x".padStart(_.random(7, 10), "x"),
  manager: "x".padStart(_.random(15, 25), "x"),
  department: "x".padStart(_.random(15, 25), "x"),
  email: "x".padStart(_.random(25, 35), "x"),
  nationality: "x".padStart(_.random(7, 10), "x"),
  totalOptionsGranted: _.random(100000, 200000),
  totalOptionsVested: _.random(100000, 200000),
  totalPercentageVested: _.random(100000, 200000),
  totalOptionsExercised: _.random(100000, 200000),
  totalPercentageExercised: _.random(100000, 200000),
  totalOptionHoldingPercentage: _.random(100000, 200000),
  totalShareholdingPercentage: _.random(100000, 200000),
  isHoldingOption: true,
  isConfirmedNominees: true,
  isExercisePeriodExtended: true,
  employerName: "x".padStart(_.random(15, 25), "x"),
  employerNameForCommunication: "x".padStart(_.random(15, 25), "x"),
  extendedExerciseMonths: _.random(100000, 200000),
  pan: "x".padStart(_.random(11, 11), "x"),
  lastDay: "2012-03-31T18:30:00.000+0000",
  dateOfJoin: "2012-03-31T18:30:00.000+0000",
  dateOfLeaving: "2012-03-31T18:30:00.000+0000",
  grade: "x".padStart(_.random(2, 2), "x"),
  notes: "x".padStart(_.random(100, 250), "x"),
  employmentType: "EMPLOYEE",
  employeeImagePath: {
    id: _.random(100000, 200000),
    filePath: "x".padStart(_.random(2, 2), "x"),
    fileName: "x".padStart(_.random(2, 2), "x"),
    fileType: "x".padStart(_.random(2, 2), "x"),
    fileData: "x".padStart(_.random(2, 2), "x"),
    fileUrl: "x".padStart(_.random(2, 2), "x"),
    createdAt: "x".padStart(_.random(2, 2), "x"),
  },
  profilePicture: initialPictureValues,
  isLoginProvided: true,
  firstLogin: "2012-03-31T18:30:00.000+0000",
  lastLogin: "2012-03-31T18:30:00.000+0000",
  deactivate: true,
});

export const randomEmployeeOverview = (): EmployeesOverviewDto => ({
  departmentEmployeeCountDtos: [],
  employees: Array(100)
    .fill(0)
    .map((_i) => randomEmployee()),
  yearofJoiningEmployeeCountDtos: [],
});

export const randomVestingTemplate = (): VestingTemplate => ({
  id: _.random(100000, 200000),
  vestingTemplateName: "x".padStart(_.random(25, 50), "x"),
  vestingType: VestingType.Standard,
  vestingTriggerType: VestingTriggerType.Time,
  vestingPeriod: _.random(48, 48),
  cliffPeriod: _.random(12, 12),
  includeTimeVesting: false,
  includeEventVesting: false,
  vestingInterval: _.random(24, 48),
  numberOfUsersApllied: _.random(50, 200),
  isEditable: false,
  isDefault: false,
  schedules: [],
});

export const randomVestingEvents = (): VestingEventReq => ({
  eventName: "x".padStart(_.random(20, 50), "x"),
  eventTargetDate: "2013-09-30T18:30:00.000+0000",
  eventDate: "2013-09-30T18:30:00.000+0000",
  vestingScheduleState: "x".padStart(_.random(20, 50), "x"),
  isEditable: false,
  vestingTemplateName: "x".padStart(_.random(20, 50), "x"),
  employeeNames: [],
  grantsINumber: _.random(100000, 200000),
  EmployeeName: "",
});

export const randomOptionBuybackOverview = (): optionBuybackDetail => ({
  id: _.random(100000, 200000),
  companyId: _.random(100000, 200000),
  name: "x".padStart(_.random(200, 4000), "x"),
  description: "x".padStart(_.random(2000, 4000), "x"),
  percentage: _.random(48, 48),
  noOfOptions: _.random(48, 48),
  price: _.random(12, 12),
  offers: [],
  cumuOffers: [],
  vestingCutOffDate: "2020-10-06T18:30:00.000+0000",
  startDate: "2020-10-06T18:30:00.000+0000",
  endDate: "2020-10-06T18:30:00.000+0000",
  state: "x".padStart(_.random(20, 50), "x"),
  type: "x".padStart(_.random(20, 50), "x"),
  transactionType: "buyback",
  isWillingNessGiven: false,
  participationConstraints: [],
  recurring: false,
  autoPilot: false,
  recurringInterval: _.random(48, 48),
  recurringIntervalUnit: "x".padStart(_.random(2000, 5000), "x"),
});

export const randomOfferList = (): any => ({
  employeeId: _.random(100000, 200000),
  ids: _.random(100000, 200000),
  granted: _.random(100000, 200000),
  vested: _.random(100000, 200000),
  exercised: _.random(100000, 200000),
  eligibility: _.random(100000, 200000),
  willingness: _.random(100000, 200000),
  pricePerOption: _.random(100000, 200000),
  employeeName: "x".padStart(_.random(20, 50), "x"),
  buybackName: "x".padStart(_.random(20, 50), "x"),
  state: "x".padStart(_.random(20, 50), "x"),
  buybackState: "x".padStart(_.random(20, 50), "x"),
  startDate: "2020-10-06T18:30:00.000+0000",
  endDate: "2020-10-06T18:30:00.000+0000",
  letterUrl: "x".padStart(_.random(20, 100), "x"),
});
