import { QueryFunctionContext } from "react-query";
import {
  RegisteredCompany,
  CheckCompanyResponse,
  AdminProfile,
  CompanyDetailsRequestModel,
} from "../types/OnBoardDataModel";
import hissaApi from "./hissaApi";
import { Company } from "../types/InitiateSeparation";

import {
  GroupName,
  HistoricalRoundModel,
  InvestorDropDown,
} from "../types/HistoricDataModel";
import { UserDetailModel } from "../types/UserDetailModel";
import {
  HistoricOverPageModel,
  InstrumentChartModel,
  RoundNameChartModel,
  ShareHolderChartModel,
} from "../types/HistoricOverView";
import { ReportDto } from "../types/Grant";
import { ShareCapitalDetailsType } from "../types/ShareCapital";

export async function getImageFile(
  context: QueryFunctionContext
): Promise<string> {
  const queryString = context.queryKey[1];
  return hissaApi.get(`/upload/getFile/${queryString}`).then((res) => res.data);
}

export async function onBoardingElasticSearch(
  context: QueryFunctionContext
): Promise<RegisteredCompany[]> {
  const queryString = context.queryKey[1];
  return hissaApi
    .get(`company_dump/_search?q=${queryString}&pretty`)
    .then((res) => res.data);
}

export async function checkCompanyName(
  context: QueryFunctionContext
): Promise<CheckCompanyResponse> {
  const nameOfTheCompany = context.queryKey[1];
  const isNewRegistration = context.queryKey[2];
  return hissaApi
    .get(
      `company/search?companyName=${nameOfTheCompany}&isNewRegistration=${isNewRegistration}`
    )
    .then((res) => res.data);
}

export async function onBoardingUploadAdminDetail(): Promise<AdminProfile> {
  return hissaApi.get(`user/uploadProfile`).then((res) => res.data);
}

export async function saveAdminProfile(
  adminProfileDetails: FormData
): Promise<AdminProfile> {
  return hissaApi
    .post("user/uploadProfilePic", adminProfileDetails)
    .then((res) => res.data);
}
export async function shareCapitalDetail(): Promise<ShareCapitalDetailsType> {
  return hissaApi.get(`capital`).then((res) => res.data);
}

export async function onBoardingUploadCompanyDetail(): Promise<CompanyDetailsRequestModel> {
  return hissaApi
    .get(`company?isNewRegistration=false`)
    .then((res) => res.data);
}

export async function createCompanyDetails(
  formData: FormData
): Promise<Company> {
  return hissaApi.post("company/create", formData).then((res) => res.data);
}

export async function saveHistoricalRound(
  jsonData: HistoricalRoundModel
): Promise<HistoricalRoundModel> {
  return hissaApi.post("historicalRound", jsonData).then((res) => res.data);
}

export async function approveHistoricalRound(
  jsonData: HistoricalRoundModel
): Promise<HistoricalRoundModel> {
  return hissaApi
    .post("historicalRound/submit", jsonData)
    .then((res) => res.data);
}

export async function getHistoricalRound(): Promise<HistoricalRoundModel> {
  return hissaApi.get("historicalRound").then((res) => res.data);
}

export async function updateCaptable(
  jsonData: HistoricalRoundModel
): Promise<HistoricOverPageModel> {
  return hissaApi
    .post("historicalRound/get/captable", jsonData)
    .then((res) => res.data);
}

export async function getDiscardHistoricalRound(
  context: QueryFunctionContext
): Promise<HistoricalRoundModel> {
  const companyId = context.queryKey[1];
  return hissaApi
    .get(`company/discard/on-boarding?companyId=${companyId}`)
    .then((res) => res.data);
}

export async function getInvestorDropdown(): Promise<InvestorDropDown> {
  return hissaApi.get("round/investor/dropdown").then((res) => res.data);
}

export async function addShareHolderGroupName(
  groupName: GroupName
): Promise<void> {
  return hissaApi.post("share_holder_group", groupName).then((res) => res.data);
}

export async function uploadImportedTransaction(
  uploadExcelDto: FormData
): Promise<UserDetailModel> {
  return hissaApi
    .post("usingexcelfile/fillHistoricalDataAfterLogin", uploadExcelDto)
    .then((res) => res.data);
}

export async function getDiscardCompanyFromOnboarding(): Promise<string> {
  return hissaApi.get(`company/discard/on-boarding`).then((res) => res.data);
}

export async function getRoundChart(
  jsonData: HistoricalRoundModel
): Promise<RoundNameChartModel[]> {
  return hissaApi
    .post("historicalRound/get/pieChart/round", jsonData)
    .then((res) => res.data);
}
export async function getInstrumentChart(
  jsonData: HistoricalRoundModel
): Promise<InstrumentChartModel[]> {
  return hissaApi
    .post("historicalRound/get/pieChart/instrument", jsonData)
    .then((res) => res.data);
}
export async function getShareHolderChart(
  jsonData: HistoricalRoundModel
): Promise<ShareHolderChartModel[]> {
  return hissaApi
    .post("historicalRound/get/pieChart/shareholder", jsonData)
    .then((res) => res.data);
}

export async function getReportBasedOnRound(): Promise<string> {
  return hissaApi
    .get(`report-generation/generateReportBeforeApproval?basedOnRound=true`)
    .then((res) => res.data);
}
export async function getReportBasedOnSecurity(): Promise<string> {
  return hissaApi
    .get(`report-generation/generateReportBeforeApproval?basedOnSecurity=true`)
    .then((res) => res.data);
}
export async function getReportBasedOnShareHolder(): Promise<string> {
  return hissaApi
    .get(`report-generation/generateReport?isHistoricalPage=true`)
    .then((res) => res.data);
}
