import _ from "lodash";

/**
 *
 * @param arr
 * @param globalFilterText
 * @param fields defaults to all fields
 * @returns search for text in any of the given fields and return all elements matching the criteria
 */
export function globalFilter<T>(
  arr: T[],
  globalFilterText: string,
  fields?: Array<keyof T>
): T[] {
  const data = arr.filter((i) => {
    const item = i as any;
    fields = fields || (Object.keys(i) as Array<keyof T>);
    for (const field of fields) {
      if (field === "optionHolderState") {
        const replacedString = item?.[field]
          .toString()
          .toLowerCase()
          .replace("_", " ");
        if (replacedString?.includes(globalFilterText.toLowerCase().trim())) {
          return true;
        }
      }
      if (
        item?.[field]
          .toString()
          .toLowerCase()
          .includes(globalFilterText.toLowerCase().trim())
      ) {
        return true;
      }
    }
    return false;
  });
  arr = data;
  return data;
}
/**
 *
 * @param arr
 * @param field
 * @param ascending defaults to true
 * @returns sort the array in place by given field, default is ascending
 */
export function sort<T>(
  arr: T[],
  field?: keyof T | "",
  ascending: boolean = true
): T[] {
  if (!field) return arr;
  const data = arr.sort((a, b) => {
    if (ascending) {
      if (a[field] > b[field]) return 1;
      if (a[field] < b[field]) return -1;
      return 0;
    } else {
      if (a[field] > b[field]) return -1;
      if (a[field] < b[field]) return 1;
      return 0;
    }
  });
  arr = data;
  return data;
}

/**
 *
 * @param arr
 * @param field
 * @returns returns the sum of given field in the array
 */

export function _sum<T>(
  arr: T[],
  field: KeysWithValsOfType<T, number>
): number {
  if (arr.length === 0) return 0;
  const _arr = arr as any[];
  const _type = typeof _arr[0][field];
  if (_type !== "number")
    throw Error(`expected field to be a number but got ${_type} `);
  const sum = _arr.reduce((acc, b) => acc + b[field], 0);
  return sum;
}

/**
 *
 * @param arr
 * @param field
 * @returns returns the maximum of given field in the array
 */

export function _max<T>(
  arr: T[],
  field: KeysWithValsOfType<T, number>
): number {
  const _arr = arr as any[];
  let maxNumber = Number.MIN_VALUE;
  for (let i = 0; i < arr.length; i++) {
    if (_arr[i][field] > maxNumber) {
      maxNumber = _arr[i][field];
    }
  }
  return maxNumber;
}

/**
 *
 * @param arr
 * @param field
 * @returns returns the minimum of given field in the array
 */

export function _min<T>(
  arr: T[],
  field: KeysWithValsOfType<T, number>
): number {
  const _arr = arr as any[];
  let minNumber = Number.MAX_VALUE;
  for (let i = 0; i < arr.length; i++) {
    if (_arr[i][field] < minNumber) {
      minNumber = _arr[i][field];
    }
  }
  return minNumber;
}

/**
 *
 * @param arr
 * @param field
 * @returns returns the first element from the array having maximum of given field
 */
export function _withMax<T>(arr: T[], field: KeysWithValsOfType<T, number>): T {
  const _arr = arr as any[];
  let maxIndex = 0;
  let maxNumber = -Infinity;
  for (let i = 0; i < arr.length; i++) {
    if (_arr[i][field] > maxNumber) {
      maxNumber = _arr[i][field];
      maxIndex = i;
    }
  }
  return _arr[maxIndex] as T;
}

/**
 *
 * @param arr
 * @param field
 * @returns returns the first element from the array having minimum of given field
 */
export function _withMin<T>(arr: T[], field: KeysWithValsOfType<T, number>): T {
  const _arr = arr as any[];
  let minIndex = 0;
  let minNumber = Infinity;
  for (let i = 0; i < arr.length; i++) {
    if (_arr[i][field] < minNumber) {
      minNumber = _arr[i][field];
      minIndex = i;
    }
  }
  return _arr[minIndex] as T;
}

export type KeysWithValsOfType<T, V> = keyof {
  [P in keyof T as T[P] extends V ? P : never]: P;
};

/**
 *
 * @param arr
 * @param groupByFn callback for grouping by
 * @param maxByFn callback for maxing by
 * @returns returns the reduced array with elements having max field from each group
 */

export function groupAndTakeMax<T>(
  arr: T[],
  groupByFn: (t: T) => any,
  maxByFn: (t: T) => any
) {
  const fieldGroupsMap = _.groupBy(arr, groupByFn);
  const reduced = Object.entries(fieldGroupsMap).map(([_field, groupItems]) =>
    _.maxBy(groupItems, maxByFn)
  );
  return reduced;
}

/**
 *
 * @param arr
 * @param groupByFn callback for grouping by
 * @param minByFn callback for maxing by
 * @returns returns the reduced array with elements having min field from each group
 */

export function groupAndTakeMin<T>(
  arr: T[],
  groupByFn: (t: T) => any,
  minByFn: (t: T) => any
) {
  const fieldGroupsMap = _.groupBy(arr, groupByFn);
  const reduced = Object.entries(fieldGroupsMap).map(([_field, groupItems]) =>
    _.minBy(groupItems, minByFn)
  );
  return reduced;
}

export function createRange<T>(
  lowest: number,
  highest: number,
  rangeItem: number
) {
  const rangeSlab: string[] = [];
  let i = lowest;
  do {
    const rangeMin = i;
    const rangeMax =
      highest >= rangeMin + rangeItem ? rangeMin + rangeItem : highest;
    const range = `${rangeMin} - ${rangeMax}`;
    rangeSlab.push(range);
    i += rangeItem;
  } while (i < highest);
  return rangeSlab;
}

function handleSortForFloat(a: number, b: number, ascending: boolean) {
  if (a > b) return ascending ? 1 : -1;
  if (a < b) return ascending ? -1 : 1;
  return 0;
}
