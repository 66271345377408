import { useEffect, useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import { Dialog } from "@mui/material";
import * as _ from "lodash";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormikContext } from "formik";
import Tooltip from "../../components/shared/Tooltip";

import {
  Box,
  ButtonPrimary,
  Center,
  HStack,
  VStack,
  isEsopViewer,
} from "../../components/utils";
import { globalFilter, sort } from "../../utils/arrays";
import BasicMenu from "../../components/shared/Menu";
import Pagination from "../../components/shared/Pagination";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import AlertDialog from "../../components/shared/AlertDialog";
import {
  CompanyDetailsPostModel,
  FounderDetailDataModel,
} from "../../types/OnBoardDataModel";

import { initialFounderData } from "./initValues";
import AddEditFounder from "../../modals/AddEditFounder";

export interface CurrentPageModel {
  currentPage: number;
  pageSize: number;
}

export default function FounderTable() {
  const formik = useFormikContext<CompanyDetailsPostModel>();
  const isUserEsopViewer = isEsopViewer();
  const { values, setFieldValue } = formik;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);

  const [globalFilterText, setGlobalFilterText] = useState("");
  const [sortField, setSortField] = useState<{
    field: keyof FounderDetailDataModel | "" | undefined;
    ascending: boolean;
  }>({ field: "fullName", ascending: false });
  const [dialog, setDialog] = useState<{
    message?: string;
    open: boolean;
    founder?: FounderDetailDataModel;
    mode?: "Edit" | "Add" | "Delete" | "Bulk Delete";
    index?: number;
  }>({
    open: false,
    mode: "Add",
  });

  const [tempFounderDetails, setTempFounderDetails] =
    useState<FounderDetailDataModel[]>();

  const [selectedItems, setSelectedItems] = useState<FounderDetailDataModel[]>(
    []
  );

  let founderList: FounderDetailDataModel[] = values.founders || [];

  founderList = useMemo(() => {
    if (!founderList) return [];
    const filterResult = globalFilter(founderList, globalFilterText, [
      "fullName",
      "emailId",
      "nationality",
    ]);
    const sortResult = sort(
      filterResult,
      sortField?.field,
      sortField?.ascending
    );
    return sortResult;
  }, [founderList, globalFilterText, sortField]);

  const onSelectionChange = (founder: FounderDetailDataModel) =>
    setSelectedItems((founders) =>
      founders.find((individualFounder) => individualFounder === founder)
        ? founders.filter((individualFounder) => individualFounder !== founder)
        : [...founders, founder]
    );

  useEffect(() => {
    if (selectAllChecked) {
      setSelectedItems(founderList);
    } else {
      setSelectedItems([]);
    }
  }, [founderList, selectAllChecked]);
  function handleSecondaryAction() {
    setFieldValue("founders", tempFounderDetails);
  }
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return founderList.slice(firstPageIndex, lastPageIndex);
  }, [founderList, currentPage, pageSize]);

  function handleAction(action: Action, founder?: FounderDetailDataModel) {
    setTempFounderDetails(formik.values.founders);
    const index = formik.values.founders.findIndex(
      (individualFounder) => individualFounder === founder
    );
    if (action.name === "Edit" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Edit",
        founder,
        message: "",
        index,
      });
    } else if (action.name === "Add" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Add",
        founder,
        message: "",
      });
    } else if (action.name === "Delete" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Delete",
        founder,
        message: "Do you want to delete this Founder Details?",
      });
    } else if (action.name === "Bulk Delete" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Bulk Delete",
        founder,
        message: "Do you want to delete selected Founder Details?",
      });
    }
  }

  const deleteAllFounder = () => {
    if (selectedItems.length === 0) return;
    const founders = selectedItems;
    setFieldValue(
      "founders",
      values?.founders?.filter((founder) => !founders?.includes(founder))
    );
    handleAction(
      {
        name: "Bulk Delete",
        disabled: false,
      },
      founders[0]
    );
    toast("Founder Deleted Successfully!", {
      type: "success",
      autoClose: 2000,
    });
    setDialog({ open: false });
    setSelectAllChecked(false);
  };

  function deleteParticularFounder(founder?: FounderDetailDataModel) {
    setFieldValue(
      "founders",
      values?.founders?.filter(
        (individualFounder) => individualFounder !== founder
      )
    );
    toast("Founder Deleted Successfully!", {
      type: "success",
      autoClose: 2000,
    });
    setDialog({ open: false });
  }

  function handleAdd(index: number) {
    const founders = [...values.founders];
    founders.splice(index, 0, initialFounderData);
    setFieldValue("founders", founders);
  }

  return (
    <div className="w-full mt-10 bg-white rounded-md">
      <Box className="w-full p-4 bg-white rounded-lg min-w-min">
        <HStack aria-label="toolbar" className="justify-between mb-8">
          <VStack className="min-w-max">
            <p className="text-lg font-medium text-gray-dark">
              Founder Details
            </p>
            <p className="text-xs font-medium text-gray-light">
              {values.founders?.length} Results
            </p>
          </VStack>
          <HStack className="h-11 min-w-min">
            {!isUserEsopViewer && (
              <>
                <Center className="p-2 px-3 mx-2 rounded text-slate-dark bg-slate-light">
                  <Tooltip text="Delete">
                    <Icon
                      className="cursor-pointer"
                      icon="fluent:delete-28-regular"
                      color={selectedItems.length === 0 ? "gray" : "#E85936"}
                      onClick={() => {
                        setDialog({ open: true });
                        handleAction(
                          {
                            name: "Bulk Delete",
                            disabled: false,
                          },
                          undefined
                        );
                      }}
                      width="20"
                      height="24"
                    />
                  </Tooltip>
                </Center>
                <HStack className="items-center p-2 rounded w-72 bg-slate-light text-slate-dark">
                  <Icon icon="fe:search" width="24" className="mr-2 " />
                  <input
                    type="text"
                    className="w-full font-medium border-0 outline-none text-xs2 bg-inherit"
                    placeholder="Search"
                    value={globalFilterText}
                    onChange={(e) => {
                      setGlobalFilterText(e.target.value);
                    }}
                  ></input>
                </HStack>
                <ButtonPrimary
                  className="mx-2 min-w-max"
                  onClick={() => {
                    handleAdd(values.founders?.length);
                    handleAction({
                      name: "Add",
                      disabled: false,
                    });
                  }}
                >
                  Add
                </ButtonPrimary>
              </>
            )}
            <Dialog open={dialog.open} maxWidth="md">
              {dialog.mode === "Delete" ? (
                <AlertDialog
                  onClose={() => setDialog({ open: false })}
                  open={dialog.open}
                  message={dialog.message}
                  onPrimaryAction={() =>
                    deleteParticularFounder(dialog.founder)
                  }
                  onSecondaryAction={() => setDialog({ open: false })}
                />
              ) : dialog.mode === "Edit" ? (
                <AddEditFounder
                  founderIndex={dialog.index || 0}
                  founder={dialog.founder}
                  onPrimaryAction={() => setDialog({ open: false })}
                  onSecondaryAction={() => {
                    handleSecondaryAction();
                    setDialog({ open: false });
                  }}
                  mode="Edit"
                />
              ) : dialog.mode === "Add" ? (
                <AddEditFounder
                  founderIndex={values.founders.length - 1}
                  onPrimaryAction={() => setDialog({ open: false })}
                  onSecondaryAction={() => {
                    handleSecondaryAction();
                    setDialog({ open: false });
                  }}
                  mode="Add"
                />
              ) : (
                <AlertDialog
                  onClose={() => setDialog({ open: false })}
                  open={dialog.open}
                  message={dialog.message}
                  onPrimaryAction={() => deleteAllFounder()}
                  onSecondaryAction={() => setDialog({ open: false })}
                />
              )}
            </Dialog>
          </HStack>
        </HStack>
        <Box className="max-h-full overflow-auto">
          <table className="w-full table-space">
            <thead className="text-xs font-medium text-gray-light">
              <tr className="">
                <td className="py-3">
                  <input
                    type="checkbox"
                    className="cursor-pointer accent-orange-501 outline-hidden"
                    checked={selectAllChecked}
                    onChange={(e) => {
                      setSelectAllChecked(!selectAllChecked);
                    }}
                  ></input>
                </td>

                <>
                  <td
                    className="py-3 hover:cursor-pointer"
                    onClick={() =>
                      setSortField({
                        field: "fullName",
                        ascending: !sortField?.ascending,
                      })
                    }
                  >
                    Founder Name
                  </td>
                </>

                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "emailId",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Email Id
                </td>
                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "nationality",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Nationality
                </td>
                <td className="py-3"></td>
              </tr>
            </thead>
            {values.founders && (
              <tbody className={""}>
                {currentTableData &&
                  currentTableData?.map((founder, index) => (
                    <tr
                      key={founder.emailId + index}
                      className="border-t border-dashed "
                    >
                      <td className="py-5 align-top">
                        <Box>
                          <input
                            type="checkbox"
                            className="cursor-pointer accent-orange-501 outline-hidden "
                            checked={selectedItems.indexOf(founder) !== -1}
                            onChange={(e) => onSelectionChange(founder)}
                          ></input>
                        </Box>
                      </td>

                      <>
                        <td className="py-4 align-top ">
                          <HStack className="w-32 ">
                            <Box>
                              <p
                                className={` text-xs font-medium text-gray-dark `}
                              >
                                {founder.fullName}
                              </p>
                            </Box>
                          </HStack>
                        </td>
                      </>

                      <td className="py-4 align-top">
                        <Box className="">
                          <HStack>
                            <p
                              className={` w-32 text-xs font-medium text-gray-dark`}
                            >
                              {founder.emailId}
                            </p>
                          </HStack>
                        </Box>
                      </td>
                      <td className="py-4 align-top">
                        <Box className="">
                          <p className={` text-xs font-normal text-gray-dark`}>
                            {founder.nationality}
                          </p>
                        </Box>
                      </td>
                      <td className="px-2 py-4 align-top">
                        <CTADropdown
                          actions={[
                            {
                              name: "Edit",
                              disabled: isUserEsopViewer,
                            },
                            {
                              name: "Delete",
                              disabled: isUserEsopViewer,
                            },
                          ]}
                          onAction={(action) => handleAction(action, founder)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </table>
          {values.founders?.length <= 0 && (
            <div className="text-center text-black">
              <h1>No Founders Data Found. Please Add the founders</h1>
            </div>
          )}
        </Box>
        <Box className="flex justify-between mt-8 cursor-pointer">
          <BasicMenu
            defaultValue={pageSize}
            options={[5, 10, 20, 50, 100]}
            onOptionChange={(value) => {
              setPageSize(value);
              setCurrentPage(1);
            }}
          />
          <Pagination
            className=""
            currentPage={currentPage}
            totalCount={founderList?.length}
            pageSize={pageSize}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </Box>
      </Box>
    </div>
  );
}
