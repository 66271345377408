/* eslint-disable max-len */
import { QueryFunctionContext } from "react-query";
import {
  ErrorInterface,
  ExercisableDetails,
  optionBuybackDetail,
  StateBuybackDetail,
} from "../types/OptionBuyback";
import { DeleteResponse } from "../types/Employee";
import hissaApi from "./hissaApi";
import esopNodeApi from "./esopNodeApi";

// details res type
export async function getOptionBuybackDetails(): Promise<
  optionBuybackDetail[]
> {
  return esopNodeApi
    .get("/liquidityEvent/getAllLiquidityEvents")
    .then((res) => res.data.data);
}

export async function createOptionBuybackDetails(
  addOptionBuyback: optionBuybackDetail
): Promise<optionBuybackDetail> {
  return esopNodeApi
    .post("/liquidityEvent/createEvent", addOptionBuyback)
    .then((res) => res.data.data);
}

export async function exercisableDetail(
  context: QueryFunctionContext
): Promise<ExercisableDetails> {
  const percentage = context.queryKey[1];
  const cutOffDate = context.queryKey[2];
  return hissaApi
    .get(
      `/optionsBuyBack/totalExercisableOptions?buyBackPercentage=${percentage}&cutOffDate=${cutOffDate}`
    )
    .then((res) => res.data);
}

export async function buybackState(
  context: QueryFunctionContext
): Promise<StateBuybackDetail[]> {
  const buybackId = context.queryKey[1];
  return esopNodeApi
    .get(`/liquidityEvent/${buybackId}/getLiquidityEventOffers`)
    .then((res) => res.data.data);
}
export async function discardBuyback(buybackId: number) {
  return esopNodeApi
    .delete(`/liquidityEvent/${buybackId}/deleteLiquidityEvent`)
    .then((res) => res.data);
}

export async function sendLiquidationIntimation(
  buybackId: number
): Promise<string> {
  return esopNodeApi
    .put(`/liquidityEvent/${buybackId}/startLiquidation`)
    .then((res) => res.data.data);
}

export async function deleteBuybackOffer(
  id: number[]
): Promise<DeleteResponse> {
  const ids = id;
  return esopNodeApi
    .delete(`/liquidityEvent/${ids}/deleteLiquidityEvent`)
    .then((res) => res.data);
}

export async function uploadEmployeeOffersList(file: any): Promise<any> {
  return hissaApi
    .post("/usingexcelfile/createTransactionOffers", file)
    .then((res) => res.data);
}

export async function uploadTDSExcel(body: any): Promise<any> {
  return hissaApi
    .post("/usingexcelfile/uploadTDS", body)
    .then((res) => res.data);
}

//node js
export async function startLiquidityEvent(
  eventId: number
): Promise<optionBuybackDetail | ErrorInterface> {
  return esopNodeApi
    .put(`/liquidityEvent/${eventId}/startLiquidityEvent`)
    .then((res) => res.data.data);
}

export async function completeLiquidityEvent(
  eventId: number
): Promise<optionBuybackDetail | ErrorInterface> {
  return esopNodeApi
    .put(`/liquidityEvent/${eventId}/completeLiquidityEvent`)
    .then((res) => res.data.data);
}

export async function closeLiquidityEvent(
  eventId: number
): Promise<optionBuybackDetail | ErrorInterface> {
  return esopNodeApi
    .put(`/liquidityEvent/${eventId}/closeLiquidityEvent`)
    .then((res) => res.data.data);
}

export async function deleteLiquidityEvent(eventId: number): Promise<any> {
  return esopNodeApi.delete(`/liquidityEvent/${eventId}/deleteLiquidityEvent`);
}

export async function editLiquidityEvent(
  editOptionBuyback: optionBuybackDetail
): Promise<optionBuybackDetail> {
  return esopNodeApi
    .put(
      `/liquidityEvent/${editOptionBuyback.id}/editLiquidityEvent`,
      editOptionBuyback
    )
    .then((res) => res.data.data);
}

export async function editPilotMode(pilotDto: {
  eventId: number | undefined;
  mode: boolean;
}): Promise<any> {
  return esopNodeApi
    .put(`/liquidityEvent/${pilotDto.eventId}/autoPilotMode/${pilotDto.mode}`)
    .then((res) => res.data.data);
}
