import { Icon } from "@iconify/react";
import React from "react";
import { VStack } from "./utils";

function SortingComponent({
  fieldName,
  isAscending,
  selectedFieldName,
  onChangeSort,
}: {
  fieldName: string;
  isAscending: boolean;
  selectedFieldName: string;
  onChangeSort: () => void;
}) {
  return (
    <VStack className="ml-2" onClick={onChangeSort}>
      <Icon
        icon="humbleicons:triangle"
        className={` ${
          fieldName === selectedFieldName && isAscending
            ? "text-orange-501 scale-125"
            : "text-gray-400"
        } rounded`}
        height={8}
        width={10}
      />
      <Icon
        icon="humbleicons:triangle"
        className={`${
          fieldName === selectedFieldName && !isAscending
            ? "text-orange-501 scale-125"
            : "text-gray-400"
        } rounded`}
        height={8}
        rotate={90}
        width={10}
      />
    </VStack>
  );
}

export default SortingComponent;
