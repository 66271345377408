import React, { useEffect, useState } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { format, subDays } from "date-fns/esm";
import { Box, ButtonPrimary, HStack, VStack } from "../../components/utils";
import { useAllVestings } from "../../queries/vest";
import { useGraphFilter } from "../../store/GraphFilters";
import { useAllExercises } from "../../queries/exercise";
import { useGrants } from "../../queries/esopPlan";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const vestingDetailMenu = ["This week", "This month", "This year", "All Time"];
const graphTypes = ["Grant", "Vest", "Exercise"];

function VestingDetails() {
  const [activeMenu, setActiveMenu] = useState<string>("All Time");
  const [activeGraph, setActiveGraph] = useState<string>("Grant");
  const [expandedGraph, setExpandedGraph] = useState(false);
  const [graphText, setGraphText] = useState("+");
  const filter = useGraphFilter();

  const { data: _grants } = useGrants();
  let grants = _grants || [];
  grants = grants.filter(
    (grant) =>
      new Date(grant.grantDate) >= filter.startDate &&
      new Date(grant.grantDate) <= filter.endDate
  );

  const { data: _exercises } = useAllExercises();
  let exercises = _exercises || [];
  exercises = exercises.filter(
    (exercise) =>
      new Date(exercise.exerciseDate) >= filter.startDate &&
      new Date(exercise.exerciseDate) <= filter.endDate
  );

  const { data: _vestings } = useAllVestings();
  let vestings = _vestings || [];
  vestings = vestings.filter(
    (vesting) =>
      new Date(vesting.projectedVestingDate) >= filter.startDate &&
      new Date(vesting.projectedVestingDate) <= filter.endDate
  );

  const exercisesData: ChartData<"line", number[], string> = {
    labels: exercises.map((e) => format(new Date(e.exerciseDate), "dd MMM yy")),
    datasets: [
      {
        label: "Exercises",
        data: exercises.map((e) => e.cumulativeExercisedOptions),
        borderColor: "rgb(255, 99, 132)",
        pointStyle: "line",
      },
    ],
  };

  const grantsData: ChartData<"line", number[], string> = {
    labels: grants.map((g) => format(new Date(g.grantDate), "dd MMM yy")),
    datasets: [
      {
        label: "Grants",
        data: grants.map((g) => g.cumulativeGrantedOptions),
        borderColor: "rgb(255, 99, 132)",
        pointStyle: "line",
      },
    ],
  };

  const vestingsData: ChartData<"line", number[], string> = {
    labels: vestings.map((v) =>
      format(new Date(v.projectedVestingDate), "dd MMM yy")
    ),
    datasets: [
      {
        label: "Projected",
        data: vestings.map((v) => v.cumulativeProjectedVestedOptions),
        borderColor: "rgb(255, 99, 132)",
        pointStyle: "line",
      },
      {
        label: "Actual",
        data: vestings.map((v) => v.cumulativeActualVestedOptions),
        borderColor: "rgb(53, 162, 235)",
        pointStyle: "line",
      },
    ],
  };

  useEffect(() => {
    if (activeMenu === "All Time") {
      filter.reset();
    }
    if (activeMenu === "This year") {
      filter.setStartDate(new Date(new Date().getFullYear(), 0, 1));
      filter.setEndDate(new Date());
    }
    if (activeMenu === "This month") {
      filter.setStartDate(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      );
      filter.setEndDate(new Date());
    }
    if (activeMenu === "This week") {
      filter.setStartDate(subDays(new Date(), 7));
      filter.setEndDate(new Date());
    }
  }, [activeMenu]);

  const getGraphData = () => {
    if (activeGraph === "Vest") {
      return vestingsData;
    } else if (activeGraph === "Exercise") {
      return exercisesData;
    } else if (activeGraph === "Grant") {
      return grantsData;
    }
    return grantsData;
  };

  return (
    <Box className="w-full py-4 bg-white rounded-lg min-w-min">
      <HStack className="justify-between">
        <Box className="w-1/3 items-center p-4 text-lg font-medium text-gray-dark">
          Vesting Details
        </Box>
        <HStack className="items-center justify-end w-2/3 py-2 mr-8 text-sm font-medium text-gray-dark">
          {vestingDetailMenu &&
            expandedGraph &&
            vestingDetailMenu.map((menu) => (
              <VStack key={menu} className="justify-between">
                <Box
                  className={`mr-8 cursor-pointer capitalize`}
                  onClick={() => setActiveMenu(menu)}
                >
                  {menu}
                </Box>
                <Box
                  className={`${
                    menu === activeMenu
                      ? "mr-8 border-b-2 border-orange-501"
                      : ""
                  } `}
                ></Box>
              </VStack>
            ))}
          <ButtonPrimary
            className="p-4"
            onClick={() => {
              setExpandedGraph((state) => !state);
              setGraphText((state) => {
                if (state === "+") {
                  return "-";
                } else {
                  return "+";
                }
              });
            }}
          >
            {graphText}
          </ButtonPrimary>
        </HStack>
      </HStack>
      {expandedGraph && (
        <>
          <Box className="border-b border-solid border-[#EBEBEB]"></Box>
          <HStack className="justify-between w-full gap-8 bg-white rounded-lg ">
            <VStack className="w-full py-2 px-2">
              <HStack className="justify-around p-1 text-sm font-medium rounded-lg text-gray-dark bg-slate-100 ">
                {graphTypes &&
                  graphTypes.map((graph) => (
                    <Box
                      key={graph}
                      className={`w-40 p-1 text-center cursor-pointer ${
                        activeGraph === graph
                          ? "bg-white rounded text-rose-500"
                          : ""
                      }`}
                      onClick={() => setActiveGraph(graph)}
                    >
                      {graph}
                    </Box>
                  ))}
              </HStack>
              <Line
                className="flex"
                options={{
                  responsive: true,
                  plugins: {
                    decimation: {
                      enabled: true,
                      samples: 500,
                      algorithm: "lttb",
                    },
                  },
                  scales: {
                    y: {
                      title: {
                        display: true,
                        text: "Grants",
                      },
                    },
                    x: {
                      title: {
                        display: true,
                        text: "Date",
                      },
                    },
                  },
                }}
                data={getGraphData()}
              />
            </VStack>
          </HStack>
        </>
      )}
    </Box>
  );
}

export default VestingDetails;
