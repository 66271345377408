import { useNavigate } from "react-router";
import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";

import {
  Box,
  Center,
  HStack,
  VStack,
  isAdminViewer,
  isEsopViewer,
} from "../../components/utils";
import GrantsTable from "../esopOverview/GrantsTable";
import CardPool from "../../shared/card-pool";
import CardPlan from "../../shared/card-plan";
import CardDraft from "../../shared/card-draft";
import CardPend from "../../shared/card-pend";
import { useEsopPlans, useGrants } from "../../queries";
import GranvtVSYearsExp from "./granvtVSYearsExp";
import VestingEvents from "./VestingEvents";
import BCHeader from "../../shared/BCHeader";
import { Dropdown } from "../../utils/interfaces/BCHeader";

import AddOrEditPlan from "./AddPlan";
import useIsMobile from "../../utils/detectDevice";
import CardTransaction from "../../shared/card-transaction";
import AllPlansVestedUnVestedGraph from "../../shared/allPlansVestedUnvested";
import { useAuthorizationStore } from "../../store";

function AllPlans() {
  const navigate = useNavigate();
  const { data: grants } = useGrants();
  const { data: esopTableDtoList } = useEsopPlans();
  const { authority } = useAuthorizationStore();
  const plans = esopTableDtoList || [];
  const [selectedPlan, setSelectedPlan] = useState<Dropdown>({
    name: "",
    id: "",
  });
  const [open, setOpen] = useState(false);
  const isUserAdminViewer = isAdminViewer();
  const isUserEsopViewer = isEsopViewer();
  const planDropdown: Dropdown[] = [];
  useEffect(() => {
    setSelectedPlan({ name: "All Plans", id: "Total" });
  }, []);
  plans.forEach((plan, index) => {
    planDropdown.push({ name: plan.planName, id: plan.esopPlanId });
  });
  function handleAction() {
    if (!(isUserAdminViewer || isUserEsopViewer)) {
      setOpen(!open);
    }
  }

  planDropdown.unshift(selectedPlan);
  function handlePlan(e: React.ChangeEvent<HTMLSelectElement>) {
    const id = e.target.value;
    if (id === "Total") {
      navigate("/options/allPlans");
    } else navigate(`/options/planView/${id}`);
  }
  const { isMobile } = useIsMobile();
  return (
    <VStack className="gap-4">
      <BCHeader
        className=" items-baseline"
        bcTitle="All Plans"
        bcSubTitle={
          selectedPlan?.name === "All Plans" ? "" : selectedPlan?.name || ""
        }
        buttonName="Create ESOP Plan"
        onClick={handleAction}
        selectOption={handlePlan}
        selectedValue={selectedPlan}
        dropdown={planDropdown}
      />

      <Dialog open={open} maxWidth="lg">
        <div className="w-[900px] mx-auto bg-white rounded-lg">
          <AddOrEditPlan
            mode="Add"
            onClose={() => {
              setOpen(!open);
            }}
          />
        </div>
      </Dialog>
      <Box className="lg:grid-cols-3 md:grid-cols-2 grid gap-6 overflow-auto rounded-lg">
        <CardPlan />
        <CardPool />
        <CardTransaction />
      </Box>
      <Box
        className={`grid gap-6 overflow-auto  ${
          isMobile ? "grid-cols-1" : "grid-cols-2"
        }`}
      >
        <VestingEvents />
        <CardPend></CardPend>
      </Box>
      {(authority === "ROLE_ADMIN" || authority === "ROLE_ESOP_ADMIN") && (
        <Box className=" bg-white rounded-lg">
          <GranvtVSYearsExp />
        </Box>
      )}
      <Box className={`w-full ${isMobile ? "overflow-x-auto" : "grow"} mb-10`}>
        {grants && <GrantsTable />}
      </Box>
    </VStack>
  );
}

export default AllPlans;
