import { useMutation, useQuery } from "react-query";
import {
  getEsopSubsidiaryCompanies,
  addSubsidiaryCompany,
  editSubsidiaryCompany,
  deleteSubsidiaryCompany,
} from "../api/EsopSubsidiaryCompany";

import { queryClient } from "./client";

export function useEsopSubsidiaryCompaniesDetail() {
  return useQuery({
    queryKey: "allEsopSubsidiaryCompaniesDetail",
    queryFn: getEsopSubsidiaryCompanies,
  });
}

export function useAddEsopSubsidiaryCompany() {
  return useMutation({
    mutationKey: "addEsopSubsidiaryCompany",
    mutationFn: addSubsidiaryCompany,
    onSuccess: () => {
      queryClient.invalidateQueries("allEsopSubsidiaryCompaniesDetail");
      queryClient.refetchQueries("allEsopSubsidiaryCompaniesDetail");
    },
    onError: () => {},
  });
}

export function useEditEsopSubsidiaryCompany() {
  return useMutation({
    mutationKey: "editEsopSubsidiaryCompany",
    mutationFn: editSubsidiaryCompany,
    onSuccess: () => {
      queryClient.invalidateQueries("allEsopSubsidiaryCompaniesDetail");
      queryClient.refetchQueries("allEsopSubsidiaryCompaniesDetail");
    },
  });
}

export function useDeleteEsopSubsidiaryCompany() {
  return useMutation({
    mutationKey: "deleteEsopSubsidiaryCompany",
    mutationFn: deleteSubsidiaryCompany,
    onSuccess: () => {
      queryClient.invalidateQueries("allEsopSubsidiaryCompaniesDetail");
      queryClient.refetchQueries("allEsopSubsidiaryCompaniesDetail");
    },
  });
}
