import React, { useEffect, useMemo, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { Dialog } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Box,
  HStack,
  isAdminViewer,
  isEsopViewer,
  VStack,
} from "../../components/utils";
import { globalFilter, sort } from "../../utils/arrays";
import BasicMenu from "../../components/shared/Menu";
import Pagination from "../../components/shared/Pagination";
import Avatar from "../esopOverview/Avatar";
import { EsopPlan } from "../../types/EsopPlan";
import StatusLabel from "../esopOverview/StatusLabel";
import AddOrEditPlan from "./AddPlan";
import {
  queryClient,
  useDeletePlan,
  useEsopPlans,
  useUploadPlanDocument,
} from "../../queries";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import PlanStateChange from "../esopOverview/PlanStateChange";
import convertToBase64 from "../../utils/convertToBase64";
import AlertDialog from "../../components/shared/AlertDialog";
import { DeleteResponse } from "../../types/Employee";
import { useError } from "../../store/errorStore";
import { getCurrencyType } from "../../utils/currencyFormatter";
import { AddOwnershipReq } from "../../types/Ownership";
import { AddOrEditOwnership } from "./AddOrEditOwnership";
import { useAuthorizationStore } from "../../store/useAuthorizationStore";
import useIsMobile from "../../utils/detectDevice";
import SortingComponent from "../../components/SortingVariation";
import DocumentsDialog from "./DocumentsDialog";
import { EmptyTable } from "../../components/shared/EmptyTable";
import PlanTableAgGrid from "./PlanTableAgGrid";
import AgGridGrantsTable from "../esopOverview/AgGridGrantsTable";

function PlansTable({ data }: { data: EsopPlan[] }) {
  const navigate = useNavigate();
  const currency = getCurrencyType();
  const { isPlaceholderData } = useEsopPlans();
  const { authority } = useAuthorizationStore();
  const errorMessage = useError();
  // filter and sort logic
  const [globalFilterText, setGlobalFilterText] = useState("");
  const [sortField, setSortField] = useState<{
    field: keyof EsopPlan | "" | undefined;
    ascending: boolean;
  }>();
  const isUserAdminViewer = isAdminViewer();
  const isUserEsopViewer = isEsopViewer();
  data = useMemo(() => {
    if (!data) return [];
    let filterResult = globalFilter(data, globalFilterText, [
      "planName",
      "esopPlanState",
    ]);
    filterResult = filterResult.filter((plan) => plan.planName !== "Total");
    const sortResult = sort(
      filterResult,
      sortField?.field,
      sortField?.ascending
    );
    return sortResult;
  }, [data, globalFilterText, sortField]);
  useEffect(() => {
    setCurrentPage(1);
  }, [globalFilterText]);
  // selection logic
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const onSelectionChange = (item: EsopPlan) => {
    setSelectedItems((items) =>
      items.find((i) => i === item)
        ? items.filter((i) => i !== item)
        : [...items, item]
    );
  };
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  useEffect(() => {
    if (selectAllChecked) {
      setSelectedItems(data);
    } else {
      setSelectedItems([]);
    }
  }, [data, selectAllChecked]);

  //pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [confirmDialog, setConfirmDialog] = useState<{
    open: boolean;
    plan?: EsopPlan;
  }>({ open: false, plan: undefined });
  const [stateChangeDialog, setStateChangeDialog] = useState<{
    open: boolean;
    plan?: EsopPlan;
  }>({ open: false, plan: undefined });
  const [uploadDocumentDialog, setUploadDocumentDialog] = useState<{
    open: boolean;
    plan?: EsopPlan;
  }>({ open: false, plan: undefined });
  const [dialog, setDialog] = useState<{
    open: boolean;
    plan?: EsopPlan;
    mode?: "Edit" | "Add" | "Clone";
  }>({ open: false, plan: undefined, mode: "Add" });
  const [ownershipDialog, setOwnershipDialog] = useState<{
    open: boolean;
    data?: EsopPlan;
  }>({ open: false, data: undefined });
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [data, currentPage, pageSize]);
  const [selectedPlan, setSelectedPlan] = useState<EsopPlan>();
  const inputFileRef: React.RefObject<HTMLInputElement> = useRef(null);
  const { mutate: uploadPlanDocument, status } = useUploadPlanDocument();
  const { mutate: deletePlan } = useDeletePlan();
  let sumTotalOptions = 0;
  let sumGrantedOptions = 0;
  let sumAvailableOptions = 0;
  data?.forEach((plan) => {
    sumTotalOptions += plan.totalShares * plan.conversionNumber;
    sumGrantedOptions +=
      plan.totalShares * plan.conversionNumber - plan.optionsReserved;
    sumAvailableOptions += plan.optionsReserved;
  });
  function handleAction(plan: EsopPlan, action: Action) {
    if (action.disabled) {
      return;
    }
    if (action.name === "Amend Plan" && plan.esopPlanState !== "Amendment") {
      setDialog({ open: true, mode: "Edit", plan });
    }
    if (action.name === "Update Plan Status" && !action.disabled) {
      setStateChangeDialog({ open: true, plan });
    } else if (action.name === "Upload Plan Document" && !action.disabled) {
      setUploadDocumentDialog({ open: true, plan });
      setSelectedPlan(plan);
    } else if (action.name === "Delete Plan" && !action.disabled) {
      setConfirmDialog({ open: true, plan });
    } else if (action.name === "Modify Plan Ownership" && !action.disabled) {
      setOwnershipDialog({ open: true, data: plan });
    }
  }
  async function onPlanDocumentUpload(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const allowedFileExtensions = ["pdf"];
      if (
        allowedFileExtensions.some((extension) => file.name.endsWith(extension))
      ) {
        const base64 = await convertToBase64(file);
        const uploadPlanDocumentDTO = {
          planName: selectedPlan?.planName,
          file: base64 as string,
        };
        uploadPlanDocument(uploadPlanDocumentDTO, {
          onSuccess: () => {
            toast("Plan Document uploaded successfully!", { type: "success" });
          },
          onError: (err: any) => {
            errorMessage.setMessage(err.response.data.reason);
            toast(err.response.data.reason, { type: "error", autoClose: 2000 });
          },
        });
      } else {
        toast(
          ` Invalid file type, allowed file types are ${allowedFileExtensions.join(
            ", "
          )}`,
          { type: "error" }
        );
      }
    }
    event.target.files = null;
    event.target.value = "";
  }
  function handlePlanDelete(planId: number): void {
    deletePlan(planId, {
      onSuccess: () => {
        toast("Plan Deleted successfully!", { type: "success" });
        setConfirmDialog({ open: false, plan: undefined });
      },
      onError: (err: any) => {
        errorMessage.setMessage(err.response.data.reason);
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
      },
    });
  }
  function viewPlan(plan: EsopPlan) {
    navigate(`/options/planView/${plan.esopPlanId}`);
  }
  const { isMobile } = useIsMobile();
  return (
    <Box
      className={`p-8 bg-white rounded-lg ${
        isMobile ? "min-w-[1040px]" : "min-w-min"
      }`}
    >
      <Dialog open={dialog.open} maxWidth="lg">
        <div className="w-[900px] mx-auto bg-white rounded-lg">
          <AddOrEditPlan
            mode="Edit"
            plan={dialog.plan}
            onClose={() => {
              setDialog({ open: false });
            }}
          />
        </div>
      </Dialog>
      <Dialog open={uploadDocumentDialog.open} maxWidth="lg">
        <div className="w-[900px] mx-auto bg-white rounded-lg">
          <DocumentsDialog
            plan={uploadDocumentDialog.plan}
            onClose={() => {
              setUploadDocumentDialog({ open: false, plan: undefined });
            }}
          />
        </div>
      </Dialog>
      <Dialog open={stateChangeDialog.open} maxWidth="lg">
        {stateChangeDialog.plan && (
          <PlanStateChange
            plan={stateChangeDialog.plan}
            onClose={() => setStateChangeDialog({ open: false })}
          />
        )}
      </Dialog>
      <Dialog open={ownershipDialog.open} maxWidth="lg">
        <div className="w-[700px] h-[400px] ">
          <AddOrEditOwnership
            onClose={() => setOwnershipDialog({ open: false })}
            data={ownershipDialog.data}
          />
        </div>
      </Dialog>
      <Dialog
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false })}
        maxWidth="lg"
      >
        {confirmDialog.plan && (
          <AlertDialog
            onClose={() => setConfirmDialog({ open: false })}
            open={confirmDialog.open}
            message="Are you sure you want to delete this plan ?"
            onPrimaryAction={() =>
              handlePlanDelete(confirmDialog.plan?.esopPlanId as number)
            }
            onSecondaryAction={() =>
              setConfirmDialog({ open: false, plan: undefined })
            }
          />
        )}
      </Dialog>
      <Toolbar
        length={data.length}
        globalFilterText={globalFilterText}
        setGlobalFilterText={setGlobalFilterText}
      ></Toolbar>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: `${(data.length >= 10 ? 10 : data.length + 3) * 60}px`,
          }}
          className="w-full max-h-full overflow-x-auto ag-theme-material h-full"
        >
          <PlanTableAgGrid
            key={"PlanTable"}
            planTableData={data}
            handleAction={handleAction}
          />
        </Box>
      </HStack>
      <HStack>
        <Box
          // style={{ height: `${pageSize * 64 + 128}px` }}
          className="w-full max-h-full overflow-x-auto hidden"
        >
          <table className={`w-full table-space overflow-auto `}>
            <thead className="text-xs font-medium text-gray-light">
              <tr className="">
                <td className="py-3"></td>
                <td>
                  <HStack className="items-center hover:cursor-pointer">
                    PLAN NAME
                    <SortingComponent
                      fieldName="planName"
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      onChangeSort={() => {
                        setSortField({
                          field: "planName",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                  </HStack>
                </td>
                <td>
                  <HStack className="items-center hover:cursor-pointer">
                    TOTAL
                    <SortingComponent
                      fieldName="totalShares"
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      onChangeSort={() => {
                        setSortField({
                          field: "totalShares",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                  </HStack>
                </td>
                <td>
                  <HStack className="items-center hover:cursor-pointer">
                    GRANTED
                    <SortingComponent
                      fieldName="issuedShares"
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      onChangeSort={() => {
                        setSortField({
                          field: "issuedShares",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                  </HStack>
                </td>
                <td>
                  <HStack className="items-center hover:cursor-pointer">
                    AVAILABLE
                    <SortingComponent
                      fieldName="optionsReserved"
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      onChangeSort={() => {
                        setSortField({
                          field: "optionsReserved",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                  </HStack>
                </td>
                <td>
                  <HStack className="items-center hover:cursor-pointer">
                    STATE
                    <SortingComponent
                      fieldName="esopPlanState"
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      onChangeSort={() => {
                        setSortField({
                          field: "esopPlanState",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                  </HStack>
                </td>
                <td className="py-3 hover:cursor-pointer"></td>
              </tr>
            </thead>
            <tbody className={`${isPlaceholderData ? "loading" : ""}`}>
              {currentTableData &&
                currentTableData?.map((plan) => (
                  <tr
                    key={plan.esopPlanId}
                    className="border-t border-dashed cursor-pointer hover:bg-slate-50"
                    onClick={() => viewPlan(plan)}
                  >
                    <td className="w-12 px-2 py-4 align-top">
                      {!isPlaceholderData ? (
                        <Avatar name={plan.planName} />
                      ) : (
                        <div className="w-8 h-8 loading-opacity"></div>
                      )}
                    </td>
                    <td className="w-1/3 py-4 align-top">
                      <Box className="">
                        <p className="text-xs font-medium text-gray-dark">
                          {plan.planName}
                        </p>
                        <p className="capitalize text-xxs text-gray-light text-wrap">
                          {plan?.planDescription?.toLowerCase()}
                        </p>
                      </Box>
                    </td>
                    <td className="py-4 align-top">
                      <Box className="">
                        <HStack>
                          <p className="text-xs font-medium text-gray-dark">
                            {(plan?.totalShares || 0) *
                              (plan?.conversionNumber || 1)}
                          </p>
                        </HStack>
                      </Box>
                    </td>
                    <td className="py-4 align-top">
                      <Box>
                        <p className="text-xs font-medium text-gray-dark">
                          {(
                            (plan?.totalShares || 0) *
                              (plan?.conversionNumber || 1) -
                            (plan?.optionsReserved || 0)
                          ).toLocaleString(currency)}
                        </p>
                      </Box>
                    </td>
                    <td className="py-4 align-top">
                      <Box>
                        <p className="text-xs font-medium text-gray-dark">
                          {plan?.optionsReserved.toLocaleString(currency)}
                        </p>
                      </Box>
                    </td>
                    <td className="py-4 align-top">
                      <Box>
                        <p className="text-xs font-medium text-gray-dark">
                          <StatusLabel
                            state={plan.esopPlanState || ""}
                          ></StatusLabel>
                        </p>
                      </Box>
                    </td>

                    <td
                      className="px-2 py-4 align-top"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <CTADropdown
                        actions={[
                          {
                            name: "Amend Plan",
                            disabled:
                              plan.esopPlanState === "Amendment" ||
                              isUserAdminViewer ||
                              isUserEsopViewer,
                          },
                          {
                            name: "Update Plan Status",
                            disabled:
                              plan.esopPlanState === "Active" ||
                              plan.esopPlanState === "Rejected" ||
                              isUserEsopViewer ||
                              isUserAdminViewer,
                          },
                          {
                            name: "Delete Plan",
                            disabled:
                              plan.issuedShares > 0 ||
                              isUserEsopViewer ||
                              isUserAdminViewer,
                          },
                          {
                            name: "Upload Plan Document",
                            disabled: isUserAdminViewer || isUserEsopViewer,
                          },
                          {
                            name: "Modify Plan Ownership",
                            disabled: !(
                              authority === "ROLE_ADMIN" ||
                              authority === "ROLE_ESOP_ADMIN"
                            ),
                          },
                        ]}
                        onAction={(action) => handleAction(plan, action)}
                      />
                    </td>
                  </tr>
                ))}
              <tr className="border-t border-dashed" key={"tableTotal"}>
                <td></td>
                <td className="py-4 font-medium align-top text-gray-light">
                  TOTAL
                </td>
                <td className="py-4 align-top">
                  <p className="text-xs font-medium text-gray-dark">
                    {sumTotalOptions.toLocaleString(currency)}
                  </p>
                  <p className="capitalize text-xxs text-gray-light">
                    Total Options
                  </p>
                </td>
                <td className="py-4 align-top">
                  <p className="text-xs font-medium text-gray-dark">
                    {sumGrantedOptions.toLocaleString(currency)}
                  </p>
                  <p className="capitalize text-xxs text-gray-light">
                    Granted Options
                  </p>
                </td>
                <td className="py-4 align-top">
                  <p className="text-xs font-medium text-gray-dark">
                    {sumAvailableOptions.toLocaleString(currency)}
                  </p>
                  <p className="capitalize text-xxs text-gray-light">
                    Available Options
                  </p>
                </td>
              </tr>
            </tbody>
          </table>

          {currentTableData.length === 0 && (
            <EmptyTable
              header="No Plans"
              subHeader="Click on Create an ESOP Plan button to add a Plan."
            />
          )}

          <Box className="flex justify-between mt-8">
            <BasicMenu
              defaultValue={pageSize}
              options={[5, 10, 20, 50, 100]}
              onOptionChange={(value) => {
                setPageSize(value);
                setCurrentPage(1);
              }}
            />
            <Pagination
              className=""
              currentPage={currentPage}
              totalCount={data.length}
              pageSize={pageSize}
              onPageChange={(page: number) => setCurrentPage(page)}
            />
          </Box>
        </Box>
      </HStack>
      <input
        type="file"
        ref={inputFileRef}
        accept="application/pdf"
        className="hidden"
        onChange={onPlanDocumentUpload}
      />
    </Box>
  );
}

export default PlansTable;

type ToolbarProps = {
  length: number;
  globalFilterText: string;
  setGlobalFilterText: (text: string) => void;
};

function Toolbar(props: ToolbarProps) {
  const [open, setOpen] = useState(false);

  return (
    <HStack aria-label="toolbar" className="justify-between mb-8">
      <VStack className="min-w-max">
        <p className="text-lg font-medium text-gray-dark">Plans</p>
        <p className="text-xs font-medium text-gray-light">
          {props.length} Plans
        </p>
      </VStack>
    </HStack>
  );
}
