import React, { useEffect } from "react";
import { Icon } from "@iconify/react";
import _ from "lodash";
import { format } from "date-fns";
import {
  CheckBoxWithLabel,
  Input,
  Label,
} from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import { Box, HStack, VStack } from "../../components/utils";
import { useEmployeeDepartment, useEmployeeStatus } from "../../queries";
import { useEmployeeTableFilterStore } from "../../store/employeeTableFilters";
import { useEmployeesJoiningDateRange } from "../../queries/employees";

const EmployeeTableFilters = () => {
  const { data: statuses } = useEmployeeStatus();
  const { data: departments } = useEmployeeDepartment();
  const filter = useEmployeeTableFilterStore();
  const { data: joiningDateRange, isFetching } = useEmployeesJoiningDateRange();

  useEffect(() => {
    if (isFetching) return;
    filter.reset();
    filter.setDateOfJoinStart(
      joiningDateRange?.dateOfJoinStart || new Date(2001, 1, 1)
    );
    filter.setDateOfJoinEnd(joiningDateRange?.dateOfJoinEnd || new Date());
  }, [isFetching]);
  return (
    <VStack className="gap-4 p-4 my-auto rounded-lg">
      <HStack className="items-center p-2 rounded bg-slate-light text-slate-dark">
        <Icon icon="fe:search" width="24" className="mr-2 " />
        <input
          type="text"
          className="font-medium border-0 outline-none text-xs2 bg-inherit"
          placeholder="Search by name, status or dept"
          value={filter.searchText}
          onChange={(e) => filter.setSearchText(e.target.value)}
        ></input>
      </HStack>
      <Box className="border-b border-gray-200 border-solid"></Box>
      <Box>
        <HStack className="justify-between">
          <Label className="text-sm font-medium">Status</Label>
          <button
            className="text-sm font-medium text-orange-501"
            onClick={() => filter.reset()}
          >
            Clear
          </button>
        </HStack>
        <Select
          options={statuses || []}
          value={filter.status}
          onChange={(e) => filter.setStatus(e.target.value)}
        />
      </Box>
      <Box>
        <Label className="text-sm font-medium">Department/Team</Label>
        <Select
          options={departments || []}
          value={filter.department}
          onChange={(e) => filter.setDepartment(e.target.value)}
        />
      </Box>
      <Box>
        <Label className="block text-sm font-medium">Date of Joining</Label>
        <Box className="border-b border-gray-200 border-solid"></Box>
        <Box className="pl-2">
          <Label className="text-sm font-normal">from</Label>
          <Input
            className="text-xs1 form-input"
            min={format(
              joiningDateRange?.dateOfJoinStart || new Date(2001, 1, 1),
              "yyyy-MM-dd"
            )}
            type="date"
            placeholder="from Date"
            name="fromDate"
            value={format(
              filter?.dateOfJoinStart || new Date(2001, 1, 1),
              "yyyy-MM-dd"
            )}
            onChange={(e) => {
              if (!e.target.value) return;
              filter.setDateOfJoinStart(new Date(e.target.value));
            }}
          />
          <Label className="text-sm font-normal">to</Label>
          <Input
            className="text-xs1 form-input"
            max={format(
              joiningDateRange?.dateOfJoinEnd || new Date(),
              "yyyy-MM-dd"
            )}
            type="date"
            placeholder="to Date"
            name="toDate"
            value={format(filter?.dateOfJoinEnd || new Date(), "yyyy-MM-dd")}
            onChange={(e) => {
              if (!e.target.value) return;
              filter.setDateOfJoinEnd(new Date(e.target.value));
            }}
          />
        </Box>
      </Box>
      <Box>
        <Label className="block text-sm font-medium">Actionable Insights</Label>
        {filter.actionableInsightFilters.map((insightFilter) => (
          <Box
            key={insightFilter.label}
            className="flex flex-row justify-between"
          >
            <CheckBoxWithLabel
              label={insightFilter.label}
              labelclassname="ml-2 text-xs1 text-gray-500 p-2"
              onChange={(e) =>
                filter.toggleActionableInsightFilter(
                  insightFilter,
                  e.target.checked
                )
              }
              checked={insightFilter.enabled}
            />
            <span className="text-gray-500"></span>
          </Box>
        ))}
      </Box>
    </VStack>
  );
};

export default EmployeeTableFilters;
