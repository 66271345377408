import { toast } from "react-toastify";
import { useExportToExcel } from "../queries";

export function downloadBlobObject(blob: Blob, fileName: string) {
  const anchor = document.createElement("a");
  const blobURL = URL.createObjectURL(blob);
  anchor.href = blobURL;
  anchor.download = fileName;
  anchor.click();
}

export async function convertBase64ToBlob(base64: string, type: string) {
  return fetch(`data:${type};base64,${base64}`).then((res) => res.blob());
}

export function downloadS3File(file: string, fileName = "") {
  const anchor = document.createElement("a");
  anchor.href = file;
  anchor.target = "_blank";
  anchor.download = fileName;
  anchor.click();
}
