import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";

import {
  CellClickedEvent,
  ColDef,
  GridApi,
  GridOptions,
  IMultiFilterParams,
  SideBarDef,
  ValueFormatterParams,
  ValueGetterParams,
  ValueParserParams,
  ExcelExportParams,
} from "ag-grid-community";
import { useNavigate } from "react-router";
import { Grant } from "../../types/Grant";
import { Action, CTADropdown } from "../../components/shared/Dropdown";

import Avatar from "./Avatar";
import GrantStatusLabel from "./StatusLabel";
import {
  Box,
  HStack,
  isAdminViewer,
  isEsopViewer,
} from "../../components/utils";
import { VestingTriggerType } from "../../types/VestingTemplate";
import { GrantStates, Role } from "../../types/common";
import {
  formatCurrency,
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import {
  useExerciseRequestDialog,
  useGrantCreatedDialog,
} from "../../store/useDialogStore";
import {
  canUserPerformActionOnGrant,
  canUserPerformActionOnEvent,
} from "../../utils/permissionHandler";
import {
  capitalizeFirstLetter,
  limitString,
  stringFormat,
} from "../../utils/string";
import AlertDialog from "../../components/shared/AlertDialog";
import { isVestAllowed, isExerciseAllowed } from "./GrantsTable";
import { useAuthStore, useAuthorizationStore } from "../../store";
import { useDeleteGrant } from "../../queries/esopPlan";

import { useError } from "../../store/errorStore";
import { AddOrEditGrant } from "./AddOrEditGrant";

type GrantInfoCellProps = {
  grant: Grant;
  currency: string; // assuming you have a currency prop
};
function AgGridGrantsTable({
  grantTableData,
  handleAction,
  onSelectedRows,
  gridRef,
}: {
  grantTableData: Grant[];
  handleAction: (grant: Grant, action: Action) => void;
  onSelectedRows: (item: Grant[]) => void;
  gridRef: any;
}) {
  const { user } = useAuthStore();
  const currency = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const userPermission = useAuthorizationStore();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const isUserAdminViewer = isAdminViewer();
  const isUserEsopViewer = isEsopViewer();
  const { mutate: deleteGrant } = useDeleteGrant();
  // route params logic
  const isPlaceholderData = false;
  const navigate = useNavigate();
  const gridApi = useRef<GridApi | null>(null);
  const chartThemes = useMemo<string[]>(() => ["ag-pastel", "ag-vivid"], []);
  const exportParams: ExcelExportParams = {
    processCellCallback: (params) => {
      // Customize cell values during export
      if (typeof params.value === "number") {
        return params.value.toFixed(2); // Format numbers to 2 decimal places
      }
      return params.value;
    },
  };

  const sideBar = useMemo<SideBarDef>(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          width: 225,
          maxWidth: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    }),
    []
  );
  const filterparams = {
    filterParams: {
      filterOptions: [
        "lessThan",
        {
          displayKey: "lessThanWithNulls",
          displayName: "Less Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue < filterValue,
        },
        "greaterThan",
        {
          displayKey: "greaterThanWithNulls",
          displayName: "Greater Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue > filterValue,
        },
        {
          displayKey: "betweenExclusive",
          displayName: "Between (Exclusive)",
          predicate: ([fv1, fv2]: any, cellValue: number | null) =>
            cellValue == null ||
            // eslint-disable-next-line no-mixed-operators
            (fv1 < cellValue && fv2 > cellValue),
          numberOfInputs: 2,
        },
      ],
    },
  };
  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));
  const normaliseValue = (value: string | number) => {
    if (typeof value === "number") return value;
    if (typeof value === "string") {
      // check if it can be converted to number first
      // assume if commas are there
      if (isValidDate(value)) return new Date(value).valueOf();
      try {
        value = value.replaceAll(",", "");
      } catch (TypeError) {
        value = value.replace(/,./g, "");
      }
      return parseFloat(value);
    }
    return value;
  };
  const sortComparator = (
    valueA: number | string,
    valueB: number | string,
    ..._: any[]
  ) => normaliseValue(valueA) - normaliseValue(valueB);

  const stringToNumberConvertor = (formattedNumber: string) =>
    parseInt(formattedNumber.toString().replaceAll(",", ""), 10);

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const rowData = grantTableData.find(
        (row) =>
          row.employeeId ===
          cellParams?.data?.grantInfo?.props?.grant?.employeeId
      );
      if (rowData) {
        navigate(`/options/employeeDetails/${rowData.employeeId}`);
      }
    }
  };

  const validateUserPermissionForGrant = (
    grant: Grant,
    action: "EDIT" | "STATE_CHANGE"
  ) => {
    if (!userPermission.authority) {
      return false;
    }
    return canUserPerformActionOnGrant(
      userPermission.authority as Role,
      action,
      grant.optionHolderState as GrantStates
    );
  };
  function numberValueParser(params: ValueParserParams) {
    const res = Number.parseInt(params.newValue, 10);
    if (Number.isNaN(res)) {
      return undefined;
    }
    return res;
  }
  const validateUserPermissionForEvent = (event: "VEST" | "EXCERCISE") => {
    if (!userPermission.authority) {
      return false;
    }
    return canUserPerformActionOnEvent(
      userPermission.authority as Role,
      event,
      "RAISE"
    );
  };
  const handleSelectionChanged = (item: any) => {
    const selectedRows = item.api.getSelectedRows();
    const grantsSelected = selectedRows.map(
      (row: any) => row.grantInfo.props.grant
    );

    onSelectedRows(grantsSelected);
  };
  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      comparator: sortComparator,
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      pivotMode: false,
    }),
    []
  );
  const columnDefs: ColDef[] = [
    {
      headerName: "HOLDER'S NAME",
      checkboxSelection: true,
      width: 200,
      autoHeight: true,
      wrapText: true,
      headerCheckboxSelection: true,
      cellStyle: { "line-height": "20px", "padding-top": "15px" },
      field: "holderName",
      filter: "agSetColumnFilter",
      filterValueGetter: (params) =>
        params.getValue("holderName").props.optionHolderName,
      initialWidth: 200,
      minWidth: 200,
      sortable: true,
      filterParams: {
        comparator: sortComparator,
      },
      comparator(valueA, valueB, ..._other) {
        return valueA.props.optionHolderName?.localeCompare(
          valueB.props.optionHolderName
        );
      },
      suppressSizeToFit: true,
      menuTabs: ["filterMenuTab"],
      cellRendererParams: ({ value }: { value: { props: any } }) => value.props,
      cellRenderer: HolderInfoRender,
    },
    {
      headerName: "GRANT INFO",
      field: "grantInfo",
      sortable: true,
      autoHeight: true,
      wrapText: true,
      width: 200,
      minWidth: 200,
      filter: "agMultiColumnFilter",
      comparator(valueA, valueB, ..._other) {
        return valueA.props.grant.grantIdentifier?.localeCompare(
          valueB.props.grant.grantIdentifier
        );
      },
      suppressSizeToFit: true,
      filterParams: {
        maxNumConditions: 5,
        buttons: ["reset"],
        filters: [
          {
            title: "Grant Date",
            display: "subMenu",
            filterParams: {
              maxNumConditions: 5,
              inRangeInclusive: true,
              buttons: ["reset"],
              comparator: (
                filterLocalDateAtMidnight: Date,
                cellValue: { props: GrantInfoCellProps }
              ) => {
                const filterDate = filterLocalDateAtMidnight;
                filterDate.setHours(0, 0, 0, 0);
                const cellDate = new Date(cellValue.props?.grant?.grantDate);
                cellDate.setHours(0, 0, 0, 0);
                const filterDateAsEpoch = filterDate.valueOf();
                const cellEpoch = cellDate.valueOf();
                return cellEpoch - filterDateAsEpoch;
              },
            },
            filter: "agDateColumnFilter",
          },
          {
            title: "Grant Status",
            filter: "agSetColumnFilter",
            cellStyle: { fontstyle: "capitalize" },
            display: "subMenu",
            filterParams: {
              buttons: ["reset"],
              keyCreator: (params: any) => {
                const status =
                  params?.data?.grantInfo?.props?.grant?.optionHolderState;
                return capitalizeFirstLetter(
                  stringFormat(status.toLowerCase())
                ); // Custom keyCreator logic
              },
              valueFormatter: (params: any) => {
                const status = params?.value?.props?.grant?.optionHolderState;
                return capitalizeFirstLetter(
                  stringFormat(status.toLowerCase())
                );
              },
            },
          },
        ],
      },
      menuTabs: ["filterMenuTab"],
      cellStyle: { "padding-top": "15px" },
      cellRendererParams: ({ value }: { value: { props: any } }) => value.props,
      cellRenderer: GrantInfoCell,
    },

    {
      headerName: "OPTIONS GRANTED",
      field: "optionsGranted",
      autoHeight: true,
      wrapText: true,
      sortable: true,
      comparator(valueA, valueB, nodeA, nodeB, isDescending) {
        return sortComparator(
          valueA.props.grant.optionsGranted,
          valueB.props.grant.optionsGranted,
          nodeA,
          nodeB,
          isDescending
        );
      },
      cellStyle: { "padding-top": "15px" },
      filter: "agMultiColumnFilter",
      filterParams: {
        comparator: sortComparator,
        maxNumConditions: 5,
        buttons: ["reset"],
        filters: [
          {
            title: "Plan Name",
            filter: "agSetColumnFilter",
            display: "subMenu",
            filterParams: {
              buttons: ["reset"],
              keyCreator: (params: any) => {
                const status = params?.data?.grantInfo?.props?.grant?.planName;
                return status; // Custom keyCreator logic
              },
              valueFormatter: (params: any) => {
                const status = params?.value?.props?.grant?.planName;
                return status;
              },
            },
          },
          {
            title: "Options Granted",
            display: "subMenu",
            filterParams: {
              buttons: ["reset"],
              valueGetter: (params: any) =>
                params?.getValue("optionsGranted")?.props?.grant
                  ?.optionsGranted,
            },
            filter: "agNumberColumnFilter",
          },
        ],
      },
      menuTabs: ["filterMenuTab"],
      cellRendererParams: ({ value }: { value: { props: any } }) => value.props,
      cellRenderer: OptionInfoCell,
    },
    {
      headerName: "VESTED",
      field: "vested",
      autoHeight: true,
      wrapText: true,
      sortable: true,
      filter: "agMultiColumnFilter",
      comparator(valueA, valueB, nodeA, nodeB, isDescending) {
        return sortComparator(
          valueA.props.grant.optionsVested,
          valueB.props.grant.optionsVested,
          nodeA,
          nodeB,
          isDescending
        );
      },
      filterParams: {
        maxNumConditions: 5,
        buttons: ["reset"],
        filters: [
          {
            title: "Options Vested",
            display: "subMenu",
            filterValueGetter: (params: any) =>
              stringToNumberConvertor(
                params.getValue("vested")?.props?.grant?.optionsVested
              ),
            filterParams: {
              buttons: ["reset"],
              valueGetter: (params: any) =>
                params?.getValue("vested")?.props?.grant?.optionsVested,
            },
            filter: "agNumberColumnFilter",
          },
          {
            title: "Vesting Template",
            filter: "agSetColumnFilter",
            display: "subMenu",
            filterParams: {
              buttons: ["reset"],
              keyCreator: (params: any) => {
                const status =
                  params?.data?.grantInfo?.props?.grant?.vestingTemplateName;
                return status; // Custom keyCreator logic
              },
              valueFormatter: (params: any) => {
                const status = params?.value?.props?.grant?.vestingTemplateName;
                return status;
              },
            },
          },
        ],
      },
      menuTabs: ["filterMenuTab"],
      cellRenderer: GrantDetailsCell,
      cellRendererParams: ({ value }: { value: { props: any } }) => value.props,
    },
    {
      headerName: "UNVESTED",
      field: "unvested",
      autoHeight: true,
      wrapText: true,
      valueParser: numberValueParser,
      filter: "agNumberColumnFilter",
      filterValueGetter: (params) =>
        stringToNumberConvertor(params.getValue("unvested")),
      filterParams: filterparams,
      menuTabs: ["filterMenuTab"],
      sortable: true,
    },
    {
      headerName: "NET VESTED",
      field: "netVested",
      autoHeight: true,
      wrapText: true,
      filter: "agNumberColumnFilter",
      filterValueGetter: (params) =>
        stringToNumberConvertor(params.getValue("netVested")),
      filterParams: filterparams,
      menuTabs: ["filterMenuTab"],
      sortable: true,
    },
    {
      headerName: "STOCK EXERCISED",
      field: "stockExercised",
      autoHeight: true,
      wrapText: true,
      filter: "agNumberColumnFilter",
      filterValueGetter: (params) =>
        stringToNumberConvertor(params.getValue("stockExercised")),
      filterParams: filterparams,
      menuTabs: ["filterMenuTab"],
      sortable: true,
    },
    {
      headerName: "CASHED OUT",
      field: "cashedOut",
      autoHeight: true,
      wrapText: true,
      filter: "agNumberColumnFilter",
      filterValueGetter: (params) =>
        stringToNumberConvertor(params.getValue("cashedOut")),
      filterParams: filterparams,
      menuTabs: ["filterMenuTab"],
      sortable: true,
    },
    {
      headerName: "TOTAL EXERCISED",
      field: "totalExercised",
      autoHeight: true,
      wrapText: true,
      filter: "agNumberColumnFilter",
      filterValueGetter: (params) =>
        stringToNumberConvertor(params.getValue("totalExercised")),
      filterParams: filterparams,
      menuTabs: ["filterMenuTab"],
      sortable: true,
    },
    {
      headerName: "FORFEITED",
      field: "forfeited",
      autoHeight: true,
      wrapText: true,
      filter: "agNumberColumnFilter",
      filterValueGetter: (params) =>
        stringToNumberConvertor(params.getValue("forfeited")),
      menuTabs: ["filterMenuTab"],
      sortable: true,
    },
    {
      headerName: "",
      field: "actions",
      pinned: "right",
      hide: false,
      width: 80,
      maxWidth: 80,
      filter: false,
      colId: "action-column",
      suppressNavigable: true,
      suppressColumnsToolPanel: true,
      resizable: false,
      sortable: false,
      menuTabs: ["columnsMenuTab"],
      cellRendererParams: ({ value }: { value: any }) => value.props,
      cellRenderer: CTADropdown,
    },
  ];

  const rowData = useMemo(
    () =>
      grantTableData?.map((grant) => ({
        avatar: (
          <Avatar name={grant.optionHolderName} status={grant.statusId} />
        ),
        holderName: (
          <HolderInfoRender
            employeeIdentifier={grant.employeeIdentifier}
            optionHolderName={grant.optionHolderName}
            statusId={grant.statusId}
          />
        ),
        grantInfo: <GrantInfoCell grant={grant} currency={currency} />,
        optionsGranted: <OptionInfoCell grant={grant} currency={currency} />,
        vested: <GrantDetailsCell currency={currency} grant={grant} />,
        unvested: grant.unVested?.toLocaleString(currency),
        netVested: grant.netVested?.toLocaleString(currency),
        stockExercised: parseInt(
          grant.optionsExercisedWithStock.toString(),
          10
        ).toLocaleString(currency),
        cashedOut: parseInt(
          grant.optionsExercisedWithCash.toString(),
          10
        ).toLocaleString(currency),
        totalExercised: parseInt(
          grant.optionsExercised.toString(),
          10
        ).toLocaleString(currency),
        forfeited: parseInt(grant.optionsLapsed.toString(), 10).toLocaleString(
          currency
        ),
        actions: (
          <CTADropdown
            dropdownClassName=" xl:right-10 lg:right-8 md:right-2"
            actions={[
              {
                name: "Edit Grant",
                disabled: !validateUserPermissionForGrant(grant, "EDIT"),
              },
              {
                name: "Update grant status",
                disabled: !validateUserPermissionForGrant(
                  grant,
                  "STATE_CHANGE"
                ),
              },
              { name: "Clone Grant" },
              {
                name: "Vest Options",
                disabled:
                  !isVestAllowed(grant) ||
                  !validateUserPermissionForEvent("VEST"),
              },
              {
                name: "Exercise Options",
                disabled:
                  !isExerciseAllowed(grant) ||
                  !validateUserPermissionForEvent("EXCERCISE"),
              },
              { name: "View Grant Letter", disabled: !grant.grantLetterLink },
              {
                name: "Delete",
                disabled:
                  grant.optionHolderState === "GRANTED" ||
                  grant.optionHolderState === "OFFERED" ||
                  grant.optionHolderState === "RESIGNED" ||
                  isUserAdminViewer ||
                  isUserEsopViewer,
              },
            ]}
            onAction={(action) => handleAction(grant, action)}
          />
        ),
      })),
    [grantTableData]
  );

  const onPageSizeChanged = useCallback(() => {
    const value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridApi.current?.paginationSetPageSize(Number(value));
  }, []);

  return (
    <>
      {" "}
      <AgGridReact
        alwaysShowHorizontalScroll
        alwaysMultiSort
        defaultColDef={defaultColDef}
        suppressRowTransform={true}
        rowData={rowData}
        animateRows={true}
        pagination={true}
        suppressCopyRowsToClipboard={true}
        suppressCopySingleCellRanges={true}
        suppressCellFocus={true}
        onCellClicked={handleCellClick}
        suppressMenuHide={true}
        rowClass={"border-t border-dashed cursor-pointer"}
        rowSelection="multiple"
        sideBar={sideBar}
        rowMultiSelectWithClick={true}
        onSelectionChanged={handleSelectionChanged}
        onGridReady={(params) => {
          // Store the grid API reference

          gridApi.current = params.api;
          gridRef.current = params.api;
        }}
        suppressRowClickSelection={true}
        columnDefs={columnDefs}
        overlayNoRowsTemplate={
          '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
        }
      ></AgGridReact>
    </>
  );
}

function HolderInfoRender(props: {
  optionHolderName: string;
  employeeIdentifier: string;
  statusId: string;
}) {
  return (
    <HStack className="align-center">
      {" "}
      <Avatar name={props.optionHolderName} status={props.statusId} />
      <Box className="align-top px-2">
        <p className={` text-xs font-medium text-gray-dark `}>
          {limitString(props.optionHolderName, 40)}
        </p>
        <p className={` text-[9px] text-gray-light`}>
          {props.employeeIdentifier}
        </p>
      </Box>
    </HStack>
  );
}

const OptionInfoCell: React.FC<GrantInfoCellProps> = ({ grant, currency }) => (
  <HStack className="align-center">
    <Box className="">
      <p className={` text-xs font-normal text-gray-dark`}>
        {grant?.optionsGranted.toLocaleString(currency)}
      </p>
      <p className="leading-5 text-xxs text-gray-light">{grant.planName}</p>
    </Box>
  </HStack>
);
const GrantInfoCell: React.FC<GrantInfoCellProps> = ({ grant, currency }) => {
  const formattedDate = new Date(grant.grantDate).toUTCString().slice(5, 17);

  return (
    <HStack className="px-2 py-2 align-top">
      <Box>
        <HStack className="gap-1">
          <p className="text-xs font-medium text-gray-dark">
            {grant.grantIdentifier}
          </p>
          <HStack className="">
            <GrantStatusLabel state={grant.optionHolderState} />
          </HStack>
        </HStack>
        <p className="leading-5 text-xxs text-gray-light">
          {formattedDate} | Price: {formatCurrency(grant.grantPrice, currency)}
        </p>
      </Box>
    </HStack>
  );
};
const GrantDetailsCell: React.FC<GrantInfoCellProps> = ({
  grant,
  currency,
}) => {
  const vestingDetails =
    grant.vestingTriggerType === VestingTriggerType.Time
      ? `, ${grant.vestingInterval}/${grant.vestingPeriod})`
      : grant.vestingTriggerType === VestingTriggerType.Event
      ? `, Event Based Vesting)`
      : `, Custom Vesting Schedule)`;
  return (
    <HStack className="px-2 py-4 align-top">
      <Box>
        <p className="text-xs font-normal text-gray-dark">
          {grant.optionsVested.toLocaleString(currency)}
        </p>
        <p className="leading-5 text-xxs text-gray-light">
          {grant.vestingTemplateName} (Cliff: {grant.cliffPeriod}
          {grant.vestingInterval &&
          grant.vestingTriggerType === VestingTriggerType.Time
            ? `  , ${grant.vestingInterval}/${grant.vestingPeriod})`
            : grant.vestingTriggerType === VestingTriggerType.Event
            ? ` , Event Based Vesting)`
            : ` , Custom Vesting Schedule)`}
        </p>
      </Box>
    </HStack>
  );
};
export default forwardRef(AgGridGrantsTable);
