import React, { useEffect, useMemo } from "react";
import { Icon } from "@iconify/react";
import { Box, HStack, VStack } from "../../components/utils";
import { Label } from "../../components/shared/InputField";
import { useGetOptionBuyback } from "../../queries/optionBuyback";
import { useAdvanceFilterDialog } from "../../store/useDialogStore";

export type propTypes = {
  onClose: () => void;
  id?: string;
};

function FilterDetails(props: propTypes) {
  const { data: optionBuybackOverview } = useGetOptionBuyback();
  const { id, onClose } = props;

  const _id = id || "";
  const advanceFilters = useMemo(
    () =>
      (optionBuybackOverview || []).filter(
        (item) => parseInt((item.id || 0)?.toString(), 10) === parseInt(_id, 10)
      )[0]?.offerFilter,
    [id]
  );

  return (
    <>
      <HStack className=" border-solid p-4 px-10 mt-4 text-lg w-200 font-medium border-b stone-700 justify-between">
        <Box>
          <h6>Advance Filters</h6>
        </Box>
        <Icon
          icon="ic:round-close"
          onClick={onClose}
          className="cursor-pointer"
        />
      </HStack>
      <Box className="p-4 border-solid">Employee Related Filters</Box>
      <VStack className="p-4 gap-8 border-b border-solid">
        <HStack className="justify-start gap-12">
          <VStack className="flex-1">
            <Label>Minimum Vested Options</Label>
            <Box>{advanceFilters?.minimumVestedOptions}</Box>
          </VStack>
          <VStack className="flex-1">
            <Label>Minimum Employement Tenure</Label>
            <Box>{advanceFilters?.minimumTenure}</Box>
          </VStack>
        </HStack>
        <HStack className="justify-start gap-12">
          <VStack className="flex-1">
            <Label>Department</Label>
            <HStack className="gap-2 p-2 max-w-[250px] overflow-auto ">
              {advanceFilters?.department?.map((dept, index) => (
                <Box key={index} className="bg-slate-300 p-2 rounded">
                  {dept}
                </Box>
              ))}
            </HStack>
          </VStack>
          <VStack className="flex-1">
            <Label>Minimum Willingness </Label>
            <Box>{advanceFilters?.minimumWillingness}</Box>
          </VStack>
        </HStack>
      </VStack>
      <Box className="p-4 border-solid">Participation Constraints</Box>
      <VStack className="p-4 gap-8 ">
        {advanceFilters?.participationConstraints?.map((pc, index) => (
          <HStack key={index}>
            <VStack className="flex-1">
              <Label>Maximum Number of Participation</Label>
              <Box>{pc.maxFrequency}</Box>
            </VStack>
            <VStack className="flex-1">
              <Label>Interval</Label>
              <Box>{pc.interval}</Box>
            </VStack>
            <VStack className="flex-1">
              <Label>Interval unit</Label>
              <Box>{pc.intervalUnit}</Box>
            </VStack>
          </HStack>
        ))}
      </VStack>
    </>
  );
}

export default FilterDetails;
