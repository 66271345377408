import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { Box, ButtonPrimary, HStack, VStack } from "../../components/utils";
import { globalFilter, sort } from "../../utils/arrays";
import BasicMenu from "../../components/shared/Menu";
import Pagination from "../../components/shared/Pagination";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { useOptionSplitDialog } from "../../store/useDialogStore";
import { OptionSplit } from "../../types/OptionSplit";
import {
  useGetOptionSplits,
  useCompleteOptionSplit,
} from "../../queries/optionSplit";
import { formatDisplayDate } from "../../utils/date";

function OptionSplitsTable({ data }: { data: OptionSplit[] }) {
  const { isPlaceholderData } = useGetOptionSplits();
  // filter and sort logic
  const [globalFilterText, setGlobalFilterText] = useState("");
  const [sortField, setSortField] = useState<{
    field: keyof OptionSplit | "" | undefined;
    ascending: boolean;
  }>();
  data = useMemo(() => {
    if (!data) return [];
    const filterResult = globalFilter(data, globalFilterText, [
      "note",
      "date",
      "state",
    ]);
    const sortResult = sort(
      filterResult,
      sortField?.field,
      sortField?.ascending
    );
    return sortResult;
  }, [data, globalFilterText, sortField]);
  useEffect(() => {
    setCurrentPage(1);
  }, [globalFilterText]);

  // selection logic
  const [selectedItems, setSelectedItems] = useState<OptionSplit[]>([]);
  const onSelectionChange = (item: OptionSplit) => {
    setSelectedItems((items) =>
      items.find((i) => i === item)
        ? items.filter((i) => i !== item)
        : [...items, item]
    );
  };
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  useEffect(() => {
    if (selectAllChecked) {
      setSelectedItems(data);
    } else {
      setSelectedItems([]);
    }
  }, [data, selectAllChecked]);

  //pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [data, currentPage, pageSize]);

  // Actions
  const { setState: setDialog } = useOptionSplitDialog();

  const { mutate: completeSplit, isLoading } = useCompleteOptionSplit();

  function handleAction(optionSplit: OptionSplit, action: Action) {
    if (action.name === "Edit" && !action.disabled) {
      setDialog({ open: true, split: optionSplit, mode: "Edit" });
    } else if (action.name === "Complete" && !action.disabled) {
      completeSplit(optionSplit.id);
    }
  }

  return (
    <Box className="p-8 bg-white rounded-lg min-w-min ">
      <HStack aria-label="toolbar" className="justify-between mb-8">
        <VStack className="min-w-max">
          <p className="text-lg font-medium text-gray-dark">Option Splits</p>
          <p className="text-xs font-medium text-gray-light">
            {data.length} Results
          </p>
        </VStack>
        <HStack className="h-11 min-w-min">
          <ButtonPrimary
            className="min-w-max"
            onClick={() => setDialog({ open: true, mode: "Add" })}
          >
            Create Option Split
          </ButtonPrimary>
        </HStack>
      </HStack>
      <table className="w-full table-space ">
        <thead className="text-xs font-medium text-gray-light">
          <tr className="">
            <td
              className="py-2 align-middle hover:cursor-pointer"
              onClick={() =>
                setSortField({
                  field: "date",
                  ascending: !sortField?.ascending,
                })
              }
            >
              DATE
            </td>
            <td
              className="py-2 align-middle hover:cursor-pointer"
              onClick={() =>
                setSortField({
                  field: "note",
                  ascending: !sortField?.ascending,
                })
              }
            >
              NOTE
            </td>
            <td
              className="py-2 align-middle hover:cursor-pointer"
              onClick={() =>
                setSortField({
                  field: "ratio",
                  ascending: !sortField?.ascending,
                })
              }
            >
              RATIO
            </td>
            <td
              className="py-2 align-middle hover:cursor-pointer"
              onClick={() =>
                setSortField({
                  field: "state",
                  ascending: !sortField?.ascending,
                })
              }
            >
              STATUS
            </td>
            <td className="py-2 align-middle"></td>
          </tr>
        </thead>
        <tbody className={isPlaceholderData ? "loading" : ""}>
          {currentTableData &&
            currentTableData?.map((optionSplit) => (
              <tr key={optionSplit.id} className="border-t border-dashed ">
                <td className="py-2 align-middle">
                  <HStack className="">
                    <Box
                      className="cursor-pointer"
                      onClick={() =>
                        setDialog({
                          open: true,
                          split: optionSplit,
                          mode: "View",
                        })
                      }
                    >
                      <p className={`text-xs font-medium text-gray-dark `}>
                        {formatDisplayDate(optionSplit.date)}
                      </p>
                    </Box>
                  </HStack>
                </td>
                <td className="py-2 align-middle">
                  <Box className="">
                    <HStack>
                      <p className={`text-xs font-medium text-gray-dark`}>
                        {optionSplit.note}
                      </p>
                    </HStack>
                  </Box>
                </td>
                <td className="py-2 align-middle">
                  <HStack>
                    <p className={`text-xs font-medium text-gray-dark`}>
                      {optionSplit.ratio}
                    </p>
                  </HStack>
                </td>
                <td className="py-2 align-middle">
                  <HStack>
                    <p className={`text-xs font-medium text-gray-dark `}>
                      {optionSplit.state}
                    </p>
                  </HStack>
                </td>
                <td className="py-2 align-middle">
                  <CTADropdown
                    actions={[
                      {
                        name: "Edit",
                      },
                      {
                        name: "Complete",
                      },
                    ]}
                    onAction={(action) => handleAction(optionSplit, action)}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Box className="flex justify-between mt-8">
        <BasicMenu
          defaultValue={pageSize}
          options={[5, 10, 20, 50, 100]}
          onOptionChange={(value) => {
            setCurrentPage(1);
            setPageSize(value);
          }}
        />
        <Pagination
          className=""
          currentPage={currentPage}
          totalCount={data.length}
          pageSize={pageSize}
          onPageChange={(page: number) => setCurrentPage(page)}
        />
      </Box>
    </Box>
  );
}

export default OptionSplitsTable;
