/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable etc/no-commented-out-code */
/* eslint-disable no-return-assign */
import { Icon } from "@iconify/react";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import _ from "lodash";
import BasicMenu from "../../components/shared/Menu";
import Pagination from "../../components/shared/Pagination";
import AlertDialog from "../../components/shared/AlertDialog";
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  Error,
  HStack,
  VStack,
  getCompanyName,
  isEsopViewer,
} from "../../components/utils";
import {
  useBuybackState,
  useCloseLiquidityEvent,
  useCompleteLiquidityEvent,
  useDeleteBuybackOffer,
  useDiscardBuyback,
  useGetOptionBuyback,
  useSendLiquidationIntimation,
  useStartLiquidityEvent,
} from "../../queries/optionBuyback";
import { globalFilter, sort } from "../../utils/arrays";
import { CumulativeOffer, ErrorInterface } from "../../types/OptionBuyback";
import {
  buyBackHeaderslist,
  buyBackHeadersObj,
  buyBackHeadingVal,
} from "../../types/ExportExcelOptionBuyBack";
import {
  convertBase64ToBlob,
  downloadBlobObject,
} from "../../utils/DownloadFile";
import { useExportToExcel } from "../../queries";
import { useBuybackStore } from "../../store/optionBuyback";
import { Input } from "../../components/shared/InputField";
import { useError } from "../../store/errorStore";
import { ExportImport } from "../esopOverview/GrantsTable";
import useIsMobile from "../../utils/detectDevice";
import SortingComponent from "../../components/SortingVariation";
import { useAuthStore } from "../../store";
import { EmptyTable } from "../../components/shared/EmptyTable";

const getHeaderObj = (keys: string[]) =>
  keys.map((ele: string) => ({
    key: ele,
    value: buyBackHeadersObj[ele],
  }));

function BuybackEmployeeList() {
  const { user } = useAuthStore();
  const isUserEsopViewer = isEsopViewer();
  const emailConfiguration = user?.company.emailsToBeSent;
  const { id } = useParams();
  const _id = id || "";
  const navigate = useNavigate();
  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    isLoading?: boolean;
  }>({
    open: false,
    message: " ",
    isLoading: false,
  });

  const errorMessage = useError();
  const [globalFilterText, setGlobalFilterText] = useState("");
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState<{
    field: keyof CumulativeOffer | "" | undefined;
    ascending: boolean;
  }>({ field: "employeeName", ascending: false });
  const { mutate: discardBuyback, isLoading } = useDiscardBuyback();
  const { data: buybackOverview } = useGetOptionBuyback();
  const optionBuybackOverview = buybackOverview || [];
  const buyback = optionBuybackOverview?.filter(
    (item) => parseInt((item?.id || 0).toString(), 10) === parseInt(_id, 10)
  )[0];
  const [buybackActionType, setBuybackActionType] = useState("");
  useEffect(() => {
    if (buyback?.state === "DRAFT") {
      if (
        buyback?.transactionType === "exercise" ||
        buyback?.transactionType === "exercise and liquidate"
      ) {
        setBuybackActionType("Start Exercise");
      } else {
        setBuybackActionType("Start Buy Back");
      }
    } else if (buyback?.state === "STARTED") {
      if (
        buyback?.transactionType === "exercise" ||
        buyback?.transactionType === "exercise and liquidate"
      ) {
        setBuybackActionType("Close Exercise");
      } else {
        setBuybackActionType("Close Buy Back");
      }
    } else if (buyback?.state.toUpperCase() === "CLOSED") {
      if (
        buyback.transactionType === "buyback" &&
        buyback.state.toUpperCase() === "CLOSED"
      ) {
        setBuybackActionType("Complete Buy Back");
      } else {
        setBuybackActionType("Complete Exercise");
      }
    } else if (buyback?.state.toUpperCase() === "COMPLETED") {
      if (
        buyback?.transactionType === "exercise and liquidate" &&
        buyback?.elState === "DRAFT"
      ) {
        setBuybackActionType("Start Liquidation");
      } else if (
        buyback?.transactionType === "exercise and liquidate" &&
        buyback?.elState === "ACTIVE"
      ) {
        setBuybackActionType("Close Liquidation");
      } else {
        setBuybackActionType("Complete Liquidation");
      }
    }
  }, [buyback]);

  const { data: _buybackStateData, isPlaceholderData } = useBuybackState(
    parseInt(_id, 10)
  );
  const buybackStateData = _.sortBy(
    _buybackStateData || [],
    (bb) => bb.employeeName
  );

  _.sortBy(buybackStateData, (bb) => bb.employeeName);
  let cumulativeOffers: any[] = [];

  cumulativeOffers = useMemo(
    () => buybackStateData.filter((list) => list),
    [isPlaceholderData, cumulativeOffers, buybackStateData]
  );
  /**
   * used for edit willingness by admin
   */
  const [editRow, setEditRow] = useState({
    editable: false,
    id: 0,
  });
  cumulativeOffers = useMemo(() => {
    if (!cumulativeOffers) return [];
    const filterResult = globalFilter(cumulativeOffers, globalFilterText, [
      "employeeName",
      "state",
    ]);
    const sortResult = sort(
      filterResult,
      sortField?.field,
      sortField?.ascending
    );
    return sortResult;
  }, [cumulativeOffers, globalFilterText, sortField, editRow]);

  const [updateEligibility, setUpdateEligibility] = useState<number>();
  const [save, setSave] = useState(false);
  // update the updateEligibility using hook
  let currentTableData: any[] = [];
  currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    cumulativeOffers.filter((list) => {
      if (list.employeeId === editRow.id && save) {
        list.eligibility = updateEligibility;
        return list;
      } else {
        return list;
      }
    });

    return cumulativeOffers.slice(firstPageIndex, lastPageIndex);
  }, [
    cumulativeOffers,
    currentPage,
    pageSize,
    editRow,
    save,
    updateEligibility,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [globalFilterText]);

  function transactionAction() {
    if (
      buybackActionType === "Start Buy Back" ||
      buybackActionType === "Start Exercise"
    ) {
      setDialog({
        open: true,
        isLoading: false,
        message: `Are you sure you want to start Option ${buybackActionType.replace(
          "Start",
          ""
        )}?
    ${
      emailConfiguration
        ? "This will send an Email invite to all eligible Employees."
        : "Since you have disabled Email configuration, Email invite will not be sent to eligible Employee"
    }`,
      });
    } else if (buybackActionType === "Start Liquidation") {
      setDialog({
        open: true,
        isLoading: false,
        message: `You are about to start the liquidation Flow
        Are you sure you want to continue? `,
      });
    } else if (
      buybackActionType === "Close Buy Back" ||
      buybackActionType === "Close Exercise"
    ) {
      setDialog({
        open: true,
        isLoading: false,
        message: `Are you sure you want to close the offer to exercise?
                  Employees who have not yet accepted the offer will not be able to participate in the Liquidity cashout program.`,
      });
    } else {
      setDialog({
        open: true,
        isLoading: false,
        message: `Are you sure you want to complete ${
          buyback.transactionType !== "buyback"
            ? "the option exercise"
            : "option"
        }  ${buybackActionType.replace("Complete", "")}?
        This will ${
          buyback.transactionType !== "buyback" ? "" : "cash"
        } exercise the options based on willingness of Employees. ${
          emailConfiguration
            ? "Email intimation will be sent confirming the same."
            : ""
        }`,
      });
    }
  }

  const { mutate: startEvent } = useStartLiquidityEvent();
  const { mutate: completeEvent } = useCompleteLiquidityEvent();
  const { mutate: closeEvent } = useCloseLiquidityEvent();
  const { mutate: startLiquidation } = useSendLiquidationIntimation();
  function handleTransactionAction() {
    setDialog({ ...dialog, isLoading: true });
    if (
      buybackActionType === "Start Liquidation" ||
      buybackActionType === "Close Liquidation" ||
      buybackActionType === "Complete Liquidation"
    ) {
      let message: string = "";
      if (buybackActionType === "Start Liquidation") {
        message = "Liquidation started successfully";
      } else if (buybackActionType === "Close Liquidation") {
        message = "Liquidation closed successfully";
      } else if (buybackActionType === "Complete Liquidation") {
        message = "Liquidation completed successfully";
      }
      handleELStartTransaction(message);
      return;
    }
    let message = "";
    const optionBuybackDto = { ...buyback };
    optionBuybackDto.elState = "DRAFT";
    if (
      buybackActionType === "Start Buy Back" ||
      buybackActionType === "Start Exercise"
    ) {
      optionBuybackDto.state = "STARTED";
      optionBuybackDto.offers = cumulativeOffers;
      optionBuybackDto.cumuOffers = cumulativeOffers;

      message = `${buybackActionType.replace(
        "Start",
        ""
      )} started successfully`;
      startLiquidityEvent(message);
    } else if (
      buybackActionType === "Close Buy Back" ||
      buybackActionType === "Close Exercise"
    ) {
      optionBuybackDto.state = "CLOSED";
      message = `${buybackActionType.replace("Close", "")} closed successfully`;
      closeLiquidityEvent(message);
    } else if (
      buybackActionType === "Complete Buy Back" ||
      buybackActionType === "Complete Exercise"
    ) {
      optionBuybackDto.state = "COMPLETED ";
      message = `${buybackActionType.replace(
        "Complete",
        ""
      )} completed successfully`;
      completeLiquidityEvent(message);
    }
  }

  function startLiquidityEvent(message: string) {
    startEvent(parseInt(_id, 10), {
      onSuccess: (data) => {
        toast(message, {
          type: "success",
          autoClose: 2000,
        });
        navigate(`/options/start-buy-back/${_id}`);
        setDialog({ open: false });
      },
      onError: (err: any) => {
        const error = err.response.data as ErrorInterface;
        toast(error.errorRaw, { type: "error", autoClose: 5000 });
        setDialog({ open: false });
      },
    });
  }
  function closeLiquidityEvent(message: string) {
    closeEvent(parseInt(_id, 10), {
      onSuccess: () => {
        toast(message, { type: "success", autoClose: 2000 });
        navigate(`/options/complete-buy-back/${_id}`);
        setDialog({ open: false });
      },
      onError: (err: any) => {
        const error = err.response.data as ErrorInterface;
        toast(error.errorRaw, { type: "success", autoClose: 2000 });
        setDialog({ open: false });
      },
    });
  }
  function completeLiquidityEvent(message: string) {
    completeEvent(parseInt(_id, 10), {
      onSuccess: () => {
        toast(message, {
          type: "success",
          autoClose: 2000,
        });
        navigate(`/options/complete-buy-back/${_id}`);
        setDialog({ open: false });
      },
      onError: (err: any) => {
        const error = err.response.data as ErrorInterface;
        toast(error.errorRaw, { type: "error", autoClose: 5000 });
        setDialog({ open: false });
      },
    });
  }
  const fileName = `BuyBack_Employees_Details_${getCompanyName()}.xlsx`;
  function handleELStartTransaction(message: string) {
    const buybackId = parseInt(_id, 10);
    startLiquidation(buybackId, {
      onSuccess: (data) => {
        toast(message, {
          type: "success",
          autoClose: 2000,
        });
        setDialog({ open: false });
        navigate(`/options/liquidation-details/${_id}`);
      },
      onError: (error) => {
        toast("Something went wrong", { type: "error", autoClose: 2000 });
        setDialog({ open: false });
      },
    });
  }

  const { mutate: exportExcel } = useExportToExcel();
  const [downloading, setDownloading] = useState(false);
  const exportClickHandler = () => {
    setDownloading(true);
    const reqData = cumulativeOffers;
    reqData.forEach((item) => {
      if (
        item.state !== "ACCEPTED" &&
        item.state !== "COMPLETED" &&
        item.state !== "PENDING_EXERCISED"
      ) {
        item.totalPriceToPay = 0;
        item.willingness = 0;
      }
      item.totalValueWillingness =
        item.willingness *
        (buyback?.transactionType === "buyback"
          ? buyback?.price || 0
          : buyback?.sellingPrice || 0);
      item.netPayout =
        item.willingness *
          (buyback?.transactionType === "buyback"
            ? buyback?.price || 0
            : buyback?.sellingPrice || 0) -
        item.totalPriceToPay;
    });
    const reqObj = {
      heading: buyBackHeadingVal,
      companyName: user?.company.nameOfTheCompany,
      data: reqData,
      headers: getHeaderObj(buyBackHeaderslist),
    };

    exportExcel(reqObj, {
      onSuccess: async (data) => {
        const blob = await convertBase64ToBlob(
          data.data || "",
          data.fileType
            ? data.fileType
            : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        downloadBlobObject(blob, fileName);
        setDownloading(false);
      },
      onError: () => {
        toast("Something Went Wrong", { type: "error", autoClose: 2000 });
        setDownloading(false);
      },
    });
  };

  function handleCancelBuyback() {
    setDialog({ open: false });
  }
  function discard() {
    if (buyback?.state !== "DRAFT") {
      toast(
        `${
          buyback?.transactionType === "buyback" ? "Buy back" : "Exercise event"
        }  can not be deleted in ${buyback?.state} state `,
        {
          type: "warning",
          autoClose: 2000,
        }
      );
    } else {
      discardBuyback(parseInt(_id, 10), {
        onSuccess: () => {
          toast("Buy Back discarded successfully", {
            type: "success",
            autoClose: 2000,
          });
          navigate("/options/esopTransactions");
        },
        onError: () => {
          toast("Something went wrong!", {
            type: "error",
            autoClose: 5000,
          });
        },
      });
    }
  }
  const { mutate: deleteBuybackOffer } = useDeleteBuybackOffer();

  function deleteOffer(offer: CumulativeOffer) {
    const buybackOfferId = offer?.ids;
    deleteBuybackOffer([parseInt(buybackOfferId, 10)], {
      onSuccess: () => {
        toast("Buy Back Offer deleted successfully", {
          type: "success",
          autoClose: 2000,
        });
      },
      onError: () => {
        toast("Something went wrong", { type: "error", autoClose: 2000 });
      },
    });
  }
  const { isMobile } = useIsMobile();
  return (
    <>
      <Dialog open={dialog.open}>
        <AlertDialog
          open={dialog.open}
          message={dialog.message}
          status={dialog.isLoading}
          primaryActionText={`${buybackActionType}`}
          secondaryActionText="Cancel"
          onPrimaryAction={handleTransactionAction}
          onSecondaryAction={handleCancelBuyback}
        />
      </Dialog>
      <Box className={`p-6 bg-[#fefefe] rounded-lg drop-shadow-sm `}>
        <Box
          aria-label="toolbar"
          className={`flex ${
            isMobile ? "flex-col" : "flex-row items-center "
          } justify-between space-y-4`}
        >
          <HStack className="px-2 py-2 text-sm font-semibold">
            Offers List{" "}
          </HStack>
          <HStack className="px-2 py-2">
            {!downloading ? (
              <ExportImport
                actions={[{ name: "Export to excel" }]}
                onAction={() => exportClickHandler()}
              />
            ) : (
              <div
                className={`${
                  downloading ? "text-orange-501" : ""
                } p-2  text-orange-501 rounded hover:cursor-pointer bg-slate-100 hover:bg-orange-501 `}
              >
                <Icon
                  className="animate-spin"
                  icon="lucide:loader-2"
                  width={20}
                  height={24}
                />
              </div>
            )}

            <HStack className="items-center p-2 rounded w-72 bg-slate-light text-slate-dark">
              <Icon icon="fe:search" width="24" className="mr-2 " />
              <input
                type="text"
                className="w-full font-medium border-0 outline-none text-xs2 bg-inherit"
                placeholder="Search by Name"
                value={globalFilterText}
                onChange={(e) => {
                  setGlobalFilterText(e.target.value);
                }}
              ></input>
            </HStack>
          </HStack>
        </Box>
        <Box className={`max-h-full p-6 overflow-auto bg-white drop-shadow-md`}>
          <Box>
            <table
              className={` table-space ${
                isMobile ? "min-w-[1040px]" : "w-full"
              }`}
            >
              <thead className="text-xs font-medium text-gray-400">
                <tr className="border-b border-dashed">
                  <td className=" whitespace-nowrap">
                    <HStack>
                      EMPLOYEE NAME
                      <SortingComponent
                        fieldName="employeeName"
                        selectedFieldName={sortField?.field || ""}
                        isAscending={sortField?.ascending || false}
                        onChangeSort={() => {
                          setSortField({
                            field: "employeeName",
                            ascending: !sortField?.ascending,
                          });
                        }}
                      />
                    </HStack>
                  </td>
                  <td className=" whitespace-nowrap">
                    <HStack className="justify-end">
                      GRANTED OPTIONS
                      <SortingComponent
                        fieldName="granted"
                        selectedFieldName={sortField?.field || ""}
                        isAscending={sortField?.ascending || false}
                        onChangeSort={() => {
                          setSortField({
                            field: "granted",
                            ascending: !sortField?.ascending,
                          });
                        }}
                      />
                    </HStack>
                  </td>
                  <td className=" whitespace-nowrap">
                    <HStack className="justify-end">
                      VESTED OPTIONS
                      <SortingComponent
                        fieldName="vested"
                        selectedFieldName={sortField?.field || ""}
                        isAscending={sortField?.ascending || false}
                        onChangeSort={() => {
                          setSortField({
                            field: "vested",
                            ascending: !sortField?.ascending,
                          });
                        }}
                      />
                    </HStack>
                  </td>
                  {buyback?.transactionType === "buyback" ? (
                    <td className=" whitespace-nowrap">
                      <HStack className="justify-end">
                        EXERCISED OPTIONS
                        <SortingComponent
                          fieldName="exercised"
                          selectedFieldName={sortField?.field || ""}
                          isAscending={sortField?.ascending || false}
                          onChangeSort={() => {
                            setSortField({
                              field: "exercised",
                              ascending: !sortField?.ascending,
                            });
                          }}
                        />
                      </HStack>
                    </td>
                  ) : (
                    <>
                      <td className=" whitespace-nowrap">
                        <HStack className="justify-end">
                          CASH EXERCISED{" "}
                          <SortingComponent
                            fieldName="cashExercisedOptions"
                            selectedFieldName={sortField?.field || ""}
                            isAscending={sortField?.ascending || false}
                            onChangeSort={() => {
                              setSortField({
                                field: "cashExercisedOptions",
                                ascending: !sortField?.ascending,
                              });
                            }}
                          />
                        </HStack>
                      </td>
                      <td className=" whitespace-nowrap">
                        <HStack className="justify-end">
                          STOCK EXERCISED{" "}
                          <SortingComponent
                            fieldName="stockExercisedOptions"
                            selectedFieldName={sortField?.field || ""}
                            isAscending={sortField?.ascending || false}
                            onChangeSort={() => {
                              setSortField({
                                field: "stockExercisedOptions",
                                ascending: !sortField?.ascending,
                              });
                            }}
                          />
                        </HStack>
                      </td>
                      <td className=" whitespace-nowrap">
                        <HStack className="justify-end">
                          FORFIETED OPTIONS{" "}
                          <SortingComponent
                            fieldName="forfietedOptions"
                            selectedFieldName={sortField?.field || ""}
                            isAscending={sortField?.ascending || false}
                            onChangeSort={() => {
                              setSortField({
                                field: "forfietedOptions",
                                ascending: !sortField?.ascending,
                              });
                            }}
                          />
                        </HStack>
                      </td>
                    </>
                  )}
                  <td className=" whitespace-nowrap">
                    <HStack className="justify-end">
                      ELIGIBILITY
                      <SortingComponent
                        fieldName="eligibility"
                        selectedFieldName={sortField?.field || ""}
                        isAscending={sortField?.ascending || false}
                        onChangeSort={() => {
                          setSortField({
                            field: "eligibility",
                            ascending: !sortField?.ascending,
                          });
                        }}
                      />
                    </HStack>
                  </td>
                  {buyback?.state !== "DRAFT" && (
                    <>
                      <td className=" whitespace-nowrap">
                        <HStack className="justify-end">
                          WILLINGNESS
                          <SortingComponent
                            fieldName="willingness"
                            selectedFieldName={sortField?.field || ""}
                            isAscending={sortField?.ascending || false}
                            onChangeSort={() => {
                              setSortField({
                                field: "willingness",
                                ascending: !sortField?.ascending,
                              });
                            }}
                          />
                        </HStack>
                      </td>
                      <td className=" whitespace-nowrap">
                        <HStack className="justify-end">
                          STATUS
                          <SortingComponent
                            fieldName="state"
                            selectedFieldName={sortField?.field || ""}
                            isAscending={sortField?.ascending || false}
                            onChangeSort={() => {
                              setSortField({
                                field: "state",
                                ascending: !sortField?.ascending,
                              });
                            }}
                          />
                        </HStack>
                      </td>
                    </>
                  )}
                </tr>
              </thead>
              <tbody
                className={`${
                  isPlaceholderData ? "loading" : ""
                } font-medium text-gray-600`}
              >
                {currentTableData &&
                  currentTableData?.map((emp, index) => (
                    <tr key={index} className="border-b border-dashed ">
                      <td className="px-3 py-3">
                        <Box>
                          <p>{emp.employeeName}</p>
                        </Box>
                      </td>
                      <td className="px-3 py-3 text-right">
                        <Box>
                          <p>{emp.granted}</p>
                        </Box>
                      </td>
                      <td className="px-3 py-3 text-right ">
                        <Box>
                          <p>{emp.vested}</p>
                        </Box>
                      </td>
                      {buyback?.transactionType === "buyback" ? (
                        <td className="px-3 py-3 text-right">
                          <Box>
                            <p>{emp.exercised}</p>
                          </Box>
                        </td>
                      ) : (
                        <>
                          <td className="px-3 py-3 text-right">
                            <Box>
                              <p>{emp.cashExercisedOptions}</p>
                            </Box>
                          </td>
                          <td className="px-3 py-3 text-right">
                            <Box>
                              <p>{emp.stockExercisedOptions}</p>
                            </Box>
                          </td>
                          <td className="px-3 py-3 text-right">
                            <Box>
                              <p>{emp.forfietedOptions || 0}</p>
                            </Box>
                          </td>
                        </>
                      )}
                      <td className="px-3 py-3 text-right">
                        <VStack>
                          <Box className="flex flex-row items-center justify-end gap-2">
                            {buyback?.transactionType !== "buyback" &&
                            buyback?.state === "DRAFT" &&
                            editRow?.editable &&
                            editRow?.id === emp.employeeId ? (
                              <>
                                <Input
                                  type="number"
                                  className="w-28"
                                  value={updateEligibility}
                                  onChange={(e) => {
                                    if (
                                      parseInt(e.target.value, 10) >
                                      parseInt(emp.vested, 10) -
                                        parseInt(emp.exercised, 10)
                                    ) {
                                      errorMessage.setMessage(
                                        "Eligibility can not be greater than un exercised options"
                                      );
                                    } else {
                                      errorMessage.setMessage("");
                                    }
                                    setUpdateEligibility(
                                      parseInt(e.target.value, 10)
                                    );
                                  }}
                                />
                                <Icon
                                  icon="ic:round-save-as"
                                  className="cursor-pointer hover:text-orange-501"
                                  onClick={() => {
                                    setEditRow({
                                      editable: false,
                                      id: emp.employeeId,
                                    });
                                    setSave(true);
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <p className="text-right">{emp.eligibility}</p>
                                {/* {buyback?.state === "DRAFT" &&
                                  buyback?.transactionType !== "buyback" && (
                                    <Icon
                                      icon="heroicons:pencil-square-20-solid"
                                      className="cursor-pointer hover:text-orange-501"
                                      onClick={() => {
                                        errorMessage.setMessage("");
                                        setEditRow({
                                          editable: true,
                                          id: emp.employeeId,
                                        });
                                      }}
                                    />
                                  )} */}
                              </>
                            )}
                          </Box>
                          {errorMessage.message !== "" &&
                            editRow?.editable &&
                            editRow?.id === emp.employeeId && (
                              <Error text={errorMessage.message} />
                            )}
                        </VStack>
                      </td>
                      {buyback?.state !== "DRAFT" && (
                        <>
                          <td className="px-3 py-3 text-right">
                            <Box>
                              <p>
                                {emp?.state !== "SIGN_GIVEN" ||
                                emp?.state !== "WILLINGNESS_GIVEN"
                                  ? emp.willingness
                                  : 0}
                              </p>
                            </Box>
                          </td>
                          <td className="px-3 py-3 text-right">
                            <Box>
                              <p>
                                {emp?.state === "SIGN_GIVEN" ||
                                emp?.state === "WILLINGNESS_GIVEN"
                                  ? "OFFERED"
                                  : emp?.state.split("_").join(" ")}
                              </p>
                            </Box>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                <tr className="text-sm font-semibold">
                  <td className="px-3 py-3 text-right ">
                    <p>Total</p>
                  </td>
                  <td className="px-3 py-3 text-right ">
                    <p>
                      {cumulativeOffers?.reduce((a, b) => a + b.granted, 0) ||
                        0}
                    </p>
                  </td>
                  <td className="px-3 py-3 text-right ">
                    <p>
                      {cumulativeOffers?.reduce((a, b) => a + b.vested, 0) || 0}
                    </p>
                  </td>
                  {buyback?.transactionType !== "buyback" ? (
                    <>
                      <td className="px-3 py-3 text-right ">
                        <p>
                          {cumulativeOffers?.reduce(
                            (a, b) => a + b.cashExercisedOptions,
                            0
                          ) || 0}
                        </p>
                      </td>
                      <td className="px-3 py-3 text-right ">
                        <p>
                          {cumulativeOffers?.reduce(
                            (a, b) => a + b.stockExercisedOptions,
                            0
                          ) || 0}
                        </p>
                      </td>
                      <td className="px-3 py-3 text-right ">
                        <p>
                          {cumulativeOffers?.reduce(
                            (a, b) => a + b.forfeitedOptions,
                            0
                          ) || 0}
                        </p>
                      </td>
                    </>
                  ) : (
                    <td className="px-3 py-3 text-right ">
                      <p>
                        {cumulativeOffers?.reduce(
                          (a, b) => a + b.cashExercisedOptions,
                          0
                        ) +
                          cumulativeOffers?.reduce(
                            (a, b) => a + b.stockExercisedOptions,
                            0
                          ) +
                          cumulativeOffers?.reduce(
                            (a, b) => a + b.forfeitedOptions,
                            0
                          ) || 0}
                      </p>
                    </td>
                  )}
                  <td className="px-3 py-3 text-right ">
                    <p>
                      {cumulativeOffers?.reduce(
                        (a, b) => a + b.eligibility,
                        0
                      ) || 0}
                    </p>
                  </td>
                  {buyback?.state.toLowerCase() !== "draft" && (
                    <>
                      <td className="px-3 py-3 text-right ">
                        <p>
                          {cumulativeOffers?.reduce(
                            (a, b) => a + b.willingness,
                            0
                          )}
                        </p>
                      </td>
                    </>
                  )}
                  <td className="px-3 py-3 text-right pr-[3%]"></td>
                </tr>
              </tbody>
            </table>
            {currentTableData.length === 0 && <EmptyTable header="No Offers" />}
          </Box>
        </Box>
        <Box className="flex justify-between mt-8">
          <BasicMenu
            defaultValue={pageSize}
            options={[5, 10, 20, 50, 100]}
            onOptionChange={(value) => setPageSize(value)}
          />
          <Pagination
            className=""
            currentPage={currentPage}
            totalCount={cumulativeOffers?.length}
            pageSize={pageSize}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </Box>
        {((buyback?.transactionType === "exercise and liquidate" &&
          buyback?.elState !== "DRAFT") || // we enable this condition later to closed
          ((!buyback?.autoPilot ||
            buyback?.state === "DRAFT" ||
            buyback?.state === "CLOSED") &&
            buyback?.state !== "COMPLETED")) &&
          !isUserEsopViewer && (
            <HStack className="justify-end gap-4 mt-8">
              <ButtonSecondary onClick={discard}>
                {" "}
                {!isLoading ? (
                  "Discard"
                ) : (
                  <Icon
                    className="animate-spin"
                    icon="lucide:loader-2"
                    width={36}
                  />
                )}
              </ButtonSecondary>
              <ButtonPrimary
                onClick={transactionAction}
                disabled={errorMessage.message !== ""}
              >
                {buybackActionType}
              </ButtonPrimary>
            </HStack>
          )}
      </Box>
    </>
  );
}

export default BuybackEmployeeList;
