import Box from "@mui/material/Box";
import { useFormikContext } from "formik";
import { toast } from "react-toastify";
import { Input, Label } from "../components/shared/InputField";
import {
  ButtonPrimary,
  HStack,
  VStack,
  Error,
  ButtonPrimary1,
} from "../components/utils";
import { initialGroupName } from "../pages/onboarding/initValues";
import { useAddShareHolderGroupName } from "../queries/onBoarding";
import {
  GroupName,
  HistoricalRoundModel,
  Secondary,
} from "../types/HistoricDataModel";

export type AddGroupNameProps = {
  transactionIndex: number;
  transactionType: "investors" | "secondaries";
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
};

const AddGroupName = ({
  transactionIndex,
  transactionType,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
}: AddGroupNameProps) => {
  const { mutate: addShareHolderGroupName } = useAddShareHolderGroupName();
  const formik = useFormikContext<HistoricalRoundModel>();
  const {
    values,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
  } = formik;

  const transactionTouch = touched?.secondaries?.[
    transactionIndex
  ] as Secondary;
  const fieldTouched = transactionTouch?.investorProfile
    ?.groupName as GroupName;
  const error = errors?.secondaries?.[transactionIndex] as Secondary;
  const err = error?.investorProfile?.groupName as GroupName;
  const basicDetailErrors = err?.groupName;

  const handleOnCancel = () => {
    setFieldValue(
      `${transactionType}[${transactionIndex}].investorProfile.groupName`,
      undefined
    );
    onSecondaryAction();
  };

  const doPartialTouch = () => {
    setFieldTouched(
      `${transactionType}[${transactionIndex}].investorProfile.groupName.groupName`
    );
  };

  const handleSubmit = () => {
    const groupName: GroupName =
      values[transactionType][transactionIndex].investorProfile?.groupName ||
      initialGroupName;
    if (groupName) {
      addShareHolderGroupName(groupName, {
        onSuccess: () => {
          toast("Group Name Added Successfully", {
            type: "success",
            autoClose: 2000,
          });
          onPrimaryAction();
        },
        onError: (error: { response: { data: { reason: string } } }) => {
          toast(`${error.response.data.reason}`, {
            type: "error",
            autoClose: 2000,
          });
        },
      });
    }
  };

  return (
    <>
      <Box className="px-10 text-lg font-medium border-b py-4">
        <h6>Add Group Name</h6>
      </Box>
      <VStack className="w-full px-10 py-7 gap-4">
        <VStack className="w-full gap-4">
          <VStack className="gap-8 ">
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Group Name
                <span className="text-rose-400">*</span>
              </Label>
              <Input
                type="text"
                placeholder="Enter Group Name"
                className="w-96"
                {...getFieldProps(
                  `${transactionType}[${transactionIndex}].investorProfile.groupName.groupName`
                )}
              />
              {fieldTouched?.groupName && err?.groupName && (
                <Error text={`${err?.groupName}`} />
              )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Description</Label>
              <Input
                type="text"
                placeholder="Enter Group Description"
                className="w-96"
                {...getFieldProps(
                  `${transactionType}[${transactionIndex}].investorProfile.groupName.description`
                )}
              />
              {fieldTouched?.description && err?.description && (
                <Error text={`${err?.description}`} />
              )}
            </div>
          </VStack>
          <HStack className="justify-end">
            <ButtonPrimary1
              type="reset"
              className="text-red-500 mr-8"
              onClick={() => {
                handleOnCancel();
                onSecondaryAction();
              }}
            >
              Cancel
            </ButtonPrimary1>
            <ButtonPrimary
              onClick={() => {
                if (basicDetailErrors) {
                  doPartialTouch();
                } else {
                  handleSubmit();
                }
              }}
            >
              Save
            </ButtonPrimary>
          </HStack>
        </VStack>
      </VStack>
    </>
  );
};

export default AddGroupName;
