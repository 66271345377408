import { useMutation, useQuery } from "react-query";
import {
  getAllCompensationTemplates,
  getAllHiringPlans,
  getAllModels,
  saveCompensationTemplate,
  saveEsopModel,
  saveHiringPlan,
  updateCompensationTemplate,
  updateHiringPlan,
} from "../api/esopModel";
import { queryClient } from "./client";

export function useAddEsopModel() {
  return useMutation({
    mutationFn: saveEsopModel,
    mutationKey: "AddEsopModel",
    onSuccess: () => {
      queryClient.invalidateQueries("getAllCompensationTemplates");
      queryClient.invalidateQueries("getAllHiringPlans");
      queryClient.invalidateQueries("getAllModels");
      queryClient.refetchQueries("getAllCompensationTemplates");
      queryClient.refetchQueries("getAllHiringPlans");
      queryClient.refetchQueries("getAllModels");
    },
  });
}

export function useGetAllCompensationTemplates() {
  return useQuery({
    queryKey: "getAllCompensationTemplates",
    queryFn: getAllCompensationTemplates,
  });
}

export function useSaveCompensationTemplate() {
  return useMutation({
    mutationFn: saveCompensationTemplate,
    mutationKey: "SaveCompensationTemplate",
  });
}

export function useGetAllModels() {
  return useQuery({
    queryKey: "getAllModels",
    queryFn: getAllModels,
  });
}

export function useGetAllHiringPlans() {
  return useQuery({
    queryKey: "getAllHiringPlans",
    queryFn: getAllHiringPlans,
  });
}

export function useSaveHiringPlan() {
  return useMutation({
    mutationFn: saveHiringPlan,
    mutationKey: "saveHiringPlan",
  });
}

export function useUpdateCompensationTemplate() {
  return useMutation({
    mutationFn: updateCompensationTemplate,
    mutationKey: "UpdateCompensationTemplate",
    onSuccess: () => {
      queryClient.invalidateQueries("getAllCompensationTemplates");
      queryClient.refetchQueries("getAllCompensationTemplates");
    },
  });
}

export function useUpdateHiringPlan() {
  return useMutation({
    mutationFn: updateHiringPlan,
    mutationKey: "UpdateHiringPlan",
    onSuccess: () => {
      queryClient.invalidateQueries("getAllHiringPlans");
      queryClient.refetchQueries("getAllHiringPlans");
    },
  });
}
