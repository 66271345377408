import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { Dialog } from "@mui/material";
import { toast } from "react-toastify";
import {
  ButtonPrimary,
  HStack,
  VStack,
  Error,
  ButtonPrimary1,
} from "../components/utils";
import { formatDate, formatWithTimeZone } from "../utils/date";
import { Input, Label } from "../components/shared/InputField";
import { Select } from "../components/shared/Select";
import {
  HistoricalRoundModel,
  HistoricalRoundPopupDetail,
  Investor,
  InvestorErrorModel,
} from "../types/HistoricDataModel";
import InvestorsNameDropDown from "../components/InvestorsNameDropDown";
import {
  initialAllotmentName,
  initialPrimaryTransaction2,
  initialRoundDetail,
} from "../pages/onboarding/initValues";
import AddRoundName from "./AddRoundName";
import { useTransactionIndexStore } from "../store/transactionIndexStore";
import { OverViewTags } from "../components/AllotmentTag";
import { SwitchButton } from "../components/shared/SwitchButton";
import { bonusTag, partialTag } from "../pages/onboarding/constantValues";

export type AddPrimaryTransactionProps = {
  transactionIndex: number;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
  mode: "Add" | "Edit" | "Clone";
};

const AddPrimaryTransaction = ({
  transactionIndex,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  mode,
}: AddPrimaryTransactionProps) => {
  const formik = useFormikContext<HistoricalRoundModel>();
  const transactionIndexStore = useTransactionIndexStore();
  const [investorTransaction, setInvestorTransaction] = useState<Investor>();

  useEffect(() => {
    setInvestorTransaction(values.investors[transactionIndex]);
  }, [transactionIndex]);
  const {
    values,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
  } = formik;

  const [enableClone, setEnableClone] = useState<boolean>(false);
  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    data?: HistoricalRoundPopupDetail;
    index?: number;
  }>({
    open: false,
  });
  const investorLength = values.investorsDropdown.length;
  const fieldTouched = touched?.investors?.[transactionIndex];
  const err = errors?.investors?.[transactionIndex] as InvestorErrorModel;

  const basicDetailErrors =
    err?.investorName ||
    err?.amount ||
    err?.par ||
    err?.premium ||
    err?.numberOfShares ||
    err?.date ||
    err?.securityType ||
    err?.roundName;

  const handleDelete = () => {
    if (mode === "Edit") {
      setFieldValue(`investors[${transactionIndex}]`, investorTransaction);
    } else {
      setFieldValue(
        "investors",
        values?.investors?.filter((_, index) => index !== transactionIndex)
      );
    }
  };

  function handleAddRound(index: number) {
    const roundDetail = [...values.historicalRoundPopupDetails];
    roundDetail.splice(index, 0, initialRoundDetail);
    setFieldValue("historicalRoundPopupDetails", roundDetail);
    setDialog({
      open: true,
    });
  }

  const handleOnInput = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "Add Round")
      handleAddRound(values.historicalRoundPopupDetails.length);
  };

  function handleAddAllotmemt(index: number, length: number) {
    const transactionDetail = [...(values.investors[index].allotment || [])];
    transactionDetail.splice(length, 0, initialAllotmentName);
    setFieldValue(`investors[${index}].allotment`, transactionDetail);
  }

  const doPartialTouch = () => {
    setFieldTouched(`investors[${transactionIndex}].investorName`);
    setFieldTouched(`investors[${transactionIndex}].amount`);
    setFieldTouched(`investors[${transactionIndex}].par`);
    setFieldTouched(`investors[${transactionIndex}].premium`);
    setFieldTouched(`investors[${transactionIndex}].numberOfShares`);
    setFieldTouched(`investors[${transactionIndex}].date`);
    setFieldTouched(`investors[${transactionIndex}].securityType`);
    setFieldTouched(`investors[${transactionIndex}].roundName`);
  };

  const setPrimaryTransactionDetail = () => {
    if (
      values.investors[transactionIndex].par === 0 &&
      values.investors[transactionIndex].premium === 0
    ) {
      const length = values.investors[transactionIndex].allotment?.length;
      setFieldValue(`investors[${transactionIndex}].allotment[${length}]`, {
        name: bonusTag.name,
        identifier: bonusTag.identifier,
      });
    }
    if (mode === "Add") {
      setFieldValue(`investors[${transactionIndex}].investorProfile`, null);
      setFieldValue(`investors[${transactionIndex}].conversionDetails`, null);
      setFieldValue(`investors[${transactionIndex}].redemptionDetails`, null);
    }
  };

  const handleClone = () => {
    handleAddAllotmemt(
      transactionIndex,
      values.investors[transactionIndex].allotment?.length || 0
    );
    if (enableClone) {
      const cloneIndex = transactionIndexStore.index;
      handleAddTransaction(
        values.investors.length,
        values.investors[cloneIndex]
      );
    } else {
      handleAddTransaction(values.investors.length);
    }
    toast("Transaction Added Successfully!", {
      type: "success",
      autoClose: 2000,
    });
  };

  const handleSubmitSaveAndClose = () => {
    if (
      values.investors[transactionIndex] !== investorTransaction ||
      mode === "Edit"
    ) {
      setPrimaryTransactionDetail();
      onPrimaryAction();
      toast(
        mode === "Edit"
          ? "Transaction Edited Successfully!"
          : "Transaction Added Successfully!",
        {
          type: "success",
          autoClose: 2000,
        }
      );
    } else {
      toast("Duplicate Transaction Cant be Added!", {
        type: "error",
        autoClose: 2000,
      });
    }
  };

  const handleSubmitSaveAndContinue = () => {
    if (values.investors[transactionIndex] !== investorTransaction) {
      setPrimaryTransactionDetail();
      handleClone();
    } else {
      toast("Duplicate Transaction Cant be Added!", {
        type: "error",
        autoClose: 2000,
      });
    }
  };

  function handleAddTransaction(index: number, investor?: Investor) {
    const investors = [...values.investors];
    investors.splice(index, 0, investor || initialPrimaryTransaction2);
    setFieldValue("investors", investors);
  }

  const calculateAmount = () => {
    setFieldValue(
      `investors[${transactionIndex}].amount`,
      ((values.investors[transactionIndex].par || 0) +
        (values.investors[transactionIndex].premium || 0)) *
        (values.investors[transactionIndex].numberOfShares || 0)
    );
  };

  const checkPartiallyPaid = (investor: Investor): boolean => {
    const shareprice = (investor?.premium || 0) + (investor?.par || 0);
    const totalAmount =
      shareprice * (investor?.numberOfShares || 0) - shareprice;
    const total = investor?.amount || 0;
    return total < totalAmount;
  };

  return (
    <>
      <Box className="px-5 text-lg font-medium border-b py-5">
        <HStack className="justify-between">
          <h6>Add Primary Transaction</h6>
          <HStack className="flex flex-row-reverse justify-start  items-center  text-sm font-semibold ">
            {mode !== "Edit" && (
              <SwitchButton
                className="text-xs font-normal items-center m-1"
                value={enableClone}
                label="Retain Current Information"
                onClick={() => {
                  transactionIndexStore.setIndex(transactionIndex);
                  setEnableClone(!enableClone);
                }}
              />
            )}
          </HStack>
        </HStack>
      </Box>
      <Dialog open={dialog.open} maxWidth="md">
        <AddRoundName
          historicalRoundIndex={values.historicalRoundPopupDetails.length - 1}
          onPrimaryAction={() => setDialog({ open: false })}
          onSecondaryAction={() => setDialog({ open: false })}
        />
      </Dialog>
      <VStack className="w-full px-10 py-7 gap-5">
        <VStack className="w-full gap-5">
          <HStack className="gap-5">
            <div className="flex-1">
              <Label className="text-sm font-normal">Investor Name</Label>
              <InvestorsNameDropDown
                dropDownOptions={values.investorsDropdown}
                selectedOptions={
                  values.investors[transactionIndex].investorName
                }
                shouldCreateNewOption
                placeholderText=" Enter Investor Name"
                updateNewValue={(investorName: string) => {
                  transactionIndexStore.setIndex(transactionIndex);
                  setFieldValue(
                    `investors[${transactionIndex}].investorName`,
                    investorName
                  );
                  if (!values.investorsDropdown.includes(investorName))
                    setFieldValue(
                      `investorsDropdown[${investorLength}]`,
                      investorName
                    );
                }}
              />
              {fieldTouched?.investorName && err?.investorName && (
                <Error text={err?.investorName} />
              )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Security Type</Label>
              <Select
                options={values.securitiesDropdown}
                {...getFieldProps(
                  `investors[${transactionIndex}].securityType`
                )}
              />
              {fieldTouched?.securityType && err?.securityType && (
                <Error text={err?.securityType} />
              )}
            </div>
          </HStack>
          <HStack className="gap-5">
            <div className="flex-1">
              <Label className="text-sm font-normal">PAR</Label>
              <Input
                type="number"
                placeholder="Eg:1,200"
                className="w-80"
                {...getFieldProps(`investors[${transactionIndex}].par`)}
              />
              {fieldTouched?.par && err?.par && <Error text={err?.par} />}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Premium</Label>
              <Input
                type="number"
                placeholder="Eg:1,020"
                className="w-80"
                {...getFieldProps(`investors[${transactionIndex}].premium`)}
              />
              {fieldTouched?.premium && err?.premium && (
                <Error text={err?.premium} />
              )}
            </div>
          </HStack>
          <HStack className="gap-5">
            <div className="flex-1">
              <Label className="text-sm font-normal">Number of Shares</Label>
              <Input
                type="number"
                placeholder="Eg:1,200"
                className="w-80"
                {...getFieldProps(
                  `investors[${transactionIndex}].numberOfShares`
                )}
              />
              {fieldTouched?.numberOfShares && err?.numberOfShares && (
                <Error text={err?.numberOfShares} />
              )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Amount</Label>
              <Input
                type="number"
                placeholder="Eg:1,020"
                className="w-80"
                onFocus={() => calculateAmount()}
                {...formik.getFieldProps(
                  `investors[${transactionIndex}].amount`
                )}
              />
              {fieldTouched?.amount && err?.amount && (
                <Error text={err?.amount} />
              )}
            </div>
          </HStack>
          <HStack className="gap-5">
            <div className="flex-1">
              <Label className="text-sm font-normal">Date</Label>
              <Input
                type="date"
                placeholder="Eg:DD/MM/YYYY"
                className="w-80"
                value={formatDate(
                  values.investors[transactionIndex].date || new Date()
                )}
                onChange={(e) =>
                  setFieldValue(
                    `investors[${transactionIndex}].date`,
                    formatWithTimeZone(e.target.value)
                  )
                }
              />
              {fieldTouched?.date && err?.date && <Error text={err?.date} />}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Round</Label>
              <Select
                options={[
                  "Add Round",
                  ...values.historicalRoundPopupDetails.map(
                    (roundDetail) => roundDetail.roundName
                  ),
                ]}
                onInput={handleOnInput}
                {...getFieldProps(`investors[${transactionIndex}].roundName`)}
              />
              {fieldTouched?.roundName && err?.roundName && (
                <Error text={err?.roundName} />
              )}
            </div>
          </HStack>
          <HStack className="justify-between">
            <HStack className="w-32 py-4">
              <Box>
                <HStack>
                  {values.investors[transactionIndex].par === 0 &&
                    values.investors[transactionIndex].premium === 0 && (
                      <OverViewTags tagName={"Bonus"} />
                    )}
                  {checkPartiallyPaid(values.investors[transactionIndex]) && (
                    <OverViewTags tagName={partialTag.name} />
                  )}
                </HStack>
              </Box>
            </HStack>

            <HStack className="justify-end">
              <ButtonPrimary1
                type="reset"
                className="text-red-500 mr-4"
                onClick={() => {
                  handleDelete();
                  onSecondaryAction();
                }}
              >
                Cancel
              </ButtonPrimary1>
              <ButtonPrimary1
                className="text-red-500 mr-4"
                onClick={() => {
                  if (basicDetailErrors) {
                    doPartialTouch();
                  } else {
                    handleSubmitSaveAndClose();
                  }
                }}
              >
                Save & Close
              </ButtonPrimary1>
              {mode !== "Edit" && (
                <ButtonPrimary
                  onClick={() => {
                    if (basicDetailErrors) {
                      doPartialTouch();
                    } else {
                      handleSubmitSaveAndContinue();
                    }
                  }}
                >
                  Save & Add Another
                </ButtonPrimary>
              )}
            </HStack>
          </HStack>
        </VStack>
      </VStack>
    </>
  );
};

export default AddPrimaryTransaction;
