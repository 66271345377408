import _ from "lodash";
import { useMutation, useQuery } from "react-query";
import { DocumentInterface } from "../types/DocumentsInterface";
import {
  addGrant,
  addPlan,
  changeOptionStates,
  editGrant,
  editPlan,
  getAllGrants,
  getEsopPlansSummary,
  getPlanDetails,
  getNextStates,
  getNextPlanStates,
  changePlanState,
  uploadPlanDocument,
  getAllGrantActivity,
  deleteGrant,
  getGrantTemplate,
  addGrantExcel,
  deletePlan,
  getCreatePlanDetails,
  getCurrentPoolSize,
  getCapTablePreviewAfterPoolUpdate,
  getGenerateFinancialReport,
  getGenerateFinancialReportWithVesting,
  getSH6Report,
  addEditPoolSize,
  getCompanyUsers,
  savePlanOwner,
  getOwnersOfPlan,
  uploadFile,
  validateExcel,
  downloadTemplate,
  importAll,
  generateReport,
  getEsopAccounting,
  bulkGenerateGrantLetters,
  getAllPlanDocs,
  updatePlanDocs,
  getPresignedUrl,
  getTransactionsCard,
  downloadPoolStatement,
  signAndApproveGrantLetter,
  previewSettlementLetter,
  updateSettlementLetter,
} from "../api/Esop";
import { PendingRequest, RequestType } from "./pendingRequests";
import { AddPlanRes } from "../types/AddPlanRes";
import { Grant } from "../types/Grant";
import { queryClient } from "./client";
import {
  randomEsopCard,
  randomGrant,
  randomPlan,
  randomEsopOverview,
} from "./placeholders";
import { DeleteResponse } from "../types/Employee";
import { GenericAxiosResponse } from "../types/EsopPlan";
import { exportExcel } from "../api/ExportExcel";
import { data } from "../pages/allPlans/GrantPrice";

export function useEsopPlans() {
  return useQuery({
    queryKey: "esopPlansSummary",
    queryFn: getEsopPlansSummary,
    select: (data) =>
      data.esopTableDtoList.filter((p) => p.planName !== "Total"),
    placeholderData: randomEsopOverview(),
  });
}
export function useIsPoolExists() {
  return useQuery({
    queryKey: "esopPlansSummary",
    queryFn: getEsopPlansSummary,
    select: (data) => data.isPoolExists,
  });
}
export function useEsopPlanNames() {
  return useQuery({
    queryKey: "esopPlansSummary",
    queryFn: getEsopPlansSummary,
    select: (data) =>
      data.esopTableDtoList
        .filter((p) => p.planName !== "Total")
        .map((plan) => plan.planName),
  });
}

export function useAddPlan() {
  return useMutation({
    mutationKey: "addPlan",
    mutationFn: addPlan,
    onSuccess: (data) => {
      queryClient.setQueryData("addPlanResult", data);
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.refetchQueries("esopPlansSummary");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("allGrants");
    },
    onError: () => {},
  });
}

export function useAddPlanResult() {
  return useQuery<AddPlanRes>({
    queryKey: "addPlanResult",
    queryFn: undefined,
  });
}

export function useGrants() {
  return useQuery({
    queryKey: "allGrants",
    queryFn: getAllGrants,
    select: (grants) => {
      grants = grants.filter(
        (grant) => grant.optionHolderState !== "TERMINATED"
      );
      grants = grants.sort(
        (a, b) =>
          new Date(a.grantDate).getTime() - new Date(b.grantDate).getTime()
      );
      let cumulativeGrantedOptions = 0;

      const grantsWithCumulation = [];
      for (const grant of grants) {
        cumulativeGrantedOptions += grant.optionsGranted;
        const vestingExtended = {
          ...grant,
          cumulativeGrantedOptions,
        };
        grantsWithCumulation.push(vestingExtended);
      }
      return grantsWithCumulation;
    },
    placeholderData: Array(60)
      .fill({})
      .map((_i) => randomGrant()) as Grant[],
  });
}

export function useGrant(id: number) {
  const { data: _grants, ...rest } = useGrants();
  const grant = (_grants || []).find((grant) => grant.optionHolderId === id);
  return { grant, ...rest };
}

export function useAddGrant() {
  return useMutation({
    mutationKey: "addGrant",
    mutationFn: addGrant,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.invalidateQueries("vestingEvents");
      queryClient.invalidateQueries("allGrantActivity");
      queryClient.refetchQueries("allGrants");
      queryClient.refetchQueries("esopPlansSummary");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("allGrantActivity");
      queryClient.refetchQueries("vestingEvents");
    },
    onError: () => {},
  });
}

export function useEditGrant() {
  return useMutation({
    mutationKey: "editGrant",
    mutationFn: editGrant,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.invalidateQueries("vestingEvents");
      queryClient.invalidateQueries("allGrantActivity");
      queryClient.refetchQueries("allGrants");
      queryClient.refetchQueries("esopPlansSummary");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("allGrantActivity");
      queryClient.refetchQueries("vestingEvents");
    },
    onError: () => {},
  });
}

export function useAddGrantExcel() {
  return useMutation({
    mutationKey: "addGrantExcel",
    mutationFn: addGrantExcel,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.invalidateQueries("vestingEvents");
      queryClient.invalidateQueries("allGrantActivity");
      queryClient.refetchQueries("allGrants");
      queryClient.refetchQueries("esopPlansSummary");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("allGrantActivity");
      queryClient.refetchQueries("vestingEvents");
    },
    onError: (error: { response: { data: { reason: string } } }) => {},
  });
}

export function useGrantTemplate() {
  return useQuery({
    queryKey: "grantTemplate",
    queryFn: getGrantTemplate,
    select: (data) => data.s3link,
  });
}

export function useGetEsopPlanDetails(planName: string) {
  return useQuery({
    queryKey: "getPlanDetails",
    queryFn: () => getPlanDetails(planName),
    onSuccess: (data) => {
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.refetchQueries("esopPlansSummary");
    },
  });
}

export function useNextStates(optionHolderId: number) {
  return useQuery({
    queryKey: ["nextStates", optionHolderId],
    queryFn: getNextStates,
    staleTime: 0,
  });
}

export function useChangeOptionStates() {
  return useMutation({
    mutationKey: "changeOptionState",
    mutationFn: changeOptionStates,
    onSuccess: () => {
      queryClient.invalidateQueries("nextStates");
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.invalidateQueries("allGrantActivity");
      queryClient.refetchQueries("nextStates");
      queryClient.refetchQueries("allGrants");
      queryClient.refetchQueries("esopPlansSummary");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("allGrantActivity");
    },
    onError: () => {},
  });
}
export function useEditPlan() {
  return useMutation({
    mutationKey: "editPlan",
    mutationFn: editPlan,
    onSuccess: (data) => {
      queryClient.setQueryData("addPlanResult", data);
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("nextPlanStates");
      queryClient.invalidateQueries("createPlanDetails");
      queryClient.refetchQueries("createPlanDetails");
      queryClient.refetchQueries("nextPlanStates");
      queryClient.refetchQueries("esopPlansSummary");
    },
  });
}
export function useNextPlanState(esopPlanId: number) {
  return useQuery({
    queryKey: ["nextPlanStates", esopPlanId],
    queryFn: getNextPlanStates,
  });
}
export function usePlanStateChange() {
  return useMutation({
    mutationKey: "changePlanState",
    mutationFn: changePlanState,
    onSuccess: () => {
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("nextPlanStates");
      queryClient.refetchQueries("esopPlansSummary");
      queryClient.refetchQueries("nextPlanStates");
      queryClient.invalidateQueries("createPlanDetails");
      queryClient.refetchQueries("createPlanDetails");
    },
    onError: () => {},
  });
}
export function useUploadPlanDocument() {
  return useMutation({
    mutationKey: undefined,
    mutationFn: uploadPlanDocument,
  });
}

export function useAllGrantActivity() {
  return useQuery({
    queryKey: "allGrantActivity",
    queryFn: getAllGrantActivity,
  });
}

export function useRecentDrafts() {
  const { data: grantActivities, ...rest } = useAllGrantActivity();
  const createdStateGrants: PendingRequest[] = (grantActivities || [])
    .filter((grantActivity) => grantActivity.optionState === "Created")
    .map((grantActivity) => ({
      id: grantActivity.optionHolderId,
      type: "Grant Created",
      subject: `${grantActivity.optionHolderName} - New Grant`,
      requestDate: new Date(grantActivity.dateOfTransaction),
    }));
  createdStateGrants.sort(
    (a, b) =>
      new Date(b.requestDate || new Date()).getTime() -
      new Date(a.requestDate || new Date()).getTime()
  );
  return {
    data: createdStateGrants,
    ...rest,
  };
}

export function useTransactionsCard() {
  return useQuery({
    queryKey: "transactionsCard",
    queryFn: getTransactionsCard,
  });
}

export function useRecentGrantOffered() {
  const { data: grantActivities, ...rest } = useAllGrantActivity();
  const offeredStateGrants: PendingRequest[] = (grantActivities || [])
    .filter((grantActivity) => grantActivity.optionState === "Offered")
    .map((grantActivity) => ({
      id: grantActivity.optionHolderId,
      type: "Grant Offered",
      subject: `${grantActivity.optionHolderName} - Yet to accept offer`,
      requestDate: new Date(grantActivity.dateOfTransaction),
    }));
  offeredStateGrants.sort(
    (a, b) =>
      new Date(b.requestDate || new Date()).getTime() -
      new Date(a.requestDate || new Date()).getTime()
  );
  return {
    data: offeredStateGrants,
    ...rest,
  };
}

export function useRecentForApprovals() {
  const { data: grantActivities, ...rest } = useAllGrantActivity();
  const forApprovalStateGrants: PendingRequest[] = (grantActivities || [])
    .filter((grantActivity) => grantActivity.optionState === "For_Approval")
    .map((grantActivity) => ({
      id: grantActivity.optionHolderId,
      type: "Grant For Approval",
      subject: `${grantActivity.optionHolderName} - For Approval`,
      requestDate: new Date(grantActivity.dateOfTransaction),
    }));
  forApprovalStateGrants.sort(
    (a, b) =>
      new Date(b.requestDate || new Date()).getTime() -
      new Date(a.requestDate || new Date()).getTime()
  );
  return {
    data: forApprovalStateGrants,
    ...rest,
  };
}

export function useDeleteGrant() {
  return useMutation({
    mutationKey: "deleteGrant",
    mutationFn: deleteGrant,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.invalidateQueries("allGrantActivity");
      queryClient.invalidateQueries("allExercises");
      queryClient.invalidateQueries("allVestings");
      queryClient.refetchQueries("allGrants");
      queryClient.refetchQueries("esopPlansSummary");
      queryClient.refetchQueries("employeesOverview");
      queryClient.refetchQueries("allGrantActivity");
      queryClient.refetchQueries("allExercises");
      queryClient.refetchQueries("allVestings");
    },
    onError: () => {},
  });
}

export function useDeletePlan() {
  return useMutation({
    mutationKey: "deletePlan",
    mutationFn: deletePlan,
    onSuccess: (data) => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("employeesOverview");
      queryClient.refetchQueries("allGrants");
      queryClient.refetchQueries("esopPlansSummary");
      queryClient.refetchQueries("employeesOverview");
    },
    onError: (error: GenericAxiosResponse) => {},
  });
}

export function useCreatePlanDetails() {
  return useQuery({
    queryKey: "createPlanDetails",
    queryFn: getCreatePlanDetails,
  });
}

export function useCompanyUsers() {
  return useQuery({
    queryKey: "companyUser",
    queryFn: getCompanyUsers,
  });
}

export function useOwnersOfPlan(planId: Number) {
  return useQuery({
    queryKey: ["getOwnersOfPlan", planId],
    queryFn: getOwnersOfPlan,
  });
}

export function useCurrentPoolSize() {
  return useQuery({
    queryKey: "currentPoolSize",
    queryFn: getCurrentPoolSize,
  });
}

export function usePreview() {
  return useMutation({
    mutationKey: "preview",
    mutationFn: getCapTablePreviewAfterPoolUpdate,
    onError: (error: GenericAxiosResponse) => {},
  });
}

export function useEditAddPoolSize() {
  return useMutation({
    mutationKey: "AddEditPoolSize",
    mutationFn: addEditPoolSize,
    onSuccess: () => {
      queryClient.invalidateQueries("currentPoolSize");
      queryClient.refetchQueries("currentPoolSize");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.refetchQueries("esopPlansSummary");
    },
    onError: () => {},
  });
}
export function useGenerateFinancialReport(
  date: string,
  includeResignedEmployees: boolean,
  planType: string,
  onSuccess: any
) {
  return useQuery({
    queryKey: [
      "generateFinancialReport",
      date,
      includeResignedEmployees,
      planType,
    ],
    queryFn: getGenerateFinancialReport,
    enabled: false,
    onSuccess,
  });
}

export function useGenerateFinancialReportWithVesting(
  date: string,
  showFutureVestings: boolean,
  includeResignedEmployees: boolean,
  planType: string,
  onSuccess: any
) {
  return useQuery({
    queryKey: [
      "generateFinancialReportWithVesting",
      date,
      showFutureVestings,
      includeResignedEmployees,
      planType,
    ],
    queryFn: getGenerateFinancialReportWithVesting,
    enabled: false,
    onSuccess,
  });
}

export function useGenerateSH6Report(date: string, id: string, onSuccess: any) {
  return useQuery({
    queryKey: ["generateSH6Report", date, id],
    queryFn: getSH6Report,
    enabled: false,
    onSuccess,
  });
}

export function useExportToExcel() {
  return useMutation({
    mutationKey: "excelExport",
    mutationFn: exportExcel,
  });
}

export function useDownloadEsopAccounting() {
  return useMutation({
    mutationKey: "downloadEsopAccounting",
    mutationFn: getEsopAccounting,
  });
}

export function useOwnerShip() {
  return useMutation({
    mutationKey: "ownerShip",
    mutationFn: savePlanOwner,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("esopPlansSummary");
      queryClient.invalidateQueries("allGrantActivity");
      queryClient.invalidateQueries("getOwnersOfPlan");
      queryClient.refetchQueries("allGrants");
      queryClient.refetchQueries("esopPlansSummary");
      queryClient.refetchQueries("allGrantActivity");
      queryClient.refetchQueries("getOwnersOfPlan");
    },
  });
}

export function useUploadFile() {
  return useMutation({
    mutationKey: "uploadFile",
    mutationFn: uploadFile,
  });
}

export function useValidateExcel(fileKey: string) {
  return useQuery({
    queryKey: ["validateExcel", fileKey],
    queryFn: validateExcel,
    enabled: false,
    select: (data) => data.data,
  });
}

export function useGenerateReport(
  fileKey: string,
  dateTillVestingToBeDone: string
) {
  return useQuery({
    queryKey: ["generateReport", fileKey, dateTillVestingToBeDone],
    queryFn: generateReport,
    enabled: false,
    select: (data) => data,
  });
}

export function useDownloadTemplate() {
  return useQuery({
    queryKey: "downloadTemplate",
    queryFn: downloadTemplate,
    enabled: false,
    select: (data) => data,
  });
}

export function useImportExcel() {
  return useMutation({
    mutationKey: "importAll",
    mutationFn: importAll,
  });
}

export function useGenerateGrantLettersBulk() {
  return useMutation({
    mutationKey: "bulkGenerateGrantLetters",
    mutationFn: bulkGenerateGrantLetters,
  });
}

export function useGetAllPlanDocs(planId: number) {
  return useQuery({
    queryKey: ["getAllPlanDocs", planId],
    queryFn: getAllPlanDocs,
  });
}

export function useUpdatePlanDocs() {
  return useMutation({
    mutationKey: ["updatePlan"],
    mutationFn: updatePlanDocs,
    onSuccess: () => {
      queryClient.invalidateQueries("getAllPlanDocs");
      queryClient.refetchQueries("getAllPlanDocs");
    },
  });
}

export function useGetPresignedUrl(documentPath: string) {
  return useQuery({
    queryKey: ["getPresignedUrl", documentPath],
    queryFn: getPresignedUrl,
    enabled: false,
    onSuccess: () => {
      queryClient.invalidateQueries("getPresignedUrl");
    },
  });
}
export function useSignAndApproveGrant() {
  return useMutation({
    mutationKey: ["signGrant"],
    mutationFn: signAndApproveGrantLetter,
    onSuccess: () => {
      queryClient.invalidateQueries("allGrants");
      queryClient.invalidateQueries("transactionsCard");
      queryClient.refetchQueries("allGrants");
      queryClient.refetchQueries("transactionsCard");
    },
  });
}

export function usePreviewSettlementLetter() {
  return useMutation({
    mutationKey: ["signSettlementLetter"],
    mutationFn: previewSettlementLetter,
  });
}

export function useUpdateSettlementLetterAfterPreviewing() {
  return useMutation({
    mutationKey: ["updateSettlementLetter"],
    mutationFn: updateSettlementLetter,
    onSuccess: () => {
      queryClient.invalidateQueries("employeePageDetails");
      queryClient.refetchQueries("employeePageDetails");
      queryClient.invalidateQueries("settlementLetterDetails");
      queryClient.refetchQueries("settlementLetterDetails");
    },
  });
}
