import { Icon } from "@iconify/react";
import React from "react";
import { ButtonPrimary } from "../utils";

export type ExpandButtonProps = {
  expanded: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export function ExpandButton(props: ExpandButtonProps) {
  const { expanded, ...rest } = props;
  return (
    <ButtonPrimary
      className="flex items-center justify-center w-4 h-4 px-0 py-0"
      {...rest}
    >
      {expanded ? (
        <Icon icon="akar-icons:minus" color="white" width="12" height="16" />
      ) : (
        <Icon icon="akar-icons:plus" color="white" width="12" height="16" />
      )}
    </ButtonPrimary>
  );
}
