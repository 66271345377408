import { Icon } from "@iconify/react";
import { useFormikContext } from "formik";
import React from "react";
import { toast } from "react-toastify";
import { Input, Label, TextArea } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import { Box, Error, HStack, VStack } from "../../components/utils";
import {
  companyTypeDropDownValues,
  currencyDropDownValues,
} from "../../constants/DropDownValues";
import { CompanyDetailsPostModel } from "../../types/OnBoardDataModel";
import { FileTypeModel } from "./AdminProfilePage";

export type CompanyDetailFormDetail = {
  companyLogoImage?: string;
  setCompanyLogoImage: (fileDetail: FileTypeModel) => void;
};

export default function CompanyDetailForm({
  companyLogoImage,
  setCompanyLogoImage,
}: CompanyDetailFormDetail) {
  const formik = useFormikContext<CompanyDetailsPostModel>();
  const { errors, touched, getFieldProps } = formik;

  const isValidImageFileUploaded = (file: File) => {
    const validExtensions = ["png", "jpeg", "jpg"];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };

  const handleEditProfileImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.[0]) {
      const file = e.target.files[0];
      if (isValidImageFileUploaded(file)) {
        setCompanyLogoImage({
          fileBlob: URL.createObjectURL(file),
          fileName: file.name,
          fileType: `image/${file.type.split("/")[1]}`,
        });
      } else {
        toast("File type not supported", { type: "error" });
      }
    }
  };

  return (
    <div className="w-full bg-white rounded-md">
      <Box className="px-10 py-4 text-lg font-medium text-left border-b">
        <h6>Company Details</h6>
      </Box>
      <VStack className="w-full  px-10 py-4 gap-6">
        <VStack className="gap-2">
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Name of the Company
                <span className="text-rose-400">*</span>
              </Label>
              <Input
                type="text"
                placeholder="Enter Company Name"
                className=""
                {...getFieldProps("nameOfTheCompany")}
              />
              {touched.nameOfTheCompany && errors.nameOfTheCompany && (
                <Error text={errors.nameOfTheCompany} />
              )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">
                CIN Number
                <span className="text-rose-400">*</span>
              </Label>
              <Input
                type="text"
                placeholder="Eg:38928432"
                className=""
                {...getFieldProps("cinNumber")}
              />
              {touched.cinNumber && errors.cinNumber && (
                <Error text={errors.cinNumber} />
              )}
            </div>
          </HStack>
        </VStack>
        <VStack>
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Type of Company
                <span className="text-rose-400">*</span>
              </Label>
              <Select
                options={companyTypeDropDownValues}
                {...getFieldProps("typeOfTheCompany")}
              />
              {touched.typeOfTheCompany && errors.typeOfTheCompany && (
                <Error text={errors.typeOfTheCompany} />
              )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Date of Incorporation
                <span className="text-rose-400">*</span>
              </Label>
              <Input
                type="date"
                placeholder="Eg:12-12-1212"
                {...getFieldProps("dateOfIncorporation")}
              />
              {touched.dateOfIncorporation && errors.dateOfIncorporation && (
                <Error text={errors.dateOfIncorporation} />
              )}
            </div>
          </HStack>
        </VStack>

        <HStack className="gap-8">
          <div className="flex-1">
            <Label className="text-sm font-normal">
              DIPP Cetificate Number
            </Label>
            <Input
              type="text"
              placeholder="Eg: UA30829832"
              {...getFieldProps("dippCertificateNumber")}
            />
            {touched.dippCertificateNumber && errors.dippCertificateNumber && (
              <Error text={errors.dippCertificateNumber} />
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">
              Company Currency
              <span className="text-rose-400">*</span>
            </Label>
            <Select
              options={currencyDropDownValues}
              {...getFieldProps("companyCurrency")}
            />
            {touched.companyCurrency && errors.companyCurrency && (
              <Error text={errors.companyCurrency} />
            )}
          </div>
        </HStack>
        <HStack className="gap-8">
          <div className="flex-1">
            <Label className="text-sm font-normal">
              Company Email ID
              <span className="text-rose-400">*</span>
            </Label>
            <Input
              type="text"
              placeholder="Eg: example@domain.com"
              {...getFieldProps("companyEmailId")}
            />
            {touched.companyEmailId && errors.companyEmailId && (
              <Error text={errors.companyEmailId} />
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Company Website</Label>
            <Input
              type="text"
              placeholder="Eg: example.coom"
              {...getFieldProps("companyWebsite")}
            />
            {touched.companyWebsite && errors.companyWebsite && (
              <Error text={errors.companyWebsite} />
            )}
          </div>
        </HStack>
        <HStack className="gap-8">
          <div className="flex-1">
            <Label className="text-sm font-normal">
              Corporate Office Address
            </Label>
            <TextArea
              placeholder="Enter Your Company Address"
              {...getFieldProps("corporateOfficeAddress")}
            />
          </div>
          <div className="flex flex-row justify-between flex-1 w-128">
            <Label className="w-32 text-sm font-normal">Profile Photo</Label>
            <Box className="w-30">
              {companyLogoImage === "" ? (
                <label className="px-2 py-2 rounded-lg cursor-not-allowed bg-slate-100">
                  <Input
                    disabled
                    type="file"
                    className="hidden"
                    accept="image/png, image/jpeg"
                    onChange={handleEditProfileImage}
                  />
                  Upload Image
                </label>
              ) : (
                <div className="relative flex flex-col justify-between">
                  <Label>
                    <input
                      type="file"
                      accept="image/png, image/jpg, image/jpeg"
                      className="hidden"
                      onInput={handleEditProfileImage}
                      {...getFieldProps("profileImageUrl")}
                    />
                    <Icon
                      icon={"eva:edit-outline"}
                      className="absolute z-10 w-6 h-6 text-gray-400 bg-gray-100 rounded-full cursor-pointer -right-2 hover:text-black"
                    />
                  </Label>
                  <img
                    className="right-0 block w-32 h-32 space-y-3 bg-gray-400 border-2 rounded-lg"
                    src={companyLogoImage}
                    alt="avatar"
                  />
                  <Label>
                    <Icon
                      icon={"eva:close-fill"}
                      onClick={() =>
                        setCompanyLogoImage({
                          fileBlob: "",
                          fileName: "",
                          fileType: "",
                        })
                      }
                      className="absolute z-10 w-6 h-6 text-gray-400 bg-gray-100 rounded-full cursor-pointer -right-2 -bottom-2 hover:text-black"
                    />
                  </Label>
                </div>
              )}
            </Box>
          </div>
        </HStack>
      </VStack>
    </div>
  );
}
