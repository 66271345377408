import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFormikContext } from "formik";
import { useState } from "react";
import { Input, Label } from "../components/shared/InputField";
import { SwitchButton } from "../components/shared/SwitchButton";
import {
  ButtonPrimary,
  HStack,
  VStack,
  Error,
  ButtonPrimary1,
  ButtonSecondary,
} from "../components/utils";
import {
  HistoricalRoundModel,
  RoundNameErrorModel,
} from "../types/HistoricDataModel";

export type AddRoundNameProps = {
  historicalRoundIndex: number;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
};

const AddRoundName = ({
  historicalRoundIndex,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
}: AddRoundNameProps) => {
  const formik = useFormikContext<HistoricalRoundModel>();
  const {
    values,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
  } = formik;

  const fieldTouched =
    touched?.historicalRoundPopupDetails?.[historicalRoundIndex];
  const err = errors?.historicalRoundPopupDetails?.[
    historicalRoundIndex
  ] as RoundNameErrorModel;
  const basicDetailErrors = err?.roundName || err?.roundIdentifier;

  const handleOnCancel = () => {
    setFieldValue(
      "historicalRoundPopupDetails",
      values?.historicalRoundPopupDetails?.filter(
        (_, index) => index === historicalRoundIndex
      )
    );
    onSecondaryAction();
  };

  const doPartialTouch = () => {
    setFieldTouched(
      `historicalRoundPopupDetails[${historicalRoundIndex}].roundName`
    );
    setFieldTouched(
      `historicalRoundPopupDetails[${historicalRoundIndex}].roundIdentifier`
    );
  };

  const handleSubmit = () => {
    if (
      !values.historicalRoundPopupDetails[historicalRoundIndex].isPricedRound
    ) {
      const length = values.investors[historicalRoundIndex].allotment?.length;
      setFieldValue(`investors[${historicalRoundIndex}].allotment[${length}]`, {
        name: "nonPriced",
        identifier: "NP",
      });
    }

    onPrimaryAction();
  };

  const [isPricedRound, setIsPricedRound] = useState<boolean>(true);

  return (
    <>
      <Box className="px-10 text-lg font-medium border-b py-4">
        <h6>Add Round Name</h6>
      </Box>
      <VStack className="w-full px-10 py-4 gap-9">
        <VStack className="w-full gap-9">
          <HStack className="gap-8 ">
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Round Name<span className="text-rose-400">*</span>
              </Label>
              <Input
                type="text"
                placeholder="Enter Round Name"
                className="w-96"
                {...getFieldProps(
                  `historicalRoundPopupDetails[${historicalRoundIndex}].roundName`
                )}
              />
              {fieldTouched?.roundName && err?.roundName && (
                <Error text={err?.roundName} />
              )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Round Identifier
                <span className="text-rose-400">*</span>
              </Label>
              <Input
                type="text"
                placeholder="Enter Round ID"
                className="w-96"
                {...getFieldProps(
                  `historicalRoundPopupDetails[${historicalRoundIndex}].roundIdentifier`
                )}
              />
              {fieldTouched?.roundIdentifier && err?.roundIdentifier && (
                <Error text={err?.roundIdentifier} />
              )}
            </div>
          </HStack>
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">Conversation Ratio</Label>
              <Input
                type="text"
                placeholder="Eg:1:20"
                className="w-96"
                {...getFieldProps(
                  `historicalRoundPopupDetails[${historicalRoundIndex}].roundConversionRatio`
                )}
              />
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Voting Rights Ratio</Label>
              <Input
                type="text"
                placeholder="Eg:1:20"
                className="w-96"
                {...getFieldProps(
                  `historicalRoundPopupDetails[${historicalRoundIndex}].roundVotingRightsRatio`
                )}
              />
            </div>
          </HStack>
          <UpdateConverstion conversionIndex={historicalRoundIndex} />
          <HStack className="justify-between">
            <HStack className="items-center space-x-2">
              <SwitchButton
                className=""
                value={isPricedRound}
                label="Priced Round"
                onClick={() => {
                  setFieldValue(
                    `historicalRoundPopupDetails[${historicalRoundIndex}].isPricedRound`,
                    !isPricedRound
                  );
                  setIsPricedRound(!isPricedRound);
                }}
              />
            </HStack>

            <HStack className="justify-end">
              <ButtonPrimary1
                className="text-red-500 mr-8"
                onClick={() => {
                  handleOnCancel();
                }}
              >
                Cancel
              </ButtonPrimary1>
              <ButtonPrimary
                onClick={() => {
                  if (basicDetailErrors) {
                    doPartialTouch();
                  } else {
                    handleSubmit();
                  }
                }}
              >
                Save
              </ButtonPrimary>
            </HStack>
          </HStack>
        </VStack>
      </VStack>
    </>
  );
};

export default AddRoundName;

function UpdateConverstion({ conversionIndex }: { conversionIndex: number }) {
  const formik = useFormikContext<HistoricalRoundModel>();
  const { values, getFieldProps, setFieldValue } = formik;
  const keyText = `historicalRoundPopupDetails[${conversionIndex}].updateConversionRatios`;
  const updateConversion =
    values.historicalRoundPopupDetails[conversionIndex].updateConversionRatios;

  function handleAdd(index: number) {
    const schedules = updateConversion;
    const newSchedule = {
      date: "",
      isValuationAvailable: false,
      valuation: "",
      conversionRatio: "",
    };
    schedules.splice(index, 0, newSchedule);
    setFieldValue(keyText, schedules);
  }
  function handleDelete(index: number) {
    setFieldValue(
      keyText,
      values.historicalRoundPopupDetails[
        conversionIndex
      ].updateConversionRatios.filter((_, indexValue) => index !== indexValue)
    );
  }

  return (
    <Box className="text-lg font-medium border-2 rounded-lg p-2">
      <Box className=" text-lg font-medium border-b p-2">
        <HStack className="justify-between">
          <h6>Update Conversion</h6>{" "}
          <ButtonSecondary
            className="text-xxs1 leading-[20px] font-medium py-1 bg-slate-201 text-slate-501 h-auto px-2"
            onClick={() => handleAdd(updateConversion?.length)}
          >
            Add Conversion
          </ButtonSecondary>
        </HStack>
      </Box>
      <table className="w-full table-space">
        <thead className="text-xs font-medium text-gray-light">
          <tr className="">
            <td className="flex-1 hover:cursor-pointer">Date</td>
            <td className="flex-1 hover:cursor-pointer px-4">
              isValuation Available
            </td>
            <td className="flex-1 hover:cursor-pointer">Valuation</td>
            <td className="flex-1 hover:cursor-pointer">Conversion Ratio</td>
            <td className="flex-1"></td>
          </tr>
        </thead>

        <tbody className={""}>
          {values.historicalRoundPopupDetails[
            conversionIndex
          ].updateConversionRatios?.map((_, index) => {
            const isValuation =
              values.historicalRoundPopupDetails[conversionIndex]
                .updateConversionRatios[index].isValuationAvailable;
            return (
              <tr className="py-2" key={index}>
                <td className="flex-1">
                  <Input
                    type="date"
                    {...getFieldProps(`${keyText}[${index}].date`)}
                  />
                </td>
                <td className="flex-1 px-4">
                  <SwitchButton
                    className=""
                    value={isValuation || false}
                    label=""
                    onClick={() => {
                      setFieldValue(
                        `${keyText}[${index}].isValuationAvailable`,
                        !isValuation
                      );
                    }}
                  />
                </td>
                <td className="flex-1">
                  <Input
                    type="number"
                    disabled={!isValuation}
                    placeholder={!isValuation ? "N/A" : "Enter Value"}
                    {...getFieldProps(`${keyText}[${index}].valuation`)}
                  />
                </td>
                <td className="flex-1">
                  <Input
                    type="text"
                    disabled={
                      values.historicalRoundPopupDetails[conversionIndex]
                        .updateConversionRatios[index].isValuationAvailable
                    }
                    placeholder={isValuation ? "N/A" : "Enter Value"}
                    {...getFieldProps(`${keyText}[${index}].conversionRatio`)}
                  />
                </td>
                <td>
                  <div
                    onClick={() => {
                      handleDelete(index);
                    }}
                    className="text-zinc-300 hover:scale-105"
                  >
                    <DeleteIcon />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {values.historicalRoundPopupDetails[conversionIndex]
        .updateConversionRatios.length <= 0 && (
        <div className="text-gray-light text-sm text-center py-4">
          <h1>No Conversion Found</h1>
        </div>
      )}
    </Box>
  );
}
