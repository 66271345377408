import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import { Grant } from "../../types/Grant";
import {
  ButtonPrimary,
  ButtonSecondary,
  HStack,
  VStack,
} from "../../components/utils";

import {
  useExerciseRequestDetails,
  useApproveExercise,
} from "../../queries/exercise";
import { ApproveExerciseReq } from "../../types/Exercise";
import { formatDate, formatDisplayDate } from "../../utils/date";
import { useError } from "../../store/errorStore";
import { formatCurrency, getCurrencyType } from "../../utils/currencyFormatter";

function ExerciseApproval({
  grant,
  onClose = () => {},
}: {
  grant: Grant;
  onClose: () => void;
}) {
  const currency = getCurrencyType();
  const { data } = useExerciseRequestDetails(grant.optionHolderId || 0);
  const { mutate: approveExercise, status } = useApproveExercise();
  const errorMessage = useError();

  function handleApprove() {
    if (status === "success") {
      onClose();
    } else {
      approveExercise(data as ApproveExerciseReq, {
        onSuccess: () => {
          toast("Exercise Approved !", { type: "success" });
          onClose();
        },
        onError: (err: any) => {
          errorMessage.setMessage(err.response.data.reason);
          toast(err.response.data.reason, { type: "error", autoClose: 2000 });
        },
      });
    }
  }

  return (
    <>
      <div className="p-4 px-10 mt-4 ml-3 text-lg font-medium stone-700">
        <h6 className="flex justify-between">
          Approve Exercise{" "}
          <span onClick={() => onClose()} className="cursor-pointer">
            X
          </span>
        </h6>
      </div>
      <div className="border-b"></div>
      <VStack className="justify-between gap-4 p-4 px-10 min-h-[300px]">
        <HStack aria-label="section body" className="flex-wrap ">
          {[
            { field: "Employee Name", value: data?.optionHolderName },
            {
              field: "Plan Name",
              value: data?.esopPlanName,
            },
            {
              field: "Exercise Price",
              value: formatCurrency(data?.exercisePrice || 0, currency),
            },
            {
              field: "Exercisable Options",
              value: data?.numberOfShares.toLocaleString(currency),
            },
            {
              field: "Options To Exercise",
              value: data?.numberOfShares.toLocaleString(currency),
            },
            {
              field: "Date of Exercise",
              value: formatDisplayDate(data?.dateOfExercise || "2000-01-01"),
            },
            { field: "Type", value: data?.isCashExercise ? "Cash" : "Stock" },
            {
              field: "Shares after Exercise",
              value: data?.isCashExercise
                ? 0
                : (data?.numberOfShares || 1) * (data?.conversionRatio || 1),
            },
          ].map(({ field, value }, i) => (
            <HStack key={i} className="w-1/2 gap-8 p-3">
              <span className="text-sm font-normal text-stone-700">
                {field}
              </span>
              <span className="text-sm font-medium text-gray-600">
                {value?.toString()}
              </span>
            </HStack>
          ))}
        </HStack>
        <HStack className="flex flex-row-reverse gap-4">
          <ButtonPrimary
            className={`flex items-center self-end justify-center ${
              status === "success" ? "bg-green-500" : ""
            }`}
            onClick={() => handleApprove()}
          >
            {(status === "idle" || status === "error") && "Approve Request"}
            {status === "loading" && (
              <Icon
                className=" animate-spin"
                icon="lucide:loader-2"
                width={36}
              />
            )}
            {status === "success" && (
              <Icon icon="clarity:success-standard-line" width={36} />
            )}
          </ButtonPrimary>
          <ButtonSecondary
            onClick={() => onClose()}
            className="text-gray-400 bg-slate-50"
          >
            Cancel
          </ButtonSecondary>
        </HStack>
      </VStack>
    </>
  );
}
export default ExerciseApproval;
