import React from "react";
import { Box } from "../utils";

function StatusIndicator({
  status,
  className,
}: {
  status: string;
  className: string;
}) {
  return (
    <>
      {status && (
        <Box
          className={`${className} bottom-5 -right-2 flex w-5 h-5
        ${status !== "Employed" ? "bg-red-500" : "bg-green-500"}
      border-4 border-white border-solid rounded-full `}
        ></Box>
      )}
    </>
  );
}
export default StatusIndicator;
