import { Icon } from "@iconify/react";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
} from "../../components/utils";
import EsopModellingGrades from "../../constants/EsopModellingGrades";
import { HireGrades } from "../../store/esopModellingStore";

type EditHireGradeProps = {
  onPrimaryAction: (hireGrade: HireGrades) => void;
  onClose: () => void;
  data: HireGrades;
  planningPeriod: number;
};

function EditHireGrade({
  onClose = () => {},
  onPrimaryAction = () => {},
  data,
  planningPeriod,
}: EditHireGradeProps) {
  const initialHireGrades: HireGrades = {
    gradeName: data.gradeName,
    totalEmployeesPerGrade: data.totalEmployeesPerGrade,
    year: data.year,
  };

  const validationSchema = Yup.object().shape({
    gradeName: Yup.string().required("Grade Name is required"),
    year: Yup.array().of(
      Yup.number().min(0).required("Number of Hires is required")
    ),
  });
  const handleSubmit = (values: HireGrades) => {
    onPrimaryAction(values);
  };
  return (
    <Formik
      key="editHireGradeTemplate"
      initialValues={initialHireGrades}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {(formik) => (
        <Form key="editHireGrade">
          <VStack className="w-full gap-9 p-10">
            <Box className="text-lg font-medium border-b">
              <h6 className="justify-between flex">
                Edit
                <span onClick={() => onClose()} className="cursor-pointer">
                  X
                </span>{" "}
              </h6>
            </Box>
            <HStack className="gap-8 ">
              <div className="flex-1">
                <Label className="text-sm font-normal">Grade</Label>
                <Select
                  options={EsopModellingGrades}
                  {...formik.getFieldProps("gradeName")}
                />
                {formik.touched.gradeName && formik.errors.gradeName && (
                  <Error text={formik.errors.gradeName} />
                )}
              </div>
              {Array(planningPeriod)
                .fill(0)
                .map((_, yearIndex) => (
                  <div className="flex-1" key={`year-${yearIndex}`}>
                    <Label className="text-sm font-normal">
                      Year {yearIndex + 1}
                    </Label>
                    <Input
                      type="number"
                      placeholder="Number of Hires"
                      {...formik.getFieldProps(`year[${yearIndex}]`)}
                    />
                    {formik.touched.year && formik.errors.year && (
                      <Error text={formik.errors.year[yearIndex]} />
                    )}
                  </div>
                ))}
            </HStack>
            <HStack className="justify-between mt-12">
              <ButtonPrimary1
                type="reset"
                className="text-red-500 mr-8"
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </ButtonPrimary1>
              <ButtonPrimary type="submit">Create</ButtonPrimary>
            </HStack>
          </VStack>
        </Form>
      )}
    </Formik>
  );
}

export default EditHireGrade;
