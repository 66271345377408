import axios, { AxiosError, AxiosRequestHeaders } from "axios";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import {
  clearCredentials,
  decodeAuthToken,
  checkTokenValidity,
  revalidateAuth,
} from "../components/utils";
import { useAuthStore } from "../store";

const nodeApi = axios.create({
  baseURL: process.env.REACT_APP_CAPTABLE_API || "http://localhost:4001",
});
nodeApi.defaults.headers.post["Content-Type"] = "application/json";
nodeApi.interceptors.request.use((config) => {
  const accesstoken =
    localStorage.getItem("accesstoken") || useAuthStore.getState().accessToken;
  revalidateAuth();
  if (accesstoken && !config.url?.endsWith("/login")) {
    (config.headers as AxiosRequestHeaders).companyId =
      localStorage.getItem("companyId") || "";
    (config.headers as AxiosRequestHeaders).accessToken = accesstoken;
  }

  return config;
});

export default nodeApi;
