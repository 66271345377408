import { Dialog } from "@mui/material";
import EditEventDetails from "../pages/esopOverview/EditEventDetails";
import ExerciseApproval from "../pages/esopOverview/ExerciseApproval";
import ExerciseRequest from "../pages/esopOverview/ExerciseRequest";
import { GrantCreated } from "../pages/esopOverview/GrantCreated";
import VestApproval from "../pages/esopOverview/VestApproval";
import AddEditOptionSplit from "../pages/optionSplit/AddEditOptionSplit";
import FilterDetails from "../pages/optionBuyback/FilterDetails";
import { AddVestingTemplateWrapper } from "../pages/vestingSchedules/AddVestingTemplate";
import {
  useOptionSplitDialog,
  useEditEventDetailsDialog,
  useExerciseApproveDialog,
  useExerciseRequestDialog,
  useGrantCreatedDialog,
  useVestingApproveDialog,
  useVestingScheduleDialog,
  useAdvanceFilterDialog,
} from "../store/useDialogStore";

function DialogContainer() {
  const { state: exerciseRequestDialog, setState: setExerciseRequestDialog } =
    useExerciseRequestDialog();
  const { state: exerciseApproveDialog, setState: setExerciseApproveDialog } =
    useExerciseApproveDialog();
  const { state: vestingApproveDialog, setState: setVestingApproveDialog } =
    useVestingApproveDialog();
  const { state: grantCreatedDialog, setState: setGrantCreatedDialog } =
    useGrantCreatedDialog();
  const { state: editEventDetailsDialog, setState: setEditEventDetailsDialog } =
    useEditEventDetailsDialog();
  const { state: vestingScheduleDialog, setState: setVestingScheduleDialog } =
    useVestingScheduleDialog();
  const { state: optionSplitDialog, setState: setOptionSplitDialog } =
    useOptionSplitDialog();
  const { state: showAdvanceFilterDialog, setState: setShowAdvanceFilters } =
    useAdvanceFilterDialog();
  return (
    <>
      <Dialog open={exerciseRequestDialog.open} maxWidth="md">
        {exerciseRequestDialog.grant && (
          <div className="w-[900px]">
            <ExerciseRequest
              grant={exerciseRequestDialog.grant}
              onClose={() => setExerciseRequestDialog({ open: false })}
            />
          </div>
        )}
      </Dialog>
      <Dialog open={exerciseApproveDialog.open} maxWidth="md">
        <div className="max-w-[900px]">
          {exerciseApproveDialog.grant && (
            <ExerciseApproval
              grant={exerciseApproveDialog.grant}
              onClose={() => setExerciseApproveDialog({ open: false })}
            />
          )}
        </div>
      </Dialog>
      <Dialog open={vestingApproveDialog.open} maxWidth="md">
        <div className="max-w-[900px]">
          {vestingApproveDialog.grant && (
            <VestApproval
              grant={vestingApproveDialog.grant}
              onClose={() => setVestingApproveDialog({ open: false })}
            />
          )}
        </div>
      </Dialog>
      <Dialog open={vestingScheduleDialog.open} maxWidth="lg">
        <div className=" w-[900px]">
          <AddVestingTemplateWrapper
            onClose={() => setVestingScheduleDialog({ open: false })}
            template={vestingScheduleDialog.template}
            mode={vestingScheduleDialog.mode}
            onCreate={() => {}}
          />
        </div>
      </Dialog>
      <Dialog
        open={grantCreatedDialog.open && !!grantCreatedDialog.grantId}
        onClose={() => setGrantCreatedDialog({ open: false })}
        maxWidth="md"
      >
        {grantCreatedDialog.grantId && <GrantCreated />}
      </Dialog>
      <Dialog open={editEventDetailsDialog.open} maxWidth="md">
        {editEventDetailsDialog.event && (
          <EditEventDetails
            event={editEventDetailsDialog.event}
            onClose={() => setEditEventDetailsDialog({ open: false })}
          />
        )}
      </Dialog>
      <Dialog open={optionSplitDialog.open} maxWidth="md">
        <AddEditOptionSplit
          split={optionSplitDialog.split}
          onClose={() => setOptionSplitDialog({ open: false })}
        />
      </Dialog>
      <Dialog
        open={showAdvanceFilterDialog.open}
        maxWidth="md"
        onClose={() => setShowAdvanceFilters({ open: false })}
      >
        <FilterDetails
          onClose={() => setShowAdvanceFilters({ open: false })}
          id={showAdvanceFilterDialog.id}
        />
      </Dialog>
    </>
  );
}
export default DialogContainer;
