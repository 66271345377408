import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import {
  ButtonPrimary,
  ButtonSecondary,
  HStack,
  VStack,
} from "../../components/utils";
import {} from "../../queries/esopPlan";
import { Input, Label } from "../../components/shared/InputField";
import { useError } from "../../store/errorStore";
import { formatWithTimeZone } from "../../utils/date";
import {
  useCompleteOptionSplit,
  useCreateOptionSplit,
} from "../../queries/optionSplit";
import { OptionSplit, OptionSplitCreateReq } from "../../types/OptionSplit";

function AddEditOptionSplit({
  split,
  onClose = () => {},
}: {
  split?: OptionSplit;
  onClose: () => void;
}) {
  const { mutate: doCreateOptionSplit, status } = useCreateOptionSplit();
  const { mutate: doCompleteOptionSplit, status: doCompleteOptionSplitStatus } =
    useCompleteOptionSplit();

  const errorMessage = useError();
  let initialValues: OptionSplitCreateReq = {
    date: new Date().toDateString(),
    note: "",
    ratio: 1.0,
  };

  if (split) {
    initialValues = { ...initialValues, ...split };
  }

  const formik = useFormik({
    initialValues,
    onSubmit: (data) => {
      const optionSplitReq: OptionSplitCreateReq = {
        ...data,
      };
      optionSplitReq.date = formatWithTimeZone(optionSplitReq.date);
      doCreateOptionSplit(optionSplitReq as OptionSplitCreateReq, {
        onSuccess: () => {
          toast("Split Draft Created", { type: "success" });
          onClose();
        },
        onError: (err: any) => {
          errorMessage.setMessage(err.response.data.reason);
          toast(err.response.data.reason, { type: "error", autoClose: 2000 });
        },
      });
    },
  });

  return (
    <>
      <div className="p-4 px-10 mt-4 ml-3 text-lg w-200 font-medium border-b stone-700 ">
        <h6>Create Option Split</h6>
      </div>
      <VStack className="justify-between gap-8 p-4 px-10 ">
        <HStack className="items-center flex-1 gap-8 p-8 mr-4">
          <Label className="text-sm font-normal ">Note</Label>
          <Input {...formik.getFieldProps("note")} />
        </HStack>
        <HStack className="items-center flex-1 gap-8 p-8 mr-4">
          <Label className="text-sm font-normal ">Ratio</Label>
          <Input type="number" {...formik.getFieldProps("ratio")} />
        </HStack>
        <HStack className="items-center flex-1 gap-8 p-8 mr-4">
          <Label className="text-sm font-normal ">Date</Label>
          <Input
            type="date"
            placeholder="Eg:DD/MM/YYYY"
            {...formik.getFieldProps("date")}
          />
        </HStack>
        <HStack className="flex flex-row-reverse gap-4">
          {split?.state && split.state === "DRAFT" && (
            <ButtonPrimary
              className={`flex items-center self-end justify-center ${
                doCompleteOptionSplitStatus === "success" ? "bg-green-500" : ""
              }`}
              onClick={() => {
                if (doCompleteOptionSplitStatus === "success") {
                  onClose();
                } else {
                  doCompleteOptionSplit(split.id, {
                    onSuccess() {
                      onClose();
                    },
                  });
                }
              }}
            >
              Complete Split
              {doCompleteOptionSplitStatus === "loading" && (
                <Icon
                  className=" animate-spin"
                  icon="lucide:loader-2"
                  width={36}
                />
              )}
              {status === "success" && (
                <Icon icon="clarity:success-standard-line" width={36} />
              )}
            </ButtonPrimary>
          )}
          <ButtonPrimary
            className={`flex items-center self-end justify-center ${
              status === "success" ? "bg-green-500" : ""
            }`}
            onClick={() => {
              if (status === "success") {
                onClose();
              } else {
                formik.handleSubmit();
              }
            }}
          >
            {(status === "idle" || status === "error") && "Save"}
            {status === "loading" && (
              <Icon
                className=" animate-spin"
                icon="lucide:loader-2"
                width={24}
              />
            )}
            {status === "success" && (
              <Icon icon="clarity:success-standard-line" width={36} />
            )}
          </ButtonPrimary>
          <ButtonSecondary
            onClick={() => onClose()}
            className="text-gray-400 bg-slate-50"
          >
            Cancel
          </ButtonSecondary>
        </HStack>
      </VStack>
    </>
  );
}
export default AddEditOptionSplit;
